import React, { createContext, useContext, useState, useCallback } from 'react';
import { useModal } from '../../../providers/ModalContext';

const TagModalContext = createContext();

const TagModalProvider = ({ children }) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [promiseResolvers, setPromiseResolvers] = useState({
        resolve: () => {},
        reject: () => {}
    });

    const { openModal } = useModal();

    const createPanel = useCallback(
        ({ postTags }) => {
            openModal('tag_post', { tags: postTags });
            return new Promise((resolve, reject) => {
                setPromiseResolvers({ resolve, reject });
            });
        },
        [openModal]
    );

    const resolvePromise = useCallback(
        (tags) => {
            promiseResolvers.resolve(tags);
        },
        [promiseResolvers]
    );

    const rejectPromise = useCallback(
        (error) => {
            promiseResolvers.reject(error);
        },
        [promiseResolvers]
    );

    return (
        <TagModalContext.Provider
            value={{ createPanel, selectedTags, setSelectedTags, resolvePromise, rejectPromise }}>
            {children}
        </TagModalContext.Provider>
    );
};

export const useTagModal = () => useContext(TagModalContext);

export default TagModalProvider;
