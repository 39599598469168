import { useHttp } from 'api/useHttp';

const useMetricService = () => {
    const axios = useHttp();

    const getWsStats = (params) => {
        return axios.get('/metrics/workspace', { params }).then((response) => response.data);
    };

    const getMetricsData = (params) => {
        return axios.get('/metrics', { params }).then((response) => response.data);
    };

    return {
        getWsStats,
        getMetricsData
    };
};

export default useMetricService;
