import { useHttp } from 'api/useHttp';

const useMissionCampaignService = () => {
    const axios = useHttp();

    const getAllDashboardMissionCampaign = (status, page) => {
        return axios
            .get(`/dashboard/all/missions?status=${status}&page=${page}`)
            .then((response) => response.data);
    };

    return {
        getAllDashboardMissionCampaign
    };
};

export default useMissionCampaignService;
