import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import { Input, ColorPicker, Select, RichText, Switch } from 'components/Form/Inputs';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection,
    ModalFormInputTable
} from 'components/Form/ModalForm';
import { FormProvider } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from 'providers/ModalContext';
import { useAlert } from 'providers/AlertContext';
import useRight from 'hooks/useRight';

const WorkspaceFormModalContent = () => {
    const { t } = useTranslation();
    const {
        contexts: {
            ['admin_workspace_form']: { currentWorkspace }
        },
        closeModal
    } = useModal();
    const queryClient = useQueryClient();
    const { showToast } = useAlert();
    const methods = useForm();
    const { hasRole } = useRight();

    const onFinish = (values) => {};

    const OPTIONS = [
        {
            label: t('WORKSPACE_ONLY_OWNER_CREATE_FOLDER'),
            key: 'only_owner_folder_create'
        },
        {
            label: t('WORKSPACE_ALLOW_FORCE_PUSH'),
            key: 'allow_force_push'
        },
        {
            label: t('WORKSPACE_ALLOW_FORCE_EMAIL_PUSH'),
            key: 'allow_force_email_push'
        },
        currentWorkspace?.allow_force_email_push
            ? {
                  label: t('WORKSPACE_ALLOW_FORCE_EMAIL_PUSH_STACKED'),
                  key: 'allow_force_email_push_stacked'
              }
            : null,
        {
            label: t('WORKSPACE_FORCE_PUSH_WHEN_NEW_POST'),
            key: 'force_push_when_new_post'
        },
        {
            label: t('WORKSPACE_IS_SECRET'),
            key: 'is_secret'
        },
        {
            label: t('WORKSPACE_SEND_EMAIL_ON_NEW_POST'),
            key: 'send_email'
        },
        {
            label: t('WORKSPACE_POST_MUST_BE_IN_FOLDER'),
            key: 'post_must_be_in_folder'
        },
        hasRole('HAS_PDF_TRACING')
            ? {
                  label: t('WORKSPACE_SENSITIVE_DOCUMENTS'),
                  key: 'sensitive_documents'
              }
            : null,
        {
            label: t('WORKSPACE_POST_CAN_BE_BACKDATED'),
            key: 'post_can_be_backdated'
        },
        {
            label: t('WORKSPACE_POST_CAN_BE_POSTDATED'),
            key: 'post_can_be_postdated'
        },
        {
            label: t('WORKSPACE_POST_MUST_BE_REVIEWED'),
            key: 'post_must_be_reviewed'
        },
        {
            label: t('WORKSPACE_DISABLE_ATTACHMENTS_IN_COMMENTS'),
            key: 'disable_attachments_in_comments'
        },
        {
            label: t('POST_CAN_HAVE_EVENT'),
            key: 'post_can_have_event'
        },
        {
            label: t('APP_PARAMETERS_NO_UPDATE_POST_DATE_ON_COMMENT'),
            key: 'disable_update_date_on_comment'
        },
        {
            label: t('APP_PARAMETERS_NO_UPDATE_POST_DATE_ON_STATUS'),
            key: 'disable_update_date_on_status'
        },
        {
            label: t('DISABLE_INDEX_WS'),
            key: 'no_index'
        },
        {
            label: t('ADMIN_SILENT_MODE'),
            key: 'silent_mode'
        },
        {
            label: t('ADMIN_FORCE_SILENT_MODE'),
            key: 'force_silent_mode'
        },
        currentWorkspace?.silent_mode
            ? {
                  label: t('SUBSCRIPTION_MANAGEMENT_HALF_READ_BY_USER_OUS'),
                  input: <Select mode={'multiple'} options={[]} name={'force_silent_mode'} />
              }
            : null,
        hasRole('ROLE_SUPER_ADMIN')
            ? {
                  label: t('HIDDEN_WORKSPACE'),
                  key: 'hidden'
              }
            : null,
        {
            label: t('STUCK_THE_POSTS'),
            key: 'not_editable'
        },
        {
            label: t('WORKSPACE_POSTS_CAN_BE_OUTDATED'),
            key: 'post_can_be_outdated'
        },
        {
            label: t('WORKSPACE_POSTS_CAN_BE_PLANNED_DELETED'),
            key: 'post_can_be_planned_deleted'
        },
        {
            label: t('WORKSPACE_POST_PDF'),
            key: 'posts_pdf'
        },
        {
            label: t('WORKSPACE_HAS_CALENDAR_VIEW'),
            key: 'has_calendar_view'
        },
        currentWorkspace?.has_calendar_view
            ? {
                  label: t('WORKSPACE_HAS_DEFAULT_CALENDAR_VIEW'),
                  key: 'has_default_calendar_view'
              }
            : null,
        {
            label: t('WORKSPACE_HAS_TABLE_VIEW'),
            key: 'has_table_view'
        },
        currentWorkspace?.has_table_view
            ? {
                  label: t('has_default_table_view'),
                  key: 'has_default_calendar_view'
              }
            : null,
        {
            label: t('WORKSPACE_HAS_TILE_VIEW'),
            key: 'has_tile_view'
        },
        currentWorkspace?.has_tile_view
            ? {
                  label: t('WORKSPACE_HAS_DEFAULT_TILE_VIEW'),
                  key: 'has_default_tile_view'
              }
            : null,
        {
            label: t('HIDE_FOLDER_MODE'),
            key: 'hide_folder_mode'
        },
        {
            label: t('MENTIONS_FOLLOW_UP_EMAIL_PUSH'),
            key: 'force_email_push_mentions_follow_up'
        },
        {
            label: t('ALLOW_DISPLAY_ATTACHMENT_IN_STACKED_VIEW'),
            key: 'allow_display_attachment_in_stacked_view'
        },
        currentWorkspace?.allow_display_attachment_in_stacked_view
            ? {
                  label: t('ALLOW_DISPLAY_ATTACHMENT_FULL_WIDTH'),
                  key: 'allow_display_attachment_full_width'
              }
            : null,
        {
            label: t('ALLOW_FEEDBACK'),
            key: 'allow_feedback'
        },
        {
            label: t('ALLOW_AUTO_DELETE'),
            key: 'auto_delete_posts'
        },
        currentWorkspace?.auto_delete_posts
            ? {
                  label: t('ALLOW_AUTO_DELETE_DAY_NUMBER'),
                  input: (
                      <Input
                          name={'auto_delete_posts_day_number'}
                          value={currentWorkspace?.auto_delete_posts_day_number}
                          type={'number'}
                      />
                  )
              }
            : null,
        {
            label: t('SEND_EMAIL_TO_WORKSPACE_ADMIN_POST_MODERATE'),
            key: 'send_email_to_workspace_admins_post_moderate'
        }
    ];

    return (
        <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
            <ModalFormBody>
                <ModalFormSection>
                    <div>
                        <label htmlFor="name">{t('CATEGORY_ADD_INPUT_NAME')}</label>
                        <Input name={'name'} value={currentWorkspace?.name} />
                    </div>
                    <div>
                        <label htmlFor="description">{t('WORKSPACE_ADD_INPUT_DESCRIPTION')}</label>
                        <RichText name={'description'} value={currentWorkspace?.description} />
                    </div>
                    <div>
                        <label htmlFor="writer_description">
                            {t('WORKSPACE_ADD_INPUT_WRITER_DESCRIPTION')}
                        </label>
                        <RichText
                            name={'writer_description'}
                            value={currentWorkspace?.writer_description}
                        />
                    </div>
                    <div>
                        <label htmlFor="rewards">{t('WORKSPACE_ADD_INPUT_REWARDS_TA')}</label>
                        <Input name={'rewards'} type={'number'} value={currentWorkspace?.rewards} />
                    </div>
                    <div>
                        <label htmlFor="custom_post_action_label">
                            {t('WORKSPACE_CUSTOM_POST_ACTION_LABEL')}
                        </label>
                        <Input
                            name={'custom_post_action_label'}
                            value={currentWorkspace?.custom_post_action_label}
                        />
                    </div>
                    <div>
                        <label htmlFor="custom_filter_action_label">
                            {t('WORKSPACE_CUSTOM_FILTER_ACTION_LABEL')}
                        </label>
                        <Input
                            name={'custom_filter_action_label'}
                            value={currentWorkspace?.custom_filter_action_label}
                        />
                    </div>
                    <div>
                        <label htmlFor="category">{t('CATEGORY_ADD_INPUT_NAME')}</label>
                        <Select
                            name={'category'}
                            value={currentWorkspace?.category?.id || null}
                            options={[]}
                        />
                    </div>
                    <div>
                        <label htmlFor="icon_color">{t('CATEGORY_ADD_INPUT_COLOR')}</label>
                        <ColorPicker name={'icon_color'} value={currentWorkspace?.icon_color} />
                    </div>
                </ModalFormSection>
                <ModalFormSection>
                    <ModalFormInputTable
                        title={'Options'}
                        headers={[
                            {
                                label: 'Activer'
                            }
                        ]}
                        groups={[
                            {
                                children: OPTIONS.filter((opt) => opt).map((option) => {
                                    if (!option) return;
                                    return {
                                        label: option.label,
                                        inputs: [
                                            {
                                                input: option.input ? (
                                                    option.input
                                                ) : (
                                                    <Switch
                                                        name={option.key}
                                                        value={
                                                            currentWorkspace
                                                                ? currentWorkspace[option.key]
                                                                : false
                                                        }
                                                    />
                                                )
                                            }
                                        ]
                                    };
                                })
                            }
                        ]}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter />
        </ModalForm>
    );
};

const WorkspaceFormModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name={`admin_workspace_form`}
            title={t('WORKSPACE_ADD_DIALOG_TITLE')}
            options={{
                isFullscreen: true,
                hasFlexContent: true
            }}>
            <WorkspaceFormModalContent />
        </Modal>
    );
};

export default WorkspaceFormModal;
