import { Checkbox } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';

const EmailOptions = ({ input }) => {
    const { t } = useTranslation();

    return (
        <>
            <Checkbox
                label={t('ENABLE_OPPORTUNITY_MODE')}
                name={`workspace_form_inputs[${input.uniqueId}].opportunity_mode`}
                shouldUnregister={false}
            />
            <Checkbox
                label={t('ENABLE_RECRUITING_MODE')}
                name={`workspace_form_inputs[${input.uniqueId}].recruiting_mode`}
                shouldUnregister={false}
            />
        </>
    );
};

export default EmailOptions;
