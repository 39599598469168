import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

function DatePicker({ name, value = null, ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value ? new dayjs(value) : null}
            shouldUnregister={true}
            render={({ field, formState }) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                    <AntdDatePicker
                        {...props}
                        onChange={(date) => {
                            field.onChange(date);
                        }}
                        name={name}
                        format={'DD/MM/YYYY'}
                        defaultValue={value ? new dayjs(value) : null}
                    />
                    {formState.errors[name] && (
                        <span className="error">{formState.errors[name].message}</span>
                    )}
                </div>
            )}
        />
    );
}

export default DatePicker;
