import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePassword from 'hooks/usePassword';
import useUserService from 'api/useUserService';
import { useConfig } from 'providers/ConfigProvider';
import { useAlert } from 'providers/AlertContext';

const ChangePassword = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { isValid, getHints, getMinimumPasswordLevel } = usePassword();
    const { changePassword } = useUserService();
    const { showToast } = useAlert();

    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
    const [email, setEmail] = useState(null);

    const isEmail = (email) => {
        if (!config.login_is_email) {
            return true;
        }

        let regEx =
            /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

        return regEx.test(email);
    };

    const canUserChangePassword = () => {
        return (
            email &&
            isEmail(email) &&
            password &&
            newPassword &&
            newPasswordConfirm &&
            newPassword === newPasswordConfirm &&
            isValid(newPassword, false)
        );
    };

    const changePasswordHanlder = () => {
        changePassword({
            email,
            password,
            password_new: newPassword,
            password_check: newPasswordConfirm
        })
            .then(() => {
                //this.setCurrentView('connection');
                showToast({
                    text: t('LOGIN_CHANGE_PASSWORD_SUCCESS'),
                    duration: 3500
                });
            })
            .catch((error) => {
                if (error.data.error) {
                    showToast({
                        text: t(error.data.error),
                        duration: 3500
                    });
                } else {
                    showToast({
                        text: t('ERROR_UNEXPECTED_CAUGHT'),
                        duration: 3500
                    });
                }
            });
    };

    return (
        <div className="reset spacing">
            <div className="form">
                <div className="fields">
                    <div className="field">
                        <div className="notice">
                            <div className="title">{t('LOGIN_RESET_PASSWORD_NOTICE_TITLE')}</div>
                            <div className="text">
                                {t('LOGIN_RESET_PASSWORD_DELAY_NOTICE_SUBTITLE')}
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="email" className="label">
                            {t('LOGIN_LOGIN_PLACEHOLDER')}
                        </label>
                        <input
                            id="email"
                            className="input"
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            placeholder={t('LOGIN_FORM_EMAIL_PLACEHOLDER')}
                        />
                    </div>

                    <div className="field">
                        <label htmlFor="password" className="label">
                            {t('LOGIN_PASSWORD_OLD_PLACEHOLDER')}
                        </label>
                        <div className="password">
                            <input
                                id="password"
                                className="input"
                                type={!showLoginPassword ? 'password' : 'text'}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                placeholder={t('LOGIN_FORM_PASSWORD_PLACEHOLDER')}
                            />
                            <button
                                type="button"
                                className="show-password"
                                onClick={() => {
                                    setShowLoginPassword(!showLoginPassword);
                                }}
                            >
                                <span>
                                    {!showLoginPassword
                                        ? t('SHOW').toUpperCase()
                                        : t('HIDE').toUpperCase()}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="field">
                        <label htmlFor="password-new" className="label">
                            {t('LOGIN_PASSWORD_NEW_PLACEHOLDER')}
                        </label>
                        <div className="password">
                            <input
                                id="password-new"
                                className="input"
                                type={!showNewPassword ? 'password' : 'text'}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    isValid(e.target.value, true);
                                }}
                                placeholder={t('LOGIN_FORM_PASSWORD_PLACEHOLDER')}
                            />

                            <button
                                type="button"
                                className="show-password"
                                onClick={() => {
                                    setShowNewPassword(!showNewPassword);
                                }}
                            >
                                <span>
                                    {!showNewPassword
                                        ? t('SHOW').toUpperCase()
                                        : t('HIDE').toUpperCase()}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="field">
                        <ul className="tips light password">
                            {config.MANAGE_PASSWORD_RULES_FROM_ADMIN && (
                                <li className="hint">
                                    {t('PASSWORD_ADVICE', {
                                        minimumPasswordLevel: getMinimumPasswordLevel()
                                    })}
                                </li>
                            )}

                            {getHints().map((passwordHint) => {
                                return (
                                    <li
                                        className={`hint ${passwordHint.matched ? 'is-matched' : null}`}
                                    >
                                        <div className="pictogram">
                                            <i
                                                className={
                                                    !passwordHint.matched
                                                        ? 'icon-remove-circle'
                                                        : 'icon-check-circle-1-filled'
                                                }
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                        <div className="text">{passwordHint.description}</div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="field">
                        <label htmlFor="password-check" className="label">
                            {t('LOGIN_PASSWORD_NEW_PLACEHOLDER_CHECK')}
                        </label>

                        <div className="password">
                            <input
                                id="password-check"
                                className="input"
                                type={!showNewPasswordConfirm ? 'password' : 'text'}
                                onChange={(e) => {
                                    setNewPasswordConfirm(e.target.value);
                                    isValid(e.target.value, true);
                                }}
                                placeholder={t('LOGIN_FORM_PASSWORD_CHECK_PLACEHOLDER')}
                            />
                            <button
                                type="button"
                                className="show-password"
                                onClick={() => {
                                    setShowNewPasswordConfirm(!showNewPasswordConfirm);
                                }}
                            >
                                <span>
                                    {!showNewPasswordConfirm
                                        ? t('SHOW').toUpperCase()
                                        : t('HIDE').toUpperCase()}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <button
                        className="btn hl"
                        type="submit"
                        onClick={changePasswordHanlder}
                        disabled={!canUserChangePassword()}
                    >
                        {t('LOGIN_BUTTON_NEW_PASSWORD')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
