import RenderDynamicPage from './components/renderDynamicPage';
import AdminProviders from '../../../Admin/providers/adminProviders';

function DashboardCampaigns() {
    return (
        <AdminProviders>
            <div className="ta_global ta_campaigns">
                <RenderDynamicPage />
            </div>
        </AdminProviders>
    );
}

export default DashboardCampaigns;
