import React, { useCallback } from 'react';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import { Input, Select, Switch } from 'components/Form/Inputs';
import useLevelService from 'api/useLevelService';
import { useAlert } from 'providers/AlertContext';
import { useQueryClient } from '@tanstack/react-query';
import { useAdminTreeContext } from '../../../providers/AdminTreeProvider';
import { forEach, indexOf, orderBy } from 'lodash-es';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection,
    ModalFormInputTable
} from 'components/Form/ModalForm';
import { useModal } from 'providers/ModalContext';

const LevelRuleModalContent = () => {
    const { t } = useTranslation();
    const { getMaxLevelIndex, getLevelByIndex, getLevelName, getLevelById } =
        useAdminLevelContext();
    const { workspaces, filterHiddenWorkspaces } = useAdminTreeContext();
    const {
        contexts: {
            add_level_rule: { currentLevelRule }
        },
        closeModal
    } = useModal();
    const LevelService = useLevelService();
    const { showToast } = useAlert();
    const queryClient = useQueryClient();
    const methods = useForm({
        defaultValues: currentLevelRule
    });

    const onFinish = (values) => {
        let method = currentLevelRule?.id ? 'putLevelConfig' : 'postLevelConfig';
        const levelConfig = { ...currentLevelRule, ...values };

        LevelService[method](levelConfig)
            .then(() => {
                showToast({
                    text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_CONFIRM'),
                    duration: 1500
                });
                queryClient.refetchQueries({ queryKey: ['levels'] });
            })
            .catch(() => {
                showToast({
                    text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_ERROR'),
                    duration: 1500
                });
            })
            .finally(() => {
                closeModal('add_level_rule');
            });
    };

    const levelSelected = useCallback(
        (index) => {
            if (!currentLevelRule.id) {
                return [];
            }

            const levelsId = [];
            for (let i = 1; i <= getMaxLevelIndex(); i++) {
                forEach(currentLevelRule.levels, (value) => {
                    const level = getLevelById(value);
                    if (
                        parseInt(level.index) === i &&
                        indexOf(
                            currentLevelRule.levels.map((l) => parseInt(l)),
                            level.id
                        ) !== -1
                    ) {
                        if (levelsId[i] === undefined) {
                            levelsId[i] = [];
                        }
                        levelsId[i].push(level.id);
                    }
                });
            }

            return levelsId[index];
        },
        [currentLevelRule]
    );

    return (
        <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input
                        name={'name'}
                        label={t('WORKSPACE_EDIT_USERS_DIALOG_RULE_NAME')}
                        placeholder={t('WORKSPACE_EDIT_USERS_DIALOG_RULE_NAME')}
                    />

                    {Array.from({ length: getMaxLevelIndex() }).map((_, index) => {
                        const levelIndex = index + 1;
                        return (
                            getLevelByIndex(levelIndex, index).length > 0 && (
                                <div className={'items'} key={`level_${levelIndex}`}>
                                    <Select
                                        label={getLevelName(levelIndex)}
                                        className="select-multiple"
                                        name={`levelsId[${index}]`}
                                        mode={'multiple'}
                                        value={levelSelected(levelIndex)}
                                        options={getLevelByIndex(levelIndex).map((option) => {
                                            return {
                                                label: option.name,
                                                value: option.id
                                            };
                                        })}
                                    />
                                </div>
                            )
                        );
                    })}
                </ModalFormSection>

                <ModalFormSection>
                    <ModalFormInputTable
                        title={t('ADMIN_USERS_DIALOG_WORKSPACES_TITLE_LABEL')}
                        headers={[
                            {
                                label: t('ADMIN_USERS_DIALOG_MANAGER_LABEL')
                            },
                            {
                                label: t('ADMIN_USERS_DIALOG_HALF_SUBCRIBER_LABEL')
                            },
                            {
                                label: t('ADMIN_USERS_DIALOG_SUBCRIBER_LABEL')
                            },
                            {
                                class: 'write',
                                label: t('ADMIN_USERS_DIALOG_CONTRIB_LABEL')
                            }
                        ]}
                        groups={[
                            {
                                children: orderBy(filterHiddenWorkspaces(workspaces), [
                                    'category.position'
                                ]).map((ws, index) => {
                                    return {
                                        label: `${ws.category.name} - ${ws.name} (${ws.id})`,
                                        inputs: [
                                            {
                                                input: (
                                                    <Switch
                                                        name={`level_rights_update[${ws.id}].is_admin`}
                                                    />
                                                )
                                            },
                                            {
                                                input: (
                                                    <Switch
                                                        name={`level_rights_update[${ws.id}].is_half_readable`}
                                                    />
                                                )
                                            },
                                            {
                                                input: (
                                                    <Switch
                                                        name={`level_rights_update[${ws.id}].is_readable`}
                                                    />
                                                )
                                            },
                                            {
                                                class: 'write',
                                                input: (
                                                    <Switch
                                                        name={`level_rights_update[${ws.id}].is_writable`}
                                                    />
                                                )
                                            }
                                        ]
                                    };
                                })
                            }
                        ]}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_level_rule');
                }}
            />
        </ModalForm>
    );
};

const LevelRuleModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name="add_level_rule"
            title={t('ADMIN_LEVEL_ADD_RULE')}
            options={{
                isFullscreen: true,
                hasFlexContent: true
            }}>
            <LevelRuleModalContent />
        </Modal>
    );
};

export default LevelRuleModal;
