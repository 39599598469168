import React, { useState, useEffect, useContext } from 'react';

import { useAuth } from './AuthContext';
import io from 'socket.io-client';
import { useConfig } from './ConfigProvider';

const SocketContext = React.createContext(null);

export const SocketProvider = ({ children }) => {
    const { config } = useConfig();
    const { user } = useAuth();

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!user) {
            if (socket?.connected) {
                socket.disconnect();

                setSocket(null);
            }
        } else {
            const connection = io.connect(
                config.socketUrl ? config.socketUrl : config.serverUrl + '/',
                {
                    path: '/socket',
                    timeout: 2000,
                    query: {
                        userId: user.securedId ? user.securedId : user.id,
                        /*isMobile: this.isMobile,
                    lastChangeTS: this.lastChangeTS,*/
                        now: Math.floor(Date.now() / 1000)
                        /*platform: this.platform,
                    appVersion: this.appVersion,
                    appBuild: this.appBuild,*/
                    }
                }
            );

            connection.on('connect', () => {
                setSocket(connection);
            });

            connection.on('disconnect', () => {
                setSocket(null);
            });
        }
    }, [user]);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export const useSocket = () => useContext(SocketContext);
