import { useHttp } from 'api/useHttp';

const useProductCampaignService = () => {
    const axios = useHttp();

    /**
     * @param {String} name The name of the campaign (e.g. 'product', 'mission', etc.)
     * @param {String} campaignId
     */
    const forgeDownloadCSVUrl = (name, campaignId) => {
        let timestamp = Math.floor((new Date().getTime() / 1000) * 60);

        return axios
            .get(`/dashboard/campaigns/${name}/${campaignId}/export?nocache=${timestamp}`)
            .then((response) => response.data);
    };

    return {
        forgeDownloadCSVUrl
    };
};

export default useProductCampaignService;
