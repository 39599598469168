import { useQuery } from '@tanstack/react-query';
import { Select } from 'components/Form/Inputs';
import useCampaignService from 'api/useCampaignService';

const Campaigns = ({ field, fieldName, ...props }) => {
    const { getAllRunning } = useCampaignService();
    const { data: campaigns } = useQuery({
        queryKey: ['allRunningCampaignsNoLimit'],
        queryFn: () =>
            getAllRunning(1, 'all', false).then(({ campaigns }) => {
                return campaigns;
            })
    });
    return (
        <Select
            shouldUnregister={false}
            mode={'multiple'}
            name={fieldName}
            value={field.value ? field.value.map((value) => value.value) : []}
            options={
                campaigns && campaigns.length > 0
                    ? campaigns.map((campaign) => {
                          return {
                              label: campaign.title,
                              value: campaign.id
                          };
                      })
                    : []
            }
            {...props}
        />
    );
};

export default Campaigns;
