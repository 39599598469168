import { useRouter } from '@uirouter/react';

const useTag = () => {
    const router = useRouter();

    const goToTag = (tag, href = false) => {
        let state = 'auth.feed.posts.tag';

        if (tag.isPostsStacked) {
            state = 'auth.feed.stacked.tag';
        }

        if (href) {
            return router.stateService.href(state, { tag: tag.id });
        }

        return router.stateService.go(
            state,
            { tag: tag.id },
            {
                reload: true,
                inherit: false
            }
        );
    };

    return {
        goToTag
    };
};

export default useTag;
