import React, { useCallback, useMemo, useState } from 'react';
import { ModalFormSection } from 'components/Form/ModalForm';
import { Button } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useFormContext } from 'react-hook-form';
import { isEmpty, isFunction, isString } from 'lodash-es';
import './styles/formInputRow.scss';
import AbstractInput from './inputs/AbstractInput';
import WidgetsService from 'components/Widgets/services/Widget';

const FormInputRow = ({ input, index, handleDeleteRow }) => {
    const [edit, setEdit] = useState(!input?.id);
    const [currentTab, setCurrentTab] = useState('Paramètres');
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: input.uniqueId
    });
    const { watch, getValues } = useFormContext();

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    };

    const WidgetService = new WidgetsService();

    const widget = useMemo(() => {
        const values = getValues();
        const widget =
            !isEmpty(values) && values?.widgets && values?.widgets[input.uniqueId]
                ? values?.widgets[input.uniqueId]
                : null;
        return widget ? widget : input;
    }, [watch(`widgets[${input.uniqueId}]`), getValues, input]);

    const isFieldConditionFulfilled = useCallback(
        (conditionalField, styling = false) => {
            const fields = styling ? Object.values(widget.styling) : Object.values(widget.fields);
            if (!conditionalField.condition && !conditionalField.conditions) {
                return true;
            }

            let conditions = conditionalField.conditions;

            if (!conditions) {
                conditions = [conditionalField.condition];
            }

            let conditionFunction = conditionalField.atLeastOneConditionIsTrue ? 'some' : 'every';

            return conditions[conditionFunction]((condition) => {
                if (!condition) {
                    return true;
                }

                return fields.some((field) => {
                    if (field.key !== condition.key) {
                        return false;
                    }

                    if (isFunction(condition.value)) {
                        return condition.value(field.value);
                    }

                    let values = isString(condition.value)
                        ? condition.value.split('|')
                        : [condition.value];

                    switch (condition.operator) {
                        case '=':
                            return values.some((value) => {
                                return field.value === value;
                            });
                        case '!=':
                            return values.some((value) => {
                                return field.value !== value;
                            });
                        default:
                            return false;
                    }
                });
            });
        },
        [widget]
    );

    return (
        <div ref={setNodeRef} style={style} className={'input-row'}>
            <ModalFormSection inline>
                <div className="handle-sort" {...attributes} {...listeners}>
                    <div className="icon">
                        <i className="icon-navigation-menu-horizontal"></i>
                    </div>
                </div>
                <div className={'meta'}>
                    <div className="name">
                        <div>{WidgetService.getWidgetFieldValueForKey(widget, 'title')}</div>
                    </div>
                    <div className={'type_and_condition'}>
                        <div className="type">{input.name}</div>
                        {widget.organizational_units &&
                            widget.organizational_units.map((ou) => (
                                <div className="condition" key={`ou_${ou.id}`}>
                                    {ou.name}
                                </div>
                            ))}
                        {widget.excluded_organizational_units &&
                            widget.excluded_organizational_units.map((ou) => (
                                <div className="condition excluded" key={`ou_${ou.id}`}>
                                    {ou.name}
                                </div>
                            ))}
                    </div>
                </div>
                <Button>
                    <i className="icon-cursor-select-4" aria-hidden="true"></i>
                </Button>
                <Button
                    onClick={() => {
                        setEdit(!edit);
                    }}>
                    <i className="icon-pencil-1" aria-hidden="true"></i>
                </Button>
                <Button>
                    <i className="icon-common-file-double-1" aria-hidden="true"></i>
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteRow(input.uniqueId);
                    }}>
                    <i className="icon-bin-2" aria-hidden="true"></i>
                </Button>
            </ModalFormSection>
            {edit && (
                <ModalFormSection tabs={['Paramètres', 'Options']} onTabChange={setCurrentTab}>
                    {currentTab === 'Paramètres' &&
                        Object.values(input.fields).map((field, fieldIndex) => {
                            if (!isFieldConditionFulfilled(field)) return;
                            return (
                                <AbstractInput
                                    field={field}
                                    fieldName={`widgets[${input.uniqueId}].fields.${field.key}.value`}
                                    uniqueId={input.uniqueId}
                                    label={field.name}
                                />
                            );
                        })}
                    {currentTab === 'Options' &&
                        Object.values(input.styling).map((field, fieldIndex) => {
                            if (!isFieldConditionFulfilled(field, true)) return;
                            return (
                                <AbstractInput
                                    field={field}
                                    fieldName={`widgets[${input.uniqueId}].styling.${field.key}.value`}
                                    uniqueId={input.uniqueId}
                                    label={field.name}
                                />
                            );
                        })}
                </ModalFormSection>
            )}
        </div>
    );
};

export default FormInputRow;
