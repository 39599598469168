import React from 'react';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const Tags = ({ result, query }) => {
    const { getResultWithQueryHighlight } = useSearchBoxResultComponent(query);

    return (
        <>
            <div
                className="icon"
                style={{ backgroundColor: result.color, borderColor: result.color }}>
                <div className="shield"></div>
            </div>

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.name)
                    }}
                />
            </div>
        </>
    );
};

export default Tags;
