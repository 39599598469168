import React, { useRef } from 'react';

import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

const ResizableImage = ({ src }) => {
    const pinchZoomPanRef = useRef();

    const onLoad = (event) => {
        setTimeout(() => {
            pinchZoomPanRef.current.applyInitialTransform();
        });
    };

    return (
        <PinchZoomPan zoomButtons={false} position="center" ref={pinchZoomPanRef}>
            <img src={src} onLoad={(event) => onLoad(event)} />
        </PinchZoomPan>
    );
};

export default ResizableImage;
