import React, { useState } from 'react';
import useMetricService from 'api/useMetricService';
import Table from 'components/Table';
import { useQuery } from '@tanstack/react-query';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import useUtils from '../../../hooks/useUtils';
import useMetric from '../../../hooks/useMetric';
import useUrl from '../../../hooks/useUrl';
import { useTranslation } from 'react-i18next';

const Workspaces = () => {
    const { getWsStats } = useMetricService();
    const { forgeDownloadAllPosts } = useMetric();
    const { openUrl } = useUrl();
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const [isCustom, setIsCustom] = useState(params.period === 'custom');
    const router = useRouter();
    const { getFirstLetter } = useUtils();

    const { data: categories, isLoading } = useQuery({
        queryFn: () =>
            getWsStats({
                ...params
            }),
        queryKey: ['metrics_workspace', params.period, params.startDate, params.endDate]
    });

    const handleChangePeriod = (value) => {
        if (value === 'custom') {
            setIsCustom(true);
        } else {
            router.stateService.go('.', { ...params, period: value });
        }
    };

    const handleChangeDates = (value) => {
        router.stateService.go('.', {
            period: 'custom',
            startDate: value[0].format('YYYY-MM-DD'),
            endDate: value[1].format('YYYY-MM-DD')
        });
    };

    const dl = () => {
        openUrl(
            forgeDownloadAllPosts({
                period: params.period,
                startDate: params.startDate,
                endDate: params.endDate,
                tagIds: null
            }),
            '_blank'
        );
    };

    const COLUMNS = [
        {
            id: 'category.name',
            header: 'Catégorie',
            sortable: true,
            cell: ({ row }) => {
                const category = row.original.category;

                return <strong>{category.name}</strong>;
            }
        },
        {
            id: 'category.numberPosts',
            header: '#actus',
            sortable: true
        },
        {
            id: 'category.numberLikes',
            header: '#likes / réactions',
            sortable: true
        },
        {
            id: 'category.likesAverage',
            header: '#likes / réactions moyens',
            sortable: true
        },
        {
            id: 'category.numberComments',
            header: '#commentaires',
            sortable: true
        },
        {
            id: 'category.commentsAverage',
            header: '#commentaires moyens',
            sortable: true
        },
        {
            id: 'category.strictReach',
            header: '#tx de conversion',
            sortable: true
        }
    ];

    const SUB_COLUMNS = [
        {
            id: 'name',
            header: 'datafeed',
            sortable: true,
            cell: ({ row }) => {
                const workspace = row.original;

                return (
                    <div className={'workspace-list-option'}>
                        <div className="icon" style={{ backgroundColor: workspace.color }}>
                            <div className="letter">{getFirstLetter(workspace.name)}</div>
                        </div>

                        <div className="name">
                            <strong>{workspace.name}</strong>
                        </div>
                    </div>
                );
            }
        },
        {
            id: 'numberPosts',
            header: '#actus',
            sortable: true
        },
        {
            id: 'numberLikes',
            header: '#likes / reactions',
            sortable: true
        },
        {
            id: 'numberComments',
            header: '#likes / reactions moyen',
            sortable: true
        },
        {
            id: 'commentsAverage',
            header: '#comments moyen',
            sortable: true
        },
        {
            id: 'consultationsAverage',
            header: '#consult. uniques moy.',
            sortable: true
        },
        {
            id: 'numberFollowers',
            header: '#nb abonnés',
            sortable: true
        },
        {
            id: 'postStrictConsultationsByWorkspace',
            header: '#lectures moy.',
            sortable: true
        },
        {
            id: 'strictReach',
            header: '#tx de conversion',
            sortable: true
        }
    ];

    return (
        <>
            <div className="header">
                <div className="top">
                    <div className="title">Metrics</div>
                    <div className="subtitle">Par level</div>
                </div>

                <div className="filters">
                    <div className="periods">
                        <div className="select">
                            <Select
                                style={{ width: '100%' }}
                                onChange={handleChangePeriod}
                                defaultValue={params.period}
                                options={[
                                    {
                                        label: '24h',
                                        value: 'last_day'
                                    },
                                    {
                                        label: '7 jours',
                                        value: 'last_week'
                                    },
                                    {
                                        label: '30 jours',
                                        value: 'last_month'
                                    },
                                    {
                                        label: '90 jours',
                                        value: 'last_three_monthes'
                                    },
                                    {
                                        label: 'Dates personnalisées',
                                        value: 'custom'
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    {isCustom && (
                        <div className="dates">
                            <DatePicker.RangePicker
                                showTime={false}
                                defaultValue={[
                                    new dayjs(params.startDate),
                                    new dayjs(params.endDate)
                                ]}
                                format="YYYY-MM-DD"
                                maxDate={new dayjs()}
                                onChange={handleChangeDates}
                            />
                        </div>
                    )}

                    <div className="buttons">
                        <div className="button export" onClick="$ctrl.dl()" onClick={() => dl()}>
                            {t('EXPORT_ALL_POSTS_BETWEEN_DATES')}
                            <i className="icon-download-bottom"></i>
                        </div>
                    </div>
                </div>
            </div>

            <Table
                columns={COLUMNS}
                subColumns={SUB_COLUMNS}
                data={categories}
                isLoading={isLoading}
                subRowPath={'workspaces'}
            />
        </>
    );
};

export default Workspaces;
