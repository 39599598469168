import React, { createContext, useContext, useState, useCallback } from 'react';
import { useModal } from 'providers/ModalContext';

const JoinDocumentModalContext = createContext();

const JoinDocumentModalProvider = ({ children }) => {
    const [modalConfiguration, setModalConfiguration] = useState({});
    const [promiseResolvers, setPromiseResolvers] = useState({
        resolve: () => {},
        reject: () => {}
    });

    const { openModal, closeModal } = useModal();

    const createPanel = useCallback(
        (params = {}) => {
            setModalConfiguration(params);
            openModal('join_document');
            return new Promise((resolve, reject) => {
                setPromiseResolvers({ resolve, reject });
            });
        },
        [openModal]
    );

    const resolvePromise = useCallback(
        (files) => {
            promiseResolvers.resolve(files);
            closeModal('join_document');
        },
        [promiseResolvers]
    );

    const rejectPromise = useCallback(
        (error) => {
            promiseResolvers.reject(error);
            closeModal('join_document');
        },
        [promiseResolvers]
    );

    return (
        <JoinDocumentModalContext.Provider
            value={{
                createPanel,
                modalConfiguration,
                setModalConfiguration,
                resolvePromise,
                rejectPromise
            }}
        >
            {children}
        </JoinDocumentModalContext.Provider>
    );
};

export const useJoinDocumentModal = () => useContext(JoinDocumentModalContext);

export default JoinDocumentModalProvider;
