import { useTranslation } from 'react-i18next';
import { useConfig } from '../providers/ConfigProvider';

const useAppSettings = () => {
    const { t } = useTranslation();
    const { config } = useConfig();

    const settings = [
        {
            title: t('TOP_APP_CONFIGURATION'),
            allowEntities: true,
            settings: [
                {
                    label: t('APP_PARAMETERS_CHANNEL'),
                    name: 'HAS_CHANNELS',
                    value: 0,
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_TROPHIES'),
                    name: 'HAS_TROPHIES',
                    value: 0,
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HIDE_REWARD_COUNT'),
                    name: 'HAS_HIDE_REWARDS_COUNT',
                    value: 0,
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_WELCOMING_MAIL_FOR_IMPORTED_USERS'),
                    name: 'WELCOMING_MAIL_FOR_IMPORTED_USERS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: "Afficher un badge dans le flux d'actualités",
                    name: 'SHOW_TROPHIES_IN_FEED',
                    needed: 'HAS_TROPHIES',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_TA'),
                    name: 'HAS_TA',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_TA_EXTERNAL'),
                    name: 'HAS_TA_EXTERNAL',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ALLOW_REPORT'),
                    name: 'HAS_REPORT_FOR_ALL_USERS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_SHOW_LIKES'),
                    name: 'HAS_LIKES',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_SHOW_COMMENT_LIKES'),
                    name: 'HAS_COMMENT_LIKES',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ALLOW_EMPLOYEE_ACCOUNT'),
                    name: 'HAS_EMPLOYEE_ACCOUNT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ALLOW_EMPLOYEE_ACCOUNT_IMPORT'),
                    name: 'ALLOW_IMPORT_EMPLOYEES',
                    needed: 'HAS_EMPLOYEE_ACCOUNT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_SHOW_TAGS'),
                    name: 'SHOW_TAGS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_SHOW_VIEWER'),
                    name: 'SHOW_VIEWER',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_EMAIL_OPPORTUNITY'),
                    name: 'HAS_EMAIL_OPPORTUNITY',
                    needed: 'HAS_TA',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_EMAIL_OPPORTUNITY_CC'),
                    name: 'HAS_EMAIL_OPPORTUNITY_CC',
                    needed: 'HAS_EMAIL_OPPORTUNITY',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_EMAIL_OPPORTUNITY_DELEGATE'),
                    name: 'HAS_EMAIL_OPPORTUNITY_DELEGATE',
                    value: 0,
                    needed: 'HAS_EMAIL_OPPORTUNITY',
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_COUPON'),
                    name: 'HAS_EMAIL_COUPON',
                    value: 0,
                    needed: 'HAS_EMAIL_OPPORTUNITY',
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_STATS'),
                    name: 'HAS_STATS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Activer les liens sur la droite',
                    name: 'HAS_RIGHT_SIDE_CONTENTS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('HELPDESK_COMMON_ENABLE_HELPDESK'),
                    name: 'HAS_HELPDESK',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_DIRECTORY'),
                    name: 'HAS_EMPLOYEE_DIRECTORY',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_DIRECTORY_V2'),
                    name: 'HAS_EMPLOYEE_DIRECTORY_V2',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_LETTER_FILTER_IN_EMPLOYEE_DIRECTORY'),
                    name: 'HAS_LETTER_FILTER_IN_EMPLOYEE_DIRECTORY',
                    value: 0,
                    needed: 'HAS_EMPLOYEE_DIRECTORY',
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ASK_TERMS_LOGIN'),
                    name: 'ASK_TERMS_ON_LOGIN',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_REFERRALS_POINTS'),
                    name: 'ALLOW_SPONSORSHIP',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ANONYMIZE_ACTIVITY_AUTOMATICALLY'),
                    name: 'ANONYMIZE_ACTIVITY_AUTOMATICALLY',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ANONYMIZE_FORMER_EMPLOYEE_AUTOMATICALLY'),
                    name: 'ANONYMIZE_FORMER_EMPLOYEE_AUTOMATICALLY',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_B2B_EMAIL'),
                    name: 'B2B_EMAIL',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ACTIVITY_REPORT'),
                    name: 'HAS_ACTIVITY_REPORT',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ACTIVITY_REPORT_BY_EMAIL'),
                    name: 'HAS_ACTIVITY_REPORT_BY_EMAIL',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_LETTERS_AS_WORKSPACE_IMAGE'),
                    name: 'HAS_LETTERS_AS_WORKSPACE_IMAGE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HIDE_FIRST_NAME_SEARCH'),
                    name: 'HIDE_FIRST_NAME_SEARCH',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Autoriser la mention dans les actualités et les commentaires',
                    name: 'ALLOW_MENTION_POST_COMMENT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_LIVE_VOTING'),
                    name: 'HAS_LIVE_VOTING',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_PDF_TRACING'),
                    name: 'HAS_PDF_TRACING',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_RESTRICTED_PROFILE_EDITING'),
                    name: 'HAS_RESTRICTED_PROFILE_EDITING',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_WORD_FILTER_FOR_EXTERNALS'),
                    name: 'HAS_WORD_FILTER_FOR_EXTERNALS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_WORD_FILTER_FOR_INTERNALS'),
                    name: 'HAS_WORD_FILTER_FOR_INTERNALS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_WORD_FILTER_FOR_POST_UPDATE'),
                    name: 'HAS_WORD_FILTER_FOR_POST_UPDATE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_SUBSCRIPTIONS_MANAGEMENT_BY_USER'),
                    name: 'HAS_SUBSCRIPTIONS_MANAGEMENT_BY_USER',
                    value: 0,
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_ADMIN_TAGS'),
                    name: 'HAS_ADMIN_TAGS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_CHECK_EMAIL_TV'),
                    name: 'CHECK_EMAIL_TV',
                    needed: 'HAS_TA',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_SPRINGBOARD'),
                    name: 'HAS_SPRINGBOARD',
                    value: 0,
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_SPRINGBOARD_AUTO_OPEN_DESKTOP'),
                    name: 'HAS_SPRINGBOARD_AUTO_OPEN_DESKTOP',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_SPRINGBOARD',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_SPRINGBOARD_AUTO_OPEN'),
                    name: 'HAS_SPRINGBOARD_AUTO_OPEN',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_SPRINGBOARD',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_MISSIONS'),
                    name: 'HAS_MISSIONS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Level lié aux missions',
                    name: 'MISSION_LEVEL_TEXT',
                    value: 'MISSION_LEVEL_LABEL',
                    needed: 'HAS_MISSIONS',
                    type: 'select',
                    choices: []
                },
                {
                    label: t('APP_PARAMETERS_HAS_HIDE_CAMPAIGN_EMPTY'),
                    name: 'HAS_HIDE_CAMPAIGN_EMPTY',
                    value: 0,
                    needed: 'HAS_TA',
                    allowEntities: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_NO_UPDATE_POST_DATE_ON_EDIT'),
                    name: 'NO_UPDATE_POST_DATE_ON_EDIT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HIDE_IMPORTANT_CAMPAIGN'),
                    name: 'HIDE_IMPORTANT_CAMPAIGN',
                    needed: 'HAS_TA',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HIDE_WORKSPACES_BEHIND_BUTTON'),
                    name: 'HIDE_WORKSPACES_BEHIND_BUTTON',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_MANAGE_PASSWORD_RULES_FROM_ADMIN'),
                    name: 'MANAGE_PASSWORD_RULES_FROM_ADMIN',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HIDE_NEW_POST_BADGE_TREE'),
                    name: 'HIDE_NEW_POST_BADGE_TREE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_TRACKS'),
                    name: 'HAS_TRACKS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Module de configuration des exports de data feed en PDF',
                    name: 'HAS_WORKSPACE_EXPORT_PDF',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('FEATURE_FLAG_MANAGE_FUTURE_POSTS'),
                    name: 'HAS_FEATURE_MANAGE_FUTURE_POSTS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_PEERTUBE'),
                    name: 'HAS_PEERTUBE',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_SHOW_POST_ID'),
                    name: 'SHOW_POST_ID',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('DISABLE_DATA_OBFUSCATION'),
                    name: 'HAS_DISABLE_DATA_OBFUSCATION',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ALLOW_ADMIN_TO_EDIT_POST'),
                    name: 'HAS_ALLOW_ADMIN_TO_EDIT_POST',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('POST_LIMIT_IN_FEED_ACTIVE'),
                    name: 'POST_LIMIT_IN_FEED_ACTIVE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('POST_LIMIT_IN_FEED'),
                    name: 'POST_LIMIT_IN_FEED',
                    needed: 'POST_LIMIT_IN_FEED_ACTIVE',
                    value: 10,
                    type: 'integer'
                },
                {
                    label: t('MAX_CONNEXION_ATTEMPTS_ACTIVE'),
                    name: 'MAX_CONNEXION_ATTEMPTS_ACTIVE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('MAX_CONNEXION_ATTEMPTS'),
                    name: 'MAX_CONNEXION_ATTEMPTS',
                    needed: 'MAX_CONNEXION_ATTEMPTS_ACTIVE',
                    value: 5,
                    type: 'integer'
                },
                {
                    label: t('HAS_FORCE_CHECKSUM_CHECK'),
                    name: 'HAS_FORCE_CHECKSUM_CHECK',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_LIKE_ON_SOCIAL_CAMPAIGNS'),
                    name: 'ENABLE_LIKE_ON_SOCIAL_CAMPAIGNS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_LESS_XHR_MODE'),
                    name: 'ENABLE_LESS_XHR_MODE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_RESOURCES_SPACE'),
                    name: 'HAS_RESOURCES_SPACE',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_RESOURCES_SECURED'),
                    name: 'HAS_RESOURCES_SECURED',
                    needed: 'HAS_RESOURCES_SPACE',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_READ_MORE_COMMENT'),
                    name: 'ENABLE_READ_MORE_COMMENT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('HIDE_FOLDER_MODE'),
                    name: 'HIDE_FOLDER_MODE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_POST_CONSULTATIONS_ONLY_ADMIN'),
                    name: 'POST_CONSULTATIONS_ONLY_FOR_ADMIN',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                // {
                //     label: t('APP_SETTINGS_ENABLE_PROFILE_ACTIVITY'),
                //     name: 'HAS_ENABLE_PROFILE_ACTIVITY',
                //     value: 0,
                //     choices: [
                //         {
                //             label: t('DISABLE'),
                //             value: 0,
                //         },
                //         {
                //             label: t('ENABLE'),
                //             value: 1,
                //         },
                //     ]
                // },
                {
                    label: t('HIDE_REVIEW_MENU_BUTTON'),
                    name: 'HIDE_REVIEW_MENU_BUTTON',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ALL_WORKSPACES_POST_PREVIEW'),
                    name: 'ALL_WORKSPACES_POST_PREVIEW',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_NEED_EMAIL_VALIDATION'),
                    name: 'NEED_EMAIL_VALIDATION',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_IS_CLOSED_FOR_EXTERNAL'),
                    name: 'IS_CLOSED_FOR_EXTERNAL',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Activer la palette de couleurs à la saisie (texte long + flux non structuré)',
                    name: 'CUSTOM_COLOR',
                    value:
                        config.quill_colors !== undefined && config.quill_colors.length > 0
                            ? true
                            : 0,
                    disabled: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: "Autoriser l'export des campagnes Tous Acteur",
                    name: 'HAS_TA_CAMPAIGN_EXPORT',
                    value:
                        config.allow_export_ta !== undefined && config.allow_export_ta ? true : 0,
                    disabled: true,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_DYNAMIC_NAV'),
                    name: 'HAS_DYNAMIC_NAV',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_NEW_POST_BADGE_IS_COLOR'),
                    name: 'NEW_POST_BADGE_IS_COLOR',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_NEW_POST_BADGE_COLOR'),
                    name: 'NEW_POST_BADGE_COLOR',
                    allowEntities: true,
                    needed: 'NEW_POST_BADGE_IS_COLOR',
                    type: 'text',
                    value: ''
                },
                {
                    label: t('APP_PARAMETERS_NEW_NOTIFICATION'),
                    name: 'NEW_NOTIFICATIONS_FORMAT',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('BLOG_MANAGEMENT'),
            allowEntities: true,
            settings: [
                {
                    label: t('APP_PARAMETERS_BLOG'),
                    name: 'HAS_BLOG',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_COMMENTS'),
                    name: 'BLOG_HIDE_COMMENTS',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_LIKES'),
                    name: 'BLOG_HIDE_LIKES',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_AUTHOR'),
                    name: 'BLOG_HIDE_AUTHOR',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_DATES'),
                    name: 'BLOG_HIDE_DATES',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_TYPE_FILTERS'),
                    name: 'BLOG_HIDE_TYPE_FILTERS',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_BLOG_HIDE_LOGO'),
                    name: 'BLOG_HIDE_LOGO',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('SEARCH_MANAGEMENT'),
            allowEntities: true,
            settings: [
                {
                    label: t('ENABLE_NEW_SEARCH'),
                    name: 'HAS_NEW_SEARCH',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_MEILI_SEARCH'),
                    name: 'HAS_MEILI_SEARCH',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_SEARCH_BY_ID'),
                    name: 'HAS_SEARCH_BY_ID',
                    is_v5: true,
                    value: 1,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_DEFAULT_VIEW_BY_DEFAULT'),
                    name: 'SEARCH_HAS_DEFAULT_VIEW_BY_DEFAULT',
                    needed: 'HAS_MEILI_SEARCH',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('SEARCH_HIDE_DOCUMENT_TAB'),
                    name: 'SEARCH_HIDE_DOCUMENT_TAB',
                    needed: 'HAS_MEILI_SEARCH',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('SEARCH_HIDE_RESOURCE_TAB'),
                    name: 'SEARCH_HIDE_RESOURCE_TAB',
                    needed: 'HAS_MEILI_SEARCH',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('SEARCH_HIDE_CAMPAIGN_TAB'),
                    name: 'SEARCH_HIDE_CAMPAIGN_TAB',
                    needed: 'HAS_MEILI_SEARCH',
                    is_v5: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('RECONNECT_MANAGEMENT'),
            allowEntities: false,
            settings: [
                {
                    label: t('ENABLE_SPONSORSHIP_CLIENT'),
                    name: 'HAS_SPONSORSHIP_CLIENT',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('RECONECT_RECO_STATS_HIDE'),
                    name: 'HAS_SPONSORSHIP_RECO_STATS_HIDDEN',
                    needed: 'HAS_SPONSORSHIP_CLIENT',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('RECONECT_LIMIT_METRICS_BY_OUS'),
                    name: 'RECONECT_METRICS_BY_OUS',
                    value: [],
                    needed: 'HAS_SPONSORSHIP_CLIENT',
                    type: 'multi_select',
                    choices: []
                },
                {
                    label: t('RECONNECT_METRICS_FIRST_LEVEL_INDEX'),
                    name: 'RECONNECT_METRICS_FIRST_LEVEL_INDEX',
                    needed: 'HAS_SPONSORSHIP_CLIENT',
                    type: 'integer',
                    value: null
                },
                {
                    label: t('RECONNECT_METRICS_SECOND_LEVEL_INDEX'),
                    name: 'RECONNECT_METRICS_SECOND_LEVEL_INDEX',
                    needed: 'HAS_SPONSORSHIP_CLIENT',
                    type: 'integer',
                    value: null
                }
            ]
        },
        {
            title: t('TRACKS_MANAGEMENT'),
            allowEntities: false,
            needed: 'HAS_TRACKS',
            settings: [
                {
                    label: t('TRACK_HIDE_STATUS'),
                    name: 'TRACK_HIDE_STATUS',
                    allowEntities: false,
                    value: [],
                    type: 'multi_select',
                    choices: []
                },
                {
                    label: t('TRACK_EXPIRE_DRAFT_AT_DEADLINE'),
                    name: 'TRACK_EXPIRE_DRAFT_AT_DEADLINE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('FEED_MANAGEMENT'),
            allowEntities: true,
            is_v5: true,
            settings: [
                {
                    label: t('ENABLE_STACKED_VIEW'),
                    name: 'HAS_STACKED_VIEW',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Nombre maximum de jours chargés pour la vue condensée',
                    name: 'MAX_BOTTOM_LINE_DAYS',
                    needed: 'HAS_STACKED_VIEW',
                    type: 'integer',
                    value: 10
                },
                {
                    label: t('APP_PARAMETERS_STACKED_VIEW_PREVIEW_MAX_LINES'),
                    name: 'STACKED_VIEW_PREVIEW_MAX_LINES',
                    needed: 'HAS_STACKED_VIEW',
                    type: 'integer',
                    value: 2
                },
                {
                    label: t('ENABLE_STACKED_VIEW_BY_DEFAULT'),
                    name: 'HAS_STACKED_VIEW_DEFAULT',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_STACKED_VIEW',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_AUTO_MEDIA_THUMBNAIL_STACKED_VIEW'),
                    name: 'HAS_AUTO_MEDIA_THUMBNAIL_STACKED_VIEW',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_STACKED_VIEW',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_AUTOLOAD_OLD_POSTS_STACKED_VIEW'),
                    name: 'HAS_AUTOLOAD_OLD_POSTS_STACKED_VIEW',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_STACKED_VIEW',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_ESSENTIAL_VIEW'),
                    name: 'HAS_ESSENTIAL_VIEW',
                    allowEntities: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('ENABLE_ESSENTIAL_VIEW_BY_DEFAULT'),
                    name: 'HAS_ESSENTIAL_VIEW_DEFAULT',
                    allowEntities: true,
                    manageable: true,
                    needed: 'HAS_ESSENTIAL_VIEW',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('METRICS_MANAGEMENT'),
            allowEntities: true,
            settings: [
                {
                    label: t('APP_PARAMETERS_ENABLE_METRICS'),
                    name: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_METRICS_DASHBOARD'),
                    name: 'HAS_DASHBOARD_METRICS',
                    needed: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_DISPLAY_REACH_METRICS'),
                    name: 'DISPLAY_REACH_METRICS',
                    needed: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_DISPLAY_STRICT_REACH_METRICS'),
                    name: 'DISPLAY_STRICT_REACH_METRICS',
                    needed: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_METRICS_HIDE_BDR'),
                    name: 'METRICS_HIDE_BDR',
                    needed: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_METRICS_HIDE_BDR_MS'),
                    name: 'METRICS_HIDE_BDR_MS',
                    needed: 'HAS_METRICS',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ENABLE_METRICS_DASHBOARD_LEVELS'),
                    name: 'DASHBOARD_METRICS_LEVEL',
                    value: [],
                    needed: 'HAS_DASHBOARD_METRICS',
                    type: 'multi_select',
                    choices: []
                }
            ]
        },
        {
            title: 'Paramètrage des règles de mot de passe',
            needed: 'MANAGE_PASSWORD_RULES_FROM_ADMIN',
            settings: [
                {
                    label: t('PASSWORD_MIN_LENGTH', { total: 'N' }),
                    name: 'PASSWORD_MIN_LENGTH',
                    value: config.minimum_password_characters,
                    type: 'integer'
                },
                {
                    label: t('PASSWORD_AT_LEAST_AN_UPPERCASE', { total: 'N' }),
                    name: 'PASSWORD_AT_LEAST_AN_UPPERCASE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('PASSWORD_UPPERCASE_MIN_LENGTH'),
                    name: 'PASSWORD_UPPERCASE_MIN_LENGTH',
                    needed: 'PASSWORD_AT_LEAST_AN_UPPERCASE',
                    value: 1,
                    type: 'integer'
                },
                {
                    label: t('PASSWORD_AT_LEAST_A_LOWERCASE', { total: 'N' }),
                    name: 'PASSWORD_AT_LEAST_A_LOWERCASE',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('PASSWORD_LOWERCASE_MIN_LENGTH'),
                    name: 'PASSWORD_LOWERCASE_MIN_LENGTH',
                    needed: 'PASSWORD_AT_LEAST_A_LOWERCASE',
                    value: 1,
                    type: 'integer'
                },
                {
                    label: t('PASSWORD_AT_LEAST_A_DIGIT', { total: 'N' }),
                    name: 'PASSWORD_AT_LEAST_A_DIGIT',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('PASSWORD_DIGIT_MIN_LENGTH'),
                    name: 'PASSWORD_DIGIT_MIN_LENGTH',
                    needed: 'PASSWORD_AT_LEAST_A_DIGIT',
                    value: 1,
                    type: 'integer'
                },
                {
                    label: t('PASSWORD_AT_LEAST_A_SPECIAL_CHAR', { total: 'N' }),
                    name: 'PASSWORD_AT_LEAST_A_SPECIAL_CHAR',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('PASSWORD_SPECIAL_CHAR_MIN_LENGTH'),
                    name: 'PASSWORD_SPECIAL_CHAR_MIN_LENGTH',
                    needed: 'PASSWORD_AT_LEAST_A_SPECIAL_CHAR',
                    value: 1,
                    type: 'integer'
                },
                {
                    label: t('PASSWORD_NO_THREE_FOLLOWING_SAME_CHAR'),
                    name: 'PASSWORD_NO_THREE_FOLLOWING_SAME_CHAR',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        },
        {
            title: t('ADMIN_MENU_HELPDESK_HOMESCREENS_LABEL'),
            settings: [
                {
                    label: 'Personnalisation du texte de confirmation',
                    name: 'HOMESCREEN_CONFIRMATION_BUTTON_TEXT',
                    value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL',
                    type: 'select',
                    choices: [
                        {
                            label: t('HOMESCREEN_READ_ACCEPT_BUTTON_LABEL'),
                            value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL'
                        },
                        {
                            label: t('HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_2'),
                            value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_2'
                        },
                        {
                            label: t('HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_3'),
                            value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_3'
                        },
                        {
                            label: t('HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_4'),
                            value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_4'
                        },
                        {
                            label: t('HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_5'),
                            value: 'HOMESCREEN_READ_ACCEPT_BUTTON_LABEL_5'
                        }
                    ]
                }
            ]
        },
        {
            title: t('ADMIN_SECURE_WORKSPACE_MESSAGES'),
            needed: 'HAS_PDF_TRACING',
            settings: [
                {
                    label: 'Message pour le niveau Alerte',
                    name: 'DOWNLOAD_SECURE_PROMPT_ALERT',
                    value: t('DOWNLOAD_SECURE_PROMPT_ALERT'),
                    type: 'long_text'
                },
                {
                    label: 'Message pour le niveau Tracking',
                    name: 'DOWNLOAD_SECURE_PROMPT_TRACKING',
                    value: t('DOWNLOAD_SECURE_PROMPT_TRACKING'),
                    type: 'long_text'
                }
            ]
        },
        {
            title: t('ADMIN_WORD_FILTER_FOR_EXTERNALS'),
            needed: 'HAS_WORD_FILTER_FOR_EXTERNALS',
            settings: [
                {
                    label: 'Liste des mots innapropriés (1 par ligne)',
                    name: 'FORBIDDEN_WORDS_FOR_EXTERNALS',
                    value: t('ADMIN_FORBIDDEN_WORDS_FOR_EXTERNALS'),
                    type: 'long_text'
                }
            ]
        },
        {
            title: t('ADMIN_WORD_FILTER_FOR_POST_UPDATE'),
            needed: 'HAS_WORD_FILTER_FOR_POST_UPDATE',
            settings: [
                {
                    label: t('ADMIN_WORD_FILTER_FOR_POST_UPDATE_LABEL'),
                    name: 'FORBIDDEN_WORDS_FOR_POST_UPDATE',
                    value: t('ADMIN_FORBIDDEN_WORDS_FOR_POST_UPDATE'),
                    type: 'long_text'
                }
            ]
        },
        {
            title: 'Filtres du classement',
            needed: 'HAS_TA',
            settings: [
                {
                    label: 'Activer le classement depuis le début',
                    name: 'HAS_RANK_ALL',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Activer le classement sur le mois courant',
                    name: 'HAS_RANK_CURRENT_MONTH',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Activer le classement sur le trimestre courant',
                    name: 'HAS_RANK_CURRENT_QUARTER',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: "Activer le classement sur l'année courante",
                    name: 'HAS_RANK_CURRENT_YEAR',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Activer le classement sur une période personnalisée',
                    name: 'HAS_RANK_CURRENT_CUSTOM',
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: 'Date de début',
                    name: 'HAS_RANK_CURRENT_CUSTOM_START_DATE',
                    needed: 'HAS_RANK_CURRENT_CUSTOM',
                    type: 'datetime',
                    format: ''
                },
                {
                    label: 'Date de fin',
                    name: 'HAS_RANK_CURRENT_CUSTOM_END_DATE',
                    needed: 'HAS_RANK_CURRENT_CUSTOM',
                    type: 'datetime',
                    format: ''
                },
                {
                    label: 'Personnalisation de la période du classement global',
                    name: 'GLOBAL_RANK_FILTER',
                    value: 'ALL',
                    type: 'select',
                    choices: [
                        {
                            label: 'Depuis le début',
                            value: 'ALL'
                        },
                        {
                            label: "Sur l'année courante",
                            value: 'YEAR'
                        },
                        {
                            label: 'Sur le trimestre courant',
                            value: 'QUARTER'
                        },
                        {
                            label: 'Sur le mois courant',
                            value: 'MONTH'
                        },
                        {
                            label: 'Sur la période personnalisée',
                            value: 'CUSTOM'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Options utilisateurs',
            manageableOnly: true,
            settings: [
                // {
                //     label: t('APP_PARAMETERS_HAS_ALLOW_NOTIFICATIONS'),
                //     name: 'HAS_ALLOW_NOTIFICATIONS',
                //     manageableOnly: true,
                //     value: 1,
                //     choices: [
                //         {
                //             label: t('DISABLE'),
                //             value: 0,
                //         },
                //         {
                //             label: t('ENABLE'),
                //             value: 1,
                //         },
                //     ]
                // },
                {
                    label: t('APP_PARAMETERS_HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS'),
                    name: 'HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS',
                    manageableOnly: true,
                    value: 1,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS_ON_MY_POSTS'),
                    name: 'HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS_ON_MY_POSTS',
                    manageableOnly: true,
                    value: 1,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_ALLOW_NOTIFICATIONS_ON_LIKES_ON_MY_POSTS'),
                    name: 'HAS_ALLOW_NOTIFICATIONS_ON_LIKES_ON_MY_POSTS',
                    manageableOnly: true,
                    value: 1,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ACTIVITY_REPORT_BY_NOTIFICATION_PERIOD'),
                    name: 'ACTIVITY_REPORT_BY_NOTIFICATION_PERIOD',
                    needed: 'HAS_ACTIVITY_REPORT',
                    manageableOnly: true,
                    value: 'DAY',
                    type: 'select',
                    choices: [
                        {
                            label: 'Jamais',
                            value: 'NEVER'
                        },
                        {
                            label: 'Chaque jour',
                            value: 'DAY'
                        },
                        {
                            label: 'Chaque semaine',
                            value: 'WEEK'
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_ACTIVITY_REPORT_BY_EMAIL_PERIOD'),
                    name: 'ACTIVITY_REPORT_BY_EMAIL_PERIOD',
                    needed: 'HAS_ACTIVITY_REPORT_BY_EMAIL',
                    manageableOnly: true,
                    value: 'NEVER',
                    type: 'select',
                    choices: [
                        {
                            label: 'Jamais',
                            value: 'NEVER'
                        },
                        {
                            label: 'Chaque jour',
                            value: 'DAY'
                        },
                        {
                            label: 'Chaque semaine',
                            value: 'WEEK'
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_DO_NOT_DISTURB'),
                    name: 'HAS_DO_NOT_DISTURB',
                    manageableOnly: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_HAS_DO_NOT_DISTURB_PRIVATE_MESSAGE'),
                    name: 'HAS_DO_NOT_DISTURB_PRIVATE_MESSAGE',
                    conditionalDisplay: 'HAS_DO_NOT_DISTURB',
                    manageableOnly: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                },
                {
                    label: t('APP_PARAMETERS_AUTO_HIDE_EDITOR_OPTIONS'),
                    name: 'HAS_AUTO_HIDE_EDITOR_OPTIONS',
                    manageableOnly: true,
                    value: 0,
                    choices: [
                        {
                            label: t('DISABLE'),
                            value: 0
                        },
                        {
                            label: t('ENABLE'),
                            value: 1
                        }
                    ]
                }
            ]
        }
    ];

    return {
        settings
    };
};

export default useAppSettings;
