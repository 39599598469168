import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useUserAppSettingService from 'api/useUserAppSettingService';
import useAppSettings from 'hooks/useAppSettings';
import { find } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Select from './Preference/Select';
import Checkbox from './Preference/Checkbox';

const SECTIONS = [
    {
        title: 'PROFILE_PREFERENCES_SECTION_NOTIFICATIONS',
        settings: [
            'HAS_ALLOW_NOTIFICATIONS',
            'HAS_DO_NOT_DISTURB',
            'HAS_DO_NOT_DISTURB_PRIVATE_MESSAGE'
        ]
    },
    {
        title: 'PROFILE_PREFERENCES_SECTION_WHEN_POSTING',
        settings: [
            'HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS_ON_MY_POSTS',
            'HAS_ALLOW_NOTIFICATIONS_ON_LIKES_ON_MY_POSTS'
        ]
    },
    {
        title: 'PROFILE_PREFERENCES_SECTION_WHEN_COMMENTING_OR_LIKING',
        settings: ['HAS_ALLOW_NOTIFICATIONS_ON_COMMENTS', 'HAS_ALLOW_NOTIFICATIONS_ON_LIKES']
    },
    {
        title: 'PROFILE_PREFERENCES_SECTION_ACTIVITY_REPORT',
        settings: ['ACTIVITY_REPORT_BY_NOTIFICATION_PERIOD', 'ACTIVITY_REPORT_BY_EMAIL_PERIOD']
    },
    {
        title: 'PROFILE_PREFERENCES_SECTION_OTHERS'
    }
];

const Preferences = () => {
    const { settings: appSettings } = useAppSettings();
    const { t } = useTranslation();
    const { getSettingsUserOverride, getUserTextConfigs, getSettingsWithoutUserOverride } =
        useUserAppSettingService();

    const [settings, setSettings] = useState([]);
    const [defaultConfigs, setDefaultConfigs] = useState([]);

    const { data } = useQuery({
        queryKey: ['user_preferences'],
        queryFn: () => {
            return Promise.all([
                getSettingsUserOverride(),
                getUserTextConfigs(),
                getSettingsWithoutUserOverride()
            ]);
        }
    });

    useEffect(() => {
        if (data) {
            const userConfigs = data[0];
            const userTextConfigs = data[1];
            const defaultConfigs = data[2];
            setDefaultConfigs(data[2]);

            let tmpSettings = appSettings
                .map((settingGroup) => {
                    return settingGroup.settings.filter((setting) => {
                        if (setting.manageable) {
                            let defaultConfig = find(defaultConfigs, (config) => {
                                return config.name === setting.name;
                            });

                            if (defaultConfig && defaultConfig.value) {
                                setting.value = defaultConfig.value;

                                return true;
                            }
                        }

                        return setting.manageableOnly;
                    });
                })
                .filter((settings) => {
                    return settings.length > 0;
                });

            tmpSettings = [].concat(...tmpSettings);
            tmpSettings.forEach((setting) => {
                let userConfig = find(userConfigs, (config) => {
                    return config.name === setting.name;
                });

                if (userConfig) {
                    setting.value = userConfig.value;
                }

                let userTextConfig = find(userTextConfigs, (config) => {
                    return config.name === setting.name;
                });

                if (userTextConfig) {
                    setting.value = userTextConfig.value;
                }
            });

            setSettings(tmpSettings);
        }
    }, [data]);

    const getSettingsForSection = (section) => {
        if (!section.settings) {
            return settings.filter((setting) => {
                return !SECTIONS.some(({ settings = null }) => {
                    return settings && settings.includes(setting.name);
                });
            });
        }

        return settings.filter((setting) => {
            return section.settings.includes(setting.name) && isNeeded(setting);
        });
    };

    const isNeeded = (setting) => {
        if (setting.needed) {
            return defaultConfigs.some((settingChild) => {
                if (['integer', 'select', 'long_text'].indexOf(settingChild.type) !== -1) {
                    return false;
                }

                return settingChild.name === setting.needed && !!settingChild.value;
            });
        }

        return true;
    };

    const isDisplayed = (setting) => {
        if (!setting.conditionalDisplay) {
            return true;
        }
        return getSettingValueByName(setting.conditionalDisplay);
    };

    const getSettingValueByName = (name) => {
        let settingSelected = find(settings, (setting) => {
            return setting.name === name;
        });
        return settingSelected ? settingSelected.value : false;
    };

    return (
        <div className={'preferences-wrapper'}>
            {settings && settings.length > 0 && defaultConfigs && defaultConfigs.length > 0 && (
                <div className="preferences">
                    <div className="sections">
                        {SECTIONS.map((section, sectionIndex) => {
                            return (
                                <div className="section" key={section.title}>
                                    <div className="title">{t(section.title)}</div>
                                    <div className="settings">
                                        {getSettingsForSection(section).map(
                                            (setting, settingIndex) => {
                                                if (!isDisplayed(setting)) return;
                                                const input =
                                                    setting.type === 'select' ? (
                                                        <Select
                                                            setting={setting}
                                                            sectionIndex={sectionIndex}
                                                            settingIndex={settingIndex}
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            setting={setting}
                                                            sectionIndex={sectionIndex}
                                                            settingIndex={settingIndex}
                                                        />
                                                    );
                                                return (
                                                    <div
                                                        className="setting"
                                                        key={`${section.title}_${settingIndex}`}
                                                    >
                                                        {input}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Preferences;
