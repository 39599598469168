import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import useViewport from 'hooks/useViewport';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTreeContext } from '../../../providers/TreeProvider';
import { cloneDeep } from 'lodash-es';
import useUtils from 'hooks/useUtils';
import Item from './partials/item';

const TreeModalMenuContent = ({ isSidebar }) => {
    const { hasRole } = useRight();
    const { t } = useTranslation();
    const viewport = useViewport();
    const [activeTab, setActiveTab] = useState('feeds');
    const { tree } = useTreeContext();
    const { orderByPosition, removeEmptyTopLevelCategories } = useUtils();

    const orderedTree = useMemo(() => {
        if (!tree) {
            return [];
        }
        return orderByPosition(removeEmptyTopLevelCategories(cloneDeep(tree)));
    }, [tree]);

    const isActiveTab = useCallback(
        (tab) => {
            return activeTab === tab;
        },
        [activeTab]
    );

    return (
        <>
            {viewport.xs && !isSidebar && hasRole('SHOW_TAGS') && (
                <div className="tabs">
                    <div
                        className={`tab ${isActiveTab('feeds') ? 'active' : ''}`}
                        onClick={() => setActiveTab('feeds')}>
                        {t('DATAFEEDS')}
                    </div>

                    <div
                        className={`tab ${isActiveTab('tags') ? 'active' : ''}`}
                        onClick={() => setActiveTab('tags')}>
                        {t('TAGS')}
                    </div>
                </div>
            )}

            {(isActiveTab('feeds') || viewport.gtXs || isSidebar || !hasRole('SHOW_TAGS')) && (
                <div className="tree-wrapper">
                    {orderedTree &&
                        orderedTree.map((item, index) => {
                            return (
                                <div className="items" key={`item_${index}`}>
                                    <div className="item">
                                        <Item item={item} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}

            {isActiveTab('tags') && !isSidebar && (
                <div className="tags-wrapper">
                    <tags tags="tags" is-light="true" is-sidebar="true"></tags>
                </div>
            )}
        </>
    );
};

export default TreeModalMenuContent;
