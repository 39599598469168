import React from 'react';

const Image = ({ input }) => {
    return (
        <div className="image">
            <img src={input.workspace_form_input.label} alt="Image" />
        </div>
    );
};

export default Image;
