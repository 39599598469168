const { useHttp } = require('api/useHttp');

const useRecruitingCampaignService = () => {
    const axios = useHttp();

    const getAllRecruitingCampaign = (status, page, showAll) => {
        return axios
            .get(
                `/dashboard/recruitment/campaigns?status=${status}&page=${page}&showAll=${showAll}`
            )
            .then((response) => response.data);
    };

    return { getAllRecruitingCampaign };
};

export default useRecruitingCampaignService;
