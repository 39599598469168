import { useHttp } from 'api/useHttp';
import { useConfig } from 'providers/ConfigProvider';

const useBlogArticleService = () => {
    const axios = useHttp();
    const { locale } = useConfig();

    const getPublishedBlogArticles = (isForConfig = false, limit = null, category = null) => {
        return axios
            .get(`/blog/all/articles/publish`, {
                params: {
                    isForConfig,
                    limit,
                    category
                }
            })
            .then((response) => {
                return response.data;
            });
    };

    const getBlogArticles = (params) => {
        return axios
            .get(`/blog/articles`, {
                params
            })
            .then((response) => {
                return response.data;
            });
    };

    const getPublicBlogArticles = (params) => {
        return axios
            .get(`/blog/public/articles`, {
                params
            })
            .then((response) => {
                return response.data;
            });
    };

    const getBlogArticle = (id, slug) => {
        return axios.get(`/blog/articles/${id}/${slug}`).then((response) => {
            return response.data;
        });
    };

    const getPublicBlogArticle = (id, slug) => {
        return axios.get(`/blog/public/articles/${id}/${slug}`).then((response) => {
            return response.data;
        });
    };

    const getRelatedBlogArticles = (id) => {
        return axios.get(`/blog/suggested/articles/${id}`).then((response) => {
            return response.data;
        });
    };

    const getPublicRelatedBlogArticles = (id) => {
        return axios.get(`/blog/public/suggested/articles/${id}`).then((response) => {
            return response.data;
        });
    };

    const searchArticles = (params) => {
        const {
            query = null,
            category = null,
            onlyTitle = false,
            exactPhrase = false,
            isForAdmin = false,
            page = 1
        } = params;

        if (!query) {
            return Promise.resolve([]);
        }

        return axios
            .get('/blog/articles/search', {
                params: {
                    query,
                    category,
                    onlyTitle,
                    exactPhrase,
                    isForAdmin,
                    page
                }
            })
            .then((response) => response.data);
    };

    return {
        getPublishedBlogArticles,
        getBlogArticles,
        getPublicBlogArticles,
        getBlogArticle,
        getPublicBlogArticle,
        getRelatedBlogArticles,
        getPublicRelatedBlogArticles,
        searchArticles
    };
};

export default useBlogArticleService;
