import { useHttp } from 'api/useHttp';

const useHomeScreenService = () => {
    const axios = useHttp();

    const getAllHomeScreens = () => {
        return axios.get('/all/helpdesk/homescreen/articles').then((response) => {
            return response.data;
        });
    };

    return {
        getAllHomeScreens
    };
};

export default useHomeScreenService;
