import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import { useAuth } from 'providers/AuthContext';
import useRight from '../../../hooks/useRight';
import useUser from '../../../hooks/useUser';
import { useChannels } from '../../../pages/Auth/providers/ChannelsProvider';
import useViewport from '../../../hooks/useViewport';
import { useConfig } from '../../../providers/ConfigProvider';
import { useNotificationsContext } from '../../../pages/Auth/providers/NotificationsProvider';

const useNavbarContext = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const Right = useRight();
    const User = useUser();
    const { channels } = useChannels();
    const Notifications = useNotificationsContext();
    const viewport = useViewport();
    const config = useConfig();
    const { user } = useAuth();

    return {
        Right,
        router,
        User,
        user,
        viewport,
        t,
        channels,
        config,
        Notifications,
        isActive: (stateNames) => {
            return stateNames.some((stateName) => {
                return router.stateService.includes(stateName);
            });
        },
        getTotalUnreadMessages() {
            return channels.reduce((acc, channel) => {
                if (!channel.unread || channel.unread === '0') {
                    return acc;
                }

                return acc + 1;
            }, 0);
        }
    };
};

export default useNavbarContext;
