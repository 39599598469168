import React, { useCallback, useRef, useState } from 'react';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import {
    isVideoAttachment,
    isAudioAttachment,
    getAllWithoutImagesVideosAndAudios,
    getAllVideosAndAudios
} from 'helpers/attachment';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import Username from 'components/Username/Username';
import useDate from 'hooks/useDate';
import AbstractField from '../CustomRender/AbstractField';
import PhotoAlbum from 'react-photo-album';
import useAttachment from 'hooks/useAttachment';
import { usePost } from '../../providers/PostProvider';
import useContent from 'hooks/useContent';
import { useTranslation } from 'react-i18next';
import Link from '../../../Link/Link';
import useRight from 'hooks/useRight';
import PostArticle from './PostArticle';
import Campaign from '../../../Campaign/Campaign';
import usePostContentPagination from '../../hooks/usePostContentPagination';
import useTree from 'hooks/useTree';
import MissionPreview from 'components/MissionPreview/MissionPreview';

const PostContent = () => {
    const { t } = useTranslation();
    const [readMoreText, setReadMoreText] = useState(
        `${t('TA_POST_READ_MORE')} <i class="fa fa-caret-down"></i>`
    );
    const [isReadMore, setIsReadMore] = useState(false);
    const textContainerRef = useRef();
    const textRef = useRef();
    const tabContainerRef = useRef();

    const {
        post,
        isReported,
        isUserReported,
        hideAll,
        downloadEvent,
        getEventDate,
        allowToEdit,
        isPostPreview,
        getFieldsForPage,
        workspaceHasCustomForm,
        isAllowToSeeInput,
        hasEvent,
        getAttachmentUrl,
        hasImages,
        attachmentPhotoImages,
        showCarousel,
        isSecurityLevelReadonly,
        downloadAllAttachments,
        downloadAttachment,
        allowViewFile,
        confirmPostConsultation,
        editPost,
        getPages
    } = usePost();

    const {
        hasPages,
        isCurrentPage,
        goToPage,
        hasPreviousPage,
        hasNextPage,
        goToPreviousPage,
        goToNextPage
    } = usePostContentPagination(post, tabContainerRef);

    const { format: dateFormat } = useDate();
    const { formatContent } = useContent();
    const { attachmentIcon } = useAttachmentIcon();
    const { getVideoState, getVideoFiles, getVideoType } = useAttachment();
    const { hasRole } = useRight();
    const { isView } = useTree();

    const hasReadMore = useCallback(() => {
        if (!textContainerRef.current) {
            return;
        }
        const container = textContainerRef.current;

        if (!container) {
            return;
        }

        if (post && !post.content) {
            container.style.height = '0px';
            return false;
        }

        let textOffsetHeight = getTextOffsetHeight();

        if (textOffsetHeight > 210 || textOffsetHeight === 0) {
            return true;
        } else {
            container.style.height = textOffsetHeight + 'px';
            return false;
        }
    }, [textContainerRef, isReadMore]);

    const getTextOffsetHeight = () => {
        const postText = textRef.current;

        if (postText) {
            return postText.offsetHeight;
        }

        return null;
    };

    const toggleReadMore = () => {
        setReadMore(!isReadMore);

        if (isView('posts')) {
            //setConsultationConfirmed(false);
        }
    };

    const canSeeBlogArticle = () => {
        return hasRole('HAS_BLOG') || hasRole('ROLE_ADMIN_BLOG');
    };

    const setReadMore = (state) => {
        if (state) {
            setReadMoreText(`${t('POST_DIALOG_CLOSE')} <i class="fa fa-caret-up"></i>`);
        } else {
            setReadMoreText(`${t('TA_POST_READ_MORE')} <i class="fa fa-caret-down"></i>`);
        }

        setIsReadMore(state);
    };

    const getStyle = () => {
        if (!isReadMore && !post.displayFull) {
            return {
                height: `210px`
            };
        }

        return {
            height: `${getTextOffsetHeight()}px`
        };
    };

    return (
        <>
            {!hideAll &&
                post.workspace_form_input_values.length === 0 &&
                !isReported() &&
                !isUserReported() && (
                    <div className="post__content">
                        <div className="description">
                            <div
                                className="text-container"
                                style={getStyle()}
                                ref={textContainerRef}>
                                <div
                                    className="text"
                                    ref={textRef}
                                    dangerouslySetInnerHTML={{
                                        __html: formatContent(post.content)
                                    }}></div>
                                {hasReadMore() && !post.displayFull && (
                                    <div className={`gradient ${isReadMore ? 'out' : ''}`}></div>
                                )}
                            </div>

                            {hasReadMore() && !post.displayFull && (
                                <div className="toggle-read-more text-center">
                                    <button
                                        className="button dark-grey"
                                        onClick={toggleReadMore}
                                        dangerouslySetInnerHTML={{ __html: readMoreText }}></button>
                                </div>
                            )}
                        </div>

                        {post.campaign && (
                            <div className="attached-campaign">
                                <Campaign campaignItem={post.campaign} forceLight={true} />
                            </div>
                        )}

                        {post.one_mission && (
                            <div className="attached-campaign">
                                <MissionPreview mission={post.one_mission} />
                            </div>
                        )}

                        {hasEvent && !isReported() && (
                            <div className="post__event">
                                <div className="post__event__main">
                                    <div className="post__event__main__action">
                                        <button className="btn" onClick={downloadEvent}>
                                            <i className="icon-download-bottom"></i>
                                            <br />
                                            {t('POST_EVENT_DOWNLOAD')}
                                        </button>
                                    </div>
                                    <div className="post__event__main__info">
                                        <div className="post__event__main__info__title">
                                            {post.event.title}
                                        </div>
                                        <div className="post__event__main__info__date">
                                            {getEventDate()}
                                        </div>
                                        <div
                                            className="ng-quill-text"
                                            dangerouslySetInnerHTML={{
                                                __html: formatContent(post.event.comment)
                                            }}></div>
                                    </div>
                                </div>
                                <div className="post__event__title">{t('POST_HAS_EVENT')}</div>
                            </div>
                        )}

                        {post.blog_article && !isReported() && canSeeBlogArticle() && (
                            <div className="attached-campaign">
                                <PostArticle article={post.blog_article} />
                            </div>
                        )}

                        {post.attachments.length > 0 && (
                            <div onClick={confirmPostConsultation}>
                                {getAllVideosAndAudios(post.attachments).map(
                                    (attachment, index) => (
                                        <div key={index} className="post__content__video">
                                            {isVideoAttachment(attachment) && (
                                                <div className="video-player-container">
                                                    <VideoPlayer
                                                        src={getAttachmentUrl(attachment)}
                                                        type={getVideoType(attachment.type)}
                                                        poster="attachment"
                                                        files={getVideoFiles(attachment)}
                                                        videoState={getVideoState(attachment)}
                                                        readOnly={attachment.read_only}
                                                        securityLevel={post.security_level}
                                                    />
                                                </div>
                                            )}

                                            {isAudioAttachment(attachment) && (
                                                <div className="video-player-container">
                                                    <VideoPlayer
                                                        src={getAttachmentUrl(attachment)}
                                                        type="attachment.type"
                                                        isAudio={true}
                                                        readOnly={attachment.read_only}
                                                        securityLevel={post.security_level}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                )}

                                {hasImages && !isPostPreview && (
                                    <div className="post__content__grid">
                                        {attachmentPhotoImages.length === 1 && (
                                            <div
                                                className="single"
                                                onClick={() => showCarousel(post.images, 0)}>
                                                <img
                                                    src={attachmentPhotoImages[0].original_url}
                                                    alt="attachment"
                                                />
                                            </div>
                                        )}

                                        {attachmentPhotoImages.length > 1 && (
                                            <PhotoAlbum
                                                photos={attachmentPhotoImages}
                                                layout={'rows'}
                                                spacing={2}
                                                onClick={({ index }) => {
                                                    showCarousel(attachmentPhotoImages, index);
                                                }}
                                            />
                                        )}

                                        {!isSecurityLevelReadonly() &&
                                            !post.workspace.disable_dl_all_attachments && (
                                                <div className="download">
                                                    {post.attachments.length > 1 ? (
                                                        <button
                                                            onClick={() =>
                                                                downloadAllAttachments(post.id)
                                                            }>
                                                            {t('POST_DOWNLOAD').toUpperCase()}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() =>
                                                                downloadAttachment(
                                                                    post.attachments[0]
                                                                )
                                                            }>
                                                            {t('POST_DOWNLOAD').toUpperCase()}
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                )}

                                {getAllWithoutImagesVideosAndAudios(post.attachments).map(
                                    (attachment, index) => (
                                        <div key={index} className="post__content__attachment">
                                            <div className="post__content__attachment__info">
                                                <div className="post__content__attachment__icon">
                                                    <button
                                                        onClick={() =>
                                                            downloadAttachment(attachment)
                                                        }>
                                                        <span
                                                            className={attachmentIcon(
                                                                attachment,
                                                                'class'
                                                            )}
                                                            style={{
                                                                color: attachmentIcon(
                                                                    attachment,
                                                                    'color'
                                                                )
                                                            }}></span>
                                                    </button>
                                                </div>
                                                <div className="post__content__attachment__info__user">
                                                    <span className="doc_name">
                                                        <button
                                                            onClick={() =>
                                                                downloadAttachment(attachment)
                                                            }>
                                                            {attachment.name}
                                                        </button>
                                                    </span>
                                                    <span className="post__content__attachment__info__user__name">
                                                        <Username user={post.user} light={true} />
                                                    </span>
                                                    <span className="post__content__attachment__info__user__date">
                                                        {dateFormat(attachment.created_at)}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="actions">
                                                {allowViewFile(attachment.type) && (
                                                    <button
                                                        className="button grey-border small bold rounded"
                                                        onClick={() => {
                                                            //PreviewDocument.show({ url: getAttachmentUrl(attachment, true) })
                                                        }}>
                                                        {t('POST_VIEW').toUpperCase()}
                                                    </button>
                                                )}

                                                {!isSecurityLevelReadonly() && (
                                                    <button
                                                        className="button grey-border small bold rounded"
                                                        onClick={() =>
                                                            downloadAttachment(attachment)
                                                        }>
                                                        {t('POST_DOWNLOAD').toUpperCase()}
                                                    </button>
                                                )}

                                                {isSecurityLevelReadonly() && (
                                                    <button
                                                        className="button grey-border small bold rounded"
                                                        onClick={() => {
                                                            //SecureDocument.show({ document: attachment })
                                                        }}>
                                                        {t('POST_VIEW').toUpperCase()}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {post.link && (
                            <>
                                <Link item={post.link} user={post.user} />
                            </>
                        )}

                        {allowToEdit() && !isPostPreview && (
                            <button className="button edit" onClick={editPost}>
                                {t('POST_EDIT')}
                            </button>
                        )}
                    </div>
                )}

            {!hideAll &&
                post.workspace_form_input_values.length > 0 &&
                !isReported() &&
                !isUserReported() && (
                    <div className="post__content" ref={tabContainerRef}>
                        {hasPages() && (
                            <div className="tabs">
                                {getPages().map((page, pageIndex) => (
                                    <div
                                        key={pageIndex}
                                        className={`tab ${isCurrentPage(pageIndex + 1) ? 'active' : ''}`}
                                        data-page={pageIndex + 1}
                                        onClick={() => goToPage(pageIndex + 1)}>
                                        {page.label_render}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className={`tabs-content ${hasPages() ? 'has-pages' : ''}`}>
                            {getPages().map((page, pageIndex) => (
                                <div
                                    key={pageIndex}
                                    className="tab-content"
                                    style={{
                                        display: isCurrentPage(pageIndex + 1) ? 'block' : 'none'
                                    }}>
                                    <div className="fields">
                                        {getFieldsForPage(pageIndex + 1).map(
                                            (input, index) =>
                                                isAllowToSeeInput(input) && (
                                                    <AbstractField
                                                        key={index}
                                                        className="render_element"
                                                        input={input}
                                                        type={input.workspace_form_input.type}
                                                        post={post}
                                                        isPostPreview={isPostPreview}
                                                    />
                                                )
                                        )}

                                        {post.link && (
                                            <>
                                                <Link item={post.link} user={post.user} />
                                            </>
                                        )}

                                        {getFieldsForPage(pageIndex + 1).length === 0 && (
                                            <div className="render_element">
                                                <div className="render_label">
                                                    {t('POST_CONTENT_EMPTY_PAGE')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {workspaceHasCustomForm() && hasPages() && (
                            <div className="post__content__actions">
                                <div className="group">
                                    <button
                                        className="btn padded text-color"
                                        disabled={!hasPreviousPage()}
                                        onClick={goToPreviousPage}>
                                        {t('EDITOR_PREVIOUS_TAB')}
                                    </button>
                                    <button
                                        className="btn padded text-color"
                                        disabled={!hasNextPage()}
                                        onClick={goToNextPage}>
                                        {t('EDITOR_NEXT_TAB')}
                                    </button>
                                </div>
                            </div>
                        )}

                        {post.campaign && (
                            <div className="attached-campaign">
                                <Campaign campaignItem={post.campaign} forceLight={true} />
                            </div>
                        )}

                        {post.one_mission && (
                            <div className="attached-campaign">
                                <MissionPreview mission={post.one_mission} />
                            </div>
                        )}

                        {hasEvent && !isReported() && (
                            <div className="post__event" style={{ marginTop: 10 }}>
                                <div className="post__event__main">
                                    <div className="post__event__main__action">
                                        <button className="btn" onClick={downloadEvent}>
                                            <i className="icon-download-bottom"></i>
                                            <br />
                                            {t('POST_EVENT_DOWNLOAD')}
                                        </button>
                                    </div>
                                    <div className="post__event__main__info">
                                        <div className="post__event__main__info__title">
                                            {post.event.title}
                                        </div>
                                        <div className="post__event__main__info__date">
                                            {getEventDate()}
                                        </div>
                                        <div
                                            className="ng-quill-text"
                                            dangerouslySetInnerHTML={{
                                                __html: formatContent(post.event.comment)
                                            }}></div>
                                    </div>
                                </div>
                                <div className="post__event__title">{t('POST_HAS_EVENT')}</div>
                            </div>
                        )}

                        {post.blog_article && !isReported() && canSeeBlogArticle() && (
                            <div className="attached-campaign">
                                <PostArticle article={post.blog_article} />
                            </div>
                        )}

                        {allowToEdit() && !isPostPreview && (
                            <button className="button edit" onClick={editPost}>
                                {t('POST_EDIT')}
                            </button>
                        )}
                    </div>
                )}
        </>
    );
};

export default PostContent;
