import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import dayjs from 'dayjs';
import VC from 'vanilla-calendar-pro';
import 'vanilla-calendar-pro/build/vanilla-calendar.min.css';
import { useConfig } from 'providers/ConfigProvider';
import usePostService from 'api/usePostService';
import CalendarClass from '../services/Calendar';
import { useAlert } from 'providers/AlertContext';
import { useTranslation } from 'react-i18next';
import { useTreeContext } from 'pages/Auth/providers/TreeProvider';
import useRight from 'hooks/useRight';
import useTreeHook from 'hooks/useTree';
import { useModal } from 'providers/ModalContext';
import { useQuery } from '@tanstack/react-query';

const VanillaCalendar = ({ config, calendarRef, ...attributes }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;
        calendarRef.current = new VC(ref.current, config);
    }, [ref, config]);

    useEffect(() => {
        if (!calendarRef.current) return;
        calendarRef.current.init();
    }, [calendarRef]);

    return <div {...attributes} className="calendar-widget" ref={ref}></div>;
};

const Calendar = ({ widget, options }) => {
    const { t } = useTranslation();
    const calendar = new CalendarClass();
    const { locale } = useConfig();
    const { showActionSheet } = useAlert();
    const { isAllowToRead, isAllowToWrite, isAllowToHalfRead } = useRight();
    const { tree } = useTreeContext();
    const { getOnlyWorkspace, goToWorkspace, goToPost } = useTreeHook();
    const { getPostsByWorkspaceAndDate } = usePostService();
    const { openModal } = useModal();
    const [selectedDate, setSelectedDate] = useState(null);
    const [today, setToday] = useState(new Date());
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const { getFieldValueForKey } = useWidgetComponent(widget, options);
    const calendarRef = useRef();

    const getPosts = () => {
        if (!tree) {
            return [];
        }
        const timestamp = Math.round(new Date(year, month).getTime() / 1000);

        const dataFrom = getFieldValueForKey('dataFrom');
        const categories = getFieldValueForKey('categories');
        const feeds = getFieldValueForKey('feeds');

        const filteredFeeds = getOnlyWorkspace(tree)
            .filter((feed) => {
                const workspace = feed?.workspace_data?.workspace;

                if (
                    !workspace ||
                    !workspace.has_calendar_view ||
                    (!isAllowToRead(workspace.id) &&
                        !isAllowToWrite(workspace.id) &&
                        !isAllowToHalfRead(workspace.id))
                ) {
                    return false;
                }

                const workspaceId = workspace.id;
                const categoryId = workspace.category.id;

                switch (dataFrom) {
                    case 'categories':
                        return categories.some((category) => category.value === categoryId);
                    case 'feeds':
                        return feeds.some((feed) => feed.value === workspaceId);
                    default:
                        return true;
                }
            })
            .map((feed) => feed.workspace_data.workspace.id);

        const promises = [];

        filteredFeeds.forEach((feed) => {
            if (!feed) {
                return;
            }

            const promise = getPostsByWorkspaceAndDate(feed, timestamp).then((posts) =>
                posts.map((post) => post.post)
            );

            promises.push(promise);
        });

        return Promise.allSettled(promises).then((posts) => {
            return processPosts(
                posts
                    .flat()
                    .map((r) => r.value)
                    .flat()
            );

            //this.processPosts(posts.flat());
        });
    };

    const { data: events, isLoading } = useQuery({
        queryFn: () => getPosts(),
        queryKey: ['calendar_widget', month, year, !!tree]
    });

    const processPosts = (posts) => {
        return posts.map((post) => {
            const startsAt = calendar.getEventDate(post);
            const endsAt = calendar.getEventEndDate(post);

            return {
                title: calendar.getEventTitle(post),
                color: calendar.getEventColorAsHex(post),
                startsAt,
                endsAt,
                isMultiDay: getFormattedDate(startsAt) !== getFormattedDate(endsAt),
                post
            };
        });
    };

    useEffect(() => {
        if (events) {
            calendarRef.current.popups = events.reduce((acc, event) => {
                const startDate = getFormattedDate(event.startsAt);
                const endDate = getFormattedDate(event.endsAt);

                if (endDate !== startDate) {
                    const range = {};
                    const rangeDiff = dayjs(endDate).diff(dayjs(startDate), 'days');

                    for (let i = 0; i <= rangeDiff; i++) {
                        const date = getFormattedDate(dayjs(startDate).add(i, 'days'));

                        range[date] = {
                            modifier: 'has-event'
                        };
                    }

                    return {
                        ...acc,
                        ...range
                    };
                }

                return {
                    ...acc,
                    [`${startDate}`]: {
                        modifier: 'has-event'
                    }
                };
            }, {});

            calendarRef.current.update();
        }
    }, [events]);

    const getSelectedDate = () => {
        if (!selectedDate && month === today.getMonth() && year === today.getFullYear()) {
            return today;
        }

        return selectedDate;
    };

    const hasEvents = useCallback(() => {
        if (!events || !events.length) {
            return false;
        }

        return events.some((event) => isEventOnSelectedDate(event));
    }, [events, selectedDate, month, year]);

    const getEvents = useCallback(() => {
        if (!events || !events.length) {
            return [];
        }

        return events.filter((event) => isEventOnSelectedDate(event));
    }, [events, selectedDate, month, year]);

    const getFormattedSelectedDate = (format = 'YYYY-MM-DD') => {
        return getFormattedDate(getSelectedDate(), format);
    };

    const getFormattedDate = (date, format = 'YYYY-MM-DD') => {
        return dayjs(date).format(format);
    };

    const isEventOnSelectedDate = (event, selectedDate = getFormattedSelectedDate()) => {
        const startDate = getFormattedDate(event.startsAt);
        const endDate = getFormattedDate(event.endsAt);

        return endDate !== startDate
            ? dayjs(selectedDate).isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]')
            : startDate === selectedDate;
    };

    const previewPost = (post) => {
        openModal('preview-post', { post });
    };

    const showActions = (event) => {
        showActionSheet({
            title: "Options d'événements",
            text: 'Choisissez une option ci-dessous',
            buttons: [
                {
                    label: t('POST_SHOW'),
                    callback: () => {
                        previewPost(event.post);
                    }
                },
                {
                    label: t('VIEW_RELATED_POST'),
                    callback: () => {
                        goToPost(event.post);
                    }
                },
                {
                    label: t('VIEW_RELATED_WORKSPACE'),
                    callback: () => {
                        console.log('VIEW_RELATED_WORKSPACE');
                        goToWorkspace({
                            workspace: event.post.workspace
                        });
                    }
                }
            ]
        });
    };

    const CALENDAR_CONFIGS = useMemo(() => {
        return {
            actions: {
                clickDay: (e, self) => {
                    const dayBtnEl = e.target.closest(`.${self.CSSClasses.dayBtn}`);

                    if (!dayBtnEl || !dayBtnEl.dataset.calendarDay) {
                        return;
                    }

                    self.selectedDates = [dayBtnEl.dataset.calendarDay];

                    if (getFormattedSelectedDate() === dayBtnEl.dataset.calendarDay) {
                        return;
                    }

                    setSelectedDate(new Date(dayBtnEl.dataset.calendarDay));

                    const selectedMonth = new Date(dayBtnEl.dataset.calendarDay).getMonth();
                    const selectedYear = new Date(dayBtnEl.dataset.calendarDay).getFullYear();

                    if (selectedMonth !== month || selectedYear !== year) {
                        setMonth(new Date(dayBtnEl.dataset.calendarDay).getMonth());
                        setYear(new Date(dayBtnEl.dataset.calendarDay).getFullYear());
                    }
                },
                clickArrow: (e, self) => {
                    self.selectedDates = [];

                    if (
                        self.selectedMonth === today.getMonth() &&
                        self.selectedYear === today.getFullYear()
                    ) {
                        self.selectedDates = [getFormattedDate(today)];
                    }

                    setSelectedDate(null);
                    setMonth(self.selectedMonth);
                    setYear(self.selectedYear);
                }
            },
            settings: {
                lang: locale,
                date: {
                    today
                },
                // range: {
                //     disableWeekday: [0, 6],
                // },
                selected: {
                    dates: [getFormattedSelectedDate()]
                },
                selection: {
                    month: 'only-arrows',
                    year: 'only-arrows'
                },
                visibility: {
                    theme: 'light',
                    weekend: false
                }
            }
        };
    }, []);

    return (
        <div className="wrapper">
            <VanillaCalendar config={CALENDAR_CONFIGS} calendarRef={calendarRef} />

            <div className="calendar-events">
                {isLoading && (
                    <div className="is-loading">
                        <div className="icon"></div>
                    </div>
                )}

                {!isLoading && getSelectedDate() && (
                    <div className="is-loaded">
                        {!hasEvents() && (
                            <div className="no-events">
                                <div className="icon">
                                    <i className="icon-information" aria-hidden="true"></i>
                                </div>

                                <div className="text">
                                    Aucun événement {getFormattedSelectedDate('ddd DD')}
                                </div>
                            </div>
                        )}

                        {hasEvents() && (
                            <div className="has-events">
                                <div className="items">
                                    {getEvents().map((event, index) => {
                                        return (
                                            <div className="item" key={`event_${index}`}>
                                                <div
                                                    className="border"
                                                    style={{
                                                        backgroundColor: event.color
                                                    }}></div>

                                                <div
                                                    className="meta"
                                                    onClick={() => previewPost(event.post)}>
                                                    <div className="title">{event.title}</div>

                                                    <div className="duration">
                                                        <div className="icon">
                                                            <i
                                                                className="icon-time-clock-circle"
                                                                aria-hidden="true"></i>
                                                        </div>

                                                        {!event.isMultiDay && (
                                                            <div className="date">
                                                                {getFormattedDate(
                                                                    event.startsAt,
                                                                    'ddd DD MMM'
                                                                )}
                                                            </div>
                                                        )}

                                                        {event.isMultiDay && (
                                                            <div className="date">
                                                                du{' '}
                                                                {getFormattedDate(
                                                                    event.startsAt,
                                                                    'ddd DD'
                                                                )}{' '}
                                                                au{' '}
                                                                {getFormattedDate(
                                                                    event.endsAt,
                                                                    'ddd DD MMM'
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="actions">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            showActions(event);
                                                        }}>
                                                        <i
                                                            className="icon-navigation-menu-horizontal"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Calendar;
