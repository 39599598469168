import React from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useTagContext } from 'pages/Auth/providers/TagProvider';
import { usePost } from '../../providers/PostProvider';
import useContent from 'hooks/useContent';

const PostDescription = () => {
    const { t } = useTranslation();
    const { allTags } = useTagContext();
    const { post, isAdminTagFeed, Posts, isPostOutdated, forceDefaultView } = usePost();
    const [hideAll, setHideAll] = React.useState(false);
    const { params } = useCurrentStateAndParams();
    const { formatContent } = useContent();

    const getStyleResume = () => {
        if (!allTags) {
            return;
        }

        let currentTag = null;

        if (params.tag) {
            currentTag = allTags.find((tag) => {
                return tag.id === parseInt(params.tag);
            });
        }

        if (!currentTag) {
            currentTag = allTags[0];
        }

        let color = Color(currentTag.color).rgb().array();

        return {
            borderColor: currentTag.color,
            backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`
        };
    };

    return (
        <>
            {post.resume && isAdminTagFeed() && (
                <div className="description">
                    <div className="text-container-resume" style={getStyleResume()}>
                        {post.image_resume && (
                            <img src={Posts.forgeImageResumeUrl(post.image_resume)} alt="resume" />
                        )}
                        <div
                            className="text-resume ng-quill-text"
                            dangerouslySetInnerHTML={{ __html: formatContent(post.resume) }}></div>
                    </div>
                    {!isPostOutdated() && (
                        <button className="showAll" onClick={() => setHideAll(!hideAll)}>
                            <span>{hideAll ? t('POST_SHOW') : t('POST_HIDE')}</span>
                        </button>
                    )}
                </div>
            )}

            {isPostOutdated() && !post.hideForSearch && (
                <div className="description">
                    <div className="text-container-resume outdated">
                        <div className="text-resume ng-quill-text">{t('POST_OUTDATED_TEXT')}</div>
                    </div>
                    {!forceDefaultView && (
                        <button className="showAll" onClick={() => setHideAll(!hideAll)}>
                            <span>{hideAll ? t('POST_SHOW') : t('POST_HIDE')}</span>
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

export default PostDescription;
