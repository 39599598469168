// TODO logout et deleteAccount
import React from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';

const ProfileSidebarActions = () => {
    const { t } = useTranslation();
    const { hasRole, isInternal } = useRight();

    return (
        <>
            <div className="action">
                <button className="btn outline warning" ng-click="$ctrl.logout()">
                    {t('TOP_LOGOUT')}
                </button>
            </div>

            {(!hasRole('HAS_RESTRICTED_PROFILE_EDITING') || !isInternal()) && (
                <div className="action">
                    <button className="btn outline warning" ng-click="$ctrl.deleteAccount()">
                        {t('MISC_DELETE')}
                    </button>
                </div>
            )}
        </>
    );
};

export default ProfileSidebarActions;
