import React, { useState, useEffect, useRef } from 'react';
import Plyr from 'plyr';
import { useDevice } from 'providers/DeviceContext';
import { Browser } from '@capacitor/browser';
import useAttachment from 'hooks/useAttachment';
import useUrl from 'hooks/useUrl';
import useRight from 'hooks/useRight';

const ScreenOrientation = require('@capawesome/capacitor-screen-orientation').ScreenOrientation;
const OrientationType = require('@capawesome/capacitor-screen-orientation').OrientationType;

const VideoPlayer = (props) => {
    const [error, setError] = useState(false);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { device, setIsFullScreen } = useDevice();
    const { hasRole } = useRight();
    const { getUrlWithToken } = useUrl();

    const { forgeAttachmentPoster, download } = useAttachment();

    const isReadOnly = () => {
        return !!props.readOnly || (props.securityLevel && props.securityLevel === 3);
    };

    const getPosterUrl = () => {
        return props.posterUrl ? props.posterUrl : forgeAttachmentPoster(props.poster);
    };

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }
        const controls = [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'settings',
            'fullscreen'
        ];

        if (!isReadOnly() || hasRole('ROLE_ADMIN')) {
            controls.push('download');
        }

        const listeners = {
            download: (event) => {
                event.preventDefault();

                if (device.isApp) {
                    getUrlWithToken(props.src).then((url) => {
                        Browser.open({ url });
                    });
                } else {
                    download(props.src, props.securityLevel);
                }
            }
        };

        const settings = ['captions', 'quality'];
        const quality = {
            default:
                navigator &&
                navigator.connection &&
                navigator.connection.downlink &&
                navigator.connection.downlink > 5
                    ? 1080
                    : 480,
            options: props.files ? props.files.map((file) => file.resolution.id) : [480, 720, 1080]
        };

        playerRef.current = new Plyr(videoRef.current, {
            controls,
            fullscreen: {
                enabled: true,
                fallback: true,
                iosNative: true
            },
            settings,
            quality,
            listeners,
            storage: false,
            urls: {
                download: window.location.href
            }
        });

        const loadCallbackHandler = () => {
            if (props.loadCallbackParam) {
                props.loadCallback(props.loadCallbackParam);
            } else {
                props.loadCallback();
            }
        };

        if (props.loadCallback) {
            playerRef.current.on('loadeddata', loadCallbackHandler);
        }

        const onErrorEventHandler = () => {
            setError(true);
        };

        const fullScreenEventHandler = (event) => {
            switch (event.type) {
                case 'enterfullscreen':
                    if (device.platform === 'android') {
                        ScreenOrientation.unlock();
                    } else {
                        ScreenOrientation.lock({ type: OrientationType.LANDSCAPE });
                    }
                    setIsFullScreen(true);
                    break;
                case 'exitfullscreen':
                    ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
                    setIsFullScreen(false);
                    break;
            }
        };

        playerRef.current.on('error', onErrorEventHandler);

        if (device.isApp) {
            playerRef.current.on('enterfullscreen', fullScreenEventHandler);
            playerRef.current.on('exitfullscreen', fullScreenEventHandler);
        }

        return () => {
            if (props.loadCallback) {
                playerRef.current.off('loadeddata', loadCallbackHandler);
            }
            playerRef.current.off('error', onErrorEventHandler);

            if (device.isApp) {
                playerRef.current.off('enterfullscreen', fullScreenEventHandler);
                playerRef.current.off('exitfullscreen', fullScreenEventHandler);
            }
        };
    }, [props, videoRef]);

    return (
        <div className="video-player-wrapper">
            <div className={props.isAudio ? 'audio-player-container' : 'video-player-container'}>
                {!props.isAudio ? (
                    <video
                        className="video-player"
                        controls
                        playsInline
                        data-poster={getPosterUrl()}
                        ref={videoRef}
                    >
                        {props.files ? (
                            props.files.map((file) => (
                                <source
                                    key={`video_source_${file.id}`}
                                    src={file.fileUrl}
                                    type="video/mp4"
                                    size={file.resolution.id}
                                />
                            ))
                        ) : (
                            <source src={props.src} type={props.type} />
                        )}
                    </video>
                ) : (
                    <audio className="audio-player" controls playsInline ref={videoRef}>
                        <source src={props.src} type={props.type} />
                    </audio>
                )}

                {error && <div className="error">Impossible de charger la vidéo.</div>}
                {props.videoState === 0 && (
                    <div className="transcoding">La vidéo est en cours de conversion.</div>
                )}
            </div>
        </div>
    );
};

export default VideoPlayer;
