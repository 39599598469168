import React, { useState } from 'react';
import Comments from 'components/Comments/Comments';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import { usePost } from '../../providers/PostProvider'; // Ensure you have a Comments component

const PostComments = () => {
    const { post, inCarousel } = usePost();
    const [showComments, setShowComments] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <>
            {!showComments && post.comments.length > 0 && !post.hideAll && !post.hideForSearch && (
                <div className="comment_container">
                    <div className="comments">
                        <div className="expand">
                            <button onClick={() => setShowComments(true)}>
                                <i className="icon-messages-bubble-double"></i>{' '}
                                {t('POST_SHOW_TOTAL_COMMENTS', {
                                    comment_number: post.comments.length
                                })}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showComments && post.comments.length > 0 && !post.hideAll && !post.hideForSearch && (
                <Comments object={post} user={user} inCarousel={inCarousel} />
            )}
        </>
    );
};

export default PostComments;
