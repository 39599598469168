import combineComponents from 'helpers/combineComponents';

import ConfigProvider from './ConfigProvider';
import InitProvider from './InitProvider';

import { AuthProvider } from './AuthContext';
import { ModalProvider } from './ModalContext';
import { AlertProvider } from './AlertContext';
import { SocketProvider } from './SocketContext';
import { DeviceProvider } from './DeviceContext';

const providers = [
    ConfigProvider,
    DeviceProvider,
    AuthProvider,
    InitProvider,
    SocketProvider,
    ModalProvider,
    AlertProvider
];

export default combineComponents(...providers);
