import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div className="row">
                <div className="icon" style={{ width: '45px', height: '45px' }}></div>

                <div className="column">
                    <div className="text" style={{ width: '75%' }}></div>
                    <div className="text" style={{ width: '45%' }}></div>
                </div>
            </div>

            <div className="text" style={{ width: '90%' }}></div>
            <div className="text"></div>
            <div className="text" style={{ width: '60%' }}></div>

            <div className="text" style={{ width: '90%' }}></div>
            <div className="text"></div>
            <div className="text" style={{ width: '60%' }}></div>
        </div>
    );
};
