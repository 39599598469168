import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { concat, forEach, isArray } from 'lodash-es';
import { useAuth } from '../providers/AuthContext';

const useTree = () => {
    const router = useRouter();
    const { hasRole } = useAuth();
    const { params } = useCurrentStateAndParams();

    const getView = () => {
        if (isView('stacked')) {
            return 'stacked';
        }

        if (isView('essentials')) {
            return 'essentials';
        }

        if (isView('meili-search')) {
            return 'meili-search';
        }

        if (isView('folders')) {
            return 'folders';
        }

        if (isView('calendar')) {
            return 'calendar';
        }

        if (isView('tables')) {
            return 'tables';
        }

        if (isView('tiles')) {
            return 'tiles';
        }

        return 'posts';
    };

    const goToView = (name, indirect = false) => {
        let view = getView();

        let state = router.globals.current.name.replace(view, name);

        if (state === `auth.feed.${name}.post`) {
            state = `auth.feed.${name}.workspace`;
        }

        if (indirect) {
            return router.stateService.href(state, { ...params, page: 1 });
        }

        router.stateService.go(state, { ...params, page: 1 }, { reload: true, inherit: false });
    };

    const getCategoryLink = (category) => {
        return goToCategory(category, true);
    };

    const getWorkspaceLink = (workspaceData) => {
        return goToWorkspace(workspaceData, true);
    };

    const getFolderLink = (folder, workspace) => {
        return goToFolder(folder, workspace, true);
    };

    const getPostLink = (post) => {
        return goToPost(post, true);
    };

    const getFilterableView = () => {
        if (isView('stacked')) {
            return 'stacked';
        }

        if (isView('meili-search')) {
            return 'meili-search';
        }

        if (isView('essentials')) {
            return hasRole('HAS_STACKED_VIEW') ? 'stacked' : 'posts';
        }

        if (isView('folders')) {
            return 'folders';
        }

        return 'posts';
    };

    const isView = (names) => {
        if (!isArray(names)) {
            names = [names];
        }

        return names.some((name) => {
            return router.stateService.includes(`auth.feed.${name}.**`);
        });
    };

    const goToCategory = (category, indirect = false) => {
        if (isView('meili-search')) {
            //return goToCategorySearch(category, indirect);
        }

        let params = {
            category: category.id,
            page: 1
        };

        if (category.total_unread_category > 0) {
            if (!indirect) {
                category.total_unread_category = 0;
            }

            params.unread = true;
        }

        if (indirect) {
            return router.stateService.href(`auth.feed.${getFilterableView()}.category`, params);
        }

        router.stateService.go(`auth.feed.${getFilterableView()}.category`, params, {
            reload: true,
            inherit: false
        });
    };

    const goToWorkspace = (workspaceData, indirect = false) => {
        if (isView('meili-search')) {
            //return goToWorkspaceSearch(workspaceData, indirect);
        }

        let params = {
            category: workspaceData.workspace.category.id,
            workspace: workspaceData.workspace.id,
            page: 1,
            status: workspaceData.workspace.default_view_status
                ? workspaceData.workspace.default_view_status
                : null
        };

        if (workspaceData.unread_post && workspaceData.unread_post.length > 0) {
            if (!indirect) {
                workspaceData.unread_post = [];
            }
            params.unread = true;
        }

        let view = getFilterableView();

        if (
            workspaceData.workspace.has_calendar_view &&
            (workspaceData.workspace.has_default_calendar_view || isView('calendar'))
        ) {
            view = 'calendar';
        }

        if (
            workspaceData.workspace.has_table_view &&
            workspaceData.workspace.has_default_table_view
        ) {
            view = 'tables';
        }

        if (
            workspaceData.workspace.has_tile_view &&
            workspaceData.workspace.has_default_tile_view
        ) {
            view = 'tiles';
        }

        if (indirect) {
            return router.stateService.href(`auth.feed.${view}.workspace`, params);
        }

        router.stateService.go(`auth.feed.${view}.workspace`, params, {
            reload: false,
            inherit: false
        });
    };

    const goToFolder = (folder, workspace, indirect = false) => {
        if (isView('meili-search')) {
            //return goToFolderSearch(folder, workspace, indirect);
        }

        let params = {
            category: workspace.category.id,
            workspace: workspace.id,
            folder: folder.id,
            page: 1
        };

        if (indirect) {
            return router.stateService.href(`auth.feed.${getFilterableView()}.folder`, params);
        }

        router.stateService.go(`auth.feed.${getFilterableView()}.folder`, params, {
            reload: true,
            inherit: false
        });
    };

    const goToPost = (post, indirect = false) => {
        let params = {
            category: post.workspace.category.id,
            workspace: post.workspace.id,
            post: post.id,
            page: 1
        };

        if (indirect) {
            return router.stateService.href('auth.feed.posts.post', params);
        }

        router.stateService.go('auth.feed.posts.post', params, { reload: true, inherit: false });
    };

    const getOnlyWorkspace = (tree) => {
        let workspaces = [];

        forEach(tree, (item) => {
            if (item.category) {
                workspaces = concat(workspaces, getOnlyWorkspace(item.workspaces));
            } else {
                workspaces.push(item);
            }
        });

        return workspaces;
    };

    const getOnlyFolder = (tree) => {
        let folders = [];

        forEach(tree, (item) => {
            if (item.category) {
                folders = concat(folders, getOnlyFolder(item.workspaces));
            } else {
                folders = concat(folders, getFolderChildren(item.workspace_data.folders));
            }
        });

        return folders.flat();
    };

    const getFolderChildren = (folders) => {
        let children = [];

        forEach(folders, (folder) => {
            children.push(folder[0]);
            children = concat(children, getFolderChildren(Object.values(folder).slice(1)));
        });

        return children;
    };

    const getOnlyCategory = (tree) => {
        let categories = [];

        forEach(tree, (item) => {
            if (!item.category) {
                return;
            }

            categories.push({
                ...item.category,
                workspaces: item.workspaces
                    .filter((workspace) => !!workspace.workspace_data)
                    .map((workspace) => {
                        return {
                            id: workspace.workspace_data.workspace.id,
                            name: workspace.workspace_data.workspace.name,
                            icon_color: workspace.workspace_data.workspace.icon_color,
                            has_calendar_view: workspace.workspace_data.workspace.has_calendar_view,
                            has_default_calendar_view:
                                workspace.workspace_data.workspace.has_default_calendar_view,
                            has_default_table_view:
                                workspace.workspace_data.workspace.has_default_table_view,
                            has_default_tile_view:
                                workspace.workspace_data.workspace.has_default_tile_view,
                            has_table_view: workspace.workspace_data.workspace.has_table_view,
                            has_tile_view: workspace.workspace_data.workspace.has_tile_view
                        };
                    })
            });

            categories = concat(categories, getOnlyCategory(item.workspaces));
        });

        return categories;
    };

    return {
        getFilterableView,
        goToPost,
        goToFolder,
        goToView,
        goToCategory,
        goToWorkspace,
        getPostLink,
        getFolderLink,
        getWorkspaceLink,
        getCategoryLink,
        isView,
        getOnlyWorkspace,
        getOnlyCategory,
        getOnlyFolder,
        getView
    };
};

export default useTree;
