import { useHttp } from 'api/useHttp';

const useWorkspacesService = () => {
    const axios = useHttp();

    const getAllWorkspaceForms = async () => {
        return axios.get('/all/workspace/forms').then((response) => response.data);
    };

    return {
        getAllWorkspaceForms
    };
};

export default useWorkspacesService;
