import React, { useEffect } from 'react';
import Modal from '../../components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { UIView, useRouter } from '@uirouter/react';
import { useConfig } from 'providers/ConfigProvider';
import useRight from 'hooks/useRight';
import useViewport from '../../hooks/useViewport';
import AdminProviders from './providers/adminProviders';
import { useModal } from '../../providers/ModalContext';
import { useTop } from '../../providers/TopProvider';

const Admin = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { config } = useConfig();
    const { hasRole, hasTrophies } = useRight();
    const viewport = useViewport();
    const { openModal } = useModal();
    const { setTitle, addActionButton } = useTop();

    useEffect(() => {
        setTitle('Administration');

        addActionButton({
            icon: 'icon-navigation-menu',
            callback: () => {
                openModal('admin_sidebar');
            }
        });
    }, []);

    return (
        <AdminProviders>
            <div className="admin-wrapper">
                <div className="admin">
                    <Modal
                        name="admin_sidebar"
                        title={t('TOP_ADMIN')}
                        options={{ isSidebar: true, zIndex: 200 }}
                        className={`sidebar ${viewport.gtXs ? 'scrollable' : ''}`}>
                        <div className="sidebar-wrapper">
                            <div className="navigation">
                                <div className="heading">
                                    {t('ADMIN_MANAGE_USERS_GLOBAL_TITLE')}
                                </div>
                                <ul className="settings">
                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.users', {
                                                page: null,
                                                letter: null,
                                                search: null
                                            })}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-user"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_MANAGE_USERS_TITLE')}
                                            </div>
                                        </a>
                                    </li>

                                    {hasRole('HAS_EMPLOYEE_ACCOUNT') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.import-employees'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-user"></i>
                                                </div>
                                                <div className="name">
                                                    {t('EMPLOYEES_MANAGEMENT')}
                                                </div>
                                            </a>
                                        </li>
                                    )}

                                    {hasRole('HAS_EMPLOYEE_DIRECTORY_V2') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.directory-config'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-user"></i>
                                                </div>
                                                <div className="name">
                                                    {t('ADMIN_MANAGE_DIRECTORY_TITLE')}
                                                </div>
                                            </a>
                                        </li>
                                    )}

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href(
                                                'auth.admin.organizational-units'
                                            )}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-user"></i>
                                            </div>
                                            <div className="name">{t('ORGANIZATIONAL_UNITS')}</div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.groups')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-user"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_MANAGE_GROUPS_TITLE')}
                                            </div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href(
                                                'auth.admin.hide-list-users'
                                            )}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-sliders"></i>
                                            </div>
                                            <div className="name">{t('HIDE_LIST_USERS')}</div>
                                        </a>
                                    </li>
                                </ul>

                                <div className="heading">{t('ADMIN_MENU_PLATFORM_LABEL')}</div>
                                <ul className="settings">
                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.tree')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-list"></i>
                                            </div>
                                            <div className="name">
                                                {t('TOP_CATEGORIES_AND_WORKSPACES')}
                                            </div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.forms')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-file-text"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_WORKSPACE_FORMS_TITLE')}
                                            </div>
                                        </a>
                                    </li>

                                    {hasRole('ROLE_SUPER_ADMIN') && hasRole('HAS_TRACKS') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.tracks'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa icon-calendar-1"></i>
                                                </div>
                                                <div className="name">{t('TRACKS_MANAGEMENT')}</div>
                                            </a>
                                        </li>
                                    )}

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.levels')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-sliders"></i>
                                            </div>
                                            <div className="name">{t('TOP_LEVEL')}</div>
                                        </a>
                                    </li>

                                    {hasRole('ROLE_SUPER_ADMIN') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.levels-stats'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-sliders"></i>
                                                </div>
                                                <div className="name">{t('ADMIN_LEVELS')}</div>
                                            </a>
                                        </li>
                                    )}

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href(
                                                'auth.admin.helpdesk.homescreens'
                                            )}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-book"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_MENU_HELPDESK_HOMESCREENS_LABEL')}
                                            </div>
                                        </a>
                                    </li>

                                    {hasRole('HAS_WORKSPACE_EXPORT_PDF') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.export-pdf'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-file"></i>
                                                </div>
                                                <div className="name">
                                                    {t('DATA_FEED_EXPORT_PDF')}
                                                </div>
                                            </a>
                                        </li>
                                    )}

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.rgpd')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-book"></i>
                                            </div>
                                            <div className="name">{t('RGPD_MANAGEMENT')}</div>
                                        </a>
                                    </li>

                                    {hasRole('HAS_SPONSORSHIP_CLIENT') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.sponsorship-client'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-users"></i>
                                                </div>
                                                <div className="name">
                                                    {t('RECONNECT_MANAGEMENT')}
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                </ul>

                                <div className="heading">{t('ADMIN_MENU_CUSTOMIZATION_LABEL')}</div>
                                <ul className="settings">
                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href(
                                                'auth.admin.springboard'
                                            )}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-cubes"></i>
                                            </div>
                                            <div className="name">Gérer les widgets</div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.navbar')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-bars"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_NAVBAR_MANAGEMENT')}
                                            </div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.brands')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-th-large"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_MENU_BRANDS_LABEL')}
                                            </div>
                                        </a>
                                    </li>

                                    {hasTrophies() && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.achievements'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-trophy"></i>
                                                </div>
                                                <div className="name">Gérer les badges</div>
                                            </a>
                                        </li>
                                    )}

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.profile')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-address-card"></i>
                                            </div>
                                            <div className="name">Gérer les champs du profil</div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.tags')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-tags"></i>
                                            </div>
                                            <div className="name">Gérer les tags partagés</div>
                                        </a>
                                    </li>

                                    <li className="setting">
                                        <a
                                            className="action"
                                            href={router.stateService.href('auth.admin.triggers')}>
                                            <div className="icon grey">
                                                <i className="fa fa-fw fa-sliders"></i>
                                            </div>
                                            <div className="name">
                                                {t('ADMIN_TRIGGERS_MANAGEMENT')}
                                            </div>
                                        </a>
                                    </li>

                                    {hasRole('ROLE_SUPER_ADMIN') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.translations'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-language"></i>
                                                </div>
                                                <div className="name">Gérer les traductions</div>
                                            </a>
                                        </li>
                                    )}

                                    {hasRole('ROLE_SUPER_ADMIN') && (
                                        <li className="setting">
                                            <a
                                                className="action"
                                                href={router.stateService.href(
                                                    'auth.admin.settings'
                                                )}>
                                                <div className="icon grey">
                                                    <i className="fa fa-fw fa-cog"></i>
                                                </div>
                                                <div className="name">
                                                    {t('TOP_APP_CONFIGURATION')}
                                                </div>
                                            </a>
                                        </li>
                                    )}
                                </ul>

                                {hasRole('HAS_RESOURCES_SPACE') && (
                                    <>
                                        <div className="heading">Espace ressources</div>
                                        <ul className="settings">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.sharedDocuments.articles'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-file-text-o"></i>
                                                    </div>
                                                    <div className="name">Articles</div>
                                                </a>
                                            </li>
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.sharedDocuments.categories'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-archive"></i>
                                                    </div>
                                                    <div className="name">Catégories</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}

                                {hasRole('HAS_HELPDESK') && (
                                    <>
                                        <div className="heading">
                                            {t('ADMIN_MENU_HELPDESK_LABEL')}
                                        </div>
                                        <ul className="settings">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.helpdesk.articles'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-file-text-o"></i>
                                                    </div>
                                                    <div className="name">
                                                        {t('ADMIN_MENU_HELPDESK_ARTICLES_LABEL')}
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.helpdesk.categories'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-archive"></i>
                                                    </div>
                                                    <div className="name">
                                                        {t('ADMIN_MENU_HELPDESK_CATEGORIES_LABEL')}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}

                                {hasRole('HAS_MISSIONS') && (
                                    <>
                                        <div className="heading">{t('MISSIONS')}</div>
                                        <ul className="settings" ng-if="hasRole('HAS_MISSIONS')">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.import-missions'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-download"></i>
                                                    </div>
                                                    <div className="name">Import des suivis</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}

                                {hasRole('ROLE_ADMIN') && (
                                    <>
                                        <div className="heading">Modération</div>
                                        <ul className="settings">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.review',
                                                        { tab: 'posts' }
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-file-text-o"></i>
                                                    </div>
                                                    <div className="name">Actualités</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.review',
                                                        { tab: 'comments' }
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-fw fa-comments"></i>
                                                    </div>
                                                    <div className="name">Commentaires</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}

                                {config.hasHomepage && (
                                    <>
                                        <div className="heading">{t('HOME_PAGE')}</div>
                                        <ul className="settings">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.ambassadors'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa icon-human-resources-employee-stress"></i>
                                                    </div>
                                                    <div className="name">
                                                        {t('AMBASSADOR_OF_THE_MONTH')}
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.tips'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa icon-user-question"></i>
                                                    </div>
                                                    <div className="name">{t('TIPS')}</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.events'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa icon-calendar-1"></i>
                                                    </div>
                                                    <div className="name">{t('EVENTS')}</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}

                                {/* <div className="heading" ng-if="$ctrl.CustomComponent.adminCustomRoutes.length">Custom
                                    component
                                </div>
                                <ul className="settings" ng-if="$ctrl.CustomComponent.adminCustomRoutes.length">
                                    <li className="setting" ng-repeat="route in $ctrl.CustomComponent.adminCustomRoutes">
                                        <a className="action" ng-click="$ctrl.CustomComponent.goTo(route.state)">
                                            <div className="icon grey">
                                                <i className="{ route.icon }"></i>
                                            </div>
                                            <div className="name">{route.label}</div>
                                        </a>
                                    </li>
                                </ul>*/}

                                {hasRole('ROLE_SUPER_ADMIN') && (
                                    <>
                                        <div className="heading">Informations techniques</div>

                                        <ul className="settings">
                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.variables'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-info-circle"></i>
                                                    </div>
                                                    <div className="name">Variables</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.commands'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-hand-pointer-o"></i>
                                                    </div>
                                                    <div className="name">Commandes</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.queries'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-database"></i>
                                                    </div>
                                                    <div className="name">Requêtes</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.logs'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-file-text-o"></i>
                                                    </div>
                                                    <div className="name">Logs</div>
                                                </a>
                                            </li>

                                            <li className="setting">
                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.admin.snippets'
                                                    )}>
                                                    <div className="icon grey">
                                                        <i className="fa fa-file-text-o"></i>
                                                    </div>
                                                    <div className="name">Snippets</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                )}
                            </div>

                            <div className="version">{/*Version {$ctrl.hash}*/}</div>
                        </div>
                    </Modal>
                    <div className={`view scrollable`}>
                        <UIView />
                    </div>
                </div>
            </div>
        </AdminProviders>
    );
};

export default Admin;
