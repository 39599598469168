import { useHttp } from 'api/useHttp';

const useTileService = () => {
    const axios = useHttp();

    const getWorkspaceTileFilters = (id) => {
        return axios.get(`/tile/workspace/${id}/filters`).then((response) => {
            return response.data;
        });
    };

    const getTilePosts = (workspace, page, status, query, order, since) => {
        return axios
            .get(`/tile/workspace/${workspace}/posts`, {
                params: { page, status, query, order, since }
            })
            .then((response) => response.data);
    };

    return {
        getWorkspaceTileFilters,
        getTilePosts
    };
};

export default useTileService;
