import extractParamsFromUrl from 'helpers/extractParamsFromUrl';
import { isInternalLink } from 'helpers/link';
import { isEqual, unset } from 'lodash-es';

export default function onClick(event, router, config) {
    let { target = null, path = (event.composedPath && event.composedPath()) || [] } = event;

    if (!target) {
        return;
    }

    let link;

    try {
        link = target.closest('a');
    } catch (e) {
        link = target.tagName === 'A' ? target : path.find((el) => el.tagName === 'A');
    }

    if (!link || !link.href) {
        return;
    }

    if (link.getAttribute('uiSref')) {
        return;
    }

    let { url = null, pathname = null, params = null } = extractParamsFromUrl(link.href);

    let isInternal = isInternalLink(url, config.hostnames);

    if (!isInternal || (link.dataset.target && link.dataset.target === '_blank')) {
        return;
    }

    let matchedRoute = router.urlService.match({
        path: pathname,
        search: params,
        hash: url === null ? null : url.hash.replace('#', '')
    });

    if (matchedRoute && matchedRoute.rule && matchedRoute.rule.type === 'STATE') {
        if (['auth.dashboard.**', 'auth.admin.**'].includes(matchedRoute.rule.state.name)) {
            return;
        }

        const sortFunction = (a, b) => {
            if (a[0] < b[0]) {
                return -1;
            }
            if (a[0] > b[0]) {
                return 1;
            }
            return 0;
        };

        let currentParams = router.globals.params;
        unset(currentParams, '#');
        unset(currentParams, 'page');
        let matchedParams = matchedRoute.match;
        unset(matchedParams, 'page');

        let options = {
            reload:
                router.globals.current.name === matchedRoute.rule.state.name &&
                isEqual(
                    Object.entries(currentParams).sort(sortFunction),
                    Object.entries(matchedParams).sort(sortFunction)
                ),
            inherit: !!(link.dataset.inherit && link.dataset.inherit === 'true')
        };

        router.stateService.go(
            matchedRoute.rule.state.name,
            {
                ...matchedRoute.match,
                askChangePassword: null
            },
            options
        );

        event.preventDefault();
        event.stopImmediatePropagation();
    }
}
