import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from 'hooks/useViewport';
import classes from 'classnames';
import { useDevice } from 'providers/DeviceContext';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useStatService from 'api/useStatService';
import { defaultTo, get, has, set } from 'lodash-es';
import useRight from 'hooks/useRight';
import useUser from 'hooks/useUser';
import { DatePicker, Select } from 'antd';
import useLevelService from '../../api/useLevelService';
import dayjs from 'dayjs';
import useStatExportLinks from './hooks/useStatExportLinks';
import { useAlert } from '../../providers/AlertContext';
import useCampaignService from '../../api/useCampaignService';

const { RangePicker } = DatePicker;

const FIXED_COLUMNS = [
    {
        title: 'STATS_USERS_TABLE_USERS',
        field: 'id',
        custom: true,
        hidden: false
    }
];

const COLUMNS = [
    {
        title: 'STATS_USERS_TABLE_FIRST_LOGIN',
        field: 'first_connection',
        sortable: '11',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_TOTAL_POINTS',
        field: 'total_points',
        legend: 'point(s)',
        sortable: '17',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SOCIAL_TOTAL_POINTS',
        field: 'social_total_points',
        legend: 'point(s)',
        classes: 'group social',
        sortable: '30',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SOCIAL_TOTAL_SHARES',
        field: 'social_total_shares',
        legend: 'partage(s)',
        classes: 'group social',
        sortable: '31',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SOCIAL_TOTAL_CLICKS',
        field: 'social_total_clicks',
        legend: 'clic(s)',
        classes: 'group social',
        sortable: '32',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SOCIAL_DETAILS',
        field: 'social_more_details',
        legend: 'clic(s)',
        classes: 'group social',
        custom: true,
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_POINTS',
        field: 'recruiting_total_points',
        legend: 'point(s)',
        classes: 'group recruiting',
        sortable: '22',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_APPLICATIONS',
        field: 'recruiting_total_applications',
        legend: 'candidature(s)',
        classes: 'group recruiting',
        sortable: '23',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_SUCCESSFUL_COOPTIONS',
        field: 'recruiting_total_successful_cooptions',
        legend: 'recrutement(s)',
        classes: 'group recruiting',
        sortable: '25',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_SHARES',
        field: 'recruiting_social_total_shares',
        legend: 'partage(s)',
        classes: 'group recruiting',
        sortable: '27',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_CLICKS',
        field: 'recruiting_social_total_clicks',
        legend: 'clic(s)',
        classes: 'group recruiting',
        sortable: '28',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_RECRUITING_TOTAL_SHARES',
        field: 'recruiting_social_total_points_shares',
        legend: 'point(s)',
        classes: 'group recruiting',
        sortable: '29',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_PRODUCT_TOTAL_POINTS',
        field: 'product_total_points',
        legend: 'point(s)',
        classes: 'group products',
        sortable: '20',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_PRODUCT_TOTAL_OPPORTUNITIES',
        field: 'product_total_opportunities',
        legend: 'opportunité(s)',
        classes: 'group products',
        sortable: '21',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SURVEY_TOTAL_POINTS',
        field: 'survey_total_points',
        legend: 'point(s)',
        classes: 'group survey',
        sortable: '18',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SURVEY_TOTAL_PARTICIPATIONS',
        field: 'survey_total_participations',
        legend: 'participation(s)',
        classes: 'group survey',
        sortable: '19',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_POSTS_TOTAL_POINTS',
        field: 'posts_points',
        legend: 'point(s)',
        classes: 'group other',
        sortable: '34',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SPONSORSHIP_TOTAL',
        field: 'sponsorship_count',
        legend: 'parrainage(s)',
        classes: 'group other',
        sortable: '35',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_SPONSORSHIP_TOTAL_POINTS',
        field: 'sponsorship_points',
        legend: 'point(s)',
        classes: 'group other',
        sortable: '36',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_POST_STATUS_POINTS',
        field: 'post_status_total_points',
        legend: 'point(s)',
        classes: 'group other',
        sortable: '38',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_OTHER_POINTS',
        field: 'other_points',
        legend: 'point(s)',
        classes: 'group other',
        sortable: '37',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_CONNECTED_OVER',
        field: 'is_active',
        legend: '',
        classes: 'group other',
        sortable: '12',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_CONNECTIONS_NUMBER',
        field: 'total_connection',
        legend: 'connexion(s)',
        classes: 'group other',
        sortable: '13',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_POSTS_NUMBER',
        field: 'total_posts',
        legend: 'actualité(s)',
        classes: 'group other',
        sortable: '14',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_COMMENTS_NUMBER',
        field: 'total_comments',
        legend: 'commentaire(s)',
        classes: 'group other',
        sortable: '15',
        hidden: false
    },
    {
        title: 'STATS_USERS_TABLE_LIKES_NUMBER',
        field: 'total_likes',
        legend: 'like(s)',
        classes: 'group other',
        sortable: '16',
        hidden: false
    }
];

const Stats = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const { device } = useDevice();
    const viewport = useViewport();
    const { params } = useCurrentStateAndParams();
    const { getUsersRanking } = useStatService();
    const { getLevelConfigs, getLevelsDetails } = useLevelService();
    const { postAdjustRewardUser } = useCampaignService();
    const { hasRole } = useRight();
    const { forgeAvatarUrl } = useUser();
    const { exportUsersRanking, exportLoginHistory, exportSocketHistory } = useStatExportLinks();
    const { showPrompt } = useAlert();
    const queryClient = useQueryClient();

    const bodyRef = useRef();

    const [query, setQuery] = useState('');

    const [isOnlyEmployee, setIsOnlyEmployee] = useState(
        JSON.parse(defaultTo(params.onlyEmployees, true))
    );
    const [selectedLevels, setSelectedLevels] = useState(defaultTo(params.byLevels, '').split(','));
    const [filterBy, setFilterBy] = useState(defaultTo(params.filterBy, ''));
    const [startAt, setStartAt] = useState(params.startDate ? dayjs(params.startDate) : null);
    const [endAt, setEndAt] = useState(params.endDate ? dayjs(params.endDate) : null);

    const [levels, setLevels] = useState([]);
    const [total, setTotal] = useState(0);

    const { data } = useQuery({
        queryKey: [query, 'stats'],
        queryFn: () => {
            return getUsersRanking(params);
        }
    });

    const { data: levelsData } = useQuery({
        queryKey: [query, 'levels_detail'],
        queryFn: () => {
            return Promise.all([getLevelsDetails(), getLevelConfigs()]);
        }
    });

    useEffect(() => {
        if (data && data.total) {
            setTotal(data.total);
        }
    }, [data]);

    useEffect(() => {
        setIsOnlyEmployee(JSON.parse(defaultTo(params.onlyEmployees, false)));
        setSelectedLevels(defaultTo(params.byLevels, '').split(','));
        setFilterBy(defaultTo(params.filterBy, ''));
        setStartAt(params.startDate ? dayjs(params.startDate) : null);
        setEndAt(params.endDate ? dayjs(params.endDate) : null);
        setQuery(`stats_${[...Object.values(params)].join('_')}`);
    }, [params]);

    useEffect(() => {
        if (levelsData && levelsData.length === 2) {
            setLevels(
                levelsData[1].map((level) => {
                    level.levels = levelsData[0].filter((levelConfig) => {
                        return levelConfig.index == level.index;
                    });

                    return {
                        label: level.name,
                        title: level.name,
                        options: level.levels.map((child) => {
                            return {
                                label: (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>{child.name}</div>
                                        <div>{child.total_users} utilisateurs</div>
                                    </div>
                                ),
                                value: child.id
                            };
                        })
                    };
                })
            );
        }
    }, [levelsData]);

    useEffect(() => {
        if (bodyRef.current && data && data.users.length > 0) {
            resizeTable();
            syncScrollable();
            resizeColumns();
        }
    }, [bodyRef, data]);

    const syncScrollable = () => {
        let scrollable = document.querySelector('.table.can-scroll .scrollable');

        let verticalSyncedRows = document.querySelectorAll('.table.is-fixed .tbody .rows');
        let horizontalSyncedRows = document.querySelectorAll('.table.can-scroll .thead .rows');

        scrollable.addEventListener('scroll', () => {
            let scrollTop = scrollable.scrollTop;
            let scrollLeft = scrollable.scrollLeft;

            verticalSyncedRows.forEach((verticalSyncedRow) => {
                verticalSyncedRow.style.webkitTransform = `translateY(-${scrollTop}px)`;
                verticalSyncedRow.style.msTransform = `translateY(-${scrollTop}px)`;
                verticalSyncedRow.style.transform = `translateY(-${scrollTop}px)`;
            });

            horizontalSyncedRows.forEach((horizontalSyncedRow) => {
                horizontalSyncedRow.style.webkitTransform = `translateX(-${scrollLeft}px)`;
                horizontalSyncedRow.style.msTransform = `translateX(-${scrollLeft}px)`;
                horizontalSyncedRow.style.transform = `translateX(-${scrollLeft}px)`;
            });
        });
    };

    const resizeColumns = () => {
        let absoluteColumn = document.querySelector('.column.absolute');
        let canGrowColumnTableSections = document.querySelectorAll('.column.can-grow .table > div');

        canGrowColumnTableSections.forEach((canGrowColumnTableSection) => {
            canGrowColumnTableSection.style.marginLeft = `${absoluteColumn.offsetWidth}px`;
        });
    };

    const resizeTable = () => {
        let tables = document.querySelectorAll('.table');
        let rowSizes = {};
        let colSizes = {};

        tables.forEach((table, tableIndex) => {
            let rows = table.querySelectorAll('.row');

            rows.forEach((row, rowIndex) => {
                let cols = row.querySelectorAll('.col');

                let rowSize = row.offsetHeight;

                if (!has(rowSizes, [rowIndex])) {
                    set(rowSizes, [rowIndex], rowSize);
                }

                let rowMaxSize = get(rowSizes, [rowIndex]);

                if (rowSize > rowMaxSize) {
                    set(rowSizes, [rowIndex], rowSize);
                }

                cols.forEach((col, colIndex) => {
                    let colSize = col.offsetWidth;

                    if (!has(colSizes, [tableIndex, colIndex])) {
                        set(colSizes, [tableIndex, colIndex], colSize);
                    }

                    let colMaxSize = get(colSizes, [tableIndex, colIndex]);

                    if (colSize > colMaxSize) {
                        set(colSizes, [tableIndex, colIndex], colSize);
                    }
                });
            });
        });

        tables.forEach((table, tableIndex) => {
            let rows = table.querySelectorAll('.row');

            rows.forEach((row, rowIndex) => {
                let cols = row.querySelectorAll('.col');

                cols.forEach((col, colIndex) => {
                    col.style.width = `${get(colSizes, [tableIndex, colIndex])}px`;
                    col.style.height = `${get(rowSizes, [rowIndex])}px`;
                });
            });
        });
    };

    const toggleOnlyEmployees = () => {
        router.stateService.go('auth.stats', { page: 1, onlyEmployees: !isOnlyEmployee });
    };

    const filterByName = () => {
        router.stateService.go('auth.stats', { ...params, page: 1, filterBy });
    };

    const levelsSelected = useMemo(() => {
        if (selectedLevels && levelsData) {
            return levelsData[0]
                .filter((l) => {
                    return selectedLevels.map((l) => parseInt(l)).indexOf(l.id) !== -1;
                })
                .map((l) => {
                    return {
                        label: l.name,
                        value: l.id
                    };
                });
        }

        return [];
    }, [selectedLevels, levelsData]);

    const handleChangeSelect = useCallback(
        (value) => {
            router.stateService.go('auth.stats', { ...params, page: 1, byLevels: value.join(',') });
        },
        [params]
    );

    const handleChangeDates = useCallback(
        (value) => {
            if (value) {
                router.stateService.go('auth.stats', {
                    ...params,
                    page: 1,
                    startDate: value[0].format('YYYY-MM-DD'),
                    endDate: value[1].format('YYYY-MM-DD')
                });
            } else {
                router.stateService.go('auth.stats', {
                    ...params,
                    page: 1,
                    startDate: null,
                    endDate: null
                });
            }
        },
        [params]
    );

    const onSortByClicked = useCallback(
        (col) => {
            if (!col.sortable) {
                return;
            }
            const sortBy = defaultTo(params.sortBy, '').split(':');
            let orderBy = '';
            let orderDirection = '';
            if (sortBy.length > 0) {
                orderBy = sortBy[0];
                orderDirection = sortBy[1];
            }

            if (col.sortable === orderBy) {
                if (orderDirection === 'asc') {
                    return router.stateService.go('auth.stats', {
                        ...params,
                        page: 1,
                        sortBy: `${col.sortable}:desc`
                    });
                }

                return router.stateService.go('auth.stats', {
                    ...params,
                    page: 1,
                    sortBy: `${col.sortable}:asc`
                });
            }

            return router.stateService.go('auth.stats', {
                ...params,
                page: 1,
                sortBy: `${col.sortable}:desc`
            });
        },
        [params]
    );

    const getSortOrderForCol = useCallback(
        (col) => {
            const sortBy = defaultTo(params.sortBy, '').split(':');
            let orderBy = '';
            let orderDirection = '';
            if (sortBy.length > 0) {
                orderBy = sortBy[0];
                orderDirection = sortBy[1];
            }

            if (col.sortable === orderBy) {
                if (orderDirection === 'asc') {
                    return 'fa-sort-asc';
                }

                return 'fa-sort-desc';
            }

            return 'fa-sort';
        },
        [params]
    );

    const goToPreviousPage = () => {
        router.stateService.go('auth.stats', { ...params, page: params.page - 1 });
    };

    const goToNextPage = () => {
        router.stateService.go('auth.stats', { ...params, page: params.page + 1 });
    };

    const hasPreviousPage = () => {
        return params.page > 1;
    };

    const hasNextPage = () => {
        return params.count * params.page < total;
    };

    const adjustReward = (user) => {
        showPrompt({
            title: `Modifier les points de ${user.first_name}`,
            text: `Combien de points voulez-vous ajouter ou supprimer à ${user.first_name} ?`,
            placeholder: 'Nombre de points',
            button: {
                label: 'Modifier',
                classes: ['green', 'bold']
            }
        })
            .then((reward) => {
                postAdjustRewardUser(reward, user.id).then(() => {
                    queryClient.resetQueries(['stats']);
                });
            })
            .catch(() => {});
    };

    return (
        <div className="stats-wrapper">
            <div className="stats scrollable">
                <div className="view">
                    <div className="header">
                        <div className="top">
                            <div className="title">{t('STATS_USERS_TITLE')}</div>

                            <a
                                href={exportUsersRanking(params)}
                                target="_blank"
                                className="button blue small rounded"
                                rel="noreferrer">
                                <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                {t('TA_ACTIVITY_SURVEY_EXPORT')}
                            </a>

                            <a
                                href={exportUsersRanking(params, true)}
                                target="_blank"
                                className="button blue small rounded"
                                rel="noreferrer">
                                <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                {t('LIGHT_EXPORT')}
                            </a>

                            {hasRole('ROLE_SUPER_ADMIN') && (
                                <>
                                    <a
                                        href={exportLoginHistory(params)}
                                        target="_blank"
                                        className="button blue small rounded"
                                        rel="noreferrer">
                                        <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                        {t('TA_ACTIVITY_SURVEY_EXPORT_LOGIN_HISTORY')}
                                    </a>

                                    <a
                                        href={exportSocketHistory(params)}
                                        target="_blank"
                                        className="button blue small rounded"
                                        rel="noreferrer">
                                        <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                        {t('TA_ACTIVITY_SURVEY_EXPORT_SOCKET_HISTORY')}
                                    </a>
                                </>
                            )}
                        </div>

                        <div className="filters">
                            <div className="name-filter">
                                <input
                                    type="text"
                                    value={filterBy}
                                    onChange={(e) => {
                                        setFilterBy(e.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 13) {
                                            filterByName();
                                        }
                                    }}
                                    placeholder={t('STATS_USERS_FILTER_BY_NAME')}
                                />
                            </div>

                            <div className="spacer"></div>

                            <div className="group" onClick={toggleOnlyEmployees}>
                                <div className={`btn ${isOnlyEmployee ? 'active' : ''}`}>
                                    Internes
                                </div>
                                <div className={`btn ${!isOnlyEmployee ? 'active' : ''}`}>
                                    Externes
                                </div>
                            </div>

                            <div className="spacer"></div>

                            <div className="range start">
                                <RangePicker
                                    defaultValue={[startAt, endAt]}
                                    onChange={handleChangeDates}
                                    format={'DD/MM/YYYY'}
                                />
                            </div>
                        </div>

                        {isOnlyEmployee && (
                            <div className="filters">
                                <Select
                                    options={levels}
                                    mode={'multiple'}
                                    defaultValue={levelsSelected}
                                    onChange={handleChangeSelect}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        )}
                    </div>

                    {viewport.gtXs && (
                        <div className="body" ref={bodyRef}>
                            <div className="column absolute">
                                <div className="table is-fixed">
                                    <div className="thead">
                                        <div className="rows">
                                            <div className="row">
                                                {FIXED_COLUMNS.map((col) => {
                                                    return (
                                                        <div
                                                            className="col"
                                                            onClick={() => onSortByClicked(col)}
                                                            key={col.field}>
                                                            <span className="title">
                                                                {t(col.title)}
                                                            </span>
                                                            {col.sortable && (
                                                                <span className="sort">
                                                                    <i
                                                                        className={`fa fa-fw ${getSortOrderForCol(col)}`}
                                                                        aria-hidden="true"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tbody">
                                        <div className="rows">
                                            {data &&
                                                data.users &&
                                                data.users.map((user) => {
                                                    return (
                                                        <div
                                                            className="row"
                                                            key={`stat_user_${user.id}`}>
                                                            {FIXED_COLUMNS.map((col) => {
                                                                return (
                                                                    <div
                                                                        className="col"
                                                                        key={`stat_user_${user.id}_${col.field}`}>
                                                                        {col.custom ? (
                                                                            <div className="custom">
                                                                                {col.field ===
                                                                                    'id' && (
                                                                                    <div className="user">
                                                                                        <div className="avatar">
                                                                                            <img
                                                                                                src={forgeAvatarUrl(
                                                                                                    user.image_name
                                                                                                )}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="meta">
                                                                                            <div className="fullname">
                                                                                                {
                                                                                                    user.first_name
                                                                                                }{' '}
                                                                                                {
                                                                                                    user.last_name
                                                                                                }
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                {
                                                                                                    user.city
                                                                                                }{' '}
                                                                                                –{' '}
                                                                                                {
                                                                                                    user.job
                                                                                                }
                                                                                            </div>
                                                                                            <div className="reward">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() =>
                                                                                                        adjustReward(
                                                                                                            user
                                                                                                        )
                                                                                                    }>
                                                                                                    Ajuster
                                                                                                    les
                                                                                                    points
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="regular">
                                                                                <span className="data">
                                                                                    {
                                                                                        user[
                                                                                            col
                                                                                                .field
                                                                                        ]
                                                                                    }
                                                                                </span>
                                                                                {col.legend && (
                                                                                    <span className="legend">
                                                                                        {col.legend}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="column can-grow">
                                <div className="table can-scroll">
                                    <div className="thead">
                                        <div className="rows">
                                            <div className="row">
                                                {COLUMNS.map((col) => {
                                                    return (
                                                        <div
                                                            className={classes(
                                                                col.classes
                                                                    ? [
                                                                          'col',
                                                                          ...col.classes.split(' ')
                                                                      ]
                                                                    : ['col']
                                                            )}
                                                            onClick={() => onSortByClicked(col)}
                                                            key={col.field}>
                                                            <span className="title">
                                                                {t(col.title)}
                                                            </span>
                                                            {col.sortable && (
                                                                <span className="sort">
                                                                    <i
                                                                        className={`fa fa-fw ${getSortOrderForCol(col)}`}
                                                                        aria-hidden="true"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tbody scrollable">
                                        <div className="rows">
                                            {data &&
                                                data.users &&
                                                data.users.map((user) => {
                                                    return (
                                                        <div
                                                            className="row"
                                                            key={`stat_user_columns_${user.id}`}>
                                                            {COLUMNS.map((col) => {
                                                                return (
                                                                    <div
                                                                        className="col"
                                                                        key={`stat_user_${user.id}_${col.field}`}>
                                                                        {col.custom ? (
                                                                            <div className="custom">
                                                                                {col.field ===
                                                                                    'social_more_details' && (
                                                                                    <div className="sharing-details">
                                                                                        {user[
                                                                                            col
                                                                                                .field
                                                                                        ] ? (
                                                                                            <div className="networks">
                                                                                                {/*{ user[col.field].map(([network, clicks]) => {
                                                                                               // TODO CAPITALIZE
                                                                                               return (
                                                                                                   <div className="network">
                                                                                                       { network } : {clicks} {col.legend}
                                                                                                   </div>
                                                                                               )
                                                                                           }) }*/}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="empty">
                                                                                                <span className="data">
                                                                                                    0{' '}
                                                                                                </span>
                                                                                                <span className="legend">
                                                                                                    {
                                                                                                        col.legend
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="regular">
                                                                                <span className="data">
                                                                                    {
                                                                                        user[
                                                                                            col
                                                                                                .field
                                                                                        ]
                                                                                    }{' '}
                                                                                </span>
                                                                                {col.legend && (
                                                                                    <span className="legend">
                                                                                        {col.legend}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {device.isApp && (
                        <div className="body mobile">{t('STATS_NOT_AVAILABLE_FOR_MOBILE')}</div>
                    )}

                    {!device.isApp && (
                        <div className="footer">
                            <div className="pagination">
                                {hasPreviousPage() && (
                                    <div className="previous page" onClick={goToPreviousPage}>
                                        {t('STATS_PREVIOUS_PAGE')}
                                    </div>
                                )}
                                <div className="spacer"></div>
                                {hasNextPage() && (
                                    <div className="next page" onClick={goToNextPage}>
                                        {t('STATS_NEXT_PAGE')}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Stats;
