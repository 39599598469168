import { useForm } from 'components/Form/hooks';
import { ModalForm, ModalFormBody, ModalFormSection } from 'components/Form/ModalForm';
import { Input, Textarea } from 'components/Form/Inputs';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import rgpdFormSchema from './schemas/rgpdForm.schema.json';
import { useTranslation } from 'react-i18next';
import useRgpd from './api/useRgpd';
import { useAlert } from 'providers/AlertContext';
import { useQuery } from '@tanstack/react-query';

const Rgpd = () => {
    const { t } = useTranslation();
    const [activeRgpd, setActiveRgpd] = useState(null);

    const { showToast } = useAlert();
    const { getRgpdCampaignConfigs, postRgpdCampaignConfigs } = useRgpd();

    const { data: configs, isFetched } = useQuery({
        queryKey: ['rgpd-campaign-configs'],
        queryFn: getRgpdCampaignConfigs
    });

    const config = configs?.[0];
    const methods = useForm({
        schema: rgpdFormSchema
    });
    const { watch, reset, setValue } = methods;

    useEffect(() => {
        if (!isFetched && !config) return;

        reset(config);
    }, [isFetched, config, reset, activeRgpd]);

    const onFinish = (values) => {
        const payload = {
            type: 'recruiting',
            ...values
        };

        postRgpdCampaignConfigs(payload).then(() => {
            showToast({
                text: t('RGPD_CONFIG_UPDATED_SUCCESSFULLY'),
                duration: 1500
            });
        });
    };

    const handleCta = (action) => {
        const cta = action === 'accept' ? 'accept' : 'decline';

        setValue('text', `${watch('text')} [[${cta}]]`, {
            shouldDirty: true,
            shouldValidate: true
        });
    };

    return (
        <div>
            <div class="admin-header">
                <div class="title">
                    {t('RGPD_CAMPAIGN_MANAGEMENT', {
                        campaign_title: t('TA_RECRUITING_TITLE')
                    })}
                </div>
                <div>
                    <a
                        href="https://www.cnil.fr/fr/comprendre-le-rgpd"
                        target="_blank"
                        class="ant-btn ant-btn-default"
                        rel="noreferrer">
                        <span>Comprendre le RGPD</span>
                    </a>
                </div>
            </div>

            <div>
                <div
                    className="checkbox"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}>
                    <input
                        type="checkbox"
                        className="input"
                        name="activeRgpd"
                        onChange={() => setActiveRgpd(!activeRgpd)}
                    />

                    <label className="label">
                        <div className="tick"></div>
                        <div className="name">{t('ENABLE')}</div>
                    </label>
                </div>
            </div>

            {activeRgpd && (
                <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
                    <ModalFormBody>
                        <ModalFormSection>
                            <Input
                                type="number"
                                name="retentionDelay"
                                label={t('RGPD_CAMPAIGN_DATA_RETENTION_DELAY')}
                            />

                            <div>
                                <Textarea
                                    name="text"
                                    rows={4}
                                    label={t('RGPD_CAMPAIGN_MAIL_CONTENT')}
                                />
                                <span>{t('RGPD_CAMPAIGN_MAIL_CONTENT_INFO')}</span>

                                <Button
                                    disabled={watch('text')?.includes('[[accept]]')}
                                    onClick={() => handleCta('accept')}>
                                    Accept
                                </Button>
                                <Button
                                    disabled={watch('text')?.includes('[[decline]]')}
                                    onClick={() => handleCta('decline')}>
                                    Decline and Delete
                                </Button>
                            </div>

                            <Textarea
                                name="mention_email"
                                label={t('RGPD_CAMPAIGN_MENTION_EMAIL')}
                            />

                            <Textarea
                                name="mention_candidate"
                                label={t('RGPD_CAMPAIGN_MENTION_CANDIDATE')}
                            />

                            <Textarea
                                name="mention_coopt"
                                rows={4}
                                label={t('RGPD_CAMPAIGN_MENTION_COOPT')}
                            />

                            <Textarea
                                name="mention_share"
                                rows={4}
                                label={t('RGPD_CAMPAIGN_MENTION_SHARE')}
                            />

                            <Textarea
                                name="intro_text"
                                rows={4}
                                label={t('RGPD_INTRO_CANDIDATE_FORM')}
                            />

                            <Input name="accept_label" rows={4} label={t('ACCEPT_BUTTON_LABEL')} />

                            <Input
                                name="decline_label"
                                rows={4}
                                label={t('DECLINE_BUTTON_LABEL')}
                            />

                            <Input
                                name="website"
                                label={t('RGPD_CAMPAIGN_WEBSITE')}
                                placeholder="https://"
                            />
                        </ModalFormSection>
                        <Button type="primary" htmlType="submit">
                            Ajouter
                        </Button>
                    </ModalFormBody>
                </ModalForm>
            )}
        </div>
    );
};

export default Rgpd;
