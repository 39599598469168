import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div
                className="text"
                style={{ width: '15%', height: '26px', borderRadius: '15px' }}
            ></div>

            <div className="row">
                <div
                    className="icon"
                    style={{ alignSelf: 'flex-end', width: '32px', height: '32px' }}
                ></div>

                <div className="column">
                    <div className="text" style={{ width: '125px', height: '8px' }}></div>
                    {props.odd ? (
                        <div className="text" style={{ width: '50%', minWidth: '200px' }}></div>
                    ) : (
                        <div className="text" style={{ width: '40%', minWidth: '145px' }}></div>
                    )}
                    <div className="text" style={{ width: '60px', height: '8px' }}></div>
                </div>
            </div>
        </div>
    );
};
