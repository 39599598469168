import React, { useEffect } from 'react';
import { useModal } from 'providers/ModalContext';
import Loader from '../Loader/Loader';
import classes from 'classnames';
import useViewport from 'hooks/useViewport';
import { useForm, FormProvider } from 'react-hook-form';

const Modal = ({ name, title, subTitle, options, className, children }) => {
    const { registerModal, closeModal, updateModal, modalExists, isModalOpen } = useModal();
    const viewport = useViewport();

    let {
        isSidebar = false,
        isFullscreen = false,
        isDesktopHeight80 = false,
        canScroll = true,
        clickOutsideToDismiss = true,
        hasPaddedContent = false,
        hasFlexContent = false,
        showPrevButton = false,
        zIndex = 300,
        invisible = false,
        customClasses = null,
        hideLoader = false,
        prevCallback = () => {},
        closeCallback = () => {}
    } = options || {};

    if (isSidebar || hasFlexContent) {
        hasPaddedContent = true;
    }

    const isOpen = isModalOpen(name);
    const computedZIndex = zIndex;

    useEffect(() => {
        if (modalExists(name)) {
            updateModal(name, { title, subTitle });
        } else {
            registerModal({
                name,
                closeCallback
            });
        }
    }, [title, subTitle]);

    const close = () => {
        closeModal(name);
    };

    const clickOutsideToDismissHandler = () => {
        if (clickOutsideToDismiss) {
            closeModal(name);
        }
    };

    return (
        <div className={`modal-wrapper ${className}`} data-name={name}>
            {!isSidebar && (
                <div
                    className={`modal-backdrop ${isOpen ? 'is-open' : null} ${invisible ? 'invisible' : null}`}
                    style={{ zIndex: computedZIndex - 1 }}
                    onClick={() => clickOutsideToDismissHandler()}></div>
            )}

            <div
                className={`modal ${isOpen ? 'is-open' : null} ${classes([
                    customClasses,
                    isSidebar ? 'is-sidebar' : null,
                    canScroll ? 'scrollable' : null,
                    /*'is-msie': $ctrl.Config.isMsie,*/
                    isFullscreen ? 'is-fullscreen' : null,
                    isDesktopHeight80 ? 'is-desktop-height-80' : null,
                    canScroll ? 'can-scroll' : null
                ])}`}
                style={{ zIndex: computedZIndex }}>
                <div className="modal-body">
                    {!(isSidebar && viewport.gtXs) && !hideLoader && <Loader></Loader>}

                    <div className="modal-header">
                        {title && <div className="title">{title}</div>}
                        {subTitle && <div className="subtitle">{subTitle}</div>}

                        <div className="close" onClick={close}>
                            <i className="icon-remove-circle" aria-hidden="true"></i>
                        </div>

                        {showPrevButton && (
                            <div className="prev" onClick={prevCallback}>
                                <i className="icon-arrow-left" aria-hidden="true"></i>
                            </div>
                        )}
                    </div>

                    <div
                        className={`modal-content ${classes([
                            canScroll ? 'scrollable' : null,
                            hasFlexContent ? 'has-flex-content' : null,
                            hasPaddedContent ? 'has-padded-content' : null
                        ])}`}>
                        {(isOpen || isSidebar) && children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
