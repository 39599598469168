import { useHttp } from 'api/useHttp';

const useDirectoryConfigService = () => {
    const axios = useHttp();

    const getDirectoryConfigs = () => {
        return axios.get(`/directory-configs`).then((response) => {
            return response.data;
        });
    };

    const postDirectoryConfig = (directoryConfig) => {
        return axios.post(`/directory-configs`, directoryConfig).then((response) => response.data);
    };

    const putDirectoryConfig = (directoryConfig) => {
        return axios
            .put(`/directory-configs/${directoryConfig.id}`, directoryConfig)
            .then((response) => response.data);
    };

    const getDirectoryConfigForUser = () => {
        return axios.get(`/directory-configs/user`).then((response) => {
            return response.data;
        });
    };

    return {
        getDirectoryConfigs,
        postDirectoryConfig,
        putDirectoryConfig,
        getDirectoryConfigForUser
    };
};

export default useDirectoryConfigService;
