import React, { useCallback, useRef, useState } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react';
import Header from './partials/Header';
import Actions from './partials/Actions';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useFeedContext } from '../providers/FeedProvider';
import { useTreeContext } from '../../Auth/providers/TreeProvider';
import { useQuery } from '@tanstack/react-query';
import useTree from 'hooks/useTree';
import useRight from 'hooks/useRight';
import { useTranslation } from 'react-i18next';
import useUtils from 'hooks/useUtils';
import usePostService from 'api/usePostService';
import dayjs from 'dayjs';
import useCalendarEvent from '../hooks/useCalendarEvent';
import { useConfig } from 'providers/ConfigProvider';
import Post from 'components/Post/Post';
import { orderBy } from 'lodash-es';
import { useModal } from 'providers/ModalContext';
import { useAlert } from 'providers/AlertContext';

const Calendar = () => {
    const { t } = useTranslation();
    const { tree } = useTreeContext();
    const { locale } = useConfig();
    const { getOnlyWorkspace } = useTree();
    const { category, workspace } = useFeedContext();
    const { params } = useCurrentStateAndParams();
    const { isAllowToRead, isAllowToWrite, isAllowToHalfRead, hasRole } = useRight();
    const { getFirstLetter } = useUtils();
    const { getPostsByWorkspaceAndDate } = usePostService();
    const { getEventDate, getEventEndDate, getEventTitle, getEventColorAsHex } = useCalendarEvent();
    const { openModal } = useModal();
    const calendarRef = useRef();
    const { showActionSheet } = useAlert();
    const { goToPost, goToWorkspace } = useTree();

    const [feeds, setFeeds] = useState([]);
    const [selectedFeeds, setSelectedFeeds] = useState([]);
    const [dates, setDates] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());

    const isSameDate = (a, b) => {
        return (
            a.getDate() === b.getDate() &&
            a.getMonth() === b.getMonth() &&
            a.getYear() === b.getYear()
        );
    };

    const postsAsEvents = (posts) => {
        return posts.map(({ post }) => {
            const start = getEventDate(post);
            const end = getEventEndDate(post);

            const startDate = new Date(start);
            const endDate = new Date(end);

            const pos = parseInt(
                post?.workspace_form_input_values?.find(
                    (formInputValue) => formInputValue.workspace_form_input.label === 'position'
                )?.text
            );

            return {
                id: post.id,
                title: getEventTitle(post),
                color: workspace ? getEventColorAsHex(post) : post.workspace.icon_color,
                start: dayjs(startDate).startOf('day').toDate(),
                end: dayjs(endDate).endOf('day').toDate(),
                extendedProps: {
                    post,
                    pos: pos ? pos : null,
                    color: workspace ? getEventColorAsHex(post) : post.workspace.icon_color,
                    start: startDate,
                    end: endDate,
                    isMultiDay: !isSameDate(startDate, endDate)
                }
            };
        });
    };

    const fetchPosts = async () => {
        if (!dates) {
            return [];
        }
        let feed = [];
        if (!params.workspace && !params.category) {
            if (tree) {
                const workspaces = getOnlyWorkspace(tree);
                feed = workspaces
                    .map((feed) => feed?.workspace_data?.workspace)
                    .filter((feed) => {
                        if (
                            !feed ||
                            (!isAllowToRead(feed.id) &&
                                !isAllowToWrite(feed.id) &&
                                !isAllowToHalfRead(feed.id))
                        ) {
                            return false;
                        }

                        return feed.has_calendar_view;
                    });
            } else {
                return [];
            }
        }
        if (params.workspace) {
            if (workspace) {
                feed = [workspace];
            } else {
                return [];
            }
        }

        if (params.category && !params.workspace) {
            if (category) {
                const workspaces = category.workspaces;
                feed = workspaces.filter((feed) => {
                    if (
                        !feed ||
                        (!isAllowToRead(feed.id) &&
                            !isAllowToWrite(feed.id) &&
                            !isAllowToHalfRead(feed.id))
                    ) {
                        return false;
                    }

                    return feed.has_calendar_view;
                });
            } else {
                return [];
            }
        }

        if (feed.length > 0) {
            setFeeds(feed);
            setSelectedFeeds(feed);
            const timestamp = Math.round(dates.view.currentStart.getTime() / 1000);
            const results = await Promise.allSettled(
                feed
                    .filter((f) => f)
                    .map((f) => {
                        return getPostsByWorkspaceAndDate(f.id, timestamp).then((data) => {
                            return data;
                        });
                    })
            );
            const posts = results.reduce((acc, result) => acc.concat(result.value), []);
            return postsAsEvents(posts);
        } else {
            return [];
        }
    };

    const isSelectedFeed = (feed) => {
        return selectedFeeds.find((f) => f.id === feed.id);
    };

    const toggleFeed = (feed) => {
        setSelectedFeeds((prev) => {
            if (prev.find((f) => f.id === feed.id)) {
                return prev.filter((f) => f.id !== feed.id);
            } else {
                return [...prev, feed];
            }
        });
    };

    const { data: events, isLoading } = useQuery({
        queryFn: () => fetchPosts(),
        queryKey: [
            'calendar',
            params.workspace,
            params.category,
            category?.id,
            workspace?.id,
            !!tree,
            dates?.start
        ]
    });

    const filterEvents = (events) => {
        return events?.filter(({ extendedProps }) => {
            return selectedFeeds.map((f) => f.id).indexOf(extendedProps.post.workspace.id) !== -1;
        });
    };

    const isToday = () => {
        if (dates) {
            return isSameDate(currentDate, new Date());
        }
        return false;
    };

    const getFormattedDate = (format = 'YYYY-MM-DD', date = currentDate) => {
        return dayjs(date).format(format);
    };

    const isEventOnSelectedDate = (event, date = currentDate) => {
        const start = event.start;
        const end = event.end || start;

        return isSameDate(start, end)
            ? isSameDate(start, date)
            : dayjs(date).isBetween(dayjs(start), dayjs(end), 'day', '[)');
    };

    const isFeedSelected = (feed) => {
        return selectedFeeds.some(({ id }) => id === feed.id);
    };

    const hasEvents = useCallback(
        (date = currentDate, e = events) => {
            return e?.some((event) => {
                return (
                    isFeedSelected(event.extendedProps.post.workspace) &&
                    isEventOnSelectedDate(event, date)
                );
            });
        },
        [currentDate, events, selectedFeeds]
    );

    const getEvents = () => {
        return orderBy(
            events.filter(
                (event) =>
                    isFeedSelected(event.extendedProps.post.workspace) &&
                    isEventOnSelectedDate(event)
            ),
            ['start', '-duration', 'allDay', 'pos', 'title']
        );
    };

    const goToMonth = (direction) => {
        const calendarApi = calendarRef.current?.getApi();
        if (direction > 0) {
            calendarApi.next();
        } else {
            calendarApi.prev();
        }

        const today = new Date();
        const date = calendarApi.getDate();
        setCurrentDate(date);

        if (
            !isSameDate(date, today) &&
            date.getDate() === 1 &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            calendarApi.gotoDate(today);
        }
    };

    const previewPost = (post) => {
        openModal('preview-post', { post });
    };

    const showActions = (event) => {
        showActionSheet({
            title: "Options d'événements",
            text: 'Choisissez une option ci-dessous',
            buttons: [
                {
                    label: t('POST_SHOW'),
                    callback: () => {
                        previewPost(event.extendedProps.post);
                    }
                },
                {
                    label: t('VIEW_RELATED_POST'),
                    callback: () => {
                        goToPost(event.extendedProps.post);
                    }
                },
                {
                    label: t('VIEW_RELATED_WORKSPACE'),
                    callback: () => {
                        goToWorkspace({
                            workspace: event.extendedProps.post.workspace
                        });
                    }
                }
            ]
        });
    };

    return (
        <div className="calendar-root">
            <div className="calendar">
                <div className="body">
                    <div className="main">
                        <div className="content">
                            <div className="metadata">
                                <Header />
                                <Actions />
                            </div>
                            <div class="header">
                                <div class="date">{getFormattedDate('MMMM YYYY')}</div>

                                <div class="nav">
                                    <div class="prev" onClick={() => goToMonth(-1)}>
                                        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyNCAyNCc+PHBhdGggZmlsbD0nIzBmMTcyYScgZD0nTTEyIDE2Yy0uMyAwLS41LS4xLS43LS4zbC02LTZjLS40LS40LS40LTEgMC0xLjRzMS0uNCAxLjQgMGw1LjMgNS4zIDUuMy01LjNjLjQtLjQgMS0uNCAxLjQgMHMuNCAxIDAgMS40bC02IDZjLS4yLjItLjQuMy0uNy4zeicvPjwvc3ZnPg==" />
                                    </div>

                                    <div class="next" onClick={() => goToMonth(1)}>
                                        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyNCAyNCc+PHBhdGggZmlsbD0nIzBmMTcyYScgZD0nTTEyIDE2Yy0uMyAwLS41LS4xLS43LS4zbC02LTZjLS40LS40LS40LTEgMC0xLjRzMS0uNCAxLjQgMGw1LjMgNS4zIDUuMy01LjNjLjQtLjQgMS0uNCAxLjQgMHMuNCAxIDAgMS40bC02IDZjLS4yLjItLjQuMy0uNy4zeicvPjwvc3ZnPg==" />
                                    </div>
                                </div>
                            </div>
                            <div className="full-calendar">
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    dayMaxEventRows={
                                        hasRole('DISABLE_SHOW_MORE_CALENDAR') ? false : 4
                                    }
                                    eventDisplay={'block'}
                                    events={filterEvents(events)}
                                    firstDay={1}
                                    contentHeight={'auto'}
                                    height={'auto'}
                                    eventOrder={'start,-duration,allDay,pos,title'}
                                    locale={locale}
                                    displayEventTime={false}
                                    headerToolbar={false}
                                    stickyHeaderDates={false}
                                    stickyFooterScrollbar={false}
                                    fixedWeekCount={false}
                                    showNonCurrentDates={true}
                                    ref={calendarRef}
                                    eventClick={({ event }) => {
                                        previewPost(event.extendedProps.post);
                                    }}
                                    dayCellClassNames={({ date }) => {
                                        const classes = [];
                                        const calendarApi = calendarRef.current?.getApi();
                                        if (!calendarApi) {
                                            return '';
                                        }

                                        if (isSameDate(date, calendarApi.getDate())) {
                                            classes.push('fc-selected');
                                        }

                                        if (hasEvents(date, calendarApi.getEvents())) {
                                            classes.push('fc-has-events');
                                        }

                                        return classes.join(' ');
                                    }}
                                    dayHeaderContent={({ date }) => {
                                        return dayjs(date).format('ddd').replace(/\./, '');
                                    }}
                                    datesSet={(dates) => {
                                        setDates(dates);
                                    }}
                                    dateClick={({ date }) => {
                                        const calendarApi = calendarRef.current.getApi();
                                        calendarApi.gotoDate(date);
                                        setCurrentDate(date);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sidebar">
                        {!workspace && (
                            <div className="block">
                                <div className="title">{t('FEED_CALENDAR_MY_CALENDARS')}</div>

                                <div className="content">
                                    <div className="filters">
                                        {feeds &&
                                            feeds.map((feed) => {
                                                return (
                                                    <div
                                                        className="filter"
                                                        key={`feed_${feed.id}`}
                                                        onClick={() => toggleFeed(feed)}>
                                                        <div className="checkbox">
                                                            <i
                                                                className={
                                                                    isSelectedFeed(feed)
                                                                        ? 'icon-check-circle-1-filled'
                                                                        : 'icon-check-circle-1'
                                                                }></i>
                                                        </div>

                                                        <div
                                                            className="icon"
                                                            style={{
                                                                backgroundColor: feed.icon_color
                                                            }}>
                                                            <div className="letter">
                                                                {getFirstLetter(feed.name)}
                                                            </div>
                                                        </div>

                                                        <div className="name">{feed.name}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="block">
                            {isToday() ? (
                                <div className="title">{t('CHAT_MESSAGE_DATE_IS_TODAY')}</div>
                            ) : (
                                <div className="title">{getFormattedDate('dddd DD MMMM')}</div>
                            )}

                            {isLoading ? (
                                <div className="content">
                                    <div className="is-loading">
                                        <div className="icon"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    {hasEvents(currentDate, events) ? (
                                        <div
                                            className={`events has-events ${params.workspace ? 'is-workspace' : ''}`}>
                                            <div className="items">
                                                {getEvents().map((event) => {
                                                    return (
                                                        <div
                                                            className="item"
                                                            key={`post_${event.id}`}
                                                            onClick={() =>
                                                                previewPost(
                                                                    event.extendedProps.post
                                                                )
                                                            }>
                                                            <div className="wrapper">
                                                                <div
                                                                    className="border"
                                                                    style={{
                                                                        backgroundColor:
                                                                            event.extendedProps
                                                                                .color
                                                                    }}></div>

                                                                <div className="meta">
                                                                    <div className="title">
                                                                        {event.title}
                                                                    </div>

                                                                    <div className="duration">
                                                                        <div className="icon">
                                                                            <i
                                                                                className="icon-time-clock-circle"
                                                                                aria-hidden="true"></i>
                                                                        </div>

                                                                        {!event.extendedProps
                                                                            .isMultiDay ? (
                                                                            <div className="date">
                                                                                {getFormattedDate(
                                                                                    'ddd DD MMM',
                                                                                    event
                                                                                        .extendedProps
                                                                                        .start
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="date">
                                                                                du{' '}
                                                                                {getFormattedDate(
                                                                                    'ddd DD',
                                                                                    event
                                                                                        .extendedProps
                                                                                        .start
                                                                                )}{' '}
                                                                                au{' '}
                                                                                {getFormattedDate(
                                                                                    'ddd DD MMM',
                                                                                    event
                                                                                        .extendedProps
                                                                                        .end
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="actions">
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            showActions(event);
                                                                        }}>
                                                                        <i
                                                                            className="icon-navigation-menu-horizontal"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="post">
                                                                <Post
                                                                    className={`post ${event.extendedProps.post.alreadyLiked}}`}
                                                                    post={event.extendedProps.post}
                                                                    alreadyLiked={
                                                                        event.extendedProps.post
                                                                            .alreadyLiked
                                                                    }
                                                                    download={
                                                                        event.extendedProps.post
                                                                            .download
                                                                    }
                                                                    consultations={
                                                                        event.extendedProps.post
                                                                            .post_consultations
                                                                    }
                                                                    tagsId={
                                                                        event.extendedProps.post
                                                                            .tags_id
                                                                    }
                                                                    forceUnconfirmedStyle={true}
                                                                    template={'stacked'}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="events no-events">
                                            <div className="icon">
                                                <i
                                                    className="icon-information"
                                                    aria-hidden="true"></i>
                                            </div>

                                            <div className="text">Aucun événement ce jour là</div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
