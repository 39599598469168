import useTableCeilComponent from '../hooks/useTableCeilComponent';
import { useFeedContext } from '../../../../providers/FeedProvider';
import useRight from 'hooks/useRight';
import { useCallback } from 'react';

const StatusCeil = ({ config, row }) => {
    const { getStatus, getStatusColorStyle } = useTableCeilComponent(config, row);
    const { workspace } = useFeedContext();
    const { workspacePermissionCheck } = useRight();

    const canEditPostStatus = useCallback(
        (row) => {
            if (!workspace) {
                return false;
            }
            return (
                workspacePermissionCheck({ id: workspace.id }, 'ADMIN') &&
                workspace.post_status &&
                workspace.post_status.length &&
                (row.post_status === undefined || !row.post_status.final)
            );
        },
        [workspace]
    );

    return (
        <div className="status-ceil">
            <div style={getStatusColorStyle()} className="label">
                {getStatus(config, row)}
            </div>
            <div>
                {canEditPostStatus(row) && (
                    <button ng-click="$ctrl.editPostStatus(row)">
                        <i className="fa fa-pencil"></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default StatusCeil;
