import { useConfig } from 'providers/ConfigProvider';
import { useDevice } from 'providers/DeviceContext';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import linkifyHtml from 'linkify-html';
import { defaultTo } from 'lodash-es';
import { useAlert } from 'providers/AlertContext';
import useUrl from 'hooks/useUrl';
import isVideoAttachment from '../helpers/attachment/isVideoAttachment';
import isImageAttachment from '../helpers/attachment/isImageAttachment';

const useAttachment = () => {
    const { config } = useConfig();
    const { device } = useDevice();
    const { t } = useTranslation();
    const { showConfirm } = useAlert();
    const { getUrlWithToken, openUrl } = useUrl();

    const getVideoFiles = (attachment) => {
        if (
            attachment.video &&
            attachment.video.streaming_playlists &&
            attachment.video.streaming_playlists.length === 1 &&
            attachment.video.streaming_playlists[0].files
        ) {
            return attachment.video.streaming_playlists[0].files;
        }

        return null;
    };

    const getVideoType = () => {
        if (device.isApp || config.isSafari || config.isMsie) {
            return 'video/mp4';
        }

        return 'video/webm';
    };

    const getVideoState = (attachment) => {
        if (attachment.video) {
            return attachment.video.state;
        }

        return 1;
    };

    const forgeAttachmentPoster = (attachment) => {
        if (attachment == undefined || attachment == null) {
            return;
        }

        return `${config.staticUrl}download/attachments/${attachment.id}/poster?poster=${attachment.poster}`;
    };

    const forgeAllWorkspaceAttachmentsUrl = (workspaceId, startDate, endDate) => {
        return `${config.staticUrl}download/workspace/${workspaceId}/attachments?start=${startDate}&end=${endDate}`;
    };

    const forgeAttachmentUrl = (
        attachment,
        isFull = false,
        source = null,
        forceToken = false,
        before = false,
        isSecure = false
    ) => {
        if (!attachment) {
            return;
        }

        if (typeof attachment === 'string' && attachment.indexOf('http') === 0) {
            return attachment;
        }

        if (typeof attachment === 'string' && attachment.indexOf('download') === 0) {
            return config.staticUrl + attachment;
        }

        if (source === null && isVideoAttachment(attachment)) {
            if (device.isApp || config.isSafari || config.isMsie) {
                source = 'mp4';
            } else {
                source = 'webm';
            }
        }

        if (
            device.isApp &&
            !isImageAttachment(attachment) &&
            !isVideoAttachment(attachment) &&
            device.platform === 'android' &&
            !isSecure
        ) {
            before = true;
        }

        if (before) {
            return `${config.staticUrl}before/download/attachments/${attachment.id}?isFull=${isFull}&source=${source}`;
        } else {
            return `${config.staticUrl}download/attachments/${attachment.id}/internal.json?isFull=${isFull}&source=${source}`;
        }
    };

    const forgeTmpAttachmentUrl = (attachment) => {
        if (!attachment) {
            return;
        }

        return `${config.staticUrl}download/tmp/attachments?hard_name=${attachment.hard_name}&type=${attachment.type}`;
    };

    const forgeExternalAttachmentUrl = (attachment) => {
        return `${config.staticUrl}download/attachments/${attachment.id}/external.json`;
    };

    const download = (url, securityLevel = 0, attachment = null) => {
        if (securityLevel !== 0) {
            let securityText = defaultTo(
                config.download_secure_prompt_alert,
                t('DOWNLOAD_SECURE_PROMPT_ALERT')
            );

            switch (securityLevel) {
                case 1:
                    break;
                case 2:
                    securityText = defaultTo(
                        config.download_secure_prompt_tracking,
                        t('DOWNLOAD_SECURE_PROMPT_TRACKING')
                    );
                    break;
            }

            showConfirm({
                title: t('DOWNLOAD_SECURE_PROMPT_TITLE'),
                text: linkifyHtml(securityText, {
                    nl2br: true
                }),
                classes: ['large'],
                button: {
                    label: t('DOWNLOAD_SECURE_PROMPT_CONFIRM'),
                    classes: ['bold']
                }
            })
                .then(() => {
                    forceDownload(url, attachment);
                })
                .catch(() => {});
        } else {
            forceDownload(url, attachment);
        }
    };

    const forceDownload = (url, attachment = null) => {
        if (attachment && device.isApp) {
            getUrlWithToken(url).then((urlWithToken) => {
                if (device.platform === 'android') {
                    Browser.open({ url: urlWithToken });
                } else {
                    window.PreviewAnyFile.previewPath(
                        (win) => console.log('open status', win),
                        (error) => console.error('open failed', error),
                        urlWithToken,
                        { name: attachment.name, mimeType: attachment.type }
                    );
                }
            });
        } else {
            openUrl(url);
        }
    };

    const forgeAllAttachmentsUrl = (id, inputId = null) => {
        return `${config.staticUrl}download/attachments/all/${id}/internal.json?inputId=${inputId}`;
    };

    const forgeAllPostAttachmentsUrl = (postId) => {
        return `${config.staticUrl}download/post/${postId}/attachments`;
    };

    const forgeBlogArticleContentAttachmentUrl = (attachment, isFull = false, withToken = true) => {
        return `${config.staticUrl}download/blog-article/attachments/${attachment.id}?isFull=${isFull}`;
    };

    const getPhotoGridObject = (attachments, isPostPreview) => {
        let attachmentPhotoImages = [];
        attachments.forEach((attachment, index) => {
            if (isImageAttachment(attachment)) {
                attachmentPhotoImages.push({
                    original_url: isPostPreview
                        ? forgeTmpAttachmentUrl(attachment)
                        : forgeAttachmentUrl(attachment),
                    nth: index
                });
            }
        });

        return attachmentPhotoImages;
    };

    return {
        forgeAttachmentPoster,
        download,
        forgeAttachmentUrl,
        getVideoFiles,
        getVideoState,
        forgeTmpAttachmentUrl,
        forgeExternalAttachmentUrl,
        forgeAllAttachmentsUrl,
        forgeAllPostAttachmentsUrl,
        forgeBlogArticleContentAttachmentUrl,
        getVideoType,
        getPhotoGridObject,
        forgeAllWorkspaceAttachmentsUrl
    };
};

export default useAttachment;
