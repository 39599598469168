import React from 'react';
import { useTranslation } from 'react-i18next';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import { useConfig } from 'providers/ConfigProvider';
import Shared from './shared';

const Product = ({ campaign }) => {
    const { t } = useTranslation();
    const { config: appConfig } = useConfig();

    const {
        isClosed,
        isDraft,
        isRunning,
        closeCampaign,
        cloneCampaign,
        deleteCampaign,
        draftCampaign,
        forgeDownloadCSVUrl
    } = useCampaignsCustomRender(campaign);

    const timestamp = new Date().getTime();

    const forgeDownloadRespondentsCSVUrl = (productCampaignId, timestamp) => {
        return `${appConfig.apiUrl}/dashboard/products/respondents/${productCampaignId}/campaign/download?nocache=${timestamp}`;
    };

    const productCampaignService = () => {
        // Implement your logic here
        console.log('in progress');
    };

    const toggleDistributedPoints = () => {};
    const goToActivities = () => {};
    const togglePreview = () => {};

    const formatAVGAnswer = (value) => value.toFixed(2);

    return (
        <Shared campaign={campaign}>
            <div className={`informations ${campaign.type}`}>
                <div className="details">
                    <div className="stats">
                        {campaign.reward > 0 && (
                            <div className="stat application" onClick={toggleDistributedPoints}>
                                <div className="icon">
                                    <i className="fa fa-money" aria-hidden="true"></i>
                                </div>
                                <div className="text">
                                    {campaign.total_rewards} {t('TA_CAMPAIGN_POINTS_GIVEN')}
                                </div>
                            </div>
                        )}
                        {campaign.total_unique_answers > 0 && (
                            <div className="stat application not-pointer">
                                <div className="icon">
                                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                                </div>
                                <div className="text">
                                    {t('PRODUCT_CORRECT_ANSWERS', {
                                        number: campaign.total_answers_correct
                                    })}
                                </div>
                            </div>
                        )}
                        {campaign.total_unique_answers > 0 && (
                            <div className="stat application not-pointer">
                                <div className="icon">
                                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                                </div>
                                <a
                                    href={forgeDownloadRespondentsCSVUrl(campaign.id, timestamp)}
                                    target="_blank"
                                    className="text"
                                    rel="noreferrer">
                                    {t('PRODUCT_UNIQUE_ANSWERS', {
                                        number: campaign.total_unique_answers
                                    })}
                                </a>
                            </div>
                        )}
                        {campaign.total_answers_correct > 0 && (
                            <div className="stat application not-pointer">
                                <div className="icon">
                                    <i className="fa fa-bar-chart-o" aria-hidden="true"></i>
                                </div>
                                <div className="text">
                                    {t('PRODUCT_INCORRECT_ANSWERS', {
                                        number: formatAVGAnswer(
                                            (campaign.total_answers_correct +
                                                campaign.total_answers_incorrect) /
                                                campaign.total_unique_answers
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        {campaign.total_unique_answers > 0 && (
                            <div className="stat application not-pointer">
                                <div className="icon">
                                    <i className="fa fa-bar-chart-o" aria-hidden="true"></i>
                                </div>
                                <div
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                        __html: t('PRODUCT_PARTICIPATION_RATE', {
                                            number: formatAVGAnswer(
                                                (campaign.total_answers_correct /
                                                    campaign.total_unique_answers) *
                                                    100
                                            )
                                        })
                                    }}></div>
                            </div>
                        )}
                        {(campaign.opportunity || campaign.email_opportunity) && (
                            <div className="stat application" onClick={goToActivities}>
                                <div className="icon">
                                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                                </div>
                                <div className="text">
                                    {campaign.submits.length}{' '}
                                    {t('TA_CAMPAIGN_PRODUCT_TOTAL_SUBMITS')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="actions">
                    {campaign.reward > 0 && (
                        <div className="reward">
                            <div className="unique">
                                {campaign.reward} {t('POINTS')}
                            </div>
                        </div>
                    )}
                    <div className="buttons">
                        {campaign.submits && campaign.submits.length > 0 && (
                            <a
                                href={forgeDownloadCSVUrl(timestamp)}
                                target="_blank"
                                className="button grey"
                                rel="noreferrer">
                                {t('TA_CAMPAIGN_EXPORT')}
                            </a>
                        )}
                        {campaign.total_answers_correct > 0 && (
                            <a
                                href={forgeDownloadRespondentsCSVUrl(campaign.id, timestamp)}
                                target="_blank"
                                className="button grey"
                                rel="noreferrer">
                                {t('TA_CAMPAIGN_EXPORT_PRODUCT_ANSWER')}
                            </a>
                        )}
                        {(isDraft || isRunning) && (
                            <>
                                <button className="button grey" onClick={closeCampaign}>
                                    {t('TA_CAMPAIGN_CLOSE')}
                                </button>
                                <button className="button grey" onClick={() => {}}>
                                    {t('TA_CAMPAIGN_EDIT')}
                                </button>
                            </>
                        )}
                        <button className="button grey" onClick={cloneCampaign}>
                            {t('TA_CAMPAIGN_DUPLICATE')}
                        </button>
                        <button className="button grey" onClick={togglePreview}>
                            {t('TA_CAMPAIGN_PREVIEW')}
                        </button>
                        {isClosed && (
                            <button className="button grey" onClick={draftCampaign}>
                                {t('TA_CAMPAIGN_MOVE_DRAFT')}
                            </button>
                        )}
                        {!isRunning && (
                            <button className="button red" onClick={deleteCampaign}>
                                {t('TA_CAMPAIGN_DELETE')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Shared>
    );
};

export default Product;
