import React from 'react';
import Modal from '../Modal/Modal';
import { useComposer } from 'pages/Auth/providers/ComposerContext';
import Composer from './Composer';

const ComposerModal = () => {
    const { currentStepSubtitle, currentStepTitle } = useComposer();

    return (
        <Modal
            name={'composer'}
            subTitle={currentStepSubtitle}
            title={currentStepTitle}
            options={{ showPrevButton: true }}>
            <div className={'composer-wrapper'}>
                <Composer />
            </div>
        </Modal>
    );
};

export default ComposerModal;
