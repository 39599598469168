import { cloneDeep, includes, toLower, omit, upperFirst } from 'lodash-es';
import useRight from 'hooks/useRight';
import { useTreeContext } from '../../providers/TreeProvider';
import useTree from 'hooks/useTree';
import { useMemo } from 'react';
import { useChannels as useChannelsContext } from '../../providers/ChannelsProvider';
import { useTagContext } from '../../providers/TagProvider';
import useUserService from 'api/useUserService';
import useUtils from 'hooks/useUtils';
import removeAccents from 'remove-accents';

export const SEARCHABLES = [
    'workspaces',
    //'campaigns',
    'channels',
    'tags',
    'sharedTags',
    'users',
    'categories',
    'folders'
];

const useSearchBoxService = () => {
    const { hasRole } = useRight();
    const { tree } = useTreeContext();
    const { channels } = useChannelsContext();
    const { tags, adminTags: sharedTags } = useTagContext();
    const { getOnlyWorkspace, getOnlyCategory, getOnlyFolder } = useTree();
    const { findUsersByCriteria } = useUserService();
    const { orderByPosition, removeEmptyTopLevelCategories } = useUtils();

    const workspaces = useMemo(() => {
        return getOnlyWorkspace(tree);
    }, [tree]);

    const categories = useMemo(() => {
        const orderedTree = orderByPosition(removeEmptyTopLevelCategories(cloneDeep(tree)));
        return getOnlyCategory(orderedTree);
    }, [tree]);

    const folders = useMemo(() => {
        return getOnlyFolder(tree);
    }, [tree]);

    const onQueryChange = (query) => {
        if (!query) {
            return null;
        }
        let promises = SEARCHABLES.map((searcheable) => {
            return search(query, searcheable).then((results) => ({
                [searcheable]: results
            }));
        });

        return Promise.all(promises).then((resultsArray) => {
            return resultsArray.reduce((acc, result) => ({ ...acc, ...result }), {});
        });
    };

    const search = (query, searcheable) => {
        const searchFunctions = {
            searchCategories,
            searchWorkspaces,
            searchFolders,
            searchTags,
            searchSharedTags,
            searchUsers,
            searchChannels
        };

        const searchFunction = searchFunctions[`search${upperFirst(searcheable)}`];
        if (searchFunction) {
            return searchFunction(query).then((results) => {
                return processResults(results, searcheable);
            });
        }
        return Promise.resolve([]);
    };

    const processResults = (results, searcheable) => {
        return results && results.length > 0 ? { [searcheable]: results } : {};
    };

    const searchCategories = (query) => {
        return Promise.resolve(
            categories.filter((category) => {
                return includes(normalize(category.name), query);
            })
        );
    };

    const searchWorkspaces = (query) => {
        return Promise.resolve(
            workspaces.filter((workspace) => {
                return includes(normalize(workspace.workspace_data.workspace.name), query);
            })
        );
    };

    const searchFolders = (query) => {
        return Promise.resolve(
            folders.filter((folder) => {
                return includes(normalize(folder.name), query);
            })
        );
    };

    /*const searchCampaigns = (query) => {
        return Promise.resolve(
            campaigns.filter((campaign) => {
                return includes(normalize(campaign.title), query);
            })
        );
    };*/

    const searchChannels = (query) => {
        return Promise.resolve(
            channels.filter((channel) => {
                return (
                    includes(normalize(channel.name), query) ||
                    channel.users.some((user) => {
                        return (
                            includes(normalize(user.first_name), query) ||
                            includes(normalize(user.last_name), query)
                        );
                    })
                );
            })
        );
    };

    const searchTags = (query) => {
        return Promise.resolve(
            tags.filter((tag) => {
                return !tag.admin && includes(normalize(tag.name), query);
            })
        );
    };

    const searchSharedTags = (query) => {
        return Promise.resolve(
            sharedTags.filter((sharedTag) => {
                return includes(normalize(sharedTag.name), query);
            })
        );
    };

    const searchUsers = (query) => {
        if (hasRole('ROLE_HIDE_LIST_USERS')) {
            return Promise.resolve([]);
        }

        return findUsersByCriteria(query);
    };

    const getWorkspaceDataForFolder = (folder) => {
        return workspaces.find((workspaceData) => {
            return workspaceData.workspace_data.workspace.id === folder.workspace_id;
        });
    };

    const normalize = (text) => {
        return removeAccents(toLower(text));
    };

    return {
        onQueryChange,
        normalize,
        getWorkspaceDataForFolder
    };
};

export default useSearchBoxService;
