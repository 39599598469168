import React, { useState } from 'react';
import { useAdminLevelContext } from '../../providers/AdminLevelProvider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useLevelService from 'api/useLevelService';
import AdminLevelRightRow from './components/AdminLevelRightRow';
import LevelRuleModal from './modals/LevelRuleModal';
import LevelConfigModal from './modals/LevelConfigModal';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';
import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import { Button } from 'antd';

const LevelsConfigTable = () => {
    const { levelConfigs, isLoadingLevelConfigs } = useAdminLevelContext();
    const { openModal } = useModal();
    const { showConfirm, showToast } = useAlert();
    const queryClient = useQueryClient();
    const { deleteLevelConfig } = useLevelService();

    const deleteLevelConfigAction = (levelConfig) => {
        showConfirm({
            title: `Supprimer`,
            text: `Voulez-vous supprimer cette configuration de level ?`,
            button: {
                label: 'Supprimer',
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                deleteLevelConfig(levelConfig).then(() => {
                    showToast({
                        text: `La configuation du level a bien été supprimée.`,
                        duration: 1500
                    });
                    queryClient.refetchQueries({ queryKey: ['levels'] });
                });
            })
            .catch((err) => {});
    };
    const COLUMNS = [
        {
            id: 'name',
            header: 'Nom',
            sortable: true
        },
        {
            id: 'index',
            header: 'Index',
            sortable: true
        }
    ];

    return (
        <Table
            columns={COLUMNS}
            data={levelConfigs}
            isLoading={isLoadingLevelConfigs}
            actionsAsButtons
            handleActions={[
                {
                    label: 'ADMIN_MENU_BRANDS_EDIT_BRAND_LABEL',
                    handler: (level) => {
                        openModal('add_level_config', {
                            currentLevelConfig: level
                        });
                    }
                },
                {
                    label: 'Supprimer',
                    danger: true,
                    handler: (row) => {
                        deleteLevelConfigAction(row);
                    }
                }
            ]}
        />
    );
};

const Levels = () => {
    const { getAdminLevelRights } = useLevelService();
    const [showMore, setShowMore] = useState();

    const { data: adminLevelRights } = useQuery({
        queryKey: ['adminLevelRights'],
        queryFn: () => getAdminLevelRights()
    });

    return (
        <>
            <LevelRuleModal />
            <LevelConfigModal />
            <AdminHeader
                title={'ADMIN_LEVEL_LEVELS_TITLE'}
                modalName={'add_level_config'}
                modalButtonTitle={'ADMIN_LEVEL_ADD_LEVEL_CONFIG'}
            />
            <LevelsConfigTable />

            <div style={{ paddingTop: 20 }}>
                <AdminHeader
                    title={'ADMIN_LEVEL_RULES_TITLE'}
                    modalName={'add_level_rule'}
                    modalButtonTitle={'ADMIN_LEVEL_ADD_RULE'}>
                    <Button onClick={() => setShowMore(!showMore)}>
                        {!showMore ? (
                            <span>
                                <i className="icon-search" aria-hidden="true"></i> Afficher les
                                détails
                            </span>
                        ) : (
                            <span>
                                <i className="icon-remove-circle" aria-hidden="true"></i> Cacher les
                                détails
                            </span>
                        )}
                    </Button>
                </AdminHeader>
            </div>

            <div className="container admin__levels">
                {adminLevelRights &&
                    adminLevelRights.map((adminLevelRight) => {
                        return (
                            <AdminLevelRightRow
                                adminLevelRight={adminLevelRight}
                                showMore={showMore}
                                key={adminLevelRight.id}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default Levels;
