import React from 'react';
import Comment from 'components/Comment/Comment';
import { usePost } from '../../providers/PostProvider';

const PostAddComment = () => {
    const { post, commentSubmitCallbackHandler } = usePost();
    return (
        <div className="post_add-comment">
            <Comment
                postItem={post}
                submitCallback={commentSubmitCallbackHandler}
                hasFocus={post.focusComment}
                postStatus={post.post_status}
            />
        </div>
    );
};

export default PostAddComment;
