import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import { useForm } from 'components/Form/hooks';
import { Input, Checkbox, Radio, Select, Textarea } from 'components/Form/Inputs';
import { useConfig } from 'providers/ConfigProvider';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import notificationFormSchema from '../schemas/notificationForm.schema.json';
import { useAdminOrganizationalUnitContext } from '../../../providers/AdminOrganizationalUnitProvider';
import { useAdminTreeContext } from '../../../providers/AdminTreeProvider';
import useTreeHook from 'hooks/useTree';
import { orderBy } from 'lodash-es';
import useLevel from 'hooks/useLevel';
import useUserService from 'api/useUserService';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';

const SendNotificationModal = (options = {}) => {
    const { needRedirectSelection = true, levels = null } = options;
    const { levelConfigs, getLevelName, levels: allLevels } = useAdminLevelContext();
    const { fullTree } = useAdminTreeContext();
    const { getOnlyCategory } = useTreeHook();
    const { allOrganizationalUnits } = useAdminOrganizationalUnitContext();
    const { levelsGroupByIndex } = useLevel();
    const { sendNotification } = useUserService();
    const { showToast } = useAlert();
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const methods = useForm({
        schema: notificationFormSchema
    });
    const { config } = useConfig();

    const REDIRECTION_OPTIONS = [
        {
            value: '/posts',
            label: t('NOTIFICATION_REDIRECTION_POSTS')
        },
        {
            value: '/campaigns/all/running',
            label: t('NOTIFICATION_REDIRECTION_CAMPAIGNS')
        },
        {
            value: '/misc/profile?page=1&editing=true',
            label: t('NOTIFICATION_REDIRECTION_PROFILE')
        },
        {
            value: 'custom_route',
            label: t('CUSTOM_ROUTE')
        }
    ];

    const onFinish = (values) => {
        const payload = values;
        if (payload.custom_route) {
            payload.custom_route = payload.custom_route.replace(config.serverUrl, '');
        }

        sendNotification(payload).then(
            (data) => {
                showToast({
                    text: t('NOTIFICATION_SENT_SUCCESS'),
                    duration: 1500
                });
                closeModal('send-notification');
            },
            () => {
                showToast({
                    text: t('NOTIFICATION_SENT_ERROR'),
                    duration: 1500
                });
            }
        );
    };

    return (
        <Modal
            name={`send-notification`}
            title={t('ADMIN_USERS_SEND_NOTIFICATION_LABEL')}
            options={{ hasPaddedContent: true, zIndex: 301 }}>
            <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
                <ModalFormBody>
                    <ModalFormSection>
                        <div>
                            <label className="title" htmlFor="title">
                                {t('NOTIFICATION_TITLE_LABEL')}
                            </label>
                            <Input type={'text'} name={'title'} />
                        </div>

                        <div>
                            <label className="title" htmlFor="message">
                                {t('NOTIFICATION_MESSAGE_LABEL')}
                            </label>
                            <Textarea name={'message'} />
                        </div>

                        {needRedirectSelection && !levels && (
                            <div>
                                <label className="title">
                                    {t('NOTIFICATION_REDIRECTION_LABEL')}
                                </label>
                                <Select name="redirection.value" options={REDIRECTION_OPTIONS} />
                            </div>
                        )}

                        {methods.watch('redirection.value') === 'custom_route' && !levels && (
                            <div>
                                <label className="title" htmlFor="custom_route">
                                    {t('FOLDER_POST_TYPE_LINK')} ({t('ROUTE_WITHOUT_DOMAIN_NAME')} "
                                    {config.serverUrl}")
                                </label>

                                <Input type={'text'} name={'custom_route'} />
                            </div>
                        )}
                    </ModalFormSection>

                    <ModalFormSection>
                        <div className="title">{t('TA_CREATE_CAMPAIGN_TARGET_TITLE')}</div>

                        {levels && (
                            <div className="field">
                                <div className="radio">
                                    <div className="label title">Levels</div>

                                    {levels.map((level, index) => {
                                        return <div key={`level_${index}`}>{level}</div>;
                                    })}
                                </div>
                            </div>
                        )}

                        {needRedirectSelection && (
                            <Checkbox
                                name="only_uncompleted_profile"
                                label={t('NOTIFICATION_ONLY_UNCOMPLETED_PROFILE_LABEL')}
                            />
                        )}

                        {!levels && (
                            <div>
                                <Radio
                                    name={`notificationTarget`}
                                    options={[
                                        {
                                            value: 3,
                                            label: t('TA_CREATE_CAMPAIGN_TARGET_WORKSPACE')
                                        },
                                        {
                                            value: 4,
                                            label: t(
                                                'TA_CREATE_CAMPAIGN_SELECT_TARGET_BY_SEGMENTATION'
                                            )
                                        },
                                        {
                                            value: 5,
                                            label: t('BY_OU')
                                        }
                                    ]}
                                />
                            </div>
                        )}

                        {methods.watch('notificationTarget') === '3' && !levels && (
                            <div>
                                <div className="title">
                                    {t('TA_CREATE_CAMPAIGN_SELECT_WORKSPACES')}
                                </div>

                                <div className="field">
                                    <Select
                                        mode={'multiple'}
                                        name={'workspaces_target'}
                                        options={orderBy(getOnlyCategory(fullTree), ['name']).map(
                                            (category) => {
                                                return {
                                                    label: category.name,
                                                    title: category.name,
                                                    options: orderBy(category.workspaces, [
                                                        'name'
                                                    ]).map((workspace) => {
                                                        return {
                                                            label: workspace.name,
                                                            value: workspace.id
                                                        };
                                                    })
                                                };
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                        )}

                        {methods.watch('notificationTarget') === '4' && !levels && (
                            <div>
                                <div className="title">{t('TA_CREATE_CAMPAIGN_SELECT_LEVELS')}</div>
                                <Select
                                    name={'levels_target_index'}
                                    options={[
                                        {
                                            value: '',
                                            label: t(
                                                'ADMIN_MENU_HELPDESK_COMMON_CHOOSE_LEVEL_CRITERA_LABEL'
                                            )
                                        },
                                        ...levelConfigs.map((levelConfig) => {
                                            return {
                                                value: levelConfig.index,
                                                label: getLevelName(levelConfig.index)
                                            };
                                        })
                                    ]}
                                />

                                {allLevels &&
                                    Object.entries(levelsGroupByIndex(allLevels)).map(
                                        ([levelIndex, levelList]) => {
                                            if (
                                                methods.watch('levels_target_index') !==
                                                parseInt(levelIndex)
                                            ) {
                                                return;
                                            }
                                            return (
                                                <div key={`level_${levelIndex}`} className="field">
                                                    <div
                                                        className="title"
                                                        style={{
                                                            paddingTop: 10
                                                        }}>
                                                        Sélectionner 1 ou plusieurs levels
                                                    </div>
                                                    <Select
                                                        name={'levels_target'}
                                                        mode={'multiple'}
                                                        options={orderBy(levelList, ['name']).map(
                                                            (level) => {
                                                                return {
                                                                    label: level.name,
                                                                    value: level.id
                                                                };
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                        )}

                        {methods.watch('notificationTarget') === '5' && !levels && (
                            <div>
                                <label className="title" htmlFor={'organizationalUnits'}>
                                    {t('ORGANIZATIONAL_UNITS')}
                                </label>
                                <Select
                                    name={'organizationalUnits'}
                                    mode={'multiple'}
                                    options={allOrganizationalUnits.map((ou) => {
                                        return {
                                            value: ou.id,
                                            label: ou.name
                                        };
                                    })}
                                />
                            </div>
                        )}
                    </ModalFormSection>
                </ModalFormBody>

                <ModalFormFooter
                    onCancel={() => {
                        closeModal('send-notification');
                    }}
                />
            </ModalForm>
        </Modal>
    );
};

export default SendNotificationModal;
