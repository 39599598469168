import React from 'react';

import useContent from 'hooks/useContent';

const Collection = ({ input }) => {
    const { formatContent } = useContent();

    const renderValues = () => {
        return input.values?.map((value, index) => {
            return (
                <div className="render_collection" key={index}>
                    <span
                        className="value"
                        dangerouslySetInnerHTML={{ __html: formatContent(value.text) }}
                    ></span>
                    {value.qualification && (
                        <span
                            className="qualification"
                            dangerouslySetInnerHTML={{
                                __html: `(${formatContent(value.qualification)})`
                            }}
                        ></span>
                    )}
                </div>
            );
        });
    };

    return (
        <>
            {input.workspace_form_input.label_render !== '' && (
                <div className="render_label inline">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}

            {renderValues()}

            {!input.values && input.text && (
                <div>
                    <span className="value">{input.text}</span>
                </div>
            )}
        </>
    );
};

export default Collection;
