import isImageAttachment from './isImageAttachment';

export default function getAllImages(attachments) {
    if (!attachments || attachments.length === 0) {
        return [];
    }

    return attachments.filter((attachment) => {
        if (!attachment) return false;

        return isImageAttachment(attachment);
    });
}
