import { useState, useEffect } from 'react';

function useViewport() {
    const [viewportSize, setViewportSize] = useState({
        xs: false,
        gtXs: false,
        sm: false,
        gtSm: false,
        md: false,
        gtMd: false,
        lg: false,
        gtLg: false,
        xl: false
    });

    useEffect(() => {
        const mediaQueries = {
            xs: '(max-width: 599px)',
            gtXs: '(min-width: 600px)',
            sm: '(min-width: 600px) and (max-width: 959px)',
            gtSm: '(min-width: 960px)',
            md: '(min-width: 960px) and (max-width: 1279px)',
            gtMd: '(min-width: 1280px)',
            lg: '(min-width: 1280px) and (max-width: 1919px)',
            gtLg: '(min-width: 1920px)',
            xl: '(min-width: 1920px)'
        };

        const mediaQueryLists = {};
        const listeners = {};

        const evaluateMediaQueries = () => {
            const results = {};
            for (const key in mediaQueries) {
                results[key] = mediaQueryLists[key].matches;
            }
            setViewportSize(results);
        };

        for (const key in mediaQueries) {
            mediaQueryLists[key] = window.matchMedia(mediaQueries[key]);
            listeners[key] = () => evaluateMediaQueries();
            mediaQueryLists[key].addEventListener('change', listeners[key]);
        }

        evaluateMediaQueries();

        return () => {
            for (const key in mediaQueries) {
                mediaQueryLists[key].removeEventListener('change', listeners[key]);
            }
        };
    }, []);

    return viewportSize;
}

export default useViewport;
