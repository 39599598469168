import React from 'react';
import { UIView, useRouter } from '@uirouter/react';
import SharedDocumentProvider, {
    useShareDocument as useShareDocumentContext
} from './providers/SharedDocumentProvider';
import { useTranslation } from 'react-i18next';
import SharedDocumentCategories from './components/SharedDocumentCategories';

const SharedDocumentsContent = () => {
    const { parentCategory, breadCrumb, breadcrumbArticle } = useShareDocumentContext();
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <>
            {/*<share-document-category-panel
                    ng-if="$ctrl.SharedDocuments.canSeeAdminActionsForCategory($ctrl.parentCategory)"></share-document-category-panel>
                <share-document-article-panel
                    ng-if="$ctrl.SharedDocuments.canSeeAdminActionsForCategory($ctrl.parentCategory)"></share-document-article-panel>

                <modal md-name="shared_documents_recents"
                       md-title="'Documents les plus récents'"
                       ng-if="$ctrl.getLatestResources().length > 0">
                    <div className="resources">
                        <div className="resource"
                             ng-class="{ 'is-image': $ctrl.SharedDocuments.isImage(resource) }"
                             ng-repeat="resource in $ctrl.getLatestResources() track by resource.id">
                            <div className="thumbnail" ng-if="$ctrl.SharedDocuments.isImage(resource)">
                                <img ng-src="{{ $ctrl.Attachment.forgeAttachmentUrl(resource.attachment, true) }}"/>
                            </div>

                            <div className="icon" ng-if="!$ctrl.SharedDocuments.isImage(resource)">
                                <i className="fa"
                                   ng-if="$ctrl.SharedDocuments.isFile(resource)"
                                   ng-class="$ctrl.attachmentIcon(resource.attachment, 'class')"
                                   ng-style="{color: $ctrl.attachmentIcon(resource.attachment, 'color')}"></i>

                                <i className="fa"
                                   ng-if="!$ctrl.SharedDocuments.isFile(resource)"
                                   ng-class="$ctrl.SharedDocuments.getIconResource(resource)"></i>
                            </div>

                            <div className="meta">
                                <div className="label">{{resource.label}}</div>
                                <div className="date">{{
                                    $ctrl
                                    .translate('CREATED_AT', {date: $ctrl.getFormatedDate(resource.created_at)})
                                }}</div>
                                <div className="date" ng-if="resource.updated_at">{{
                                    $ctrl
                                    .translate('UPDATED_AT', {date: $ctrl.getFormatedDate(resource.updated_at)})
                                }}</div>
                                <div className="size" ng-if="$ctrl.SharedDocuments.isImage(resource)">{{
                                    $ctrl
                                    .translate('FILE_IMAGE_SIZE')
                                }} : {{$ctrl.SharedDocuments.getImageSize(resource)}}</div>
                            </div>

                            <div className="actions">
                                <div className="action"
                                     ng-click="$ctrl.SharedDocuments.resourceAction(resource)"
                                     ng-if="!$ctrl.onlyVisualization(resource)">
                                    {{$ctrl.getLabelButton(resource)}}
                                </div>

                                <div className="action"
                                     ui-sref="auth.sharedDocuments.categories.article({ articleId: resource.article_id, categoryId: resource.category_id })"
                                     ui-sref-opts="{ reload: true, inherit: false }">
                                    {{$ctrl.translate('SHARED_DOCUMENT_RESOURCE_SEE_ARTICLE')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </modal>*/}

            <div className="shared-documents-categories">
                <div className="breadcrumb">
                    {parentCategory ? (
                        <div className="items">
                            {breadCrumb &&
                                breadCrumb.length > 0 &&
                                breadCrumb.map((category, index) => {
                                    return (
                                        <a
                                            className="item"
                                            href={router.stateService.href(
                                                'auth.sharedDocuments.categories',
                                                { categoryId: category.id }
                                            )}
                                            key={`breadcrumb_${category.id}`}
                                        >
                                            {index === 0 && (
                                                <div className="icon">
                                                    <i
                                                        className="icon-house-chimney-1"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                            )}

                                            <div className="name">{category.name}</div>
                                        </a>
                                    );
                                })}

                            {breadcrumbArticle && (
                                <div className="item">
                                    <div className="name">{breadcrumbArticle.title}</div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="items">
                            <div className="item">
                                <div className="icon">
                                    <i className="icon-house-chimney-1" aria-hidden="true"></i>
                                </div>

                                <div className="name">{t('ADMIN_MENU_SHARED_DOCUMENTS_LABEL')}</div>
                            </div>
                        </div>
                    )}
                </div>

                <UIView>
                    <SharedDocumentCategories />
                </UIView>
            </div>
        </>
    );
};

const SharedDocuments = () => {
    return (
        <SharedDocumentProvider>
            <div className="shared-documents-categories-wrapper">
                <SharedDocumentsContent />
            </div>
        </SharedDocumentProvider>
    );
};

export default SharedDocuments;
