import React, { useEffect, useState } from 'react';
import { UIView, useCurrentStateAndParams, useRouter } from '@uirouter/react';
import useViewport from 'hooks/useViewport';
import { useQuery } from '@tanstack/react-query';
import useRuleService from 'api/useRuleService';
import useRight from 'hooks/useRight';
import { useTranslation } from 'react-i18next';
import TreeModal from '../Auth/modals/TreeModal';

const Campaigns = () => {
    const router = useRouter();
    const { hasRole } = useRight();
    const viewport = useViewport();
    const { t } = useTranslation();
    const { getRule } = useRuleService();
    const { params } = useCurrentStateAndParams();

    const [state, setState] = useState(null);
    const [type, setType] = useState(params.type || 'product');

    useEffect(() => {
        setType(params.type || 'product');
    }, [params]);

    useEffect(() => {
        setState(router.globals.current.name);
    }, [router.globals.current.name]);

    const { data: rules } = useQuery({
        queryKey: ['rules_general', 'rules'],
        queryFn: () => {
            return getRule('general');
        }
    });

    return (
        <div className="campaigns-user-wrapper">
            <div className={`ta-user-campaigns ${state}`}>
                <div className="sidebar">
                    <TreeModal
                        name={'campaign_tree'}
                        title={t('FILTER_BY_CAMPAIGN')}
                        options={{ isSidebar: true }}
                        page={'campaigns'}
                    />
                </div>

                <div className="view">
                    <UIView />
                </div>

                <div className="sidebar">
                    {/*<campaigns-widget show-all="true" filter-mode="true" selected-type="$ctrl.type"
                                      ng-if="$ctrl.User.hasTA()"></campaigns-widget>*/}

                    {rules && viewport.gtXs && hasRole('ROLE_INTERNAL') && (
                        <div className="actions">
                            <button className="show-rules" ng-click="$ctrl.showGeneralRules()">
                                <div>
                                    <span>{t('TA_CAMPAIGN_REWARD_HOW_USE')}</span>
                                    <span>{t('TA_CAMPAIGN_REWARD_MY_POINTS')}</span>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Campaigns;
