import AdminHeader from '../../../../components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React, { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTagService from 'api/useTagService';
import TagsNewModal from './modals/TagsNewModal';
import { useTranslation } from 'react-i18next';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';

const Tags = () => {
    const { getAllAdminTags, deleteTag } = useTagService();
    const { t } = useTranslation();
    const router = useRouter();
    const { showConfirm, showToast } = useAlert();
    const { params } = useCurrentStateAndParams();
    const [searchTag, setSearchTag] = useState(params.searchTag);
    const { openModal } = useModal();

    const handleFilter = useCallback(
        (params) => {
            router.stateService.go('.', params);
        },
        [router.stateService]
    );

    const filterTags = (rows) => {
        if (!rows) return [];
        let filteredRows = rows;
        if (params.isEssential) {
            filteredRows = filteredRows.filter((rows) => rows.isEssential);
        }

        if (params.searchTag) {
            filteredRows = filteredRows.filter(
                (rows) => rows.name.toLowerCase().indexOf(params.searchTag.toLowerCase()) !== -1
            );
        }

        return filteredRows;
    };

    const COLUMNS = [
        {
            id: 'position',
            header: 'ADMIN_MENU_HELPDESK_COMMON_POSITION_LABEL',
            sortable: true,
            center: true
        },
        {
            id: 'id',
            header: 'Id',
            sortable: true
        },
        {
            id: 'name',
            header: 'ADMIN_MENU_BRANDS_NAME_LABEL',
            sortable: true
        },
        {
            id: 'isEssential',
            header: 'ADMIN_TAGS_IS_ESSENTIAL',
            cell: ({ cell }) => (cell.getValue() ? 'Oui' : 'Non'),
            sortable: true,
            center: true
        },
        {
            id: 'isWeeklyListing',
            header: 'ADMIN_TAGS_IS_WEEKLY_LISTING_SHORT',
            cell: ({ cell }) => (cell.getValue() ? 'Oui' : 'Non'),
            sortable: true,
            center: true
        },
        {
            id: 'isPostsStacked',
            header: 'ADMIN_TAGS_IS_POST_STACKED_SHORT',
            cell: ({ cell }) => (cell.getValue() ? 'Oui' : 'Non'),
            sortable: true,
            center: true
        },
        {
            id: 'organizationalUnits',
            header: 'TAG_ORGANIZATIONAL_UNIT_LABEL',
            cell: ({ cell }) =>
                cell
                    .getValue()
                    .map((_) => _.name)
                    .join(', '),
            sortable: true
        },
        {
            id: 'tagUsers',
            header: 'TAG_USERS_LABEL',
            cell: ({ cell }) =>
                cell
                    .getValue()
                    .map((_) => _.email)
                    .join(', '),
            sortable: true
        }
    ];

    const {
        data: tags,
        isLoading,
        refetch
    } = useQuery({
        queryKey: ['tags'],
        queryFn: () => getAllAdminTags()
    });

    return (
        <>
            <TagsNewModal />

            <AdminHeader
                title={'Gestion des tags partagés'}
                modalName={'add_tag'}
                modalButtonTitle={'ADD_ADMIN_TAGS'}
            />

            <div className="admin__users__letters">
                <div className="option">
                    <label htmlFor="isEssential">{t('ADMIN_SEARCH_ESSENTIAL_TAGS')}</label>
                    <input
                        type="checkbox"
                        name={'isEssential'}
                        id={'isEssential'}
                        checked={!!params.isEssential}
                        onChange={(e) => handleFilter({ ...params, isEssential: e.target.checked })}
                    />
                </div>

                <form className="search-form-tree">
                    <input
                        type="text"
                        value={searchTag}
                        onChange={(e) => setSearchTag(e.target.value)}
                        placeholder={t('ADMIN_SEARCH_TAGS')}
                    />
                    <button
                        onClick={() => {
                            if (searchTag) {
                                handleFilter({ ...params, searchTag: searchTag });
                            } else {
                                handleFilter({ ...params, searchTag: null });
                            }
                        }}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>

            <Table
                columns={COLUMNS}
                data={filterTags(tags)}
                isLoading={isLoading}
                filters={params}
                handleActions={[
                    {
                        label: 'Éditer',
                        handler: (row) => {
                            openModal('add_tag', {
                                ...row
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        handler: (row) => {
                            showConfirm({
                                title: t('TAG_DELETE_CONFIRM_TITLE'),
                                text: t('TAG_DELETE_CONFIRM_TEXT'),
                                button: {
                                    label: t('TAG_DELETE_CONFIRM_YES'),
                                    classes: ['red', 'bold']
                                }
                            }).then(() => {
                                deleteTag(row)
                                    .then(() => {
                                        showToast({ text: t('TAG_DELETE_DONE') });

                                        refetch();
                                    })
                                    .then((data) => {
                                        console.log(data);
                                    });
                            });
                        }
                    }
                ]}
            />
        </>
    );
};
export default Tags;
