import { useConfig } from 'providers/ConfigProvider';

const useMetric = () => {
    const { config } = useConfig();

    const forgeDownloadAllPosts = (params) => {
        let timestamp = Math.floor((new Date().getTime() / 1000) * 60);

        let url = `${config.staticUrl}all/posts/download.json?period=${params.period}&nocache=${timestamp}`;

        if (params.startDate) {
            url = url + `&start=${params.startDate}`;
        }

        if (params.endDate) {
            url = url + `&end=${params.endDate}`;
        }

        if (params.tagIds) {
            url = url + `&tagIds=${params.tagIds}`;
        }

        return url;
    };

    return {
        forgeDownloadAllPosts
    };
};

export default useMetric;
