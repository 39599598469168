import { useHttp } from './useHttp';

const useSearchService = () => {
    const axios = useHttp();

    const getUsers = (params, fromAdmin) => {
        return axios
            .get(`/search/users`, {
                params: { ...params, fromAdmin }
            })
            .then((response) => {
                return response.data;
            });
    };

    const getAllUsers = (params, fromAdmin = false) => {
        return axios
            .get(`/users`, {
                params: { ...params, fromAdmin }
            })
            .then((response) => response.data);
    };

    return {
        getUsers,
        getAllUsers
    };
};

export default useSearchService;
