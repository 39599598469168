import { useAuth } from 'providers/AuthContext';
import { useConfig } from 'providers/ConfigProvider';
import { isArray, some } from 'lodash-es';
import { useMemo } from 'react';

const useRight = () => {
    const { user, roles } = useAuth();
    const { config } = useConfig();

    const userHasRole = (role) => {
        if (!user || !roles) {
            return false;
        }

        return roles.indexOf(role) !== -1 || config[role];
    };

    const hasRole = (roles) => {
        if (!isArray(roles)) {
            roles = [roles];
        }

        return roles.every((role) => {
            return userHasRole(role);
        });
    };

    const isReviewer = () => {
        let manageMissionsAllLevels = false;

        if (
            user.campaign_restricted_targets != null &&
            user.campaign_restricted_targets.ROLE_MISSION != null &&
            user.campaign_restricted_targets.ROLE_MISSION == 0
        ) {
            if (parseInt(user.campaign_restricted_targets.ROLE_MISSION) === 0) {
                manageMissionsAllLevels = true;
            }
        }

        return (
            (user.missions_manager_levels_ids !== undefined &&
                user.missions_manager_levels_ids &&
                user.missions_manager_levels_ids.length > 0) ||
            hasRole('ROLE_SUPER_MISSION') ||
            manageMissionsAllLevels
        );
    };

    const canSeeSlots = () => {
        return user.isTrackContrib || user.isTrackAdmin || user.isTrackModerator;
    };

    const isInternal = () => {
        return hasRole('ROLE_INTERNAL');
    };

    const hasTA = () => {
        return (
            (hasRole('HAS_TA') && hasRole('ROLE_INTERNAL')) ||
            (hasRole('HAS_TA_EXTERNAL') && !hasRole('ROLE_INTERNAL'))
        );
    };

    const isAdminTA = () => {
        return (
            hasRole('ROLE_RS') ||
            hasRole('ROLE_SURVEY') ||
            hasRole('ROLE_PRODUCT') ||
            hasRole('ROLE_RECRUITING')
        );
    };

    const isAdmin = () => {
        return hasRole('ROLE_ADMIN') || hasRole('ROLE_SUPER_ADMIN');
    };

    const isBlogAdmin = () => {
        return hasRole('ROLE_ADMIN_BLOG');
    };

    const isSuperAdmin = () => {
        return hasRole('ROLE_SUPER_ADMIN');
    };

    const hasTrophies = () => {
        return hasRole('HAS_TROPHIES');
    };

    const hasRanking = () => {
        return hasTA() && !hasRole('HAS_HIDE_REWARDS_COUNT') && !config.HIDE_RANKING;
    };

    const isApp = (platformNames) => {
        if (!isArray(platformNames)) {
            platformNames = [platformNames];
        }

        return platformNames.some((platformName) => {
            return process.env.REACT_APP_NAME.indexOf(platformName) !== -1;
        });
    };

    const hasWriteAccess = () => {
        return some(user.workspaces_rules, (rule) => {
            return rule.is_writable;
        });
    };

    const hasCampaignType = (type) => {
        switch (type) {
            case 'social':
                return !isApp(['pca', 'caap', 'cacb']);
            case 'product':
                return !isApp(['ca-lorraine', 'calorraine', 'pca', 'cacb']);
            case 'recruiting':
                return !isApp(['ca-lorraine', 'calorraine', 'pca', 'caap', 'cacb']);
            default:
                return true;
        }
    };

    const isWorkspaceAdmin = (workspace) => {
        if (!workspace) {
            return false;
        }

        return (
            workspacePermissionCheck(workspace, 'ADMIN') ||
            (workspace.owner && user.id === workspace.owner.id)
        );
    };

    const workspacePermissionFormat = (workspace, type) => {
        return `${workspace.id}_${type.toUpperCase()}`;
    };

    const workspacePermissionCheck = (workspace, type) => {
        return hasRole(workspacePermissionFormat(workspace, type));
    };

    const isAllowToRead = (workspaceId) => {
        return workspacePermissionCheck(
            {
                id: workspaceId
            },
            'READ'
        );
    };

    const isAllowToWrite = (workspaceId) => {
        return workspacePermissionCheck(
            {
                id: workspaceId
            },
            'WRITE'
        );
    };

    const isAllowToHalfRead = (workspaceId) => {
        return workspacePermissionCheck(
            {
                id: workspaceId
            },
            'SILENT'
        );
    };

    const hasGamification = () => {
        return hasTrophies() || hasRanking();
    };

    const isAllowToUseResetPassword = () => {
        if (
            config.domain_not_allow_to_use_forgot_password &&
            config.domain_not_allow_to_use_forgot_password.length
        ) {
            let domain = user.email.split('@')[1];
            return config.domain_not_allow_to_use_forgot_password.indexOf(domain) === -1;
        }

        return true;
    };

    const isHalfSubscribedToWorkspace = (workspaceId) => {
        if (!user.workspaces_rules) {
            return false;
        }

        return user.workspaces_rules.some((item) => {
            return item.workspace_id === workspaceId && item.is_half_readable === true;
        });
    };

    const TAViewAll = useMemo(
        () =>
            [
                'ROLE_SUPER_RS',
                'ROLE_SUPER_SURVEY',
                'ROLE_SUPER_PRODUCT',
                'ROLE_SUPER_RECRUITING',
                'ROLE_SUPER_MISSION'
            ].some((role) => user.roles.includes(role)),
        [user]
    );

    const hasAtLeastOneRole = (roles) => {
        roles.some((role) => user.roles.includes(role));
    };

    return {
        isInternal,
        hasTA,
        isAdminTA,
        isAdmin,
        isSuperAdmin,
        hasTrophies,
        hasRanking,
        hasCampaignType,
        hasWriteAccess,
        workspacePermissionFormat,
        workspacePermissionCheck,
        hasRole,
        userHasRole,
        isAllowToRead,
        isAllowToWrite,
        isAllowToHalfRead,
        hasAtLeastOneRole,
        isBlogAdmin,
        hasGamification,
        isAllowToUseResetPassword,
        isReviewer,
        canSeeSlots,
        isWorkspaceAdmin,
        isHalfSubscribedToWorkspace,
        TAViewAll
    };
};

export default useRight;
