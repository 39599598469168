import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import { useConfig } from 'providers/ConfigProvider';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'providers/AlertContext';
import useUserService from 'api/useUserService';
import usePasswordService from 'hooks/usePassword';

const RegularConnection = () => {
    const { t } = useTranslation();
    const { showToast } = useAlert();
    const router = useRouter();
    const { config } = useConfig();
    const User = useUserService();
    const Password = usePasswordService();

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [userKeepSessionOpened, setUserKeepSessionOpened] = useState(true);
    const [timeLeftBeforeLogin, setTimeLeftBeforeLogin] = useState(null);
    const [connectionAttempts, setConnectionAttempts] = useState(0);
    const [userHasAcceptedConnectionTermsOfUse, setUserHasAcceptedConnectionTermsOfUse] =
        useState(false);

    const maximumConnectionAttempts = config.maximum_connection_attempts || 8;

    useEffect(() => {
        const storedTimeLeft = localStorage.getItem('timeLeftBeforeLogin');
        const storedConnectionAttempts = localStorage.getItem('connectionAttempts');
        if (storedTimeLeft) {
            setTimeLeftBeforeLogin(storedTimeLeft);
            setConnectionAttempts(storedConnectionAttempts);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('connectionAttempts', connectionAttempts);
    }, [connectionAttempts]);

    useEffect(() => {
        localStorage.setItem('timeLeftBeforeLogin', timeLeftBeforeLogin);
    }, [timeLeftBeforeLogin]);

    const loginIsEmail = () => {
        return config.login_is_email;
    };

    const isTermsOfUseApprovalNeeded = () => {
        return !!config.ASK_TERMS_ON_LOGIN;
    };

    const canUserLogin = () => {
        return email && isEmail(email) && password && userHasAcceptedConnectionTermsOfUse;
    };

    const isEmail = (email) => {
        if (!config.login_is_email) {
            return true;
        }

        let regEx =
            /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

        return regEx.test(email);
    };

    const login = (e) => {
        e.preventDefault();
        /*if (IS_APP) {
            Device.closeKeyboard();
            Device.setAccessoryBarVisible(false);
        }*/

        if (!canUserLogin()) {
            showToast({
                text: t('LOGIN_MISSING_CREDENTIALS'),
                duration: 3500
            });

            return;
        }

        if (!!timeLeftBeforeLogin) {
            let currentTime = new Date().getTime();

            if (timeLeftBeforeLogin > currentTime) {
                let timeLeftInMinutes = (timeLeftBeforeLogin - currentTime) / (1000 * 60);

                showToast({
                    text: t('MAXIMUM_CONNEXION_ATTEMPT_REACH', {
                        minutes: Math.ceil(timeLeftInMinutes)
                    }),
                    duration: 3500
                });

                return;
            } else {
                setConnectionAttempts(0);
                setTimeLeftBeforeLogin(0);
            }
        }

        User.login(email, password)
            .then(() => {
                setConnectionAttempts(0);
                setTimeLeftBeforeLogin(0);

                localStorage.removeItem('connectionAttempts');
                localStorage.removeItem('timeLeftBeforeLogin');

                if (!Password.isValid(password, false)) {
                    router.stateService.go('login.reset');
                } else {
                    User.saveLoginHistory();
                }

                /*if (IS_APP) {
                window.cordova.plugins.CookieManagementPlugin.flush();
            }*/

                /*Init.boot().then(() => {
                $state.go(Config.getHomeStateName(), {}, { reload: true, inherit: false });
            }).catch(() => {
                User.refreshTokenOrCheckConnectivity();
            });*/
            })
            .catch((err) => {
                console.log('err', err);

                if (err.status === 403 && err.data.message === 'NEED_TO_CHANGE_PASSWORD') {
                    showToast({
                        text: t('NEED_TO_CHANGE_PASSWORD'),
                        duration: 3500
                    });

                    router.stateService.go('login.changePassword');

                    return;
                }

                if ((err.status === 403 || err.status === 500) && err.data.error !== undefined) {
                    showToast({
                        text: t(err.data.error),
                        duration: 3500
                    });

                    return;
                }

                if (err.status === 401 && err.data.message === 'Account is disabled.') {
                    showToast({
                        text: t('LOGIN_ACCOUNT_DISABLED'),
                        duration: 3500
                    });

                    return;
                }

                if (err.status === 401) {
                    setConnectionAttempts(connectionAttempts + 1);

                    if (connectionAttempts >= maximumConnectionAttempts) {
                        setTimeLeftBeforeLogin(new Date().getTime() + 30 * 60 * 1000); // 30 minutes
                    }
                }

                if (err.status === 401) {
                    showToast({
                        text: t('LOGIN_BAD_CREDENTIALS_WITH_ATTEMPTS', {
                            attempts: maximumConnectionAttempts - connectionAttempts
                        }),
                        duration: 5000
                    });
                } else {
                    showToast({
                        text: t('ERROR_UNEXPECTED_CAUGHT'),
                        duration: 3000
                    });
                }
            });
    };

    return (
        <form className="form" onSubmit={login}>
            <div className="fields">
                <div className="field">
                    <label htmlFor="email" className="label">
                        {t('LOGIN_LOGIN_PLACEHOLDER')}
                    </label>
                    <input
                        id="email"
                        className="input"
                        type={loginIsEmail() ? 'email' : 'text'}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder={t('LOGIN_FORM_EMAIL_PLACEHOLDER')}
                    />
                </div>

                <div className="field">
                    <label htmlFor="password" className="label">
                        {t('LOGIN_PASSWORD_PLACEHOLDER')}
                    </label>

                    <div className="password">
                        <input
                            id="password"
                            className="input"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            placeholder={t('LOGIN_FORM_PASSWORD_PLACEHOLDER')}
                        />
                        <button
                            type="button"
                            className="show-password"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {t(showPassword ? 'HIDE' : 'SHOW').toUpperCase()}
                        </button>
                    </div>
                </div>

                <div className="field">
                    <div className="tips space-between">
                        <div className="input-with-label">
                            <input
                                className="switch"
                                type="checkbox"
                                id="keep-session"
                                checked={userKeepSessionOpened}
                                onChange={(e) => {
                                    setUserKeepSessionOpened(e.target.checked);
                                }}
                            />

                            <label className="label" htmlFor="keep-session">
                                {t('LOGIN_REMEMBER_ME')}
                            </label>
                        </div>

                        {loginIsEmail() && (
                            <button
                                className="link"
                                type="button"
                                onClick={() => router.stateService.go('login.reset')}
                            >
                                {t('LOGIN_FORGOT_LINK')}
                            </button>
                        )}
                    </div>
                </div>

                {isTermsOfUseApprovalNeeded() && (
                    <div className="field">
                        <div className="input-with-label">
                            <input
                                className="switch"
                                type="checkbox"
                                id="terms-of-use"
                                checked={userHasAcceptedConnectionTermsOfUse}
                                onChange={(e) => {
                                    setUserHasAcceptedConnectionTermsOfUse(e.target.checked);
                                }}
                            />

                            <label className="label" for="terms-of-use">
                                {t('LOGIN_AGREE')} (
                                <a
                                    className="link"
                                    target="_blank"
                                    rel={'noreferrer'}
                                    href={config.terms_url}
                                >
                                    {t('LOGIN_READ_TERMS')}
                                </a>
                                )
                            </label>
                        </div>
                    </div>
                )}
            </div>

            <div className="actions">
                <button className="btn hl" type="submit" disabled={!canUserLogin()}>
                    {t('LOGIN_BUTTON_TEXT')}
                </button>

                <br />
                <br />

                <button
                    onClick={() => router.stateService.go('auth.feed.posts')}
                    className="btn hl"
                    type="submit"
                >
                    Go /postss
                </button>
            </div>
        </form>
    );
};
export default RegularConnection;
