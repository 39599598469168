import { useTranslation } from 'react-i18next';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import { useAlert } from 'providers/AlertContext';
import useMissionService from 'pages/Dashboard/api/useMissionService';
import useProductCampaignService from 'pages/Dashboard/api/useProductCampaignService';
import { useConfig } from 'providers/ConfigProvider';
import { useQueryClient } from '@tanstack/react-query';
import useAttachment from 'hooks/useAttachment';

const Mission = ({ campaign }) => {
    const queryClient = useQueryClient();
    const { forgeAttachmentPoster, forgeAttachmentUrl } = useAttachment();
    const { config: appConfig } = useConfig();
    const { t } = useTranslation();
    const { showConfirm, showToast } = useAlert();
    const { expireMission } = useMissionService();
    const { forgeDownloadCSVUrl } = useProductCampaignService();
    const {
        status,
        isClosed,
        isDraft,
        isRunning,
        getCampaignTarget,
        getCampaignAuthor,
        closeCampaign,
        cloneCampaign,
        deleteCampaign,
        draftCampaign
    } = useCampaignsCustomRender(campaign);

    const isMissionRunning = () => {
        return campaign.type === 'mission' && status === 'closed';
    };

    const handleExpireMission = () => {
        showConfirm({
            title: t('TA_MISSION_DELETE_CONFIRM_TITLE'),
            text: t('TA_MISSION_DELETE_CONFIRM_TITLE'),
            button: {
                label: t('TA_MISSION_DELETE_CONFIRM_BUTTON'),
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                expireMission.then(() => {
                    showToast({
                        text: 'La campagne a bien été supprimée'
                    });

                    queryClient.refetchQueries({
                        queryKey: ['dashboard-campaigns']
                    });
                });
            })
            .catch(() => {
                showToast({
                    text: "Une erreur est survenue pendant l'expiration"
                });
            });
    };

    const handleExportMission = () => {
        window.open(
            `${appConfig.apiUrl}/campaign/campaigns/${campaign.id}/mission/export`,
            '_blank'
        );
    };

    return (
        <div className="ta_global ta_campaign">
            <div className="metadata">
                <div className="created">
                    {t('TA_CAMPAIGN_CREATED_AT')} {campaign.created_at}
                </div>

                {campaign.startDate && campaign.endDate && (
                    <div className="campaign-dates">
                        {campaign.startDate && (
                            <span>
                                {t('TA_CAMPAIGN_STARTING_AT')} {campaign.startDate}
                            </span>
                        )}
                        <br />
                        {campaign.endDate && (
                            <span>
                                {t('TA_CAMPAIGN_ENDING_AT')} {campaign.endDate}
                            </span>
                        )}{' '}
                        <br />
                        {campaign.closingDate && (
                            <span>
                                {t('CLOSING_AT')} {campaign.closingDate}
                            </span>
                        )}
                    </div>
                )}
                <div className="id">
                    {t('MISSION_ID')} {campaign.id}
                </div>
            </div>

            <div
                style={{
                    flexDirection: 'column'
                }}
                className="block">
                <div
                    style={{
                        flexDirection: 'column'
                    }}>
                    <div className="description flex-gt-xs-40">
                        <div className="title">{campaign.title}</div>

                        <div className="image">
                            {!campaign.image && campaign.link && (
                                <img src={campaign.link.picture_url} alt="" />
                            )}
                            {!campaign?.image?.poster && (!campaign.link || campaign.image) && (
                                <img src={forgeAttachmentUrl(campaign.image)} alt="" />
                            )}
                            {campaign?.image?.poster && (
                                <img src={forgeAttachmentPoster(campaign.image)} alt="" />
                            )}
                        </div>

                        {campaign.target && (
                            <div className="target">
                                {t('TA_CREATE_CAMPAIGN_TARGET_TITLE')} : {getCampaignTarget()}
                            </div>
                        )}
                        {campaign.owner && (
                            <div className="target">
                                {t('TA_CREATE_CAMPAIGN_AUTHOR_TITLE')} : {getCampaignAuthor()}
                            </div>
                        )}
                    </div>

                    <div className="layout-align-stretch-stretch layout-row flex-gt-xs-auto">
                        <div className="informations layout-gt-xs-row layout-align-space-between-stretch layout-column flex-auto mission">
                            <div className="actions layout-align-gt-xs-space-between-end layout-align-space-between-start layout-column flex-auto">
                                <div className="buttons layout-column">
                                    {campaign.submits_count > 0 && (
                                        <a
                                            className="button grey"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={forgeDownloadCSVUrl(campaign.type, campaign.id)}>
                                            {t('TA_CAMPAIGN_EXPORT')}
                                        </a>
                                    )}
                                    {(isDraft || isRunning) && (
                                        <button className="button grey" onClick={closeCampaign}>
                                            {t('CLOSE_MISSION')}
                                        </button>
                                    )}
                                    {(isDraft || isRunning || isClosed) && (
                                        <a
                                            className="button grey"
                                            href={`/auth/dashboard/campaigns/create?type=${campaign.type}&id=${campaign.id}`}>
                                            {t('EDIT_MISSION')}
                                        </a>
                                    )}
                                    <button className="button grey" onClick={cloneCampaign}>
                                        {t('TA_CAMPAIGN_DUPLICATE')}
                                    </button>

                                    {isClosed && (
                                        <button className="button grey" onClick={draftCampaign}>
                                            {t('TA_CAMPAIGN_MOVE_DRAFT')}
                                        </button>
                                    )}
                                    {/* Uncomment the following line if needed */}
                                    {/* <button className="button grey" onClick={() => togglePreview(campaign)}>{t('TA_CAMPAIGN_PREVIEW')}</button> */}
                                    {!isRunning && (
                                        <button className="button red" onClick={deleteCampaign}>
                                            {t('TA_MISSION_DELETE')}
                                        </button>
                                    )}
                                    {isMissionRunning() && (
                                        <button
                                            className="button red"
                                            onClick={handleExpireMission}>
                                            {t('TA_MISSION_EXPIRE')}
                                        </button>
                                    )}
                                    <button className="button grey" onClick={handleExportMission}>
                                        {t('TA_CAMPAIGN_EXPORT')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <ng-include src="$ctrl.campaignPartial()"></ng-include> */}
            </div>
        </div>
    );
};

export default Mission;
