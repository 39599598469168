import React from 'react';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useModal } from 'providers/ModalContext';
import { useAlert } from 'providers/AlertContext';
import useUserService from 'api/useUserService';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useForm } from 'components/Form/hooks';
import { FileButton } from 'components/Form/Inputs';

const ImportUserRewardsModalContent = () => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const { showToast } = useAlert();
    const { postUserRewardsImport } = useUserService();
    const methods = useForm();

    const importUserRewards = (values) => {
        postUserRewardsImport(values.files[0])
            .then((data) => {
                let text = t('USER_IMPORT_REWARDS_SUCCESS', {
                    users: data.total_user_rewards_imported
                });

                if (data.total_user_rewards_imported <= 1) {
                    text = t('USER_IMPORT_REWARDS_SUCCESS_SINGLE');
                }

                showToast({
                    text: text,
                    duration: 1500
                });

                closeModal('import_user_rewards');
            })
            .catch((e) => {
                if (e.status === 400) {
                    if (e.data.message === 'HEADERS_BAD_VALUE') {
                        showToast({
                            text: t(e.data.message),
                            duration: 2000
                        });
                    }

                    if (e.data.message === 'IMPORT_POINTS_BAD_EMAILS') {
                        showToast({
                            text: t(e.data.message, {
                                noneUserEmails: Object.values(e.data.data).join(', ')
                            }),
                            duration: 3000
                        });
                    }
                }
            });
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(importUserRewards)}>
            <ModalFormBody>
                <ModalFormSection>
                    <div
                        className="helper"
                        dangerouslySetInnerHTML={{ __html: t('USER_IMPORT_REWARDS_HELP') }}></div>

                    <div className="row">
                        <FileButton name={'files'}>{t('IMPORT_DATA_FILE')}</FileButton>
                    </div>
                </ModalFormSection>
            </ModalFormBody>

            <ModalFormFooter onCancel={() => closeModal('import_user_rewards')} />
        </ModalForm>
    );
};

const ImportUserRewardsModal = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();

    return (
        <Modal
            name={`import_user_rewards`}
            title={t('ADMIN_USERS_IMPORT_REWARDS')}
            options={{ hasPaddedContent: true }}>
            {isModalOpen('import_user_rewards') && <ImportUserRewardsModalContent />}
        </Modal>
    );
};

export default ImportUserRewardsModal;
