import React from 'react';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import { Input, Select, Checkbox } from 'components/Form/Inputs';
import useLevelService from 'api/useLevelService';
import { useAlert } from 'providers/AlertContext';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useModal } from 'providers/ModalContext';

const LevelConfigModalContent = () => {
    const { t } = useTranslation();
    const { getLevelRange } = useAdminLevelContext();
    const {
        contexts: {
            add_level_config: { currentLevelConfig }
        },
        closeModal
    } = useModal();
    const LevelService = useLevelService();
    const { showToast } = useAlert();
    const queryClient = useQueryClient();
    const methods = useForm({
        defaultValues: currentLevelConfig
    });

    const onFinish = (data) => {
        const method = currentLevelConfig?.id ? 'putLevelConfig' : 'postLevelConfig';

        const params = { ...currentLevelConfig, ...data };
        params.index = { index: params.index };

        LevelService[method](params)
            .then(
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_CONFIRM'),
                        duration: 1500
                    });
                    queryClient.refetchQueries({ queryKey: ['levels'] });
                },
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_ERROR'),
                        duration: 1500
                    });
                }
            )
            .finally(() => {
                closeModal('add_level_config');
            });
    };

    return (
        <>
            {getLevelRange().length === 0 && !currentLevelConfig?.id && (
                <div className="all-done">{t('ADMIN_LEVEL_ALL_DONE_LEVEL_CONFIG')}</div>
            )}

            {(getLevelRange().length > 0 || currentLevelConfig?.id) && (
                <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
                    <ModalFormBody>
                        <ModalFormSection>
                            <Input
                                label={t('LEVEL_NAME')}
                                name={'name'}
                                placeholder={t('LEVEL_NAME')}
                            />

                            {!currentLevelConfig?.id && (
                                <Select
                                    label={t('LEVEL_INDEX')}
                                    name={'index'}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={getLevelRange().map((value) => {
                                        return {
                                            label: value.index,
                                            value: value.index
                                        };
                                    })}
                                />
                            )}

                            <Checkbox
                                name={'show_in_directory'}
                                label={t('LEVEL_SHOW_IN_DIRECTORY')}
                            />

                            <Checkbox name={'show_in_metrics'} label={t('LEVEL_SHOW_IN_METRICS')} />
                        </ModalFormSection>
                    </ModalFormBody>
                    <ModalFormFooter
                        onCancel={() => {
                            closeModal('add_level_config');
                        }}
                    />
                </ModalForm>
            )}
        </>
    );
};

const LevelConfigModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name="add_level_config"
            title={t('ADMIN_LEVEL_ADD_LEVEL_CONFIG')}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <LevelConfigModalContent />
        </Modal>
    );
};

export default LevelConfigModal;
