import { useHttp } from './useHttp';

const useCampaignSocialService = () => {
    const axios = useHttp();

    const getSocialCampaign = (id, fromCampaignDashboard = false) => {
        return axios
            .get(`/dashboard/socials/${id}?fromCampaignDashboard=${fromCampaignDashboard}`)
            .then((response) => response.data);
    };

    return {
        getSocialCampaign
    };
};

export default useCampaignSocialService;
