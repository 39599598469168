const TRUSTED_PROVIDERS = [
    'youtube',
    'linkedin',
    'deezer',
    'spotify',
    'dailymotion',
    'vimeo',
    'google drive',
    'wedia-group'
];

export default function isLinkTrusted(provider) {
    if (!provider) {
        return false;
    }

    return TRUSTED_PROVIDERS.indexOf(provider.toLowerCase()) !== -1;
}
