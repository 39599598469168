import React, { useState } from 'react';
import dayjs from 'dayjs';
import Color from 'color';
import useWidgetComponent from '../hooks/useWidgetComponent';
import useRss from '../hooks/useRss';
import { useTranslation } from 'react-i18next';
import clip from 'text-clipper';

const Rss = ({ widget, options }) => {
    const { t } = useTranslation();
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(1);
    const { isLoading, items, title, link } = useRss(widget.id, refresh);

    const getColorWithOpacity = (hexCode, opacity = 1) => {
        return Color(hexCode).alpha(opacity).rgb().string();
    };

    const getDate = (item) => {
        let date = dayjs(item.date);
        let isToday = date.isSame(dayjs(), 'day');

        return isToday ? date.format('LT') : date.format('lll');
    };

    const getItemsByPage = () => {
        let offset = (page - 1) * getFieldValueForKey('offset');
        let limit = page * getFieldValueForKey('offset');

        return items?.slice(offset, limit);
    };

    const getTruncatedContent = (content) => {
        return clip(content, 280, { html: true });
    };

    const getTotalPages = () => {
        return items?.length > 0 ? Math.ceil(items.length / getFieldValueForKey('offset')) : 1;
    };

    const isLastPage = () => {
        return page === getTotalPages();
    };

    if (isLoading && !items) {
        return null;
    }

    return (
        <div className="rss">
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                <a className="text" href={link} target="_blank" rel={'noreferrer'} title={title}>
                    <i className="fa fa-rss" aria-hidden="true"></i> {title}
                </a>

                <div
                    className="reload"
                    style={{
                        backgroundColor: getStyleValueForKey('titleColor'),
                        color: getStyleValueForKey('refreshTextColor')
                    }}
                >
                    <button
                        onClick={() => {
                            setRefresh(refresh + 1);
                        }}
                    >
                        <i
                            className={`icon-button-refresh-arrows ${isLoading ? 'is-loading' : null}`}
                            aria-hidden="true"
                        ></i>{' '}
                        {t('REFRESH')}
                    </button>
                </div>
            </div>

            <div className="content" style={{ color: getStyleValueForKey('textColor') }}>
                <div className="items">
                    {getItemsByPage()?.map((item, index) => {
                        return (
                            <div
                                className="item"
                                style={{
                                    borderBottomColor: getColorWithOpacity(
                                        getStyleValueForKey('textColor'),
                                        0.25
                                    )
                                }}
                                key={`rss_row_${index}`}
                            >
                                {getFieldValueForKey('showDate') && (
                                    <div className="date">{getDate(item)}</div>
                                )}

                                <a
                                    className={`link ${getFieldValueForKey('showContent') ? 'is-title' : null}`}
                                    href={item.link}
                                    title={item.description}
                                    dangerouslySetInnerHTML={{ __html: item.title }}
                                    target="_blank"
                                ></a>

                                {getFieldValueForKey('showContent') && (
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{
                                            __html: getTruncatedContent(item.content)
                                        }}
                                    ></div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {getTotalPages() > 1 && (
                    <div className="pagination">
                        <div className="prev" title={t('STATS_PREVIOUS_PAGE')}>
                            <button
                                onClick={() => {
                                    setPage(page - 1);
                                }}
                                disabled={page === 1}
                            >
                                <i className="icon-move-bottom-left" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="current">
                            {t('PAGE_X_IN_X', { current: page, total: getTotalPages() })}
                        </div>

                        <div className="next" title={t('STATS_NEXT_PAGE')}>
                            <button
                                onClick={() => {
                                    setPage(page + 1);
                                }}
                                disabled={isLastPage()}
                            >
                                <i className="icon-move-bottom-left" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Rss;
