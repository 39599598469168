import { useQuery } from '@tanstack/react-query';
import useLevelService from 'api/useLevelService';
import { ColorPicker, Input, Select } from 'components/Form/Inputs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';
import { Button } from 'antd';
import { useForm } from 'components/Form/hooks';
import levelFormDirectorySchema from '../schemas/formDirectoryConfig.schema.json';
import { ModalForm } from 'components/Form/ModalForm';
import { useFieldArray } from 'react-hook-form';

const AddLevelForm = ({ id, methods, onDelete }) => {
    const { t } = useTranslation();
    const { getLevels } = useLevelService();
    const { data: dataLevels, isLoading: isLoadingLevels } = useQuery({
        queryKey: [`adminLevels`],
        queryFn: () => getLevels()
    });

    const { watch, getValues, register } = methods;

    return (
        <div>
            <label>{t('DIRECTORY_CONFIG_PINNED_LEVELS')}</label>
            <Select
                name={`levels.${id}.level`}
                loading={isLoadingLevels}
                options={dataLevels?.map((level) => ({
                    value: level.id,
                    label: level.name
                }))}
            />

            <Input {...register(`levels.${id}.directoryName`)} label={'Nom'} />

            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                }}>
                <ColorPicker
                    {...register(`levels.${id}.directoryColor`)}
                    label={'Couleur du texte'}
                    value={
                        watch(`levels.${id}.directoryColor`) ||
                        getValues(`levels.${id}.directoryColor`) ||
                        '#000000'
                    }
                />
                <ColorPicker
                    {...register(`levels.${id}.directoryBorderColor`)}
                    label={'Couleur de la bordure'}
                    value={
                        watch(`levels.${id}.directoryBorderColor`) ||
                        getValues(`levels.${id}.directoryBorderColor`) ||
                        '#000000'
                    }
                />
                <ColorPicker
                    {...register(`levels.${id}.directoryBackgroundColor`)}
                    label={'Couleur de fond'}
                    value={
                        watch(`levels.${id}.directoryBackgroundColor`) ||
                        getValues(`levels.${id}.directoryBackgroundColor`) ||
                        '#000000'
                    }
                />
            </div>

            <Button
                type={'danger'}
                onClick={() => {
                    onDelete(id);
                }}>
                Supprimer
            </Button>
        </div>
    );
};

/**
 * LevelsToPin component to display the levels to pin in the directory config
 * @param {*} methods from the useForm hook with react-hook-form
 * @returns {JSX.Element} LevelsToPin component
 */
const LevelsToPin = ({ methods }) => {
    const {
        control,
        watch,
        formState: { errors }
    } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'levels'
    });

    const watchFieldArray = watch('levels');
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const hasErrors = () => {
        return fields.some((field, index) => {
            const errorFields = errors.levels && errors.levels[index];
            return errorFields && Object.keys(errorFields).length > 0;
        });
    };

    return (
        <div>
            {controlledFields.map((field, index) => (
                <AddLevelForm
                    key={field.id}
                    id={field.id}
                    methods={methods}
                    onDelete={() => remove(index)}
                />
            ))}

            <Button
                type={'primary'}
                onClick={() => {
                    append({
                        level: '',
                        directoryName: '',
                        directoryColor: '#000000',
                        directoryBorderColor: '#000000',
                        directoryBackgroundColor: '#000000'
                    });
                }}
                disabled={hasErrors()}>
                Ajouter un niveau
            </Button>
        </div>
    );
};

export default LevelsToPin;
