import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import { useBlog as useBlogContext } from '../providers/BlogProvider';
import useBlogArticleService from 'api/useBlogArticleService';
import { defaultTo } from 'lodash-es';
import Card from 'components/Article/Card';

const Articles = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const [query, setQuery] = useState('');
    const { currentState, isSearchState } = useBlogContext();
    const { getBlogArticles, getPublicBlogArticles, searchArticles } = useBlogArticleService();
    const [articles, setArticles] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const { data, isFetching, isLoading } = useQuery({
        queryKey: [query, 'blog_articles'],
        queryFn: () => {
            if (!isSearchState) {
                if (currentState === 'public') {
                    return getPublicBlogArticles(params);
                }

                return getBlogArticles(params);
            } else {
                return searchArticles(params);
            }
        }
    });

    useEffect(() => {
        setQuery(`blog_articles_${[...Object.values(params)].join('_')}_${currentState}`);
    }, [params, currentState]);

    useEffect(() => {
        if (data?.articles) {
            setArticles(data?.articles);
            setTotalPages(data?.totalPage);
        }
    }, [data]);

    const hasPreviousPage = useMemo(() => {
        return params.page > 1;
    }, [params]);

    const hasNextPage = useMemo(() => {
        return params.page < totalPages;
    }, [params, totalPages]);

    const isHome = useMemo(() => {
        return (
            params.page === 1 && !defaultTo(params.category, null) && !defaultTo(params.type, null)
        );
    }, [params]);

    const goToPage = (increment) => {
        router.stateService.go(
            '.',
            { page: params.page + increment },
            { reload: false, inherit: true }
        );
    };

    return (
        <div className="blog-articles-wrapper">
            <div className="blog-articles">
                {!isFetching && !isLoading && articles && articles.length > 0 && (
                    <div className="articles">
                        {articles.map((article) => {
                            return (
                                <div
                                    className={`article ${article.isFrontPage && isHome ? 'front-page' : ''}`}
                                    key={`article_${article.id}`}
                                >
                                    <Card article={article} isHome={isHome} />
                                </div>
                            );
                        })}
                    </div>
                )}

                {!isFetching && !isLoading && articles && articles.length > 0 && (
                    <div className="pagination">
                        <div className="action">
                            <button
                                className="btn previous"
                                onClick={() => goToPage(-1)}
                                disabled={!hasPreviousPage}
                            >
                                <i className="icon-move-bottom-left" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="total">
                            <span className="current">Page {params.page}</span> / {totalPages}
                        </div>

                        <div className="action">
                            <button
                                className="btn next"
                                onClick={() => goToPage(1)}
                                disabled={!hasNextPage}
                            >
                                <i className="icon-move-bottom-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                )}

                {!isFetching && !isLoading && articles && articles.length === 0 && (
                    <div className="no-articles">
                        <div className="empty">
                            <div className="icon">
                                <i className="icon-remove-circle" aria-hidden="true"></i>
                            </div>

                            <div className="message">{t('BLOG_NO_RESULTS')}</div>

                            <div className="actions">
                                <a
                                    className="button action"
                                    ui-sref="auth.blog.articles"
                                    ui-sref-opts="{ reload: true, inherit: false }"
                                >
                                    {t('BLOG_BACK_TO_HOME_BUTTON')}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Articles;
