import React, { useState } from 'react';
import useBlog from 'hooks/useBlog';
import useAttachment from 'hooks/useAttachment';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';

const Card = ({ article, isHome }) => {
    const router = useRouter();
    const { t } = useTranslation();
    const {
        canSeeAdminActions,
        readMore,
        getLocalizedCategoryTitle,
        getArticleTimeToRead,
        getLocalizedArticleProperty,
        showConsultationUsers
    } = useBlog();
    const { forgeBlogArticleContentAttachmentUrl } = useAttachment();
    const [details, setDetails] = useState([]);

    const showDetails = () => {
        if (details.length > 0) {
            setDetails([]);
        } else {
            setDetails([
                `${t('BLOG_COUNT_READ')} : ${t(article.readCount)}`,
                `${t('BLOG_COUNT_READ_CONNECTED')} : ${t(article.connectedReadCount)}`,
                `${t('BLOG_COUNT_READ_ANONYMOUS')} : ${t(article.anonymousReadCount)}`
            ]);
        }
    };

    return (
        <div className="article-wrapper">
            {getLocalizedArticleProperty(article, 'thumbnail') && (
                <div className="thumbnail" onClick={() => readMore(article)}>
                    <img
                        src={forgeBlogArticleContentAttachmentUrl(
                            getLocalizedArticleProperty(article, 'thumbnail')
                        )}
                        alt={getLocalizedArticleProperty(article, 'title')}
                    />
                </div>
            )}

            <div className="content">
                <div className="tags">
                    <a
                        className="tag category"
                        href={router.stateService.href('auth.blog.articles', {
                            category: article.category.id
                        })}
                        style={{ backgroundColor: article.category.color }}
                    >
                        {getLocalizedCategoryTitle(article.category)}
                    </a>

                    {getArticleTimeToRead(article) && (
                        <div className="tag time-to-read">{getArticleTimeToRead(article)}</div>
                    )}

                    {canSeeAdminActions() && (
                        <div className="tag stats is-clickable" onClick={showDetails}>
                            <div className="tooltip-toggle">
                                <i className="fa fa-eye"></i>
                            </div>

                            {details && (
                                <div className={`tooltip ${details.length > 0 ? 'hover' : ''}`}>
                                    <div className="tooltip-content">
                                        {details.map((detail, index) => {
                                            return (
                                                <div key={`article_detail_${index}`}>
                                                    {detail}
                                                    <br />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {article.isFrontPage && isHome && (
                        <div
                            className="consultations is-clickable"
                            onClick={() => showConsultationUsers(article.id)}
                        >
                            {t('POST_DOWNLOADS_COUNT', {
                                download_count: article.connectedReadCount
                            })}
                        </div>
                    )}
                </div>

                <div className="title is-clickable" onClick={() => readMore(article)}>
                    {getLocalizedArticleProperty(article, 'title')}
                </div>

                {!(article.isFrontPage && isHome) && (
                    <div
                        className="consultations is-clickable"
                        onClick={() => showConsultationUsers(article.id)}
                    >
                        {t('POST_DOWNLOADS_COUNT', { download_count: article.connectedReadCount })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
