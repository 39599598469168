import { isEmpty, filter, includes, toLower, some } from 'lodash-es';

export default function channelFilterBy(array, searchText) {
    if (isEmpty(searchText)) {
        return array;
    }

    searchText = toLower(searchText);

    return filter(array, (item) => {
        return (
            includes(toLower(item.name), searchText) ||
            some(item.users, (user) => {
                return (
                    includes(toLower(user.first_name), searchText) ||
                    includes(toLower(user.last_name), searchText)
                );
            })
        );
    });
}
