import { isNil } from 'lodash-es';
import { useAuth } from '../providers/AuthContext';
import useRight from './useRight';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const useMission = () => {
    const { user: currentUser } = useAuth();
    const { hasRole } = useRight();
    const { t } = useTranslation();

    const hasStatus = (mission) => {
        return !!mission && !!mission.last_history && !!mission.last_history.status;
    };

    const getStatusLabel = (mission) => {
        return getStatusLabelMission(mission.last_history.status);
    };

    const getStatusLabelMission = (status) => {
        switch (status) {
            case 'WAITING_FOR_REVIEW':
                return t('WAITING_FOR_REVIEW');
            case 'UPDATED':
                return t('UPDATED');
            case 'TO_BE_IMPROVED':
                return t('TO_BE_IMPROVED');
            case 'VALIDATED':
                return t('VALIDATED');
            case 'TIMEOUT':
                return t('TIMEOUT');
            default:
                return null;
        }
    };

    const getStatusSlug = (mission) => {
        switch (mission.last_history.status) {
            case 'WAITING_FOR_REVIEW':
                return 'review';
            case 'UPDATED':
                return 'updated';
            case 'TO_BE_IMPROVED':
                return 'improved';
            case 'VALIDATED':
                return 'validated';
            case 'TIMEOUT':
                return 'timeout';
            default:
                return null;
        }
    };

    const isStatus = () => {};

    const isReviewer = (user = null) => {
        if (!user) {
            user = currentUser;
        }

        let manageMissionsAllLevels = false;

        if (
            user.campaign_restricted_targets != null &&
            user.campaign_restricted_targets.ROLE_MISSION != null &&
            user.campaign_restricted_targets.ROLE_MISSION == 0
        ) {
            if (parseInt(user.campaign_restricted_targets.ROLE_MISSION) === 0) {
                manageMissionsAllLevels = true;
            }
        }

        return (
            (user.missions_manager_levels_ids && user.missions_manager_levels_ids.length > 0) ||
            manageMissionsAllLevels ||
            hasRole('ROLE_SUPER_MISSION')
        );
    };

    const getProgress = (mission) => {
        const totalStepsCompleted = mission.steps.filter((step) => {
            return !isNil(step.user);
        }).length;

        return Math.floor((totalStepsCompleted / mission.steps.length) * 100);
    };

    const hasProgress = (mission) => {
        return mission.steps.some((step) => {
            return !isNil(step.user);
        });
    };

    const isMissionExpired = (mission) => {
        let endAtMoment = dayjs(mission.end_at);

        return endAtMoment.isBefore(dayjs());
    };

    return {
        hasStatus,
        getStatusLabel,
        getStatusSlug,
        isStatus,
        isReviewer,
        hasProgress,
        getProgress,
        isMissionExpired
    };
};

export default useMission;
