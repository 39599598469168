import { forEach, indexOf, has } from 'lodash-es';

const Keys = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    UP: 38,
    DOWN: 40
};

const useQuill = () => {
    const extractMentions = (contents) => {
        let mentionUserIds = [];
        forEach(contents.ops, (value) => {
            if (has(value, 'insert') && has(value.insert, 'mention')) {
                mentionUserIds.push(parseInt(value.insert.mention.id));
            }
        });

        return mentionUserIds;
    };

    const getOnlyNewMentions = (current, prev) => {
        let mentionUserIds = [];
        forEach(current, (value) => {
            if (indexOf(prev, value) === -1) {
                mentionUserIds.push(value);
            }
        });

        return mentionUserIds;
    };

    const getBindings = (callback) => {
        return {
            submit: {
                key: Keys.ENTER,
                empty: false,
                ctrlKey: true,
                handler: () => {
                    callback();
                }
            }
        };
    };

    return {
        extractMentions,
        getOnlyNewMentions,
        getBindings
    };
};

export default useQuill;
