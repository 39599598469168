import { useHttp } from './useHttp';

const useSharedDocumentCategoryService = () => {
    const axios = useHttp();

    const getCategories = (params) => {
        return axios
            .get(`/shared/documents/categories`, {
                params
            })
            .then((response) => response.data);
    };

    const getAllSharedCategories = (params) => {
        return axios
            .get(`/all/shared/documents/categories`, {
                params
            })
            .then((response) => response.data);
    };

    return {
        getCategories,
        getAllSharedCategories
    };
};

export default useSharedDocumentCategoryService;
