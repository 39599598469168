import Placeholder from 'components/Placeholder/Placeholder';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import limitTo from 'helpers/limitTo';
import Username from 'components/Username/Username';
import useUser from 'hooks/useUser';
import { useChannelContext } from '../../providers/ChannelProvider';
import { useAuth } from 'providers/AuthContext';

const ChannelDetails = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { channelInfo, isAdmin, isOwner } = useChannelContext();
    const [expandParticipants, setExpandParticipants] = useState(false);
    const { forgeAvatarUrl } = useUser();

    const getParticipants = useCallback(() => {
        let users = orderBy(channelInfo.users, ['first_name']);

        if (!expandParticipants) {
            return limitTo(users, 5);
        }

        return users;
    }, [channelInfo, expandParticipants]);

    return (
        <>
            {channelInfo ? (
                <div className="channel">
                    <div className="block">
                        <div className="meta">
                            <div className="avatar">
                                {!channelInfo.channel.cover ? (
                                    <div className="default">
                                        <i className="icon-multiple-users-1"></i>
                                    </div>
                                ) : (
                                    <img src={forgeAvatarUrl(channelInfo.channel.cover)} />
                                )}
                            </div>

                            <div className="name">{channelInfo.channel.name}</div>
                        </div>
                    </div>

                    <div className="block">
                        <div className="heading">
                            <div className="text">{t('CHANNEL_USERS')}</div>

                            {isAdmin(user) && (
                                <div
                                    className="action"
                                    title="{ t('CHANNEL_ADD_USERS') }"
                                    ng-click="$ctrl.addUser()"
                                >
                                    <i className="icon-add" aria-hidden="true"></i>
                                </div>
                            )}
                        </div>

                        <div className="participants">
                            {getParticipants().map((participant) => {
                                return (
                                    <div
                                        className="participant"
                                        key={`participant_${participant.id}`}
                                    >
                                        <div
                                            className="avatar"
                                            ng-click="$ctrl.goToUserProfile(user)"
                                        >
                                            <img
                                                src={forgeAvatarUrl(participant.image_profile_name)}
                                            />
                                        </div>

                                        <div
                                            className="name"
                                            ng-click="$ctrl.goToUserProfile(user)"
                                        >
                                            <Username user={participant} light={true} />
                                        </div>

                                        {isAdmin(participant) && (
                                            <>
                                                <div className="role">
                                                    {isOwner(participant) ? (
                                                        <span>
                                                            {t(
                                                                'CHAT_DETAILS_PANEL_USERS_OWNER_LABEL'
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {t(
                                                                'CHAT_DETAILS_PANEL_USERS_ADMIN_LABEL'
                                                            )}
                                                        </span>
                                                    )}
                                                </div>

                                                {participant.id !== user.id && (
                                                    <div
                                                        className="action"
                                                        ng-if="$ctrl.isAdmin($ctrl.User.getUserObject()) && !$ctrl.User.isMe(user.id)"
                                                        ng-click="$ctrl.userAdminAction(user)"
                                                    >
                                                        <i
                                                            className="icon-navigation-menu-horizontal"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="options">
                            {channelInfo.users.length > 5 && (
                                <div
                                    className="option as-link is-clickable"
                                    onClick={() => setExpandParticipants(!expandParticipants)}
                                >
                                    {!expandParticipants ? (
                                        <div className="label">{t('CHANNEL_VIEW_ALL_USERS')}</div>
                                    ) : (
                                        <div className="label">{t('CHANNEL_VIEW_LESS_USERS')}</div>
                                    )}

                                    <div className={`icon`}>
                                        <i
                                            className={`${!expandParticipants ? 'icon-arrow-down-1-arrows-diagrams' : 'icon-arrow-up-1-arrows-diagrams'}`}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="block" ng-if="$ctrl.hasDocuments">
                        <div className="heading">
                            <div className="text">
                                {t('CHAT_DETAILS_PANEL_DOCUMENTS_TITLE_LABEL')}
                            </div>

                            <div
                                className="action"
                                ng-if="$ctrl.hasMoreDocuments"
                                ng-attr-title="{ t('CHAT_DETAILS_PANEL_MORE_DOCUMENTS') }"
                                ng-click="$ctrl.loadDocuments(1)"
                            >
                                <i
                                    className="icon-arrow-right-1-arrows-diagrams"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>

                        <div className="participants">
                            <div
                                className="participant"
                                ng-repeat="document in $ctrl.documents track by document.id"
                                ng-click="$ctrl.downloadAttachment(document)"
                            >
                                <div
                                    className="avatar as-icon"
                                    ng-style="::{ 'background-color': $ctrl.attachmentIcon(document, 'color') }"
                                >
                                    <i
                                        className="fa fa-fw"
                                        aria-hidden="true"
                                        ng-class="::[$ctrl.attachmentIcon(document, 'icon')]"
                                    ></i>
                                </div>

                                <div className="name">{document.name}</div>

                                <div className="action">
                                    <i className="icon-download-bottom" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block" ng-if="$ctrl.hasPhotos">
                        <div className="heading">
                            <div className="text">{t('CHAT_DETAILS_PANEL_PHOTOS_TITLE_LABEL')}</div>

                            <div
                                className="action"
                                ng-if="$ctrl.hasMorePhotos"
                                ng-attr-title="{ t('CHAT_DETAILS_PANEL_MORE_PHOTOS') }"
                                ng-click="$ctrl.loadPhotos(1)"
                            >
                                <i
                                    className="icon-arrow-right-1-arrows-diagrams"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>

                        <div className="photos">
                            <div className="grid">
                                <div
                                    className="photo"
                                    ng-repeat="photo in $ctrl.photos track by photo.id"
                                    ng-click="$ctrl.showCarousel($ctrl.photos, $index)"
                                >
                                    <div className="image">
                                        <img ng-src="{ ::$ctrl.getAttachmentUrl(photo, false) }" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block">
                        <div className="heading">{t('CHAT_DETAILS_PANEL_ACTIONS_TITLE_LABEL')}</div>

                        <div className="options">
                            <div
                                className="option cursor pointer"
                                ng-if="$ctrl.isAdmin($ctrl.User.getUserObject()) && $ctrl.users.length > 2"
                                ng-model-options="{ debounce: 0 }"
                                ngf-select
                                ng-model="$ctrl.newPicture"
                                name="file"
                                ngf-pattern="'image/*'"
                                ngf-accept="'image/*'"
                                ngf-max-size="20MB"
                                ngf-change="$ctrl.editPicture()"
                                ngf-fix-orientation="true"
                                ngf-resize="{ width: 512, height: 512, centerCrop: true }"
                            >
                                <div className="label">
                                    {t('CHAT_DETAILS_PANEL_CUSTOMIZE_COVER_TITLE_LABEL')}
                                </div>

                                <div className="icon">
                                    <i className="icon-camera-retro" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div
                                className="option"
                                ng-if="$ctrl.isAdmin($ctrl.User.getUserObject()) && $ctrl.users.length > 2"
                                ng-click="$ctrl.renameChannel()"
                            >
                                <div className="label">
                                    {t('CHAT_DETAILS_PANEL_CUSTOMIZE_RENAME_TITLE_LABEL')}
                                </div>

                                <div className="icon">
                                    <i className="icon-pencil-1" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div
                                className="option"
                                ng-if="$ctrl.isAdmin($ctrl.User.getUserObject())"
                                ng-click="$ctrl.addUser()"
                            >
                                <div className="label">{t('CHANNEL_ADD_USERS')}</div>

                                <div className="icon">
                                    <i className="icon-add" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div className="option" ng-click="$ctrl.mute()">
                                <div className="label">
                                    <span ng-if="$ctrl.isMuted()">
                                        {t('CHANNEL_MUTE_REACTIVE')}
                                    </span>
                                    <span ng-if="!$ctrl.isMuted()">{t('CHANNEL_MUTE')}</span>
                                </div>

                                <div className="icon">
                                    <i
                                        className="icon-alarm-bell-1"
                                        aria-hidden="true"
                                        ng-if="!$ctrl.isMuted()"
                                    ></i>
                                    <i
                                        className="icon-alarm-bell-off"
                                        aria-hidden="true"
                                        ng-if="$ctrl.isMuted()"
                                    ></i>
                                </div>
                            </div>

                            <div className="option" ng-click="$ctrl.leaveChannel()">
                                <div className="label">{t('CHANNEL_LEAVE')}</div>

                                <div className="icon">
                                    <i className="icon-logout-1" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div className="option" ng-click="$ctrl.togglePinChannel()">
                                <div className="label">
                                    {channelInfo.pinned
                                        ? t('CHANNEL_PIN_ON')
                                        : t('CHANNEL_PIN_OFF')}
                                </div>

                                <div className="icon">
                                    <i
                                        ng-class="{ 'icon-pin': !$ctrl.channelInfo.pinned, 'icon-unpin': $ctrl.channelInfo.pinned }"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </div>

                            <div
                                className="option"
                                ng-if="$ctrl.isAdmin($ctrl.User.getUserObject())"
                                ng-click="$ctrl.deleteChannel()"
                            >
                                <div className="label">{t('CHANNEL_DELETE')}</div>

                                <div className="icon">
                                    <i className="icon-bin-2" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="channel" ng-if="!$ctrl.isChannelFullyLoaded">
                    <Placeholder template={'channel'} />
                    <Placeholder template={'text'} />
                    <Placeholder template={'text'} />
                    <Placeholder template={'text'} />
                </div>
            )}
        </>
    );
};

export default ChannelDetails;
