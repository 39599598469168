import { useConfig } from '../providers/ConfigProvider';
import useRight from 'hooks/useRight';

const useHomeStateName = () => {
    const { hasRole } = useRight();
    const { config } = useConfig();

    const getHomeStateName = () => {
        if (!config.homeStateName) {
            return getFeedDefaultViewState();
        }

        switch (config.homeStateName) {
            case 'posts.feed':
                return getFeedDefaultViewState();
            default:
                return `auth.${config.homeStateName}`;
        }
    };

    const getFeedDefaultView = () => {
        let stateName = 'posts';

        if (hasRole('HAS_STACKED_VIEW_DEFAULT') || hasRole('HAS_ESSENTIAL_VIEW_DEFAULT')) {
            stateName = hasRole('HAS_STACKED_VIEW_DEFAULT') ? 'stacked' : 'essentials';
        }

        return stateName;
    };

    const getFeedDefaultViewState = () => {
        return `auth.${getFeedDefaultView()}.feed`;
    };

    return {
        getHomeStateName,
        getFeedDefaultView,
        getFeedDefaultViewState
    };
};

export default useHomeStateName;
