import { useHttp } from 'api/useHttp';

const useRgpd = () => {
    const axios = useHttp();

    const getRgpdCampaignConfigs = () => {
        return axios.get('/rgpd-config/campaigns').then((response) => response.data);
    };

    const postRgpdCampaignConfigs = (data) => {
        return axios.post('/rgpd-config/campaigns', data).then((response) => response.data);
    };

    return {
        getRgpdCampaignConfigs,
        postRgpdCampaignConfigs
    };
};

export default useRgpd;
