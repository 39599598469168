import { useTranslation } from 'react-i18next';

/**
 * OrderOfAdditionalInformation component to display the order of additional information in the directory config
 * @param {*} methods from the useForm hook with react-hook-form
 * @returns {JSX.Element} LevelsToPin component
 */
const OrderOfAdditionalInformation = ({ methods }) => {
    const { t } = useTranslation();

    return <div>OrderOfAdditionalInformation</div>;
};

export default OrderOfAdditionalInformation;
