import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useDevice } from 'providers/DeviceContext';
import { useConfig } from 'providers/ConfigProvider';
import useUserService from 'api/useUserService';
import { useAuth } from 'providers/AuthContext';
import { useAlert } from 'providers/AlertContext';

const Redirect = () => {
    const { params } = useCurrentStateAndParams();
    const { t } = useTranslation();
    const router = useRouter();
    const { device } = useDevice();
    const { config } = useConfig();
    const { showToast } = useAlert();
    const { setIsAuthenticated } = useAuth();
    const { loginWithSSOToken } = useUserService();

    const getValue = (name, values) => {
        let valueFound = false;

        values.forEach((value) => {
            if (value.name === name) {
                valueFound = value.value;
            }
        });
        return valueFound;
    };

    const hashToArray = (exploded) => {
        let values = [];

        exploded.forEach((value) => {
            let associatedValue = value.split('=');

            values.push({
                name: associatedValue[0],
                value: associatedValue[1]
            });
        });

        return values;
    };

    useEffect(() => {
        let access_token = null;
        let code = params.code;

        if (params['#'] !== null) {
            let hash = params['#'].replace('#', '');
            let values = hashToArray(hash.split('&'));
            access_token = getValue('access_token', values) || getValue('id_token', values);
            code = params.code || getValue('code', values);
        }

        if (!code && !access_token) {
            showToast({
                text: t('LOGIN_SSO_SERVER_ERROR'),
                duration: 7000
            });

            router.stateService.go(config.loginStateName);
            return;
        }

        let redirectUri = null;
        let clientId = null;

        if (device.isApp) {
            redirectUri = config.mobile_sso_redirect_uri || null;
            clientId = config.mobile_sso_client_id || null;
        } else {
            redirectUri = config.sso_redirect_uri || null;
            clientId = config.sso_client_id || null;
        }

        loginWithSSOToken(access_token, code, clientId, redirectUri)
            .then((data) => {
                setIsAuthenticated(true);
            })
            .catch((error) => {});
    }, []);

    return <div>Registration</div>;
};

export default Redirect;
