import React, { useEffect, useState } from 'react';
import WidgetsService from 'components/Widgets/services/Widget';
import useWidgetsService from 'api/useWidgetService';
import classes from 'classnames';
import { useQuery } from '@tanstack/react-query';

import Title from './components/Title';
import Action from './components/Action';
import Shortcut from './components/Shortcut';
import Shortcuts from './components/Shortcuts';
import Text from './components/Text';
import Separator from './components/Separator';
import Category from './components/Category';
import Iframe from './components/Iframe';
import Rss from './components/Rss';
import Calendar from './components/Calendar';
import SharedDocument from './components/SharedDocument';
import SectionStart from './components/SectionStart';
import BlogArticles from './components/BlogArticles';
import Ambassador from './components/Ambassador';
import Image from './components/Image';
import Chats from './components/Chats';
import Notifications from './components/Notifications';
import Tags from './components/Tags';
import Campaigns from './components/Campaigns';
import Search from './components/Search';
import Tree from './components/Tree';
import Posts from './components/Posts';

const Widgets = ({
    position = null,
    onlyGTX = false,
    isChild = false,
    childrenWidgets = null,
    innerRef = null,
    widgetsLoaded = null,
    optionsLoaded = null,
    children
}) => {
    const widgetService = new WidgetsService();
    const [options, setOptions] = useState(null);
    const [items, setItems] = useState(childrenWidgets);
    const [sections, setSections] = useState(null);

    const { getUserWidgetConfigs } = useWidgetsService();
    const { data: widgets } = useQuery({
        queryKey: [`widgets_${position}`],
        queryFn: () => {
            if (position) {
                return getUserWidgetConfigs(position);
            }
            return null;
        }
    });

    useEffect(() => {
        setItems(widgetsLoaded);
    }, [widgetsLoaded]);

    useEffect(() => {
        setOptions(optionsLoaded);
    }, [optionsLoaded]);

    useEffect(() => {
        if (widgets && !items) {
            setItems(
                getItems(
                    widgets.map((widget) => {
                        return widgetService.mergeWithDefaultConfigs(widget);
                    })
                )
            );
            setOptions(widgetService.mergeSpringboardWithDefaultConfigs(widgets));
        }
    }, [widgets]);

    const getWidgetStyle = (widget) => {
        if (widget.type === 'tree' || widget.type === 'search' || widget.type === 'posts') {
            return null;
        }

        if (widget.type === 'section-start') {
            return {
                'background-color': getWidgetStyleValueForKey(widget, 'bgColor')
            };
        }

        let roundSize = getWidgetStyleValueForKey(options, 'roundSize');
        let hasCustomRoundSize = getWidgetStyleValueForKey(widget, 'customRoundSize');

        if (hasCustomRoundSize) {
            roundSize = getWidgetStyleValueForKey(widget, 'roundSize');
        }

        let style = {
            padding: `${getWidgetStyleValueForKey(widget, 'marginTopSize')}px ${getWidgetStyleValueForKey(widget, 'marginRightSize')}px ${getWidgetStyleValueForKey(widget, 'marginBottomSize')}px ${getWidgetStyleValueForKey(widget, 'marginLeftSize')}px`,
            borderRadius: `${roundSize}px`
        };

        let hasBgGradient = getWidgetStyleValueForKey(widget, 'bgGradient');

        if (hasBgGradient) {
            let bgGradientType = getWidgetStyleValueForKey(widget, 'bgGradientType');
            let bgGradientStartColor = getWidgetStyleValueForKey(widget, 'bgGradientStartColor');
            let bgGradientEndColor = getWidgetStyleValueForKey(widget, 'bgGradientEndColor');
            let bgGradientAngle = getWidgetStyleValueForKey(widget, 'bgGradientAngle');

            let bgGradientStyle = null;

            if (bgGradientType === 'linear') {
                bgGradientStyle = `linear-gradient(${bgGradientAngle}deg, ${bgGradientStartColor}, ${bgGradientEndColor})`;
            } else {
                bgGradientStyle = `radial-gradient(${bgGradientStartColor}, ${bgGradientEndColor})`;
            }

            style = {
                ...style,
                backgroundImage: bgGradientStyle
            };
        } else {
            style = {
                ...style,
                backgroundColor: getWidgetStyleValueForKey(widget, 'bgColor')
            };
        }

        if (getWidgetStyleValueForKey(widget, 'borderSize') > 0) {
            style = {
                ...style,
                border: `${getWidgetStyleValueForKey(widget, 'borderSize')}px solid ${getWidgetStyleValueForKey(widget, 'borderColor')}`
            };
        }

        return style;
    };

    const getWidgetStyleValueForKey = (item, key) => {
        return widgetService.getWidgetStyleValueForKey(item, key);
    };

    const getWidgetClasses = (widget) => {
        return [
            widget.type,
            getWidgetStyleValueForKey(widget, 'size'),
            getWidgetStyleValueForKey(widget, 'layoutMode')
        ];
    };

    const getGridStyle = () => {
        if (isChild) {
            return;
        }

        return {
            backgroundColor: getWidgetStyleValueForKey(options, 'bgColor'),
            margin: `0px -${getWidgetStyleValueForKey(options, 'gutterSize') / 2}px`,
            padding: `${getWidgetStyleValueForKey(options, 'marginTopBottomSize')}px ${getWidgetStyleValueForKey(options, 'marginLeftRightSize')}px`
        };
    };

    const getItemStyle = (item) => {
        let style = {
            padding: `0px ${getWidgetStyleValueForKey(options, 'gutterSize') / 2}px`
        };

        if (item.type === 'title') {
            style = {
                ...style
            };
        } else if (
            item.type === 'tree' ||
            item.type === 'search' ||
            item.type === 'separator' ||
            item.type === 'posts' ||
            item.type === 'section-start'
        ) {
            let offsetSize =
                getWidgetStyleValueForKey(options, 'marginLeftRightSize') -
                getWidgetStyleValueForKey(options, 'gutterSize') / 2;

            style = {
                margin: `0 -${offsetSize}px`,
                marginBottom: `${getWidgetStyleValueForKey(options, 'gutterSize')}px`
            };
        } else {
            style = {
                ...style,
                marginBottom: `${getWidgetStyleValueForKey(options, 'gutterSize')}px`
            };
        }

        return style;
    };

    const getItemClasses = (item) => {
        return [item.type, getWidgetStyleValueForKey(item, 'size')];
    };

    const getItems = (items) => {
        const startIndexes = items
            .map((item, i) => (item.type === 'section-start' ? i : -1))
            .filter((i) => i !== -1);

        const endIndexes = items
            .map((item, i) => (item.type === 'section-end' ? i : -1))
            .filter((i) => i !== -1);

        if (
            startIndexes.length === 0 ||
            endIndexes.length === 0 ||
            startIndexes.length !== endIndexes.length
        ) {
            return getItemsWithoutSectionBlocks(items);
        }

        const sectionsComputed = startIndexes.reduce((acc, startIndex, index) => {
            const endIndex = endIndexes[index];

            if (startIndex >= endIndex) {
                return acc;
            }

            return acc.concat({
                id: items[startIndex].id,
                startIndex,
                endIndex,
                children: getInternalSectionChildren(startIndex, endIndex, items)
            });
        }, []);

        setSections(sectionsComputed);

        if (sectionsComputed.length === 0) {
            return getItemsWithoutSectionBlocks(items);
        }

        return items.filter((item, index) => {
            if (item.type === 'section-end') {
                return false;
            }

            if (item.type === 'section-start') {
                return true;
            }

            return sectionsComputed.every((section) => {
                return section.startIndex > index || section.endIndex < index;
            });
        });
    };

    const getItemsWithoutSectionBlocks = (items) => {
        return items.filter((item) => {
            return item.type !== 'section-start' && item.type !== 'section-end';
        });
    };

    const getInternalSectionChildren = (startIndex, endIndex, items) => {
        return items.filter((item, index) => index > startIndex && index < endIndex);
    };

    const getSectionChildren = (id) => {
        const section = sections.find((section) => section.id === id);

        if (!section) {
            return null;
        }

        return section.children;
    };

    return (
        <div className="aside-wrapper" ref={innerRef}>
            <div className="widgets">
                <div className="grid" style={getGridStyle()}>
                    {children}
                    {items?.map((widget, index) => {
                        return (
                            <div
                                className={`item ${classes(getItemClasses(widget))}`}
                                style={getItemStyle(widget)}
                                key={`widget_${index}`}>
                                <div
                                    style={getWidgetStyle(widget)}
                                    className={`widget ${classes(getWidgetClasses(widget))}`}>
                                    {widget.type === 'section-start' &&
                                        sections &&
                                        getSectionChildren(widget.id) && (
                                            <SectionStart
                                                children={getSectionChildren(widget.id)}
                                                widget={widget}
                                                options={options}
                                            />
                                        )}
                                    {widget.type === 'calendar' && (
                                        <Calendar widget={widget} options={options} />
                                    )}
                                    {widget.type === 'sharedDocument' && (
                                        <SharedDocument widget={widget} options={options} />
                                    )}
                                    {widget.type === 'blogArticles' && (
                                        <BlogArticles widget={widget} options={options} />
                                    )}
                                    {widget.type === 'action' && (
                                        <Action widget={widget} options={options} />
                                    )}
                                    {widget.type === 'shortcut' && (
                                        <Shortcut widget={widget} options={options} />
                                    )}
                                    {widget.type === 'shortcuts' && (
                                        <Shortcuts widget={widget} options={options} />
                                    )}
                                    {widget.type === 'text' && (
                                        <Text widget={widget} options={options} />
                                    )}
                                    {widget.type === 'title' && (
                                        <Title widget={widget} options={options} />
                                    )}
                                    {widget.type === 'separator' && (
                                        <Separator widget={widget} options={options} />
                                    )}
                                    {widget.type === 'category' && (
                                        <Category widget={widget} options={options} />
                                    )}
                                    {widget.type === 'iframe' && (
                                        <Iframe widget={widget} options={options} />
                                    )}
                                    {widget.type === 'rss' && (
                                        <Rss widget={widget} options={options} />
                                    )}
                                    {widget.type === 'ambassador' && (
                                        <Ambassador widget={widget} options={options} />
                                    )}
                                    {widget.type === 'image' && (
                                        <Image widget={widget} options={options} />
                                    )}
                                    {widget.type === 'chats' && (
                                        <Chats widget={widget} options={options} />
                                    )}
                                    {widget.type === 'notifications' && (
                                        <Notifications widget={widget} options={options} />
                                    )}
                                    {widget.type === 'tags' && (
                                        <Tags widget={widget} options={options} />
                                    )}
                                    {widget.type === 'campaigns' && (
                                        <Campaigns widget={widget} options={options} />
                                    )}
                                    {widget.type === 'search' && (
                                        <Search widget={widget} options={options} />
                                    )}
                                    {widget.type === 'tree' && (
                                        <Tree widget={widget} options={options} />
                                    )}
                                    {widget.type === 'posts' && (
                                        <Posts widget={widget} options={options} />
                                    )}
                                    {/*

                            <missions-widget className="widget" ng-class="$ctrl.getWidgetClasses(widget)"
                                             ng-style="$ctrl.getWidgetStyle(widget)" widget="widget" options="$ctrl.options"
                                             ng-switch-when="missions"></missions-widget>

                            <feedbacks-widget className="widget" ng-class="$ctrl.getWidgetClasses(widget)"
                                              ng-style="$ctrl.getWidgetStyle(widget)" widget="widget"
                                              options="$ctrl.options" ng-switch-when="feedback"></feedbacks-widget>

                            <blog-articles-springboard-widget className="widget" ng-class="$ctrl.getWidgetClasses(widget)"
                                                              ng-style="$ctrl.getWidgetStyle(widget)" widget="widget"
                                                              options="$ctrl.options"
                                                              ng-switch-when="blogArticles"></blog-articles-springboard-widget>
    */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Widgets;
