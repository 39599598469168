import React, { useRef, useEffect } from 'react';
import NavbarProvider, { useNavbar } from './providers/NavbarProvider';
import { UIView } from '@uirouter/react';
import { useModal } from 'providers/ModalContext';
import { ComposerProvider } from 'pages/Auth/providers/ComposerContext';
import Top from './components/Top';
import TreeProvider from './providers/TreeProvider';
import ComposerModal from '../../components/Composer/ComposerModal';
import Navbar from '../../components/Navbar/Navbar';
import Loader from '../../components/Loader/Loader';
import useViewport from '../../hooks/useViewport';
import TagProvider from './providers/TagProvider';
import ChannelsProvider from './providers/ChannelsProvider';
import ShowLikerModalProvider from './providers/ShowLikerModalProvider';
import ShowLikerModal from './modals/ShowLikerModal';
import TagModalProvider from './providers/TagModalProvider';
import TagModal from './modals/TagModal';
import JoinDocumentModalProvider from './providers/JoinDocumentModalProvider';
import JoinDocumentModal from './modals/JoinDocumentModal';

import { useDevice } from 'providers/DeviceContext';
import { useScroll } from 'react-use';
import NotificationsProvider from './providers/NotificationsProvider';
import TreeModal from './modals/TreeModal';
import { useTranslation } from 'react-i18next';
import SearchModal from './modals/SearchModal';
import CarouselModal from './modals/CarouselModal';
import PreviewModal from './modals/PreviewModal';

const AuthNavBar = () => {
    const { navbarSettings } = useNavbar();

    return (
        <div className="navbarRoot">
            <Navbar settings={navbarSettings} />
        </div>
    );
};

const Auth = () => {
    const viewport = useViewport();
    const { hasModalOpened } = useModal();
    const { setScrollPosition } = useDevice();
    const contentRef = useRef(null);
    const { x, y } = useScroll(contentRef);
    const { t } = useTranslation();

    useEffect(() => {
        setScrollPosition({ x, y });
    }, [x, y]);

    return (
        <div className="auth">
            <TagProvider>
                <ChannelsProvider>
                    <NotificationsProvider>
                        <JoinDocumentModalProvider>
                            <TagModalProvider>
                                <ShowLikerModalProvider>
                                    <TreeProvider>
                                        <TreeModal
                                            name={'tree-modal'}
                                            title={t('POST_CHOOSE_WORKSPACE')}
                                            page={'tree'}
                                            options={{ isSidebar: false }}
                                            className={'tree-modal'}
                                        />
                                        <ComposerProvider>
                                            <Top />
                                            <CarouselModal />
                                            <PreviewModal />
                                            <SearchModal />
                                            <ShowLikerModal />
                                            <TagModal />
                                            <JoinDocumentModal />
                                            <ComposerModal />
                                            <NavbarProvider>
                                                <AuthNavBar />
                                                {viewport.gtXs && <Loader />}
                                                {/*

                                                    <navbar user="$ctrl.user" channels-resolver="$ctrl.channelsResolver"
                                                            notifications-data="$ctrl.notificationsData"
                                                            ng-class="{ 'keyboard-open': $ctrl.Device.getDevice().isKeyboardOpen }"></navbar>

                                                    <subnav ng-if="$ctrl.Viewport.is('gt-xs')" template="$ctrl.getSubnavTemplateName()"></subnav>*/}

                                                <div className="screen">
                                                    <div
                                                        className={`content scrollable ${hasModalOpened() ? 'has-modal-opened' : null}`}
                                                        ref={contentRef}>
                                                        <div className="view">
                                                            <UIView />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavbarProvider>
                                        </ComposerProvider>
                                    </TreeProvider>
                                </ShowLikerModalProvider>
                            </TagModalProvider>
                        </JoinDocumentModalProvider>
                    </NotificationsProvider>
                </ChannelsProvider>
            </TagProvider>
        </div>
    );
};

export default Auth;
