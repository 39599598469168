import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useRight from '../../hooks/useRight';
import useMission from '../../hooks/useMission';

const MissionPreview = ({ mission }) => {
    const { t } = useTranslation();
    const { hasRole } = useRight();
    const {
        isReviewer,
        hasStatus,
        getStatusLabel,
        getStatusSlug,
        isMissionExpired,
        isStatus,
        hasProgress
    } = useMission();

    const getAction = (mission) => {
        if (hasRole('ROLE_SUPER_MISSION')) {
            return t('SEE_REALIZATIONS');
        }

        if (isReviewer()) {
            return t('MISSIONS_VIEW');
        }

        if (!hasStatus(mission)) {
            if (hasProgress(mission)) {
                return t('RESUME_MISSION');
            }

            if (isMissionExpired(mission)) {
                return t('MISSIONS_VIEW');
            } else {
                return t('START_MISSION');
            }
        } else {
            if (isStatus(mission, 'improved')) {
                return t('MISSIONS_START_FIX');
            } else {
                return t('MISSIONS_VIEW');
            }
        }
    };

    return (
        <div
            className={`mission ${!hasStatus(mission) && !isMissionExpired(mission) ? 'active' : ''}`}>
            <div className="meta">
                {hasStatus(mission) && (
                    <div className={`status ${getStatusSlug(mission)}`}>
                        {getStatusLabel(mission)}
                    </div>
                )}

                {!hasStatus(mission) && isMissionExpired(mission) && (
                    <div className="status timeout">{t('TIMEOUT')}</div>
                )}

                <div className="title">{mission.title}</div>

                <div className="text owned-by">
                    {t('X_STEPS', { stepsCount: mission.steps.length })}
                </div>

                {mission.end_at && !mission.last_history && !isMissionExpired(mission) && (
                    <div className="text">
                        {t('REALIZATION_LIMIT_DATE')}{' '}
                        {dayjs(mission.end_at).format('DD/MM/YYYY à HH:mm').replace(':', 'h')}
                    </div>
                )}

                {mission.closed_at && isStatus(mission, 'improved') && (
                    <div className="text">
                        {t('REALIZATION_LIMIT_DATE')}{' '}
                        {dayjs(mission.closed_at).format('DD/MM/YYYY à HH:mm').replace(':', 'h')}
                    </div>
                )}
            </div>

            <div className="actions">
                {!hasRole('ROLE_SUPER_MISSION') && (
                    <button className="action btn" ng-click="openAnyRealization(mission.id, true)">
                        {getAction(mission)}
                    </button>
                )}

                {isReviewer() && (
                    <button className="action btn" ng-click="openAnyRealization(mission.id)">
                        {t('SEE_REALIZATIONS')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default MissionPreview;
