import React, { useEffect, useState, useRef } from 'react';
import Placeholder from 'components/Placeholder/Placeholder';
import usePostService from '../../../api/usePostService';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import Post from '../../../components/Post/Post';
import { useCurrentStateAndParams } from '@uirouter/react';
import Actions from './partials/Actions';
import Header from './partials/Header';
import ViewsSelector from './partials/ViewsSelector';
import PostStatusTab from './partials/PostStatusTab';
import { useFeedContext } from '../providers/FeedProvider';

const Schedule = () => {
    const { params } = useCurrentStateAndParams();
    const { hasPostStatus } = useFeedContext();
    const [query, setQuery] = useState('');

    return (
        <>
            <div></div>
            <div></div>
        </>
    );
};

export default Schedule;
