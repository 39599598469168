import { Checkbox } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import WorkspaceFormsService from 'components/Form/services/WorkspaceForm';
import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import useFormRowInputComponent from '../hooks/useFormRowInputComponent';

const FileOptions = ({ input }) => {
    const { t } = useTranslation();
    const { watch, getValues } = useFormContext();
    const { isFieldType, WorkspaceForms } = useFormRowInputComponent(input);

    const isLimitedExtension = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.limit_extensions
            : input?.limit_extensions;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].limit_extensions`), input]);

    return (
        <>
            <Checkbox
                name={`workspace_form_inputs[${input.uniqueId}].limit_extensions`}
                label={t('ENABLE_LIMIT_EXTENSIONS')}
                shouldUnregister={false}
            />
            {isLimitedExtension && (
                <div className={'checkbox_list'}>
                    {WorkspaceForms.getAllowedExtensions().map((extension) => {
                        return (
                            <Checkbox
                                name={`workspace_form_inputs[${input.uniqueId}].selected_extensions[${extension}]`}
                                label={t(extension)}
                                shouldUnregister={false}
                            />
                        );
                    })}
                </div>
            )}
            {isFieldType('files') && (
                <Checkbox
                    name={`workspace_form_inputs[${input.uniqueId}].photo_grid`}
                    label={t('DISPLAY_WITH_PHOTOGRID')}
                    shouldUnregister={false}
                />
            )}
        </>
    );
};

export default FileOptions;
