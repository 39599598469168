import { useHttp } from 'api/useHttp';

const useCommentService = () => {
    const axios = useHttp();

    const addComment = (comment) => {
        let type = comment.post_id
            ? 'posts'
            : comment.blog_article_id
              ? 'blog-article'
              : 'campaigns';

        return axios.post(`/${type}/comments`, comment).then((response) => response.data);
    };

    const publishComment = (commentId) => {
        return axios.put(`/comments/${commentId}/publish`).then((response) => response.data);
    };

    const stickyComment = (commentId) => {
        return axios.put(`/comments/${commentId}/sticky`).then((response) => response.data);
    };

    const deleteComment = (comment) => {
        return axios.delete(`/comments/${comment.id}`);
    };

    const reportComment = (commentId) => {
        return axios
            .post(`/comments/reports`, { comment_id: commentId })
            .then((response) => response.data);
    };

    const getCommentsWaitingForReview = () => {
        return axios.get('/users/comments/review').then((response) => response.data);
    };

    return {
        publishComment,
        stickyComment,
        deleteComment,
        reportComment,
        getCommentsWaitingForReview,
        addComment
    };
};

export default useCommentService;
