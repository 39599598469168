import { Checkbox, Select } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import useFormRowInputComponent from '../hooks/useFormRowInputComponent';

const Options = ({ input, index }) => {
    const { t } = useTranslation();
    const { watch, getValues } = useFormContext();
    const { isFieldType, workspaceFormsService } = useFormRowInputComponent(input);

    const isHidden = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.hidden
            : input?.hidden;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].hidden`), input]);

    const isDuplicate = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.duplicate
            : input?.duplicate;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].duplicate`), input]);

    const hasCondition = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.has_condition
            : input?.has_condition;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].has_condition`), input]);

    const hasInputCondition = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.input_condition?.id
            : input?.input_condition?.id;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].input_condition`), input]);

    const canBeDedupeField = useMemo(() => {
        const values = getValues();
        if (isEmpty(values)) {
            return (
                input?.duplicate ||
                isFieldType('select') ||
                isFieldType('number') ||
                isFieldType('collection')
            );
        } else {
            if (!values.workspace_form_inputs) return;
            const workspace_form_inputs = Object.values(values.workspace_form_inputs);

            if (
                workspace_form_inputs.some((field) => field.duplicate) &&
                !values.workspace_form_inputs[input.uniqueId].duplicate
            ) {
                return false;
            }

            return isFieldType('select') || isFieldType('number') || isFieldType('collection');
        }
    }, [watch(`workspace_form_inputs`), input]);

    const getConditionalFields = useCallback(
        (fieldIndex) => {
            const values = getValues();
            if (isEmpty(values)) {
                return [];
            }
            if (!values.workspace_form_inputs) return;

            const workspace_form_inputs = Object.values(values.workspace_form_inputs);
            return workspace_form_inputs.filter((field, index) => {
                if (fieldIndex === 0 || fieldIndex <= index || !field.id) {
                    return false;
                }

                return workspaceFormsService.getFieldKeyByType('allowCondition', field.type);
            });
        },
        [watch(`workspace_form_inputs`)]
    );

    const getOptions = () => {
        const field = getConditionalFields(index).filter(
            (field) =>
                field.id === watch(`workspace_form_inputs[${input.uniqueId}].input_condition.id`)
        )[0];

        if (!field) {
            return [];
        }

        return field?.values
            .filter((v) => v)
            .map((value) => ({
                label: value,
                value
            }));
    };

    return (
        <>
            {getConditionalFields(index).length > 0 && (
                <>
                    <Checkbox
                        name={`workspace_form_inputs[${input.uniqueId}].has_condition`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_HAS_CONDITION')}
                        shouldUnregister={false}
                    />
                    {hasCondition && (
                        <>
                            <Select
                                name={`workspace_form_inputs[${input.uniqueId}].input_condition.id`}
                                label={t('WORKSPACE_CUSTOM_FORM_FIELD_CONDITION')}
                                shouldUnregister={false}
                                options={getConditionalFields(index).map((field) => {
                                    return {
                                        label: field.label,
                                        value: field.id
                                    };
                                })}
                            />
                            {hasInputCondition && (
                                <Select
                                    mode={'multiple'}
                                    shouldUnregister={false}
                                    name={`workspace_form_inputs[${input.uniqueId}].input_condition.value_condition_as_array`}
                                    label={t('WORKSPACE_CUSTOM_FORM_FIELD_CONDITION_VALUE')}
                                    options={getOptions()}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            {!isFieldType('page') && (
                <>
                    {!(isFieldType('title') || isFieldType('image')) && (
                        <Checkbox
                            name={`workspace_form_inputs[${input.uniqueId}].require`}
                            label={t('WORKSPACE_CUSTOM_FORM_FIELD_REQUIRED')}
                            shouldUnregister={false}
                        />
                    )}
                    <Checkbox
                        name={`workspace_form_inputs[${input.uniqueId}].hidden`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_HIDDEN')}
                        shouldUnregister={false}
                    />

                    {isHidden && (
                        <Checkbox
                            name={`workspace_form_inputs[${input.uniqueId}].hidden_for_admin`}
                            label={t('WORKSPACE_CUSTOM_FORM_FIELD_HIDDEN_FOR_ADMIN')}
                            shouldUnregister={false}
                        />
                    )}

                    {canBeDedupeField && (
                        <Checkbox
                            name={`workspace_form_inputs[${input.uniqueId}].duplicate`}
                            label={t('DUPLICATE_ON_THIS_FIELD')}
                            shouldUnregister={false}
                        />
                    )}

                    {isDuplicate && (
                        <Checkbox
                            name={`workspace_form_inputs[${input.uniqueId}].duplicate_days`}
                            label={t('DUPLICATE_DAYS')}
                            shouldUnregister={false}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Options;
