import React, { useEffect, useState } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { useRouter } from '@uirouter/react';
import limitTo from 'helpers/limitTo';
import { useChannels } from '../../../pages/Auth/providers/ChannelsProvider';
import channelOrderByLatestActivity from '../../../pages/Chat/helpers/channlOrderByLatestActivity';
import useUser from 'hooks/useUser';

const Chats = ({ widget, options }) => {
    const router = useRouter();
    const { channels } = useChannels();
    const { getStyleValueForKey, getFieldValueForKey, getRoundSize } = useWidgetComponent(
        widget,
        options
    );
    const { forgeAvatarUrl } = useUser();

    const [chats, setChats] = useState([]);

    const goToChat = (chat) => {
        //chat.unread = 0;
        router.stateService.go(
            'auth.chat.channel',
            { id: chat.id },
            { reload: true, inherit: false }
        );
    };

    useEffect(() => {
        if (channels) {
            setChats(limitTo(channelOrderByLatestActivity(channels), getFieldValueForKey('limit')));
        }
    }, [channels]);

    return (
        <div className="chats">
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                {getFieldValueForKey('title')}
            </div>

            <div className="listing">
                {chats.map((chat) => {
                    return (
                        <div
                            className="chat"
                            onClick={() => goToChat(chat)}
                            key={`chat_widget_${chat.id}`}
                        >
                            <div
                                className="meta"
                                style={{
                                    backgroundColor: getStyleValueForKey('cardBgColor'),
                                    borderRadius: getRoundSize()
                                }}
                            >
                                {chat.unread > 0 && <div className="unread">{chat.unread}</div>}

                                {!chat.cover && (
                                    <div
                                        className="icon"
                                        style={{
                                            backgroundColor: getStyleValueForKey('iconBgColor'),
                                            color: getStyleValueForKey('iconColor')
                                        }}
                                    >
                                        <i className="icon-multiple-users-1" aria-hidden="true"></i>
                                    </div>
                                )}

                                {chat.cover && (
                                    <div className="avatar">
                                        <img src={forgeAvatarUrl(chat.cover)} alt={chat.name} />
                                    </div>
                                )}

                                <div
                                    className="name"
                                    style={{ color: getStyleValueForKey('textColor') }}
                                >
                                    <div className="text">{chat.name}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Chats;
