import { Input } from 'components/Form/Inputs';
import { WorkspaceList } from 'components/Form/Admin/Inputs';

const AdminFormFieldSettings = () => {
    return (
        <>
            <Input name={'name'} label={'Nom du formulaire :'} />
            <WorkspaceList mode={'multiple'} name={'workspaces'} label={'Data feed(s) lié(s) :'} />
        </>
    );
};

export default AdminFormFieldSettings;
