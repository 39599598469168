import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePost } from '../../providers/PostProvider';

const PostInfo = () => {
    const {
        post,
        showLikers,
        showReactPeople,
        canUserSeeConsultations,
        showConsultationUsers,
        consultations,
        hasReactions,
        isScheduledPost
    } = usePost();

    const { t } = useTranslation();

    return (
        <>
            {!post.suggest &&
                !post.waiting_for_review &&
                !post.hideAll &&
                !post.hideForSearch &&
                !isScheduledPost() && (
                    <div className="post__info data">
                        {!hasReactions() && (
                            <a
                                href="javascript:;"
                                className="likes tooltip-toggle"
                                onClick={(e) => showLikers(e)}
                            >
                                <i className="icon-like-1"></i>{' '}
                                <span className="result">{post.likes.length}</span>
                            </a>
                        )}

                        {hasReactions() &&
                            post.workspace.reactions.map(
                                (reaction, index) =>
                                    !reaction.deleted_at && (
                                        <a
                                            key={index}
                                            href="javascript:;"
                                            className="likes tooltip-toggle"
                                            onClick={() => showReactPeople(reaction)}
                                        >
                                            <i
                                                className={`icon-${reaction.icon}`}
                                                style={{ backgroundColor: reaction.color }}
                                            ></i>
                                            <span className="result">
                                                {post.post_reactions[reaction.id]?.count || 0}
                                            </span>
                                        </a>
                                    )
                            )}

                        <div>
                            <i className="icon-messaging-imessage"></i>{' '}
                            <span className="result">{post.comments.length}</span>
                        </div>

                        {canUserSeeConsultations() && (
                            <div
                                className="downloads_count"
                                onClick={(e) => showConsultationUsers(e, post.id)}
                            >
                                {consultations > 1 ? (
                                    <span className="post_downloads">
                                        {t('POST_DOWNLOADS_COUNT', {
                                            download_count: consultations
                                        })}
                                    </span>
                                ) : consultations === 1 ? (
                                    <span className="post_downloads">
                                        {t('POST_DOWNLOAD_COUNT')}
                                    </span>
                                ) : (
                                    <span className="post_downloads">{t('POST_NO_DOWNLOAD')}</span>
                                )}
                                <div>{/* include 'post_read_count' template if needed */}</div>
                            </div>
                        )}
                    </div>
                )}
        </>
    );
};

export default PostInfo;
