import { useHttp } from './useHttp';
import useCampaignSocialService from './useCampaignSocialService';
import useCampaignProductService from './useCampaignProductService';
import useCampaignSurveyService from './useCampaignSurveyService';
import useCampaignRecruitingService from './useCampaignRecruitingService';
import useCampaignMissionService from './useCampaignMissionService';

const useCampaignService = () => {
    const axios = useHttp();
    const { getSocialCampaign } = useCampaignSocialService();
    const { getProductCampaign } = useCampaignProductService();
    const { getSurveyCampaign } = useCampaignSurveyService();
    const { getRecruitingCampaign } = useCampaignRecruitingService();
    const { getMissionCampaign } = useCampaignMissionService();

    const getAllActivitiesWithTrophies = (page, userId = null, lightData = false) => {
        return axios
            .get(
                `/campaign/all/activities/with/trophies?page=${page}&user=${userId}&lightData=${lightData}`
            )
            .then((response) => response.data);
    };

    const getAllRunning = (page = 1, type, limit = true, workspaceId = null) => {
        return axios
            .get(
                `/campaign/running?page=${page}&type=${type}&limit=${limit}&workspaceId=${workspaceId}`
            )
            .then((response) => response.data);
    };

    const getCountRunning = (showAll) => {
        return axios.get(`/campaign/count/running?showAll=${showAll}`).then((response) => {
            return response.data;
        });
    };

    const getLiveCampaigns = () => {
        return axios.get('/campaign/live/running').then((response) => response.data);
    };

    const getCampaignById = (id, type, fromCampaignDashboard = false) => {
        switch (type) {
            case 'social':
                return getSocialCampaign(id, fromCampaignDashboard);
            case 'survey':
                return getSurveyCampaign(id, fromCampaignDashboard);
            case 'product':
                return getProductCampaign(id, fromCampaignDashboard);
            case 'recruiting':
                return getRecruitingCampaign(id, fromCampaignDashboard);
            case 'mission':
                return getMissionCampaign(id);
            default:
                throw Error(`Invalid TYPE : ${type}`);
        }
    };

    const postCloneCampaign = (campaign) => {
        return axios
            .post(`/campaign/${campaign?.id}/clone`, campaign)
            .then((response) => response.data);
    };

    const putCampaignStatus = (data) => {
        return axios.put(`/campaign/${data.id}/status`, data).then((response) => response.data);
    };

    const deleteCampaign = (campaign) => {
        return axios.delete(`/campaign/${campaign?.id}`).then((response) => response.data);
    };

    const postAdjustRewardUser = (reward, userId) => {
        let data = {
            reward,
            userId
        };

        return axios
            .post(`/dashboard/users/rewards/adjust`, data)
            .then((response) => response.data);
    };

    return {
        getAllActivitiesWithTrophies,
        getAllRunning,
        getCountRunning,
        getLiveCampaigns,
        getCampaignById,
        postCloneCampaign,
        putCampaignStatus,
        deleteCampaign,
        postAdjustRewardUser
    };
};

export default useCampaignService;
