import React from 'react';
import classes from 'classnames';
import useWidgetComponent from '../hooks/useWidgetComponent';

const Title = ({ widget, options }) => {
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);

    return (
        <div className="title">
            <div
                className={`title ${classes(getStyleValueForKey('titleAlign'))}`}
                style={{
                    color: getStyleValueForKey('titleColor'),
                    fontSize: getStyleValueForKey('fontSize', 'px')
                }}
            >
                {getFieldValueForKey('title')}
            </div>
        </div>
    );
};

export default Title;
