export function hasReadableWorkspaceForLevelRight(levelRight) {
    return hasWorkspaceForLevelRightAndKey(levelRight, 'is_readable');
}

export function hasWritableWorkspaceForLevelRight(levelRight) {
    return hasWorkspaceForLevelRightAndKey(levelRight, 'is_writable');
}

export function hasHalfReadableWorkspaceForLevelRight(levelRight) {
    return hasWorkspaceForLevelRightAndKey(levelRight, 'is_half_readable');
}

export function hasAdminWorkspaceForLevelRight(levelRight) {
    return hasWorkspaceForLevelRightAndKey(levelRight, 'is_admin');
}

export function hasWorkspaceForLevelRightAndKey(levelRight, key = 'is_readable') {
    return levelRight.level_right_workspaces.some((levelRightWorkspace) => {
        return levelRightWorkspace[key];
    });
}

export function getReadableWorkspacesForLevelRight(levelRight) {
    return getWorkspacesForLevelRightAndKey(levelRight, 'is_readable');
}

export function getWritableWorkspacesForLevelRight(levelRight) {
    return getWorkspacesForLevelRightAndKey(levelRight, 'is_writable');
}

export function getHalfReadableWorkspacesForLevelRight(levelRight) {
    return getWorkspacesForLevelRightAndKey(levelRight, 'is_half_readable');
}

export function getAdminWorkspacesForLevelRight(levelRight) {
    return getWorkspacesForLevelRightAndKey(levelRight, 'is_admin');
}

export function getWorkspacesForLevelRightAndKey(levelRight, key = 'is_readable') {
    return levelRight.level_right_workspaces
        .filter((levelRightWorkspace) => {
            return levelRightWorkspace[key];
        })
        .map((levelRightWorkspace) => levelRightWorkspace.workspace.name)
        .join(', ');
}
