import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div className="row">
                <div className="icon" style={{ width: '50px', height: '50px' }}></div>

                <div className="column">
                    <div className="row space-between">
                        <div className="text" style={{ width: '50%' }}></div>
                        <div className="text" style={{ width: '10%' }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
