import React from 'react';
import useUtils from 'hooks/useUtils';
import { useComposer } from 'pages/Auth/providers/ComposerContext';
import { indexOf, some } from 'lodash-es';
import useRight from '../../../hooks/useRight';
import useWorkspace from '../../../hooks/useWorkspace';

const Category = () => {
    const {
        userHasFewWorkspaces,
        writableWorkspacesByCategories,
        writableWorkspaces,
        setCategory,
        setCategoryAndWorkspace,
        setCurrentStepIndex
    } = useComposer();

    const { hasRole } = useRight();
    const { forgeIconUrl } = useWorkspace();

    const isChildOfTopLevelCategory = (category) => {
        return some(writableWorkspacesByCategories, (item) => {
            if (!!item.categories_id && item.categories_id.length > 0) {
                return indexOf(item.categories_id, category.id) !== -1;
            }

            return false;
        });
    };

    const { getFirstLetter } = useUtils();

    return (
        <div className="step category">
            {!userHasFewWorkspaces() && (
                <div className="categories">
                    {writableWorkspacesByCategories.map((category) => {
                        return (
                            <div
                                className="category"
                                onClick={() => {
                                    setCategory(category);
                                    setCurrentStepIndex(1);
                                }}
                                key={`category_${category.id}`}>
                                <div
                                    className={`meta ${isChildOfTopLevelCategory(category) ? 'has-parent' : null}`}>
                                    <div className="name">
                                        <div className="text">{category.name}</div>
                                    </div>

                                    <div className="arrow">
                                        <i className="icon-arrow-left" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {userHasFewWorkspaces() && (
                <div className="workspaces">
                    <div className="workspaces">
                        {writableWorkspaces.map((item) => {
                            return (
                                <div
                                    className="workspace"
                                    onClick={() => {
                                        setCategoryAndWorkspace(item.workspace_data.workspace);
                                    }}>
                                    <div className="meta">
                                        {hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                                            <div
                                                className="icon"
                                                style={{
                                                    backgroundColor:
                                                        item.workspace_data.workspace.icon_color
                                                }}>
                                                <div className="letter">
                                                    {getFirstLetter(
                                                        item.workspace_data.workspace.name
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="icon">
                                                <img
                                                    src={forgeIconUrl(
                                                        item.workspace_data.workspace.icon_name
                                                    )}
                                                />
                                            </div>
                                        )}

                                        <div className="name">
                                            <div className="parent">
                                                {item.workspace_data.workspace.category.name}
                                            </div>
                                            <div className="text">
                                                {item.workspace_data.workspace.name}
                                            </div>
                                        </div>

                                        <div className="arrow">
                                            <i className="icon-arrow-left" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Category;
