import { useTranslation } from 'react-i18next';

const useAttachmentIcon = () => {
    const { t } = useTranslation();

    const attachmentIcon = (item, attr = 'class') => {
        const icon_mime_types = [
            {
                icon: 'icon-office-file-xls-1',
                color: '#8EF15C',
                description: t('ATTACHMENT_FILE_DESCRIPTION_XLS'),
                mime_types: [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
                    'application/vnd.ms-excel.sheet.macroEnabled.12',
                    'application/vnd.ms-excel.template.macroEnabled.12',
                    'application/vnd.ms-excel.addin.macroEnabled.12',
                    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                    'text/csv',
                    'application/vnd.oasis.opendocument.spreadsheet'
                ]
            },
            {
                icon: 'icon-office-file-pdf-1',
                color: '#FF323E',
                description: t('ATTACHMENT_FILE_DESCRIPTION_PDF'),
                mime_types: ['application/pdf']
            },
            {
                icon: 'icon-office-file-ppt-1',
                color: '#FE6D03',
                description: t('ATTACHMENT_FILE_DESCRIPTION_PPT'),
                mime_types: [
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.openxmlformats-officedocument.presentationml.template',
                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                    'application/vnd.ms-powerpoint.template.macroEnabled.12',
                    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
                    'application/vnd.oasis.opendocument.presentation'
                ]
            },
            {
                icon: 'icon-office-file-text',
                color: '#337799',
                description: t('ATTACHMENT_FILE_DESCRIPTION_DOC'),
                mime_types: [
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                    'application/vnd.ms-word.document.macroEnabled.12',
                    'application/vnd.ms-word.template.macroEnabled.12'
                ]
            },
            {
                icon: 'icon-office-file-text-graph-alternate',
                color: '#337799',
                description: t('ATTACHMENT_FILE_DESCRIPTION_ODT'),
                mime_types: ['application/vnd.oasis.opendocument.text']
            },
            {
                icon: 'icon-file-zip',
                color: '#FFCE21',
                description: t('ATTACHMENT_FILE_DESCRIPTION_ZIP'),
                mime_types: [
                    'application/octet-stream',
                    'application/x-bzip',
                    'application/x-bzip2',
                    'application/java-archive',
                    'application/x-rar-compressed',
                    'application/x-tar',
                    'application/zip',
                    'application/x-7z-compressed',
                    'application/x-gzip'
                ]
            },
            {
                icon: 'icon-design-file-text-image',
                color: '#672E71',
                description: t('ATTACHMENT_FILE_DESCRIPTION_JPG'),
                mime_types: [
                    'image/gif',
                    'image/x-icon',
                    'image/jpeg',
                    'image/svg+xml',
                    'image/tiff',
                    'image/webp',
                    'image/png'
                ]
            },
            {
                icon: 'icon-video-file-camera',
                color: '#672E71',
                description: t('ATTACHMENT_FILE_DESCRIPTION_MP4'),
                mime_types: [
                    'video/x-msvideo',
                    'video/mpeg',
                    'video/mp4',
                    'video/ogg',
                    'video/webm',
                    'video/3gpp',
                    'video/3gpp2'
                ]
            },
            {
                icon: 'icon-audio-file',
                color: '#672E71',
                description: t('ATTACHMENT_FILE_DESCRIPTION_AUDIO'),
                mime_types: [
                    'audio/aac',
                    'audio/midi',
                    'audio/ogg',
                    'audio/x-wav',
                    'audio/webm',
                    'audio/3gpp',
                    'audio/3gpp2'
                ]
            },
            {
                icon: 'icon-office-file-text-graph-alternate',
                color: '#D2CCBD',
                description: t('ATTACHMENT_FILE_DESCRIPTION_SCRIPT'),
                mime_types: [
                    'application/x-csh',
                    'application/x-sh',
                    'text/css',
                    'text/html',
                    'application/js',
                    'application/json',
                    'application/xhtml+xml',
                    'application/xml',
                    'application/vnd.mozilla.xul+xml'
                ]
            }
        ];

        let icon_class = 'icon-design-file-text-image';
        let icon_color = null;
        let icon_description = t('ATTACHMENT_FILE_DESCRIPTION_DEFAULT');

        icon_mime_types.forEach((icon_mime_type) => {
            if (!!item) {
                if (icon_mime_type.mime_types.indexOf(item.type) !== -1) {
                    icon_class = icon_mime_type.icon;
                    icon_color = icon_mime_type.color ? icon_mime_type.color : null;
                    icon_description = icon_mime_type.description;
                }
            }
        });

        if (attr === 'description') {
            return icon_description;
        }

        if (attr === 'color') {
            return icon_color;
        }

        return icon_class;
    };

    return {
        attachmentIcon
    };
};

export default useAttachmentIcon;
