import { Checkbox, Select, InputList, Input } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useAdminLevelContext } from '../../../../../../providers/AdminLevelProvider';
import useFormRowInputComponent from '../hooks/useFormRowInputComponent';

const SelectOptions = ({ input }) => {
    const { t } = useTranslation();
    const { watch, getValues } = useFormContext();
    const { levelConfigs } = useAdminLevelContext();
    const { isFieldType } = useFormRowInputComponent(input);

    const basedOnLevel = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.basedOnLevel
            : !!input?.level_config;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].basedOnLevel`), input]);

    const hasQualifations = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.has_qualified_values
            : !!input?.has_qualified_values;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].has_qualified_values`), input]);

    const hasLimitedValuesCount = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.has_limited_values_count
            : !!input?.has_limited_values_count;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].has_limited_values_count`), input]);

    return (
        <>
            <InputList
                label={t('Liste des valeurs :')}
                value={input?.values ? input?.values : []}
                name={`workspace_form_inputs[${input.uniqueId}].values`}
                shouldUnregister={false}
            />
            <Checkbox
                name={`workspace_form_inputs[${input.uniqueId}].basedOnLevel`}
                value={basedOnLevel}
                label={"À partir des valeurs d'un level"}
                shouldUnregister={false}
            />
            {basedOnLevel && (
                <Select
                    name={`workspace_form_inputs[${input.uniqueId}].level_config`}
                    label={'Sélectionnez un level'}
                    options={levelConfigs?.map((l) => ({
                        label: l.name,
                        value: l.id
                    }))}
                    shouldUnregister={false}
                />
            )}
            {isFieldType('collection') && (
                <>
                    <Checkbox
                        name={`workspace_form_inputs[${input.uniqueId}].has_dynamic_values`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_DYNAMIC_VALUES')}
                        shouldUnregister={false}
                    />
                    <Checkbox
                        name={`workspace_form_inputs[${input.uniqueId}].has_limited_values_count`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_LIMIT_VALUES_COUNT')}
                        shouldUnregister={false}
                    />
                    {hasLimitedValuesCount && (
                        <Input
                            name={`workspace_form_inputs[${input.uniqueId}].limited_values_count`}
                            label={t('WORKSPACE_CUSTOM_FORM_FIELD_LIMIT_VALUES_COUNT_LABEL')}
                            type={'number'}
                            shouldUnregister={false}
                        />
                    )}
                    <Checkbox
                        name={`workspace_form_inputs[${input.uniqueId}].has_qualified_values`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_QUALIFY_VALUES')}
                        shouldUnregister={false}
                    />
                    {hasQualifations && (
                        <InputList
                            label={t('Liste des qualifications :')}
                            value={input?.qualifications ? input?.qualifications : []}
                            name={`workspace_form_inputs[${input.uniqueId}].qualifications`}
                            shouldUnregister={false}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default SelectOptions;
