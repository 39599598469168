import React, { createContext, useContext } from 'react';
import useWorkspaceService from 'api/useWorkspaceService';
import useWorkspace from 'hooks/useWorkspace';
import { useQuery } from '@tanstack/react-query';

const AdminFormContext = createContext();

const AdminFormProvider = ({ children }) => {
    const { getAllWorkspaces } = useWorkspaceService();
    const { extractWorkspaceFormsFromWorkspaces } = useWorkspace();

    const { data: workspaceForms, isLoading } = useQuery({
        queryKey: ['allWorkspace'],
        queryFn: () => {
            return getAllWorkspaces([]).then((data) => {
                return extractWorkspaceFormsFromWorkspaces(data);
            });
        }
    });

    return (
        <AdminFormContext.Provider
            value={{
                workspaceForms,
                isLoading
            }}>
            {children}
        </AdminFormContext.Provider>
    );
};

export const useAdminFormContext = () => useContext(AdminFormContext);
export default AdminFormProvider;
