export const sinceOptions = [
    {
        label: '24 heures',
        value: '1d'
    },
    {
        label: '7 jours',
        value: '7d'
    },
    {
        label: '30 jours',
        value: '1m'
    },
    {
        label: '3 mois',
        value: '3m'
    },
    {
        label: '6 mois',
        value: '6m'
    },
    {
        label: '1 an',
        value: '1y'
    },
    {
        label: 'Depuis le début',
        value: 'start'
    }
];

export const orders = [
    {
        label: 'TILE_ORDER_RECENT',
        value: 'recent'
    },
    {
        label: 'TILE_ORDER_VIEWS',
        value: 'view'
    },
    {
        label: 'TILE_ORDER_COMMENT',
        value: 'comment'
    },
    {
        label: 'TILE_ORDER_LIKE',
        value: 'like'
    }
];

const useTileComponent = () => {};

export default useTileComponent;
