import { useHttp } from 'api/useHttp';

const useCommandService = () => {
    const axios = useHttp();

    const getCommands = () => {
        return axios.get('/super-admin/commands').then((response) => response.data);
    };

    const postCommand = (params) => {
        return axios.post('/super-admin/command', params).then((response) => response.data);
    };

    return {
        getCommands,
        postCommand
    };
};

export default useCommandService;
