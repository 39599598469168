import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function Checkbox({ name, label, value, ...props }) {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            const shouldUnregister = props.hasOwnProperty('shouldUnregister')
                ? props.shouldUnregister
                : true;
            if (shouldUnregister) {
                unregister(name);
            }
        };
    }, []);

    return (
        <div className="checkbox">
            <input
                className="input"
                id={name}
                type="checkbox"
                name={name}
                {...register(name, { value })}
            />
            <label className="label" htmlFor={name}>
                <div className="tick"></div>
                <div className="name">{label}</div>
            </label>
        </div>
    );
}

export default Checkbox;
