import { isArray, isNil, first } from 'lodash-es';

export default class BaseWidgets {
    constructor(options) {
        this.type = options.type || 'homepage';
        this.defaultPosition = options.defaultPosition || 3;

        this.widgets = [];

        this.colors = [
            'transparent',
            '#FFFFFF',
            '#263C8A',
            '#D44627',
            '#2E4453',
            '#F4DA49',
            '#E9A03B',
            '#245865',
            '#251C34'
        ];
    }

    getColors() {
        return this.colors;
    }

    mergeSpringboardWithDefaultConfigs(configs) {
        let springboardDefaultConfig = {
            styling: this.getSpringboardFieldsConfigs()
        };

        return {
            ...springboardDefaultConfig,
            ...configs,
            styling: springboardDefaultConfig.styling.map((style) => {
                return {
                    ...style,
                    value: this.getWidgetStyleValueForKey(configs, style.key, style.value)
                };
            })
        };
    }

    mergeWithDefaultConfigs(widget, fromImport = false) {
        let widgetDefaultConfig = this.getWidgetFieldsConfigsByType(widget.type);

        return {
            ...widgetDefaultConfig,
            ...widget,
            fields: widgetDefaultConfig.fields.map((field) => {
                if (fromImport && field.ignoreFromImport) {
                    return field;
                }

                return {
                    ...field,
                    value: this.getWidgetFieldValueForKey(widget, field.key, field.value)
                };
            }),
            styling: widgetDefaultConfig.styling.map((style) => {
                if (fromImport && style.ignoreFromImport) {
                    return style;
                }

                let defaultValue = style.migrateFromKey
                    ? this.getWidgetStyleValueForKey(widget, style.migrateFromKey, style.value)
                    : style.value;

                return {
                    ...style,
                    value: this.getWidgetStyleValueForKey(widget, style.key, defaultValue)
                };
            })
        };
    }

    getWidgetFieldsConfigsByType(type) {
        return this.widgets.find((widget) => {
            return widget.type === type;
        });
    }

    getWidgetFieldValueForKey(item, key, defaultValue = null) {
        let field = Object.values(item.fields).find((field) => {
            return field.key === key;
        });

        if (!field || isNil(field.value)) {
            return defaultValue;
        }

        return field.value;
    }

    getWidgetFieldFirstValueForKey(item, key, defaultValue = null) {
        const value = this.getWidgetFieldValueForKey(item, key, defaultValue);

        if (!value) {
            return defaultValue;
        }

        return isArray(value) ? first(value) : value;
    }

    getWidgetStyleValueForKey(item, key, defaultValue = null) {
        if (!item || !isArray(item.styling)) {
            return defaultValue;
        }

        let style = item.styling.find((style) => {
            return style.key === key;
        });

        if (!style || isNil(style.value)) {
            return defaultValue;
        }

        return style.value;
    }

    getWidgetStyleFirstValueForKey(item, key, defaultValue = null) {
        const value = this.getWidgetStyleValueForKey(item, key, defaultValue);

        if (!value) {
            return defaultValue;
        }

        return isArray(value) ? first(value) : value;
    }

    getWidgets() {
        return this.widgets;
    }
}
