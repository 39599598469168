import React from 'react';
import ProfileSidebarUser from './components/User';
import ProfileSidebarTools from './components/Tools';
import ProfileSidebarActions from './components/Actions';
import useUser from 'hooks/useUser';

const Sidebar = ({ user }) => {
    const { isMe } = useUser();

    if (!user) return;

    return (
        <>
            <div className="user card">
                <ProfileSidebarUser user={user} />
            </div>

            {isMe(user) ? (
                <>
                    <div className="tools hidden-lt-md card">
                        <ProfileSidebarTools user={user} />
                    </div>

                    <div className="actions hidden-lt-md">
                        <ProfileSidebarActions />
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default Sidebar;
