import React, { useEffect, useState } from 'react';
import { find } from 'lodash-es';
import {
    getAdminWorkspacesForLevelRight,
    getHalfReadableWorkspacesForLevelRight,
    getReadableWorkspacesForLevelRight,
    getWritableWorkspacesForLevelRight,
    hasAdminWorkspaceForLevelRight,
    hasHalfReadableWorkspaceForLevelRight,
    hasReadableWorkspaceForLevelRight,
    hasWritableWorkspaceForLevelRight
} from '../helpers/levelRight';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import AdminLevelRightActions from './AdminLevelRightActions';
import { useModal } from '../../../../../providers/ModalContext';

const AdminLevelRightRow = ({ adminLevelRight, showMore }) => {
    const { getLevelName, levels } = useAdminLevelContext();
    const [sortedLevels, setSortedLevels] = useState({});

    useEffect(() => {
        const getLevelsForLevelRight = (levelRight) => {
            let levelsByRight = {};

            levelRight.levels.forEach((levelId) => {
                let level = levels.find((level) => {
                    return level.id === parseInt(levelId);
                });

                if (!level) {
                    return;
                }

                if (levelsByRight.hasOwnProperty(level.index)) {
                    levelsByRight[level.index].push(level);
                } else {
                    levelsByRight[level.index] = [level];
                }
            });

            return levelsByRight;
        };
        setSortedLevels(getLevelsForLevelRight(adminLevelRight));
    }, [levels, adminLevelRight]);

    const findNumberOfEmployees = (levelRight, levelId) => {
        if (levelRight.level_employees_count.length === 0) {
            return 0;
        }

        let levelCount = find(levelRight.level_employees_count, (l) => {
            return l.id === levelId;
        });

        if (levelCount) {
            return levelCount.total;
        }

        return 0;
    };

    return (
        <div className="level" key={`admin_level_right_${adminLevelRight.id}`}>
            <div className="level__description">
                <div className="level__description__name">
                    {adminLevelRight.name} ({adminLevelRight.total_employees_affected.total})
                </div>

                {showMore && (
                    <div className="level__description__more">
                        {Object.keys(sortedLevels).map((levelIndex) => (
                            <div className="level__description__" key={`${levelIndex}`}>
                                <div className="level__description__title">
                                    {getLevelName(levelIndex)}
                                </div>

                                <div className="level__description__levels">
                                    <div className="value">
                                        {sortedLevels[levelIndex].map((level, index) => (
                                            <span key={`${level.id}_${index}`}>
                                                {index !== 0 && <span>, </span>}
                                                {level.name} (
                                                {findNumberOfEmployees(adminLevelRight, level.id)})
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}

                        {hasReadableWorkspaceForLevelRight(adminLevelRight) && (
                            <div className="level__description__readable">
                                <div className="level__description__title">Abonnés à</div>

                                <div className="level__description__readable__workspaces">
                                    <div className="value">
                                        {getReadableWorkspacesForLevelRight(adminLevelRight)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {hasWritableWorkspaceForLevelRight(adminLevelRight) && (
                            <div className="level__description__writable">
                                <div className="level__description__title">Contributeurs de</div>

                                <div className="level__description__writable__workspaces">
                                    <div className="value">
                                        {getWritableWorkspacesForLevelRight(adminLevelRight)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {hasHalfReadableWorkspaceForLevelRight(adminLevelRight) && (
                            <div className="level__description__halfreadable">
                                <div className="level__description__title">Semi-abonnés à</div>
                                <div className="level__description__halfreadable__workspaces">
                                    <div className="value">
                                        {getHalfReadableWorkspacesForLevelRight(adminLevelRight)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {hasAdminWorkspaceForLevelRight(adminLevelRight) && (
                            <div className="level__description__admin">
                                <div className="level__description__title">Gestionnaires de</div>
                                <div className="level__description__admin__workspaces">
                                    <div className="value">
                                        {getAdminWorkspacesForLevelRight(adminLevelRight)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <AdminLevelRightActions adminLevelRight={adminLevelRight} />
        </div>
    );
};

export default AdminLevelRightRow;
