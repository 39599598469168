import { useEditorContext } from '../../providers/EditorProvider';
import useEditorComponent from '../../hooks/useEditorComponent';
import { Title, Select, Collection, Text, LongText, Date, Recipient } from './inputs';
import { useCallback } from 'react';
import striptags from 'striptags';
import { isArrayLike, isNil, some, isEmpty } from 'lodash-es';

const Custom = () => {
    const { workspace, currentPage, goToPage, currentFormValues, allowUseEmail } =
        useEditorContext();
    const { hasPages, getPages, getInput } = useEditorComponent(workspace);

    const isCurrentPage = (pageIndex) => {
        return currentPage === pageIndex;
    };

    const pageHasRequiredFields = (page) => {
        return getFieldsForPage(page).some((input) => {
            return (
                (input.require && !isConditional(input)) ||
                (input.require && conditionIsFullFilled(input))
            );
        });
    };

    const pageHasErroredFields = (page) => {
        return getFieldsForPage(page).some((input) => {
            return !isInputFilled(input);
        });
    };

    const getFieldsForPage = (page) => {
        if (!workspace) {
            return [];
        }

        let pages = getPages();
        let inputs = workspace.workspace_form.workspace_form_inputs;

        let currentPage = pages[page - 1];
        let nextPage = pages.length > page ? pages[page] : null;

        let currentPageIndex = inputs.findIndex((input) => {
            return currentPage.id && input.id && input.id === currentPage.id;
        });

        if (currentPageIndex === -1) {
            return inputs;
        }

        if (!nextPage) {
            return inputs.slice(currentPageIndex);
        }

        let nextPageIndex = inputs.findIndex((input) => {
            return input.id === nextPage.id;
        });

        return inputs.slice(currentPageIndex, nextPageIndex);
    };

    const isConditional = (field) => {
        return !!field.input_condition?.id;
    };

    const conditionIsMatched = (field, inputConditionValue) => {
        if (field.input_condition && field.input_condition.type === 'collection') {
            let values = inputConditionValue;

            return values && values.some((value) => field.value_condition.includes(value.value));
        }

        return field.value_condition.split('|').includes(inputConditionValue);
    };

    const conditionIsFullFilled = useCallback(
        (field) => {
            if (!currentFormValues?.fields) {
                return false;
            }
            if (!isConditional(field)) {
                return true;
            }

            let inputCondition = getInput(field.input_condition.id);

            if (!currentFormValues.fields[inputCondition.id]) {
                return false;
            }

            if (isConditional(inputCondition)) {
                return (
                    conditionIsMatched(field, currentFormValues.fields[inputCondition.id].value) &&
                    conditionIsFullFilled(inputCondition)
                );
            }

            return conditionIsMatched(field, currentFormValues.fields[inputCondition.id].value);
        },
        [currentFormValues]
    );

    const getTotalCharLeft = (field) => {
        return field.limited_character_count - striptags(field.value).length;
    };

    const isFilled = useCallback(
        (input) => {
            if (!currentFormValues?.fields) {
                return false;
            }

            if (!currentFormValues.fields[input.id]) {
                return false;
            }

            if (input.type === 'position') {
                /*if(isEmpty(input.value) || input.value !== this.Geolocation.geolocationResult) {
                input.value = this.Geolocation.geolocationResult;
            }

            return !isEmpty(input.value) && this.Geolocation.geolocationResult.address !== '';*/
            }

            if (input.type === 'numeric') {
                return !isNil(currentFormValues.fields[input.id].value);
            }

            if (input.type === 'file' || input.type === 'files') {
                return (
                    !isNil(currentFormValues.fields[input.id].value) &&
                    currentFormValues.fields[input.id].value.attachments &&
                    currentFormValues.fields[input.id].value.attachments.length > 0
                );
            }

            if (isArrayLike(currentFormValues.fields[input.id].value)) {
                return !isEmpty(currentFormValues.fields[input.id].value);
            }

            return (
                !isNil(currentFormValues.fields[input.id].value) &&
                currentFormValues.fields[input.id].value !== ''
            );
        },
        [currentFormValues]
    );

    const isInputFilled = useCallback(
        (input) => {
            if (!currentFormValues?.fields) {
                return false;
            }

            if (!currentFormValues.fields[input.id]) {
                return false;
            }
            if (input.type === 'collection') {
                if (input.has_qualified_values) {
                    if (
                        some(currentFormValues.fields[input.id].value, (value) => {
                            return isNil(value.qualification) || !value.qualification;
                        })
                    ) {
                        return false;
                    }
                }
            }

            if (input.type === 'title' || input.type === 'image') {
                return true;
            }

            if (
                input.type === 'long_text' &&
                input.has_limited_character_count &&
                getTotalCharLeft(input) < 0
            ) {
                return false;
            }

            if (
                input.type === 'email' &&
                (input.opportunity_mode || input.recruiting_mode) &&
                !allowUseEmail
            ) {
                return false;
            }

            return (
                !input.require ||
                (input.require && isFilled(input)) ||
                (input.require && !conditionIsFullFilled(input))
            );
        },
        [currentFormValues, allowUseEmail]
    );

    return (
        <div className="custom">
            {hasPages() && (
                <div className="tabs">
                    {getPages().map((page, pageIndex) => {
                        return (
                            <div
                                className={`tab ${isCurrentPage(pageIndex + 1) ? 'active' : ''}`}
                                key={`tab_page_${pageIndex}`}
                                data-page={pageIndex + 1}
                                onClick={() => goToPage(pageIndex + 1)}
                                ng-class="{ 'active': $ctrl.isCurrentPage(pageIndex + 1), 'error': $ctrl.displayErrors && $ctrl.pageHasErroredFields(pageIndex + 1) }">
                                {page.label}{' '}
                                {pageHasRequiredFields(pageIndex + 1) && <span>*</span>}
                            </div>
                        );
                    })}
                </div>
            )}

            {getPages().map((page, pageIndex) => {
                if (!isCurrentPage(pageIndex + 1)) return;
                return (
                    <div className="tabs-content" key={`editor_page_${pageIndex}`}>
                        <div className="fields">
                            {getFieldsForPage(pageIndex + 1).map((field) => {
                                if (isConditional(field) && !conditionIsFullFilled(field)) return;
                                return (
                                    <div
                                        className={`field ${field.type}`}
                                        ng-class="[{ 'error': $ctrl.displayErrors && !$ctrl.isInputFilled(field) }]">
                                        {field.type === 'title' && <Title field={field} />}
                                        {field.type === 'select' && <Select field={field} />}
                                        {field.type === 'text' && <Text field={field} />}
                                        {field.type === 'long_text' && <LongText field={field} />}
                                        {field.type === 'date' && <Date field={field} />}
                                        {field.type === 'recipients' && <Recipient field={field} />}
                                        {field.type === 'collection' && (
                                            <Collection field={field} />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}

            <div className="link" ng-if="$ctrl.hasLink()">
                <div className="remove" ng-click="$ctrl.removeLink()">
                    <i className="icon-remove-circle" aria-hidden="true"></i>
                </div>

                <link-preview item="$ctrl.form.link" small="true" />
            </div>
        </div>
    );
};

export default Custom;
