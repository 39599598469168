import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { concat, uniqBy } from 'lodash-es';
import useTagService from 'api/useTagService';
import useRight from 'hooks/useRight';

const TagContext = createContext();

const TagProvider = ({ children }) => {
    const { hasRole } = useRight();
    const { getTags, getAdminTags, getEssentialTags } = useTagService();
    const [allTags, setAllTags] = useState([]);

    const { data: tags } = useQuery({
        queryKey: ['tags', 'my_tags'],
        queryFn: () => {
            if (hasRole('SHOW_TAGS')) {
                return getTags();
            } else {
                return Promise.resolve([]);
            }
        }
    });

    const { data: adminTags } = useQuery({
        queryKey: ['tags', 'admin_tags'],
        queryFn: () => {
            if (hasRole('HAS_ADMIN_TAGS')) {
                return getAdminTags();
            } else {
                return Promise.resolve([]);
            }
        }
    });

    const { data: essentialTags } = useQuery({
        queryKey: ['tags', 'essentials_tags'],
        queryFn: () => {
            if (hasRole('HAS_ADMIN_TAGS')) {
                return getEssentialTags();
            } else {
                return Promise.resolve([]);
            }
        }
    });

    useEffect(() => {
        if (tags && adminTags) {
            setAllTags(
                uniqBy(concat(tags, adminTags), (tag) => {
                    return tag.id;
                })
            );
        }
    }, [tags, adminTags]);

    const hasTags = () => {
        return allTags && allTags.length > 0;
    };

    return (
        <TagContext.Provider value={{ allTags, adminTags, tags, essentialTags, hasTags }}>
            {children}
        </TagContext.Provider>
    );
};

export const useTagContext = () => useContext(TagContext);
export default TagProvider;
