import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAttachment from '../../../hooks/useAttachment';
import useCampaignComponent from './hooks/useCampaignComponent';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Link from 'components/Link/Link';
import useEmoji from '../../../hooks/useEmoji';

const Product = ({ campaign }) => {
    const { t } = useTranslation();
    const { emoji } = useEmoji();
    const { forgeAttachmentUrl, getVideoState, getVideoFiles, getVideoType } = useAttachment();
    const {
        canSeeStats,
        showActions,
        toggleRules,
        showCarousel,
        displayReward,
        openModalCampaignForm,
        getOpportunityButtonLabel
    } = useCampaignComponent(campaign);
    const [isReadMore, setIsReadMore] = useState(false);
    const textContainerRef = useRef(null);
    const textRef = useRef(null);

    const readMoreText = isReadMore
        ? `${t('POST_DIALOG_CLOSE')} <i class="fa fa-caret-up"></i>`
        : `${t('TA_POST_READ_MORE')} <i class="fa fa-caret-down"></i>`;

    const getTextOffsetHeight = useCallback(() => {
        return textRef.current ? textRef.current.offsetHeight : 0;
    }, []);

    const hasReadMore = useCallback(() => {
        const textOffsetHeight = getTextOffsetHeight();
        return textOffsetHeight > 105;
    }, [getTextOffsetHeight]);

    const toggleReadMore = () => {
        setIsReadMore((prev) => !prev);
    };

    const containerStyle = {
        height: isReadMore ? `${getTextOffsetHeight()}px` : '105px'
    };

    useEffect(() => {
        if (textContainerRef.current && !isReadMore) {
            const textOffsetHeight = getTextOffsetHeight();
            textContainerRef.current.style.height =
                textOffsetHeight > 105 ? '105px' : `${textOffsetHeight}px`;
        }
    }, [isReadMore, getTextOffsetHeight]);

    return (
        <>
            <div className="header">
                <div className="icon">
                    <i className="fa fa-cube" aria-hidden="true"></i>
                </div>
                <div className="title">{t('TA_PRODUCT_TITLE')}</div>
                <div className="post__header__menu">
                    <button onClick={showActions}>
                        <i className="icon-navigation-menu-horizontal"></i>
                    </button>
                </div>
            </div>

            <div className="campaign">
                <div className="description">
                    <div className="title">{campaign.title}</div>
                    <div className="text-container" style={containerStyle} ref={textContainerRef}>
                        <div
                            className="text ng-quill-text"
                            ref={textRef}
                            dangerouslySetInnerHTML={{ __html: emoji(campaign.text) }}
                        ></div>
                        {hasReadMore() && (
                            <div className={`gradient ${isReadMore ? 'out' : ''}`}></div>
                        )}
                    </div>
                    {hasReadMore() && (
                        <div className="text-center">
                            <button
                                className="button dark-grey"
                                onClick={toggleReadMore}
                                dangerouslySetInnerHTML={{ __html: readMoreText }}
                            ></button>
                        </div>
                    )}
                </div>

                <div className="image">
                    {campaign.documents?.length > 0 &&
                        campaign.documents.every((doc) => doc.type.includes('image')) && (
                            <div className="pictures">
                                <div
                                    className="picture"
                                    onClick={() => showCarousel(campaign.documents, 0)}
                                >
                                    <img
                                        src={forgeAttachmentUrl(campaign.documents[0])}
                                        alt="campaign"
                                    />
                                </div>
                                {campaign.documents.length > 1 && (
                                    <div className="show-carrousel">{t('SHOW_CARROUSEL')}</div>
                                )}
                            </div>
                        )}
                    {campaign.link && (
                        <div className="item">
                            <Link item={campaign.link} className="item" campaign={true} />
                        </div>
                    )}
                </div>

                {campaign.documents?.length > 0 && campaign.documents[0].type.includes('video') && (
                    <div className="video-player-container">
                        <VideoPlayer
                            src={forgeAttachmentUrl(campaign.documents[0])}
                            files={getVideoFiles(campaign.documents[0])}
                            state={getVideoState(campaign.documents[0])}
                            type={getVideoType(campaign.documents[0].type)}
                            poster={campaign.documents[0]}
                        />
                    </div>
                )}

                <div className="actions">
                    {campaign.reward > 0 && !campaign.user_already_answered && (
                        <button className="button product big-button" onClick={toggleRules}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('TA_POST_PTS_TO_EARN', { points: campaign.reward })
                                }}
                            ></span>
                        </button>
                    )}
                    {campaign.no_quizz &&
                        (campaign.opportunity || campaign.email_opportunity) &&
                        campaign.reward > 0 && (
                            <button className="button product big-button" onClick={toggleRules}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('TA_POST_PTS_TO_EARN', {
                                            points: campaign.reward_by_opportunity
                                        })
                                    }}
                                ></span>
                            </button>
                        )}
                    {!campaign.no_quizz &&
                        campaign.rewards > 0 &&
                        campaign.reward > 0 &&
                        campaign.user_already_answered && (
                            <button
                                className="button product big-button user-already-earned"
                                onClick={displayReward}
                            >
                                {campaign.rewards} {t('TA_POST_POINTS_EARNED')}
                            </button>
                        )}
                    {!campaign.user_already_answered &&
                        campaign.status === 2 &&
                        campaign.canParticipate && (
                            <button
                                className="button product big-button bg-white"
                                onClick={() =>
                                    openModalCampaignForm(
                                        t('TA_POST_SURVEY_ANSWER_PANEL'),
                                        campaign.title
                                    )
                                }
                            >
                                {t('TA_POST_SURVEY_ANSWER_PANEL')}
                            </button>
                        )}
                    {!campaign.canParticipate && (
                        <div className="button small tiny see-stats product">
                            {t('CAMPAIGN_NO_ACCESS')}
                        </div>
                    )}
                    {!campaign.no_quizz && campaign.user_already_answered && (
                        <button
                            className="button product big-button user-already-earned"
                            onClick={() =>
                                openModalCampaignForm(
                                    t('TA_POST_PRODUCT_SEE_QUIZZ_ANSWERS'),
                                    campaign.title,
                                    false,
                                    true
                                )
                            }
                        >
                            {t('TA_POST_PRODUCT_SEE_ANSWERS')}
                        </button>
                    )}
                    {campaign.user_already_answered &&
                        (campaign.opportunity || campaign.email_opportunity) && (
                            <button
                                className="button product big-button bg-white"
                                onClick={() =>
                                    openModalCampaignForm(
                                        getOpportunityButtonLabel(),
                                        campaign.title,
                                        true
                                    )
                                }
                            >
                                {getOpportunityButtonLabel()}
                            </button>
                        )}
                    {canSeeStats() && (
                        <>
                            {campaign.submits?.length > 0 ? (
                                <button
                                    ui-sref="auth.dashboard.campaigns.feed({ type: 'product', status: 'running' })"
                                    className="button small tiny see-stats product"
                                >
                                    {t('SEE_THE_STATS')}
                                </button>
                            ) : (
                                <span className="button small tiny see-stats product">
                                    {t('NO_STATS')}
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Product;
