import { useHttp } from './useHttp';

const useUploadService = () => {
    const axios = useHttp();

    const upload = ({ url, data = {} }) => {
        const formData = new FormData();
        Object.entries(data).map(([key, value]) => {
            formData.append(key, value);
        });

        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    return {
        upload
    };
};

export default useUploadService;
