import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';
import { detect } from 'detect-browser';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import localeFR from 'antd/locale/fr_FR';
import dayjs from 'dayjs';

import defaultParameters from 'parameters/default';
import extractParamsFromUrl from '../helpers/extractParamsFromUrl';

const ConfigContext = createContext();

const getParams = (appName) => {
    try {
        if (appName) {
            return import(`../parameters/${appName}`).then((module) => {
                if (!module.default) {
                    return defaultParameters;
                }

                return {
                    ...defaultParameters,
                    ...module.default
                };
            });
        }
    } catch (error) {
        console.error('Failed to load client-specific configuration:', error);
    }

    return Promise.resolve(defaultParameters);
};

export const ConfigProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const [params, setParams] = useState(null);
    const [locale, setLocale] = useState('fr');

    const config = useMemo(() => {
        if (!params) {
            return null;
        }

        let browser = detect();
        let isSafari = !!(
            browser &&
            (browser.name === 'safari' || browser.name === 'ios' || browser.name === 'ios-webview')
        );
        let isMobile = !!(browser && (browser.os === 'Android OS' || browser.os === 'iOS'));
        let browserVersion = browser.version;
        let isIOS13OrOlder = isMobile && isSafari && parseInt(browserVersion.split('.')[0]) <= '13';

        let origin = `${location.protocol}://${location.hostname}`;

        if (location.port) {
            origin = `${origin}:${location.port}`;
        }

        if (params.dynamic_url) {
            params.serverUrl = origin;
            params.staticUrl = `${origin}/api/`;
        }

        let { url = null } = extractParamsFromUrl(params.serverUrl);

        let hostnames = [location.hostname, url.hostname].filter(
            (v, i, a) => !!v && a.indexOf(v) === i
        );

        return {
            ...params,
            origin,
            hostnames,
            apiUrl: (params.serverUrl ? params.serverUrl : '') + '/api',
            staticUrl: params.staticUrl ? params.staticUrl : '/api/',
            socket: {
                server: (params.socketUrl ? params.socketUrl : params.serverUrl) + '/',
                path: '/socket'
            },
            appSchema: params.appSchema ? params.appSchema : process.env.REACT_APP_NAME,
            isCordova: !!window.cordova,
            configs: params.configs,
            isMsie: !!document.documentMode,
            isSafari: isSafari,
            isMobileOS: isMobile,
            browserVersion: browserVersion,
            isIOS13OrOlder: isIOS13OrOlder,
            maxPeopleLimit: params.max_people_limit ? params.max_people_limit : 20,
            facebookCostPerClick: params.facebookCostPerClick ? params.facebookCostPerClick : 1.6,
            linkedInCostPerClick: params.linkedInCostPerClick ? params.linkedInCostPerClick : 2.5,
            twitterCostPerClick: params.twitterCostPerClick ? params.twitterCostPerClick : 0.4,
            otherCostPerClick: params.otherCostPerClick ? params.otherCostPerClick : 0.5,
            hasHomepage: params.has_homepage ? params.has_homepage : false,
            no_updater: params.no_updater ? params.no_updater : false,
            hide_ranking: params.hide_ranking ? params.hide_ranking : false,
            only_employee_type_allowed: params.only_employee_type_allowed
                ? params.only_employee_type_allowed
                : false,
            remove_http_cache: params.remove_http_cache ? params.remove_http_cache : false,
            hide_connexion_tabs: params.hide_connexion_tabs ? params.hide_connexion_tabs : false,
            login_is_email: params.login_is_email === false ? params.login_is_email : true,
            show_regular_form: params.show_regular_form === false ? params.show_regular_form : true,
            allow_create_account:
                params.allow_create_account === false ? params.allow_create_account : true,
            IS_V5: true,
            IS_APP: false,
            IS_WONDERPUSH: false
            // IS_APP: IS_APP,
            // IS_WONDERPUSH: IS_WONDERPUSH,
        };
    }, [params]);

    const setConfig = (config) => {
        console.log('config', config);
        setParams((prev) => {
            return {
                ...prev,
                ...params,
                ...config
            };
        });
    };

    useEffect(() => {
        i18n.changeLanguage(locale);
        dayjs.locale(locale);
    }, [locale]);

    useEffect(() => {
        if (config?.language) {
            setLocale(config.language);
        }

        if (config?.timezone) {
            dayjs.tz.setDefault(config.timezone);
        }
    }, [config]);

    useEffect(() => {
        getParams(process.env.REACT_APP_NAME).then((params) => setParams(params));
    }, []);

    if (!config) {
        return;
    }

    const isExternalDependencyRestricted = (name) => {
        return (
            config.restrictedExternalDependencies &&
            config.restrictedExternalDependencies.length > 0 &&
            config.restrictedExternalDependencies.indexOf(name) !== -1
        );
    };

    return (
        <ConfigContext.Provider
            value={{ config, setConfig, locale, setLocale, isExternalDependencyRestricted }}>
            <AntdConfigProvider locale={localeFR}>{children}</AntdConfigProvider>
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);

export default ConfigProvider;
