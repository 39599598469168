import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { remove, indexOf } from 'lodash-es';
import linkifyHtml from 'linkify-html';
import clip from 'text-clipper';

import Avatar from 'components/Avatar/Avatar';
import Achievement from 'components/Achievement/Achievement';
import Username from 'components/Username/Username';
import Comment from 'components/Comment/Comment';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Link from 'components/Link/Link';
import RelativeTime from 'components/RelativeTime/RelativeTime';
import { useAuth } from 'providers/AuthContext';
import useRight from 'hooks/useRight';
import useUser from 'hooks/useUser';
import { useConfig } from 'providers/ConfigProvider';
import { useDevice } from 'providers/DeviceContext';
import { useSocket } from 'providers/SocketContext';
import useForbiddenWords from 'hooks/useForbiddenWords';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import { useAlert } from 'providers/AlertContext';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import useDate from 'hooks/useDate';
import useAttachment from 'hooks/useAttachment';
import useBlog from 'hooks/useBlog';
import useLikeService from 'api/useLikeService';
import { isAllImagesAttachment } from 'helpers/attachment/';
import useCommentService from 'api/useCommentService';
import useEmoji from 'hooks/useEmoji';
import { useShowLikerModal } from '../../../pages/Auth/providers/ShowLikerModalProvider';
import dayjs from 'dayjs';
import useUtils from 'hooks/useUtils';
import { isViewableFile } from 'helpers/attachment';

const DOMPurify = require('dompurify')(window);

const CommentRender = (props) => {
    const { user } = useAuth();
    const { isInternal, hasRole } = useRight();
    const { goToProfileLink } = useUser();
    const { config } = useConfig();
    const { device } = useDevice();
    const socket = useSocket();
    const { hasMatches, getMatches } = useForbiddenWords();
    const { t } = useTranslation();
    const router = useRouter();
    const { showToast, showActionSheet, showConfirm } = useAlert();
    const { format: dateFormat } = useDate();
    const { forgeAttachmentUrl, download, getVideoFiles, getVideoState, getVideoType } =
        useAttachment();
    const { isBlogState } = useBlog();
    const { attachmentIcon } = useAttachmentIcon();
    const { addCommentLike, deleteLike } = useLikeService();
    const { stickyComment, reportComment, deleteComment, publishComment } = useCommentService();
    const { emoji } = useEmoji();
    const UsersLike = useShowLikerModal();

    const [comment, setComment] = useState(props.comment);
    const [showMore, setShowMore] = useState(false);
    const [hasShowMore, setHasShowMore] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        processHasReadMore();
    }, []);

    useEffect(() => {
        if (comment.id && socket) {
            const addLikeListener = (data) => {
                if (data.comment.id === comment.id && user.id !== data.like.user.id) {
                    //Posts.resetCache();
                    setComment((prevState) => ({
                        ...prevState,
                        likes: [...prevState.likes, data.like]
                    }));
                } else {
                    //Posts.resetCache();
                }
            };

            const deleteLikeListener = (data) => {
                if (data.comment.id === comment.id && user.id !== data.dislike_user_id) {
                    //Posts.resetCache();
                    setComment((prevState) => ({
                        ...prevState,
                        likes: prevState.likes.filter(
                            (like) => like.user.id !== data.dislike_user_id
                        )
                    }));
                } else {
                    //Posts.resetCache();
                }
            };

            socket.on(`add_comment_like_${comment.id}`, addLikeListener, true);
            socket.on(`delete_comment_like_${comment.id}`, deleteLikeListener, true);

            return () => {
                if (comment.id) {
                    socket.removeAllListeners(`add_comment_like_${comment.id}`);
                    socket.removeAllListeners(`delete_comment_like_${comment.id}`);
                }
            };
        }
    }, [comment.id, socket]);

    const processHasReadMore = () => {
        if (!hasRole('ENABLE_READ_MORE_COMMENT')) {
            return;
        }

        let content = emoji(
            linkifyHtml(comment.content, {
                defaultProtocol: 'https',
                nl2br: true
            })
        );

        let clippedContent = getClippedContent(content);

        setHasShowMore(content !== clippedContent);
    };

    const getClippedContent = (content) => {
        return clip(content, 280, { html: true, maxLines: 15 });
    };

    const isNew = () => {
        return indexOf(user.unread_comments, parseInt(comment.id)) !== -1;
    };

    const isSticky = () => {
        return comment.sticky;
    };

    const showActionButton = () => {
        let commentObj = comment;

        if (props.object.type === 'slot') {
            return false;
        }

        return (
            user.id === commentObj.user.id ||
            (props.object.type === 'post'
                ? props.object.workspace.owner && user.id === props.object.workspace.owner.id
                : false) ||
            !hasRole('ROLE_INTERNAL') ||
            hasRole('ROLE_ADMIN')
        );
    };

    const showCommentAction = () => {
        let commentObj = comment;

        let buttons = [];

        if (
            (user.id === commentObj.user.id && props.object.post_status === undefined) ||
            (user.id === commentObj.user.id &&
                props.object.post_status !== undefined &&
                !props.object.post_status.final)
        ) {
            buttons.push({
                label: t('EDIT_COMMENT'),
                callback: () => {
                    setComment((prevState) => ({
                        ...prevState,
                        editing: !prevState.editing
                    }));
                }
            });
        }

        if (
            (!hasRole('ROLE_INTERNAL') || hasRole('HAS_REPORT_FOR_ALL_USERS')) &&
            user.id !== commentObj.user.id
        ) {
            let title = t('COMMENT_REPORT_CONFIRM_TITLE');
            let text = t('COMMENT_REPORT_CONFIRM_TEXT');
            let buttonText = t('REPORT');
            let label = t('POST_REPORT_COMMENT');

            if (isCommentReported(commentObj)) {
                title = t('COMMENT_UNREPORT_CONFIRM_TITLE');
                text = t('COMMENT_UNREPORT_CONFIRM_TEXT');
                buttonText = t('UNREPORT');
                label = t('POST_UNREPORT_COMMENT');
            }

            buttons.push({
                label,
                callback: () => {
                    reportCommentHandler(commentObj, title, text, buttonText);
                }
            });
        }

        if (
            !isBlogState() &&
            (hasRole('ROLE_ADMIN') ||
                (props.object.type === 'post'
                    ? props.object.workspace.owner && user.id === props.object.workspace.owner.id
                    : false) ||
                (props.object.type === 'post'
                    ? hasRole(`${props.object.workspace.id}_ADMIN`)
                    : false))
        ) {
            buttons.push({
                label: commentObj.sticky ? t('UNSTICK_COMMENT') : t('STICKY_COMMENT'),
                callback: () => {
                    stickyCommentHanlder(commentObj);
                }
            });
        }

        if (
            (user.id === commentObj.user.id ||
                (props.object.type === 'post'
                    ? props.object.workspace.owner && user.id === props.object.workspace.owner.id
                    : false) ||
                (objectIsCampaign() && props.object.owner.id === user.id) ||
                hasRole('ROLE_ADMIN')) &&
            (props.object.post_status === undefined ||
                (props.object.post_status !== undefined && !props.object.post_status.final))
        ) {
            buttons.push({
                label: t('DELETE_COMMENT'),
                classes: ['red'],
                callback: () => {
                    removeComment(commentObj);
                }
            });
        }

        showActionSheet({ buttons });
    };

    const isCommentReported = (comment) => {
        if (!comment.reports || comment.reports.length === 0) {
            return false;
        }

        return comment.reports.some((value) => value.user.id === user.id);
    };

    const stickyCommentHanlder = (comment) => {
        stickyComment(comment.id)
            .then(() => {
                setComment((prevState) => ({
                    ...prevState,
                    sticky: !comment.sticky
                }));
                props.stickyCallback(comment);
            })
            .catch(() => {
                showToast({
                    text: t('COMMENT_REPORT_FAIL'),
                    duration: 1500
                });
            });
    };

    const removeComment = (comment) => {
        showConfirm({
            title: t('COMMENT_DELETE_CONFIRM_TITLE'),
            text: t('COMMENT_DELETE_CONFIRM_TEXT'),
            button: {
                label: t('DELETE'),
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                deleteComment(comment)
                    .then(() => {
                        props.deleteCallback(comment);
                        showToast({
                            text: t('COMMENT_DELETE_DONE'),
                            duration: 1500
                        });
                    })
                    .catch(() => {
                        showToast({
                            text: t('COMMENT_DELETE_FAIL'),
                            duration: 1500
                        });
                    });
            })
            .catch(() => {});
    };

    const reportCommentHandler = (comment, title, text, buttonText) => {
        showConfirm({
            title,
            text,
            button: {
                label: buttonText,
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                reportComment(comment.id)
                    .then((report) => {
                        showToast({
                            text: t('POST_REPORT_DONE'),
                            duration: 1500
                        });

                        setComment((prevState) => {
                            let reports = prevState.reports;
                            if (!report.removed) {
                                reports.push(report);
                            } else {
                                reports = reports.filter((item) => report.id !== item.removed);
                            }

                            return {
                                ...prevState,
                                reports
                            };
                        });
                    })
                    .catch(() => {
                        showToast({
                            text: t('COMMENT_REPORT_FAIL'),
                            duration: 1500
                        });
                    });
            })
            .catch(() => {});
    };

    const showLikers = () => {
        if (hasRole('ROLE_HIDE_LIKE_LIST_USERS')) {
            return;
        }

        UsersLike.createPanel({
            id: comment.id,
            type: 'like_comment'
        });
    };

    const like = () => {
        if (!isBusy) {
            setIsBusy(true);

            const action = comment.alreadyLiked ? 'delete' : 'addCommentLike';
            const actions = {
                delete: deleteLike,
                addCommentLike: addCommentLike
            };

            actions[action](comment.alreadyLiked ? comment.alreadyLiked : comment)
                .then((like) => {
                    const likes = comment.likes ? [...comment.likes] : [];

                    if (like.id) {
                        likes.push(like);
                    } else {
                        remove(likes, (like) => like.user.id === user.id);
                    }

                    setComment((prevState) => ({
                        ...prevState,
                        likes,
                        alreadyLiked: like ? like.id : null
                    }));
                })
                .finally(() => {
                    setIsBusy(false);
                });
        }
    };

    const objectIsCampaign = () => {
        return ['social', 'recruiting', 'product', 'survey'].includes(props.object.type);
    };

    const commentSubmitCallback = (updatedComment) => {
        setComment((prevState) => ({
            ...prevState,
            ...updatedComment,
            editing: false
        }));
    };

    const formatContent = () => {
        let content = emoji(
            linkifyHtml(comment.content, {
                defaultProtocol: 'https',
                nl2br: true
            })
        );

        if (hasShowMore && !showMore) {
            return getClippedContent(content);
        }

        return content;
    };

    const isPostStatus = () => {
        return comment.post_status || isDeletedPostStatus();
    };

    const isDeletedPostStatus = () => {
        return comment.content === 'GUY_HAS_BEEN_UPDATED_POST_STATUS' && !comment.post_status;
    };

    const hasAttachment = () => {
        return comment.attachments && comment.attachments.length > 0;
    };

    const hasSingleAttachment = () => {
        return comment.attachments && comment.attachments.length === 1;
    };

    const isImage = (attachment) => {
        return attachment.type.includes('image');
    };

    const hasLink = () => {
        return !!comment.link;
    };

    const getAttachmentUrl = (attachment, forceToken) => {
        return forgeAttachmentUrl(attachment, false, null, forceToken);
    };

    const isVideo = (attachment) => {
        return attachment.type.includes('video');
    };

    const isAudio = (attachment) => {
        return attachment.type.includes('audio');
    };

    const downloadAttachment = (attachment) => {
        download(getAttachmentUrl(attachment), props.object.security_level, attachment);
    };

    const allowViewFile = (type) => {
        if (props.object.security_level) {
            return props.object.security_level === 0 && isViewableFile(type);
        }
        return isViewableFile(type);
    };

    const showCarousel = (attachments, index) => {
        /*if (props.inCarousel) {
            Carousel.images = attachments;
            Carousel.index = index;
        } else {
            Carousel.initCarouselState();
            Carousel.images = attachments;
            Carousel.index = index;
        }
        Carousel.show();*/
    };

    const reviewGoToSource = () => {
        if (props.object.workspace) {
            router.stateService.go(
                'auth.posts.post',
                {
                    category: props.object.workspace.category.id,
                    workspace: props.object.workspace.id,
                    post: props.object.id
                },
                { reload: true, inherit: false }
            );
        } else {
            router.stateService.go(
                'auth.campaigns.campaign',
                {
                    type: props.object.type,
                    id: props.object.id
                },
                { reload: true, inherit: false }
            );
        }
    };

    const reviewDeleteComment = () => {
        showConfirm({
            title: t('COMMENT_DELETE_CONFIRM_TITLE'),
            text: t('COMMENT_DELETE_CONFIRM_TEXT'),
            button: {
                label: t('COMMENT_DELETE_CONFIRM_YES'),
                classes: ['red', 'bold']
            }
        }).then(() => {
            deleteComment(comment)
                .then(() => {
                    showToast({
                        text: t('COMMENT_DELETE_DONE'),
                        duration: 1500
                    });
                    //Posts.resetCache();
                    router.stateService.reload();
                })
                .catch(() => {
                    showToast({
                        text: t('COMMENT_DELETE_FAIL'),
                        duration: 1500
                    });
                });
        });
    };

    const reviewPublishComment = () => {
        publishComment(comment.id)
            .then(() => {
                showToast({
                    text: t('COMMENT_ADDED'),
                    duration: 1500
                });
                //Posts.resetCache();
                router.stateService.reload();
            })
            .catch(() => {
                showToast({
                    text: t('Error while publishing the comment'),
                    duration: 1500
                });
            });
    };

    const goToUser = () => {
        goToProfileLink(comment.user);
    };

    const toggleReadMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className={`comment-render ${props.className}`}>
            {!isPostStatus() ? (
                <div className={classNames('content', { 'has-metadata': isNew() || isSticky() })}>
                    {(isNew() || isSticky()) && (
                        <div className="metadata">
                            {isNew() && <div className="new">{t('NEW')}</div>}
                            {isSticky() && <div className="sticky">{t('STICKY')}</div>}
                        </div>
                    )}
                    {showActionButton() && (
                        <button className="action-button" onClick={showCommentAction}>
                            <i className="icon-navigation-menu-horizontal" />
                        </button>
                    )}
                    <div
                        className={classNames('avatar', {
                            'cursor pointer': !hasRole('ROLE_HIDE_LIST_USERS')
                        })}
                        onClick={goToUser}>
                        <Avatar user={comment.user} hideBadge={true} />
                    </div>
                    <div className="body">
                        <div className="meta">
                            {comment.user.user_achievement && (
                                <div className="achievement">
                                    <div className="inline">
                                        <div className="icon">
                                            <Achievement
                                                item={comment.user.user_achievement.achievement}
                                                showPopover={true}
                                            />
                                        </div>
                                        <div className="name">
                                            {comment.user.user_achievement.achievement.name}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                className={classNames('username', {
                                    'cursor pointer': !hasRole('ROLE_HIDE_LIST_USERS')
                                })}
                                onClick={goToUser}>
                                <Username user={comment.user} />
                            </div>
                        </div>
                        {!comment.editing ? (
                            <>
                                {!isCommentReported(comment) ? (
                                    <div className="text">
                                        <div
                                            className="content"
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(formatContent(true))
                                            }}
                                        />
                                        {hasShowMore && (
                                            <div className="action">
                                                <button onClick={toggleReadMore}>
                                                    {showMore ? (
                                                        <span className="show-less">
                                                            {t('FOLDERS_VIEW_LESS')}{' '}
                                                            <i className="icon-arrow-left" />
                                                        </span>
                                                    ) : (
                                                        <span className="show-more">
                                                            {t('TA_POST_READ_MORE')}{' '}
                                                            <i className="icon-arrow-left" />
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="text">{t('POST_COMMENT_REPORTED')}</div>
                                )}
                                {hasRole('HAS_COMMENT_LIKES') &&
                                    !hasRole('ROLE_HIDE_LIKE_LIST_USERS') &&
                                    comment.likers && (
                                        <div
                                            className={classNames('tooltip', {
                                                hover: comment.likers.length > 0
                                            })}>
                                            <div className="tooltip-content">
                                                {comment.likers.map((liker, index) => (
                                                    <div className="liker" key={index}>
                                                        {liker}
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                <div className="date">
                                    {hasRole('HAS_COMMENT_LIKES') &&
                                        props.object.type !== 'slot' && (
                                            <span
                                                className={classNames('like', {
                                                    liked: comment.alreadyLiked
                                                })}>
                                                {comment.likes && comment.likes.length > 0 && (
                                                    <span onClick={showLikers}>
                                                        ({comment.likes.length})&nbsp;
                                                    </span>
                                                )}
                                                <button onClick={like}>{t('POST_LIKE')}</button>
                                                &nbsp;•&nbsp;
                                            </span>
                                        )}
                                    <RelativeTime date={comment.updated_at} />
                                    {comment.created_at !== comment.updated_at && (
                                        <span> ({t('COMMENT_MODIFIED')})</span>
                                    )}
                                </div>
                                {hasAttachment() && (
                                    <div className="attachments">
                                        {isAllImagesAttachment(comment.attachments) ? (
                                            <div className="images">
                                                {comment.attachments.map((attachment, index) => (
                                                    <div className="attachment" key={index}>
                                                        <div className="image">
                                                            <img
                                                                onClick={() =>
                                                                    showCarousel(
                                                                        comment.attachments,
                                                                        index
                                                                    )
                                                                }
                                                                src={getAttachmentUrl(attachment)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="files">
                                                {comment.attachments.map((attachment, index) => (
                                                    <div className="file" key={index}>
                                                        {!hasSingleAttachment() ||
                                                        (hasSingleAttachment() &&
                                                            !isVideo(attachment) &&
                                                            !isAudio(attachment)) ? (
                                                            <div className="is-mixed">
                                                                <div className="mixed">
                                                                    <div className="icon">
                                                                        <i
                                                                            className={attachmentIcon(
                                                                                attachment,
                                                                                'class'
                                                                            )}
                                                                            style={{
                                                                                color: attachmentIcon(
                                                                                    attachment,
                                                                                    'color'
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="meta">
                                                                        <button
                                                                            className="filename"
                                                                            onClick={() =>
                                                                                downloadAttachment(
                                                                                    attachment
                                                                                )
                                                                            }>
                                                                            {attachment.name}
                                                                        </button>
                                                                        <div className="owner">
                                                                            <Username
                                                                                user={comment.user}
                                                                            />
                                                                        </div>
                                                                        <div className="date">
                                                                            {dateFormat(
                                                                                attachment.created_at
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="actions">
                                                                        {hasRole('SHOW_VIEWER') &&
                                                                            allowViewFile(
                                                                                attachment.type
                                                                            ) && (
                                                                                <button
                                                                                    type="button"
                                                                                    className="action"
                                                                                    onClick={() => {
                                                                                        //PreviewDocument.show({url: getAttachmentUrl(attachment, true)})
                                                                                    }}>
                                                                                    <i
                                                                                        className="icon-search"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        {isImage(attachment) && (
                                                                            <button
                                                                                type="button"
                                                                                className="action"
                                                                                onClick={() =>
                                                                                    showCarousel(
                                                                                        [
                                                                                            attachment
                                                                                        ],
                                                                                        0
                                                                                    )
                                                                                }>
                                                                                <i
                                                                                    className="icon-view-1"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        )}
                                                                        <button
                                                                            type="button"
                                                                            className="action"
                                                                            onClick={() =>
                                                                                downloadAttachment(
                                                                                    attachment
                                                                                )
                                                                            }
                                                                            title={t(
                                                                                'POST_DOWNLOAD'
                                                                            )}>
                                                                            <i
                                                                                className="icon-download-bottom"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="is-video">
                                                                <div className="video">
                                                                    {isVideo(attachment) ? (
                                                                        <VideoPlayer
                                                                            src={getAttachmentUrl(
                                                                                attachment
                                                                            )}
                                                                            type={getVideoType(
                                                                                attachment.type
                                                                            )}
                                                                            files={getVideoFiles(
                                                                                attachment
                                                                            )}
                                                                            state={getVideoState(
                                                                                attachment
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        <VideoPlayer
                                                                            src={getAttachmentUrl(
                                                                                attachment
                                                                            )}
                                                                            type={attachment.type}
                                                                            isAudio={true}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {hasLink() && (
                                    <div className="link">
                                        <Link item={comment.link} user={comment.user} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <Comment
                                {...(props.object.type === 'post'
                                    ? { postItem: props.object }
                                    : { campaignItem: props.object })}
                                commentItem={comment}
                                submitCallback={commentSubmitCallback}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div className="content status">
                    {isDeletedPostStatus() ? (
                        <span>
                            {t(comment.content, {
                                firstname: comment.user.first_name,
                                lastname: isInternal()
                                    ? comment.user.last_name
                                    : `${comment.user.last_name.charAt(0)}.`,
                                status: t('COMMENT_DELETED_POST_STATUS'),
                                date: dayjs(comment.updated_at).format('LLL')
                            })}
                        </span>
                    ) : (
                        <span>
                            {t(comment.content, {
                                firstname: comment.user.first_name,
                                lastname: isInternal()
                                    ? comment.user.last_name
                                    : `${comment.user.last_name.charAt(0)}.`,
                                status: comment.post_status.label.toUpperCase(),
                                date: dayjs(comment.updated_at).format('LLL')
                            })}
                        </span>
                    )}
                </div>
            )}
            {comment.waiting_for_review && (
                <>
                    {hasMatches(comment.content) && hasMatches(comment.content).length > 0 && (
                        <div className="forbidden-words">
                            <span className="title">{t('FORBIDDEN_WORDS_LABEL')} :</span>{' '}
                            {getMatches(comment.content).join(', ')}
                        </div>
                    )}
                    <div className="review-source" onClick={reviewGoToSource}>
                        {t('REVIEW_COMMENT_SEE_SOURCE_OBJECT')}
                    </div>
                    <div className="review">
                        <button className="button small rounded grey" onClick={reviewDeleteComment}>
                            {t('DELETE')}
                        </button>
                        <button
                            className="button small rounded primary-color"
                            onClick={reviewPublishComment}>
                            {t('PUBLISH')}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CommentRender;
