import { RichText } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';

const TitleOptions = ({ input }) => {
    const { t } = useTranslation();

    return (
        <>
            <RichText
                label={t('WORKSPACE_CUSTOM_FORM_FIELD_TITLE')}
                name={`workspace_form_inputs[${input.uniqueId}].label`}
                shouldUnregister={false}
            />
        </>
    );
};

export default TitleOptions;
