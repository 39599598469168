import { useTranslation } from 'react-i18next';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import Shared from './shared';
import { useRouter } from '@uirouter/react';
import { useMemo } from 'react';

const Recruiting = ({ campaign }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        isRunning,
        isDraft,
        isClosed,
        forgeDownloadCSVUrl,
        closeCampaign,
        cloneCampaign,
        togglePreview,
        deleteCampaign,
        changeToDraft,
        getTotalClicks
    } = useCampaignsCustomRender(campaign);

    const totalShares = () => {};

    const ROI = useMemo(() => {
        return 0;
    }, [campaign]);

    return (
        <Shared campaign={campaign}>
            <div className={`informations ${campaign.type}`}>
                <div className="details">
                    <div className="stats">
                        {campaign.reward > 0 && (
                            <>
                                <div className="stat application">
                                    <div className="icon">
                                        <i className="fa fa-money" aria-hidden="true"></i>
                                    </div>
                                    <div className="text">
                                        {campaign.total_rewards} {t('TA_CAMPAIGN_POINTS_GIVEN')}
                                    </div>
                                </div>
                                <div className="stat application" ng-click="$ctrl.toggleSharers()">
                                    <div className="icon">
                                        <i className="fa fa-money" aria-hidden="true"></i>
                                    </div>
                                    <div className="text">
                                        {campaign.total_share_rewards}{' '}
                                        {t('TA_CAMPAIGN_POINTS_GIVEN_SHARE')}
                                    </div>
                                </div>

                                <div className="stat application" ng-click="$ctrl.toggleSharers()">
                                    <div className="icon">
                                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                                    </div>
                                    <div className="text">
                                        {totalShares()} {t('TA_CAMPAIGN_SOCIAL_SHARES')}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="stat application" ng-click="$ctrl.goToActivities()">
                            <div className="icon">
                                <i className="fa fa-share-alt" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                {campaign.applications.length}{' '}
                                {t('TA_CAMPAIGN_RECRUITING_TOTAL_SUBMITS')} (
                                <span>
                                    {t('DIRECT_APPLICATIONS_COUNT', {
                                        count: campaign.total_direct_cooptation
                                    })}
                                </span>
                                )
                            </div>
                        </div>

                        <div
                            className="stat application"
                            ng-click="$ctrl.toggleRecruitingSharers()">
                            <div className="icon">
                                <i className="fa fa-share-alt" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                <span>
                                    {t('TA_CAMPAIGN_RECRUITING_SHARING_COOPTATIONS', {
                                        count: campaign.direct_cooptations_count
                                    })}
                                </span>
                            </div>
                        </div>

                        <div className="stat application" ng-click="$ctrl.toggleSharers()">
                            <div className="icon">
                                <i className="fa fa-mouse-pointer" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                {getTotalClicks()} {t('TA_CAMPAIGN_RECRUITING_CLICKS')}
                            </div>
                        </div>

                        <div className="stat not-pointer">
                            <div className="icon">
                                <i className="fa fa-money" aria-hidden="true"></i>
                            </div>
                            <div className="text">ROI : {ROI}</div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    {campaign.reward > 0 && (
                        <div className="reward">
                            <div className="unique">
                                {campaign.reward} {t('POINTS')}
                            </div>
                        </div>
                    )}

                    <div className="buttons">
                        {campaign.applications.length > 0 && (
                            <a className="button grey" target="_blank" href={forgeDownloadCSVUrl()}>
                                {t('TA_CAMPAIGN_EXPORT')}
                            </a>
                        )}
                        {(isDraft || isRunning) && (
                            <>
                                <button className="button grey" onClick={closeCampaign}>
                                    {t('TA_CAMPAIGN_CLOSE')}
                                </button>
                                <a
                                    className="button grey"
                                    href={router.stateService.href(
                                        'auth.dashboard.campaigns.create',
                                        {
                                            type: campaign.type,
                                            id: campaign.id
                                        }
                                    )}>
                                    {t('TA_CAMPAIGN_EDIT')}
                                </a>
                            </>
                        )}

                        <button className="button grey" onClick={cloneCampaign}>
                            {t('TA_CAMPAIGN_DUPLICATE')}
                        </button>
                        {isClosed && (
                            <button className="button grey" onClick={changeToDraft}>
                                {t('TA_CAMPAIGN_MOVE_DRAFT')}
                            </button>
                        )}
                        <button
                            className="button grey"
                            ng-click="$ctrl.togglePreview($ctrl.campaign)">
                            {t('TA_CAMPAIGN_PREVIEW')}
                        </button>
                        {!isRunning && (
                            <button className="button red" onClick={deleteCampaign}>
                                {t('TA_CAMPAIGN_DELETE')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Shared>
    );
};

export default Recruiting;
