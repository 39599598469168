import React from 'react';
import { Select as AntdSelect } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

function Select({ name, value, options = [], ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field }) => {
                return (
                    <>
                        {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                        <AntdSelect
                            style={{ width: '100%' }}
                            options={options}
                            onChange={(value) => {
                                field.onChange(value);
                            }}
                            value={field.value}
                            onBlur={field.onBlur}
                            {...props}
                        />
                    </>
                );
            }}
        />
    );
}

export default Select;
