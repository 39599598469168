import React, { createContext, useContext, useEffect, useState } from 'react';
import { useConfig } from './ConfigProvider';
import { useHttp } from 'api/useHttp';

const InitContext = createContext();

const InitProvider = ({ children }) => {
    const axios = useHttp();
    const { setConfig } = useConfig();

    useEffect(() => {
        const promises = [
            axios.get('/configs').then((response) => response.data),
            axios.get('/text_configs').then((response) => response.data),
            axios.get('/employee/configs').then((response) => response.data),
            axios.get('/sso_configs').then((response) => response.data)
        ];

        Promise.all(promises).then((values) => {
            setConfig({
                ...values[0].reduce((acc, item) => {
                    acc[item.name] = item.value;
                    return acc;
                }, {}),
                ...values[1].reduce((acc, item) => {
                    acc[item.name] = item.value;
                    return acc;
                }, {}),
                ...{ employee_configs: values[2] },
                ...values[3]
            });

            setLoaded(true);
        });
    }, []);

    const [loaded, setLoaded] = useState(false);

    return <InitContext.Provider value={{ loaded }}>{loaded && children}</InitContext.Provider>;
};

export const useInit = () => useContext(InitContext);

export default InitProvider;
