const VIDEOS_EXTENSION = ['.mp4', '.ogg', '.webm'];

export default function isVideoLink(url) {
    if (!url) {
        return false;
    }

    url = url.toLowerCase();

    return VIDEOS_EXTENSION.some((ext) => {
        return url.indexOf(ext) !== -1;
    });
}
