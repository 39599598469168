import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';

const notFilterableInputs = ['comments', 'likes', 'views', 'text'];
const dates = [
    {
        value: 'today',
        label: "Aujourd'hui"
    },
    {
        value: 'yesterday',
        label: 'Hier'
    },
    {
        value: '7d',
        label: 'Une semaine'
    },
    {
        value: '15d',
        label: '15 jours'
    },
    {
        value: '1m',
        label: 'Depuis 1 mois'
    },
    {
        value: '3m',
        label: 'Depuis 3 mois'
    },
    {
        value: '6m',
        label: 'Depuis 6 mois'
    },
    {
        value: '12m',
        label: 'Depuis 1 an'
    },
    {
        value: 'custom',
        label: 'Dates personnalisées'
    }
];

const useTableComponent = (workspace, initialFilters) => {
    const getInput = useCallback(
        (id) => {
            return workspace.workspace_form.workspace_form_inputs.find((input) => {
                return input.id === id;
            });
        },
        [workspace]
    );

    const getFormInputType = (id) => {
        let inputForm = getInput(id);

        return inputForm ? inputForm.type : false;
    };

    const getInputType = (input) => {
        let type = input.type;
        if (/^input:\d+/.test(input.type)) {
            type = getFormInputType(parseInt(input.type.replace('input:', '')));
        }

        return type;
    };

    const configs = useMemo(() => {
        return workspace?.table_configs.map((config) => {
            let filter = initialFilters?.find((filter) => {
                return config.type === filter.key;
            });
            if (filter) {
                if (
                    (getInputType(config) === 'created_at' ||
                        getInputType(config) === 'update_at') &&
                    filter.value === 'custom'
                ) {
                    return {
                        ...config,
                        ...filter,
                        startDatePicker: dayjs(filter.startDate),
                        endDatePicker: dayjs(filter.endDate)
                    };
                }
                return {
                    ...config,
                    ...filter
                };
            }
            return { ...config };
        });
    }, [workspace, initialFilters]);

    const statuses = useMemo(() => {
        return workspace?.post_status.map((ps) => {
            return {
                value: ps.id,
                label: ps.label
            };
        });
    }, [workspace]);

    const inputValues = useMemo(() => {
        const inputValues = {};
        workspace?.workspace_form?.workspace_form_inputs?.forEach((input) => {
            if (input.values) {
                inputValues[input.id] = orderBy(
                    input.values.map((v) => {
                        return {
                            value: v,
                            label: v
                        };
                    }),
                    ['label'],
                    ['asc']
                );
            }
        });

        return inputValues;
    }, [workspace]);

    const getConfig = useCallback(
        (id) => {
            return configs.find((config) => config.type === `input:${id}`);
        },
        [configs]
    );

    const getFormInputFromConfig = (config) => {
        const id = parseInt(config.type.replace('input:', ''));

        return getInput(id);
    };

    const conditionIsMatched = (field, conditionalValue) => {
        return field.value_condition.split('|').includes(conditionalValue);
    };

    const isConditional = (field) => {
        return !!(field && field.input_condition);
    };

    const conditionIsFullFilled = (field) => {
        if (!isConditional(field)) {
            return true;
        }

        let inputCondition = getInput(field.input_condition.id);
        let inputConditionConfig = getConfig(inputCondition.id);

        if (!inputCondition || !inputConditionConfig) {
            return false;
        }

        if (isConditional(inputCondition)) {
            return (
                conditionIsMatched(field, inputConditionConfig.value) &&
                conditionIsFullFilled(inputCondition)
            );
        }

        return conditionIsMatched(field, inputConditionConfig.value);
    };

    const isConfigFilterable = (config) => {
        let type = config.inputType ? config.inputType : config.type;

        switch (type) {
            case 'author':
            case 'handled_by':
            case 'recipients':
            case 'created_at':
            case 'updated_at':
                return true;
            default:
                if (notFilterableInputs.indexOf(type) !== -1) {
                    return false;
                }

                const input = getFormInputFromConfig(config);

                return !isConditional(input) || conditionIsFullFilled(input);
        }
    };

    const getTemplate = (input) => {
        let type = getInputType(input);

        switch (type) {
            case 'status':
            case 'select':
            case 'radio':
            case 'collection':
                return 'select';
            case 'created_at':
            case 'updated_at':
                return 'date';
            case 'author':
            case 'handled_by':
            case 'recipients':
                return 'autoComplete';
            case 'numeric':
                return 'numeric';
        }
    };

    const getTemplateCeil = (input) => {
        let type = getInputType(input);

        switch (type) {
            case 'status':
                return 'status';
            case 'created_at':
            case 'updated_at':
                return 'date';
            case 'select':
            case 'radio':
            case 'collection':
            case 'recipients':
            case 'text':
            case 'numeric':
            case 'handled_by':
                return 'text';
            case 'views':
                return 'views';
            case 'likes':
                return 'likes';
            case 'comments':
                return 'comments';
            case 'author':
                return 'user';
        }
    };

    const isSelect = (input) => {
        let type = getInputType(input);

        switch (type) {
            case 'status':
            case 'select':
            case 'radio':
            case 'collection':
            case 'created_at':
            case 'updated_at':
                return true;
            case 'author':
            case 'handled_by':
            case 'recipients':
            case 'numeric':
                return false;
        }
    };

    const getSelectValues = useCallback(
        (input) => {
            let type = getInputType(input);

            switch (type) {
                case 'status':
                    return statuses;
                case 'select':
                case 'radio':
                case 'collection':
                    let id = parseInt(input.type.replace('input:', ''));
                    return inputValues[id];
                case 'created_at':
                case 'updated_at':
                    return dates;
                default:
                    return [];
            }
        },
        [inputValues, statuses]
    );

    const isConfigDisplayed = (config) => {
        const { type = null } = config;

        if (!type.includes('input:')) {
            return true;
        }

        const input = getFormInputFromConfig(config);

        return !isConditional(input) || conditionIsFullFilled(input);
    };

    const getNumberCeil = useCallback(() => {
        return workspace?.table_configs.filter((config) => getInputType(config) === 'numeric');
    }, [workspace]);

    return {
        configs,
        isConfigFilterable,
        isSelect,
        inputValues,
        getTemplate,
        getSelectValues,
        getInputType,
        isConfigDisplayed,
        getTemplateCeil,
        getNumberCeil
    };
};

export default useTableComponent;
