import { useHttp } from './useHttp';

const useStatService = () => {
    const axios = useHttp();

    const getUsersRanking = (params) => {
        return axios
            .get('/stats/users/ranking', {
                params
            })
            .then((response) => {
                return response.data;
            });
    };

    return {
        getUsersRanking
    };
};

export default useStatService;
