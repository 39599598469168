import { Autocomplete } from './index';
import React from 'react';
import useUserService from 'api/useUserService';

const UserAutocomplete = ({ ...props }) => {
    const { findUsersByCriteria } = useUserService();

    const fetchUserList = async (username) => {
        return findUsersByCriteria(username).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.name}`,
                    value: r.id
                };
            });
        });
    };

    return <Autocomplete fetchOptions={fetchUserList} {...props} />;
};

export default UserAutocomplete;
