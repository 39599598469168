import React from 'react';

export default (props) => {
    return (
        <svg
            className="svg"
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 87 87"
            style={{ stroke: props.strokeColorHex }}
        >
            <path
                d="m8.5 63.448v-40.378l35-20.184 35 20.184v40.378l-35 20.65-35-20.65z"
                fill={`url(${props.hashUrl})`}
            />

            <defs>
                <linearGradient id={props.hash} x2="0" y2="1">
                    <stop stopColor={props.startColorHex} />
                    <stop offset="1" stopColor={props.stopColorHex} />
                </linearGradient>
            </defs>
        </svg>
    );
};
