import { useHttp } from 'api/useHttp';

const useTranslationService = () => {
    const axios = useHttp();

    const getCustomTranslations = () => {
        return axios.get('/custom_translations').then(({ data }) => {
            return data;
        });
    };

    return {
        getCustomTranslations
    };
};

export default useTranslationService;
