import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useSpringboardService from '../api/useSpringboardService';

const AdminSpringboardContext = createContext();

const AdminSpringboardProvider = ({ children }) => {
    const { getAllSpringboards } = useSpringboardService();
    const { data: springboards, isLoading } = useQuery({
        queryKey: ['springboards'],
        queryFn: () => getAllSpringboards()
    });

    return (
        <AdminSpringboardContext.Provider
            value={{
                springboards,
                isLoading
            }}>
            {children}
        </AdminSpringboardContext.Provider>
    );
};

export const useAdminSpringboardContext = () => useContext(AdminSpringboardContext);
export default AdminSpringboardProvider;
