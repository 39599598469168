import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';
import { useConfig } from 'providers/ConfigProvider';
import { useQuery } from '@tanstack/react-query';
import useHomeScreenService from './api/useHomeScreenService';
import { useModal } from 'providers/ModalContext';
import useUrl from 'hooks/useUrl';
import HomeScreenFormModal from './modals/HomeScreenFormModal';

const HomeScreens = () => {
    const { locale } = useConfig();
    const { getAllHomeScreens } = useHomeScreenService();
    const { openModal } = useModal();
    const { openUrl } = useUrl();

    const downloadStats = (homeScreen) => {
        openUrl(`/api/helpdesk/homescreen/articles/stats/${homeScreen.id}`);
    };

    const COLUMNS = [
        {
            id: 'contents',
            header: 'ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL',
            sortable: true,
            cell: ({ cell }) => cell.getValue()[locale]?.name
        },
        {
            id: 'position',
            header: 'ADMIN_MENU_HELPDESK_COMMON_POSITION_LABEL',
            sortable: true
        },
        {
            id: 'helpdeskArticleRead',
            header: 'ADMIN_MENU_HELPDESK_COMMON_READS_LABEL',
            sortable: true,
            cell: ({ cell }) => cell.getValue().length
        }
    ];

    const { data: homeScreens, isLoading } = useQuery({
        queryKey: ['homeScreens'],
        queryFn: () => getAllHomeScreens()
    });

    return (
        <>
            <HomeScreenFormModal />
            <AdminHeader
                title={'ADMIN_MENU_HELPDESK_COMMON_HOMESCREENS_TITLE_LABEL'}
                modalName={'edit_home_screen'}
                modalButtonTitle={'ADMIN_MENU_HELPDESK_COMMON_ADD_HOMESCREEN_BUTTON_LABEL'}
            />

            <Table
                columns={COLUMNS}
                data={homeScreens}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'ADMIN_MENU_HELPDESK_COMMON_EDIT_BUTTON_LABEL',
                        handler: (homeScreen) => {
                            openModal('add_edit_homescreen', {
                                currentHomeScreen: homeScreen
                            });
                        }
                    },
                    {
                        label: 'ADMIN_MENU_HELPDESK_COMMON_DOWNLOAD_BUTTON_LABEL',
                        handler: (homeScreen) => {
                            downloadStats(homeScreen);
                        }
                    },
                    {
                        label: 'ADMIN_MENU_HELPDESK_COMMON_DELETE_BUTTON_LABEL',
                        danger: true,
                        handler: (row) => {
                            console.log(row);
                        }
                    }
                ]}
            />
        </>
    );
};

export default HomeScreens;
