import { useHttp } from 'api/useHttp';

const useLikeService = () => {
    const axios = useHttp();

    const getLikesByComment = (commentId) => {
        return axios.get(`/likes/comments/${commentId}`).then((response) => response.data);
    };

    const deleteLike = (like) => {
        return axios.delete(`/likes/${like}`);
    };

    const addLike = (obj) => {
        if (obj.type === 'post' || obj.type === undefined) {
            return axios
                .post(`/posts/likes`, {
                    post_id: obj.id
                })
                .then((response) => response.data);
        } else if (obj.type === 'article') {
            return axios
                .post(`/blog-article/likes`, {
                    articleId: obj.id
                })
                .then((response) => response.data);
        } else {
            return axios
                .post(`/campaigns/likes`, {
                    id: obj.id,
                    type: obj.type
                })
                .then((response) => response.data);
        }
    };

    const addCommentLike = (comment) => {
        return axios
            .post(`/comments/post/likes`, {
                comment_id: comment.id
            })
            .then((response) => response.data);
    };

    return {
        getLikesByComment,
        deleteLike,
        addCommentLike,
        addLike
    };
};

export default useLikeService;
