import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRight from '../../../../../hooks/useRight';
import { orderBy } from 'lodash-es';
import { CSS } from '@dnd-kit/utilities';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useModal } from 'providers/ModalContext';

const TreeCategory = ({ category, workspaces, workspace_data, id }) => {
    const { openModal } = useModal();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    const { t } = useTranslation();
    const { hasRole } = useRight();
    const [open, setOpen] = useState(false);

    const filterNotHiddenWorkspaces = (ws) => {
        return ws;
    };

    const getFirstLetter = () => {
        return 'W';
    };

    const orderWorkspaces = (workspaces) => {
        return orderBy(workspaces, ['workspace_data', 'workspace', 'position'], 'asc').map(
            (w, index) => {
                return { ...w, id: index + 1 };
            }
        );
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            workspaces((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    return (
        <div ref={setNodeRef} style={style}>
            {category && (
                <div className="category is-open">
                    <div className="meta">
                        <div className="handle" {...attributes} {...listeners}>
                            <div className="icon">
                                <i className="icon-navigation-menu-horizontal"></i>
                            </div>
                        </div>

                        <div
                            className="name"
                            onClick={() => {
                                setOpen(!open);
                            }}>
                            <div className="text">{category.name}</div>
                            {category.archived_at && (
                                <div className="archived">({t('ARCHIVED')})</div>
                            )}
                            {workspaces.length > 0 && (
                                <div className="workspaces">
                                    {filterNotHiddenWorkspaces(workspaces).length} {t('DATAFEEDS')}
                                </div>
                            )}
                        </div>

                        <div className="actions">
                            <button
                                type="button"
                                onClick={() => {
                                    openModal('admin_category_form', { currentCategory: category });
                                }}>
                                <i className="icon-pencil-1" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-click="deleteCategory(category)"
                                ng-if="!workspaces || workspaces.length === 0">
                                <i className="icon-bin-2" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-disabled="category.archived_at"
                                ng-click="archiveCategory(category)">
                                <i className="icon-door-lock" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    {workspaces && open && (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}>
                            <div className="children" data-ng-model="workspaces">
                                <SortableContext
                                    items={orderWorkspaces(workspaces)}
                                    strategy={verticalListSortingStrategy}>
                                    {orderWorkspaces(workspaces).map((value, index) => {
                                        return (
                                            <TreeCategory
                                                key={`${id}_${value.id}`}
                                                id={`${id}_${value.id}`}
                                                {...value}
                                            />
                                        );
                                    })}
                                </SortableContext>
                            </div>
                        </DndContext>
                    )}
                </div>
            )}

            {((workspace_data && workspace_data.workspace.hidden === false) ||
                (workspace_data &&
                    workspace_data.workspace.hidden === true &&
                    hasRole('ROLE_SUPER_ADMIN'))) && (
                <div className="workspace">
                    <div className="meta">
                        <div className="handle" {...attributes} {...listeners}>
                            <div className="icon">
                                <i className="icon-navigation-menu-horizontal"></i>
                            </div>
                        </div>

                        {!hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') && (
                            <div className="icon">
                                <img src="{ workspaceService.forgeIconUrl(workspace_data.workspace.icon_name) }" />
                            </div>
                        )}

                        {hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') && (
                            <div
                                className="icon"
                                style={{ backgroundColor: workspace_data.workspace.icon_color }}>
                                <div className="letter">
                                    {getFirstLetter(workspace_data.workspace.name)}
                                </div>
                            </div>
                        )}

                        <div className="name">
                            <div>
                                {workspace_data.workspace.name} (ID: {workspace_data.workspace.id})
                            </div>
                            {workspace_data?.workspace.archived_at && (
                                <div className="archived">({t('ARCHIVED')})</div>
                            )}
                            <div className="data">
                                {workspace_data.total_posts} actualité(s) -{' '}
                                {workspace_data.total_comments} commentaire(s) -{' '}
                                {workspace_data.total_likes} like(s) -{' '}
                                {workspace_data.total_reactions} réaction(s)
                            </div>
                        </div>

                        <div className="actions">
                            <button
                                type="button"
                                ng-click="formWorkspace(workspace_data.workspace)">
                                <i className="icon-pencil-1" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-click="duplicateWorkspace(workspace_data.workspace)">
                                <i className="icon-copy-paste" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-click="copyWorkspaceUsers(workspace_data.workspace)">
                                <i className="icon-file-copyright-user" aria-hidden="true"></i>
                            </button>

                            <button type="button" ng-click="editUsers(workspace_data.workspace)">
                                <i
                                    className="icon-human-resources-search-team"
                                    aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-click="deleteWorkspace(workspace_data.workspace)">
                                <i className="icon-bin-2" aria-hidden="true"></i>
                            </button>

                            <button
                                type="button"
                                ng-disabled="workspace_data.workspace.archived_at"
                                ng-click="archiveWorkspace(workspace_data.workspace)">
                                <i className="icon-door-lock" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TreeCategory;
