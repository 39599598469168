import { useState } from 'react';
import { first } from 'lodash-es';

const DEFAULT_PAGES = [
    {
        label: 'Default page'
    }
];

const usePostContentPagination = (post, tabRef) => {
    const [currentPage, setCurrentPage] = useState(1);

    const hasPreviousPage = () => {
        return currentPage !== 1;
    };

    const hasNextPage = () => {
        return currentPage !== getPages().length;
    };

    const goToPreviousPage = () => {
        goToPage(currentPage - 1);
    };

    const goToNextPage = () => {
        goToPage(currentPage + 1);
    };

    const hasPages = () => {
        if (!(post.workspace && post.workspace.workspace_form)) {
            return false;
        }

        let field = first(post.workspace.workspace_form.workspace_form_inputs);

        return field.type === 'page';
    };

    const getPages = () => {
        if (!hasPages()) {
            return DEFAULT_PAGES;
        }

        return post.workspace.workspace_form.workspace_form_inputs.filter((input) => {
            return input.type === 'page';
        });
    };

    const goToPage = (page) => {
        setCurrentPage(page);
        if (!tabRef.current || isScrolledIntoView(tabRef.current)) {
            return;
        }
        tabRef.current.closest('.post_wrapper').scrollIntoView({ behavior: 'smooth' });
    };

    const isScrolledIntoView = (el) => {
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        let elemBottom = rect.bottom;
        let winHeight = window.innerHeight || document.documentElement.clientHeight;
        return elemTop < winHeight && elemBottom >= 0;
    };

    const isCurrentPage = (page) => {
        return currentPage === page;
    };

    return {
        isCurrentPage,
        getPages,
        goToNextPage,
        hasPreviousPage,
        hasNextPage,
        hasPages,
        goToPreviousPage,
        goToPage
    };
};

export default usePostContentPagination;
