import React from 'react';
import { useTranslation } from 'react-i18next';
import useBlog from 'hooks/useBlog';
import useAttachment from 'hooks/useAttachment';

const PostArticle = ({ article }) => {
    const { t } = useTranslation();
    const { getLocalizedArticleProperty, getArticleTypeIcon, readMore } = useBlog();
    const { forgeAttachmentUrl } = useAttachment();

    const translateArticleType = (type) => {
        switch (type) {
            case 'article':
                return t('BLOG_ADMIN_SETTING_ARTICLE_TYPE_DEFAULT_LABEL');
            case 'video':
                return t('BLOG_ADMIN_SETTING_ARTICLE_TYPE_VIDEO_LABEL');
            case 'podcast':
                return t('BLOG_ADMIN_SETTING_ARTICLE_TYPE_PODCAST_LABEL');
            case 'gallery':
                return t('BLOG_ADMIN_SETTING_ARTICLE_TYPE_GALLERY_LABEL');
        }
    };

    const hasThumbnail = (article) => {
        return article.contents.find((content) => {
            return content.thumbnail;
        });
    };

    const getArticleUrl = (article) => {
        return forgeAttachmentUrl(getLocalizedArticleProperty(article, 'thumbnail'));
    };

    return (
        <div className="ta_post_article">
            <div className="ta_post">
                <div className="item">
                    <div className="image">
                        {hasThumbnail(article) ? (
                            <div
                                className={`icon ${article.type === 'video' || article.type === 'podcast' ? 'is-playable' : ''}`}
                            >
                                <div
                                    className="background"
                                    style={{ backgroundImage: `url(${getArticleUrl(article)})` }}
                                ></div>
                            </div>
                        ) : (
                            <div
                                className={`icon no-image ${article.type === 'video' || article.type === 'podcast' ? 'is-playable' : ''}`}
                            >
                                <i className={getArticleTypeIcon(article)} aria-hidden="true"></i>
                            </div>
                        )}
                        {(article.type === 'video' || article.type === 'podcast') && (
                            <span className="play" onClick={() => readMore(article)}>
                                <i className="fa fa-play"></i>
                            </span>
                        )}
                    </div>
                    <div className="content">
                        <div className="type">{translateArticleType(article.type)}</div>
                        <div className="title">{getLocalizedArticleProperty(article, 'title')}</div>
                        <div className="action">
                            <button
                                onClick={() => readMore(article)}
                                className="button small rounded"
                            >
                                {t('POST_ATTACH_BLOG_ARTICLE_READ')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostArticle;
