import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useLevelService from '../../../api/useLevelService';
import useOrganizationalUnitService from '../../Admin/components/OrganizationalUnits/api/useOrganizationalUnitService';

const MetricsContext = createContext();

const MetricsProvider = ({ children }) => {
    console.log("metric provider");

    const { getLevelConfigsByLevelsUsersCounts } = useLevelService();
    const { getAllWithStaff } = useOrganizationalUnitService();

    const { data: levels } = useQuery({
        queryKey: ['levels', 'getLevelConfigsByLevelsUsersCounts'],
        queryFn: () => {
            return getLevelConfigsByLevelsUsersCounts();
        }
    });

    const { data: organizationalUnits } = useQuery({
        queryKey: [`ou`, 'getAllWithStaff'],
        queryFn: () => {
            return getAllWithStaff();
        }
    });

    return (
        <MetricsContext.Provider
            value={{
                organizationalUnits,
                levels
            }}
        >
            {children}
        </MetricsContext.Provider>
    );
};

export const useMetricsContext = () => useContext(MetricsContext);
export default MetricsProvider;
