import Stats from '../Stats';

export default [
    {
        name: 'auth.stats',
        url: 'stats?page&count&sortBy&filterBy&startDate&endDate&byLevels&onlyEmployees',
        component: Stats,
        params: {
            page: {
                type: 'int',
                value: 1
            },
            onlyEmployees: {
                type: 'string',
                value: 'true'
            },
            count: {
                type: 'int',
                value: 25
            },
            filterBy: {
                type: 'string'
            }
        },
        navbar: {
            getIcon: () => {
                return 'icon-graph-stats-circle';
            },
            getLabel: (context) => {
                return context.t('STATS_NAVBAR_LABEL');
            },
            isActive: (context) => {
                return context.isActive(['auth.stats.**']);
            },
            isVisible: (context) => {
                return context.Right.hasRole(['ROLE_ADMIN', 'HAS_STATS']);
            }
        }
    }
];
