import { useHttp } from 'api/useHttp';

const useImportEmployeeService = () => {
    const axios = useHttp();

    const getAllConfigs = () => {
        return axios.get('/import/employee/configs').then((response) => response.data);
    };

    const deleteConfig = (config) => {
        return axios
            .delete('/import/employee/config/' + config.id)
            .then((response) => response.data);
    };

    const saveConfig = (config) => {
        return axios.post('/import/employee/config', { config }).then((response) => response.data);
    };

    const getEmployeesByType = (config, page) => {
        return axios
            .get('/employee/list/' + config.id + '?page=' + page)
            .then((response) => response.data);
    };

    const importEmployee = (file, dryRun, configId, onlyCreate = false) => {
        return axios
            .post(`/imports/employees`, { file, dryRun, configId, onlyCreate })
            .then((response) => response.data);
    };

    return {
        getAllConfigs,
        deleteConfig,
        saveConfig,
        getEmployeesByType,
        importEmployee
    };
};

export default useImportEmployeeService;
