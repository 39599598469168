import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import RegularConnection from './RegularConnection';
import SSO from './SSO';

const SSOConnection = () => {
    const { t } = useTranslation();
    const [showRegularLoginForm, setShowRegularLoginForm] = useState(false);

    return (
        <>
            <SSO />
            <div className="separator">
                <span
                    className="text"
                    onClick={() => {
                        setShowRegularLoginForm(!showRegularLoginForm);
                    }}
                >
                    {t('LOGIN_OR')}{' '}
                    <i
                        className={`fa ${showRegularLoginForm ? 'fa-angle-up' : 'fa-angle-down'}`}
                    ></i>
                </span>
            </div>
            {showRegularLoginForm && (
                <div className="regular spacing">
                    <RegularConnection />
                </div>
            )}
        </>
    );
};
export default SSOConnection;
