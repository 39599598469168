import { useEditorContext } from '../providers/EditorProvider';
import useEditorComponent from '../hooks/useEditorComponent';

const SubscriptionLess = () => {
    const { workspace, isPreview, isReview } = useEditorContext();
    const { isSubscriptionLess, hasCustomFieldWithRecipient } = useEditorComponent(workspace);

    if (!(isSubscriptionLess() && !isReview && !hasCustomFieldWithRecipient() && !isPreview)) {
        return null;
    }

    return (
        <div className="option">
            {/*<div className="subscription_less">
            <div>Destinataire(s): <span ng-if="$ctrl.workspace.is_subscription_less_required">*</span></div>

            <form className="form">
                <ui-select ng-model-options="{ debounce: 0 }" id="users" multiple ng-change="$ctrl.searchUser()"
                           ng-model="$ctrl.form.targetUsers" theme="select2" style="width: 100%;">
                    <ui-select-match ng-attr-placeholder="{{ ::$ctrl.translate('TYPE_TO_SEARCH') }}">
                        <div className="workspace">
                            <div className="meta">
                                <div className="name">
                                    {{$item.name}}
                                </div>
                            </div>
                        </div>
                    </ui-select-match>

                    <ui-select-choices refresh="$ctrl.querySearch($select.search)" repeat="user in $ctrl.selectedUsers"
                                       minimum-input-length="2">
                        <div className="workspace">
                            <div className="desc">
                                <div className="name">{{user.name}}</div>
                            </div>
                        </div>
                    </ui-select-choices>
                </ui-select>
            </form>
        </div>*/}
        </div>
    );
};

export default SubscriptionLess;
