import { useConfig } from 'providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import { defaultTo, isNil, isEmpty } from 'lodash-es';
import useRight from './useRight';
import escapeStringRegexp from 'escape-string-regexp';

const removeAccents = require('remove-accents');

const useForbiddenWords = () => {
    const { config } = useConfig();
    const { isInternal, hasRole } = useRight();
    const { t } = useTranslation();

    const getMatches = (source) => {
        let { content, forbiddenWords } = processText(source);

        return forbiddenWords
            .map((forbiddenWord) => {
                return content.match(new RegExp(`\\b${forbiddenWord}\\b`, 'gi'));
            })
            .filter((item) => item !== null)
            .flat();
    };

    const hasMatches = (source) => {
        if (
            (isInternal() && !hasRole('HAS_WORD_FILTER_FOR_INTERNALS')) ||
            (!isInternal() && !hasRole('HAS_WORD_FILTER_FOR_EXTERNALS'))
        ) {
            return false;
        }

        let { content, forbiddenWords } = processText(source);

        return forbiddenWords.filter((forbiddenWord) => {
            let matches = content.match(new RegExp(`\\b${forbiddenWord}\\b`, 'gi'));

            return matches !== null;
        });
    };

    const processText = (content) => {
        let forbiddenWords = defaultTo(
            config.forbidden_words_for_externals,
            t('ADMIN_FORBIDDEN_WORDS_FOR_EXTERNALS')
        );

        return {
            content: removeAccents(defaultTo(content, '')),
            forbiddenWords: removeAccents(forbiddenWords)
                .split('\n')
                .filter((forbiddenWord) => !isNil(forbiddenWord) && !isEmpty(forbiddenWord))
                .map((forbiddenWord) => escapeStringRegexp(forbiddenWord.trim()))
        };
    };

    return {
        getMatches,
        hasMatches,
        processText
    };
};

export default useForbiddenWords;
