import Blog from '../Blog';
import Articles from '../components/Articles';
import Article from '../components/Article';
import { defaultTo } from 'lodash-es';

const stateParams = { url: 'blog', abstract: true, component: Blog };

const articlesStateParams = {
    url: '?type&category&page',
    params: {
        page: {
            type: 'int',
            value: 1,
            squash: true
        },
        category: {
            type: 'int'
        }
    },
    navbar: {
        getIcon: () => {
            return 'icon-cog';
        },
        getLabel: (context) => {
            return 'Blog';
        },
        getImage: (context) => {
            defaultTo(context.config.blog_logo_url, '/images/logo-blog.png');
        },
        isActive: (context) => {
            return context.isActive(['auth.blog.**']);
        },
        isVisible: (context) => {
            return (
                ((context.Right.hasRole('HAS_BLOG') &&
                    !context.router.stateService.includes('auth.blog.**')) ||
                    context.Right.hasRole('ROLE_ADMIN_BLOG')) &&
                !context.Right.hasRole('BLOG_HIDE_LOGO')
            );
        }
    },
    component: Articles
};

const articleStateParams = {
    url: '/:article/:slug?isPreview',
    params: {
        article: {
            type: 'int'
        },
        slug: {
            type: 'string',
            dynamic: true
        },
        isPreview: {
            type: 'bool',
            value: false,
            squash: true
        }
    },
    component: Article
};

export default [
    { name: 'public.blog', ...stateParams },
    { name: 'auth.blog', ...stateParams },
    { name: 'public.blog.articles', ...articlesStateParams },
    { name: 'auth.blog.articles', ...articlesStateParams },
    { name: 'public.blog.article', ...articleStateParams },
    { name: 'auth.blog.article', ...articleStateParams },
    {
        name: 'auth.blog.search',
        url: '/search?query&category&onlyTitle&exactPhrase&page',
        params: {
            query: {
                type: 'string',
                value: null,
                squash: true
            },
            category: {
                type: 'int',
                value: null,
                squash: true
            },
            onlyTitle: {
                type: 'bool',
                value: false,
                squash: true
            },
            exactPhrase: {
                type: 'bool',
                value: false,
                squash: true
            },
            page: {
                type: 'int',
                value: 1,
                squash: true
            }
        },
        component: Articles
    }
];
