import { useTranslation } from 'react-i18next';
import useViewport from 'hooks/useViewport';
import { createElement, useCallback, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import AutoCompleteFilter from './filters/AutoComplete';
import DateFilter from './filters/Date';
import NumericFilter from './filters/Numeric';
import SelectFilter from './filters/Select';
import { useForm } from 'components/Form/hooks';
import { ModalForm } from 'components/Form/ModalForm';
import useTableComponent from './hooks/useTableComponent';
import { useFeedContext } from '../../../providers/FeedProvider';
import { isObject } from 'lodash-es';
import { useConfig } from 'providers/ConfigProvider';
import useUrl from '../../../../../hooks/useUrl';

const filters = {
    autoComplete: AutoCompleteFilter,
    date: DateFilter,
    numeric: NumericFilter,
    select: SelectFilter
};

const Sidebar = ({ initialFilters = [], postsNumber = 0 }) => {
    const { config } = useConfig();
    const { openUrl } = useUrl();
    const { workspace } = useFeedContext();
    const { params } = useCurrentStateAndParams();
    const { t } = useTranslation();
    const viewport = useViewport();
    const [areFiltersOpen, setAreFiltersOpen] = useState(false);
    const router = useRouter();
    const methods = useForm({
        defaultValues: initialFilters.reduce((acc, obj) => {
            acc[obj.key] = isObject(obj.value) ? obj.value.id : obj.value;
            return acc;
        }, {})
    });
    const { configs, isConfigFilterable, isSelect, getTemplate, getInputType } = useTableComponent(
        workspace,
        initialFilters
    );

    const resetFilters = () => {
        return router.stateService.go('.', { filters: null }, { reload: true });
    };

    const onFinish = (values) => {
        let filters = Object.entries(values)
            .map(([inputType, value]) => {
                let type = getInputType({ type: inputType });
                if (!value && type !== 'numeric') {
                    return null;
                }
                switch (type) {
                    case 'status':
                    case 'select':
                    case 'radio':
                    case 'collection':
                        return `${inputType}=${value}`;
                    case 'created_at':
                    case 'updated_at':
                        if (value === 'custom') {
                            const startDate = values[`${inputType}__startDate`];
                            const endDate = values[`${inputType}__endDate`];
                            return `${inputType}=${value}=${startDate?.format('YYYY-MM-DD 00:00:00')},${endDate?.format('YYYY-MM-DD 23:59:59')}`;
                        }
                        return `${inputType}=${value}`;
                    case 'author':
                    case 'handled_by':
                    case 'recipients':
                        return `${inputType}=${value}`;
                    case 'numeric':
                        const min = values[`${inputType}__min`];
                        const max = values[`${inputType}__max`];
                        if (min || max) {
                            return `${inputType}=${JSON.stringify({
                                min,
                                max
                            })}`;
                        }
                }
            })
            .filter((filter) => {
                return filter;
            });

        const filtersString = encodeURI(filters.join(';'));

        router.stateService.go('.', { filters: filtersString }, { reload: false });
    };

    const downloadTable = useCallback(() => {
        let exportUrl =
            config.staticUrl +
            'download/table/export/' +
            workspace.id +
            '.json?filters=' +
            params.filters;

        openUrl(exportUrl);
    }, [config, workspace]);

    return (
        <div className="table-sidebar-wrapper">
            <div className="content">
                <div className="header">
                    <h2>{t('TABLE_SIDEBAR_TITLE')}</h2>
                    <div className="posts-number">
                        {postsNumber > 0 && (
                            <span>{t('TABLE_SIDEBAR_RESULTS', { posts_number: postsNumber })}</span>
                        )}
                    </div>
                    {viewport.xs && (
                        <button onClick={() => setAreFiltersOpen((prev) => !prev)}>
                            <i
                                className={`fa ${areFiltersOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                        </button>
                    )}
                </div>
                {(areFiltersOpen || !viewport.xs) && (
                    <>
                        <ModalForm
                            onSubmit={methods.handleSubmit(onFinish)}
                            methods={methods}
                            className={'table-form'}>
                            <div className={'form'}>
                                {configs &&
                                    configs.map((input, index) => {
                                        if (!isConfigFilterable(input)) {
                                            return;
                                        }
                                        return (
                                            <div
                                                key={`input_${index}`}
                                                className={`select ${isSelect(input) ? 'has-dropdown' : ''}`}>
                                                {createElement(filters[getTemplate(input)], {
                                                    input
                                                })}
                                            </div>
                                        );
                                    })}
                            </div>

                            <div className="form-buttons">
                                <button type={'submit'}>{t('TABLE_SUBMIT_BUTTON')}</button>{' '}
                                <button onClick={resetFilters} type={'button'}>
                                    {t('TABLE_RESET_BUTTON')}
                                </button>{' '}
                                {params.filters && (
                                    <>
                                        <button type={'button'} onClick={downloadTable}>
                                            {t('TABLE_DOWNLOAD_BUTTON')}
                                        </button>
                                    </>
                                )}
                            </div>
                        </ModalForm>
                    </>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
