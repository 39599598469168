import { useTranslation } from 'react-i18next';
import { useNotificationsContext } from '../../Auth/providers/NotificationsProvider';
import useRight from 'hooks/useRight';
import NotificationStacked from '../../../components/Notifications/NotificationStacked';

const NotificationsPanel = () => {
    const { t } = useTranslation();
    const { mode, notifications } = useNotificationsContext();
    const { hasRole } = useRight();

    return (
        <div className={'notifications'}>
            <div className="header">
                <div className="title">{t('Notifications')}</div>

                <button type="button" ng-click="$ctrl.markAllAsRead()">
                    {t('MARK_ALL_AS_READ')}
                </button>
            </div>

            <div className="body scrollable">
                {mode !== 'stacked' && hasRole('NEW_NOTIFICATIONS_FORMAT') && (
                    <div className="header">
                        <button type="button" className="back" ng-click="$ctrl.stack()">
                            <span ng-include="'/svg/back.svg'"></span>
                        </button>
                    </div>
                )}

                {mode === 'stacked' && <NotificationStacked notifications={notifications} />}
                {/*<ng-include src="$ctrl.getMainTemplate()"></ng-include>*/}
            </div>

            <div className="footer">
                <button
                    type="button"
                    ui-sref="auth.notifications"
                    ui-sref-active="active"
                    ui-sref-opts="{ reload: true, inherit: false }"
                    ng-click="state.callback($ctrl, state)">
                    {t('SEE_ALL_NOTIFICATIONS')}
                </button>
            </div>
        </div>
    );
};

export default NotificationsPanel;
