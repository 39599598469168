import { useTranslation } from 'react-i18next';

const useSpringboardComponent = () => {
    const { t } = useTranslation();

    const getTranslatedPosition = (position) => {
        switch (position) {
            case 1:
                return t('HOMEPAGE_ANCHOR_LEFT');
            case 2:
                return t('HOMEPAGE_ANCHOR_RIGHT');
            default:
                return t('HOMEPAGE_ANCHOR_MOBILE');
        }
    };

    return {
        getTranslatedPosition
    };
};

export default useSpringboardComponent;
