import React, { useEffect, useCallback, useState } from 'react';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { Progress } from '@mantine/core';
import { useJoinDocumentModal } from '../providers/JoinDocumentModalProvider';
import useUploadJoinDocuments from './hooks/useUploadJoinDocuments';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import useUploadService from 'api/useUploadService';
import { useAlert } from 'providers/AlertContext';
import useAttachment from 'hooks/useAttachment';

const JoinDocumentModalContent = () => {
    const { t } = useTranslation();
    const { setModalConfiguration, modalConfiguration } = useJoinDocumentModal();
    const { resolvePromise, rejectPromise } = useJoinDocumentModal();
    const { attachmentIcon } = useAttachmentIcon();
    const { getVideoType } = useAttachment();
    const {
        files,
        progresses,
        uploaded,
        addVideoPoster,
        uploadFiles,
        errors,
        deleteFile,
        editFile,
        editCustomName,
        toggleVideo
    } = useUploadJoinDocuments(`/attachments`);
    const { upload } = useUploadService();
    const { showToast } = useAlert();
    const { closeModal } = useModal();
    const folders = [];

    useEffect(() => {
        if (modalConfiguration.invisible) {
            const fileInput = document.getElementById('file-upload');
            fileInput.click();
        }
    }, [modalConfiguration]);

    useEffect(() => {
        if (files.length > 0 && modalConfiguration && modalConfiguration.invisible) {
            setModalConfiguration((prevState) => {
                return {
                    ...prevState,
                    invisible: false
                };
            });
        }
    }, [files, modalConfiguration]);

    useEffect(() => {
        return () => {
            setModalConfiguration({});
            rejectPromise([]);
        };
    }, []);

    useEffect(() => {
        const handleFocus = () => {
            if (files.length === 0 && modalConfiguration.invisible) {
                closeModal('join_document');
            }
        };

        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [files]);

    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            uploadFiles(event.target.files);
        }
    };

    const send = () => {
        let promiseArray = files.map((file) => {
            return new Promise((resolve, reject) => {
                if (errors[file.name]) {
                    resolve(null);
                }

                if (isFileVideo(file.type)) {
                    if (file.poster === undefined) {
                        toggleVideo(file.name);

                        setTimeout(() => {
                            generatePoster(file)
                                .then((data) => {
                                    file.poster = data.hard_name;
                                    resolve(file);
                                })
                                .catch((e) => {
                                    resolve(null);
                                });
                        }, 100);
                    } else {
                        resolve(file);
                    }
                } else {
                    resolve(file);
                }
            });
        });

        Promise.all(promiseArray)
            .then((files) => {
                let send = { files: files.filter((f) => f) };

                /*if (this.selectedFolder) {
                    send.folder = this.selectedFolder;
                }

                if (modalConfiguration.forceFolder) {
                    this.forcedFolder = this.JoinDocument.folders?.find((folder) => {
                        return folder.id === parseInt(modalConfiguration.forceFolder);
                    });

                    if (this.forcedFolder !== undefined) {
                        send.folder = this.forcedFolder;
                    }
                }*/

                resolvePromise(send);
            })
            .catch((error) => {
                console.error('errrr', error);
            });
    };

    const isImage = (type) => {
        return type.indexOf('image') !== -1;
    };

    const isFileVideo = (type) => {
        return type.indexOf('video') !== -1;
    };

    const generatePoster = async (video) => {
        return new Promise((resolve, reject) => {
            let videoEl = document.getElementById(video.hard_name);

            let canvasEl = document.createElement('canvas');
            let canvasCtx = canvasEl.getContext('2d');

            canvasEl.width = videoEl.videoWidth;
            canvasEl.height = videoEl.videoHeight;

            canvasCtx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);

            let playButton = new Image();
            playButton.src = require('assets/images/play-button.png');

            playButton.onload = async () => {
                let x = canvasEl.width / 2 - playButton.width / 2;
                let y = canvasEl.height / 2 - playButton.height / 2;

                canvasCtx.drawImage(playButton, x, y);

                let imageBinary = atob(canvasEl.toDataURL('image/jpeg').split(',')[1]);
                let imageBinaryLength = imageBinary.length;
                let arrayBinary = new Uint8Array(imageBinaryLength);

                for (let i = 0; i < imageBinaryLength; i++) {
                    arrayBinary[i] = imageBinary.charCodeAt(i);
                }

                const type = 'image/jpeg';

                const imageBlob = new Blob([arrayBinary], {
                    type: type
                });

                const imageFile = new File(
                    [imageBlob],
                    video.hard_name.split('.')[0] + '-poster.jpeg',
                    { type: imageBlob.type }
                );

                const data = await uploadPictureVideo(video, imageFile);
                resolve(data);
            };
        });
    };

    const editPictureVideo = async (file, video) => {
        const imageFile = new File(
            [file],
            video.hard_name.split('.')[0] + '-poster.' + file.type.split('/')[1],
            { type: file.type }
        );

        return await uploadPictureVideo(video, imageFile);
    };

    const uploadPictureVideo = async (video, picture) => {
        return upload({
            url: `/attachments`,
            data: {
                file: picture
            }
        }).then((response) => {
            showToast({
                text: t('TA_CREATE_CAMPAIGN_POSTER_CONFIRM_CREATION'),
                duration: 1500
            });

            toggleVideo(video.name);
            addVideoPoster(video.name, response.data.hard_name);
            return response.data;
        });
    };

    return (
        <div className="join-document" aria-label="Join Document">
            <div className="main">
                {(!files || (files && files.length === 0)) && (
                    <div
                        className="no-files"
                        onClick={() => document.getElementById('file-upload').click()}
                    >
                        <div className="icon">
                            <i className="icon-cursor-hand-1" aria-hidden="true"></i>
                        </div>
                        <div className="text">Add New Document</div>
                    </div>
                )}

                {files && files.length > 0 && (
                    <div className="list">
                        {files.map((file, index) => (
                            <div
                                key={index}
                                className={`file ${errors[file.name] ? 'is-error' : ''} ${progresses[file.name] >= 0 && progresses[file.name] !== 100 && !errors[file.name] ? 'is-uploading' : ''}`}
                            >
                                <div className="meta">
                                    {isImage(file.type) ? (
                                        <div className="image">
                                            <div
                                                className="image"
                                                style={{
                                                    backgroundImage: `url(${URL.createObjectURL(file)})`
                                                }}
                                            ></div>
                                        </div>
                                    ) : (
                                        <div className="icon">
                                            <i
                                                className={`fa icon-file ${attachmentIcon(file)}`}
                                                aria-hidden="true"
                                                style={{ color: attachmentIcon(file, 'color') }}
                                            ></i>
                                        </div>
                                    )}
                                    <div className="name">
                                        {!file.editing && (
                                            <div className="static">
                                                {!file.custom_name && <span>{file.name}</span>}
                                                {file.custom_name && (
                                                    <span>{file.custom_name}</span>
                                                )}
                                            </div>
                                        )}

                                        {file.editing && (
                                            <div className="editing">
                                                <input
                                                    type="text"
                                                    value={file.custom_name}
                                                    onChange={(e) => {
                                                        editCustomName(file.name, e.target.value);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="action">
                                        {isFileVideo(file.type) && (
                                            <div
                                                onClick={() => toggleVideo(file.name)}
                                                className={file.show ? 'active' : null}
                                                title="Modifier la miniature de la vidéo"
                                            >
                                                <i
                                                    className="icon-expand-interface-essentials"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        )}
                                        <div
                                            onClick={() => editFile(file.name)}
                                            className={file.editing ? 'active' : null}
                                        >
                                            <i className="icon-pencil-1" aria-hidden="true"></i>
                                        </div>
                                        <div onClick={() => deleteFile(file.name)}>
                                            <i className="icon-bin-2" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>

                                {!uploaded[file.name] && (
                                    <Progress
                                        value={progresses[file.name] || 0}
                                        size="lg"
                                        striped
                                        animate
                                        style={{ marginTop: 10 }}
                                    />
                                )}

                                {isFileVideo(file.type) && file.show && (
                                    <div className="video-poster-container">
                                        <div className="video">
                                            <video
                                                id={file.hard_name}
                                                controls
                                                playsInline
                                                src={URL.createObjectURL(file)}
                                            >
                                                <source
                                                    type={getVideoType(file.type)}
                                                    src={URL.createObjectURL(file)}
                                                />
                                            </video>
                                        </div>

                                        <label className="button">
                                            <input
                                                hidden="hidden"
                                                type="file"
                                                onChange={(event) => {
                                                    if (event.target.files.length === 1) {
                                                        editPictureVideo(
                                                            event.target.files[0],
                                                            file
                                                        );
                                                    }
                                                }}
                                                accept="image/*"
                                            />
                                            {t('TAKE_PICTURE_VIDEO')}
                                        </label>

                                        <button
                                            className="button"
                                            onClick={() => generatePoster(file)}
                                        >
                                            {t('GENERATE_POSTER_BUTTON')}
                                        </button>
                                    </div>
                                )}

                                {errors[file.name] && (
                                    <div className="error">
                                        <div className="meta">
                                            <div className="title">Error</div>
                                            <div className="reason">
                                                {errors[file.name].message || 'An error occurred'}
                                            </div>
                                        </div>
                                        <div
                                            className="action"
                                            onClick={() => deleteFile(file.name)}
                                        >
                                            <i className="icon-bin-2" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                <div className="submits">
                    <label className="browse_button">
                        <input
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                            multiple={!!modalConfiguration.multiple}
                            style={{ display: modalConfiguration.invisible ? 'block' : 'none' }}
                            accept="*/*"
                        />
                        Browse
                    </label>
                </div>

                {files &&
                    files.length > 0 &&
                    folders.length > 0 &&
                    !modalConfiguration.forceFolder && (
                        <div className="categories">
                            <h4>Choose Folder</h4>
                            <div className="folder_container">
                                {folders.map((folder, index) => (
                                    <div key={index} className="folder">
                                        {folder.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
            </div>
            <div className="actions">
                <button className="submit button grey" onClick={send} disabled={!files.length}>
                    Validate
                </button>
            </div>
        </div>
    );
};

const JoinDocumentModal = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();
    const { modalConfiguration } = useJoinDocumentModal();

    return (
        <div
            className={`join-document-panel ${modalConfiguration?.invisible || modalConfiguration?.invisibleAtStartup ? 'hide' : ''}`}
        >
            <Modal
                name="join_document"
                title={t('POST_JOIN_DOC_QUESTION')}
                subTitle={t('POST_JOIN_DOC_SUBTITLE')}
                options={{ hasPaddedContent: true, zIndex: 320 }}
            >
                {isModalOpen('join_document') && <JoinDocumentModalContent />}
            </Modal>
        </div>
    );
};

export default JoinDocumentModal;
