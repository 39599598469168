import { useHttp } from 'api/useHttp';

const useProfileSettingsService = () => {
    const axios = useHttp();

    const getAllSettings = () => {
        return axios.get('/profile/settings').then((response) => response.data);
    };

    const postProfileSetting = (data) => {
        return axios.post('/profile/setting', data).then((response) => response.data);
    };

    const putProfileSetting = (data) => {
        return axios.put(`/profile/${data.id}/setting`, data).then((response) => response.data);
    };

    const deleteProfileSetting = (id) => {
        return axios.delete(`/profile/setting/${id}`).then((response) => response.data);
    };

    return {
        getAllSettings,
        postProfileSetting,
        putProfileSetting,
        deleteProfileSetting
    };
};

export { useProfileSettingsService };
