import { useTranslation } from 'react-i18next';
import useUser from '../../../../../hooks/useUser';
import RealTimeDate from '../../../../../components/RealTimeDate/RealTimeDate';

const Recruiting = ({ activity }) => {
    const { t } = useTranslation();
    const { getAvatar } = useUser();
    return (
        <>
            <div className="badge">
                <i className="fa fa-users" aria-hidden="true"></i>
            </div>

            <div className="content">
                <div className="campaign">{activity?.campaign?.title}</div>

                {!activity?.submit?.hasAuthorization ? (
                    <div className="text">
                        {t('TA_ACTIVITIES_RECRUITING_TITLE_ANO', {
                            referer_firstname: activity?.submit?.user?.first_name,
                            referer_name: activity?.submit?.user?.last_name
                        })}
                    </div>
                ) : (
                    <div className="text">
                        {t('TA_ACTIVITIES_RECRUITING_TITLE', {
                            firstname: t(activity?.submit?.first_name),
                            name: t(activity?.submit?.last_name),
                            referer_firstname: activity?.submit?.user?.first_name,
                            referer_name: activity?.submit?.user?.last_name
                        })}
                    </div>
                )}

                {activity.submit && activity.submit.from_social && (
                    <div className="from-social">{t('TA_ACTIVITIES_RECRUITING_TITLE_VIA_RS')}</div>
                )}
            </div>

            {activity?.submit?.row && (
                <div className="meta">
                    <div className="date">
                        <RealTimeDate date={activity?.submit?.row?.created_at} isLight={true} />
                    </div>

                    <div className="avatar">
                        <img src={getAvatar(activity?.submit?.row?.user)} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Recruiting;
