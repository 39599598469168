import dayjs from 'dayjs';

const useTableCeilComponent = (config, row) => {
    const getIntValue = () => {
        return parseInt(row[config.type]);
    };

    const getDateValue = () => {
        return row[config.type] ? dayjs(row[config.type]).format('DD/MM/YYYY HH:mm:ss') : null;
    };

    const getStatusColorStyle = () => {
        return row[config.type] ? { color: row[config.type].color } : null;
    };

    const getStatus = () => {
        return row[config.type] ? row[config.type].label : null;
    };

    const getAuthorName = () => {
        return row[config.type] && row[config.type].id
            ? `${row[config.type].first_name} ${row[config.type].last_name}`
            : null;
    };

    const getTextValue = () => {
        return row[config.type];
    };

    return {
        getIntValue,
        getDateValue,
        getStatusColorStyle,
        getStatus,
        getAuthorName,
        getTextValue
    };
};

export default useTableCeilComponent;
