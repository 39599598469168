import React from 'react';
import clip from 'text-clipper';
import AbstractField from '../CustomRender/AbstractField';
import useContent from 'hooks/useContent';
import { useConfig } from 'providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import useEmoji from 'hooks/useEmoji';
import { usePost } from '../../providers/PostProvider';

const Excerpt = ({ post, showReadMore = false, onClick = () => {} }) => {
    const { formatContent } = useContent();
    const { config } = useConfig();
    const { t } = useTranslation();
    const { emoji } = useEmoji();
    const { isPostOutdated } = usePost();

    const getStackedPreviewMaxHeight = () => {
        return `${getStackedMaxPreviewLines() * 1.6}rem`;
    };

    const getStackedMaxPreviewLines = () => {
        return config.STACKED_VIEW_PREVIEW_MAX_LINES || 2;
    };

    const workspaceHasCustomForm = () => {
        return (
            (post.workspace && post.workspace.workspace_form) ||
            (post.workspace_form_input_values.length > 0 && !post.content)
        );
    };

    const getStackedContent = () => {
        return getClippedContent(emoji(formatContent(post.content)));
    };

    const getClippedContent = (content, maxLines = 15) => {
        return clip(content, 280, { html: true, maxLines });
    };

    const getStackedReadMore = () => {
        let attachments = post.attachments?.filter((a) => !a.thumbnail);

        if (workspaceHasCustomForm()) {
            let workspaceFormInputValues = post.workspace_form_input_values;

            if (workspaceFormInputValues && workspaceFormInputValues.length === 0) {
                let fileOrFilesInputValues = workspaceFormInputValues.filter(
                    (workspaceFormInputValue) => {
                        return (
                            ['file', 'files'].indexOf(
                                workspaceFormInputValue.workspace_form_input.type
                            ) !== -1
                        );
                    }
                );

                fileOrFilesInputValues.forEach((fileOrFilesInputValue) => {
                    let fileOrFilesAttachments = fileOrFilesInputValue.attachments
                        ? fileOrFilesInputValue.attachments
                        : [fileOrFilesInputValue.attachment];

                    attachments = attachments.concat(fileOrFilesAttachments);
                });
            }
        }

        if (attachments && attachments.length > 0) {
            let attachmentsTranslate =
                attachments.length === 1
                    ? 'FOLDERS_DOCUMENT_TYPE_ATTACHMENT'
                    : 'FOLDERS_DOCUMENT_TYPE_ATTACHMENTS';

            return `${t('TA_POST_READ_MORE')}, ${attachments.length} ${t(attachmentsTranslate)}`;
        }

        return t('TA_POST_READ_MORE');
    };

    const getStackedFields = () => {
        let workspaceFormInputValues = processPostWorkspaceFormInputValues();

        if (!workspaceFormInputValues || workspaceFormInputValues.length === 0) {
            return [];
        }

        return workspaceFormInputValues
            .filter((workspaceFormInputValue) => {
                let workspaceFormInput = workspaceFormInputValue.workspace_form_input;

                return (
                    !workspaceFormInput.hidden &&
                    ['file', 'files', 'separator', 'recipients', 'position'].indexOf(
                        workspaceFormInput.type
                    ) === -1
                );
            })
            .slice(0, getStackedMaxPreviewLines());
    };

    const processPostWorkspaceFormInputValues = () => {
        let workspaceFormInputValues = post.workspace_form_input_values;

        if (!workspaceFormInputValues || workspaceFormInputValues.length === 0) {
            return;
        }

        let workspaceFormInputCollectionValues = {};

        workspaceFormInputValues = workspaceFormInputValues.filter((workspaceFormInputValue) => {
            let workspaceFormInput = workspaceFormInputValue.workspace_form_input;

            if (
                workspaceFormInput &&
                workspaceFormInput.type === 'collection' &&
                !workspaceFormInputValue.is_processed
            ) {
                if (workspaceFormInputCollectionValues.hasOwnProperty(workspaceFormInput.id)) {
                    workspaceFormInputCollectionValues[workspaceFormInput.id].values.push({
                        id: workspaceFormInputValue.id,
                        text: workspaceFormInputValue.text,
                        qualification: workspaceFormInputValue.qualification
                    });
                } else {
                    workspaceFormInputCollectionValues[workspaceFormInput.id] = {
                        is_processed: true,
                        order: workspaceFormInputValue.order,
                        workspace_form_input: workspaceFormInputValue.workspace_form_input,
                        values: [
                            {
                                id: workspaceFormInputValue.id,
                                text: workspaceFormInputValue.text,
                                qualification: workspaceFormInputValue.qualification
                            }
                        ]
                    };
                }

                return false;
            }

            return true;
        });

        workspaceFormInputValues = workspaceFormInputValues.concat(
            Object.values(workspaceFormInputCollectionValues)
        );

        return workspaceFormInputValues;
    };

    const isFirstInputFile = () => {
        if (workspaceHasCustomForm()) {
            let workspaceFormInputValues = post.workspace_form_input_values;

            if (workspaceFormInputValues[0]) {
                return (
                    workspaceFormInputValues[0].workspace_form_input.type === 'files' ||
                    workspaceFormInputValues[0].workspace_form_input.type === 'file'
                );
            }
        }
    };

    const renderInputField = () => {
        return getStackedFields().map((input) => {
            return (
                <div
                    className={`field ${input.workspace_form_input.type}`}
                    key={input.workspace_form_input.id}>
                    <AbstractField
                        input={input}
                        type={input.workspace_form_input.type}></AbstractField>
                </div>
            );
        });
    };

    if (!isPostOutdated()) {
        return (
            <div
                className="excerpt"
                style={{ maxHeight: getStackedPreviewMaxHeight() }}
                onClick={onClick}>
                {!workspaceHasCustomForm() && (
                    <div
                        className="html"
                        dangerouslySetInnerHTML={{ __html: getStackedContent() }}></div>
                )}

                {workspaceHasCustomForm() && <div className="fields">{renderInputField()}</div>}

                {showReadMore && (
                    <div
                        className={`read-more ${workspaceHasCustomForm() && isFirstInputFile() && 'files-first'}`}>
                        <div className="text">{getStackedReadMore()}</div>
                    </div>
                )}
            </div>
        );
    }

    return <></>;
};

export default Excerpt;
