import React from 'react';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';
import { useConfig } from 'providers/ConfigProvider';

const Users = ({ result, query }) => {
    const { config } = useConfig();
    const { getResultWithQueryHighlight } = useSearchBoxResultComponent(query);

    return (
        <>
            <div className="icon">
                <img src={`${config.serverUrl}${result.image}`} alt="User Avatar" />
            </div>

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.name)
                    }}
                />
            </div>
        </>
    );
};

export default Users;
