import React from 'react';
import useCampaigns from 'hooks/useCampaign';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const Campaigns = ({ result, query }) => {
    const { getCampaignImage, getCampaignTypeIcon } = useCampaigns();
    const { getResultWithQueryHighlight } = useSearchBoxResultComponent(query);

    const campaignImage = getCampaignImage(result);

    return (
        <>
            {campaignImage ? (
                <div
                    className={`icon ${result.type}`}
                    style={{ backgroundImage: `url(${campaignImage})` }}></div>
            ) : (
                <div className={`icon ${result.type}`}>
                    <i className={`fa ${getCampaignTypeIcon(result)}`}></i>
                </div>
            )}

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.title)
                    }}
                />
            </div>
        </>
    );
};

export default Campaigns;
