import useAttachment from './useAttachment';
import { useTranslation } from 'react-i18next';

const useCampaign = () => {
    const { forgeAttachmentUrl, forgeAttachmentPoster } = useAttachment();
    const { t } = useTranslation();

    const TYPES = {
        social: {
            name: t('TA_SOCIAL_TITLE'),
            firstLetters: t('TA_SOCIAL_TITLE')
                .match(/\b(\w)/g)
                .join('')
        },
        product: {
            name: t('TA_PRODUCT_TITLE'),
            firstLetters: t('TA_PRODUCT_TITLE')
                .match(/\b(\w)/g)
                .join('')
        },
        recruiting: {
            name: t('TA_RECRUITING_TITLE'),
            firstLetters: t('TA_RECRUITING_TITLE')
                .match(/\b(\w)/g)
                .join('')
        },
        survey: {
            name: t('TA_SURVEY_TITLE'),
            firstLetters: t('TA_SURVEY_TITLE')
                .match(/\b(\w)/g)
                .join('')
        },
        mission: {
            name: t('MISSIONS'),
            firstLetters: t('MISSIONS')
                .match(/\b(\w)/g)
                .join('')
        }
    };

    const getCampaignImage = (campaign) => {
        if (campaign.link) {
            return campaign.link.picture_url;
        } else if (campaign.image) {
            if (!campaign.image.poster) {
                return forgeAttachmentUrl(campaign.image);
            } else {
                return forgeAttachmentPoster(campaign.image);
            }
        }

        return null;
    };

    const getCampaignTypeName = (campaign) => {
        return TYPES[campaign.type].name;
    };

    const getCampaignTypeIcon = (campaign) => {
        switch (campaign.type) {
            case 'social':
                return 'fa-share-alt';
            case 'recruiting':
                return 'fa-users';
            case 'product':
                return 'fa-cube';
            case 'survey':
                return 'fa-comment';
        }
    };

    return {
        getCampaignImage,
        getCampaignTypeIcon,
        getCampaignTypeName
    };
};

export default useCampaign;
