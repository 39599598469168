import React, { useState } from 'react';
import { ColorPicker as AntdColorPicker } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

function ColorPicker({ name, value = null, ...props }) {
    const { control } = useFormContext();
    const [open, setOpen] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field, formState }) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                    <AntdColorPicker
                        open={open}
                        onOpenChange={setOpen}
                        onChange={(color) => {
                            field.onChange(color.toHexString());
                        }}
                        defaultValue={value || field.value}
                        value={field.value}
                        style={{
                            justifyContent: 'start'
                        }}
                        showText={(color) => <span>{color.toHexString()}</span>}
                        {...props}
                    />
                    {formState.errors[name] && (
                        <span className="error">{formState.errors[name].message}</span>
                    )}
                </div>
            )}
        />
    );
}

export default ColorPicker;
