import React, { useState, useEffect, useRef, useMemo } from 'react';
import Color from 'color';
import classNames from 'classnames';
import Hexagon from './shapes/hexagon';
import Octagram from './shapes/octagram';

const Achievement = (props) => {
    const iconRef = useRef(null);

    const shapes = {
        hexagon: Hexagon,
        octagram: Octagram
    };

    const [iconSize, setIconSize] = useState('12px');
    const [startColor, setStartColor] = useState(null);
    const [stopColor, setStopColor] = useState(null);
    const [strokeColor, setStrokeColor] = useState(null);

    const uniqueId = useRef(
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    ).current;

    useEffect(() => {
        setTimeout(() => {
            let iconSize = iconRef.current && iconRef.current.offsetWidth;

            if (iconSize && iconSize !== 0) {
                setIconSize(`${iconSize}px`);
            }
        }, 0);
    }, []);

    const setupColors = (color) => {
        try {
            const startColor = Color(color);

            if (startColor.isLight()) {
                setStopColor(startColor.darken(0.15));
                setStrokeColor(startColor.mix(Color('#fff'), 0.5));
            } else {
                setStopColor(startColor.lighten(0.15));
                setStrokeColor(startColor.mix(Color('#fff'), 0.5));
            }

            setStartColor(startColor);
        } catch (e) {
            console.error('Not a valid color');
        }
    };

    useEffect(() => {
        setupColors(getColor());
    }, [props.item, props.color]);

    const hash = useMemo(() => {
        return uniqueId;
    }, [props]);

    const hashUrl = useMemo(() => {
        return `${window.location.href}#${hash}`;
    }, [hash]);

    const getName = () => {
        return props.item ? props.item.name : props.name;
    };

    const getDescription = () => {
        return props.item && props.item.description
            ? `${getName()}: ${props.item.description}`
            : getName();
    };

    const getTitle = () => {
        return props.showPopover ? '' : getDescription();
    };

    const getShape = () => {
        return props.item ? props.item?.shape : props?.shape;
    };

    const getShapeComponent = () => {
        return shapes[getShape()];
    };

    const getIcon = () => {
        return props.item ? props.item?.icon : props?.icon;
    };

    const getColor = () => {
        return props.item ? props.item?.color : props?.color;
    };

    const getStartColorHex = () => {
        return startColor ? startColor.hex() : '';
    };

    const getStopColorHex = () => {
        return stopColor ? stopColor.hex() : '';
    };

    const getStrokeColorHex = () => {
        return strokeColor ? strokeColor.hex() : '';
    };

    const getIconClassName = () => {
        return `icon-${getIcon()}`;
    };

    const AchievementShape = getShapeComponent();

    return (
        <div className="achievementRoot">
            <div className={classNames('achievement', getShape())} title={getTitle()}>
                {props.showPopover ? <div className="popover">{getDescription()}</div> : null}

                <div className="badge">
                    {getShape() && (
                        <AchievementShape
                            strokeColorHex={getStrokeColorHex()}
                            hashUrl={hashUrl}
                            hash={hash}
                            startColorHex={getStartColorHex()}
                            stopColorHex={getStopColorHex()}
                        />
                    )}
                </div>

                <div className="icon" ref={iconRef} style={{ fontSize: iconSize }}>
                    <i className={getIconClassName()} aria-hidden="true" />
                </div>
            </div>
        </div>
    );
};

export default Achievement;
