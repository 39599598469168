import { useHttp } from './useHttp';

const useCampaignSurveyService = () => {
    const axios = useHttp();

    const getSurveyCampaign = (id, fromCampaignDashboard = false) => {
        return axios
            .get(`/dashboard/surveys/${id}/campaign?fromCampaignDashboard=${fromCampaignDashboard}`)
            .then((response) => response.data);
    };

    return {
        getSurveyCampaign
    };
};

export default useCampaignSurveyService;
