import { Input } from 'components/Form/Inputs';
import { useState } from 'react';

const NumericFilter = ({ input }) => {
    const [toggle, setToggle] = useState(false);
    return (
        <>
            <button className="label" onClick={() => setToggle((prev) => !prev)}>
                {input.name}
            </button>
            {toggle && (
                <div className="custom">
                    <div className="range start">
                        <Input
                            type="number"
                            ignore-mouse-wheel
                            placeholder="Min"
                            name={`${input.type}__min`}
                        />
                    </div>

                    <div className="range end">
                        <Input
                            type="number"
                            ignore-mouse-wheel
                            placeholder="Max"
                            name={`${input.type}__max`}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default NumericFilter;
