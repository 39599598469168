import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function Switch({ name, value = false }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field }) => {
                return (
                    <div className="switch-input">
                        <input
                            className="input"
                            id={field.name}
                            type="checkbox"
                            name={field.name}
                            checked={field.value}
                            onChange={field.onChange}
                        />
                        <label className="label" htmlFor={field.name}></label>
                    </div>
                );
            }}
        />
    );
}

export default Switch;
