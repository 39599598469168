import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useLocation } from 'react-use';
import { useAuth } from 'providers/AuthContext';
import useUserService from 'api/useUserService';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const router = useRouter();
    const { params } = useCurrentStateAndParams();
    const { user } = useAuth();
    const location = useLocation();
    const { getUserProfile } = useUserService();

    const [currentUser, setCurrentUser] = useState();
    const [currentActiveTab, setCurrentActiveTab] = useState();

    useEffect(() => {
        if (!params.userId || params.userId === user.id) {
            setCurrentUser(user);
        } else {
            getUserProfile(params.userId).then((data) => {
                setCurrentUser(data);
            });
        }

        switch (router.globals.current.name) {
            case 'auth.profile':
            case 'auth.my-profile':
                setCurrentActiveTab('posts');
                return;
            case 'auth.profile.badges':
                setCurrentActiveTab('badges');
                return;
            case 'auth.profile.preferences':
                setCurrentActiveTab('preferences');
                return;
            case 'auth.profile.subscriptions':
                setCurrentActiveTab('subscriptions');
                return;
        }
    }, [location, router.globals.current.name]);

    return (
        <ProfileContext.Provider
            value={{
                currentUser,
                currentActiveTab
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () => useContext(ProfileContext);
export default ProfileProvider;
