import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import { merge } from 'lodash-es';
import { useRouter } from '@uirouter/react';

const TreeModalCampaignContent = () => {
    const router = useRouter();
    const { hasCampaignType } = useRight();
    const { t } = useTranslation();

    const currentCampaignFeed =
        router.globals.current.name.indexOf('activities') !== -1 ? 'activities' : 'feed';

    const getFilteredCampaignPageHref = (params) => {
        if (currentCampaignFeed === 'activities') {
            params = merge(params, { state: 'activity', page: 1, opportunity: null });
        } else {
            params = merge(params, { state: 'running', page: 1, opportunity: null });
        }

        return router.stateService.href(`auth.campaigns.${currentCampaignFeed}`, params);
    };

    const CAMPAIGN_TYPES = [
        {
            type: 'all',
            icon: 'icon-award-trophy',
            label: 'TA_ALL_TITLE'
        },
        {
            type: 'product',
            icon: 'fa fa-fw fa-cube',
            label: 'TA_PRODUCT_TITLE'
        },
        {
            type: 'social',
            icon: 'fa fa-fw fa-share-alt',
            label: 'TA_SOCIAL_TITLE'
        },
        {
            type: 'recruiting',
            icon: 'fa fa-fw fa-calendar',
            label: 'TA_RECRUITING_TITLE'
        },
        {
            type: 'survey',
            icon: 'fa fa-fw fa-calendar',
            label: 'TA_SURVEY_TITLE'
        }
    ];

    return (
        <div className="tree">
            <div className="body">
                <div className="tree">
                    {CAMPAIGN_TYPES.map((campaignType) => {
                        return (
                            hasCampaignType(campaignType.type) && (
                                <div className="items" key={campaignType.type}>
                                    <div className="item">
                                        <div className="workspace">
                                            <a
                                                className="meta"
                                                href={getFilteredCampaignPageHref({
                                                    type: campaignType.type
                                                })}>
                                                <div className={`icon ${campaignType.type}`}>
                                                    <i
                                                        className={campaignType.icon}
                                                        aria-hidden="true"></i>
                                                </div>

                                                <div className="name">{t(campaignType.label)}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TreeModalCampaignContent;
