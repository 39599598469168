import { useHttp } from 'api/useHttp';
import { useConfig } from 'providers/ConfigProvider';
import { useQueryClient } from '@tanstack/react-query';
import { useDevice } from 'providers/DeviceContext';

const useUserService = () => {
    const axios = useHttp();
    const { config } = useConfig();
    const queryClient = useQueryClient();
    const { device } = useDevice();

    const reInitPassword = (email) => {
        return axios.post(`/magic/connection/mail`, { email }).then((response) => response.data);
    };

    const login = (email, password) => {
        return axios
            .post(`/${config.login_url ? config.login_url : 'login_check'}`, {
                _username: email,
                _password: password
            })
            .then((response) => response.data);
    };

    const findEmployeeByCriteriaV2 = (criteria, availableOnly = true) => {
        return axios
            .get(`/employees/autocomplete/v2`, {
                params: {
                    criteria,
                    availableOnly: availableOnly
                }
            })
            .then((response) => response.data);
    };

    const findUsersByCriteria = (
        criteria,
        excludedUserIds = [],
        workspaceId = null,
        campaignId = null
    ) => {
        return axios
            .get(`/users/autocomplete`, {
                params: {
                    criteria: criteria?.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    'excludedUserIds[]': excludedUserIds,
                    workspaceId,
                    campaignId
                }
            })
            .then((response) => response.data);
    };

    const getUser = (ignoreInterceptor = false, token = null, useCache = true) => {
        let url = '/user/information';

        if (token) {
            url = `${url}?token=${token}`;
        }

        return axios.get(url).then((response) => {
            return response.data;
        });
    };

    const getUserConfig = () => {
        return axios.get('/languages/with/configs').then((response) => {
            return response.data;
        });
    };

    const loginWithSSOToken = (access_token, code, clientId, redirectUri, state) => {
        return axios
            .post('/login/sso/token', {
                access_token,
                code,
                clientId,
                redirectUri,
                state
            })
            .then((response) => response.data);
    };

    const changePassword = (data) => {
        return axios.post(`/users/change/password`, data).then((response) => response.data);
    };

    const saveLoginHistory = () => {};

    const eraseUser = (user) => {
        return axios.put(`/users/${user.id}/erase/points`).then((response) => response.data);
    };

    const getLastXConnections = (user, limit) => {
        return axios
            .get(`/user/${user.id}/last/${limit}/connections`)
            .then((response) => response.data);
    };

    const getToken = () => {
        if (!device.isApp) {
            return Promise.resolve({ token: null });
        }

        return axios
            .get('/user/token')
            .then((response) => response.data)
            .then(({ token }) => {
                return { token };
            });
    };

    const updateUser = (user) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`/users/${user.id}`, {
                    user_modify: user
                })
                .then((response) => {
                    queryClient.removeQueries();
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const getUserProfile = (id) => {
        return axios
            .get(`/user`, {
                params: {
                    id
                }
            })
            .then((response) => response.data);
    };

    const postUserRewardsImport = (importUserRewardsAttachment) => {
        return axios
            .post(`/dashboard/users/rewards/import`, { importUserRewardsAttachment })
            .then((response) => response.data);
    };

    const sendNotification = (notification) => {
        return axios
            .post('/users/send/notifications', { notification })
            .then((response) => response.data);
    };

    return {
        reInitPassword,
        login,
        getUser,
        saveLoginHistory,
        loginWithSSOToken,
        changePassword,
        findEmployeeByCriteriaV2,
        findUsersByCriteria,
        updateUser,
        getToken,
        getUserProfile,
        getLastXConnections,
        postUserRewardsImport,
        eraseUser,
        sendNotification,
        getUserConfig
    };
};

export default useUserService;
