import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';

const Image = ({ widget, options }) => {
    const { getStyleValueForKey, getFieldValueForKey, getRoundSize } = useWidgetComponent(
        widget,
        options
    );

    return (
        <div className="image">
            {!getFieldValueForKey('hideTitle') && (
                <div
                    className={`title ${getStyleValueForKey('titleAlign')}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                >
                    {getFieldValueForKey('title')}
                </div>
            )}

            <div className="image" style={{ borderRadius: getRoundSize(), overflow: 'hidden' }}>
                <img src={getFieldValueForKey('image')} />
            </div>
        </div>
    );
};

export default Image;
