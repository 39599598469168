import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { Input, Checkbox, Select, Textarea } from 'components/Form/Inputs';
import { orderBy, isEmpty } from 'lodash-es';
import { useAdminTreeContext } from '../../../providers/AdminTreeProvider';
import useTreeHook from 'hooks/useTree';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import useOrganizationalUnitService from '../api/useGroupService';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';
import { useQueryClient } from '@tanstack/react-query';
import useUtils from 'hooks/useUtils';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import useLevel from '../../../../../hooks/useLevel';

const GroupFormModalContent = () => {
    const { t } = useTranslation();
    const { levels, levelConfigs } = useAdminLevelContext();
    const { levelsGroupByIndex } = useLevel();
    const {
        contexts: {
            ['add_group']: { currentGroup }
        },
        closeModal
    } = useModal();
    const { postOrganizationalUnit } = useOrganizationalUnitService();
    const { showToast } = useAlert();
    const { arrayAsId } = useUtils();
    const queryClient = useQueryClient();

    const methods = useForm();

    const onFinish = (values) => {
        const payload = { id: currentGroup?.id, ...values };
        payload.excluded_emails = payload.excluded_emails.split('/n');
        payload.manualUserEmails = payload.manualUserEmails.split('/n');
        payload.writable_workspaces_target = arrayAsId(payload.writable_workspaces_target);
        payload.readable_workspaces_target = arrayAsId(payload.readable_workspaces_target);
        payload.levels_target = arrayAsId(payload.levels_target);
        postOrganizationalUnit(payload)
            .then(
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_CONFIRM'),
                        duration: 1500
                    });
                    queryClient.refetchQueries({ queryKey: ['allOrganizationalUnits'] });
                },
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_ERROR'),
                        duration: 1500
                    });
                }
            )
            .finally(() => {
                closeModal('add_edit_ou');
            });
    };

    return (
        <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
            <ModalFormBody>
                <ModalFormSection>
                    <div>
                        <Input
                            type={'text'}
                            name={'name'}
                            label={t('ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL')}
                            value={currentGroup?.name}
                        />
                    </div>
                    <div>
                        <Textarea
                            name={'description'}
                            label={t('ADMIN_MENU_HELPDESK_COMMON_DESCRIPTION_LABEL')}
                            value={currentGroup?.name}
                        />
                    </div>
                </ModalFormSection>
                <ModalFormSection>
                    <Select
                        style={{ width: '100%' }}
                        name={'levelIndex'}
                        label={t('ADMIN_MANAGE_GROUPS_SELECT_LEVEL_INDEX')}
                        value={currentGroup?.levelIndex}
                        options={orderBy(levelConfigs, ['name']).map((level) => {
                            return {
                                label: level.name,
                                value: level.index
                            };
                        })}
                    />
                    {methods.watch('levelIndex') && (
                        <Select
                            style={{ width: '100%' }}
                            mode={'multiple'}
                            name={'excludedLevelValues'}
                            label={t('ADMIN_MANAGE_GROUPS_EXCLUDED_LEVELS')}
                            value={currentGroup?.excludedLevelValues}
                            options={orderBy(
                                levels
                                    ? levelsGroupByIndex(levels)[methods.watch('levelIndex')]
                                    : [],
                                ['name']
                            ).map((level) => {
                                return {
                                    label: level.name,
                                    value: level.id
                                };
                            })}
                        />
                    )}

                    <Select
                        style={{ width: '100%' }}
                        name={'adminLevelIndex'}
                        label={t('ADMIN_MANAGE_GROUPS_SELECT_ADMIN_LEVEL_INDEX')}
                        value={currentGroup?.adminLevelIndex}
                        options={orderBy(levelConfigs, ['name']).map((level) => {
                            return {
                                label: level.name,
                                value: level.index
                            };
                        })}
                    />
                    {methods.watch('adminLevelIndex') && (
                        <Select
                            style={{ width: '100%' }}
                            mode={'multiple'}
                            name={'adminLevelValues'}
                            label={t('ADMIN_MANAGE_GROUPS_ADMIN_LEVELS_VALUES')}
                            value={currentGroup?.adminLevelValues}
                            options={orderBy(
                                levels
                                    ? levelsGroupByIndex(levels)[methods.watch('adminLevelIndex')]
                                    : [],
                                ['name']
                            ).map((level) => {
                                return {
                                    label: level.name,
                                    value: level.id
                                };
                            })}
                        />
                    )}
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_edit_ou');
                }}
            />
        </ModalForm>
    );
};

const GroupFormModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name={`add_group`}
            title={t('ADMIN_MANAGE_GROUPS_ADD_GROUP')}
            options={{
                hasFlexContent: true
            }}>
            <GroupFormModalContent />
        </Modal>
    );
};

export default GroupFormModal;
