import React from 'react';
import { useAuth } from '../../providers/AuthContext';
import useDate from '../../hooks/useDate';
import { useTranslation } from 'react-i18next';
import striptags from 'striptags';
import Avatar from '../Avatar/Avatar';
import Username from '../Username/Username';
import clip from 'text-clipper';
import linkifyHtml from 'linkify-html';
import useEmoji from '../../hooks/useEmoji';
import limitTo from '../../helpers/limitTo';
import Link from '../Link/Link';
import { isImageAttachment, isVideoAttachment } from '../../helpers/attachment';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import useAttachmentIcon from '../../hooks/useAttachmentIcon';
import useAttachment from '../../hooks/useAttachment';

const ChannelMessage = ({ message }) => {
    const { user } = useAuth();
    const { format: dateFormat } = useDate();
    const { t } = useTranslation();
    const { emoji } = useEmoji();
    const { attachmentIcon } = useAttachmentIcon();
    const { getVideoState, getVideoFiles, getVideoType } = useAttachment();
    const { forgeAttachmentUrl } = useAttachment();

    const isItToday = (message) => {
        let now = new Date();
        let messageDate = new Date(message.created_at);

        return (
            messageDate.getDate() === now.getDate() &&
            messageDate.getMonth() === now.getMonth() &&
            messageDate.getYear() === now.getYear()
        );
    };

    const getAnswerReplyTo = (message) => {
        if (message.isFromLoggedUser) {
            if (message.answer.user.id === message.user.id) {
                return striptags(
                    t('CHAT_ANSWER_USER', {
                        name: t('CHAT_ANSWER_YOURSELF')
                    })
                );
            }

            return t('CHAT_OWN_MESSAGE_ANSWER_TO_OTHERS', {
                name: message.answer.user.first_name
            });
        }

        if (message.answer.user.id === message.user.id) {
            return t('CHAT_MESSAGE_ANSWER_TO_SELF', {
                name: message.user.first_name
            });
        }

        if (message.answer.user.id === user.id) {
            return t('CHAT_MESSAGE_ANSWER_TO_ME', {
                name: message.user.first_name
            });
        }

        return t('CHAT_MESSAGE_ANSWER_TO_GROUP', {
            name: message.user.first_name,
            name2: message.answer.user.first_name
        });
    };

    const getClippedContent = (content, maxLines = 3) => {
        return clip(content, 280, { html: true, indicator: '', maxLines });
    };

    const hasAnswer = (message) => {
        return !!message.answer;
    };

    const getAnswerContent = (message) => {
        let { content = null } = message.answer;

        if (!content) {
            return t('CHAT_ANSWER_FILE_CONTENT');
        }

        let clippedContent = getClippedContent(content);

        if (clippedContent.length < content.length) {
            clippedContent += '...';
        }

        return clippedContent;
    };

    const formattedMessage = (message) => {
        return linkifyHtml(message, {
            defaultProtocol: 'https',
            nl2br: true
        });
    };

    const onlyEmoji = (content) => {
        const regex = /[\uD800-\uDFFF][\uDC00-\uDFFF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g;
        return content.replace(regex, '').length === 0;
    };

    return (
        <div className={`item ${message.isStacked ? 'stacked' : ''}`}>
            {message.currentDayIsDifferent && (
                <div className="date">
                    {!isItToday(message) && (
                        <span className="time not-today">
                            {dateFormat(message.created_at, 'dd/MM/yyyy')}
                        </span>
                    )}

                    {isItToday(message) && (
                        <span className="time is-today">{t('CHAT_MESSAGE_DATE_IS_TODAY')}</span>
                    )}
                </div>
            )}

            {message.isNew && (
                <div className="is-new">
                    <div className="text">
                        {message.unreadMessagesCount === 1
                            ? t('CHANNEL_UNREAD_MESSAGE')
                            : t('CHANNEL_UNREAD_MESSAGES', {
                                  unread_count: message.unreadMessagesCount
                              })}
                    </div>
                </div>
            )}

            <div
                className={`message ${message.isFromLoggedUser ? 'mine' : 'theirs'}`}
                data-message-id={message.id}
            >
                <div className="avatar">
                    {!message.isStacked && <Avatar user={message.user} showPopover={false} />}
                </div>

                <div className="content">
                    {!message.isFromLoggedUser && !message.isStacked && !hasAnswer(message) && (
                        <div className="name">
                            <Username user={message.user} light={true} />
                        </div>
                    )}
                    {hasAnswer(message) && (
                        <div className="name answer">{getAnswerReplyTo(message)}</div>
                    )}

                    {hasAnswer(message) && (
                        <div
                            className={`actions-wrapper ${message.reactions.length > 0 ? 'has-reactions' : ''}`}
                        >
                            <div
                                className="text answer"
                                title={t('CHAT_ANSWER_GO_TO_ORIGINAL')}
                                dangerouslySetInnerHTML={{
                                    __html: emoji(formattedMessage(getAnswerContent(message)))
                                }}
                            ></div>
                        </div>
                    )}

                    {message.attachment && (
                        <div className="actions-wrapper">
                            <div className="attachment">
                                {isVideoAttachment(message.attachment) && (
                                    <VideoPlayer
                                        src={forgeAttachmentUrl(message.attachment)}
                                        type={getVideoType(message.attachment.type)}
                                        poster="attachment"
                                        files={getVideoFiles(message.attachment)}
                                        videoState={getVideoState(message.attachment)}
                                        readOnly={message.attachment.read_only}
                                    />
                                )}

                                {isImageAttachment(message.attachment) && (
                                    <img
                                        src={forgeAttachmentUrl(message.attachment)}
                                        alt="Attachment"
                                    />
                                )}

                                {!isVideoAttachment(message.attachment) &&
                                    !isImageAttachment(message.attachment) && (
                                        <div className="file">
                                            <div className="meta">
                                                <div className="icon">
                                                    <i
                                                        className={`fa ${attachmentIcon(message.attachment, 'class')}`}
                                                        style={{
                                                            color: attachmentIcon(
                                                                message.attachment,
                                                                'color'
                                                            )
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="name">
                                                    {message.attachment.name}
                                                </div>
                                            </div>
                                            <div className="download">
                                                <a
                                                    href={forgeAttachmentUrl(message.attachment)}
                                                    download
                                                >
                                                    <i
                                                        className="fa fa-arrow-circle-down"
                                                        aria-hidden="true"
                                                    ></i>{' '}
                                                    {t('POST_DOWNLOAD')}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            {message.reactions.length > 0 && (
                                <div className="reactions">
                                    {limitTo(message.reactions, 3).map((reaction, index) => (
                                        <span
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: reaction.emoji }}
                                        ></span>
                                    ))}
                                    {message.reactions.length > 1 && (
                                        <div className="number">{message.reactions.length}</div>
                                    )}
                                </div>
                            )}

                            <div className="actions">
                                <button
                                    className="action"
                                    onClick={() => {
                                        /* Add reaction logic */
                                    }}
                                >
                                    <i className="icon-smiley-happy" aria-hidden="true"></i>
                                </button>
                                <button
                                    className="action"
                                    title={t('CHAT_ANSWER_ACTION_TITLE')}
                                    onClick={() => {
                                        /* Answer logic */
                                    }}
                                >
                                    <i className="icon-email-action-reply" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    )}

                    {message.link && (
                        <div className="link">
                            <Link
                                item={message.link}
                                load-callback="$ctrl.onImageLoadedHandler"
                                small={true}
                            />
                        </div>
                    )}

                    {message.content && (
                        <div
                            className={`actions-wrapper ${message.reactions.length > 0 ? 'has-reactions' : ''}`}
                        >
                            <div className="text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: emoji(formattedMessage(message.content))
                                    }}
                                    className={`text-content ${onlyEmoji(message.content) ? 'only-emoji' : ''}`}
                                ></div>
                            </div>

                            {message.reactions.length > 0 && (
                                <div className="reactions">
                                    {limitTo(message.reactions, 3).map((reaction, index) => (
                                        <span
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: reaction.emoji }}
                                        ></span>
                                    ))}
                                    {message.reactions.length > 1 && (
                                        <div className="number">{message.reactions.length}</div>
                                    )}
                                </div>
                            )}

                            <div className="actions">
                                <button
                                    className="action"
                                    onClick={() => {
                                        /* Add reaction logic */
                                    }}
                                >
                                    <i className="icon-smiley-happy" aria-hidden="true"></i>
                                </button>
                                <button
                                    className="action"
                                    title={t('CHAT_ANSWER_ACTION_TITLE')}
                                    onClick={() => {
                                        /* Answer logic */
                                    }}
                                >
                                    <i className="icon-email-action-reply" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    )}

                    {message.needToDisplayDate && (
                        <div className="meta">
                            <div className="timestamp">
                                <time dateTime={message.created_at}>
                                    {dateFormat(message.created_at)}
                                </time>
                                {message.isFromLoggedUser && (
                                    <div className="hour">
                                        <div className="space">·</div>
                                        <div
                                            className={`read ${message.isRead ? 'confirmed' : ''}`}
                                        >
                                            <i className="icon-double-check"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChannelMessage;
