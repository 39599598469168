import { useHttp } from './useHttp';

const useChannelService = () => {
    const axios = useHttp();

    const getChannels = () => {
        return axios.get(`/channels`).then((response) => response.data);
    };

    const getChannelInfo = (channelId) => {
        return axios.get(`/channels/${channelId}`).then((response) => response.data);
    };

    const getMessages = (channelId, lastMessageId) => {
        return axios
            .get(`/channels/${channelId}/messages`, {
                params: { page: lastMessageId }
            })
            .then((response) => response.data);
    };

    return {
        getChannels,
        getChannelInfo,
        getMessages
    };
};

export default useChannelService;
