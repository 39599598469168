import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBlog from 'hooks/useBlog';
import useRight from 'hooks/useRight';
import { useBlog as useBlogContext } from '../providers/BlogProvider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import useBlogArticleService from 'api/useBlogArticleService';
import useAttachment from 'hooks/useAttachment';
import { isAudioAttachment, isVideoAttachment } from 'helpers/attachment';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import Comments from 'components/Comments/Comments';
import limitTo from 'helpers/limitTo';
import useDate from 'hooks/useDate';
import Username from 'components/Username/Username';
import useUser from 'hooks/useUser';
import Comment from 'components/Comment/Comment';
import Card from 'components/Article/Card';
import PhotoAlbum from 'react-photo-album';
import { useAuth } from '../../../providers/AuthContext';
import useLikeService from '../../../api/useLikeService';

const Article = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const {
        isPublicState,
        getLocalizedCategoryTitle,
        getArticleTimeToRead,
        getLocalizedArticleProperty
    } = useBlog();
    const { showNavigation, currentState } = useBlogContext();
    const { hasRole, isBlogAdmin } = useRight();
    const { params } = useCurrentStateAndParams();
    const {
        getPublicBlogArticle,
        getBlogArticle,
        getPublicRelatedBlogArticles,
        getRelatedBlogArticles
    } = useBlogArticleService();
    const {
        forgeBlogArticleContentAttachmentUrl,
        getVideoFiles,
        getVideoState,
        getVideoType,
        getPhotoGridObject,
        download
    } = useAttachment();
    const { attachmentIcon } = useAttachmentIcon();
    const { forgeAvatarUrl } = useUser();
    const { format: dateFormat } = useDate();
    const { user } = useAuth();
    const LikeService = useLikeService();
    const queryClient = useQueryClient();

    const { data: article } = useQuery({
        queryKey: ['blog_articles', `blog_article_${params.article}_${params.slug}`],
        queryFn: () => {
            if (currentState === 'public') {
                return getPublicBlogArticle(params.article, params.slug);
            }

            return getBlogArticle(params.article, params.slug);
        }
    });

    const { data: relatedArticles } = useQuery({
        queryKey: ['blog_articles', `blog_related_article_${params.article}`],
        queryFn: () => {
            if (currentState === 'public') {
                return getPublicRelatedBlogArticles(params.article);
            }

            return getRelatedBlogArticles(params.article);
        }
    });

    const showComments = () => {
        if (isPublicState()) {
            return false;
        }

        return !hasRole('BLOG_HIDE_COMMENTS');
    };

    const showLikes = () => {
        if (isPublicState()) {
            return false;
        }

        return !hasRole('BLOG_HIDE_LIKES');
    };

    const showAuthor = () => {
        return !hasRole('BLOG_HIDE_AUTHOR');
    };

    const showDates = () => {
        return !hasRole('BLOG_HIDE_DATES');
    };

    const isType = (type) => {
        return article.type === type;
    };

    const commentSubmitCallbackHandler = () => {};

    const hasLiked = (article) => {
        return article.likes.some((like) => {
            return like.user.id === user.id;
        });
    };

    const toggleLike = (article) => {
        let existingLikeFromUser = article.likes.find((like) => {
            return like.user.id === user.id;
        });

        let method = existingLikeFromUser ? 'deleteLike' : 'addLike';
        let params = existingLikeFromUser
            ? existingLikeFromUser.id
            : { id: article.id, type: 'article' };

        return LikeService[method](params).then((like) => {
            queryClient.refetchQueries({ queryKey: ['blog_articles'] });
            if (method === 'delete') {
                return existingLikeFromUser;
            }

            return like;
        });
    };

    return (
        <div className="blog-article-wrapper">
            {/*<edit-article></edit-article>*/}

            <div className="blog-article">
                {article && (
                    <div className="article-wrapper">
                        {showNavigation && (
                            <div className="breadcrumb">
                                <div className="item">
                                    <a
                                        className="item"
                                        href={router.stateService.href('auth.blog.articles')}
                                    >
                                        {t('BLOG_ARTICLE_HOME')}
                                    </a>
                                </div>

                                {article.category?.parent && (
                                    <div className="item">
                                        <a
                                            className="item"
                                            href={router.stateService.href('auth.blog.articles', {
                                                category: article.category.parent.id
                                            })}
                                        >
                                            {getLocalizedCategoryTitle(article.category.parent)}
                                        </a>
                                    </div>
                                )}

                                <div className="item">
                                    <a
                                        className="item"
                                        href={router.stateService.href('auth.blog.articles', {
                                            category: article.category.id
                                        })}
                                    >
                                        {getLocalizedCategoryTitle(article.category)}
                                    </a>
                                </div>
                            </div>
                        )}

                        <div className="article">
                            <div className="title">
                                {getLocalizedArticleProperty(article, 'title')}
                            </div>

                            <div className="tags">
                                {showLikes() && (
                                    <div className="tag likes">
                                        {t('POST_LIKES', { like_number: article.likes.length })}
                                    </div>
                                )}

                                {showComments() && (
                                    <div className="tag comments">
                                        {t('POST_COMMENTS', {
                                            comment_number: article.comments.length
                                        })}
                                    </div>
                                )}

                                {getArticleTimeToRead(article) && (
                                    <div className="tag time-to-read">
                                        {getArticleTimeToRead(article)}
                                    </div>
                                )}

                                {isBlogAdmin() && (
                                    <div className="tag edit-article" ng-click="editArticle()">
                                        {t('BLOG_EDIT')}
                                    </div>
                                )}
                            </div>

                            <div className="attachment">
                                {(isType('article') || isType('gallery')) &&
                                    getLocalizedArticleProperty(article, 'thumbnail') && (
                                        <div className="thumbnail">
                                            <img
                                                src={forgeBlogArticleContentAttachmentUrl(
                                                    getLocalizedArticleProperty(
                                                        article,
                                                        'thumbnail'
                                                    )
                                                )}
                                                alt={getLocalizedArticleProperty(article, 'title')}
                                            />
                                        </div>
                                    )}

                                {isType('video') && article.attachment && (
                                    <div className="video">
                                        {isVideoAttachment(article.attachment) && (
                                            <div className="video-player-container">
                                                <VideoPlayer
                                                    src={forgeBlogArticleContentAttachmentUrl(
                                                        article.attachment
                                                    )}
                                                    type={getVideoType(article.attachment.type)}
                                                    posterUrl={
                                                        getLocalizedArticleProperty(
                                                            article,
                                                            'thumbnail'
                                                        )
                                                            ? forgeBlogArticleContentAttachmentUrl(
                                                                  getLocalizedArticleProperty(
                                                                      article,
                                                                      'thumbnail'
                                                                  )
                                                              )
                                                            : null
                                                    }
                                                    files={getVideoFiles(article.attachment)}
                                                    videoState={getVideoState(article.attachment)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {isType('podcast') && article.attachment && (
                                    <div className="audio">
                                        {getLocalizedArticleProperty(article, 'thumbnail') && (
                                            <div className="thumbnail">
                                                <img
                                                    src={forgeBlogArticleContentAttachmentUrl(
                                                        getLocalizedArticleProperty(
                                                            article,
                                                            'thumbnail'
                                                        )
                                                    )}
                                                    alt={getLocalizedArticleProperty(
                                                        article,
                                                        'title'
                                                    )}
                                                />
                                            </div>
                                        )}

                                        {isAudioAttachment(article.attachment) && (
                                            <div className="video-player-container">
                                                <VideoPlayer
                                                    src={forgeBlogArticleContentAttachmentUrl(
                                                        article.attachment
                                                    )}
                                                    type={getVideoType(article.attachment.type)}
                                                    files={getVideoFiles(article.attachment)}
                                                    videoState={getVideoState(article.attachment)}
                                                    isAudio={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                    __html: getLocalizedArticleProperty(article, 'content')
                                }}
                            ></div>

                            {article.gallery_attachments.length > 0 && (
                                <div className="photo-grid">
                                    <PhotoAlbum
                                        photos={getPhotoGridObject(article.gallery_attachments)}
                                        layout={'rows'}
                                        spacing={2}
                                    />
                                </div>
                            )}

                            {showLikes() && (
                                <div className="actions">
                                    <button
                                        className={`action ${hasLiked(article) ? 'liked' : ''}`}
                                        onClick={() => toggleLike(article)}
                                    >
                                        {!hasLiked(article) ? (
                                            <div className="label">
                                                <i className="icon-like-1" aria-hidden="true"></i>
                                                {t('POST_LIKE')}
                                            </div>
                                        ) : (
                                            <div className="label">
                                                <i
                                                    className="icon-dislike-1"
                                                    aria-hidden="true"
                                                ></i>
                                                <span>{t('POST_UNLIKE')}</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            )}

                            <div className="files">
                                {getLocalizedArticleProperty(article, 'pdfs') &&
                                    getLocalizedArticleProperty(article, 'pdfs').map(
                                        (pdf, index) => {
                                            return (
                                                <div className="pdfs" key={`pdf_${index}`}>
                                                    <div className="pdf">
                                                        <div className="icon">
                                                            <button
                                                                onClick={() => {
                                                                    download(
                                                                        forgeBlogArticleContentAttachmentUrl(
                                                                            pdf
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <span
                                                                    className={attachmentIcon(
                                                                        pdf,
                                                                        'class'
                                                                    )}
                                                                    style={{
                                                                        color: attachmentIcon(
                                                                            pdf,
                                                                            'color'
                                                                        )
                                                                    }}
                                                                ></span>
                                                            </button>
                                                        </div>
                                                        <div className="name">
                                                            <span className="doc_name">
                                                                <button
                                                                    onClick={() => {
                                                                        download(
                                                                            forgeBlogArticleContentAttachmentUrl(
                                                                                pdf
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    {pdf.name}
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="actions">
                                                        <button
                                                            className="button grey-border small bold rounded"
                                                            onClick={() => {
                                                                download(
                                                                    forgeBlogArticleContentAttachmentUrl(
                                                                        pdf
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {t('POST_DOWNLOAD').toUpperCase()}
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                            </div>

                            {(showAuthor() || showDates()) && (
                                <div className="author">
                                    {showAuthor() && (
                                        <div className="avatar">
                                            <img
                                                src={forgeAvatarUrl(
                                                    article.user.image_profile_name
                                                )}
                                                alt={article.user.lastname}
                                            />
                                        </div>
                                    )}

                                    <div className="description">
                                        {showAuthor() && (
                                            <div className="username">
                                                {t('BLOG_ARTICLE_WRITTEN_BY')}{' '}
                                                <Username user={article.user} />
                                            </div>
                                        )}

                                        {showDates() && (
                                            <div className="meta">
                                                {t('BLOG_ARTICLE_PUBLISHED_AT')}{' '}
                                                {dateFormat(article.createdAt, 'dd/MM/yyyy')}{' '}
                                                {t('BLOG_ARTICLE_PUBLISHED_IN')}
                                                <a
                                                    className="item"
                                                    href={router.stateService.href(
                                                        'auth.blog.articles',
                                                        { category: article.category.id }
                                                    )}
                                                >
                                                    {getLocalizedCategoryTitle(article)}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {showComments() && (
                            <div className="article-comments-wrapper">
                                <div
                                    className={`comments ${article.comments.length > 0 ? 'has-comments' : ''}`}
                                >
                                    <div className="title">{t('SUB_COMMENTS')}</div>

                                    {article.comments.length > 0 && <Comments object={article} />}

                                    {!isPublicState() && (
                                        <Comment
                                            articleItem={article}
                                            submitCallback={commentSubmitCallbackHandler}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {relatedArticles && showNavigation && (
                    <div className="related-articles">
                        <div className="sticky">
                            <div className="title">{t('BLOG_SUGGESTED_ARTICLES')}</div>

                            <div className="articles">
                                {limitTo(relatedArticles, 4).map((article) => {
                                    return (
                                        <div
                                            className="article"
                                            key={`related_article_${article.id}`}
                                        >
                                            <Card article={article} isHome={false} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Article;
