import Modal from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { RichText, Input, Radio, Checkbox } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';

const HomeScreenFormModalContent = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrenTab] = useState('fr');
    const handleTabChange = (tab) => {
        setCurrenTab(tab);
    };
    const {
        contexts: {
            edit_home_screen: { currentHomeScreen }
        },
        closeModal
    } = useModal();
    const methods = useForm();

    const onFinish = (values) => {
        console.log('values', values);
    };

    const TABS = ['fr', 'en'];

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection tabs={TABS} onTabChange={handleTabChange}>
                    {TABS.map((tab) => {
                        if (currentTab !== tab) return;
                        return (
                            <>
                                <Input
                                    label={`Nom (${tab})`}
                                    name={`content[${tab}].name`}
                                    placeholder={`Nom (${tab})`}
                                    shouldUnregister={false}
                                />
                                <RichText
                                    name={`content[${tab}].content`}
                                    label={`Contenu (${tab})`}
                                    placeholder={`Contenu (${tab})`}
                                    shouldUnregister={false}
                                />
                            </>
                        );
                    })}
                </ModalFormSection>
                <ModalFormSection>
                    <Radio
                        name={'target'}
                        inline
                        label={t('ADMIN_MENU_HELPDESK_COMMON_TARGET_LABEL')}
                        options={[
                            {
                                label: t('ADMIN_MENU_HELPDESK_COMMON_TARGET_ALL_LABEL'),
                                value: 0
                            },
                            {
                                label: t('ADMIN_MENU_HELPDESK_COMMON_TARGET_INTERNAL_LABEL'),
                                value: 1
                            },
                            {
                                label: t('ADMIN_MENU_HELPDESK_COMMON_TARGET_EXTERNAL_LABEL'),
                                value: 2
                            },
                            {
                                label: t('ADMIN_MENU_HELPDESK_COMMON_TARGET_WORKSPACE_LABEL'),
                                value: 3
                            },
                            {
                                label: t('ADMIN_MENU_HELPDESK_COMMON_TARGET_LEVEL_LABEL'),
                                value: 4
                            }
                        ]}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <Checkbox
                        name={'isFirstConnectionHomescreen'}
                        label={t('ADMIN_MENU_HELPDESK_HOMESCREENS_FIRST_CONNECTION')}
                    />
                    <Input
                        name={'homescreen_position'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_POSITION_LABEL')}
                        type={'number'}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('assign_achievement');
                }}
            />
        </ModalForm>
    );
};

const HomeScreenFormModal = () => {
    return (
        <Modal
            name={'edit_home_screen'}
            title={"Créer ou éditer un écran d'accueil"}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true,
                isFullscreen: true
            }}>
            <HomeScreenFormModalContent />
        </Modal>
    );
};

export default HomeScreenFormModal;
