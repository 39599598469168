import { useHttp } from 'api/useHttp';

const useNotificationService = () => {
    const axios = useHttp();

    const getAllByUser = (limit = 40) => {
        return axios
            .get(`/notifications/by/user`, {
                params: {
                    page: 1,
                    limit
                }
            })
            .then((response) => response.data);
    };

    const getAllByUserByKey = (key, limit = 40) => {
        return axios
            .get(`/notifications/by/user/${key}`, {
                params: {
                    page: 1,
                    limit
                }
            })
            .then((response) => response.data);
    };

    const getAllStackedByUser = (limit = 40) => {
        return axios
            .get(`/notifications/stacked/by/user`, {
                params: {
                    page: 1,
                    limit
                }
            })
            .then((response) => response.data);
    };

    const markNotificationAsRead = (notification, key = null) => {
        return axios
            .put(`/notification`, { id: notification.id, key })
            .then((response) => response.data);
    };

    const markAllNotificationsAsRead = () => {
        return axios.put(`/notifications`);
    };

    return {
        getAllByUser,
        getAllByUserByKey,
        getAllStackedByUser,
        markNotificationAsRead,
        markAllNotificationsAsRead
    };
};

export default useNotificationService;
