import { useTranslation } from 'react-i18next';
import { Select as InputSelect } from 'components/Form/Inputs';
import { Button, Divider, Input, Space } from 'antd';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

const DropDownRender = (menu, field, values, setValues) => {
    const [customValue, setCustomValue] = useState('');
    const onCustomValueChange = (event) => {
        setCustomValue(event.target.value);
    };

    const addItem = (e) => {
        if (!customValue) return;
        e.preventDefault();
        setValues([
            ...values,
            {
                label: customValue,
                value: customValue
            }
        ]);
        setCustomValue('');
    };

    if (!field.has_dynamic_values) {
        return <>{menu}</>;
    }
    return (
        <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
                <Input
                    placeholder="Ajouter une valeur"
                    value={customValue}
                    onChange={onCustomValueChange}
                    onKeyDown={(e) => e.stopPropagation()}
                    style={{
                        width: '100%'
                    }}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Ajouter
                </Button>
            </Space>
        </>
    );
};

const Collection = ({ field }) => {
    const { t } = useTranslation();
    const [values, setValues] = useState(
        field.values.map((value) => ({
            label: value,
            value
        }))
    );
    return (
        <div className="custom_select">
            <label className="custom_text" htmlFor="{{ field.id }}">
                {field.label} {field.require && <span className="required">*</span>}
            </label>

            <div className="select">
                <InputSelect
                    mode={'multiple'}
                    name={`form.fields[${field.id}].value`}
                    placeholder={t('EDITOR_COLLECTION_SELECT_VALUE')}
                    options={values}
                    dropdownRender={(menu) => {
                        return DropDownRender(menu, field, values, setValues);
                    }}
                />
            </div>
            {field.explanation && <div className="explanation">{field.explanation}</div>}

            {field.has_limited_values_count && (
                <div className="limited-values-count">
                    {t('EDITOR_COLLECTION_LIMIT_X_VALUES', { total: field.limited_values_count })}
                </div>
            )}
        </div>
    );
};

export default Collection;
