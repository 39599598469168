import React from 'react';
import { usePost } from '../providers/PostProvider';
import { useTranslation } from 'react-i18next';
import PostHeader from './Partials/PostHeader';
import PostRecipient from './Partials/PostRecipient';
import PostDescription from './Partials/PostDescription';
import PostContent from './Partials/PostContent';
import PostReport from './Partials/PostReport';
import PostInfo from './Partials/PostInfo';
import PostActions from './Partials/PostActions';
import PostReview from './Partials/PostReview';
import PostComment from './Partials/PostComment';
import PostComments from './Partials/PostComments';

const PreviewPost = () => {
    const { post, isAllowToComment } = usePost();
    const { t } = useTranslation();

    return (
        <>
            <div class="post_wrapper">
                <div>
                    <PostHeader />
                </div>
                <div>
                    <PostRecipient />
                </div>
                <div>
                    <PostDescription />
                </div>
                <div>
                    <PostContent />
                </div>
                <div>
                    <PostReport />
                </div>

                {/* { hasFeedback() && !hideAll && <div className={`feedback_wrapper ${feedbackIsDone() ? 'done' : null}`}>
                    <PostFeedback />
                </div> }*/}

                <div class="post_misc">
                    <div>
                        <PostInfo />
                    </div>
                    <div>
                        <PostActions />
                    </div>
                </div>

                <div>
                    <PostReview />
                </div>
            </div>

            <div class="comment_wrapper">
                {isAllowToComment() && post.comments.length === 0 && (
                    <div class="post_first_comment">
                        <div class="icon">
                            <span class="icon-messages-bubble-double"></span>
                        </div>

                        <div class="text">{t('POST_FIRST_TO_COMMENT')}</div>
                    </div>
                )}
                <PostComments />

                <div class="post_misc">
                    <div>
                        <PostActions />
                    </div>
                    <div>
                        <PostInfo />
                    </div>
                </div>

                {isAllowToComment() && (
                    <div>
                        <PostComment />
                    </div>
                )}
            </div>
        </>
    );
};

export default PreviewPost;
