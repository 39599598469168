export default {
    language: 'fr',
    minimum_password_characters: 8,
    minimum_password_level: 5,
    logo_url: '/images/logo-ta-white.svg',
    logo_login_url: '/images/logo-ta-white.svg',
    logo_mobile_url: '/images/logo-ta-white.svg',
    blog_logo_url: '/images/logo-blog.png',
    show_channels: true,
    show_likes: true,
    login_is_email: true,
    allow_create_account: true,
    allow_cookies: true,
    show_notifications: true,
    theme_color: '#174987',
    is_v5: true,
    allow_export_ta: true,
    quill_colors: ['red', 'green'],
    has_tousambassadeurs: true,
    title: 'Mon App Transport',
    homeStateName: 'feed.posts',
    loginStateName: 'login',
    staticUrl: 'http://localhost:8081/api/',
    serverUrl: 'http://localhost:8081',
    frontUrl: 'http://localhost:8080',
    oneSignal: {
        app_id: 'aa4e20b8-6900-4621-90b3-97a758075036'
    },
    custom_blog_article_colors: ['#fff', '#000'],
    terms_url: 'http://blog.nexenture.fr/conditions-generales-dutilisation-tousnexenture/',
    pushed_campaign_positions: [1, 8],
    max_people_limit: 20,
    facebookCostPerClick: 1.6,
    linkedInCostPerClick: 2.5,
    twitterCostPerClick: 0.4,
    otherCostPerClick: 0.5,
    rewards_management: {
        product: {
            reward: 400,
            reward_message: 'Test message',
            reward_by_opportunity: 600,
            force_default_values: true
        }
    }
};
