import { orderBy, findIndex, concat, pullAt } from 'lodash-es';

export default function channelOrderByLatestActivity(array, activeChannelId) {
    let channels = orderBy(array, ['pinned', 'last_message.created_at'], ['desc', 'desc']);

    if (!activeChannelId) {
        return channels;
    }

    let activeChannelIndex = findIndex(channels, (channel) => {
        return channel.id === activeChannelId;
    });

    if (activeChannelIndex === -1) {
        return channels;
    }

    return concat(pullAt(channels, activeChannelIndex), channels);
}
