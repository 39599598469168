import {
    ModalForm,
    ModalFormBody,
    ModalFormFooter,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useForm } from 'components/Form/hooks';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import newTriggerFormSchema from '../schemas/newTriggerForm.schema.json';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useTriggerService from '../api/useTriggerService';
import { useAdminTreeContext } from 'pages/Admin/providers/AdminTreeProvider';
import { Checkbox, Input, Select } from 'components/Form/Inputs';
import { orderBy } from 'lodash';
import useTreeHook from 'hooks/useTree';
import useTagService from 'api/useTagService';
import useFolderService from 'api/useFolderService';
import { useAlert } from 'providers/AlertContext';
import useUtils from 'hooks/useUtils';
import { useMemo } from 'react';

const TriggerFormModalContent = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const {
        closeModal,
        contexts: { form_triggers: trigger }
    } = useModal();
    const { showToast } = useAlert();
    const { fullTree } = useAdminTreeContext();
    const { getOnlyCategory } = useTreeHook();
    const postAdminTrigger = useTriggerService();
    const { getAllAdminTags } = useTagService();
    const { getFolders } = useFolderService();
    const { arrayAsId } = useUtils();

    const { data: tags, isLoading: tagsLoading } = useQuery({
        queryKey: ['adminTriggerTags'],
        queryFn: () => getAllAdminTags()
    });

    const { data: folders, isLoading: foldersLoading } = useQuery({
        queryKey: ['adminTriggerFolders'],
        queryFn: () => getFolders()
    });

    const methods = useForm({
        schema: newTriggerFormSchema,
        defaultValues: {
            name: trigger?.name || '',
            tags: trigger?.tags?.map((_) => _.id) || [],
            enabled: trigger?.enabled || false,
            categories: trigger?.categories?.map((_) => _.id) || [],
            workspaces: trigger?.workspaces?.map((_) => _.id) || [],
            folders: trigger?.folders?.map((_) => _.id) || [],
            triggerWorkspaceInputs: trigger?.triggerWorkspaceInputs?.map((_) => _.id) || []
        }
    });

    const onFinish = (values) => {
        let method = trigger?.id ? 'putAdminTrigger' : 'postAdminTrigger';
        console.log('method', method);

        const payload = {
            ...(method === 'putAdminTrigger' && { id: trigger.id }),
            ...values
        };
        payload.tags = arrayAsId(payload.tags);
        payload.categories = arrayAsId(payload.categories);
        payload.workspaces = arrayAsId(payload.workspaces);
        payload.folders = arrayAsId(payload.folders);

        postAdminTrigger[method](payload)
            .then(() => {
                showToast({
                    type: 'success',
                    message: t(trigger ? 'ADMIN_TRIGGER_UPDATE_SUCCESS' : 'ADMIN_TRIGGER_CREATED')
                });

                queryClient.invalidateQueries('adminTriggers');

                methods.reset();
            })
            .catch((error) => {
                showToast({
                    type: 'error',
                    message: error.message
                });
                console.log('error', error);
            });

        closeModal('form_triggers');
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input name="name" label={t('ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL')} />

                    <Select
                        name="tags"
                        mode="multiple"
                        loading={tagsLoading}
                        label={t('ADMIN_TRIGGER_TAG')}
                        options={tags?.map((tag) => ({
                            label: tag.name,
                            value: tag.id
                        }))}
                    />

                    <Checkbox
                        label={t('ADMIN_TRIGGER_ENABLE')}
                        mode="multiple"
                        type="checkbox"
                        value={methods.watch('enabled')}
                        name="enabled"
                    />

                    <Select
                        name="categories"
                        label={t('ADMIN_TRIGGER_CATEGORIES')}
                        mode="multiple"
                        options={orderBy(getOnlyCategory(fullTree), ['name']).map((category) => {
                            return {
                                label: category.name,
                                value: category.id
                            };
                        })}
                    />

                    <Select
                        name="workspaces"
                        mode="multiple"
                        label={t('ADMIN_TRIGGER_WORKSPACE')}
                        options={orderBy(getOnlyCategory(fullTree), ['name']).map((category) => {
                            return {
                                label: category.name,
                                title: category.name,
                                options: orderBy(category.workspaces, ['name']).map((workspace) => {
                                    return {
                                        label: workspace.name,
                                        value: workspace.id
                                    };
                                })
                            };
                        })}
                    />

                    <Select
                        name="folders"
                        mode="multiple"
                        loading={foldersLoading}
                        label={t('ADMIN_TRIGGER_FOLDERS')}
                        options={folders?.map((folder) => ({
                            label: `${folder.name} (${folder?.workspace_name})`,
                            value: folder.id
                        }))}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter onCancel={() => closeModal('form_triggers')} />
        </ModalForm>
    );
};

const TriggerFormModal = () => {
    const { t } = useTranslation();
    const { contexts } = useModal();
    const { form_triggers: trigger } = contexts;
    const titleModal = useMemo(() => {
        return t(trigger ? 'ADMIN_EDIT_TRIGGER' : 'ADMIN_ADD_TRIGGER');
    }, [t, trigger]);

    return (
        <Modal
            name="form_triggers"
            title={titleModal}
            options={{
                hasPaddedContent: true
            }}>
            <TriggerFormModalContent />
        </Modal>
    );
};

export default TriggerFormModal;
