import { useFeedContext } from '../../providers/FeedProvider';
import { useTranslation } from 'react-i18next';
import useTree from '../../../../hooks/useTree';
import useRight from '../../../../hooks/useRight';
import useUser from '../../../../hooks/useUser';

const Modes = () => {
    const { folderId, workspaceId, categoryId, workspace, category } = useFeedContext();
    const { t } = useTranslation();
    const { isView, goToView } = useTree();
    const { getFeedDefaultView } = useUser();
    const { hasRole } = useRight();

    const categoryHasWorkspaceWithCalendarView = () => {
        return (
            category &&
            category.workspaces &&
            category.workspaces.some((workspace) => {
                return workspace.has_calendar_view;
            })
        );
    };

    const showFoldersButton = () => {
        return !hasRole('HIDE_FOLDER_MODE') && workspace && !workspace.hide_folder_mode;
    };

    if (!(folderId || workspaceId || categoryId)) return;

    return (
        <div className="modes">
            <a
                className={`mode ${isView(['posts', 'essentials', 'stacked']) ? 'active' : ''}`}
                href={goToView(getFeedDefaultView(), true)}>
                {t('FEED_VIEW_POSTS')}
            </a>

            {((workspace && workspace.has_calendar_view) ||
                (!workspace && category && categoryHasWorkspaceWithCalendarView()) ||
                isView('calendar')) && (
                <a
                    className={`mode ${isView('calendar') ? 'active' : ''}`}
                    href={goToView('calendar', true)}>
                    {t('FEED_VIEW_CALENDAR')}
                </a>
            )}

            {((workspace && workspace.has_table_view) ||
                (!workspace && category && categoryHasWorkspaceWithCalendarView()) ||
                isView('tables')) && (
                <a
                    className={`mode ${isView('tables') ? 'active' : ''}`}
                    href={goToView('tables', true)}>
                    {t('FEED_VIEW_TABLE')}
                </a>
            )}

            {((workspace && workspace.has_tile_view) || isView('tiles')) && (
                <a
                    className={`mode ${isView('tiles') ? 'active' : ''}`}
                    href={goToView('tiles', true)}>
                    {t('FEED_VIEW_TILE')}
                </a>
            )}

            {(showFoldersButton() || isView('folders')) && (
                <a
                    className={`mode ${isView('folders') ? 'active' : ''}`}
                    href={goToView('folders', true)}>
                    {t('FEED_VIEW_FOLDERS')}
                </a>
            )}
        </div>
    );
};

export default Modes;
