import React from 'react';
import { UIRouter, UIView, pushStateLocationPlugin } from '@uirouter/react';

import EventsWrapper from 'components/EventsWrapper/EventsWrapper';
import MainWrapper from 'components/MainWrapper/MainWrapper';
import TopProvider from '../providers/TopProvider';

import loginRoutes from 'pages/Login/config/routes';
import authRoutes from 'pages/Auth/config/routes';
import feedRoutes from 'pages/Feed/config/routes';
import phoneBookRoutes from 'pages/PhoneBook/config/routes';
import rankingRoutes from 'pages/Ranking/config/routes';
import campaignRoutes from 'pages/Campaigns/config/routes';
import chatRoutes from 'pages/Chat/config/routes';
import publicRoutes from 'pages/Public/config/routes';
import blogRoutes from 'pages/Blog/config/routes';
import profileRoutes from 'pages/Profile/config/routes';
import statRoutes from 'pages/Stats/config/routes';
import sharedDocumentsRoutes from 'pages/SharedDocuments/config/routes';
import adminRoutes from 'pages/Admin/config/routes';
import metricsRoutes from 'pages/Metrics/config/routes';
import notificationsRoutes from 'pages/Notifications/config/routes';
import dashboardRoutes from 'pages/Dashboard/config/routes';

const RoutingConfig = () => {
    const states = [
        ...loginRoutes,
        { name: 'otherwise', url: '/*path', redirectTo: 'login.home' },
        ...authRoutes,
        ...feedRoutes,
        ...phoneBookRoutes,
        ...rankingRoutes,
        ...campaignRoutes,
        ...chatRoutes,
        ...publicRoutes,
        ...blogRoutes,
        ...profileRoutes,
        ...statRoutes,
        ...sharedDocumentsRoutes,
        ...adminRoutes,
        ...metricsRoutes,
        ...notificationsRoutes,
        ...dashboardRoutes
    ];

    const plugins = [pushStateLocationPlugin];

    return (
        <UIRouter states={states} plugins={plugins}>
            <TopProvider>
                <EventsWrapper>
                    <MainWrapper>
                        <UIView />
                    </MainWrapper>
                </EventsWrapper>
            </TopProvider>
        </UIRouter>
    );
};

export default RoutingConfig;
