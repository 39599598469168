import WidgetsService from '../services/Widget';
import { first, isNil } from 'lodash-es';
import { useAlert } from 'providers/AlertContext';
import { useCallback, useEffect, useMemo, useState } from 'react';

const WIDGETS_SAVED_FOLDED_STATES_KEY = 'widgets.saved.folded.states';
const useWidgetComponent = (widget, options) => {
    const { showConfirm } = useAlert();
    const [isFolded, setIsFolded] = useState(false);
    const widgetService = new WidgetsService();

    useEffect(() => {
        setIsFolded(getSavedFoldedState());
    }, []);

    const getFieldValueForKey = (key, fields = null) => {
        if (fields) {
            return widgetService.getWidgetFieldValueForKey({ fields }, key);
        }

        return widgetService.getWidgetFieldValueForKey(widget, key);
    };

    const getStyleValueForKey = useCallback(
        (key, unit = null) => {
            if (!unit) {
                return widgetService.getWidgetStyleValueForKey(widget, key);
            }

            return `${widgetService.getWidgetStyleValueForKey(widget, key)}${unit}`;
        },
        [widget, widgetService]
    );

    const getIconForKey = (key, fields = null) => {
        let icon = getFirstFieldValueForKey(key, fields);

        if (!icon) {
            return null;
        }

        return `icon-${icon.value}`;
    };

    const configurationError = () => {
        showConfirm({
            title: 'Problème de configuration',
            text: "Vous n'avez pas accès à ce contenu."
        }).catch(() => {});
    };

    const getFirstFieldValueForKey = (key, fields = null) => {
        return first(getFieldValueForKey(key, fields));
    };

    const getRoundSize = () => {
        let roundSize = widgetService.getWidgetStyleValueForKey(options, 'roundSize');
        let hasCustomRoundSize = widgetService.getWidgetStyleValueForKey(widget, 'customRoundSize');

        if (hasCustomRoundSize) {
            roundSize = widgetService.getWidgetStyleValueForKey(widget, 'roundSize');
        }

        return `${roundSize}px`;
    };

    const isImageDisplayType = () => {
        let displayType = getFieldValueForKey('displayType');

        return displayType === 'displayImage';
    };

    const getSavedFoldedState = (defaultValue) => {
        if (!widget.id) {
            return defaultValue;
        }

        try {
            const savedFoldedStates = JSON.parse(
                localStorage.getItem(WIDGETS_SAVED_FOLDED_STATES_KEY)
            );

            if (!savedFoldedStates) {
                return defaultValue;
            }

            const savedFoldedState = savedFoldedStates[widget.id];

            return !isNil(savedFoldedState) ? savedFoldedState : defaultValue;
        } catch (error) {
            localStorage.removeItem(WIDGETS_SAVED_FOLDED_STATES_KEY);
            return defaultValue;
        }
    };

    const setSavedFoldedState = (state) => {
        if (!widget.id) {
            return;
        }

        setIsFolded(state);

        const savedFoldedStates = localStorage.getItem(WIDGETS_SAVED_FOLDED_STATES_KEY);

        localStorage.setItem(
            WIDGETS_SAVED_FOLDED_STATES_KEY,
            JSON.stringify({
                ...JSON.parse(savedFoldedStates),
                [widget.id]: state
            })
        );
    };

    const isFoldable = useMemo(() => {
        return !!getStyleValueForKey('isFoldable');
    }, [getStyleValueForKey]);

    const getWidgetStyle = () => {
        let style = {
            padding: `${getStyleValueForKey('marginTopSize')}px ${getStyleValueForKey('marginRightSize')}px ${getStyleValueForKey('marginBottomSize')}px ${getStyleValueForKey('marginLeftSize')}px`
        };

        let hasBgGradient = getStyleValueForKey('bgGradient');

        if (hasBgGradient) {
            let bgGradientType = getStyleValueForKey('bgGradientType');
            let bgGradientStartColor = getStyleValueForKey('bgGradientStartColor');
            let bgGradientEndColor = getStyleValueForKey('bgGradientEndColor');
            let bgGradientAngle = getStyleValueForKey('bgGradientAngle');

            let bgGradientStyle = null;

            if (bgGradientType === 'linear') {
                bgGradientStyle = `linear-gradient(${bgGradientAngle}deg, ${bgGradientStartColor}, ${bgGradientEndColor})`;
            } else {
                bgGradientStyle = `radial-gradient(${bgGradientStartColor}, ${bgGradientEndColor})`;
            }

            style = {
                ...style,
                'background-image': bgGradientStyle
            };
        } else {
            style = {
                ...style,
                'background-color': getStyleValueForKey('bgColor')
            };
        }

        return style;
    };

    return {
        getFieldValueForKey,
        getStyleValueForKey,
        getIconForKey,
        getRoundSize,
        isImageDisplayType,
        configurationError,
        setSavedFoldedState,
        getSavedFoldedState,
        getFirstFieldValueForKey,
        isFolded,
        setIsFolded,
        isFoldable,
        getWidgetStyle
    };
};

export default useWidgetComponent;
