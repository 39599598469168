import React from 'react';
import useRight from 'hooks/useRight';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useAchievementService from 'api/useAchievementService';
import dayjs from 'dayjs';
import { useConfig } from 'providers/ConfigProvider';
import useProfileActivityCampaignComponent from '../../../components/Profile/components/Activity/hooks/useProfileActivityCampaignComponent';
import AchievementBadge from 'components/Achievement/Achievement';
import { useModal } from 'providers/ModalContext';

const CAMPAIGN_TYPES = ['product', 'social', 'recruiting', 'survey'];

const Sidebar = ({ user }) => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { user: me } = useAuth();
    const { hasRanking, hasTrophies, hasCampaignType } = useRight();
    const { params } = useCurrentStateAndParams();
    const { getCampaignTypeName } = useProfileActivityCampaignComponent();
    const router = useRouter();
    const { openModal } = useModal();

    const { getMyPosition, getPosition } = useAchievementService();

    const { data: position } = useQuery({
        queryKey: [params.userId ? `user_position_${params.userId}` : 'my_position'],
        queryFn: () => {
            if (params.userId) {
                return getPosition(params.userId);
            }

            return getMyPosition();
        }
    });

    const isMe = () => {
        return user && me.id === user.id;
    };

    const getUserAchievementSubtitle = () => {
        if (isMe()) {
            return t('PROFILE_GAMIFICATION_BADGES_TITLE');
        }

        return t('PROFILE_GAMIFICATION_THEIR_BADGES_TITLE');
    };

    const getPointsSubtitle = () => {
        if (isMe()) {
            return t('YOU_EARNED');
        }

        return t('X_EARNED', { name: user.first_name });
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat().format(number);
    };

    const getRankingSubtitle = () => {
        if (isMe()) {
            return t('MY_RANK_WITHOUT_FILTER');
        }

        return t('THEIR_RANK_WITHOUT_FILTER');
    };

    const getPointsForPeriod = (format = true) => {
        let points = 0;

        if (position && position.main) {
            points = position.main.clicks
                ? position.main.clicks
                : position.main.total
                  ? position.main.total
                  : 0;
        }

        if (format) {
            return formatNumber(points);
        }

        return points;
    };

    const getRankingPeriod = () => {
        if (config.global_rank_filter) {
            if (config.global_rank_filter === 'CUSTOM') {
                return t(`FILTER_CUSTOM_WITH_DATE`, {
                    date: dayjs(config.has_rank_current_custom_start_date).format('DD/MM/YYYY')
                });
            } else {
                return t(`FILTER_${config.global_rank_filter}`);
            }
        } else {
            return t(`FILTER_ALL`);
        }
    };

    const openGlobalRankingModal = () => {
        openModal('display_rank');
    };

    return (
        <>
            <div
                className="title"
                dangerouslySetInnerHTML={{ __html: t('PROFILE_GAMIFICATION_TITLE') }}
            ></div>

            {hasRanking() && (
                <div className="points">
                    <div className="subtitle">{getPointsSubtitle()}</div>
                    <div className="title">
                        {getPointsForPeriod()} {t('POINTS')}
                    </div>
                    <div className="legend">{getRankingPeriod()}</div>
                </div>
            )}

            {hasTrophies() && user.user_achievement && (
                <div className="user-achievement">
                    <div className="subtitle">{getUserAchievementSubtitle()}</div>

                    <div className="achievement">
                        <div className="badge">
                            <AchievementBadge item={user.user_achievement.achievement} />
                        </div>

                        <div className="details">
                            <div className="name">{user.user_achievement.achievement.name}</div>
                            <div className="description">
                                {user.user_achievement.achievement.description}
                            </div>
                        </div>
                    </div>

                    {isMe() && (
                        <div className="actions">
                            <div className="action">
                                <a
                                    className="btn purple"
                                    href={router.stateService.href('auth.ranking.badges')}
                                >
                                    {t('PROFILE_BADGES_CHANGE_HIGHLIGHT_BADGE')}
                                </a>
                            </div>

                            <div className="action">
                                <a
                                    className="btn outline white"
                                    href={router.stateService.href('auth.ranking.badges')}
                                >
                                    {t('PROFILE_BADGES_GO_TO_BADGES')}
                                </a>
                            </div>
                        </div>
                    )}

                    {!isMe() && (
                        <div className="actions">
                            <div className="action">
                                <a
                                    className="btn outline white"
                                    ui-sref="auth.profile.badges"
                                    data-reload="false"
                                >
                                    {t('PROFILE_BADGES_GO_TO_THEIR_BADGES')}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {hasRanking() && position && (
                <div className="ranking">
                    <div className="subtitle">{getRankingSubtitle()}</div>
                    <div className="legend">{getRankingPeriod()}</div>

                    {position.main && (
                        <div className="title">
                            {position.main.rank}
                            {position.main.rank === 1 && <sup>{t('FIRST_SHORT')}</sup>}
                            {position.main.rank === 2 && <sup>{t('SECOND_SHORT')}</sup>}
                            {position.main.rank === 3 && <sup>{t('OTHER_NUMBER_SHORT')}</sup>}
                        </div>
                    )}

                    {!position.main && <div className="title">{t('UNKNOWN')}</div>}

                    <div className="campaigns">
                        {CAMPAIGN_TYPES.map((campaignType) => {
                            if (!hasCampaignType(campaignType)) return;

                            return (
                                <div className={`campaign ${campaignType}`} key={campaignType}>
                                    <div className="rank">
                                        {position[campaignType] && (
                                            <div className="number">
                                                {position[campaignType].rank}
                                                {position[campaignType].rank === 1 && (
                                                    <sup>{t('FIRST_SHORT')}</sup>
                                                )}
                                                {position[campaignType].rank === 2 && (
                                                    <sup>{t('SECOND_SHORT')}</sup>
                                                )}
                                                {position[campaignType].rank >= 3 && (
                                                    <sup>{t('OTHER_NUMBER_SHORT')}</sup>
                                                )}
                                            </div>
                                        )}

                                        {!position[campaignType] && (
                                            <div className="number">{t('UNKNOWN')}</div>
                                        )}
                                    </div>

                                    <div
                                        className="name"
                                        dangerouslySetInnerHTML={{
                                            __html: getCampaignTypeName(campaignType)
                                        }}
                                    ></div>
                                </div>
                            );
                        })}
                    </div>

                    {isMe() && (
                        <div className="actions">
                            <div className="action">
                                <button
                                    className="btn outline white"
                                    onClick={openGlobalRankingModal}
                                >
                                    {t('SEE_GENERAL_RANKING')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Sidebar;
