import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Input, Textarea, Checkbox, FileButton, ColorPicker } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';

const BrandFormModalContent = () => {
    const { t } = useTranslation();
    const {
        contexts: {
            add_edit_brand: { currentBrand }
        },
        closeModal
    } = useModal();
    const methods = useForm();

    const onFinish = (values) => {};

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input
                        name={'name'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL')}
                        value={currentBrand?.name}
                    />
                    <Input
                        name={'industry'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_INDUSTRY_LABEL')}
                        value={currentBrand?.industry}
                    />
                    <Input
                        name={'workforce'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_WORKFORCE_LABEL')}
                        type={'number'}
                        value={currentBrand?.workforce}
                    />
                    <Input
                        name={'career_website'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_CAREER_WEBSITE_LABEL')}
                        value={currentBrand?.career_website}
                    />
                    <Textarea
                        name={'description'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_DESCRIPTION_LABEL')}
                        value={currentBrand?.description}
                    />
                    <ColorPicker
                        name={'color'}
                        label={t('ADMIN_MENU_BRANDS_COLOR_LABEL')}
                        value={currentBrand?.color}
                    />
                    <Checkbox
                        name={'default'}
                        label={t('ADMIN_MENU_BRANDS_DEFAULT_LABEL')}
                        value={currentBrand?.default || false}
                    />
                    <FileButton name={'logo'} value={currentBrand?.logo ? [] : []}>
                        {t('ADMIN_MENU_BRANDS_FORM_LOGO_LABEL')}
                    </FileButton>
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_edit_brand');
                }}
            />
        </ModalForm>
    );
};

const BrandFormModal = () => {
    return (
        <Modal
            name={'add_edit_brand'}
            title={'Créer ou éditer une marque'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <BrandFormModalContent />
        </Modal>
    );
};

export default BrandFormModal;
