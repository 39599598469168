import React from 'react';

const LineSpace = () => {
    return (
        <div>
            <br />
        </div>
    );
};

export default LineSpace;
