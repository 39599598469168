import { useForm as useFormRHF } from 'react-hook-form';
import { ajvResolver } from '@hookform/resolvers/ajv';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const ajv = new Ajv();
addFormats(ajv);

/**
 * Hook to create a form with schema validation using Zod
 * @param {Object} schema The schema of the form
 * @param  {...any} props Additional props to pass to useForm
 * @link https://react-hook-form.com/api/useform
 * @returns
 */
const useForm = ({ schema, ...props } = {}) => {
    return useFormRHF({
        ...(schema ? { resolver: ajvResolver(schema) } : {}),
        mode: props.mode || 'onTouched',
        reValidateMode: props.reValidateMode || 'onChange',
        shouldFocusError: props.shouldFocusError || true,
        ...props
    });
};

export default useForm;
