import { Checkbox, Input } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

const LongTextOptions = ({ input }) => {
    const { t } = useTranslation();
    const { watch, getValues } = useFormContext();

    const hasLimitedCharacterCount = useMemo(() => {
        const values = getValues();
        return !isEmpty(getValues())
            ? values.workspace_form_inputs[input.uniqueId]?.has_limited_character_count
            : input?.has_limited_character_count;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].has_limited_character_count`), input]);

    return (
        <>
            <Checkbox
                name={`workspace_form_inputs[${input.uniqueId}].has_limited_character_count`}
                label={t('WORKSPACE_CUSTOM_FORM_FIELD_LIMIT_LONG_TEXT_CHARACTERS')}
                shouldUnregister={false}
            />
            {hasLimitedCharacterCount && (
                <Input
                    name={'limited_character_count'}
                    label={t('WORKSPACE_CUSTOM_FORM_FIELD_LIMIT_LONG_TEXT_CHARACTERS_LABEL')}
                    type={'number'}
                    min={0}
                    max={9999}
                    shouldUnregister={false}
                />
            )}
            <Checkbox
                name={`workspace_form_inputs[${input.uniqueId}].has_read_more`}
                label={t('ENABLE_READ_MORE_LONG_TEXT')}
                shouldUnregister={false}
            />
        </>
    );
};

export default LongTextOptions;
