import React, { useEffect, useState } from 'react';
import classes from 'classnames';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { useRouter } from '@uirouter/react';
import { useHttp } from '../../../api/useHttp';

const SharedDocument = ({ widget, options }) => {
    const router = useRouter();
    const axios = useHttp();

    const [unreadCount, setUnreadCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        isImageDisplayType,
        getIconForKey,
        getRoundSize
    } = useWidgetComponent(widget, options);

    useEffect(() => {
        const categoryId = getFieldValueForKey('category')[0].value;
        axios.get(`/shared/documents/notifications/${categoryId}`).then(({ data }) => {
            setUnreadCount(
                data.filter((notification) => {
                    return !notification.read_at;
                }).length
            );
            setNotifications(data);
        });
    }, []);

    const goToSharedDocumentCategory = () => {
        const categoryId = getFieldValueForKey('category')[0].value;
        router.stateService.go(
            'auth.sharedDocuments.categories',
            { categoryId },
            { reload: true, inherit: false }
        );
    };

    return (
        <>
            {!isImageDisplayType() && (
                <div
                    className={`missions ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToSharedDocumentCategory}
                >
                    <div
                        className={`icon ${classes(getStyleValueForKey('iconShape'))}`}
                        style={{
                            backgroundColor: getStyleValueForKey('iconBgColor'),
                            color: getStyleValueForKey('iconColor')
                        }}
                    >
                        <i className={getIconForKey('icon')}></i>
                    </div>

                    <div className={`title ${classes(getStyleValueForKey('textAlign'))}`}>
                        <div className="text">{getFieldValueForKey('title')}</div>
                        {unreadCount > 0 && <div className="chip">{unreadCount}</div>}
                    </div>
                </div>
            )}

            {!isImageDisplayType() && (
                <div
                    className={`missions ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToSharedDocumentCategory}
                >
                    <div
                        className="image"
                        style={{ borderRadius: getRoundSize(), overflow: 'hidden' }}
                    >
                        <img src={getFieldValueForKey('image')} />
                        {unreadCount > 0 && <div className="chip">{unreadCount}</div>}
                    </div>
                </div>
            )}
        </>
    );
};

export default SharedDocument;
