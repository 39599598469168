import { useTranslation } from 'react-i18next';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';

export const ALL_FILTERS = [
    {
        value: 'running',
        label: 'TA_CAMPAIGNS_IS_RUNNING'
    },
    {
        value: 'coming',
        label: 'TA_CAMPAIGNS_IS_COMING'
    },
    {
        value: 'closed',
        label: 'TA_CAMPAIGNS_IS_CLOSED'
    },
    {
        value: 'draft',
        label: 'TA_CAMPAIGNS_IS_DRAFT'
    }
];

const Filters = ({ extraFilters = [] }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { params } = useCurrentStateAndParams();

    const isActive = (status) => {
        return status === params.status;
    };

    return (
        <div className={'header'}>
            <ul className={'tabs'}>
                {ALL_FILTERS.concat(extraFilters).map((filer) => {
                    return (
                        <li
                            className={`tab ${isActive(filer.value) ? 'active' : ''}`}
                            key={filer.value}>
                            <a
                                href={router.stateService.href('.', {
                                    status: filer.value,
                                    page: 1
                                })}>
                                {t(filer.label)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Filters;
