import { useTranslation } from 'react-i18next';
import { useEditorContext } from '../providers/EditorProvider';
import useEditorComponent from '../hooks/useEditorComponent';

const EditorPagination = () => {
    const { t } = useTranslation();
    const { workspace, isPreview, currentPage, goToPreviousPage, goToNextPage } =
        useEditorContext();
    const { workspaceHasCustomForm, hasPages, getPages } = useEditorComponent(workspace);

    const hasPreviousPage = () => {
        return currentPage !== 1;
    };

    const hasNextPage = () => {
        return currentPage !== getPages().length;
    };

    if (!(workspaceHasCustomForm() && hasPages() && !isPreview)) {
        return null;
    }

    return (
        <div className="actions justify-center">
            <div className="group">
                <button
                    className="btn padded text-color"
                    disabled={!hasPreviousPage()}
                    onClick={goToPreviousPage}>
                    {t('EDITOR_PREVIOUS_TAB')}
                </button>

                <button
                    className="btn padded text-color"
                    disabled={!hasNextPage()}
                    onClick={goToNextPage}>
                    {t('EDITOR_NEXT_TAB')}
                </button>
            </div>
        </div>
    );
};

export default EditorPagination;
