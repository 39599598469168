import React, { useState } from 'react';
import { some } from 'lodash-es';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import { useConfig } from 'providers/ConfigProvider';
import { useQuery } from '@tanstack/react-query';
import useAchievementService from 'api/useAchievementService';
import dayjs from 'dayjs';
import useUser from 'hooks/useUser';
import Username from '../../../components/Username/Username';
import Placeholder from '../../../components/Placeholder/Placeholder';
import useRight from '../../../hooks/useRight';

const DisplayRankModalContent = () => {
    const { user: me } = useAuth();
    const { hasRole } = useRight();
    const { config } = useConfig();
    const { getAvatar } = useUser();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(config.global_rank_filter);
    const { closeModal } = useModal();

    const { getRank } = useAchievementService();

    const { data: users } = useQuery({
        queryKey: [`rank_${activeTab}`],
        queryFn: () => getRank(activeTab)
    });

    const inRank = () => {
        return (
            users &&
            some(users, (user) => {
                return user.user.id === me.id;
            })
        );
    };

    const isActive = (filter) => {
        return activeTab === filter;
    };

    const isGlobalRankConfigTab = () => {
        let globalRankFilter = config.global_rank_filter;

        if (!globalRankFilter) {
            globalRankFilter = 'ALL';
        }

        return isActive(globalRankFilter);
    };

    const gettCustom = () => {
        return t(`FILTER_CUSTOM_FROM_TO`, {
            from: dayjs(config.has_rank_current_custom_start_date).format('DD/MM/YYYY'),
            to: dayjs(config.has_rank_current_custom_end_date).format('DD/MM/YYYY')
        });
    };

    return (
        <>
            {(hasRole('HAS_RANK_ALL') ||
                hasRole('HAS_RANK_CURRENT_MONTH') ||
                hasRole('HAS_RANK_CURRENT_QUARTER') ||
                hasRole('HAS_RANK_CURRENT_YEAR') ||
                hasRole('HAS_RANK_CURRENT_CUSTOM')) && (
                <div className="filters">
                    {hasRole('HAS_RANK_ALL') && (
                        <div
                            onClick={() => {
                                setActiveTab('ALL');
                            }}
                            className={`filter ${isActive('ALL') ? 'active' : null}`}
                        >
                            {t('FILTER_ALL')}
                        </div>
                    )}
                    {hasRole('HAS_RANK_CURRENT_YEAR') && (
                        <div
                            onClick={() => {
                                setActiveTab('YEAR');
                            }}
                            className={`filter ${isActive('YEAR') ? 'active' : null}`}
                        >
                            {t('FILTER_YEAR')}
                        </div>
                    )}
                    {hasRole('HAS_RANK_CURRENT_QUARTER') && (
                        <div
                            onClick={() => {
                                setActiveTab('QUARTER');
                            }}
                            className={`filter ${isActive('QUARTER') ? 'active' : null}`}
                        >
                            {t('FILTER_QUARTER')}
                        </div>
                    )}
                    {hasRole('HAS_RANK_CURRENT_MONTH') && (
                        <div
                            onClick={() => {
                                setActiveTab('MONTH');
                            }}
                            className={`filter ${isActive('MONTH') ? 'active' : null}`}
                        >
                            {t('FILTER_MONTH')}
                        </div>
                    )}
                    {hasRole('HAS_RANK_CURRENT_CUSTOM') && (
                        <div
                            onClick={() => {
                                setActiveTab('CUSTOM');
                            }}
                            className={`filter ${isActive('CUSTOM') ? 'active' : null}`}
                        >
                            {gettCustom()}
                        </div>
                    )}
                </div>
            )}

            {users &&
                users.map((user) => {
                    return (
                        <div
                            className={`user ${user.user.id === me.id ? 'me' : null}`}
                            key={`rank_${user.user.id}`}
                        >
                            <div className="avatar">
                                <img src={getAvatar(user.user)} />
                            </div>
                            <div className="name">
                                <Username user={user.user} light={true} />
                            </div>
                            <div className="position">
                                {user.rank}
                                {user.rank == 1 && <sup>{t('FIRST_SHORT')}</sup>}
                                {user.rank == 2 && <sup>{t('SECOND_SHORT')}</sup>}
                                {user.rank >= 3 && <sup>{t('OTHER_NUMBER_SHORT')}</sup>}
                            </div>
                            {user.clicks && <div className="clicks">{user.clicks}</div>}
                            {(user.points || user.points >= 0) && (
                                <div className="total">{user.points}pts</div>
                            )}
                        </div>
                    );
                })}

            {!inRank() && isGlobalRankConfigTab() && me && <div className="separator">...</div>}

            {!inRank() && isGlobalRankConfigTab() && me && (
                <div className="user me not-in-rank">
                    <div className="avatar">
                        <img src={getAvatar(me)} />
                    </div>
                    <div className="name">
                        {me.last_name} {me.first_name}
                    </div>
                    <div className="position">
                        {me.rank}
                        {me.rank == 1 && <sup>{t('FIRST_SHORT')}</sup>}
                        {me.rank == 2 && <sup>{t('SECOND_SHORT')}</sup>}
                        {me.rank >= 3 && <sup>{t('OTHER_NUMBER_SHORT')}</sup>}
                    </div>
                    {me.clicks && me.clicks >= 0 && <div className="clicks">{me.clicks}</div>}
                    {me.total && me.total >= 0 && <div className="total">{me.total}pts</div>}
                </div>
            )}

            {!users && <Placeholder number={10} template={'rank'} />}

            <button
                type="button"
                onClick={() => {
                    closeModal('display_rank');
                }}
            >
                {t('POST_DIALOG_CLOSE')}
            </button>
        </>
    );
};

const DisplayRankModal = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();

    return (
        <div className={'display-rank-wrapper'}>
            <Modal
                name="display_rank"
                title={t('STATS_USERS_TITLE')}
                options={{ hasFlexContent: false, canScroll: true, isDesktopHeight80: true }}
            >
                <div className={'display-rank'}>
                    {isModalOpen('display_rank') && <DisplayRankModalContent />}
                </div>
            </Modal>
        </div>
    );
};

export default DisplayRankModal;
