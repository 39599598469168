import Admin from '../Admin';
import Users from '../components/Users/Users';
import ImportEmployees from '../components/ImportEmployees/ImportEmployees';
import DirectoryConfigs from '../components/DirectoryConfigs/DirectoryConfigs';
import OrganizationalUnits from '../components/OrganizationalUnits/OrganizationalUnits';
import Achievements from '../components/Achievements/Achievements';
import Ambassadors from '../components/Ambassadors/Ambassadors';
import AppSettings from '../components/AppSettings/AppSettings';
import Brands from '../components/Brands/Brands';
import Commands from '../components/Commands/Commands';
import Events from '../components/Events/Events';
import Levels from '../components/Levels/Levels';
import LevelsStats from '../components/LevelsStats/LevelsStats';
import Queries from '../components/Queries/Queries';
import Logs from '../components/Logs/Logs';
import Tree from '../components/Tree/Tree';
import Variables from '../components/Variables/Variables';
import Translations from '../components/Translations/Translations';
import ExportPdf from '../components/ExportPdf/ExportPdf';
import Tags from '../components/Tags/Tags';
import Triggers from '../components/Triggers/Triggers';
import Profile from '../components/Profile/Profile';
import Forms from '../components/Forms/Forms';
import Groups from '../components/Groups/Groups';
import Helpdesk from '../components/Helpdesk/Helpdesk';
import HomeScreens from '../components/Helpdesk/HomeScreens/HomeScreens';
import Springboards from '../components/Springboards/Springboards';
import NavBars from '../components/Navbars/NavBars';
import SponsorshipClient from '../components/SponsorshipClient/SponsorshipClient';
import AddForm from '../components/Forms/components/AddForm/AddForm';
import Rgpd from '../components/Rgpd/Rgpd';
import EditSpringboard from '../components/Springboards/components/EditSpringboard/EditSpringboard';

const routes = [
    { name: 'auth.admin', url: 'admin', abstract: true, component: Admin },
    {
        name: 'auth.admin.users',
        url: '/users?page&letter&q&onlyDisabled&onlyExternal&onlyAdmins&role',
        component: Users,
        params: {
            page: {
                type: 'int',
                value: 1,
                dynamic: true
            },
            onlyDisabled: {
                type: 'bool',
                value: false
            },
            onlyExternal: {
                type: 'bool',
                value: false
            },
            onlyAdmins: {
                type: 'bool',
                value: false
            }
        }
    },
    {
        name: 'auth.admin.import-employees',
        url: '/import-employees',
        component: ImportEmployees,
        permissions: {
            only: ['HAS_EMPLOYEE_ACCOUNT'],
            redirectTo: {
                default: 'auth.admin.users'
            }
        }
    },
    { name: 'auth.admin.directory-config', url: '/directory-configs', component: DirectoryConfigs },
    {
        name: 'auth.admin.organizational-units',
        url: '/organizational-units',
        component: OrganizationalUnits
    },
    { name: 'auth.admin.achievements', url: '/achievements', component: Achievements },
    { name: 'auth.admin.ambassadors', url: '/ambassadors', component: Ambassadors },
    {
        name: 'auth.admin.settings',
        url: '/settings?ou',
        component: AppSettings,
        permissions: {
            only: ['HAS_SUPER_ADMIN'],
            redirectTo: {
                default: 'auth.admin.users'
            }
        }
    },
    { name: 'auth.admin.brands', url: '/brands', component: Brands },
    {
        name: 'auth.admin.commands',
        url: '/commands',
        component: Commands,
        permissions: {
            only: ['HAS_SUPER_ADMIN'],
            redirectTo: {
                default: 'auth.admin.users'
            }
        }
    },
    { name: 'auth.admin.events', url: '/events', component: Events },
    {
        name: 'auth.admin.logs',
        url: '/logs?tab',
        component: Logs,
        params: {
            tab: {
                type: 'string',
                value: 'errors',
                dynamic: true
            }
        },
        permissions: {
            only: ['HAS_SUPER_ADMIN'],
            redirectTo: {
                default: 'auth.admin.users'
            }
        }
    },
    { name: 'auth.admin.levels', url: '/levels', component: Levels },
    {
        name: 'auth.admin.levels-stats',
        url: '/levels/stats?noEmployees&noRules&nameLevel',
        component: LevelsStats,
        params: {
            noEmployees: {
                type: 'bool',
                value: false
            },
            noRules: {
                type: 'bool',
                value: false
            },
            nameLevel: {
                type: 'string',
                value: ''
            }
        }
    },
    {
        name: 'auth.admin.queries',
        url: '/queries',
        component: Queries,
        permissions: {
            only: ['HAS_SUPER_ADMIN'],
            redirectTo: {
                default: 'auth.admin.users'
            }
        }
    },
    { name: 'auth.admin.tree', url: '/tree', component: Tree },
    { name: 'auth.admin.variables', url: '/variables', component: Variables },
    { name: 'auth.admin.translations', url: '/translations', component: Translations },
    { name: 'auth.admin.export-pdf', url: '/export-pdf', component: ExportPdf },
    {
        name: 'auth.admin.tags',
        url: '/tags/stats?isEssential&searchTag',
        component: Tags,
        params: {
            isEssential: {
                type: 'bool',
                value: false
            },
            searchTag: {
                type: 'string',
                value: ''
            }
        }
    },
    { name: 'auth.admin.profile', url: '/profile', component: Profile },
    { name: 'auth.admin.triggers', url: '/triggers', component: Triggers },
    { name: 'auth.admin.rgpd', url: '/rgpd', component: Rgpd },
    { name: 'auth.admin.forms', url: '/forms', component: Forms },
    { name: 'auth.admin.forms.add', url: '/add?tab', component: AddForm },
    { name: 'auth.admin.forms.edit', url: '/edit/{formId}?tab', component: AddForm },
    { name: 'auth.admin.groups', url: '/groups', component: Groups },
    { name: 'auth.admin.springboard', url: '/springboard', component: Springboards },
    {
        name: 'auth.admin.widgets',
        url: '/widgets/:position?tab',
        component: EditSpringboard,
        params: {
            position: {
                type: 'int',
                value: 3
            },
            tab: {
                type: 'string',
                value: 'widgets',
                dynamic: true
            }
        }
    },
    { name: 'auth.admin.navbar', url: '/navbar', component: NavBars },
    { name: 'auth.admin.helpdesk', url: '/helpdesk', component: Helpdesk },
    { name: 'auth.admin.helpdesk.homescreens', url: '/homescreens', component: HomeScreens },
    {
        name: 'auth.admin.sponsorship-client',
        url: '/sponsorship-client',
        component: SponsorshipClient
    }
];

export default routes;
