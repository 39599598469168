import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import usePassword from 'hooks/usePassword';

const External = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { isValid, getHints, getMinimumPasswordLevel } = usePassword();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordConfirm, setPasswordConfirm] = useState(null);
    const [showRegisterPassword, setShowRegisterPassword] = useState(false);
    const [showRegisterPasswordConfirm, setShowRegisterPasswordConfirm] = useState(false);
    const [userHasAcceptedTermsOfUse, setUserHasAcceptedTermsOfUse] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const canUserRegisterAccount = () => {
        return (
            firstName &&
            lastName &&
            email &&
            //this.isEmail(this.user.email) &&
            password &&
            passwordConfirm &&
            password === passwordConfirm &&
            isValid(password, false) &&
            userHasAcceptedTermsOfUse
        );
    };

    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="fields">
                <div className="field">
                    <label htmlFor="first-name" className="label">
                        {t('LOGIN_FIRST_NAME_PLACEHOLDER')}
                    </label>
                    <input
                        id="first-name"
                        className="input"
                        type="text"
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        placeholder={t('LOGIN_FORM_FIRST_NAME_PLACEHOLDER')}
                    />
                </div>

                <div className="field">
                    <label htmlFor="name" className="label">
                        {t('LOGIN_LAST_NAME_PLACEHOLDER')}
                    </label>
                    <input
                        id="name"
                        className="input"
                        type="text"
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        placeholder={t('LOGIN_FORM_LAST_NAME_PLACEHOLDER')}
                    />
                </div>

                <div className="field">
                    <label htmlFor="email" className="label">
                        {t('LOGIN_LOGIN_PLACEHOLDER')}
                    </label>
                    <input
                        id="email"
                        className="input"
                        type="email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder={t('LOGIN_FORM_EMAIL_PLACEHOLDER')}
                    />
                </div>

                <div className="field">
                    <label htmlFor="password" className="label">
                        {t('LOGIN_PASSWORD_PLACEHOLDER')}
                    </label>

                    <div className="password">
                        <input
                            id="password"
                            className="input"
                            type={showRegisterPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                isValid(e.target.value);
                            }}
                            placeholder={t('LOGIN_FORM_PASSWORD_PLACEHOLDER')}
                        />
                        <button
                            type="button"
                            className="show-password"
                            onClick={() => {
                                setShowRegisterPassword(!showRegisterPassword);
                            }}
                        >
                            <span>{t(showRegisterPassword ? 'HIDE' : 'SHOW').toUpperCase()}</span>
                        </button>
                    </div>
                </div>

                <div className="field">
                    <ul className="tips light password">
                        {config.MANAGE_PASSWORD_RULES_FROM_ADMIN && (
                            <li className="hint">
                                {t('PASSWORD_ADVICE', {
                                    minimumPasswordLevel: getMinimumPasswordLevel()
                                })}
                            </li>
                        )}

                        {getHints().map((passwordHint) => {
                            return (
                                <li
                                    className={`hint ${passwordHint.matched ? 'is-matched' : null}`}
                                >
                                    <div className="pictogram">
                                        <i
                                            className={
                                                !passwordHint.matched
                                                    ? 'icon-remove-circle'
                                                    : 'icon-check-circle-1-filled'
                                            }
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="text">{passwordHint.description}</div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="field">
                    <label htmlFor="password-check" className="label">
                        {t('LOGIN_PASSWORD_PLACEHOLDER_CHECK')}
                    </label>
                    <div className="password">
                        <input
                            id="password"
                            className="input"
                            type={showRegisterPasswordConfirm ? 'text' : 'password'}
                            onChange={(e) => {
                                setPasswordConfirm(e.target.value);
                            }}
                            placeholder={t('LOGIN_FORM_PASSWORD_CHECK_PLACEHOLDER')}
                        />

                        <button
                            type="button"
                            className="show-password"
                            onClick={() => {
                                setShowRegisterPasswordConfirm(!showRegisterPasswordConfirm);
                            }}
                        >
                            <span>
                                {t(showRegisterPasswordConfirm ? 'HIDE' : 'SHOW').toUpperCase()}
                            </span>
                        </button>
                    </div>
                </div>

                <div className="field">
                    <div className="input-with-label">
                        <input
                            className="switch"
                            type="checkbox"
                            id="terms-of-use"
                            onChange={(e) => {
                                setUserHasAcceptedTermsOfUse(e.target.checked);
                            }}
                        />

                        <label className="label" htmlFor="terms-of-use">
                            {t('LOGIN_AGREE')} (
                            <a className="link" target="_blank" href={config.terms_url}>
                                {t('LOGIN_READ_TERMS')}
                            </a>
                            )
                        </label>
                    </div>
                </div>
            </div>

            <div className="actions">
                <button className="btn hl" type={'submit'} disabled={!canUserRegisterAccount()}>
                    {t('LOGIN_CREATE_MY_ACCOUNT_TEXT')}
                </button>
            </div>
        </form>
    );
};

export default External;
