import { useHttp } from 'api/useHttp';

const useWorkspaceFormService = () => {
    const axios = useHttp();

    const saveAll = (workspaceForm) => {
        return axios
            .post('/workspaces/forms', {
                workspaceForm: { ...workspaceForm, workspaces: null },
                workspaces: workspaceForm.workspaces
            })
            .then((response) => response.data);
    };

    const importWorkspaceForm = (json) => {
        return axios
            .post('/workspaces/forms/import', {
                jsonWorkspaceForm: json
            })
            .then((response) => response.data);
    };

    return {
        saveAll,
        importWorkspaceForm
    };
};

export default useWorkspaceFormService;
