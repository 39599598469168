import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';

const NavBars = () => {
    const NAVBARS = [
        {
            position: 2,
            name: 'Menu pour les utilisateurs connectés'
        },
        {
            position: 1,
            name: 'Menu pour les utilisateurs non-connectés'
        }
    ];

    const COLUMNS = [
        {
            id: 'name',
            header: 'Nom du menu',
            sortable: true
        }
    ];
    return (
        <>
            <AdminHeader title={'Liste des menus'} />

            <Table
                columns={COLUMNS}
                data={NAVBARS}
                isLoading={false}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Gérer le menu',
                        handler: (row) => {
                            console.log(row);
                        }
                    },
                    {
                        label: 'Gérer les options',
                        handler: (row) => {
                            console.log(row);
                        }
                    }
                ]}
            />
        </>
    );
};

export default NavBars;
