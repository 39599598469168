import { useHttp } from './useHttp';

const useLevelService = () => {
    const axios = useHttp();

    const getLevelConfigs = (params) => {
        return axios.get(`/levels/configs`).then((response) => response.data);
    };

    const getLevels = (index = null) => {
        return axios.get(`/levels?index=${index}`).then((response) => response.data);
    };

    const getLevelsDetails = (params) => {
        return axios.get('/levels/details').then((response) => response.data);
    };

    const getAdminLevelRights = () => {
        return axios.get(`/admin/levels/rights`).then((response) => response.data);
    };

    const putLevelConfig = (levelConfig) => {
        return axios
            .put(`/levels/${levelConfig.id}/configs`, { level_config: levelConfig })
            .then((response) => response.data);
    };

    const toggleEnable = (id) => {
        return axios.put(`/levels/${id}/right/enable`).then((response) => response.data);
    };

    const postLevelConfig = (levelConfig) => {
        return axios
            .post(`/levels/configs`, { level_config: levelConfig })
            .then((response) => response.data);
    };

    const deleteLevelConfig = (levelConfig) => {
        return axios.delete(`/levels/${levelConfig.id}/configs`).then((response) => response.data);
    };

    const getLevelConfigsByLevelsUsersCounts = () => {
        return axios.get(`/levels/configs/levels/users/counts`).then((response) => response.data);
    };

    return {
        getLevelConfigs,
        getLevelsDetails,
        getLevels,
        getAdminLevelRights,
        putLevelConfig,
        toggleEnable,
        postLevelConfig,
        deleteLevelConfig,
        getLevelConfigsByLevelsUsersCounts
    };
};

export default useLevelService;
