import { forEach, orderBy } from 'lodash-es';

export default function mergeAuthorizedWorkspaceRulesByUserWithTree(
    authorizedWorkspaceRulesByUser,
    tree
) {
    forEach(authorizedWorkspaceRulesByUser, ({ workspaceRules = [] }, categoryId) => {
        let category = tree.find((category) => {
            return category.id === parseInt(categoryId);
        });

        if (!category) {
            tree.push({
                ...workspaceRules[0].workspace.category,
                workspaces: workspaceRules.map((workspaceRule) => {
                    return {
                        workspace: workspaceRule.workspace
                    };
                })
            });
        } else {
            workspaceRules.forEach((workspaceRule) => {
                let foundIndex = category.workspaces.findIndex((workspaceData) => {
                    return workspaceData.workspace.id === workspaceRule.workspace.id;
                });

                if (foundIndex < 0) {
                    category.workspaces.push({
                        workspace: workspaceRule.workspace
                    });
                } else {
                    category.workspaces[foundIndex] = {
                        workspace: workspaceRule.workspace
                    };
                }
            });
        }
    });

    return orderBy(
        tree.map((category) => {
            return {
                ...category,
                workspaces: orderBy(category.workspaces, ['position'], ['asc'])
            };
        }),
        ['position'],
        ['asc']
    );
}
