import { useQuery } from '@tanstack/react-query';
import { useRouter } from '@uirouter/react';
import useAppSettingService from 'api/useAppSettingService';
import useCampaignService from 'api/useCampaignService';
import useRight from 'hooks/useRight';
import useWorkspacesService from 'pages/Dashboard/api/useWorkspacesService';
import { useAlert } from 'providers/AlertContext';
import { useAuth } from 'providers/AuthContext';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DashboardHome = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const router = useRouter();
    const { showToast } = useAlert();
    const { hasRole, TAViewAll } = useRight();

    const isMissions = router.globals.current.name.includes('missions');

    const [customFormValue, setCustomFormValue] = useState('');

    const { getCountRunning } = useCampaignService();
    const { getAllWorkspaceForms } = useWorkspacesService();
    const { getTextConfig, saveTextConfig } = useAppSettingService();
    const { data: countRunning, isLoading } = useQuery({
        queryKey: `countRunning`,
        queryFn: () => getCountRunning(TAViewAll)
    });
    const { data: allWorkspaceForms } = useQuery({
        queryKey: `allWorkspaceForms`,
        queryFn: () => getAllWorkspaceForms()
    });
    const { data: textConfigCustomForm } = useQuery({
        queryKey: 'textConfig',
        queryFn: () => getTextConfig('MISSIONS_CUSTOM_FORM')
    });

    if (isLoading) return <></>;

    const saveSelectedForm = (formId) => {
        saveTextConfig({
            name: 'MISSIONS_CUSTOM_FORM',
            value: Number(formId)
        }).then(() => {
            showToast('success', 'Le formulaire a été lié aux missions avec succès');
        });
    };

    return (
        <div className="ta_global ta_admin">
            <div className="header">
                <h1>{t('TA_DASHBOARD_TITLE')}</h1>
            </div>

            {!isMissions && (
                <div className="modules" hidden={isLoading}>
                    <div className="wrapper-module">
                        <div
                            onClick={() =>
                                router.stateService.go('auth.dashboard.campaigns.feed', {
                                    type: 'product',
                                    status: 'running'
                                })
                            }
                            className="module products">
                            <div className="title">
                                Tous Vendeurs{' '}
                                <span
                                    hidden={
                                        !['ROLE_SUPER_PRODUCT'].every((role) =>
                                            user.roles.includes(role)
                                        )
                                    }>
                                    (Super administrateur)
                                </span>
                            </div>
                            <div className="campaigns">
                                <span className="number">{countRunning?.product}</span>
                                <span className="text">
                                    campagne
                                    <br />
                                    en cours
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="wrapper-module">
                        <div
                            onClick={() =>
                                router.stateService.go('auth.dashboard.campaigns.feed', {
                                    type: 'social',
                                    status: 'running'
                                })
                            }
                            className="module social">
                            <div className="title">
                                Tous Influenceurs{' '}
                                <span
                                    hidden={
                                        !['ROLE_SUPER_RS'].every((role) =>
                                            user.roles.includes(role)
                                        )
                                    }>
                                    (Super administrateur)
                                </span>
                            </div>
                            <div className="campaigns">
                                <span className="number">{countRunning?.social}</span>
                                <span className="text">
                                    campagne
                                    <br />
                                    en cours
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-module">
                        <div
                            onClick={() =>
                                router.stateService.go('auth.dashboard.campaigns.feed', {
                                    type: 'recruiting',
                                    status: 'running'
                                })
                            }
                            className="module recruiting">
                            <div className="title">
                                Tous Recruteurs{' '}
                                <span
                                    hidden={
                                        !['ROLE_SUPER_RECRUITING'].every((role) =>
                                            user.roles.includes(role)
                                        )
                                    }>
                                    (Super administrateur)
                                </span>
                            </div>
                            <div className="campaigns">
                                <span className="number">{countRunning?.recruiting}</span>
                                <span className="text">
                                    campagne
                                    <br />
                                    en cours
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-module">
                        <div
                            onClick={() =>
                                router.stateService.go('auth.dashboard.campaigns.feed', {
                                    type: 'survey',
                                    status: 'running'
                                })
                            }
                            className="module survey">
                            <div className="title">
                                Tous Acteurs{' '}
                                <span
                                    hidden={
                                        !['ROLE_SUPER_SURVEY'].every((role) =>
                                            user.roles.includes(role)
                                        )
                                    }>
                                    (Super administrateur)
                                </span>
                            </div>
                            <div className="campaigns">
                                <span className="number">{countRunning?.survey}</span>
                                <span className="text">
                                    campagne
                                    <br />
                                    en cours
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isMissions && ['ROLE_SUPER_MISSION'].every((role) => user.roles.includes(role)) && (
                <div className="modules">
                    <div className="wrapper-module">
                        <div
                            onClick={() =>
                                router.stateService.go('auth.dashboard.campaigns.feed', {
                                    type: 'mission',
                                    status: 'running'
                                })
                            }
                            className="module mission">
                            <div className="title">
                                {t('MISSIONS')}{' '}
                                <span
                                    hidden={
                                        !['ROLE_SUPER_MISSION'].every((role) =>
                                            user.roles.includes(role)
                                        )
                                    }>
                                    (Super administrateur)
                                </span>
                            </div>

                            <div className="campaigns">
                                <span className="number">{countRunning?.mission}</span>
                                <span className="text">
                                    {t('MISSIONS')}
                                    <br />
                                    en cours
                                </span>
                            </div>
                        </div>
                    </div>

                    {hasRole('HAS_MISSIONS') &&
                        ['ROLE_SUPER_MISSION'].every((role) => user.roles.includes(role)) && (
                            <div className="wrapper-module missions">
                                <div className="field">
                                    <label className="label">
                                        Formulaire personnalisé / CR visites de conformités
                                    </label>

                                    <select
                                        className="input"
                                        disabled={isLoading}
                                        onChange={(e) => setCustomFormValue(e.target.value)}>
                                        <option value="">
                                            Sélectionner un formulaire personnalisé
                                        </option>

                                        {allWorkspaceForms?.map((form) => (
                                            <option
                                                key={form.id}
                                                value={form.id}
                                                selected={
                                                    Number(textConfigCustomForm?.value) ===
                                                    Number(form.id)
                                                        ? 'selected'
                                                        : ''
                                                }>
                                                {form.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="actions">
                                    <button
                                        className="select-mission button grey"
                                        onClick={() => {
                                            saveSelectedForm(customFormValue);
                                        }}>
                                        Lier ce formulaire personnalisé aux missions
                                    </button>
                                </div>
                            </div>
                        )}
                </div>
            )}

            {!isMissions &&
                [
                    'ROLE_RS',
                    'ROLE_PRODUCT',
                    'ROLE_RECRUITING',
                    'ROLE_SURVEY',
                    'ROLE_SUPER_RS',
                    'ROLE_SUPER_PRODUCT',
                    'ROLE_SUPER_RECRUITING',
                    'ROLE_SUPER_SURVEY'
                ].every((role) => user.roles.includes(role)) && (
                    <div className="actions">
                        <a
                            href={router.stateService.href('auth.dashboard.campaigns.create', {
                                type: 'product'
                            })}
                            className="button grey outline">
                            Créer une campagne
                        </a>
                    </div>
                )}

            {isMissions && ['ROLE_SUPER_MISSION'].every((role) => user.roles.includes(role)) && (
                <div className="actions">
                    <a
                        href={router.stateService.href('auth.dashboard.missions.create', {
                            type: 'mission'
                        })}
                        className="button grey outline">
                        Créer une mission
                    </a>
                </div>
            )}
        </div>
    );
};

export default DashboardHome;
