import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import AdminSpringboardProvider, {
    useAdminSpringboardContext
} from './providers/AdminSpringboardProvider';
import useSpringboardComponent from './hooks/useSpringboardComponent';

const SpringboardsContent = () => {
    const { springboards, isLoading } = useAdminSpringboardContext();
    const router = useRouter();
    const { getTranslatedPosition } = useSpringboardComponent();

    const COLUMNS = [
        {
            id: 'position',
            header: "Emplacement de l'ancre",
            sortable: true,
            cell: ({ cell }) => getTranslatedPosition(cell.getValue())
        }
    ];

    return (
        <>
            <AdminHeader title={'Liste des ancres'} />

            <Table
                columns={COLUMNS}
                data={springboards}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Gérer les widgets',
                        handler: (row) => {
                            router.stateService.go('auth.admin.widgets', {
                                position: row.position,
                                tab: 'widgets'
                            });
                        }
                    },
                    {
                        label: 'Gérer les options',
                        handler: (row) => {
                            router.stateService.go('auth.admin.widgets', {
                                position: row.position,
                                tab: 'options'
                            });
                        }
                    }
                ]}
            />
        </>
    );
};

const Springboards = () => {
    return (
        <AdminSpringboardProvider>
            <SpringboardsContent />
        </AdminSpringboardProvider>
    );
};

export default Springboards;
