const Title = ({ field }) => {
    return (
        <div className="form__actions custom_title">
            <div
                dangerouslySetInnerHTML={{
                    __html: field.label
                }}></div>
        </div>
    );
};

export default Title;
