import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import RealTimeDate from 'components/RealTimeDate/RealTimeDate';
import { useRouter } from '@uirouter/react';
import Comments from 'components/Comments/Comments';
import Comment from 'components/Comment/Comment';

const Product = ({ activity }) => {
    const { t } = useTranslation();
    const { getAvatar } = useUser();
    const router = useRouter();
    return (
        <>
            <div className={`card ${activity.type}`}>
                <div className="badge">
                    <i className="fa fa-cube" aria-hidden="true"></i>
                </div>

                <div className="content">
                    <div className="campaign">{activity.campaign.title}</div>
                    <div className="text">
                        {t('TA_ACTIVITIES_PRODUCT_TITLE', {
                            firstname: activity.submit.user.first_name,
                            name: activity.submit.user.last_name
                        })}
                    </div>
                </div>

                <div className="meta">
                    <div className="date">
                        <RealTimeDate date={activity.submit.row.created_at} />
                    </div>

                    <div className="avatar">
                        <img src={getAvatar(activity.submit.row.user)} />
                    </div>
                </div>
            </div>

            {activity?.submit?.hasAuthorization && (
                <div className="submit">
                    {activity?.submit?.user_reward?.reward && (
                        <>
                            <div className="title">{t('TA_ACTIVITY_REWARD_INFORMATION')}</div>

                            <div className="referer">
                                <div
                                    className="reward"
                                    translate="TA_ACTIVITY_PRODUCT_POINTS"
                                    translate-values="{ points: activity.submit.user_reward.reward }"></div>

                                <div className="actions">
                                    <button
                                        className="button"
                                        ng-click="rewardUser(activity.submit.user.id, activity.submit, activity.campaign)">
                                        <i className="fa fa-money" aria-hidden="true"></i>{' '}
                                        {t('EDIT_USER_REWARD')}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="title">{t('TA_ACTIVITY_PRODUCT_SUBMIT_INFORMATION')}</div>

                    <div className="infos">
                        {activity?.submit?.answers &&
                            activity.submit.answers.map((answer) => {
                                return (
                                    <div className="info" key={answer.label}>
                                        <div className="label">{answer.label} :</div>
                                        <div className="value">{t(answer.value)}</div>
                                    </div>
                                );
                            })}

                        {activity.submit.email &&
                            (!activity?.submit?.answers ||
                                activity?.submit?.answers?.length === 0) && (
                                <div className="info">
                                    <div className="label">{t(activity.submit.email)}</div>
                                </div>
                            )}
                    </div>

                    <div className="title">{t('TA_ACTIVITY_USER_INFORMATION')}</div>

                    <div className="referer">
                        <div className="user">
                            <div className="avatar">
                                <img src={getAvatar(activity.submit.row.user)} />
                            </div>

                            <div className="info">
                                <div className="name">
                                    {activity.submit.row.user.first_name}{' '}
                                    {activity.submit.row.user.last_name}
                                </div>
                                <div className="job">{activity.submit.row.user.job}</div>
                            </div>
                        </div>

                        <div className="actions">
                            <a
                                href={router.stateService.href('auth.profile', {
                                    userId: activity.submit.row.user.id
                                })}
                                className="button">
                                <i className="fa fa-search" aria-hidden="true"></i>{' '}
                                {t('TA_ACTIVITY_VIEW_PROFILE')}
                            </a>
                        </div>
                    </div>

                    <div className="title">
                        {t('TA_ACTIVITY_KEEP_UPTODATE')} {activity.submit.row.user.first_name}{' '}
                        {activity.submit.row.user.last_name}
                    </div>

                    <Comments className="comments" object={activity}></Comments>

                    <div className="post_add-comment">
                        <Comment
                            activityItem={activity}
                            submitCallback={
                                () => {}
                                //commentSubmitCallbackHandler
                            }></Comment>
                    </div>
                </div>
            )}

            {!activity.submit.hasAuthorization && (
                <div className="submit">
                    <div className="text" translate="TA_ACTIVITIES_RECRUITING_ANO"></div>
                </div>
            )}
        </>
    );
};

export default Product;
