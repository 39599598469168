import React from 'react';
import useContent from 'hooks/useContent';

const Recipients = ({ input }) => {
    const { formatRecipientsContent } = useContent();
    return (
        <>
            {input.workspace_form_input.label_render !== '' && input.recipients.length > 0 && (
                <div className="render_label inline">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}
            {input.recipients.length > 0 && (
                <div
                    className={`render_content inline ${input.workspace_form_input.label.toLowerCase().split(' ').join('_')}`}
                    dangerouslySetInnerHTML={{ __html: formatRecipientsContent(input.recipients) }}
                ></div>
            )}
        </>
    );
};

export default Recipients;
