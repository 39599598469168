import {
    isArray,
    mapValues,
    orderBy,
    isNil,
    forEach,
    includes,
    every,
    set,
    merge,
    unset,
    isObject,
    find,
    values
} from 'lodash-es';

const useUtils = () => {
    const getFirstLetter = (string) => {
        if (!string) {
            return '';
        }

        return String.fromCodePoint(string.codePointAt(0));
    };

    const orderByPosition = (tree) => {
        const workspaceWithCategorySiblingsOffset = 1000;

        const fn = (item) => {
            if (!isArray(item.workspaces)) {
                item.workspaces = mapValues(item.workspaces, (workspaceOrCategory) => {
                    if (workspaceOrCategory.category) {
                        workspaceOrCategory = orderByPosition(workspaceOrCategory);
                    }

                    return workspaceOrCategory;
                });

                item.workspaces = orderBy(
                    item.workspaces,
                    [
                        (workspaceOrCategory) => {
                            if (workspaceOrCategory.workspace_data) {
                                return (
                                    workspaceWithCategorySiblingsOffset +
                                    workspaceOrCategory.workspace_data.workspace.position
                                );
                            }

                            return (
                                workspaceOrCategory?.category?.position ||
                                workspaceOrCategory?.category?.order
                            );
                        }
                    ],
                    ['asc']
                );
            } else {
                item.workspaces = orderBy(
                    item.workspaces,
                    (workspace) => {
                        return workspace.workspace_data.workspace.position;
                    },
                    ['asc']
                );
            }

            return item;
        };

        return orderBy(
            mapValues(tree, (item) => {
                return fn(item);
            }),
            [
                (item) => {
                    return !isNil(item?.category?.position)
                        ? item.category.position
                        : item?.category?.order;
                }
            ],
            ['asc']
        );
    };

    const removeEmptyTopLevelCategories = (tree) => {
        forEach(tree, (item, key) => {
            if (includes(key, 'cat_')) {
                if (!isArray(item.workspaces)) {
                    let itemHasOnlyChildCategories = every(item.workspaces, (item, key) => {
                        return includes(key, 'cat_');
                    });

                    if (itemHasOnlyChildCategories) {
                        forEach(item.workspaces, (child) => {
                            set(child, ['category', 'hasHiddenParent'], true);
                        });

                        merge(tree, item.workspaces);
                        unset(tree, key);
                    }
                }

                removeEmptyTopLevelCategories(item.workspaces);
            }
        });

        return tree;
    };

    const orderTopLevelFoldersByName = (folders) => {
        return orderBy(folders, (folderWithChildren) => {
            return folderWithChildren[0].name.toLowerCase();
        });
    };

    const orderFoldersByName = (folders) => {
        folders = values(folders);
        folders.shift();

        return orderBy(folders, (folderWithChildren) => {
            return folderWithChildren[0].name.toLowerCase();
        });
    };

    const arrayAsId = (arr) => {
        if (!arr) return;
        return arr.map((id) => {
            return {
                id
            };
        });
    };

    return {
        getFirstLetter,
        orderByPosition,
        removeEmptyTopLevelCategories,
        arrayAsId,
        orderFoldersByName,
        orderTopLevelFoldersByName
    };
};

export default useUtils;
