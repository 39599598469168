import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';
import { useModal } from 'providers/ModalContext';
import GroupFormModal from './modals/GroupFormModal';

const Groups = () => {
    const { openModal } = useModal();
    const deleteGroup = () => {};
    const COLUMNS = [
        {
            id: 'id',
            header: 'ID',
            sortable: true
        },
        {
            id: 'name',
            header: 'Nom',
            sortable: true
        },
        {
            id: 'total_groups',
            header: 'Total',
            sortable: true
        }
    ];
    return (
        <>
            <GroupFormModal />
            <AdminHeader
                title={'Gestion des regroupements'}
                modalName={'add_group'}
                modalButtonTitle={'Ajouter un regroupement'}
            />
            <Table
                columns={COLUMNS}
                data={[]}
                isLoading={false}
                handleActions={[
                    {
                        label: 'ADMIN_MENU_BRANDS_EDIT_BRAND_LABEL',
                        handler: (organizationalUnit) => {
                            openModal('add_edit_ou', {
                                currentOU: organizationalUnit
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        handler: (row) => {
                            deleteGroup(row);
                        }
                    }
                ]}
            />
        </>
    );
};
export default Groups;
