import React, { useState, useEffect, useRef } from 'react';
import 'url-polyfill';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { isArray } from 'lodash-es';
import striptags from 'striptags';
import clip from 'text-clipper';
import classNames from 'classnames';
import decode from 'ent/decode';
import usePostService from 'api/usePostService';
import { isLinkTrusted, isVideoLink, isPhotoLink, isInternalLink } from 'helpers/link';
import { useAuth } from 'providers/AuthContext';
import { isAllImagesAttachment } from 'helpers/attachment';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import extractParamsFromUrl from 'helpers/extractParamsFromUrl';
import useAttachment from 'hooks/useAttachment';
import { useRouter } from '@uirouter/react';
import useRight from '../../hooks/useRight';

const Link = (props) => {
    const [overrideItem, setOverrideItem] = useState(null);
    const [pictureSelectedIndex, setPictureSelectedIndex] = useState(0);
    const linkRef = useRef(null);

    const { getPostById } = usePostService();
    const { user } = useAuth();
    const { isAllowToRead } = useRight();
    const router = useRouter();
    const { t } = useTranslation();
    const { config } = useConfig();
    const { forgeAttachmentUrl } = useAttachment();

    const loadCallbackHandler = () => {
        if (props.loadCallbackParam) {
            props.loadCallback(props.loadCallbackParam);
        } else {
            props.loadCallback();
        }
    };

    useEffect(() => {
        if (isLinkTrusted(props.item.provider) && hasType('rich')) {
            const provider = props.item.provider.toLowerCase();
            if (provider === 'twitter') {
                //twttr?.widgets.load(linkRef.current);
            }
        }

        if (props.loadCallback) {
            linkRef.current.querySelectorAll('iframe').forEach((iframe) => {
                iframe.addEventListener('load', loadCallbackHandler);
            });

            linkRef.current.querySelectorAll('img').forEach((img) => {
                img.addEventListener('load', loadCallbackHandler);
            });
        }

        processInternalLink();

        return () => {
            if (props.loadCallback) {
                linkRef.current.querySelectorAll('iframe').forEach((iframe) => {
                    iframe.removeEventListener('load', loadCallbackHandler);
                });

                linkRef.current.querySelectorAll('img').forEach((img) => {
                    img.removeEventListener('load', loadCallbackHandler);
                });
            }
        };
    }, [props.item]);

    const workspaceHasCustomForm = (post) => {
        return post.workspace && post.workspace.workspace_form;
    };

    const processInternalLink = () => {
        const { url = null, pathname = null, params = null } = extractParamsFromUrl(props.item.url);

        if (!isInternalLink(url, config.hostnames)) {
            return;
        }

        const matchedRoute = router.urlService.match({
            path: pathname,
            search: params,
            hash: url === null ? null : url.hash.replace('#', '')
        });

        if (!matchedRoute || !matchedRoute.rule || matchedRoute.rule.type !== 'STATE') {
            return;
        }

        if (matchedRoute.rule.state.name === 'auth.posts.post') {
            let { workspace: workspaceId = null, post: postId = null } = matchedRoute.match;
            postId = parseInt(postId);
            workspaceId = parseInt(workspaceId);

            if (!postId || !workspaceId || !isAllowToRead(workspaceId)) {
                setOverrideItem({
                    title: t('NO_READ_ACCESS_TO_POST')
                });
                return;
            }

            getPostById(postId).then(([postData]) => {
                const { post = null } = postData;
                let attachments = post.attachments;
                const title = t('POST_PUBLISHED_IN', {
                    workspaceName: post.workspace.name
                });
                let description = null;

                if (workspaceHasCustomForm(post)) {
                    const workspaceFormInputValues = post.workspace_form_input_values;

                    if (workspaceFormInputValues && workspaceFormInputValues.length === 0) {
                        return;
                    }

                    const fileOrFilesInputValues = workspaceFormInputValues.filter(
                        (workspaceFormInputValue) => {
                            return (
                                ['file', 'files'].indexOf(
                                    workspaceFormInputValue.workspace_form_input.type
                                ) !== -1 && !workspaceFormInputValue.workspace_form_input.hidden
                            );
                        }
                    );

                    if (fileOrFilesInputValues && fileOrFilesInputValues.length > 0) {
                        const imageAttachmentInputValue = fileOrFilesInputValues.find(
                            (fileOrFilesInputValue) => {
                                const fileOrFilesAttachments = fileOrFilesInputValue.attachments
                                    ? fileOrFilesInputValue.attachments
                                    : [fileOrFilesInputValue.attachment];
                                return isAllImagesAttachment(fileOrFilesAttachments);
                            }
                        );

                        if (imageAttachmentInputValue) {
                            attachments = imageAttachmentInputValue.attachments
                                ? imageAttachmentInputValue.attachments
                                : [imageAttachmentInputValue.attachment];
                        }
                    }

                    const textOrLongTextInputValues = workspaceFormInputValues
                        .filter((workspaceFormInputValue) => {
                            return (
                                ['file', 'files', 'recipients', 'position'].indexOf(
                                    workspaceFormInputValue.workspace_form_input.type
                                ) === -1 && !workspaceFormInputValue.workspace_form_input.hidden
                            );
                        })
                        .slice(0, 2)
                        .map((workspaceFormInputValue) => {
                            return `${workspaceFormInputValue.workspace_form_input.label_render}: ${striptags(workspaceFormInputValue.text)}`;
                        });

                    description =
                        textOrLongTextInputValues.length > 0
                            ? textOrLongTextInputValues.join('\n')
                            : null;
                } else {
                    const content = striptags(post.content).replace(
                        /\s*([.!?]+)(?!\s*$)\s*/g,
                        '$1 '
                    );
                    description = content ? clip(content, 140, { maxLines: 15 }) : null;
                }

                const attachment = attachments.find((attachment) =>
                    isAllImagesAttachment([attachment])
                );

                setOverrideItem({
                    picture_url: attachment ? forgeAttachmentUrl(attachment) : null,
                    title,
                    description
                });
            });
        }
    };

    const isSmall = () => {
        return !!props.small;
    };

    const hasType = (type) => {
        return props.item.type === type;
    };

    const hasProvider = (providers) => {
        if (!isArray(providers)) {
            providers = [providers];
        }
        return providers.some((provider) => props.item.provider.toLowerCase() === provider);
    };

    const removeScriptTags = (code) => {
        return code.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    };

    const forceHttpsIfProtocolIsNotDefined = (code) => {
        return code.replace(/src="\/\//gi, 'src="https://');
    };

    const getMime = (url) => {
        const fileExt = url.split('.').pop();
        return `video/${fileExt}`;
    };

    const forgeLinkUrl = () => {
        if (props.campaign) {
            return props.item.url;
        }

        const { url = null, pathname = null, params = null } = extractParamsFromUrl(props.item.url);

        if (isInternalLink(url, config.hostnames)) {
            const matchedRoute = router.urlService.match({
                path: pathname,
                search: params,
                hash: url === null ? null : url.hash.replace('#', '')
            });

            if (matchedRoute && matchedRoute.rule && matchedRoute.rule.type === 'STATE') {
                return props.item.url;
            }
        }

        return `${config.apiUrl}/download/links/${props.item.id}/user/${user.id}`;
    };

    const getDomainFromUrl = (url) => {
        if (url && url.indexOf('http') === 0) {
            return new URL(url).hostname;
        }
        return '';
    };

    const nextPicture = () => {
        let newIndex = pictureSelectedIndex + 1;
        if (newIndex >= props.item.pictures_url.length) {
            newIndex = 0;
        }
        props.editCallback(newIndex);
        setPictureSelectedIndex(newIndex);
    };

    const prevPicture = () => {
        let newIndex = pictureSelectedIndex - 1;
        if (newIndex < 0) {
            newIndex = props.item.pictures_url.length - 1;
        }
        props.editCallback(newIndex);
        setPictureSelectedIndex(newIndex);
    };

    const showPicture = () => {
        /*Carousel.initCarouselState();
        Carousel.images = [props.item];
        Carousel.index = 0;
        Carousel.show();*/
    };

    if (overrideItem) {
        return (
            <div className="linkRoot" ref={linkRef}>
                <div
                    className={classNames(
                        'link',
                        'small',
                        'internal-link',
                        props.item.provider && props.item.provider.toLowerCase()
                    )}
                >
                    <div className="is-untrusted">
                        <div className="is-link">
                            <div className="is-text">
                                <a href={forgeLinkUrl()} target="_blank" className="card">
                                    {overrideItem.picture_url ? (
                                        <div className="has-photo">
                                            <div className="wrapper">
                                                <div
                                                    className="backdrop"
                                                    style={{
                                                        backgroundImage: `url(${overrideItem.picture_url})`
                                                    }}
                                                />
                                                <img src={overrideItem.picture_url} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="has-photo internal-link">
                                            <div className="wrapper">
                                                <img src={config.logo_url} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="meta">
                                        <div className="domain">
                                            {config.window_title
                                                ? config.window_title
                                                : config.title}
                                        </div>
                                        {overrideItem.title ? (
                                            <div className="title">
                                                {decode(overrideItem.title)}
                                            </div>
                                        ) : null}
                                        {overrideItem.description ? (
                                            <div
                                                className="description"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                            >
                                                {decode(overrideItem.description)}
                                            </div>
                                        ) : null}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="linkRoot" ref={linkRef}>
            {isSmall() ? (
                <div
                    className={classNames(
                        'link',
                        'small',
                        props.item.provider && props.item.provider.toLowerCase()
                    )}
                >
                    <div className="is-untrusted">
                        <div className="is-link">
                            <div className="is-text">
                                <a href={forgeLinkUrl()} target="_blank" className="card">
                                    {props.item.picture_url ? (
                                        <div className="has-photo">
                                            <div className="wrapper">
                                                <div
                                                    className="backdrop"
                                                    style={{
                                                        backgroundImage: `url(${props.item.picture_url})`
                                                    }}
                                                />
                                                <img src={props.item.picture_url} />
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="meta">
                                        <div className="domain">
                                            {getDomainFromUrl(props.item.url)}
                                        </div>
                                        {props.item.title ? (
                                            <div className="title">{decode(props.item.title)}</div>
                                        ) : null}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={classNames(
                        'link',
                        props.item.type,
                        props.item.provider && props.item.provider.toLowerCase()
                    )}
                >
                    {isLinkTrusted(props.item.provider) && props.item.code ? (
                        <div className="is-trusted">
                            {hasProvider(['youtube', 'dailymotion', 'vimeo']) ? (
                                <div
                                    className="wrapper iframe-responsive"
                                    dangerouslySetInnerHTML={{ __html: props.item.code }}
                                />
                            ) : hasProvider('twitter') ? (
                                <div
                                    className="wrapper"
                                    dangerouslySetInnerHTML={{
                                        __html: removeScriptTags(props.item.code)
                                    }}
                                />
                            ) : (
                                <div
                                    className="wrapper"
                                    dangerouslySetInnerHTML={{ __html: props.item.code }}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="is-untrusted">
                            {hasType('photo') ? (
                                <div className="is-photo" onClick={() => showPicture()}>
                                    <div
                                        className="backdrop"
                                        style={{ backgroundImage: `url(${forgeLinkUrl()})` }}
                                    />
                                    <img src={props.item.picture_url} />
                                </div>
                            ) : hasType('video') ? (
                                <div className="is-video">
                                    {isVideoLink(props.item.url) ? (
                                        <div className="is-file">
                                            <VideoPlayer
                                                src={props.item.url}
                                                type={getMime(props.item.url)}
                                            />
                                        </div>
                                    ) : (
                                        <div className="is-code">
                                            {props.item.code ? (
                                                <div
                                                    className="wrapper"
                                                    dangerouslySetInnerHTML={{
                                                        __html: forceHttpsIfProtocolIsNotDefined(
                                                            props.item.code
                                                        )
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="is-link">
                                    {isPhotoLink(props.item.url) ? (
                                        <div className="is-photo" onClick={() => showPicture()}>
                                            <div
                                                className="backdrop"
                                                style={{
                                                    backgroundImage: `url(${props.item.url})`
                                                }}
                                            />
                                            <img src={props.item.url} />
                                        </div>
                                    ) : (
                                        <div className="is-text">
                                            {props.edit && props.item.pictures_url.length > 0 ? (
                                                <div className="change-image">
                                                    <button
                                                        onClick={() => prevPicture()}
                                                        className="prev"
                                                    >
                                                        <i className="fa fa-chevron-left" />
                                                    </button>
                                                    <div className="label">
                                                        Image {pictureSelectedIndex + 1}/
                                                        {props.item.pictures_url.length}
                                                    </div>
                                                    <button
                                                        onClick={() => nextPicture()}
                                                        className="next"
                                                    >
                                                        <i className="fa fa-chevron-right" />
                                                    </button>
                                                </div>
                                            ) : null}
                                            <a
                                                className="card"
                                                href={forgeLinkUrl()}
                                                target="_blank"
                                            >
                                                {props.item.picture_url ? (
                                                    <div className="has-photo">
                                                        <div
                                                            className="backdrop"
                                                            style={{
                                                                backgroundImage: `url(${props.item.picture_url})`
                                                            }}
                                                        />
                                                        <img src={props.item.picture_url} />
                                                    </div>
                                                ) : null}
                                                <div className="meta">
                                                    <div className="domain">
                                                        {getDomainFromUrl(props.item.url)}
                                                    </div>
                                                    {props.item.title ? (
                                                        <div className="title">
                                                            {decode(props.item.title)}
                                                        </div>
                                                    ) : null}
                                                    {props.item.description ? (
                                                        <div className="description">
                                                            {decode(props.item.description)}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Link;
