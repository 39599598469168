import React from 'react';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const SharedTags = ({ result, query }) => {
    const { getResultWithQueryHighlight } = useSearchBoxResultComponent(query);

    return (
        <>
            <div className="icon" style={{ backgroundColor: result.color }}>
                <i className="icon-pin" aria-hidden="true"></i>
            </div>

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.name)
                    }}
                />
            </div>
        </>
    );
};

export default SharedTags;
