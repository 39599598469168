export const TYPES = [
    {
        name: 'text',
        value: 'ADMIN_PROFILE_TYPE_TEXT'
    },
    {
        name: 'long_text',
        value: 'ADMIN_PROFILE_TYPE_LONG_TEXT'
    },
    {
        name: 'select',
        value: 'ADMIN_PROFILE_TYPE_SELECT',
        need_values: true
    },
    {
        name: 'numeric',
        value: 'ADMIN_PROFILE_TYPE_NUMERIC'
    }
];
