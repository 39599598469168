import React, { useEffect, useRef } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { iframeResize } from 'iframe-resizer';
import WidgetsService from '../services/Widget';

const Iframe = ({ widget, options }) => {
    const iframeRef = useRef();
    const { getFieldValueForKey, getStyleValueForKey } = useWidgetComponent(widget, options);

    const widgetService = new WidgetsService();

    const getBgColor = () => {
        let bgColor = '#EFEDEF';

        const localBgColor = getStyleValueForKey('bgColor');
        const globalBgColor = widgetService.getWidgetStyleValueForKey(options, 'bgColor');

        if (localBgColor && localBgColor !== 'transparent') {
            bgColor = localBgColor;
        } else {
            if (globalBgColor && globalBgColor !== 'transparent') {
                bgColor = globalBgColor;
            }
        }

        return bgColor;
    };

    useEffect(() => {
        if (iframeRef.current) {
            iframeResize(
                {
                    autoResize: true,
                    bodyBackground: getBgColor(),
                    checkOrigin: false
                },
                iframeRef.current
            );

            iframeRef.current.onload = () => {
                iframeRef.current.iFrameResizer.resize();
            };
        }
    }, [iframeRef]);

    return (
        <div className="iframe">
            <iframe src={getFieldValueForKey('link')} ref={iframeRef}></iframe>
        </div>
    );
};

export default Iframe;
