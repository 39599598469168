import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useAchievementService from 'api/useAchievementService';
import Achievement from 'components/Achievement/Achievement';
import AdminHeader from 'components/Admin/components/AdminHeader';
import AchievementCategoryFormModal from './modals/AchievementCategoryFormModal';
import { useModal } from 'providers/ModalContext';
import AchievementFormModal from './modals/AchievementFormModal';
import { last } from 'lodash-es';
import AchievementAssignFormModal from './modals/AchievementAssignFormModal';

const Achievements = () => {
    const { t } = useTranslation();
    const { getCategories } = useAchievementService();
    const { openModal } = useModal();

    const { data: categories } = useQuery({
        queryFn: () => getCategories(),
        queryKey: ['achievements_categories']
    });

    const addAchievementForCategory = (category) => {
        openModal('add_edit_achievement', {
            currentAchievement: {
                position: last(category.achievements).position + 1,
                category
            }
        });
    };

    return (
        <div className="admin__achievements">
            <AchievementCategoryFormModal />
            <AchievementFormModal />
            <AchievementAssignFormModal />
            <AdminHeader
                title={'Gestion des badges'}
                modalName={'edit_achievement_category'}
                modalButtonTitle={'Ajouter une catégorie'}
            />

            <div className="list">
                {categories &&
                    categories.map((category) => {
                        return (
                            <div className="item" key={`category_${category.id}`}>
                                <div className="category">
                                    <div className="metadata">
                                        <div className="name">
                                            <span className="text">{category.name}</span>
                                            <span className="position">
                                                Position {category.position}
                                            </span>
                                        </div>
                                        <div className="trophies">
                                            {category.achievements.length} badges
                                        </div>
                                    </div>

                                    <div className="actions">
                                        <button
                                            onClick={() => {
                                                openModal('edit_achievement_category', {
                                                    currentAchievementCategory: category
                                                });
                                            }}
                                            title="Éditer la catégorie">
                                            <i className="icon-pencil-1" aria-hidden="true"></i>
                                        </button>

                                        <button
                                            onClick={() => addAchievementForCategory(category)}
                                            title="Ajouter un badge à cette catégorie">
                                            <i className="icon-add-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>

                                {category?.achievements?.length > 0 && (
                                    <div className="children">
                                        {category.achievements.map((achievement) => {
                                            return (
                                                <div
                                                    className="achievement"
                                                    key={`achievemment_${achievement.id}`}>
                                                    <div className="checkbox">
                                                        <input
                                                            className="input"
                                                            id={`achievement_${achievement.id}`}
                                                            type="checkbox"
                                                            checked={achievement.enabled}
                                                            ng-change="$ctrl.toggleAchievement(achievement)"
                                                        />
                                                        <label
                                                            className="label"
                                                            htmlFor={`achievement_${achievement.id}`}></label>
                                                    </div>

                                                    <div className="trophy">
                                                        <Achievement
                                                            item={achievement}
                                                            hasWatcher={true}
                                                        />
                                                    </div>

                                                    <div className="metadata">
                                                        <div className="name">
                                                            <span className="text">
                                                                {t(achievement.name)}
                                                            </span>
                                                            {achievement?.points > 0 && (
                                                                <span className="points">
                                                                    +{achievement.points} points
                                                                </span>
                                                            )}
                                                            <span className="position">
                                                                Position {achievement.position}
                                                            </span>
                                                        </div>
                                                        <div className="description">
                                                            {t(achievement.description)}
                                                        </div>
                                                    </div>

                                                    <div className="actions">
                                                        <button
                                                            onClick={() => {
                                                                openModal('add_edit_achievement', {
                                                                    currentAchievement: {
                                                                        ...achievement,
                                                                        category
                                                                    }
                                                                });
                                                            }}
                                                            title="Éditer le badge">
                                                            <i
                                                                className="icon-pencil-1"
                                                                aria-hidden="true"></i>
                                                        </button>

                                                        <button
                                                            ng-click="$ctrl.cloneAchievement(category, achievement)"
                                                            title="Dupliquer le badge">
                                                            <i
                                                                className="icon-common-file-double-1"
                                                                aria-hidden="true"></i>
                                                        </button>

                                                        <button
                                                            onClick={() => {
                                                                openModal('assign_achievement', {
                                                                    currentAchievement: achievement
                                                                });
                                                            }}
                                                            title="Assigner le badge à un utilisateur">
                                                            <i
                                                                className="icon-human-resources-search-team"
                                                                aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Achievements;
