import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Autocomplete, Input } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';
import useUserService from 'api/useUserService';
import Achievement from 'components/Achievement/Achievement';
import './styles/achievementFormModal.scss';

const AchievementAssignFormModalContent = () => {
    const {
        contexts: {
            assign_achievement: { currentAchievement }
        },
        closeModal
    } = useModal();
    const methods = useForm();
    const { findUsersByCriteria } = useUserService();

    const fetchUserList = async (username) => {
        return findUsersByCriteria(username).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.name}`,
                    value: r.id
                };
            });
        });
    };

    const onFinish = (values) => {};

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Autocomplete
                        fetchOptions={fetchUserList}
                        name={'user'}
                        label={'Utilisateur'}
                        placeholder={'Rechercher un utilisateur'}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <div className={'preview'}>
                        <div className={'achievement'}>
                            <Achievement item={currentAchievement} hasWatcher={true} />
                        </div>
                        <div className={'config'}>
                            <Input
                                name={'name'}
                                label={'Nom du badge'}
                                value={currentAchievement?.name}
                                disabled
                            />
                            <Input
                                name={'description'}
                                label={'Description du badge'}
                                value={currentAchievement?.description}
                                disabled
                            />
                        </div>
                    </div>
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('assign_achievement');
                }}
            />
        </ModalForm>
    );
};

const AchievementAssignFormModal = () => {
    return (
        <Modal
            name={'assign_achievement'}
            title={'Assigner un badge à un utilisateur'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <AchievementAssignFormModalContent />
        </Modal>
    );
};

export default AchievementAssignFormModal;
