import React from 'react';
import { useQuery } from '@tanstack/react-query';
import useDirectoryConfigService from './api/useDirectoryConfigService';
import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import { useModal } from 'providers/ModalContext';
import DirectoryConfigModal from './modals/DirectoryConfigModal';

const DirectoryConfigs = () => {
    const { getDirectoryConfigs } = useDirectoryConfigService();
    const { openModal } = useModal();
    const { data: directoryConfigs, isLoading } = useQuery({
        queryFn: () => getDirectoryConfigs(),
        queryKey: 'directory_configs'
    });

    const COLUMNS = [
        {
            id: 'name',
            header: "Nom de l'annuaire",
            sortable: true
        }
    ];

    return (
        <>
            <DirectoryConfigModal />

            <AdminHeader
                title={'DIRECTORY_MANAGEMENT'}
                modalName={'directory_config'}
                modalButtonTitle={'ADD_DIRECTORY_CONFIG'}
            />

            <Table
                columns={COLUMNS}
                data={directoryConfigs}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Modifier',
                        handler: (directoryConfig) => {
                            openModal('add_edit_directory', {
                                currentDirectoryConfig: directoryConfig
                            });
                        }
                    }
                ]}
            />
        </>
    );
};

export default DirectoryConfigs;
