import { useQuery } from '@tanstack/react-query';
import { Select } from 'components/Form/Inputs';
import useBlogCategoryService from 'api/useBlogCategoryService';

const CategoryBlogArticles = ({ field, fieldName, ...props }) => {
    const { getBlogCategories } = useBlogCategoryService();
    const { data: categories } = useQuery({
        queryKey: ['blogCategories'],
        queryFn: () => getBlogCategories()
    });
    return (
        <Select
            shouldUnregister={false}
            mode={'multiple'}
            name={fieldName}
            options={
                categories
                    ? categories.map((article) => {
                          return {
                              label: article.name,
                              value: article.id
                          };
                      })
                    : []
            }
            {...props}
        />
    );
};

export default CategoryBlogArticles;
