import { useConfig } from 'providers/ConfigProvider';

const useStatExportLinks = () => {
    const { config } = useConfig();

    const exportUsersRanking = (params, light = false) => {
        let timestamp = Math.floor((new Date().getTime() / 1000) * 60);

        let query = Object.keys(params)
            .filter((key) => {
                return !!params[key];
            })
            .map((key) => {
                return `${key}=${params[key]}`;
            })
            .join('&');

        return `${config.staticUrl}stats/users/ranking/download?${query}&nocache=${timestamp}&light=${light}`;
    };

    const exportLoginHistory = (params) => {
        let timestamp = Math.floor((new Date().getTime() / 1000) * 60);

        let query = Object.keys(params)
            .filter((key) => {
                return !!params[key];
            })
            .map((key) => {
                return `${key}=${params[key]}`;
            })
            .join('&');

        return `${config.staticUrl}stats/login/history/download?${query}&nocache=${timestamp}`;
    };

    const exportSocketHistory = (params) => {
        let timestamp = Math.floor((new Date().getTime() / 1000) * 60);

        let query = Object.keys(params)
            .filter((key) => {
                return !!params[key];
            })
            .map((key) => {
                return `${key}=${params[key]}`;
            })
            .join('&');

        return `${config.staticUrl}stats/socket/connections?${query}&nocache=${timestamp}`;
    };

    return {
        exportUsersRanking,
        exportLoginHistory,
        exportSocketHistory
    };
};

export default useStatExportLinks;
