import { useAdminTreeContext } from '../../../../../../providers/AdminTreeProvider';
import { Select } from 'components/Form/Inputs';
import { orderBy } from 'lodash-es';
import useTreeHook from 'hooks/useTree';

const Categories = ({ field, fieldName, ...props }) => {
    const { fullTree } = useAdminTreeContext();
    const { getOnlyCategory } = useTreeHook();
    return (
        <Select
            name={fieldName}
            mode="multiple"
            options={orderBy(getOnlyCategory(fullTree), ['name']).map((category) => {
                return {
                    label: category.name,
                    value: category.id
                };
            })}
            {...props}
        />
    );
};

export default Categories;
