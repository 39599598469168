import { Checkbox, ColorPicker, Input, RichText, Select, Textarea } from 'components/Form/Inputs';
import {
    ModalForm,
    ModalFormBody,
    ModalFormFooter,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useForm } from 'components/Form/hooks';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import newTagFormSchema from '../schemas/newTagForm.schema.json';
import { useAlert } from 'providers/AlertContext';
import useTagService from 'api/useTagService';
import { useAdminOrganizationalUnitContext } from 'pages/Admin/providers/AdminOrganizationalUnitProvider';
import useUtils from 'hooks/useUtils';

const TagsNewModalContent = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { showToast } = useAlert();
    const {
        closeModal,
        contexts: { add_tag: tag }
    } = useModal();
    const { addTag } = useTagService();
    const { arrayAsId } = useUtils();
    const { allOrganizationalUnits, isLoadingOU } = useAdminOrganizationalUnitContext();

    const methods = useForm({
        schema: newTagFormSchema,
        defaultValues: {
            admin: true,
            name: tag?.name || '',
            description: tag?.description || '',
            color: tag?.color || '#000000',
            position: tag?.position || 0,
            isEssential: tag?.isEssential || false,
            isWeeklyListing: tag?.isWeeklyListing || false,
            isPostsStacked: tag?.isPostsStacked || false,
            organizationalUnits: tag?.organizationalUnits?.map((_) => _.id) || [],
            tagUserEmails: tag?.tagUserEmails?.join('/n').replace('/n', ' ') || '',
            adminOrganizationalUnits: tag?.adminOrganizationalUnits?.map((_) => _.id) || []
        }
    });

    const onFinish = (values) => {
        const payload = {
            ...values
        };

        payload.organizationalUnits = arrayAsId(payload.organizationalUnits);
        payload.adminOrganizationalUnits = arrayAsId(payload.adminOrganizationalUnits);
        payload.tagUserEmails = payload.tagUserEmails?.split('/n');

        addTag(payload)
            .then(() => {
                showToast({
                    type: 'success',
                    message: 'Tag ajouté avec succès !'
                });

                queryClient.invalidateQueries('tags');

                methods.reset();
            })
            .catch((error) => {
                showToast({
                    type: 'error',
                    message: error.message
                });
                console.log('error', error);
            });

        closeModal('add_tag');
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input name="name" label={t('ADD_TAGS_NAME')} autocomplete="off" />

                    <RichText name={`description`} label={t('ADD_TAGS_DESCRIPTION')} />

                    <ColorPicker
                        name={'color'}
                        label={t('ADD_TAGS_COLOR')}
                        value={methods.watch('color') || methods.getValues('color') || '#000000'}
                    />

                    <Input name={'position'} label={t('ADD_TAGS_POSITION')} type="number" />
                </ModalFormSection>

                <ModalFormSection>
                    <p>{t('ADD_TAGS_OPTIONS')} :</p>

                    <Checkbox name={'isEssential'} label={t('ADD_TAGS_IS_ESSENTIAL')} />
                    <Checkbox name={'isWeeklyListing'} label={t('ADD_TAGS_IS_WEEKLY_LISTING')} />
                    <Checkbox name={'isPostsStacked'} label={t('ADD_TAGS_IS_POST_STACKED')} />
                </ModalFormSection>

                <ModalFormSection>
                    <Select
                        name={'organizationalUnits'}
                        label={t('TAG_ORGANIZATIONAL_UNITS')}
                        options={allOrganizationalUnits?.map((ou) => ({
                            label: ou.name,
                            value: ou.id
                        }))}
                        loading={isLoadingOU}
                        mode="multiple"
                    />

                    <Textarea name={'tagUserEmails'} label={t('ADMIN_TAG_USERS')} />

                    <Select
                        name={'adminOrganizationalUnits'}
                        label={t('TAG_ORGANIZATIONAL_UNITS_ADMIN')}
                        options={allOrganizationalUnits?.map((ou) => ({
                            label: ou.name,
                            value: ou.id
                        }))}
                        loading={isLoadingOU}
                        mode="multiple"
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter onCancel={() => closeModal('add_tag')} />
        </ModalForm>
    );
};

const TagsNewModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name="add_tag"
            title={t('ADD_ADMIN_TAGS')}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <TagsNewModalContent />
        </Modal>
    );
};

export default TagsNewModal;
