import Feed from '../Feed';
import Posts from '../components/Posts';
import Schedule from '../components/Schedule';
import Review from '../components/Review';
import Tables from '../components/Tables';
import { UIView } from '@uirouter/react';
import Tiles from '../components/Tiles';
import Calendar from '../components/Calendar';

const states = ['posts', 'stacked'];

const navbarData = (state) => {
    return {
        getIcon: () => {
            return 'icon-paragraph-bullets';
        },
        getLabel: (context) => {
            return context.t('SEARCH_TAB_ACTUALITY');
        },
        getAdminLabel: (context) => {
            switch (state) {
                case 'stacked':
                    return `${context.t('SEARCH_TAB_ACTUALITY')} en vue condensée`;
                case 'essentials':
                    return `${context.t('SEARCH_TAB_ACTUALITY')} en vue essentiels`;
                default:
                    return `${context.t('SEARCH_TAB_ACTUALITY')} en vue dépliée`;
            }
        },
        isActive: (context) => {
            return (
                context.isActive([
                    'auth.feed.posts.**',
                    'auth.feed.stacked.**'
                    /*'auth.essentials.**',*/
                ]) && !context.isActive(['auth.feed.posts.review.**'])
            );
        },
        isVisible: () => {
            return true;
        }
    };
};

const routes = [
    { name: 'auth.feed', url: '', abstract: true, component: Feed },
    {
        name: `auth.feed.scheduled`,
        url: 'posts/scheduled',
        component: Schedule
    },
    {
        name: `auth.feed.tables`,
        url: 'tables',
        component: UIView,
        abstract: true
    },
    {
        name: `auth.feed.tables.workspace`,
        url: '/category/:category/workspace/:workspace?filters',
        component: Tables
    },
    {
        name: `auth.feed.tiles`,
        url: 'tiles',
        component: UIView,
        abstract: true
    },
    {
        name: `auth.feed.tiles.workspace`,
        url: '/category/:category/workspace/:workspace?status&query&order&since',
        component: Tiles,
        params: {
            query: {
                array: true,
                value: []
            },
            status: {
                type: 'int',
                value: 0,
                squash: true
            },
            order: {
                type: 'string',
                value: '',
                squash: true
            },
            since: {
                type: 'string',
                value: '',
                squash: true
            }
        }
    },
    {
        name: `auth.feed.calendar`,
        url: 'calendar?timestamp',
        component: Calendar,
        params: {
            timestamp: {
                type: 'int',
                value: Math.round(new Date().getTime() / 1000),
                squash: true,
                dynamic: true
            }
        }
    },
    {
        name: `auth.feed.calendar.category`,
        url: '/category/:category',
        component: Calendar
    },
    {
        name: `auth.feed.calendar.workspace`,
        url: '/category/:category/workspace/:workspace',
        component: Calendar
    },
    {
        name: `auth.feed.review`,
        url: 'posts/review?tab',
        component: Review,
        params: {
            tab: {
                type: 'string',
                value: 'posts',
                dynamic: true
            }
        },
        navbar: {
            getIcon: () => {
                return 'icon-social-profile-bubble';
            },
            getLabel: ({ t }) => {
                return t('REVIEW');
            },
            isActive: ({ isActive }) => {
                return isActive(['auth.posts.review.**']);
            },
            isVisible: ({ Right, user }) => {
                return user.hasAccessReviews && !Right.hasRole('HIDE_REVIEW_MENU_BUTTON');
            }
        }
    }
];

states.forEach((state) => {
    routes.push(
        {
            name: `auth.feed.${state}`,
            url: `${state}?page&openEditor`,
            component: Posts,
            navbar: navbarData(state),
            params: {
                page: {
                    type: 'int',
                    value: 1,
                    dynamic: true,
                    squash: true
                },
                openEditor: {
                    type: 'bool',
                    value: false,
                    dynamic: true,
                    squash: true
                }
            }
        },
        {
            name: `auth.feed.${state}.category`,
            url: '/category/:category?unread',
            component: Posts
        },
        {
            name: `auth.feed.${state}.workspace`,
            url: '/category/:category/workspace/:workspace?open&unread&status&query',
            component: Posts,
            params: {
                query: {
                    array: true,
                    value: []
                },
                status: {
                    type: 'int',
                    value: 0,
                    squash: true
                }
            }
        },
        {
            name: `auth.feed.${state}.folder`,
            url: '/category/:category/workspace/:workspace/folder/:folder',
            component: Posts
        },
        {
            name: `auth.feed.${state}.post`,
            url: '/category/:category/workspace/:workspace/post/:post',
            component: Posts
        },
        { name: `auth.feed.${state}.tag`, url: '/tag/:tag', component: Posts }
    );
});

export default routes;
