import React, { useState } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import useRight from 'hooks/useRight';
import { useTagContext } from 'pages/Auth/providers/TagProvider';
import { useTranslation } from 'react-i18next';
import { ModalForm, ModalFormBody, ModalFormSection } from 'components/Form/ModalForm';
import { ColorPicker, Input } from 'components/Form/Inputs';
import { useForm } from 'components/Form/hooks';
import '../styles/form-tags.scss';
import { Button } from 'antd';
import { useAuth } from 'providers/AuthContext';
import useTag from 'hooks/useTag';
import { useAlert } from 'providers/AlertContext';
import useTagService from 'api/useTagService';
import { useQueryClient } from '@tanstack/react-query';

const PersonalTags = ({ widget, options, tags }) => {
    const { getFieldValueForKey, isFolded, isFoldable, setSavedFoldedState, getStyleValueForKey } =
        useWidgetComponent(widget, options);
    const { t } = useTranslation();
    const [currentEditingTag, setCurrentEditingTag] = useState({});
    const [showForm, setShowForm] = useState(false);
    const methods = useForm();
    const { user } = useAuth();
    const { goToTag } = useTag();
    const { showConfirm, showToast } = useAlert();
    const { deleteTag, addTag } = useTagService();
    const queryClient = useQueryClient();

    const isTagOwner = (tag) => {
        return tag.user && tag.user.id === user.id;
    };

    const removeTag = (tag) => {
        showConfirm({
            title: t('TAG_DELETE_CONFIRM_TITLE'),
            text: t('TAG_DELETE_CONFIRM_TEXT'),
            button: {
                label: t('TAG_DELETE_CONFIRM_YES'),
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                deleteTag(tag).then(
                    () => {
                        cancelForm();
                        queryClient.invalidateQueries(['tags', 'my_tags']);
                        showToast({
                            text: t('TAG_DELETE_DONE'),
                            duration: 1500
                        });
                    },
                    () => {
                        showToast({
                            text: t('TAG_DELETE_FAIL'),
                            duration: 1500
                        });
                    }
                );
            })
            .catch(() => {});
    };

    const editTag = (tag) => {
        setCurrentEditingTag(tag);
        methods.setValue('id', tag.id);
        methods.setValue('name', tag.name);
        methods.setValue('color', tag.color);
        setShowForm(true);
    };

    const cancelForm = () => {
        setCurrentEditingTag({});
        methods.setValue('id', null);
        methods.setValue('name', null);
        methods.setValue('color', null);
        setShowForm(false);
    };

    const onFinish = (values) => {
        addTag(values).then(() => {
            cancelForm();
            queryClient.invalidateQueries(['tags', 'my_tags']);
        });
    };

    return (
        <>
            <div className="title" onClick={() => setSavedFoldedState(!isFolded)}>
                <div className={`text ${getStyleValueForKey('textAlign')}`}>
                    {getFieldValueForKey('title')}
                </div>

                {isFoldable && (
                    <div className="toggle">
                        <i
                            className={
                                !isFolded
                                    ? 'icon-arrow-up-1-arrows-diagrams'
                                    : 'icon-arrow-down-1-arrows-diagrams'
                            }
                            aria-hidden="true"></i>
                    </div>
                )}
            </div>

            <div className="wrapper" hidden={isFolded}>
                {tags &&
                    tags.map((tag, index) => {
                        if (tag.admin) {
                            return;
                        }
                        return (
                            <div className="shortcut tag" key={`tag_shared_${index}`}>
                                <a
                                    className="icon"
                                    style={{
                                        'background-color': tag.color,
                                        'border-color': tag.color
                                    }}
                                    href={goToTag(tag, true)}>
                                    <i className="icon-pin"></i>
                                </a>

                                <a className="name" href={goToTag(tag, true)}>
                                    {tag.name}
                                </a>

                                {isTagOwner(tag) && (
                                    <div className="actions">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                editTag(tag);
                                            }}>
                                            <i className="icon-pencil-1" aria-hidden="true"></i>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                removeTag(tag);
                                            }}>
                                            <i className="icon-bin-2" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}

                <div className="actions" hidden={showForm}>
                    <button className="btn outline" type="button" onClick={() => setShowForm(true)}>
                        <i className="icon-add-circle" aria-hidden="true"></i> {t('ADD_TAGS')}
                    </button>
                </div>

                <div hidden={!showForm}>
                    <ModalForm
                        className={'form-wrapper tags'}
                        methods={methods}
                        onSubmit={methods.handleSubmit(onFinish)}>
                        <ModalFormBody>
                            <ModalFormSection>
                                <Input name={'name'} label={t('ADD_TAGS_NAME')} />
                                <ColorPicker name={'color'} label={t('ADD_TAGS_COLOR')} />
                            </ModalFormSection>
                            <ModalFormSection>
                                <div className="actions">
                                    <Button danger onClick={cancelForm} htmlType={'button'}>
                                        {t('DIALOG_CANCEL')}
                                    </Button>
                                    <Button htmlType={'submit'} ng-click="$ctrl.addTag()">
                                        {!currentEditingTag.id && (
                                            <>
                                                <i
                                                    className="icon-add-circle"
                                                    aria-hidden="true"></i>
                                                &nbsp;
                                                <span>{t('ADD_TAGS')}</span>
                                            </>
                                        )}
                                        {currentEditingTag.id && (
                                            <>
                                                <i className="icon-pencil-1" aria-hidden="true"></i>
                                                &nbsp;
                                                <span>{t('EDIT_TAGS')}</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </ModalFormSection>
                        </ModalFormBody>
                    </ModalForm>
                </div>
            </div>
        </>
    );
};

const SharedTags = ({ widget, options, tags }) => {
    const { getFieldValueForKey, isFolded, isFoldable, setSavedFoldedState, getStyleValueForKey } =
        useWidgetComponent(widget, options);
    const { goToTag } = useTag();

    return (
        <>
            <div className="title" onClick={() => setSavedFoldedState(!isFolded)}>
                <div className={`text ${getStyleValueForKey('textAlign')}`}>
                    {getFieldValueForKey('title')}
                </div>

                {isFoldable && (
                    <div className="toggle">
                        <i
                            className={
                                !isFolded
                                    ? 'icon-arrow-up-1-arrows-diagrams'
                                    : 'icon-arrow-down-1-arrows-diagrams'
                            }
                            aria-hidden="true"></i>
                    </div>
                )}
            </div>

            <div className="wrapper" hidden={isFolded}>
                {tags &&
                    tags.map((tag, index) => {
                        return (
                            <div className="shortcut tag" key={`tag_shared_${index}`}>
                                <a
                                    className="icon"
                                    style={{
                                        'background-color': tag.color,
                                        'border-color': tag.color
                                    }}
                                    onClick={() => {
                                        goToTag(tag, true);
                                    }}>
                                    <i className="icon-pin"></i>
                                </a>

                                <a
                                    className="name"
                                    onClick={() => {
                                        goToTag(tag, true);
                                    }}>
                                    {tag.name}
                                </a>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

const Tags = ({ widget, options }) => {
    const { getFieldValueForKey, isFoldable, getStyleValueForKey } = useWidgetComponent(
        widget,
        options
    );
    const { hasRole } = useRight();

    const { adminTags, essentialTags, tags } = useTagContext();

    const getTemplate = () => {
        const tagsType = getFieldValueForKey('tagsType')
            ? getFieldValueForKey('tagsType')
            : 'personal';
        if (tagsType === 'shared' || tagsType === 'essential') {
            if (!hasRole('HAS_ADMIN_TAGS')) {
                return <></>;
            }
            return (
                <SharedTags
                    widget={widget}
                    options={options}
                    tags={tagsType === 'shared' ? adminTags : essentialTags}
                />
            );
        } else {
            if (!hasRole('SHOW_TAGS')) {
                return <></>;
            }
            return <PersonalTags widget={widget} options={options} tags={tags} />;
        }
    };

    return (
        <div
            className={`shortcuts tags ${isFoldable ? 'foldable' : ''}`}
            style={{ color: getStyleValueForKey('titleColor') }}>
            {getTemplate()}
        </div>
    );
};

export default Tags;
