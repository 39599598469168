import { isVideoAttachment } from '../../../helpers/attachment';

export function isFile(resource) {
    return resource.type === 'attachment';
}

export function isImage(resource) {
    if (!isFile(resource)) {
        return false;
    }

    return resource?.attachment?.type && resource.attachment.type.indexOf('image') !== -1;
}

export function getImageSize(url) {
    const imgEl = document.querySelector(`img[src="${url}"]`);

    if (!imgEl) {
        return 'N/A';
    }

    return `${imgEl.naturalWidth} x ${imgEl.naturalHeight}px`;
}

export function getIconResource(resource) {
    switch (resource.type) {
        case 'attachment':
            if (isVideoAttachment(resource.attachment)) {
                return 'fa-eye';
            }
            return 'fa-download';
        case 'link':
            return 'fa-link';
        case 'action':
            return 'fa-pencil';
    }
}
