import useUserService from 'api/useUserService';
import { isInternalLink } from 'helpers/link';
import { useConfig } from 'providers/ConfigProvider';

const useUrl = () => {
    const { getToken } = useUserService();
    const { config } = useConfig();

    const getUrlWithToken = (url) => {
        return getToken().then(({ token }) => {
            if (token) {
                if (url.indexOf('?') !== -1) {
                    url = `${url}&token=${token}`;
                } else {
                    url = `${url}?token=${token}`;
                }
            }

            return url;
        });
    };

    const openUrl = (url, target = '_blank', withToken = true) => {
        url = url.indexOf('http') === 0 ? url : `${config.serverUrl}${url}`;
        let isInternal = isInternalLink(url, config.hostnames);

        let urlWithTokenPromise =
            withToken && isInternal ? getUrlWithToken(url) : Promise.resolve(url);

        urlWithTokenPromise.then((urlWithToken) => {
            let anchor = window.document.createElement('a');

            anchor.href = urlWithToken;
            anchor.target = target;
            anchor.dataset.target = target;

            window.document.body.appendChild(anchor);

            anchor.click();
            anchor.parentNode.removeChild(anchor);
        });
    };

    return {
        getUrlWithToken,
        openUrl
    };
};

export default useUrl;
