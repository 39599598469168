import React from 'react';
import iconsFile from 'assets/common/icomoon/ta-v4.json';
import { Select } from './index';
import { orderBy } from 'lodash-es';

function IconPicker({ ...props }) {
    const optionRender = (option) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                <i className={`icon-${option.data.value}`}></i>

                <div className="name">{option.data.value}</div>
            </div>
        );
    };
    return (
        <Select
            options={orderBy(iconsFile?.icons, ['properties.name']).map((icon) => {
                return {
                    value: icon.properties.name,
                    label: icon.properties.name
                };
            })}
            optionRender={optionRender}
            {...props}
        />
    );
}

export default IconPicker;
