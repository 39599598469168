import Ranking from '../Ranking';
import Badges from '../components/Badges';

export default [
    {
        name: 'auth.ranking',
        url: 'ranking?page&userId',
        component: Ranking,
        title: 'RANK_PAGE_TITLE',
        params: {
            page: {
                type: 'int',
                value: 1,
                squash: true,
                dynamic: true
            }
        },
        navbar: {
            getIcon: () => {
                return 'icon-award-trophy';
            },
            getLabel: (context) => {
                return context.t('RANK_PAGE_TITLE');
            },
            isActive: (context) => {
                return context.isActive(['auth.ranking.**']);
            },
            isVisible: (context) => {
                return (
                    (context.Right.hasRole('HAS_TA') && context.Right.hasRole('ROLE_INTERNAL')) ||
                    (context.Right.hasRole('HAS_TA_EXTERNAL') &&
                        !context.Right.hasRole('ROLE_INTERNAL'))
                );
            }
        }
    },
    { name: 'auth.ranking.badges', url: '/badges', component: Badges }
];
