import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { concat, first, flattenDeep, forEach, indexOf } from 'lodash-es';
import { useAuth } from 'providers/AuthContext';
import useTreeHook from 'hooks/useTree';
import dayjs from 'dayjs';
import { useTagContext } from '../../../pages/Auth/providers/TagProvider';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../providers/AlertContext';
import useRight from '../../../hooks/useRight';
import { useConfig } from '../../../providers/ConfigProvider';
import useAttachment from '../../../hooks/useAttachment';
import { isImageAttachment, isViewableFile } from '../../../helpers/attachment';
import { useDevice } from '../../../providers/DeviceContext';
import { useTagModal } from '../../../pages/Auth/providers/TagModalProvider';
import useLikeService from '../../../api/useLikeService';
import useUser from '../../../hooks/useUser';

const PostContext = createContext();

const PostProvider = ({
    children,
    currentPost,
    hideForSearch,
    isPostPreview,
    consultations,
    download,
    tagIds,
    slot,
    forceUnconfirmedStyle,
    alreadyLiked: alreadyLikedProp,
    inCarousel = false,
    tagId,
    defaultTemplate
}) => {
    const DEFAULT_PAGES = [
        {
            label: 'Default page'
        }
    ];
    const { params } = useCurrentStateAndParams();
    const { user } = useAuth();
    const { hasRole, isAllowToRead: isAllowToReadWorkspace } = useRight();
    const { workspacePermissionCheck } = useRight();
    const { t } = useTranslation();
    const { allTags, tags, adminTags: sharedTags } = useTagContext();
    const { showToast, showActionSheet } = useAlert();
    const { config } = useConfig();
    const {
        downloadFn,
        forgeAllPostAttachmentsUrl,
        forgeTmpAttachmentUrl,
        forgeAttachmentUrl,
        getPhotoGridObject
    } = useAttachment();
    const { isAdmin } = useRight();
    const Tree = useTreeHook();
    const TagModal = useTagModal();
    const LikeService = useLikeService();
    const { goToProfileLink } = useUser();

    const [post, setPost] = useState({
        ...currentPost,
        editPost: false,
        doComment: currentPost.comments && currentPost.comments.length > 0,
        focusComment: false
    });
    const [alreadyLiked, setAlreadyLiked] = useState(alreadyLikedProp);
    const [template, setTemplate] = useState(defaultTemplate);
    const [hasCampaigns, setHasCampaigns] = useState(false);
    const [forceDefaultView, setForceDefaultView] = useState(false);
    const [isHome, setIsHome] = useState(false);
    const [adminTags, setAdminTags] = useState([]);
    const [adminTagsFullyLoaded, setAdminTagsFullyLoaded] = useState(false);
    const [relevantTag, setRelevantTag] = useState(null);
    const [hideAll, setHideAll] = useState(false);
    const [hasImages, setHasImages] = useState(false);
    const [forceShowFeedback, setForceShowFeedback] = useState(false);
    const [attachmentPhotoImages, setAttachmentPhotoImages] = useState([]);
    const [showComments, setShowComments] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [resumeLabel, setResumeLabel] = useState(t('TAG_ADD_RESUME'));
    const router = useRouter();

    useEffect(() => {
        if (allTags && allTags.length > 0) {
            const sharedTag = allTags.find((tag) => {
                return tag.is_admin;
            });

            if (sharedTag) {
                setRelevantTag(sharedTag);
            } else {
                setRelevantTag(first(allTags));
            }
        }
    }, [allTags]);

    useEffect(() => {
        const init = () => {
            if (
                !router.globals.current.name.includes('category') &&
                !router.globals.current.name.includes('tag')
            ) {
                setIsHome(true);
            }

            if (router.globals.current.name.includes('auth.feed.posts') && template === null) {
                setTemplate('default');
            }

            if (router.globals.current.name.includes('auth.feed.stacked') && template === null) {
                setTemplate('stacked');
            }

            if (isAdminTagFeed() && post.resume) {
                setHideAll(true);
            }

            if (isPostOutdated()) {
                setHideAll(true);
            }

            if (post.attachments && post.attachments.length > 0) {
                setAttachmentPhotoImages(
                    getPhotoGridObject(
                        post.attachments.filter((a) => !a.thumbnail),
                        isPostPreview
                    )
                );
                setHasImages(
                    post.attachments.filter((a) => {
                        return !a.thumbnail && isImageAttachment(a);
                    }).length > 0
                );
            }
        };

        init();
    }, []);

    const isAdminTagFeed = () => {
        return false;
    };

    const isReported = () => {
        if (!post.reports || (post.reports && post.reports.length === 0)) {
            return false;
        }

        let found = false;

        forEach(post.reports, (value) => {
            if (value.user && value.user.id === user.id) {
                found = true;
            }
        });

        return found;
    };

    const isSecurityLevelReadonly = () => {
        return !!post.security_level && post.security_level === 3;
    };

    const commentSubmitCallbackHandler = () => {};

    const isUserReported = () => {
        if (post.is_campaign) {
            return;
        }

        if (user.users_reported === undefined || user.users_reported.length === 0) {
            return false;
        }

        let found = false;

        forEach(user.users_reported, (value) => {
            if (value === post.user.id) {
                found = true;
            }
        });

        return found;
    };

    const getTemplateName = () => {
        if (forceDefaultView) {
            return `default`;
        }

        return template;
    };

    const isAllowToRead = () => {
        return (
            hasRole('ROLE_ADMIN') ||
            isWorkspaceAdmin() ||
            user.id === post.user.id ||
            (post.targetUsers &&
                post.targetUsers.length > 0 &&
                post.targetUsers.some((user) => {
                    return user.id === user.id;
                })) ||
            isAllowToReadWorkspace(post.workspace.id)
        );
    };

    const isWorkspaceAdmin = () => {
        return (
            workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN') ||
            (post.workspace.owner && user.id === post.workspace.owner.id)
        );
    };

    const canReviewPost = () => {
        if (!post.waiting_for_review) {
            return false;
        }

        if (post.drafted_at) {
            return false;
        }

        let canEditPostSlot = false;
        //let canEditPostSlot = slot ? Track.isTrackModerator(slot.track) : user.isTrackAdmin;

        return (
            (isWorkspaceAdmin() && router.globals.current.name.includes('auth.posts.review')) ||
            (hasRole('ROLE_ADMIN') && router.globals.current.name.includes('auth.admin.review')) ||
            (canEditPostSlot && router.globals.current.name.includes('auth.slots'))
        );
    };

    const displayStackedSlotReview = () => {
        return router.globals.current.name.includes('auth.slots');
    };

    const canEditReviewPost = () => {
        return (
            hasRole('ROLE_ADMIN') ||
            (post.workspace.allow_workspace_admin_to_edit_all_posts &&
                workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN'))
        );
    };

    const isNew = () => {
        if (indexOf(concat(user.unread_posts, user.own_unread_posts), parseInt(post.id)) === -1) {
            return false;
        }

        return true;
    };

    const showPostId = () => {
        return hasRole('SHOW_POST_ID');
    };

    const isPostOutdated = () => {
        if (!post.outdated_at) {
            return false;
        }

        const outdatedDate = dayjs(post.outdated_at);

        return outdatedDate.isBefore();
    };

    const isConsultationConfirmed = () => {
        if (router.globals.current.name.includes('auth.slots')) {
            return false;
        }

        return post.post_consultation_history && post.post_consultation_history.confirmed;
    };

    const hasFeedback = () => {
        return post.has_feedback;
    };

    const feedbackIsDone = (ignoreForceShowFeedback = false) => {
        return (
            (!post.has_feedback_action ||
                (post.has_feedback_action && post.post_consultation_history.feedback_confirmed)) &&
            (!post.has_feedback_rating ||
                (post.has_feedback_rating && post.post_consultation_history.feedback_rating)) &&
            (!forceShowFeedback || ignoreForceShowFeedback)
        );
    };

    const isAllowToComment = () => {
        if (
            post.post_status !== undefined &&
            post.post_status.final &&
            post.post_status.user !== undefined &&
            user.id !== post.post_status.user.id
        ) {
            return false;
        }

        if (post.locked && post.user.id !== user.id) {
            return false;
        }

        if (!post.workspace.only_writer_can_comment) {
            return true;
        }

        return workspacePermissionCheck({ id: post.workspace.id }, 'WRITE');
    };

    const isScheduledPost = () => {
        return (
            (router.globals.current.name.includes('auth.feed..posts.review') &&
                !!post.postdated_at) ||
            (router.globals.current.name.includes('auth.feed.posts.scheduled') &&
                post.user.id === user.id)
        );
    };

    const hasReactions = () => {
        return post.workspace.reactions !== undefined && post.workspace.reactions.length > 0;
    };

    const showComment = () => {
        return (
            !hasRole('HAS_LIKES') ||
            post.doComment ||
            hasReactions() ||
            router.globals.current.name.includes('auth.slots')
        );
    };

    const showCarousel = () => {
        console.log('ici');
    };

    const canComment = () => {
        if (hideAll || isPostPreview || isScheduledPost() || hideForSearch) {
            return false;
        }

        if (isAdminTagFeed() && isAllowToComment()) {
            return showComments;
        }
        return isAllowToComment() && showComment();
    };

    const workspaceHasCustomForm = () => {
        return post.workspace && post.workspace.workspace_form;
    };

    const hasCustomFieldWithRecipient = () => {
        if (!workspaceHasCustomForm()) {
            return false;
        }

        return (
            post.workspace.workspace_form.workspace_form_inputs.filter((item) => {
                return item.type === 'recipients';
            }).length > 0
        );
    };

    const isOwnerOfPostWorkspace = () => {
        return user.id === post.workspace?.owner?.id;
    };

    const showConsultationUsers = (event, postId) => {
        if (!hasRole('ROLE_ADMIN') && !isOwnerOfPostWorkspace()) {
            return;
        }
        /*$window.open(
            Posts.downloadConsultationsUsers(postId),
            '_blank'
        );*/
    };

    const showReactPeople = (reaction) => {
        if (hasRole('ROLE_HIDE_LIKE_LIST_USERS')) {
            return;
        }
        /*
        UsersLike.createPanel({
            reactionsResolver: PostReaction.getReactionsByPost(post.id)
        });*/
    };

    const showLikers = () => {
        if (hasRole('ROLE_HIDE_LIKE_LIST_USERS')) {
            return;
        }
        /*
        UsersLike.createPanel({
            likesResolver: Like.getLikesByPost(post.id)
        })*/
    };

    const canUserSeeConsultations = () => {
        return !(!hasRole('ROLE_ADMIN') && hasRole('POST_CONSULTATIONS_ONLY_FOR_ADMIN'));
    };

    const toggleComment = () => {
        setShowComments(true);

        setPost((prevState) => {
            return { ...prevState, focusComment: true, doComment: true };
        });

        setTimeout(() => {
            setPost((prevState) => {
                return { ...prevState, focusComment: false };
            });
        }, 50);
    };

    const like = () => {
        if (!post.inLikeProgress) {
            setPost((prevState) => {
                return {
                    ...prevState,
                    inLikeProgress: true
                };
            });

            let toggle = alreadyLiked ? 'deleteLike' : 'addLike';

            return LikeService[toggle](alreadyLiked ? alreadyLiked : post)
                .then((like) => {
                    setAlreadyLiked(like ? like.id : null);

                    if (like.id) {
                        setPost((prevState) => {
                            const likes = prevState.likes;
                            likes.push(like);

                            return {
                                ...prevState,
                                likes
                            };
                        });
                    } else {
                        setPost((prevState) => {
                            return {
                                ...prevState,
                                likes: prevState.likes.filter((like) => {
                                    return like.user.id !== user.id;
                                })
                            };
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error : ', err);
                })
                .finally(() => {
                    setPost((prevState) => {
                        return {
                            ...prevState,
                            inLikeProgress: false
                        };
                    });
                });
        }
    };

    const react = () => {};

    const canWriteResume = () => {
        let tagManagers = [];
        let isTagAdmin = false;

        if (post.resume) {
            setResumeLabel(t('TAG_MODIFY_RESUME'));
        }

        forEach(allTags, (tag) => {
            isTagAdmin = tag.admin;

            if (tag.admin) {
                tagManagers.push(tag.tagUsers);
            }
        });

        if (!isTagAdmin) {
            return;
        }

        return flattenDeep(tagManagers).some((user) => {
            return user.id === user.id;
        });
    };

    const isSinglePost = () => {
        //return this.$state.current.name === `auth.${this.Tree.getFilterableView()}.post` && this.post.id === this.$stateParams.post;
    };

    const allowToEdit = () => {
        if (isScheduledPost()) {
            return false;
        }

        if (router.globals.current.name.includes('auth.slots')) {
            if (post.drafted_at) {
                return false;
            }

            return !!(
                post.workspace.allow_workspace_admin_to_edit_all_posts &&
                workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN')
            );
        }

        return (
            (post.waiting_for_review && post.user.id === user.id && isSinglePost()) ||
            (post.workspace.allow_writer_to_edit_all_posts &&
                workspacePermissionCheck({ id: post.workspace.id }, 'WRITE')) ||
            (post.workspace.allow_workspace_admin_to_edit_all_posts &&
                workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN') &&
                !router.globals.current.name.includes('auth.feed.posts.review'))
        );
    };

    const goToTagPage = (clickedTag) => {
        let tagToRemoves = tags.filter((tag) => {
            return tag.admin;
        });

        let tagsUserIsAllowedToView = tags.filter((tag) => !tagToRemoves.includes(tag));
        tagsUserIsAllowedToView = concat(tagsUserIsAllowedToView, sharedTags);

        if (
            !tagsUserIsAllowedToView.some((tag) => {
                return tag.id === clickedTag.id;
            })
        ) {
            return showToast({
                title: t('TAG_NO_ACCESS'),
                duration: 2000
            });
        }

        if (clickedTag.isEssential && hasRole('HAS_ESSENTIAL_VIEW')) {
            router.stateService.go(
                'auth.essentials.feed',
                { selectedTags: [clickedTag.id] },
                { reload: true, inherit: false }
            );
        } else {
            //Tags.goToTag(clickedTag);
        }
    };

    const canTag = () => {
        return hasRole('SHOW_TAGS') && tags && tags.length > 0;
    };

    const addPostTags = () => {
        TagModal.createPanel({
            postTags: [...getTags()],
            post: post
        })
            .then((postTags) => {
                tagIds = postTags.map((postTag) => {
                    return postTag.id;
                });

                //processTags();
            })
            .catch(() => {});
    };

    const isTagged = () => {
        let tag = tags.find((tag) => {
            return tag.id === tagId;
        });

        return !!tag;
    };

    const canUserLockComments = () => {
        return (
            isAdmin() ||
            (post.workspace.owner && user.id === post.workspace.owner.id) ||
            workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN')
        );
    };

    const handleLockComments = () => {
        setPost((prevState) => {
            return { ...prevState, locked: !prevState.locked };
        });
        //Posts.putPostLocked(post.id, post.locked);
    };

    const hasAttachments = () => {
        let hasAttachments = false;

        if (post.workspace_form_input_values && post.workspace_form_input_values.length > 0) {
            post.workspace_form_input_values.forEach((workspaceFormInput) => {
                if (workspaceFormInput.workspace_form_input.type === 'files') {
                    if (
                        workspaceFormInput.attachments &&
                        workspaceFormInput.attachments.length >= 1
                    ) {
                        hasAttachments = true;
                    }
                }

                if (workspaceFormInput.workspace_form_input.type === 'file') {
                    if (workspaceFormInput.attachment && workspaceFormInput.attachment.hard_name) {
                        hasAttachments = true;
                    }
                }
            });
        }

        if (post.attachments && post.attachments.length >= 1) {
            hasAttachments = true;
        }

        return hasAttachments;
    };

    const showActions = () => {
        let buttons = [];

        if (canTag()) {
            let label = t('TAG_THAT_POST');

            if (isTagged()) {
                label = t('UPDATE_THE_TAG');
            }

            buttons.push({
                label: label,
                callback: () => {
                    addPostTags();
                }
            });
        }

        if (
            workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN') &&
            post.workspace.post_status &&
            post.workspace.post_status.length > 0 &&
            (post.post_status === undefined || !post.post_status.final) &&
            !isScheduledPost()
        ) {
            buttons.push({
                label: t('UPDATE_THE_STATUS'),
                callback: () => {
                    //editPostStatus();
                }
            });
        }

        if (canUserLockComments()) {
            buttons.push({
                label: t(post.locked ? 'UNLOCK_COMMENTS' : 'LOCK_COMMENTS'),
                callback: () => {
                    handleLockComments();
                }
            });
        }

        if (hasRole('ROLE_ADMIN') && !isScheduledPost()) {
            buttons.push({
                label: t('POST_UP'),
                callback: () => {
                    /*Posts.putPostUpdatedDate(post.id).then(() => {
                        showToast({
                            text: t('POST_UPDATED_OK'),
                            duration: 1500,
                        });
                    })*/
                }
            });
        }

        if (!workspaceHasCustomForm() && hasRole('ROLE_ADMIN')) {
            buttons.push({
                label: t('POST_DUPLICATE'),
                callback: () => {
                    if (inCarousel) {
                        //Carousel.initCarouselState();
                    }
                    /*
                    Composer.show({
                        fromPost: post,
                    });*/
                }
            });
        }

        buttons.push({
            label: t('COPY_LINK_ADDRESS_POST'),
            callback: () => {
                let domain = config.frontUrl ? config.frontUrl : config.serverUrl;
                /*
                Clipboard.write({
                    string: domain + $state.href(`auth.posts.post`, { category: post.workspace.category.id, workspace: post.workspace.id, post: post.id })
                }).then(() => {
                    Alert.showToast({
                        text: translate('POST_LINK_COPIED'),
                        duration: 3500,
                    });
                });*/
            }
        });

        if (post.workspace.posts_pdf) {
            buttons.push({
                label: t('PDF_EXPORT'),
                callback: () => {
                    //downloadPostAsPdf(post.id);
                }
            });
        }

        if (
            hasAttachments() &&
            (hasRole('ROLE_ADMIN') || workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN'))
        ) {
            buttons.push({
                label: t('DL_ALL_FILES'),
                callback: () => {
                    downloadFn(forgeAllPostAttachmentsUrl(post.id));
                }
            });
        }

        if (
            !hasRole('ROLE_INTERNAL') ||
            hasRole('HAS_REPORT_FOR_ALL_USERS') ||
            user.id === post.user.id ||
            (post.workspace.owner && user.id === post.workspace.owner.id) ||
            hasRole('ROLE_ADMIN')
        ) {
            if (
                (user.id === post.user.id && post.post_status === undefined) ||
                (user.id === post.user.id &&
                    post.post_status !== undefined &&
                    !post.post_status.final) ||
                (hasRole('HAS_ALLOW_ADMIN_TO_EDIT_POST') && hasRole('ROLE_ADMIN'))
            ) {
                if (
                    (!post.workspace.not_editable &&
                        (!post.workspace.post_must_be_reviewed ||
                            post.waiting_for_review ||
                            post.workspace.allow_user_to_edit_after_moderation ||
                            post.drafted_at)) ||
                    hasRole('ROLE_ADMIN')
                ) {
                    buttons.push({
                        label: t('POST_EDIT'),
                        callback: () => {
                            if (inCarousel) {
                                //Carousel.initCarouselState();
                            }
                            //editPost();
                        }
                    });
                }
            }

            if (
                (!hasRole('ROLE_INTERNAL') || hasRole('HAS_REPORT_FOR_ALL_USERS')) &&
                user.id !== post.user.id
            ) {
                let labelPostReport = t('POST_REPORT');
                let titleConfirm = t('POST_REPORT_CONFIRM_TITLE');
                let textConfirm = t('POST_REPORT_CONFIRM_TEXT');
                let buttonConfirmReport = t('REPORT');

                if (isReported()) {
                    labelPostReport = t('POST_UNREPORT_CONFIRM_TITLE');
                    titleConfirm = t('POST_UNREPORT_CONFIRM_TITLE');
                    textConfirm = t('POST_UNREPORT_CONFIRM_TEXT');
                    buttonConfirmReport = t('UNREPORT');
                }

                buttons.push({
                    label: labelPostReport,
                    callback: () => {
                        //reportPost(titleConfirm, textConfirm, buttonConfirmReport);
                    }
                });

                let labelUserReport = t('POST_REPORT_USER');
                let titleUserConfirm = t('POST_REPORT_USER_CONFIRM_TITLE');
                let textUserConfirm = t('POST_REPORT_USER_CONFIRM_TEXT');
                let buttonConfirmUserReport = t('REPORT');

                if (isUserReported()) {
                    labelUserReport = t('POST_UNREPORT_USER_CONFIRM_TITLE');
                    titleUserConfirm = t('POST_UNREPORT_USER_CONFIRM_TITLE');
                    textUserConfirm = t('POST_UNREPORT_USER_CONFIRM_TEXT');
                    buttonConfirmUserReport = t('UNREPORT');
                }

                buttons.push({
                    label: labelUserReport,
                    callback: () => {
                        //reportUser(titleUserConfirm, textUserConfirm, buttonConfirmUserReport);
                    }
                });
            }

            if (
                (user.id === post.user.id && !post.workspace.not_editable) ||
                (post.workspace.owner && user.id === post.workspace.owner.id) ||
                hasRole('ROLE_ADMIN')
            ) {
                buttons.push({
                    label: t('POST_DELETE'),
                    classes: ['red'],
                    callback: () => {
                        //removePost();
                    }
                });
            }
        }

        if (user.default_subscription_type !== 2 || user.id !== post.user.id) {
            if (post.user_is_subscribed) {
                buttons.push({
                    label: t('POST_UNFOLLOW'),
                    classes: ['red'],
                    callback: () => {
                        //  unSubscribe();
                    }
                });
            } else {
                buttons.push({
                    label: t('POST_FOLLOW'),
                    callback: () => {
                        //subscribeActionSheet();
                    }
                });
            }
        }

        showActionSheet({
            title: 'Options',
            buttons
        });
    };

    const hasTags = () => {
        return getTags().length > 0;
    };

    const getTags = () => {
        return allTags.filter((tag) => {
            return tagIds.indexOf(tag.id) !== -1;
        });
    };

    const goToUserProfile = (user) => {
        goToProfileLink(user);
    };

    const getPostLink = () => {
        if (hideForSearch) {
            return;
        }

        return Tree.getPostLink(post);
    };

    const goToPostFromDate = () => {
        if (!hideForSearch) {
            return;
        }

        //goToPreviewPost(post);
    };

    const isPostScheduledAndPostdated = () => {
        return isScheduledPost() && !!post.postdated_at;
    };

    const isModified = () => {
        return post.edited_at && post.edited_at !== post.created_at;
    };

    const showPostStatusLabel = () => {
        return (
            (post.post_status && router.globals.current.name !== 'auth.posts.workspace') ||
            (post.post_status && (params.status === '0' || !params.status))
        );
    };

    const showPostStatus = () => {
        if (isScheduledPost()) {
            return false;
        }

        return !!post.post_status;
    };

    const goToPostStatusPage = (workspace, postStatus) => {
        let params = {
            category: workspace.category.id,
            workspace: workspace.id,
            folder: null,
            status: postStatus.id
        };

        router.stateService.go(`auth.feed.posts.workspace`, params, {
            reload: true,
            inherit: false
        });
    };

    const canEditPostStatus = () => {
        return (
            (workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN') ||
                (post.workspace.can_writer_change_status && post.user.id === user.id)) &&
            post.workspace.post_status &&
            post.workspace.post_status.length > 0 &&
            (post.post_status === undefined || !post.post_status.final)
        );
    };

    const editPostStatus = () => {
        let buttons = [];

        post.workspace.post_status.forEach((postStatus) => {
            buttons.push({
                label: postStatus.action_label,
                icon: {
                    type: 'circle',
                    color: postStatus.color
                },
                callback: () => {
                    if (postStatus.final) {
                        let buttons = [];

                        buttons.push({
                            label: t('CONFIRM_FINAL_STATUS_COMMENT_CANCEL'),
                            callback: () => {
                                //handlePostStatusPoint(postStatus.id);
                            }
                        });

                        buttons.push({
                            label: t('CONFIRM_FINAL_STATUS_COMMENT_ACTION'),
                            callback: () => {
                                toggleComment();
                                post.nextStatusId = postStatus.id;
                            }
                        });

                        showActionSheet({
                            title: t('CONFIRM_FINAL_STATUS_COMMENT'),
                            text: t('CONFIRM_FINAL_STATUS_COMMENT_TEXT'),
                            buttons
                        });

                        return;
                    }

                    //handlePostStatusPoint(postStatus.id);
                }
            });
        });

        showActionSheet({
            title: t('UPDATE_THE_STATUS'),
            text: t('UPDATE_THE_STATUS_TEXT'),
            buttons: buttons
        });
    };

    const setConsultationConfirmed = (setTemplate = true) => {
        if (!isConsultationConfirmed()) {
            /*this.Posts.postPostConsultationConfirmed(this.post.id).then((postConsultation) => {
                this.post.post_consultation_history = {
                    ...postConsultation,
                    post: null,
                };

                this.processStackedComments(true);
            });*/
        } else {
            //processStackedComments(true);
        }

        if (setTemplate) {
            setForceDefaultView(true);

            if (isPostOutdated()) {
                //this.hideAll = false;
            }

            /*if (hasReadMore()) {
                setReadMore(true);
            }*/
        }
    };

    const getAttachmentUrl = (attachment, forceToken = false, before = false) => {
        if (isPostPreview) {
            return forgeTmpAttachmentUrl(attachment);
        }

        return forgeAttachmentUrl(attachment, false, null, forceToken, before);
    };

    const hasPages = () => {
        if (!workspaceHasCustomForm()) {
            return false;
        }

        let field = first(post.workspace.workspace_form.workspace_form_inputs);

        return field.type === 'page';
    };

    const getPages = () => {
        if (!hasPages()) {
            return DEFAULT_PAGES;
        }

        return post.workspace.workspace_form.workspace_form_inputs.filter((input) => {
            return input.type === 'page';
        });
    };

    const isCurrentPage = (page) => {
        return currentPage === page;
    };

    const isPositionInputValid = (input) => {
        return input.address && input.address !== '';
    };

    const getFieldsForPage = (page) => {
        let pages = getPages();
        let workspaceInputs = post.workspace.workspace_form.workspace_form_inputs;

        let currentPage = pages[page - 1];
        let nextPage = pages.length > page ? pages[page] : null;

        let currentPageIndex = workspaceInputs.findIndex((input) => {
            return input.id === currentPage.id;
        });

        if (currentPageIndex === -1) {
            return post.workspace_form_input_values;
        }

        let workspaceInputsForPage = [];

        if (!nextPage) {
            workspaceInputsForPage = workspaceInputs.slice(currentPageIndex);
        } else {
            let nextPageIndex = workspaceInputs.findIndex((input) => {
                return input.id === nextPage.id;
            });

            workspaceInputsForPage = workspaceInputs.slice(currentPageIndex, nextPageIndex);
        }

        return post.workspace_form_input_values.filter((inputValue) => {
            if (
                inputValue.workspace_form_input.type === 'position' &&
                !isPositionInputValid(inputValue)
            ) {
                return false;
            }

            return (
                workspaceInputsForPage.findIndex((inputForPage) => {
                    return inputForPage.id === inputValue.workspace_form_input.id;
                }) !== -1
            );
        });
    };

    const isAllowToSeeInput = (input) => {
        if (input.workspace_form_input.hidden_for_admin) {
            return false;
        }

        if (!input.workspace_form_input.hidden) {
            return true;
        }

        return !!(hasRole('ROLE_ADMIN') || isWorkspaceAdmin() || user.id === post.user.id);
    };

    const allowViewFile = (type) => {
        return post.security_level === 0 && isViewableFile(type);
    };

    const canEditDraft = () => {
        if (!post.slot) {
            return false;
        }

        if (post.user.id !== user.id) {
            return false;
        }

        return post.drafted_at;
    };

    const editDraftedPost = () => {
        if (inCarousel) {
            //this.Carousel.initCarouselState();
        }

        //editPost();
    };

    const getReactionsCount = () => {
        return;
    };

    return (
        <PostContext.Provider
            value={{
                post,
                setPost,
                consultations,
                download,
                tagIds,
                getTemplateName,
                isAllowToRead,
                hideForSearch,
                isPostPreview,
                canReviewPost,
                displayStackedSlotReview,
                canEditReviewPost,
                relevantTag,
                forceUnconfirmedStyle,
                isNew,
                showPostId,
                isPostOutdated,
                isConsultationConfirmed,
                hideAll,
                hasFeedback,
                feedbackIsDone,
                forceDefaultView,
                setForceDefaultView,
                canComment,
                hasCustomFieldWithRecipient,
                commentSubmitCallbackHandler,
                isReported,
                isUserReported,
                hasReactions,
                showConsultationUsers,
                canUserSeeConsultations,
                showReactPeople,
                showLikers,
                isScheduledPost,
                toggleComment,
                like,
                react,
                alreadyLiked,
                isAllowToComment,
                isOwnerOfPostWorkspace,
                resumeLabel,
                canWriteResume,
                goToTagPage,
                canTag,
                addPostTags,
                showActions,
                goToUserProfile,
                getPostLink,
                goToPostFromDate,
                isPostScheduledAndPostdated,
                isModified,
                showPostStatus,
                showPostStatusLabel,
                goToPostStatusPage,
                canEditPostStatus,
                editPostStatus,
                hasTags,
                getTags,
                allowToEdit,
                hasImages,
                attachmentPhotoImages,
                isSecurityLevelReadonly,
                setConsultationConfirmed,
                getAttachmentUrl,
                getPages,
                hasPages,
                isCurrentPage,
                getFieldsForPage,
                isAllowToSeeInput,
                workspaceHasCustomForm,
                allowViewFile,
                canEditDraft,
                editDraftedPost,
                getReactionsCount,
                showCarousel
            }}>
            {children}
        </PostContext.Provider>
    );
};

export const usePost = () => useContext(PostContext);

export default PostProvider;
