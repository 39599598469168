import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { defaultTo, isEmpty } from 'lodash-es';
import useMetricService from '../../../api/useMetricService';
import { useQuery } from '@tanstack/react-query';
import useRight from '../../../hooks/useRight';

const Global = () => {
    const { t } = useTranslation();
    const { getMetricsData } = useMetricService();
    const [uniqueConnectionHovering, setUniqueConnectionHovering] = useState(false);
    const [bdrHovering, setBdrHovering] = useState(false);
    const [totaConnectionsHovering, setTotaConnectionsHovering] = useState(false);
    const [utilisationRateHovering, setUtilisationRateHovering] = useState(false);
    const [retentionHovering, setRetentionHovering] = useState(false);
    const [activationRateHovering, setActivationRateHovering] = useState(false);
    const [totalActivationsHovering, setTotalActivationsHovering] = useState(false);
    const [activationsHovering, setActivationsHovering] = useState(false);
    const [activeTab, setActiveTab] = useState('byLevels');
    const { params } = useCurrentStateAndParams();
    const [isCustom, setIsCustom] = useState(params.period === 'custom');
    const router = useRouter();
    const { hasRole } = useRight();

    const { data: metricsData, isLoading } = useQuery({
        queryFn: () => {
            return getMetricsData({ ...params });
        },
        queryKey: ['metrics_global', params.period, params.startDate, params.endDate]
    });

    if (!isLoading) {
        // console.log('Metrics Dataaaa:', metricsData);
    }

    const handleChangePeriod = (value) => {
        console.log('handleChangePeriod');

        if (value === 'custom') {
            setIsCustom(true);
        } else {
            router.stateService.go('.', { ...params, period: value });
        }
    };

    const handleChangeDates = (value) => {
        router.stateService.go('.', {
            period: 'custom',
            startDate: value[0].format('YYYY-MM-DD'),
            endDate: value[1].format('YYYY-MM-DD')
        });
    };

    const hasSocketsData = () => {
        console.log(
            'hasSocketsData',
            metricsData,
            !isEmpty(metricsData?.exploitation.sockets_data)
        );
        return !isEmpty(metricsData?.exploitation.sockets_data);
    };

    const displayBDR = () => {
        return true;
        return !hasRole('METRICS_HIDE_BDR');
    };

    return (
        <div className="header">
            <div className="top">
                <div className="title">{t('METRICS')}</div>
            </div>

            <div className="filters">
                <div className="periods">
                    <div className="select">
                        <Select
                            style={{ width: '100%' }}
                            onChange={handleChangePeriod}
                            defaultValue={params.period}
                            options={[
                                {
                                    label: '7 jours',
                                    value: 'last_week'
                                },
                                {
                                    label: '30 jours',
                                    value: 'last_month'
                                },
                                {
                                    label: '90 jours',
                                    value: 'last_three_monthes'
                                },
                                {
                                    label: 'Dates personnalisées',
                                    value: 'custom'
                                }
                            ]}
                        />
                    </div>
                </div>

                {isCustom && (
                    <div className="dates">
                        <DatePicker.RangePicker
                            showTime={false}
                            defaultValue={[new dayjs(params.startDate), new dayjs(params.endDate)]}
                            format="YYYY-MM-DD"
                            maxDate={new dayjs()}
                            onChange={handleChangeDates}
                        />
                    </div>
                )}

                <div className="buttons"></div>
            </div>

            <div className="body stats">
                <div
                    className={`stat green ${uniqueConnectionHovering ? 'hovered' : ''}`}
                    onMouseOver={() => setUniqueConnectionHovering(true)}
                    onMouseLeave={() => setUniqueConnectionHovering(false)}>
                    <div className="title">
                        {!uniqueConnectionHovering
                            ? 'Connexions uniques'
                            : "Nombre d'utilisateurs qui se sont connectés au moins une fois sur la période"}
                    </div>

                    <div className="data">
                        {!hasSocketsData() ? (
                            metricsData?.exploitation.total_unique_connections
                        ) : (
                            <>
                                {uniqueConnectionHovering ? (
                                    ''
                                ) : (
                                    <>
                                        {metricsData?.exploitation.total_unique_connections}
                                        <div className="sockets-data">
                                            <small>
                                                desktop :{' '}
                                                {
                                                    metricsData?.exploitation.sockets_data
                                                        .connections.desktop_connections
                                                }
                                            </small>
                                            <small>
                                                mobile :{' '}
                                                {
                                                    metricsData?.exploitation.sockets_data
                                                        .connections.mobile_connections
                                                }
                                            </small>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {displayBDR() && (
                    <div
                        className={`stat green ${bdrHovering ? 'hovered' : ''}`}
                        onMouseOver={() => setBdrHovering(true)}
                        onMouseLeave={() => setBdrHovering(false)}>
                        <div className="title">
                            {!bdrHovering
                                ? 'BDR'
                                : 'Pourcentage de jours ouvrés où les utilisateurs se sont connectés au moins 1 fois'}
                        </div>
                        <div className="data">
                            {!bdrHovering && `${metricsData?.business_day_ratio} %`}
                        </div>
                    </div>
                )}

                {/*{hasSocketsData && (*/}
                {/*    <div*/}
                {/*        className={`stat green ${totaConnectionsHovering ? 'hovered' : ''}`}*/}
                {/*        onMouseOver={() => setTotaConnectionsHovering(true)}*/}
                {/*        onMouseLeave={() => setTotaConnectionsHovering(false)}*/}
                {/*    >*/}
                {/*        <div className="title">*/}
                {/*            {!totaConnectionsHovering ? 'Connexions brutes' : 'Nombre total de connexions de tous les utilisateurs sur la période'}*/}
                {/*        </div>*/}
                {/*        <div className="data">*/}
                {/*            {!totaConnectionsHovering && (*/}
                {/*                <>*/}
                {/*                    {metricsData.exploitation.sockets_data.brut_connections.total_connections}*/}
                {/*                    <div className="sockets-data">*/}
                {/*                        <small>desktop : {metricsData.exploitation.sockets_data.brut_connections.desktop_connections}</small>*/}
                {/*                        <small>mobile : {metricsData.exploitation.sockets_data.brut_connections.mobile_connections}</small>*/}
                {/*                    </div>*/}
                {/*                </>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                {/*<div*/}
                {/*    className={`stat green ${utilisationRateHovering ? 'hovered' : ''}`}*/}
                {/*    onMouseOver={() => setUtilisationRateHovering(true)}*/}
                {/*    onMouseLeave={() => setUtilisationRateHovering(false)}*/}
                {/*>*/}
                {/*    <div className="title">*/}
                {/*        {!utilisationRateHovering ? "Taux d'utilisation" : "Connexions uniques sur la période divisées par l'effectif"}*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {!utilisationRateHovering && `${metricsData.exploitation.utilisation_rate_current_period} %`}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="stat green">*/}
                {/*    <div className="title">*/}
                {/*        Effectif*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {metricsData.activation.total_users}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*{hasSocketsData && (*/}
                {/*    <div className="stat green">*/}
                {/*        <div className="title">*/}
                {/*            Durée moyenne de session journalière*/}
                {/*        </div>*/}
                {/*        <div className="data">*/}
                {/*            {getAverageSocketDuration()}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                {/*<div*/}
                {/*    className={`stat green ${retentionHovering ? 'hovered' : ''}`}*/}
                {/*    onMouseOver={() => setRetentionHovering(true)}*/}
                {/*    onMouseLeave={() => setRetentionHovering(false)}*/}
                {/*>*/}
                {/*    <div className="title">*/}
                {/*        {!retentionHovering ? "Taux de rétention" : "Connexions uniques sur la période divisées par le nombre d'utilisateurs ayant activé leur compte"}*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {!retentionHovering && `${getRetention()} %`}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className={`stat blue ${activationRateHovering ? 'hovered' : ''}`}*/}
                {/*    onMouseOver={() => setActivationRateHovering(true)}*/}
                {/*    onMouseLeave={() => setActivationRateHovering(false)}*/}
                {/*>*/}
                {/*    <div className="title">*/}
                {/*        {!activationRateHovering ? "Taux d'activation" : "Utilisateurs ayant activé leur compte divisé par l'effectif"}*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {!activationRateHovering && `${metricsData.activation.activation_rate} %`}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className={`stat blue ${totalActivationsHovering ? 'hovered' : ''}`}*/}
                {/*    onMouseOver={() => setTotalActivationsHovering(true)}*/}
                {/*    onMouseLeave={() => setTotalActivationsHovering(false)}*/}
                {/*>*/}
                {/*    <div className="title">*/}
                {/*        {!totalActivationsHovering ? "Effectif actif" : "Effectif des utilisateurs ayant activé leur compte"}*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {!totalActivationsHovering && metricsData.activation.total_activations}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className={`stat blue ${activationsHovering ? 'hovered' : ''}`}*/}
                {/*    onMouseOver={() => setActivationsHovering(true)}*/}
                {/*    onMouseLeave={() => setActivationsHovering(false)}*/}
                {/*>*/}
                {/*    <div className="title">*/}
                {/*        {!activationsHovering ? "Nouveaux inscrits" : "Nombre d'utilisateurs ayant activé leur compte sur la période"}*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {!activationsHovering && metricsData.activation.activations_for_period}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="stat tomato">*/}
                {/*    <div className="title">*/}
                {/*        Nombre de posts*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {metricsData.activity.total_posts}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="stat tomato">*/}
                {/*    <div className="title">*/}
                {/*        Nombre de likes / reactions*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {metricsData.activity.total_likes}*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="stat tomato">*/}
                {/*    <div className="title">*/}
                {/*        Nombre de commentaires*/}
                {/*    </div>*/}
                {/*    <div className="data">*/}
                {/*        {metricsData.activity.total_comments}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Global;
