import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
const isBetween = require('dayjs/plugin/isBetween');
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
require('dayjs/locale/fr');
require('dayjs/locale/en');
dayjs.locale('en');

const resources = {
    fr: {
        translation: translationFR
    },
    en: {
        translation: translationEN
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
