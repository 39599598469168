import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-use';
import { useRouter } from '@uirouter/react';
import useBlog from '../../hooks/useBlog';
import classes from 'classnames';
import { useDevice } from 'providers/DeviceContext';

const MainWrapper = ({ children }) => {
    const router = useRouter();
    const location = useLocation();
    const { isBlogState } = useBlog();
    const { keyboardWillShow } = useDevice();

    const [currentStateName, setCurrentStateName] = useState(router.globals.current.name);

    useEffect(() => {
        setCurrentStateName(router.globals.current.name);
    }, [location, router.globals.current.name]);

    const stateNameAsClass = useMemo(() => {
        let stateName = currentStateName.replace(/\./g, '-');

        if (isBlogState()) {
            return `blog ${stateName}`;
        }

        if (stateName.indexOf('auth.admin') !== -1) {
            return `admin ${stateName}`;
        }

        if (stateName.indexOf('auth-missions-dashboard') !== -1) {
            return `auth-missions-dashboard ${stateName}`;
        }

        return stateName.trim();
    }, [currentStateName]);

    const keyboardClass = useMemo(() => {
        return keyboardWillShow ? 'keyboard-open' : 'keyboard-close';
    }, [keyboardWillShow]);

    return (
        <div className={classes(['main', stateNameAsClass, keyboardClass])}>
            <div className={'view'}>{children}</div>
        </div>
    );
};

export default MainWrapper;
