import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBlog from 'hooks/useBlog';
import useRight from 'hooks/useRight';
import { useConfig } from 'providers/ConfigProvider';
import { UIView, useCurrentStateAndParams, useRouter } from '@uirouter/react';
import Loader from 'components/Loader/Loader';
import BlogProvider, { useBlog as useBlogContext } from './providers/BlogProvider';
import useViewport from 'hooks/useViewport';
import { defaultTo } from 'lodash-es';

const BlogContent = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const viewport = useViewport();
    const { isPublicState, isAdminRoute, getLocalizedCategoryTitle, getArticleTypeIcon } =
        useBlog();
    const { hasRole } = useRight();
    const { config } = useConfig();
    const { params } = useCurrentStateAndParams();
    const { showNavigation, categories, currentCategory, currentArticleType } = useBlogContext();
    const [displayOptions, setDisplayOptions] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showArticles, setShowArticles] = useState(false);
    const [blogSearch, setBlogSearch] = useState({
        query: defaultTo(params.query, null),
        onlyTitle: defaultTo(params.onlyTitle, false),
        exactPhrase: defaultTo(params.exactPhrase, false)
    });

    const getCurrentCategoryName = () => {
        if (!currentCategory) {
            return t('BLOG_ALL_CATEGORIES');
        }

        return getLocalizedCategoryTitle(currentCategory);
    };

    const getCurrentArticleType = () => {
        switch (currentArticleType) {
            case 'article':
                return t('BLOG_ALL_WRITINGS');
            case 'video':
                return t('BLOG_ALL_VIDEOS');
            case 'podcast':
                return t('BLOG_ALL_PODCASTS');
            case 'gallery':
                return t('BLOG_ALL_GALLERIES');
            default:
                return t('BLOG_ALL_ARTICLES');
        }
    };

    const goAllCategories = () => {
        if (viewport.gtXs) {
            router.stateService.go(
                'auth.blog.articles',
                { category: null, page: 1 },
                { reload: true }
            );
        } else {
            setShowCategories(!showCategories);
            setShowArticles(false);
        }
    };

    const goAllArticles = () => {
        if (viewport.gtXs) {
            router.stateService.go('auth.blog.articles', { type: null, page: 1 }, { reload: true });
        } else {
            setShowCategories(false);
            setShowArticles(!showArticles);
        }
    };

    const searchBlog = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!blogSearch.query) {
            return;
        }

        let categoryId = currentCategory ? currentCategory.id : null;

        if (isAdminRoute()) {
            return router.stateService.go(
                'auth.blog.admin-search',
                {
                    query: blogSearch.query,
                    category: categoryId,
                    onlyTitle: blogSearch.onlyTitle,
                    exactPhrase: blogSearch.exactPhrase,
                    page: 1
                },
                { inherit: false, reload: true }
            );
        }

        return router.stateService.go(
            'auth.blog.search',
            {
                query: blogSearch.query,
                category: categoryId,
                onlyTitle: blogSearch.onlyTitle,
                exactPhrase: blogSearch.exactPhrase,
                page: 1
            },
            { inherit: false, reload: true }
        );
    };

    return (
        <div className="blog">
            <div className="navigation">
                <div className="container">
                    {!isPublicState() && (
                        <div className="back" ng-click="goBack()">
                            <div className="icon">
                                <i className="icon-house-chimney-1" aria-hidden="true"></i>
                            </div>
                        </div>
                    )}

                    {!isAdminRoute() && (
                        <ul className="menu">
                            {!showNavigation && !hasRole('BLOG_HIDE_LOGO') && (
                                <li className="logo">
                                    <a href="#">
                                        <img
                                            src={
                                                config.long_blog_logo_url
                                                    ? config.long_blog_logo_url
                                                    : config.blog_logo_url
                                            }
                                            alt={'Blog logo'}
                                        />
                                    </a>
                                </li>
                            )}

                            {showNavigation && !hasRole('BLOG_HIDE_LOGO') && (
                                <li className="logo">
                                    <a href={router.stateService.href('auth.blog.articles')}>
                                        <img
                                            src={
                                                config.long_blog_logo_url
                                                    ? config.long_blog_logo_url
                                                    : config.blog_logo_url
                                            }
                                            alt={'Blog logo'}
                                        />
                                    </a>
                                </li>
                            )}

                            {showNavigation && (
                                <li className="item">
                                    <a className="has-dropdown" onClick={goAllCategories}>
                                        {getCurrentCategoryName()}
                                    </a>

                                    <ul
                                        className={`dropdown ${viewport.xs && showCategories ? 'is-visible' : ''} ${hasRole('BLOG_HIDE_LOGO') ? 'no-logo' : ''}`}
                                    >
                                        <a
                                            className="title"
                                            href={router.stateService.href('auth.blog.articles', {
                                                category: null,
                                                page: 1
                                            })}
                                        >
                                            {t('BLOG_ALL_CATEGORIES')}
                                        </a>

                                        <div className="categories">
                                            {categories &&
                                                categories.map((category) => {
                                                    return (
                                                        <div
                                                            className="category"
                                                            key={`category_${category.id}`}
                                                        >
                                                            <a
                                                                className="title"
                                                                href={router.stateService.href(
                                                                    'auth.blog.articles',
                                                                    {
                                                                        category: category.id,
                                                                        page: 1
                                                                    }
                                                                )}
                                                            >
                                                                {getLocalizedCategoryTitle(
                                                                    category
                                                                )}
                                                            </a>

                                                            {category.children?.length > 0 && (
                                                                <ul className="children">
                                                                    {category.children.map(
                                                                        (child) => {
                                                                            return (
                                                                                <li
                                                                                    className="item"
                                                                                    key={`category_child_${child.id}`}
                                                                                >
                                                                                    <a
                                                                                        href={router.stateService.href(
                                                                                            'auth.blog.articles',
                                                                                            {
                                                                                                category:
                                                                                                    child.id,
                                                                                                page: 1
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        {getLocalizedCategoryTitle(
                                                                                            child
                                                                                        )}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </ul>
                                </li>
                            )}

                            {showNavigation && !hasRole('BLOG_HIDE_TYPE_FILTERS') && (
                                <li className="item">
                                    <a className="has-dropdown" onClick={goAllArticles}>
                                        {getCurrentArticleType()}
                                    </a>

                                    <ul
                                        className={`dropdown ${viewport.xs && showArticles ? 'is-visible' : ''}`}
                                    >
                                        <a
                                            className="title"
                                            href={router.stateService.href('auth.blog.articles', {
                                                type: null,
                                                page: 1
                                            })}
                                        >
                                            {t('BLOG_ALL_ARTICLES')}
                                        </a>

                                        <div className="types">
                                            <div className="type">
                                                <div className="icon">
                                                    <i
                                                        className={getArticleTypeIcon({
                                                            type: 'article'
                                                        })}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>

                                                <a
                                                    href={router.stateService.href(
                                                        'auth.blog.articles',
                                                        { type: 'article', page: 1 }
                                                    )}
                                                >
                                                    <div className="name">
                                                        {t('BLOG_TYPE_NAME_ARTICLES')}
                                                    </div>
                                                    <div className="legend">
                                                        {t('BLOG_ALL_WRITINGS')}
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="type">
                                                <div className="icon">
                                                    <i
                                                        className={getArticleTypeIcon({
                                                            type: 'video'
                                                        })}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>

                                                <a
                                                    href={router.stateService.href(
                                                        'auth.blog.articles',
                                                        { type: 'video', page: 1 }
                                                    )}
                                                >
                                                    <div className="name">
                                                        {t('BLOG_TYPE_NAME_VIDEOS')}
                                                    </div>
                                                    <div className="legend">
                                                        {t('BLOG_ALL_VIDEOS')}
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="type">
                                                <div className="icon">
                                                    <i
                                                        className={getArticleTypeIcon({
                                                            type: 'podcast'
                                                        })}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>

                                                <a
                                                    href={router.stateService.href(
                                                        'auth.blog.articles',
                                                        { type: 'podcast', page: 1 }
                                                    )}
                                                >
                                                    <div className="name">
                                                        {t('BLOG_TYPE_NAME_PODCASTS')}
                                                    </div>
                                                    <div className="legend">
                                                        {t('BLOG_ALL_PODCASTS')}
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="type">
                                                <div className="icon">
                                                    <i
                                                        className={getArticleTypeIcon({
                                                            type: 'gallery'
                                                        })}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>

                                                <a
                                                    href={router.stateService.href(
                                                        'auth.blog.articles',
                                                        { type: 'gallery', page: 1 }
                                                    )}
                                                >
                                                    <div className="name">
                                                        {t('BLOG_TYPE_NAME_GALLERIES')}
                                                    </div>
                                                    <div className="legend">
                                                        {t('BLOG_ALL_GALLERIES')}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    )}

                    {isAdminRoute() && (
                        <ul className="menu">
                            <li className="logo">
                                <a
                                    ui-sref="auth.blog.articles"
                                    ui-sref-opts="{ reload: true, inherit: false }"
                                >
                                    <img
                                        src={
                                            config.long_blog_logo_url
                                                ? config.long_blog_logo_url
                                                : config.blog_logo_url
                                        }
                                    />
                                </a>
                            </li>

                            <li className="item">
                                <a
                                    href={router.stateService.href('auth.blog.admin', {
                                        tab: 'articles'
                                    })}
                                >
                                    {t('BLOG_TYPE_NAME_ARTICLES')}
                                </a>
                            </li>

                            <li className="item">
                                <a
                                    href={router.stateService.href('auth.blog.admin', {
                                        tab: 'categories'
                                    })}
                                >
                                    {t('BLOG_TYPE_NAME_CATEGORIES')}
                                </a>
                            </li>
                        </ul>
                    )}

                    {!isPublicState() && (
                        <div className="search">
                            <form className="search-form" onSubmit={searchBlog}>
                                <div className="form-main">
                                    <div className="form-search">
                                        <button type="submit">
                                            <i className="fa fa-search" aria-hidden="false"></i>
                                        </button>

                                        <input
                                            type="text"
                                            placeholder={t('TOP_SEARCH')}
                                            onChange={(e) => {
                                                setBlogSearch((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        query: e.target.value
                                                    };
                                                });
                                            }}
                                            value={blogSearch.query}
                                        />

                                        <div
                                            className={`form-more-options is-clickable ${displayOptions ? 'opened' : ''}`}
                                            onClick={() => {
                                                setDisplayOptions(!displayOptions);
                                            }}
                                        >
                                            <span>{t('BLOG_MORE_OPTIONS')}</span>

                                            <span
                                                className={`icon ${displayOptions ? 'inverted' : ''}`}
                                            >
                                                <i
                                                    className="fa icon-move-bottom-left"
                                                    aria-hidden="false"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {displayOptions && (
                                    <div className="form-options">
                                        <div className="form-options-title">
                                            <div className="option-title">
                                                {t('BLOG_ONLY_TITLE')}
                                            </div>
                                            <input
                                                className="input"
                                                id="only-title"
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setBlogSearch((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            onlyTitle: e.target.checked
                                                        };
                                                    });
                                                }}
                                                checked={blogSearch.onlyTitle}
                                            />
                                            <label className="label" htmlFor="only-title"></label>
                                        </div>
                                        <div className="form-options-exact-phrase">
                                            <div className="option-title">
                                                {t('BLOG_EXACT_PHRASE')}
                                            </div>
                                            <input
                                                className="input"
                                                id="exact-phrase"
                                                type="checkbox"
                                                onChange={(e) => {
                                                    setBlogSearch((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            exactPhrase: e.target.checked
                                                        };
                                                    });
                                                }}
                                                checked={blogSearch.exactPhrase}
                                            />
                                            <label className="label" htmlFor="exact-phrase"></label>
                                        </div>
                                        <div className="form-options-search">
                                            <button className="btn" type="submit">
                                                <i className="fa fa-search" aria-hidden="false"></i>{' '}
                                                {t('TOP_SEARCH')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    )}

                    {isPublicState() && (
                        <div className="connect" ng-click="goToLogin()">
                            {t('LOGIN_CONNECTION')}
                        </div>
                    )}
                </div>
            </div>

            <div className="body">
                <div className="container">
                    <div className="view">
                        <UIView />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Blog = () => {
    return (
        <div className="blog-wrapper">
            <BlogProvider>
                <BlogContent />
            </BlogProvider>
        </div>
    );
};

export default Blog;
