import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePost } from '../../providers/PostProvider';

const PostReport = () => {
    const { isReported, isUserReported } = usePost();
    const { t } = useTranslation();
    return (
        <>
            {isReported() && !isUserReported() && (
                <div className="reported">{t('POST_REPORTED')}</div>
            )}
            {isUserReported() && <div className="reported">{t('USER_REPORTED')}</div>}
        </>
    );
};

export default PostReport;
