import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection,
    ModalFormTabs
} from 'components/Form/ModalForm';
import {
    IconPicker,
    Input,
    Checkbox,
    Textarea,
    RichText,
    FileButton
} from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';

const SponsorshipClientFormModalContent = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState('Programme');
    const {
        contexts: {
            'add_sponsorship-program': { currentSponsorshipProgram }
        },
        closeModal
    } = useModal();
    const methods = useForm({
        defaultValues: currentSponsorshipProgram
    });

    const onFinish = (data) => {
        console.log('data', data);
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormTabs
                tabs={['Programme', 'Template']}
                onTabChange={(tab) => {
                    setCurrentTab(tab);
                }}
            />
            <ModalFormBody>
                {currentTab === 'Programme' ? (
                    <>
                        <ModalFormSection>
                            <Input
                                name={'label'}
                                label={t('RECONNECT_PROGRAM_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'label_public'}
                                label={t('RECONNECT_PROGRAM_PUBLIC_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'email_from_name'}
                                label={t('RECONNECT_PROGRAM_EMAIL_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'acceptation_delay'}
                                label={t('RECONNECT_PROGRAM_ACCEPTATION_DELAY')}
                                type={'number'}
                                shouldUnregister={false}
                            />
                            <IconPicker
                                name={'icon'}
                                label={t('RECONNECT_PROGRAM_ICON_LABEL')}
                                shouldUnregister={false}
                            />
                        </ModalFormSection>
                        <ModalFormSection>
                            <div>
                                <label htmlFor="">{t('RECONNECT_PROGRAM_SMS_CONTENT_LABEL')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea name={'sms_content'} shouldUnregister={false} />
                            </div>
                            <div>
                                <label htmlFor="">
                                    {t('RECONNECT_PROGRAM_EMAIL_CONTENT_LABEL')}
                                </label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_EMAIL_BBCODE_LABEL')}
                                </div>
                                <RichText name={'email_content'} shouldUnregister={false} />
                            </div>
                        </ModalFormSection>
                        <ModalFormSection>
                            <Checkbox
                                name={'options[agency]'}
                                label={t('RECONNECT_PROGRAM_CONTACT_BY_AGENCY_LABEL')}
                                shouldUnregister={false}
                            />
                            <Checkbox
                                name={'options[cael]'}
                                label={t('RECONNECT_PROGRAM_CONTACT_BY_CAEL_LABEL')}
                                shouldUnregister={false}
                            />
                            <Checkbox
                                name={'options[other_agency]'}
                                label={t('RECONNECT_PROGRAM_FIND_CLOSER_AGENCY_LABEL')}
                                shouldUnregister={false}
                            />
                            <Checkbox
                                name={'enabled'}
                                label={t('RECONNECT_PROGRAM_ENABLED')}
                                shouldUnregister={false}
                            />
                            <Checkbox
                                name={'is_acceptation_implicit'}
                                label={t('RECONNECT_PROGRAM_IMPLICIT')}
                                shouldUnregister={false}
                            />
                        </ModalFormSection>
                        <ModalFormSection>
                            <FileButton name={'program_file'} shouldUnregister={false}>
                                {t('RECONNECT_PROGRAM_ATTACHMENT_LABEL')}
                            </FileButton>
                        </ModalFormSection>
                    </>
                ) : (
                    <>
                        <ModalFormSection>
                            <Input
                                name={'template[background_color]'}
                                label={t('RECONNECT_TEMPLATE_HEADER_BG_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[header_color]'}
                                label={t('RECONNECT_TEMPLATE_HEADER_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[background_form_color]'}
                                label={t('RECONNECT_TEMPLATE_FORM_BG_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[form_title_color]'}
                                label={t('RECONNECT_TEMPLATE_FORM_TITLE_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[form_color]'}
                                label={t('RECONNECT_TEMPLATE_FORM_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[form_sponsor_field_color]'}
                                label={t('RECONNECT_TEMPLATE_FORM_SPONSOR_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[background_text]'}
                                label={t('RECONNECT_TEMPLATE_CONTACT_BG_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[contact_color]'}
                                label={t('RECONNECT_TEMPLATE_CONTACT_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[background_text_over_dl_image]'}
                                label={t('RECONNECT_TEMPLATE_TEXT_IMG_BG_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[text_color_over_dl_image]'}
                                label={t('RECONNECT_TEMPLATE_TEXT_IMG_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[button_form_color]'}
                                label={t('RECONNECT_TEMPLATE_CTA_BG_LABEL')}
                                shouldUnregister={false}
                            />
                            <Input
                                name={'template[button_color_cta]'}
                                label={t('RECONNECT_TEMPLATE_CTA_COLOR_LABEL')}
                                shouldUnregister={false}
                            />
                        </ModalFormSection>
                        <ModalFormSection>
                            <div>
                                <label htmlFor="">{t('Header landing')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea
                                    name={'template[header_landing]'}
                                    shouldUnregister={false}
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    {t('RECONNECT_TEMPLATE_SUBTITLE_LANDING_LABEL')}
                                </label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea
                                    name={'template[header_sub_title_landing]'}
                                    shouldUnregister={false}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{t('RECONNECT_TEMPLATE_PARAGRAPH_LABEL')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea name={'template[paragraph]'} shouldUnregister={false} />
                            </div>
                            <div>
                                <label htmlFor="">{t('RECONNECT_TEMPLATE_CATCHY_TITLE')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea name={'template[catch_title]'} shouldUnregister={false} />
                            </div>
                            <div>
                                <label htmlFor="">{t('RECONNECT_TEMPLATE_CATCHY_LABEL')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea
                                    name={'template[catch_phrase]'}
                                    shouldUnregister={false}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{t('RECONNECT_TEMPLATE_CONTACT_LABEL')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea
                                    name={'template[contact_title]'}
                                    shouldUnregister={false}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{t('RECONNECT_TEMPLATE_RGPD_LABEL')}</label>
                                <div className={'tips'}>
                                    {t('RECONNECT_PROGRAM_SMS_BBCODE_LABEL')}
                                </div>
                                <Textarea name={'template[rgpd_phrase]'} shouldUnregister={false} />
                            </div>
                        </ModalFormSection>
                        <ModalFormSection>
                            <FileButton name={'template[logo]'} shouldUnregister={false}>
                                {t('RECONNECT_TEMPLATE_LOGO_LABEL')}
                            </FileButton>
                            <FileButton
                                name={'template[background_image]'}
                                shouldUnregister={false}>
                                {t('RECONNECT_TEMPLATE_BG_IMG_LABEL')}
                            </FileButton>
                            <FileButton name={'template[download_image]'} shouldUnregister={false}>
                                {t('RECONNECT_TEMPLATE_ATTACHMENT_IMG_LABEL')}
                            </FileButton>
                        </ModalFormSection>
                    </>
                )}
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_export-pdf');
                }}
            />
        </ModalForm>
    );
};

const SponsorshipClientFormModal = () => {
    return (
        <Modal
            name={'add_sponsorship-program'}
            title={'Configurer un nouveau programme de parrainage'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <SponsorshipClientFormModalContent />
        </Modal>
    );
};

export default SponsorshipClientFormModal;
