import React from 'react';
import { Input as AntdInput } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

function Input({ name, value = null, ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field, formState }) => (
                <div>
                    {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                    <AntdInput
                        id={name}
                        onChange={(e) => {
                            if (e.target.type === 'number') {
                                field.onChange(parseInt(e.target.value, 10));
                            }

                            field.onChange(e.target.value);
                        }}
                        {...props}
                        {...field}
                        autoComplete={props.autocomplete || 'off'}
                        defaultValue={value}
                    />
                    {formState.errors[name] && (
                        <span className="error">{formState.errors[name].message}</span>
                    )}
                </div>
            )}
        />
    );
}

export default Input;
