import { useHttp } from './useHttp';

const useCampaignRecruitingService = () => {
    const axios = useHttp();

    const getRecruitingCampaign = (id, fromCampaignDashboard = false) => {
        return axios
            .get(
                `/dashboard/recruitments/${id}/campaign?fromCampaignDashboard=${fromCampaignDashboard}`
            )
            .then((response) => response.data);
    };

    return {
        getRecruitingCampaign
    };
};

export default useCampaignRecruitingService;
