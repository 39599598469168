import React from 'react';
import Placeholder from '../../Placeholder/Placeholder';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useCampaignService from 'api/useCampaignService';
import { defaultTo } from 'lodash-es';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useAuth } from 'providers/AuthContext';
import Achievement from './Activity/components/Achievement';
import CampaignOtherUser from './Activity/components/CampaignOtherUser';
import Campaign from './Activity/components/Campaign';
import Comments from 'components/Comments/Comments';
import Comment from 'components/Comment/Comment';

const Activity = () => {
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const { user } = useAuth();

    const { getAllActivitiesWithTrophies } = useCampaignService();

    const getParams = () => {
        return {
            page: defaultTo(params?.page, 1),
            userId: defaultTo(params?.userId, user.id)
        };
    };

    const { data } = useQuery({
        queryKey: [`activities_with_trophy_${[...Object.values(getParams())].join('_')}`],
        queryFn: () => getAllActivitiesWithTrophies(...Object.values(getParams()))
    });

    const commentSubmitCallbackHandler = (comment, activity) => {
        //activity.comments.push(comment);
    };

    return (
        <div className="activity-wrapper">
            <div className="activity">
                {data && (
                    <div className="timeline">
                        {data?.activities?.map((activity, index) => {
                            return (
                                <div className="event" key={`activity_${index}`}>
                                    <div className="date">
                                        <div className="anchor">
                                            <div className="icon">
                                                <i
                                                    className="icon-time-clock-circle"
                                                    aria-hidden="true"></i>
                                            </div>
                                        </div>

                                        <div className="timestamp">
                                            <realtime-date
                                                date="{{ $ctrl.getActivityDate(activity) }}"
                                                is-light="true"></realtime-date>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {activity.type === 'achievement' ? (
                                            <Achievement activity={activity} />
                                        ) : activity.light ||
                                          (params?.userId && params?.userId !== user.id) ? (
                                            <CampaignOtherUser activity={activity} />
                                        ) : (
                                            <Campaign activity={activity} />
                                        )}
                                    </div>

                                    {!activity.light && (
                                        <div>
                                            {(activity.type == 'recruiting' ||
                                                (activity.type == 'product' &&
                                                    activity.submit.show_comment)) && (
                                                <>
                                                    <Comments object={activity} />
                                                    <div className="post_add-comment">
                                                        <Comment
                                                            activityItem={activity}
                                                            submitCallback={
                                                                commentSubmitCallbackHandler
                                                            }></Comment>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {!data && (
                    <div className="timeline">
                        <Placeholder number={10} template={'activity'} />
                    </div>
                )}

                <div className="action" ng-if="$ctrl.page <= $ctrl.activityData.paging.nb_pages">
                    <button ng-click="$ctrl.loadMoreResults()" className="btn">
                        {t('TA_ACTIVITIES_LOAD_MORE')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Activity;
