import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormBody,
    ModalFormSection,
    ModalFormFooter
} from 'components/Form/ModalForm';
import { Checkbox } from 'components/Form/Inputs';
import { Button, Select } from 'antd';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEmpty, orderBy, uniqueId } from 'lodash-es';
import WidgetsService from 'components/Widgets/services/Widget';
import { useAdminOrganizationalUnitContext } from '../../../../providers/AdminOrganizationalUnitProvider';
import useWidgetService from 'api/useWidgetService';
import useSpringboardComponent from '../../hooks/useSpringboardComponent';
import FormInputRow from './components/FormInputRow';
import AbstractInput from './components/inputs/AbstractInput';
import Widgets from '../../../../../../components/Widgets/Widgets';

const EditSpringboard = () => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const [widgets, setWidgets] = useState([]);
    const [filterByOu, setFilterByOu] = useState(null);
    const [currentTab, setCurrentTab] = useState(params.tab);
    const [options, setOptions] = useState([]);
    const methods = useForm();
    const { allOrganizationalUnits } = useAdminOrganizationalUnitContext();
    const router = useRouter();
    const { getWidgetConfigs, getConfigs } = useWidgetService();
    const { getTranslatedPosition } = useSpringboardComponent();

    const WidgetService = new WidgetsService();

    useEffect(() => {
        setCurrentTab(params.tab);
    }, [params]);

    const widgetsLoaded = useMemo(() => {
        const values = methods.getValues();
        if (isEmpty(values)) {
            return null;
        }
        const widgets = Object.values(values.widgets)
            .filter((w) => {
                console.log('w', w.organizational_units);
                return !(
                    filterByOu &&
                    w.organizational_units &&
                    w.organizational_units.map((ou) => ou.id).indexOf(filterByOu) === -1
                );
            })
            .map((w) => {
                return {
                    ...w,
                    fields: Object.values(w.fields),
                    styling: Object.values(w.styling)
                };
            });

        return widgets;
    }, [methods.watch('widgets'), filterByOu]);

    const optionsLoaded = useMemo(() => {
        const values = methods.getValues();
        if (isEmpty(values) || !values.options) {
            return null;
        }

        return {
            ...values.options,
            styling: values.options.styling ? Object.values(values.options.styling) : []
        };
    }, [methods.watch('options')]);

    useEffect(() => {
        (async () => {
            const configs = await getConfigs(params.position);
            const o = WidgetService.mergeSpringboardWithDefaultConfigs(configs);
            const tmpOptions = {
                ...o,
                styling: o.styling.reduce((acc, obj) => {
                    acc[obj.key] = obj;
                    return acc;
                }, {})
            };
            setOptions(tmpOptions);
            const widgetConfigs = await getWidgetConfigs(params.position);
            const currentWidgets = widgetConfigs.map((widget) => {
                const w = WidgetService.mergeWithDefaultConfigs(widget);
                return {
                    ...w,
                    fields: w.fields.reduce((acc, obj) => {
                        acc[obj.key] = obj;
                        return acc;
                    }, {}),
                    styling: w.styling.reduce((acc, obj) => {
                        acc[obj.key] = obj;
                        return acc;
                    }, {}),
                    uniqueId: uniqueId()
                };
            });
            setWidgets(orderBy(currentWidgets, ['order']));
            methods.setValue(
                'widgets',
                currentWidgets.reduce((acc, obj) => {
                    acc[obj.uniqueId] = obj;
                    return acc;
                }, {})
            );
            methods.setValue('options', tmpOptions);
        })();
    }, [params.position]);

    const getTitle = useCallback(() => {
        return getTranslatedPosition(params.position);
    }, [params.position]);

    const handleDeleteRow = (uniqueId) => {
        setWidgets((prevState) => [...prevState.filter((i) => i.uniqueId !== uniqueId)]);
        methods.unregister(`wigets[${uniqueId}]`);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setWidgets((items) => {
                const oldIndex = items.findIndex((item) => item.uniqueId === active.id);
                const newIndex = items.findIndex((item) => item.uniqueId === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };
    const add = () => {
        let selectedType = methods.getValues()['selectedFieldType'];

        let fieldType = cloneDeep(
            WidgetService.getWidgets().find((fieldType) => {
                return fieldType.id === selectedType;
            })
        );

        if (!fieldType) {
            return;
        }
        let field = {
            label: WidgetService.getFieldNameByType(selectedType),
            label_render: WidgetService.getFieldNameByType(selectedType),
            type: selectedType,
            uniqueId: uniqueId()
        };

        if (methods.getValues()['unshiftOnList']) {
            setWidgets((prevInputs) => {
                return [field, ...prevInputs];
            });
        } else {
            setWidgets((prevInputs) => {
                return [...prevInputs, field];
            });
        }

        //this.saveOrder();
        methods.setValue('selectedFieldType', null);
        methods.setValue('unshiftOnList', false);
    };

    const onFinish = (values) => {
        console.log('values', values);
    };

    return (
        <div className="widgets-admin-wrapper">
            <div className="admin-forms-add">
                <div className="widgets-admin is-springboard">
                    <div className="dashboard">
                        <div className="top">
                            <h1>{getTitle()}</h1>

                            <div className="actions">
                                <Select
                                    onChange={setFilterByOu}
                                    style={{ width: 300 }}
                                    value={filterByOu}
                                    options={[
                                        {
                                            label: "Filtrer par groupe d'utilisateurs",
                                            value: null
                                        },
                                        ...(allOrganizationalUnits
                                            ? allOrganizationalUnits.map((ou) => ({
                                                  label: ou.name,
                                                  value: ou.id
                                              }))
                                            : [])
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="tabs">
                            <a
                                href={router.stateService.href('.', { ...params, tab: 'widgets' })}
                                className={`tab ${currentTab === 'widgets' ? 'active' : ''}`}>
                                Liste des éléments
                            </a>
                            <a
                                href={router.stateService.href('.', { ...params, tab: 'options' })}
                                className={`tab ${currentTab === 'options' ? 'active' : ''}`}>
                                Options de style
                            </a>
                        </div>

                        <div className="tabs-content scrollable">
                            <div className="tab-content" hidden={currentTab !== 'widgets'}>
                                <ModalForm
                                    methods={methods}
                                    onSubmit={methods.handleSubmit(onFinish)}>
                                    <ModalFormBody>
                                        <ModalFormSection inline>
                                            <Select
                                                name={'selectedFieldType'}
                                                style={{ flex: 1 }}
                                                placeholder={t("Choisir un type d'élément")}
                                                options={WidgetService.widgets.map((type) => ({
                                                    label: type.name,
                                                    value: type.id
                                                }))}
                                            />
                                            <Checkbox
                                                name={'unshiftOnList'}
                                                label={'Ajouter en haut de la liste'}
                                            />
                                            <Button htmlType={'button'} onClick={add}>
                                                Ajouter
                                            </Button>
                                        </ModalFormSection>

                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}>
                                            <SortableContext
                                                items={widgets.map((i) => i.uniqueId)}
                                                strategy={verticalListSortingStrategy}>
                                                {widgets.map((input, index) => (
                                                    <FormInputRow
                                                        input={input}
                                                        key={`form_input_row_${input.uniqueId}`}
                                                        index={index}
                                                        handleDeleteRow={handleDeleteRow}
                                                    />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </ModalFormBody>
                                    <ModalFormFooter showCancel={false} />
                                </ModalForm>
                            </div>
                            <div className="tab-content" hidden={currentTab !== 'options'}>
                                <ModalForm
                                    methods={methods}
                                    onSubmit={methods.handleSubmit(onFinish)}>
                                    <ModalFormBody>
                                        <ModalFormSection>
                                            {options &&
                                                options.styling &&
                                                Object.values(options.styling).map((style) => {
                                                    return (
                                                        <AbstractInput
                                                            field={style}
                                                            fieldName={`options.styling.${style.key}.value`}
                                                            label={style.name}
                                                        />
                                                    );
                                                })}
                                        </ModalFormSection>
                                    </ModalFormBody>
                                    <ModalFormFooter showCancel={false} />
                                </ModalForm>
                            </div>
                        </div>
                    </div>

                    <div className="widgets scrollable">
                        <Widgets
                            widgetsLoaded={widgetsLoaded}
                            optionsLoaded={optionsLoaded}></Widgets>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSpringboard;
