import React from 'react';
import { useTranslation } from 'react-i18next';
import useProfileActivityCampaignComponent from '../hooks/useProfileActivityCampaignComponent';

const Campaign = ({ activity }) => {
    const { t } = useTranslation();
    const { getCampaignTypeInitials } = useProfileActivityCampaignComponent();

    const isActivityType = (type, activity) => {
        return type === activity.type;
    };

    const getActivityAction = (activity) => {
        switch (activity.type) {
            case 'product':
                return t('ACTIVITY_CORRECT_PRODUCT_ANSWER');
            case 'product':
                return t('ACTIVITY_OPPORTUNITY_GENERATED');
            case 'social':
                let total = activity.submit.networks.length;

                let hasClicksFromOtherSocialNetwork = activity.submit.networks.some((network) => {
                    return network.name === 'autre';
                });

                if (hasClicksFromOtherSocialNetwork) {
                    total = total - 1;
                }

                return t('TA_ACTIVITY_CAMPAIGN_SOCIAL_TITLE', { total });
            case 'recruiting':
                return activity.submit === undefined || !activity.submit.from_social
                    ? t('TA_ACTIVITY_CAMPAIGN_RECRUITING_TITLE')
                    : t('TA_ACTIVITY_CAMPAIGN_RECRUITING_TITLE_FROM_SOCIAL');
            case 'survey':
                return t('TA_ACTIVITY_CAMPAIGN_SURVEY_TITLE');
            case 'recruiting_share':
                return t('ACTIVITY_RECRUITING_SHARE');
            default:
                return '';
        }
    };

    const getActivityText = (activity) => {
        switch (activity.type) {
            case 'recruiting':
                return `${t('TA_ACTIVITY_CAMPAIGN_RECRUITING_ACTION')} ${activity.submit.first_name} ${activity.submit.last_name}`;
            case 'survey':
                return t('TA_ACTIVITY_CAMPAIGN_SURVEY_ACTION');
            case 'recruiting_share':
                return t('TA_ACTIVITY_CAMPAIGN_RECRUITING_SHARE_ACTION');
            case 'product':
                let text = t('ACTIVITY_SHARED_OPPORTUNITY');

                if (activity.submit.answers && activity.submit.answers.length > 0) {
                    let answers = activity.submit.answers.map((answer) => {
                        return answer.value;
                    });

                    return `${text} ${answers.join(' ')}`;
                }

                if (activity.submit.email) {
                    return `${text} ${activity.submit.email}`;
                }

                return t('TA_ACTIVITY_CAMPAIGN_SURVEY_ACTION');
            case 'social':
                let clicks = activity.submit.networks.reduce((previous, network) => {
                    return previous + parseInt(network.clicks);
                }, 0);

                return t('TA_ACTIVITY_CAMPAIGN_SOCIAL_ACTION', { clicks });
            default:
                return '';
        }
    };

    const getRating = (rating) => {
        switch (rating) {
            case 1:
                return t('TA_ACTIVITY_CAMPAIGN_RECRUITING_QUESTION_UNKNOWN');
            case 2:
                return t('TA_ACTIVITY_CAMPAIGN_RECRUITING_QUESTION_RECOMMEND');
            case 3:
                return t('TA_ACTIVITY_CAMPAIGN_RECRUITING_QUESTION_RECOMMEND_PLUS');
        }
    };

    const getActivityReward = (activity) => {
        switch (activity.type) {
            case 'product':
            case 'social':
            case 'recruiting':
            case 'recruiting_share':
            case 'survey':
                if (activity.submit) {
                    return activity.submit.rewards;
                }
                return 0;
            default:
                return 0;
        }
    };

    const isFromSocial = (activity) => {
        return activity.submit && !!activity.submit.from_social;
    };

    const getNetworkIcon = (network) => {
        if (network.name !== 'autre') {
            return network.name;
        }

        return 'mouse-pointer';
    };

    return (
        <>
            <div className={`details ${activity.type}`}>
                <div className="icon">
                    <div className="letters">{getCampaignTypeInitials(activity.type)}</div>
                </div>

                <div className="meta">
                    <div className="title">{activity?.campaign?.title}</div>

                    <div className="action">
                        <div className="name">{getActivityAction(activity)}</div>
                        <div className="text">{getActivityText(activity)}</div>
                    </div>

                    {isActivityType('recruiting', activity) && (
                        <div className="is-recruiting">
                            <div className="application">
                                <dashboard-activity-view
                                    activity="activity"
                                    is-widget="true"
                                ></dashboard-activity-view>
                            </div>

                            {isFromSocial(activity) && (
                                <div className="from-social">
                                    {activity?.submit?.rating && (
                                        <div className="has-rating">
                                            <div className="title">
                                                {t('YOUR_RECO')} {activity.submit.first_name}{' '}
                                                {activity.submit.last_name} :
                                            </div>
                                            <div className="text">
                                                {getRating(activity.submit.rating)}
                                            </div>
                                        </div>
                                    )}

                                    {!activity?.submit?.rating && (
                                        <div className="need-rating">
                                            <div className="title">
                                                {t('DO_YOU_RECOMMAND')} {activity.submit.first_name}{' '}
                                                {activity.submit.last_name} ?
                                            </div>

                                            <div className="actions">
                                                <button
                                                    className="btn outline"
                                                    ng-click="$ctrl.processRating(activity, 1)"
                                                >
                                                    {t(
                                                        'TA_ACTIVITY_CAMPAIGN_RECRUITING_QUESTION_UNKNOWN_SMALL'
                                                    )}
                                                </button>
                                                <button
                                                    className="btn outline"
                                                    ng-click="$ctrl.processRating(activity, 2)"
                                                >
                                                    {t('DIALOG_YES')}
                                                </button>
                                                <button
                                                    className="btn outline"
                                                    ng-click="$ctrl.processRating(activity, 3)"
                                                >
                                                    {t(
                                                        'TA_ACTIVITY_CAMPAIGN_RECRUITING_QUESTION_RECOMMEND_YES_PLUS'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {isActivityType('social', activity) && (
                        <div className="is-social">
                            <div className="networks">
                                {activity?.submit?.networks.map((network) => {
                                    return (
                                        <div className="network" key={`network_${network.name}`}>
                                            <i
                                                className={`fa fa-fw fa-${getNetworkIcon(network)}`}
                                            ></i>{' '}
                                            {network.clicks} clic
                                            {network.clicks > 1 && <span>s</span>}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="actions">
                <button
                    className="btn outline"
                    type="button"
                    ng-click="$ctrl.goToCampaign(activity.campaign)"
                >
                    {t('TA_ACTIVITY_CAMPAIGN_VIEW_CAMPAIGN')}
                </button>

                {getActivityReward(activity) > 0 && (
                    <button className="btn reward" type="button">
                        <span>
                            {t('TA_POST_PTS_EARNED', { points: getActivityReward(activity) })}
                        </span>
                    </button>
                )}

                {activity.type === 'product' &&
                    activity?.submit?.reward_by_opportunity > 0 &&
                    getActivityReward(activity) === 0 && (
                        <button className="btn reward" type="button">
                            <span>
                                {t('DISPLAY_REWARD_WILL_EARN_POINTS', {
                                    points: activity.submit.reward_by_opportunity
                                })}
                            </span>
                        </button>
                    )}
            </div>
        </>
    );
};

export default Campaign;
