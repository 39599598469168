import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React, { useState, useEffect, useCallback } from 'react';
import { useModal } from 'providers/ModalContext';
import useViewport from 'hooks/useViewport';
import Post from 'components/Post/Post';
import { useDevice } from 'providers/DeviceContext';
import ResizableImage from 'components/ResizableImage/ResizableImage';
import useAttachment from 'hooks/useAttachment';

const PreviewModalContent = () => {
    const {
        contexts: {
            ['preview-post']: { post, isPostPreview = false, tags = [], sharedTags = [] }
        }
    } = useModal();
    if (!post) {
        return <></>;
    }

    return (
        <div class="post">
            <Post
                className={`post ${post.alreadyLiked}`}
                post={post}
                alreadyLiked={post.alreadyLiked}
                download={post.download}
                tagId={post.tag_id}
                tags={tags}
                sharedTags={sharedTags}
                isPostPreview={isPostPreview}
                template={'preview'}
            />
        </div>
    );
};

const PreviewModal = () => {
    return (
        <div className="preview-modal-root">
            <Modal
                name="preview-post"
                title={'Preview'}
                options={{
                    hasFlexContent: true,
                    hasPaddedContent: true,
                    canScroll: false,
                    hideLoader: true,
                    zIndex: 310
                }}>
                <PreviewModalContent />
            </Modal>
        </div>
    );
};

export default PreviewModal;
