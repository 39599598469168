import { useHttp } from 'api/useHttp';

const useCategoryService = () => {
    const axios = useHttp();

    const getCategory = (id) => {
        return axios.get(`/categories/${id}`).then((response) => {
            let category = response.data.category;
            let workspaces = [];
            response.data.workspaces.forEach((data) => {
                data.workspace.folders = data.folders;
                workspaces.push(data.workspace);
            });
            category.workspaces = workspaces;
            return category;
        });
    };

    const getAllCategories = (withoutAnyFilter = 0, excludedIds = null) => {
        let params = {
            params: {
                withoutAnyFilter,
                'excludedIds[]': excludedIds
            }
        };
        return axios.get(`/categories`, params).then((response) => {
            let categories = [];
            response.data.forEach((row) => {
                let category = row.category;
                let workspaces = [];
                row.workspaces.forEach((data) => {
                    data.workspace.folders = data.folders;
                    workspaces.push(data.workspace);
                });
                category.workspaces = workspaces;
                categories.push(category);
            });

            return categories;
        });
    };

    return {
        getCategory,
        getAllCategories
    };
};

export default useCategoryService;
