import React from 'react';
import { useIsFetching } from '@tanstack/react-query';

const Loader = () => {
    const isFetching = useIsFetching();

    return (
        <div className={`loader ${isFetching ? 'show' : 'hide'}`}>
            <div className="bar bar-1"></div>
            <div className="bar bar-2"></div>
        </div>
    );
};

export default Loader;
