import React from 'react';
import { UIView, useRouter } from '@uirouter/react';
import useViewport from 'hooks/useViewport';
import { useTranslation } from 'react-i18next';
import Channels from './components/Channels';
import { useChannels } from '../Auth/providers/ChannelsProvider';
import { useDevice } from 'providers/DeviceContext';

const Chat = () => {
    const router = useRouter();
    const viewport = useViewport();
    const { t } = useTranslation();
    const { channels, isLoading } = useChannels();
    const { device } = useDevice();

    const notInChannelsRoot = () => {
        return router.globals.current.name !== 'auth.chat.channels';
    };

    return (
        <div className={'chat-wrapper'}>
            {/*<create-chat></create-chat>*/}

            <div className="chat">
                {viewport.gtXs && notInChannelsRoot() && (
                    <div className="sidebar">
                        <Channels />
                    </div>
                )}

                <div className="view">
                    <UIView />
                </div>

                {!isLoading && channels && channels.length === 0 && (
                    <div className="view empty">
                        <button className="button rounded dark" ng-click="$ctrl.createChat()">
                            <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                            {t('CREATE_YOUR_FIRST_CHANNEL')}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Chat;
