import { useHttp } from 'api/useHttp';

const useLevelStatsService = (params) => {
    const axios = useHttp();

    const getLevelStats = async (filters) => {
        const { noEmployees, noRules, nameLevel } = filters;

        return await axios
            .get('/admin/levels/stats', {
                params: {
                    noEmployees: noEmployees,
                    noRules: noRules,
                    name: nameLevel
                }
            })
            .then((response) => response.data);
    };

    const postLevelStats = async (level) => {
        return await axios.post(`/levels`, { level: level });
    };

    /**
     * deleteLevels
     * @param {Array<Object{id: number}>} levels - Array of levels to delete
     * @returns
     */
    const deleteLevels = async (levels) => {
        console.log('levels', levels);
        return await axios.post(`/levels/admin/delete`, { levels: levels });
    };

    return {
        getLevelStats,
        postLevelStats,
        deleteLevels
    };
};

export default useLevelStatsService;
