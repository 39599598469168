import { useHttp } from './useHttp';

const useRuleService = () => {
    const axios = useHttp();

    const getRule = (name) => {
        return axios.get(`/rules/${name}`).then((response) => {
            if (response.data[0]) {
                return response.data[0];
            } else {
                return null;
            }
        });
    };

    const getAllRules = () => {
        return axios.get(`/rules`).then((response) => response.data);
    };

    return {
        getRule,
        getAllRules
    };
};

export default useRuleService;
