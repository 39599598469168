import React from 'react';
import useUser from 'hooks/useUser';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const Channels = ({ result, query }) => {
    const { forgeAvatarUrl } = useUser();
    const { getResultWithQueryHighlight } = useSearchBoxResultComponent(query);

    return (
        <>
            <div className="icon">
                {!result.cover ? (
                    <i className="icon-multiple-users-1" aria-hidden="true"></i>
                ) : (
                    <img src={forgeAvatarUrl(result.cover)} alt="User Avatar" />
                )}
            </div>

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.name)
                    }}
                />
            </div>
        </>
    );
};

export default Channels;
