import { useHttp } from '../../../api/useHttp';

const useRecruitingCampaignSubmitService = () => {
    const axios = useHttp();
    const askCV = (id) => {
        return axios
            .put(`/dashboard/recruitments/${id}/campaign/submit/relaunch`)
            .then((response) => response.data);
    };

    const rewardCampaignRecruitingSubmit = (id) => {
        return axios
            .post(`/dashboard/recruitments/${id}/campaigns/submits/rewards`)
            .then((response) => response.data);
    };

    const rejectCampaignRecruitingSubmit = (id, form) => {
        return axios
            .post(`/dashboard/recruitments/${id}/campaigns/submits/rejects`, form)
            .then((response) => response.data);
    };

    return {
        askCV,
        rewardCampaignRecruitingSubmit,
        rejectCampaignRecruitingSubmit
    };
};

export default useRecruitingCampaignSubmitService;
