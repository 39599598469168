import { remove } from 'lodash-es';

export default class WorkspaceFormsService {
    constructor(Config) {
        this.Config = Config;

        this.fieldTypes = [
            {
                id: 'text',
                name: 'Texte',
                allowCondition: false
            },
            {
                id: 'long_text',
                name: 'Texte long',
                allowCondition: false
            },
            {
                id: 'file',
                name: 'Fichier (simple)',
                allowCondition: false
            },
            {
                id: 'files',
                name: 'Fichier (multiple)',
                allowCondition: false
            },
            {
                id: 'select',
                name: 'Liste déroulante',
                allowCondition: true
            },
            {
                id: 'collection',
                name: 'Collection',
                allowCondition: true
            },
            {
                id: 'radio',
                name: 'Bouton radio',
                allowCondition: true
            },
            {
                id: 'position',
                name: 'Géolocalisation',
                allowCondition: false
            },
            {
                id: 'email',
                name: 'Email',
                allowCondition: false
            },
            {
                id: 'date',
                name: 'Date',
                allowCondition: false
            },
            {
                id: 'numeric',
                name: 'Numérique',
                allowCondition: false
            },
            {
                id: 'title',
                name: 'Titre',
                allowCondition: false
            },
            {
                id: 'recipients',
                name: 'Destinataires',
                allowCondition: false
            },
            {
                id: 'page',
                name: 'Page',
                allowCondition: false
            },
            {
                id: 'separator',
                name: 'Séparateur',
                allowCondition: false
            },
            {
                id: 'line_space',
                name: 'Interligne',
                allowCondition: false
            },
            {
                id: 'image',
                name: "Image d'illustration",
                allowCondition: false
            }
        ];

        this.allowedExtensions = [
            '.doc',
            '.docx',
            '.gif',
            '.jpeg',
            '.jpg',
            '.pdf',
            '.png',
            '.pptx',
            '.ppt',
            '.xlsx',
            '.xls',
            '.csv'
        ];

        if (this.Config.excludedExtensions) {
            remove(this.allowedExtensions, (allowedExtension) => {
                return this.Config.excludedExtensions.includes(allowedExtension);
            });
        }
    }

    extractWorkspaceFormsFromWorkspaces(workspaces) {
        let workspaceForms = {};

        workspaces.forEach((workspace) => {
            if (workspace.workspace_form) {
                let workspaceForm = workspace.workspace_form;

                if (!workspaceForms[workspaceForm.id]) {
                    workspaceForms[workspaceForm.id] = {
                        ...workspaceForm,
                        workspaces: [
                            {
                                ...workspace,
                                workspace_form: null
                            }
                        ]
                    };
                } else {
                    workspaceForms[workspaceForm.id].workspaces.push({
                        ...workspace,
                        workspace_form: null
                    });
                }
            }
        });

        return Object.values(workspaceForms);
    }

    getWorkspaceFormsWithWorkspaces(workspaceForms, workspaces) {
        return workspaceForms.map((workspaceForm) => {
            return {
                ...workspaceForm,
                workspaces: this.getWorkspacesForWorkspaceForm(workspaceForm, workspaces)
            };
        });
    }

    getWorkspacesForWorkspaceForm(workspaceForm, workspaces) {
        return workspaces.filter(({ workspace_form = null }) => {
            return workspace_form && workspace_form.id === workspaceForm.id;
        });
    }

    getFieldNameByType(type) {
        return this.getFieldKeyByType('name', type);
    }

    getFieldKeyByType(key, type) {
        let field = this.fieldTypes.find((fieldType) => {
            return fieldType.id === type;
        });

        if (!field) {
            return;
        }

        return field[key];
    }

    getFieldTypes() {
        return this.fieldTypes;
    }

    getAllowedExtensions() {
        return this.allowedExtensions;
    }
}
