import { Select } from 'components/Form/Inputs';
import { useAdminOrganizationalUnitContext } from '../../../../../../providers/AdminOrganizationalUnitProvider';

const OrganizationUnits = ({ field, fieldName, ...props }) => {
    const { allOrganizationalUnits } = useAdminOrganizationalUnitContext();
    return (
        <Select
            name={fieldName}
            mode="multiple"
            options={
                allOrganizationalUnits &&
                allOrganizationalUnits.map((ou) => {
                    return {
                        label: ou.name,
                        value: ou.id
                    };
                })
            }
            {...props}
        />
    );
};

export default OrganizationUnits;
