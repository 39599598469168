import PhoneBook from '../PhoneBook';

export default [
    {
        name: 'auth.phonebookv2',
        url: 'phonebook/v2?page&q&letter&levels',
        component: PhoneBook,
        title: 'TOP_USERS',
        navbar: {
            getIcon: () => {
                return 'icon-notes-diary-1';
            },
            getLabel: (context) => {
                return context.directoryLabel;
            },
            getAdminLabel: (context) => {
                return `${context.t('TOP_USERS')} v2`;
            },
            isActive: (context) => {
                return context.isActive(['auth.phonebookv2.**']);
            },
            isVisible: (context) => {
                if (context.Right.hasRole('ROLE_HIDE_LIST_USERS')) {
                    return false;
                }

                return context.Right.hasRole(['ROLE_INTERNAL', 'HAS_EMPLOYEE_DIRECTORY_V2']);
            }
        }
    }
];
