export const TARGETS = [
    { value: 0, label: 'Tous' },
    { value: 1, label: 'Interne' },
    { value: 2, label: 'Externe' }
];

export const SHAPES = [
    { value: 'octagram', label: 'octagram' },
    { value: 'hexagon', label: 'hexagon' }
];
