import { FormProvider } from 'react-hook-form';
import classes from 'classnames';
import useEditorComponent from 'components/Editor/hooks/useEditorComponent';

const EditorForm = ({ methods, workspace, isPreview, children, ...props }) => {
    const { workspaceHasCustomForm, hasPages } = useEditorComponent(workspace);

    const getEditorClass = () => {
        const editorClasses = [];
        if (workspaceHasCustomForm()) {
            editorClasses.push('custom');
        } else {
            editorClasses.push('default');
        }

        if (hasPages()) {
            editorClasses.push('has-pages');
        }

        return editorClasses;
    };
    return (
        <FormProvider {...methods}>
            <form className={classes(['editor', ...getEditorClass()])} {...props}>
                {children}
            </form>
        </FormProvider>
    );
};

export default EditorForm;
