import React from 'react';
import useViewport from 'hooks/useViewport';
import { useTranslation } from 'react-i18next';
import { UIView, useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useConfig } from 'providers/ConfigProvider';

const Login = () => {
    const { t } = useTranslation();
    const { state } = useCurrentStateAndParams();
    const router = useRouter();
    const viewport = useViewport();
    const { config } = useConfig();
    const needDisplayStrip = () => {
        return false;
    };

    const showRegistrationTab = () => {
        return !config.hide_registration_tab;
    };

    const isAllowToCreateAccount = () => {
        return config.allow_create_account;
    };

    const hideConnexionTabs = () => {
        return config.hide_connexion_tabs || (!isConnectionTab() && !isRegistrationTab());
    };

    const isConnectionTab = () => {
        return state.name === 'login.home';
    };

    const isRegistrationTab = () => {
        return state.name === 'login.registration';
    };

    return (
        <div className="login_wrapper">
            <div className="login">
                <div className="sidebar">
                    <div className="logo">
                        <img src={config.logo_login_url} alt="Logo" />
                    </div>

                    {viewport.gtXs && (
                        <div className="welcome">
                            <div
                                className="title"
                                dangerouslySetInnerHTML={{
                                    __html: t('WELCOME', { title: config.title })
                                }}
                            ></div>
                            <div
                                className="subtitle"
                                dangerouslySetInnerHTML={{
                                    __html: t('LOGIN_WELCOME_SUBTITLE', { title: config.brand })
                                }}
                            ></div>
                            {needDisplayStrip() && (
                                <div className="strip">
                                    <img src={config.logo_strip} alt="logo strip" />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="body scrollable">
                    <div className="page">
                        <div className="content">
                            {isAllowToCreateAccount() && !hideConnexionTabs() && (
                                <div className="tabs">
                                    <div
                                        className={`tab ${isConnectionTab() ? 'active' : null}`}
                                        onClick={() => router.stateService.go('login.home')}
                                    >
                                        {t('LOGIN_CONNECTION')}
                                    </div>
                                    {showRegistrationTab() && (
                                        <div
                                            className={`tab ${isRegistrationTab() ? 'active' : null}`}
                                            onClick={() =>
                                                router.stateService.go('auth.feed.posts')
                                            }
                                        >
                                            {t('LOGIN_CREATE_ACCOUNT_TEXT')}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="view">
                                <UIView />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
