import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useCategoryService from '../../../api/useCategoryService';
import useWorkspaceService from '../../../api/useWorkspaceService';
import useFolderService from '../../../api/useFolderService';
import usePostService from '../../../api/usePostService';
import base64 from 'base-64';
import useTileService from '../api/useTileService';

const FeedContext = createContext();

const FeedProvider = ({ children, tags = [] }) => {
    const router = useRouter();
    const { params, state } = useCurrentStateAndParams();
    const { getCategory } = useCategoryService();
    const { getWorkspaceInfos } = useWorkspaceService();
    const { getTableFilters } = usePostService();
    const { getFolder } = useFolderService();
    const { getWorkspaceTileFilters } = useTileService();
    const [tag, setTag] = useState(null);

    const { data: category, isLoading: categoryIsLoading } = useQuery({
        queryKey: ['category', params.category],
        queryFn: () => {
            if (params.category) {
                return getCategory(params.category);
            }
            return null;
        }
    });

    const { data: workspace, isLoading: workspaceIsLoading } = useQuery({
        queryKey: ['workspaceInfo', params.workspace],
        queryFn: () => {
            if (params.workspace) {
                return getWorkspaceInfos(params.workspace);
            }
            return null;
        }
    });

    const { data: folder, isLoading: folderIsLoading } = useQuery({
        queryKey: ['folder', params.folder],
        queryFn: () => {
            if (params.workspace) {
                return getFolder(params.folder);
            }
            return null;
        }
    });

    const { data: initialFilters, isLoading: filtersAreLoading } = useQuery({
        queryKey: ['filters', params.filters],
        queryFn: () => getTableFilters(base64.encode(params.filters))
    });
    const { data: filters, tileFiltersAreLoading } = useQuery({
        queryKey: ['tileFilters', state.name, params.workspace],
        queryFn: () => {
            if (state.name.includes('auth.feed.tiles') && params.workspace) {
                return getWorkspaceTileFilters(params.workspace);
            } else {
                return [];
            }
        }
    });

    useEffect(() => {
        if (params.tag) {
            setTag(tags.find((t) => t.id === parseInt(params.tag)));
        } else {
            setTag(null);
        }
    }, [tags, params.tag]);

    const isTargettedInSublessWS = () => {
        if (!workspace || !workspace.subscription_less) {
            return false;
        }

        let isTargetted = false;

        /*forEach(this.posts, (postData) => {
            forEach(postData.post.targetUsers, (user => {
                if (this.user.id === user.id) {
                    isTargetted = true;
                }
            }));
        });*/

        return isTargetted;
    };

    const hasPostStatus = useCallback(() => {
        if (
            router.globals.current.name !== 'auth.feed.posts.workspace' &&
            router.globals.current.name !== 'auth.feed.stacked.workspace'
        ) {
            return false;
        }

        return (
            workspace && workspace.id && workspace.post_status && workspace.post_status.length > 0
        );
    }, [router, workspace]);

    return (
        <FeedContext.Provider
            value={{
                category: categoryIsLoading ? null : category,
                workspace: workspaceIsLoading ? null : workspace,
                folder: folderIsLoading ? null : folder,
                initialFilters: filtersAreLoading ? null : initialFilters,
                tileFilters: tileFiltersAreLoading ? null : filters,
                categoryId: params.category,
                workspaceId: params.workspace,
                folderId: params.folder,
                tag,
                isTargettedInSublessWS,
                hasPostStatus
            }}>
            {children}
        </FeedContext.Provider>
    );
};

export const useFeedContext = () => useContext(FeedContext);
export default FeedProvider;
