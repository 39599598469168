import { forEach, includes, isArray, every, set, merge, unset } from 'lodash-es';

export default function removeEmptyTopLevelCategories(tree) {
    forEach(tree, (item, key) => {
        if (includes(key, 'cat_')) {
            if (!isArray(item.workspaces)) {
                let itemHasOnlyChildCategories = every(item.workspaces, (item, key) => {
                    return includes(key, 'cat_');
                });

                if (itemHasOnlyChildCategories) {
                    forEach(item.workspaces, (child) => {
                        set(child, ['category', 'hasHiddenParent'], true);
                    });

                    merge(tree, item.workspaces);
                    unset(tree, key);
                }
            }

            removeEmptyTopLevelCategories(item.workspaces);
        }
    });

    return tree;
}
