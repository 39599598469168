import { useQuery } from '@tanstack/react-query';
import AdminHeader from 'components/Admin/components/AdminHeader';
import useTriggerService from './api/useTriggerService';
import Table from 'components/Table';
import { useTranslation } from 'react-i18next';
import TriggerFormModal from './modals/TriggerFormModal';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';

const isNil = (value) => value === null || value === undefined;

const Triggers = () => {
    const { t } = useTranslation();
    const { getAllAdminTriggers, deleteAdminTrigger } = useTriggerService();

    const { showConfirm, showToast } = useAlert();
    const { openModal } = useModal();

    const {
        data: triggers,
        isLoading,
        refetch
    } = useQuery({
        queryKey: ['triggers'],
        queryFn: () => getAllAdminTriggers()
    });

    const getTriggerWorkspaceInputData = (row) => {
        if (
            isNil(row.triggerWorkspaceInputs) ||
            (row.triggerWorkspaceInputs && row.triggerWorkspaceInputs.length === 0)
        ) {
            return t('ADMIN_TRIGGER_TARGETS_EMPTY');
        }

        return row.triggerWorkspaceInputs.map((triggerWorkspaceInput) => (
            <div key={triggerWorkspaceInput.id}>
                {t('ADMIN_TRIGGER_WORKSPACE_INPUT_TEXT', {
                    value: triggerWorkspaceInput.workspaceFormInputValue,
                    label: triggerWorkspaceInput.workspaceFormInput.label,
                    form: triggerWorkspaceInput.workspaceFormInput.workspaceForm.name
                })}
            </div>
        ));
    };

    const COLUMNS = [
        {
            id: 'name',
            header: 'ADMIN_MENU_BRANDS_NAME_LABEL',
            sortable: true
        },
        {
            id: 'enabled',
            header: 'ADMIN_TRIGGER_ENABLED',
            cell: ({ cell }) => (cell.getValue() ? 'Oui' : 'Non'),
            sortable: true
        },
        {
            id: 'triggers',
            header: 'ADMIN_TRIGGER_TAG',
            sortable: true,
            cell: ({ cell }) =>
                cell
                    .getValue()
                    ?.map((_) => _.name)
                    .join(', ')
        },
        {
            id: 'categories',
            header: 'ADMIN_TRIGGER_CATEGORIES',
            sortable: true,
            cell: ({ cell }) =>
                cell
                    .getValue()
                    ?.map((_) => _.name)
                    .join(', ')
        },
        {
            id: 'workspaces',
            header: 'ADMIN_TRIGGER_WORKSPACE',
            sortable: true,
            cell: ({ cell }) =>
                cell
                    .getValue()
                    ?.map((_) => _.name)
                    .join(', ')
        },
        {
            id: 'folders',
            header: 'ADMIN_TRIGGER_FOLDERS',
            sortable: true,
            cell: ({ cell }) =>
                cell
                    .getValue()
                    ?.map((_) => _.name)
                    .join(', ')
        },
        {
            id: 'triggerWorkspaceInputs',
            header: 'ADMIN_TRIGGER_WORKSPACE_FORM_INPUT',
            sortable: true,
            cell: ({ cell }) => getTriggerWorkspaceInputData(cell.row.original)
        }
    ];

    return (
        <>
            <TriggerFormModal />

            <AdminHeader
                title={'ADMIN_TRIGGERS'}
                modalName={'form_triggers'}
                modalButtonTitle={'Ajouter un trigger'}
            />

            <Table
                columns={COLUMNS}
                data={triggers}
                isLoading={isLoading}
                handleActions={[
                    {
                        label: 'Éditer',
                        handler: (row) => {
                            openModal('form_triggers', {
                                ...row
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        handler: (row) => {
                            showConfirm({
                                title: t('ADMIN_TRIGGER_DELETE'),
                                text: t('ADMIN_TRIGGER_DELETE_TEXT'),
                                button: {
                                    label: t('TAG_DELETE_CONFIRM_YES'),
                                    classes: ['red', 'bold']
                                }
                            }).then(() => {
                                deleteAdminTrigger(row.id)
                                    .then(() => {
                                        showToast({ text: t('ADMIN_TRIGGER_DELETE_CONFIRMED') });
                                        refetch();
                                    })
                                    .then((data) => {
                                        console.log(data);
                                    });
                            });
                        }
                    }
                ]}
            />
        </>
    );
};
export default Triggers;
