import { useHttp } from 'api/useHttp';
import { useConfig } from 'providers/ConfigProvider';

const useBlogCategoryService = () => {
    const axios = useHttp();

    const getBlogCategories = () => {
        return axios.get(`/blog/categories`).then((response) => {
            return response.data;
        });
    };

    const getPublicBlogCategories = () => {
        return axios.get(`/blog/public/categories`).then((response) => {
            return response.data;
        });
    };

    return {
        getBlogCategories,
        getPublicBlogCategories
    };
};

export default useBlogCategoryService;
