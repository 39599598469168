import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTreeService from 'api/useTreeService';

const TreeContext = createContext();

const TreeProvider = ({ children }) => {
    const { getTree } = useTreeService();

    const { data: tree, isLoading } = useQuery({
        queryKey: ['tree'],
        queryFn: () => {
            return getTree();
        }
    });

    return <TreeContext.Provider value={{ tree, isLoading }}>{children}</TreeContext.Provider>;
};

export const useTreeContext = () => useContext(TreeContext);

export default TreeProvider;
