import { useQuery } from '@tanstack/react-query';
import { Select } from 'components/Form/Inputs';
import useBlogArticleService from 'api/useBlogArticleService';
import useBlog from 'hooks/useBlog';

const BlogArticles = ({ field, fieldName, ...props }) => {
    const { getLocalizedArticleProperty } = useBlog();
    const { getPublishedBlogArticles } = useBlogArticleService();
    const { data: articles } = useQuery({
        queryKey: ['allPublishBlogArticles'],
        queryFn: () => getPublishedBlogArticles()
    });
    return (
        <Select
            shouldUnregister={false}
            mode={'multiple'}
            name={fieldName}
            value={field.value ? field.value.map((value) => value.value) : []}
            options={
                articles
                    ? articles.map((article) => {
                          return {
                              label: getLocalizedArticleProperty(article, 'title'),
                              value: parseInt(article.id)
                          };
                      })
                    : []
            }
            {...props}
        />
    );
};

export default BlogArticles;
