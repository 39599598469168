import { useQueryClient } from '@tanstack/react-query';
import useCampaignService from 'api/useCampaignService';
import useAttachment from 'hooks/useAttachment';
import { useAlert } from 'providers/AlertContext';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../../../providers/ConfigProvider';
import { useAdminOrganizationalUnitContext } from '../../../../Admin/providers/AdminOrganizationalUnitProvider';
import { useAdminTreeContext } from '../../../../Admin/providers/AdminTreeProvider';
import { useAdminLevelContext } from '../../../../Admin/providers/AdminLevelProvider';

/**
 * Custom render for the campaigns list in the dashboard
 * @param {Object} campaign The campaign object
 */
const useCampaignsCustomRender = (campaign) => {
    const queryClient = useQueryClient();
    const { forgeAttachmentUrl, forgeAttachmentPoster } = useAttachment();
    const { allOrganizationalUnits } = useAdminOrganizationalUnitContext();
    const { workspaces } = useAdminTreeContext();
    const { levels } = useAdminLevelContext();

    const {
        postCloneCampaign,
        putCampaignStatus,
        deleteCampaign: deleteCampaignService
    } = useCampaignService();

    const { t } = useTranslation();
    const { showConfirm, showToast } = useAlert();
    const { config } = useConfig();

    const getWorkspaceNames = useCallback(() => {
        if (!workspaces) {
            return;
        }
        let selectedWorkspaces = [];

        campaign.workspaces_target_selected.forEach((value) => {
            let workspace = workspaces.filter((workspace) => {
                if (workspace.id === value) {
                    return workspace;
                }
            });

            if (!workspace[0]) {
                return;
            }
            selectedWorkspaces.push(workspace[0].name);
        });

        return selectedWorkspaces.join(', ');
    }, [workspaces]);

    const getLevelNames = useCallback(() => {
        if (!levels) {
            return;
        }
        let selectedLevels = [];

        campaign.levels_target_selected.forEach((value) => {
            let level = levels.filter((level) => {
                if (level.id === value) {
                    return level;
                }
            });

            if (!level[0]) {
                return;
            }

            selectedLevels.push(level[0].name);
        });

        return selectedLevels.join(', ');
    }, [levels]);

    const getOrgaUnitNames = useCallback(() => {
        if (!allOrganizationalUnits) {
            return;
        }
        let selectedOus = [];

        campaign.user_organizational_units_target_selected.forEach((value) => {
            let organizationalUnit = allOrganizationalUnits.filter((organizationalUnit) => {
                if (organizationalUnit.id === value) {
                    return organizationalUnit;
                }
            });

            if (!organizationalUnit[0]) {
                return;
            }
            selectedOus.push(organizationalUnit[0].name);
        });

        return selectedOus.join(', ');
    }, [allOrganizationalUnits]);

    const totalShares = () => {
        if (campaign.type === 'product' || campaign.type === 'survey') {
            return 0;
        }

        let total = 0;

        campaign.networks.forEach((social) => {
            total += social.shares;
        });

        return total;
    };

    const getTotalClicks = () => {
        if (
            campaign.type === 'product' ||
            campaign.type === 'survey' ||
            campaign.type === 'mission'
        ) {
            return 0;
        }

        let total = 0;

        campaign.users.forEach((user) => {
            total += parseInt(user.sumClicks);
        });

        return total;
    };

    const forgeDownloadCSVUrl = (timestamp = new Date().getTime()) => {
        return `${config.apiUrl}/dashboard/${campaign.type}s/${campaign.id}/campaign/download?nocache=${timestamp}`;
    };

    const getCampaignImage = () => {
        if (campaign.link) {
            return campaign.link.picture_url;
        } else if (campaign.image) {
            if (!campaign.image.poster) {
                return forgeAttachmentUrl(campaign.image);
            } else {
                return forgeAttachmentPoster(campaign.image);
            }
        }

        return null;
    };
    const getCampaignTarget = () => {
        switch (campaign.target) {
            case 0:
                return t('TA_CREATE_CAMPAIGN_TARGET_ALL');
            case 1:
                return t('TA_CREATE_CAMPAIGN_TARGET_INTERNAL_ONLY');
            case 2:
                return t('TA_CREATE_CAMPAIGN_TARGET_EXTERNAL_ONLY');
            case 3:
                return t('TA_CREATE_CAMPAIGN_TARGET_WORKSPACE') + ' (' + getWorkspaceNames() + ')';
            case 4:
                return t('BY_LEVEL') + ' (' + getLevelNames() + ')';
            case 5:
                return (
                    t('TA_CREATE_CAMPAIGN_TARGET_USER_ORGANIZATIONAL_UNITS') +
                    ' (' +
                    getOrgaUnitNames() +
                    ')'
                );
            default:
                return '';
        }
    };

    const getCampaignAuthor = () => {
        return campaign.owner.last_name
            ? campaign.owner.first_name + ' ' + campaign.owner.last_name
            : campaign.owner.email;
    };

    const status = useMemo(() => {
        if (campaign.status === 1) return 'draft';
        if (campaign.status === 2) return 'running';
        if (campaign.status === 3) return 'closed';
    }, [campaign.status]);

    const isDraft = status === 'draft';
    const isRunning = status === 'running';
    const isClosed = status === 'closed';

    const closeCampaign = (campaign) => {
        showConfirm({
            title: t('TA_CAMPAIGN_CLOSE_CONFIRM_TITLE'),
            text: t('TA_CAMPAIGN_CLOSE_CONFIRM_TEXT'),
            button: {
                label: t('TA_CAMPAIGN_CLOSE_CONFIRM_BUTTON'),
                classes: ['red', 'bold']
            }
        }).then(() => {
            putCampaignStatus({
                id: campaign.id,
                status: 3
            }).then(() => {
                showToast({
                    text: 'La campagne a bien été fermée'
                });

                queryClient.refetchQueries({ queryKey: ['dashboard-campaigns'] });
            });
        });
    };

    const draftCampaign = (campaign) => {
        putCampaignStatus({
            id: campaign.id,
            status: 1
        }).then(() => {
            showToast({
                text: 'La campagne a bien été mise en brouillon'
            });

            queryClient.refetchQueries({ queryKey: ['dashboard-campaigns'] });
        });
    };

    const cloneCampaign = (campaign) => {
        postCloneCampaign(campaign).then(() => {
            showToast({
                text: 'La campagne a bien été clonée'
            });

            queryClient.refetchQueries({ queryKey: ['dashboard-campaigns'] });
        });
    };
    const togglePreview = () => {};

    const deleteCampaign = (campaign) => {
        showConfirm({
            title: t('TA_CAMPAIGN_DELETE_CONFIRM_TITLE'),
            text: t('TA_CAMPAIGN_DELETE_CONFIRM_TEXT'),
            button: {
                label: t('TA_CAMPAIGN_DELETE_CONFIRM_BUTTON'),
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                deleteCampaignService.then(() => {
                    showToast({
                        text: 'La campagne a bien été supprimée'
                    });

                    queryClient.refetchQueries({ queryKey: ['dashboard-campaigns'] });
                });
            })
            .catch(() => {
                showToast({
                    text: 'Une erreur est survenue pendant la suppression'
                });
            });
    };

    return {
        status,
        isDraft,
        isRunning,
        isClosed,
        getCampaignTarget,
        getCampaignImage,
        getCampaignAuthor,
        closeCampaign,
        draftCampaign,
        cloneCampaign,
        togglePreview,
        deleteCampaign,
        totalShares,
        forgeDownloadCSVUrl,
        getTotalClicks
    };
};

export default useCampaignsCustomRender;
