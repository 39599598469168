import { useHttp } from '../../../../../api/useHttp';

const useSponsorshipClientProgramService = () => {
    const axios = useHttp();

    const post = (program) => {
        return axios.post(`/sponsorship/client/program`, { program }).then((response) => {
            return response.data;
        });
    };

    const get = (program) => {
        return axios.get(`/sponsorship/client/programs/${program.id}`, {}).then((response) => {
            return response.data;
        });
    };

    const deleteProgram = (program) => {
        return axios.delete(`/sponsorship/client/programs/${program.id}`);
    };

    const getAll = () => {
        return axios.get(`/sponsorship/client/programs`).then((response) => {
            return response.data;
        });
    };

    const getAllActive = () => {
        return axios.get(`/sponsorship/client/active/programs`).then((response) => {
            return response.data;
        });
    };

    const getAllPublic = () => {
        return axios.get(`/sponsorship/public/client/programs`).then((response) => {
            return response.data;
        });
    };

    const up = (program) => {
        return axios.put(`/sponsorship/client/programs/up/${program.id}`).then((response) => {
            return response.data;
        });
    };

    const down = (program) => {
        return axios.put(`/sponsorship/client/programs/down/${program.id}`).then((response) => {
            return response.data;
        });
    };

    return {
        get,
        down,
        getAll,
        up,
        deleteProgram,
        getAllPublic,
        getAllActive,
        post
    };
};

export default useSponsorshipClientProgramService;
