import React, { createContext, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Alerts from 'components/Alerts/Alerts';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const { t } = useTranslation();
    const [isBusy, setIsBusy] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (alerts.length > 0 && !isBusy) {
            setAlert(alerts[0]);
            setAlerts((prevAlerts) => prevAlerts.slice(1));
            setIsBusy(true);
        }
    }, [alerts.length, isBusy]);

    const clear = () => {
        setAlert(null);
        setIsBusy(false);
    };

    const showToast = (params) => {
        let { title = null, text = null, icon = {}, duration = 3000 } = params;
        setAlerts((prevState) => [
            ...prevState,
            {
                type: 'toast',
                title,
                text,
                icon,
                duration
            }
        ]);
    };

    const hideToast = () => {};

    const showTextArea = (params) => {
        let {
            title = null,
            text = null,
            inputs = [],
            textAreas = [],
            button = {},
            cancelButtonLabel = t('TAG_DELETE_CONFIRM_CANCEL')
        } = params;

        return new Promise((resolve, reject) => {
            setAlerts((prevState) => [
                ...prevState,
                {
                    type: 'textarea',
                    title: title,
                    text: text,
                    inputs: inputs,
                    textAreas: textAreas,
                    button: button,
                    cancelButtonLabel: cancelButtonLabel,
                    confirm: (value) => {
                        return resolve(value);
                    },
                    cancel: () => {
                        return reject(new Error('cancel'));
                    }
                }
            ]);
        });
    };

    const showPrompt = (params) => {
        let {
            title = null,
            text = null,
            button = {},
            placeholder = null,
            defaultValue = null,
            isSecret = false,
            clickOutsideToDismiss = true
        } = params;

        return new Promise((resolve, reject) => {
            setAlerts((prevState) => [
                ...prevState,
                {
                    type: 'prompt',
                    title: title,
                    text: text,
                    button: button,
                    placeholder: placeholder,
                    isSecret: isSecret,
                    defaultValue: defaultValue,
                    clickOutsideToDismiss: clickOutsideToDismiss,
                    confirm: (value) => {
                        return resolve(value);
                    },
                    cancel: () => {
                        return reject(new Error('cancel'));
                    }
                }
            ]);
        });
    };

    const showActionSheet = (params) => {
        let { title = null, text = null, buttons = [], cancel = () => {} } = params;
        setAlerts((prevState) => [
            ...prevState,
            {
                type: 'actionsheet',
                title,
                text,
                buttons,
                cancelButton: {
                    label: t('TAG_DELETE_CONFIRM_CANCEL'),
                    classes: [],
                    callback: cancel
                }
            }
        ]);
    };

    const showConfirm = (params) => {
        let {
            title = null,
            text = null,
            classes = [],
            button = null,
            cancelButtonLabel = t('POST_DIALOG_CLOSE'),
            cancelButtonClasses = []
        } = params;

        return new Promise((resolve, reject) => {
            setAlerts((prevState) => [
                ...prevState,
                {
                    type: 'confirm',
                    title: title,
                    text: text,
                    classes: classes,
                    button: button,
                    cancelButtonLabel: cancelButtonLabel,
                    cancelButtonClasses: cancelButtonClasses,
                    confirm: () => {
                        return resolve();
                    },
                    cancel: () => {
                        return reject(new Error('cancel'));
                    },
                    cancelBackdrop: () => {
                        return reject(new Error('cancelBackdrop'));
                    }
                }
            ]);
        });
    };

    // TODO show promptPeriod
    const showPromptPeriod = (params) => {
        let {
            title = null,
            text = null,
            classes = [],
            button = null,
            cancelButtonLabel = t('POST_DIALOG_CLOSE'),
            cancelButtonClasses = []
        } = params;

        return new Promise((resolve, reject) => {
            setAlerts((prevState) => [
                ...prevState,
                {
                    type: 'confirm',
                    title: title,
                    text: text,
                    classes: classes,
                    button: button,
                    cancelButtonLabel: cancelButtonLabel,
                    cancelButtonClasses: cancelButtonClasses,
                    confirm: () => {
                        return resolve();
                    },
                    cancel: () => {
                        return reject(new Error('cancel'));
                    },
                    cancelBackdrop: () => {
                        return reject(new Error('cancelBackdrop'));
                    }
                }
            ]);
        });
    };

    return (
        <AlertContext.Provider
            value={{ showToast, showTextArea, showPrompt, showActionSheet, showConfirm, hideToast }}
        >
            {alert && <Alerts alert={alert} clear={clear} />}
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
