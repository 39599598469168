import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useNotificationService from 'api/useNotificationService';
import useRight from 'hooks/useRight';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
    const { hasRole } = useRight();
    const { getAllByUser, getAllStackedByUser } = useNotificationService();

    const [notifications, setNotifications] = useState([]);
    const [mode, setMode] = useState(null);

    const { data, isLoading } = useQuery({
        queryKey: ['user_notifications', 'panel'],
        queryFn: () => {
            if (hasRole('NEW_NOTIFICATIONS_FORMAT')) {
                setMode('stacked');
                return getAllStackedByUser(10);
            } else {
                setMode('default');
                return getAllByUser(10);
            }
        }
    });

    useEffect(() => {
        if (data) {
            setNotifications(data);
        }
    }, [data]);

    return (
        <NotificationsContext.Provider value={{ notifications, isLoading, mode }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationsContext = () => useContext(NotificationsContext);
export default NotificationsProvider;
