import { useHttp } from 'api/useHttp';

const useBrandService = () => {
    const axios = useHttp();

    const getBrands = () => {
        return axios.get('/brands').then((response) => response.data);
    };

    return {
        getBrands
    };
};

export default useBrandService;
