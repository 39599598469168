import { useTranslation } from 'react-i18next';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import Shared from './shared';
import { useRouter } from '@uirouter/react';

const Survey = ({ campaign }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        isClosed,
        isDraft,
        isRunning,
        changeToDraft,
        cloneCampaign,
        closeCampaign,
        forgeDownloadCSVUrl,
        deleteCampaign
    } = useCampaignsCustomRender(campaign);

    return (
        <Shared campaign={campaign}>
            <div className={`informations ${campaign.type}`}>
                <div className="details">
                    <div className="stats">
                        {campaign.reward > 0 && (
                            <div className="stat application not-pointer">
                                <div className="icon">
                                    <i className="fa fa-money" aria-hidden="true"></i>
                                </div>
                                <div className="text">
                                    {campaign.total_rewards} {t('TA_CAMPAIGN_POINTS_GIVEN')}
                                </div>
                            </div>
                        )}
                        <div className="stat application" ng-click="$ctrl.toggleSurveys()">
                            <div className="icon">
                                <i className="fa fa-share-alt" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                {campaign.submits_count} {t('TA_CAMPAIGN_SURVEY_ANSWERS')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    {campaign.reward > 0 && (
                        <div className="reward">
                            <div className="unique">
                                {campaign.reward} {t('POINTS')}
                            </div>
                        </div>
                    )}
                    <div className="buttons">
                        {campaign.submits_count > 0 && (
                            <a className="button grey" target="_blank" href={forgeDownloadCSVUrl()}>
                                {t('TA_CAMPAIGN_EXPORT')}
                            </a>
                        )}
                        {(isDraft || isRunning) && (
                            <>
                                <button className="button grey" onClick={closeCampaign}>
                                    {t('TA_CAMPAIGN_CLOSE')}
                                </button>
                                <a
                                    className="button grey"
                                    href={router.stateService.href(
                                        'auth.dashboard.campaigns.create',
                                        {
                                            type: campaign.type,
                                            id: campaign.id
                                        }
                                    )}>
                                    {t('TA_CAMPAIGN_EDIT')}
                                </a>
                            </>
                        )}

                        <button className="button grey" onClick={cloneCampaign}>
                            {t('TA_CAMPAIGN_DUPLICATE')}
                        </button>
                        {isClosed && (
                            <button className="button grey" onClick={changeToDraft}>
                                {t('TA_CAMPAIGN_MOVE_DRAFT')}
                            </button>
                        )}
                        <button
                            className="button grey"
                            ng-click="$ctrl.togglePreview($ctrl.campaign)">
                            {t('TA_CAMPAIGN_PREVIEW')}
                        </button>
                        {!isRunning && (
                            <button className="button red" onClick={deleteCampaign}>
                                {t('TA_CAMPAIGN_DELETE')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Shared>
    );
};

export default Survey;
