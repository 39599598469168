import { useConfig } from 'providers/ConfigProvider';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const useDate = () => {
    const { config, locale: code } = useConfig();
    const { t } = useTranslation();

    const format = (dateString, filterType = null) => {
        if (!dateString) {
            return '';
        }

        if (!filterType) {
            filterType = 'full';
        }

        let date = dayjs(dateString).locale(code);

        if (filterType === 'simple') {
            return date.format(t('POST_DATE_LIGHT_FORMAT'));
        }

        if (filterType === 'simple_hours') {
            return date.format(t('POST_DATE_LIGHT_HOURS_FORMAT'));
        }

        if (filterType === 'human') {
            return date.format('LLL');
        }

        if (config.isMsie) {
            if (filterType === 'light') {
                return date.format(t('POST_DATE_LIGHT_FORMAT'));
            }

            return date.format('LLL');
        }

        let diffSeconds = dayjs().diff(date, 'seconds');

        if (diffSeconds < 60) {
            return t('POST_DATE_NOW');
        }

        if (diffSeconds < 3600) {
            if (filterType === 'since') {
                return t('POST_DATE_FEW_MINUTES_SINCE', { minutes: Math.floor(diffSeconds / 60) });
            }

            return t('POST_DATE_FEW_MINUTES', { minutes: Math.floor(diffSeconds / 60) });
        }

        let startOfDay = dayjs().startOf('day');
        let secondsSinceMidnight = dayjs().diff(startOfDay, 'seconds');

        if (diffSeconds <= secondsSinceMidnight) {
            if (filterType === 'since') {
                return t('POST_DATE_FEW_HOURS_SINCE', { hours: Math.floor(diffSeconds / 3600) });
            }
            return t('POST_DATE_FEW_HOURS', { hours: Math.floor(diffSeconds / 3600) });
        }

        let diffSecondsYesterday = diffSeconds - secondsSinceMidnight;

        if (diffSecondsYesterday <= 86400) {
            return t('POST_DATE_YESTERDAY', { time: date.format('HH:mm') });
        }

        if (filterType === 'light') {
            return date.format(t('POST_DATE_LIGHT_FORMAT'));
        }

        return date.format('L');
    };

    return {
        format
    };
};

export default useDate;
