import Notifications from '../Notifications';
import NotificationsPanel from '../components/NotificationsPanel';

export default [
    {
        name: 'auth.notifications',
        url: 'notifications',
        component: Notifications,
        navbar: {
            getIcon: () => {
                return 'icon-alarm-bell';
            },
            getLabel: (context) => {
                return context.t('NAVBAR_NOTIFICATIONS');
            },
            getClasses: () => {
                return ['with-right-aligned-dropdown'];
            },
            getTemplate: () => {
                return <NotificationsPanel />;
            },
            getCallback: (context, state) => {
                //context.Notifications?.clearNotifications();

                if (context.viewport.xs) {
                    context.router.stateService.go(
                        'auth.notifications',
                        {},
                        { reload: true, inherit: false }
                    );
                } /*
                else {
                    state.isClicked = !state.isClicked;
                }*/
            },
            hasBadge: (context) => {
                //return context.Notifications.hasUnreadNotifications();
            },
            getBadge: (context) => {
                //return context.Notifications.getUnreadNotifications();
            },
            isActive: (context) => {
                return context.isActive(['auth.notifications.**']);
            },
            isVisible: () => {
                return true;
            }
        }
    }
];
