import React, { createContext, useState, useContext, useCallback } from 'react';
import { some } from 'lodash-es';
import { useSetState } from 'react-use';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);
    const [contexts, setContexts] = useSetState({});

    const registerModal = (params) => {
        if (!modalExists(params.name)) {
            setModals((prevState) => [
                ...prevState,
                {
                    isOpen: false,
                    ...params
                }
            ]);
        }
    };

    const modalExists = (name) => {
        return modals.find((modal) => modal.name === name);
    };

    const isModalOpen = (name) => {
        return modalExists(name) && modalExists(name).isOpen;
    };

    const openModal = (name, context = {}) => {
        setContexts(() => ({ [name]: context }));
        setModals((prevState) => [
            ...prevState.map((modal) => {
                if (modal.name === name) {
                    return { ...modal, isOpen: true };
                }
                return modal;
            })
        ]);
    };

    const getModal = useCallback(
        (name) => {
            return modals.find((m) => m.name === name);
        },
        [modals]
    );

    const closeModal = useCallback(
        (name) => {
            getModal(name)?.closeCallback(contexts[name]);
            setContexts(() => ({ [name]: null }));
            setModals((prevState) => [
                ...prevState.map((modal) => {
                    if (modal.name === name) {
                        return { ...modal, isOpen: false };
                    }
                    return modal;
                })
            ]);
        },
        [modals]
    );

    const hasModalOpened = useCallback(() => {
        return some(modals, (modal) => {
            return modal.isOpen && !modal.invisible && !modal.invisibleAtStartup;
        });
    }, [modals]);

    const updateModal = (name, updates) => {
        setModals((prevState) => [
            ...prevState.map((modal) => {
                if (modal.name === name) {
                    return { ...modal, ...updates };
                }
                return modal;
            })
        ]);
    };

    return (
        <ModalContext.Provider
            value={{
                registerModal,
                modalExists,
                isModalOpen,
                openModal,
                closeModal,
                hasModalOpened,
                updateModal,
                modals,
                contexts
            }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
