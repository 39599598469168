import React from 'react';
import { useTranslation } from 'react-i18next';
import useForbiddenWords from '../../../../hooks/useForbiddenWords';
import { usePost } from '../../providers/PostProvider';

const PostReview = () => {
    const {
        post,
        canReviewPost,
        hideAll,
        displayStackedSlotReview,
        reviewDiscardPost,
        reviewPublishPost,
        editPost,
        canEditReviewPost,
        canEditDraft,
        editDraftedPost
    } = usePost();
    const { t } = useTranslation();
    const { hasMatches } = useForbiddenWords();
    return (
        <>
            {hasMatches(post.content) && hasMatches(post.content).length > 0 && (
                <div className="post__info forbidden-words">
                    <span className="title">{t('FORBIDDEN_WORDS_LABEL')} :</span>{' '}
                    {hasMatches(post.content).join(', ')}
                </div>
            )}

            {canReviewPost() && !hideAll && !displayStackedSlotReview() && (
                <div className="post__info review">
                    <button className="button small rounded grey" onClick={reviewDiscardPost}>
                        {t('POST_REVIEW_REJECT')}
                    </button>
                    <button
                        className="button small rounded primary-color"
                        onClick={reviewPublishPost}>
                        {t('POST_REVIEW_PUBLISH')}
                    </button>
                    {canEditReviewPost() && (
                        <button className="button small rounded primary-color" onClick={editPost}>
                            {t('EDITOR_EDIT_FORM')}
                        </button>
                    )}
                </div>
            )}

            {canEditDraft() && (
                <div className="post__info review">
                    <button
                        className="button small rounded primary-color"
                        onClick={editDraftedPost}>
                        {t('POST_DRAFT_EDIT')}
                    </button>
                </div>
            )}
        </>
    );
};

export default PostReview;
