import { useHttp } from 'api/useHttp';

const useWorkspaceService = () => {
    const axios = useHttp();

    const getWorkspaceFolders = (id) => {
        return axios.get(`/workspaces/${id}/folders`).then((response) => {
            let workspace = response.data.workspace;
            workspace.folders = response.data.folders;
            return workspace;
        });
    };

    const putWorkspaceUser = (workspaceId) => {
        return axios.put(`/workspaces/${workspaceId}/user`).then((response) => response.data);
    };

    const findUsersByCriteria = (
        criteria,
        excludedUserIds,
        workspaceId = null,
        campaignId = null
    ) => {
        return axios
            .get(`/users/autocomplete`, {
                params: {
                    criteria: criteria.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    'excludedUserIds[]': excludedUserIds,
                    workspaceId,
                    campaignId
                }
            })
            .then((response) => response.data);
    };

    const getWorkspaceInfos = (id) => {
        return axios.get(`/workspaces/${id}/infos`).then((response) => {
            if (
                response.data.workspace_form &&
                response.data.workspace_form.workspace_form_inputs &&
                response.data.workspace_form.workspace_form_inputs.length
            ) {
                response.data.workspace_form.workspace_form_inputs.map((item) => {
                    if (item.type == 'date') {
                        item.value = item.default ? item.default : new Date();
                        return item;
                    }
                    item.value = item.default ? item.default : null;

                    return item;
                });
            }

            return response.data;
        });
    };

    const getAuthorizedWorkspaceRulesByUser = () => {
        return axios
            .get(`/all/authorized/workspaces/rules/by/user`)
            .then((response) => response.data);
    };

    const getWorkspaceUserSubscriptions = () => {
        return axios.get(`/all/workspaces/user/subscriptions`).then((response) => response.data);
    };

    const getAllPublicWorkspaces = (excludedIds = []) => {
        let params = { params: { 'excludedIds[]': excludedIds } };

        return axios.get(`/all/public/workspaces`, params).then((response) => response.data);
    };

    const toggleUserWorkspaceRule = (workspaceRuleId, type) => {
        let data = {
            type
        };

        return axios
            .put(`/workspaces/${workspaceRuleId}/rule/user`, data)
            .then((response) => response.data);
    };

    const deleteWorkspaceUserSubscription = (workspace, user) => {
        return axios
            .delete(`/workspaces/${workspace.id}/users/${user.id}/subscription`)
            .then((response) => response.data);
    };

    const postWorkspaceUserSubscription = (workspace, user) => {
        return axios
            .post(`/workspaces/${workspace.id}/users/${user.id}/subscriptions`)
            .then((response) => response.data);
    };

    const getWorkspaceAdminRulesByUser = (id) => {
        let params = { params: { userId: id } };

        return axios
            .get(`/all/workspace/admin/rules/by/user`, params)
            .then((response) => response.data);
    };

    const getWorkspaceRulesByUser = (id) => {
        let params = { params: { userId: id } };

        return axios.get(`/all/workspace/rules/by/user`, params).then((response) => response.data);
    };

    const getAllWorkspaces = (excludedIds) => {
        let params = { params: { 'excludedIds[]': excludedIds } };
        return axios.get(`/all/workspaces`, params).then((response) => response.data);
    };

    return {
        getWorkspaceFolders,
        putWorkspaceUser,
        findUsersByCriteria,
        getWorkspaceInfos,
        getAuthorizedWorkspaceRulesByUser,
        getWorkspaceUserSubscriptions,
        toggleUserWorkspaceRule,
        deleteWorkspaceUserSubscription,
        postWorkspaceUserSubscription,
        getWorkspaceAdminRulesByUser,
        getWorkspaceRulesByUser,
        getAllPublicWorkspaces,
        getAllWorkspaces
    };
};

export default useWorkspaceService;
