import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTo } from 'lodash-es';
import { useAuth } from 'providers/AuthContext';
import { useConfig } from 'providers/ConfigProvider';
import useRight from './useRight';

const usePassword = () => {
    const { user } = useAuth();
    const { hasRole } = useRight();
    const { config } = useConfig();
    const { t } = useTranslation();

    const [rules, setRules] = useState([]);
    const [passwordHints, setPasswordHints] = useState([]);

    useEffect(() => {
        configureRules();
    }, [user, config]);

    const configureRules = () => {
        let minimumPasswordLength = getMinimumPasswordCharacters();

        let minimumUppercaseCount = 1;
        let minimumLowercaseCount = 1;
        let minimumDigitCount = 1;
        let minimumSpecialCharCount = 1;

        if (hasRole('MANAGE_PASSWORD_RULES_FROM_ADMIN')) {
            minimumPasswordLength = defaultTo(config.password_min_length, minimumPasswordLength);
        }

        if (hasRole('PASSWORD_AT_LEAST_AN_UPPERCASE')) {
            minimumUppercaseCount = defaultTo(
                config.password_uppercase_min_length,
                minimumUppercaseCount
            );
        }

        if (hasRole('PASSWORD_AT_LEAST_A_LOWERCASE')) {
            minimumLowercaseCount = defaultTo(
                config.password_lowercase_min_length,
                minimumLowercaseCount
            );
        }

        if (hasRole('PASSWORD_AT_LEAST_A_DIGIT')) {
            minimumDigitCount = defaultTo(config.password_digit_min_length, minimumDigitCount);
        }

        if (hasRole('PASSWORD_AT_LEAST_A_SPECIAL_CHAR')) {
            minimumSpecialCharCount = defaultTo(
                config.password_special_char_min_length,
                minimumSpecialCharCount
            );
        }

        const newRules = [
            {
                description: t('PASSWORD_MIN_LENGTH', { total: minimumPasswordLength }),
                enabled: true,
                mandatory: true,
                test: (password) => {
                    return password.length >= minimumPasswordLength;
                }
            },
            {
                description: t('PASSWORD_AT_LEAST_AN_UPPERCASE', { total: minimumUppercaseCount }),
                enabled: true,
                mandatory: hasRole('PASSWORD_AT_LEAST_AN_UPPERCASE'),
                test: (password) => {
                    return new RegExp(`([A-Z].*){${minimumUppercaseCount},}`).test(password);
                }
            },
            {
                description: t('PASSWORD_AT_LEAST_A_LOWERCASE', { total: minimumLowercaseCount }),
                enabled: true,
                mandatory: hasRole('PASSWORD_AT_LEAST_A_LOWERCASE'),
                test: (password) => {
                    return new RegExp(`([a-z].*){${minimumLowercaseCount},}`).test(password);
                }
            },
            {
                description: t('PASSWORD_AT_LEAST_A_DIGIT', { total: minimumDigitCount }),
                enabled: true,
                mandatory: hasRole('PASSWORD_AT_LEAST_A_DIGIT'),
                test: (password) => {
                    return new RegExp(`([0-9].*){${minimumDigitCount},}`).test(password);
                }
            },
            {
                description: t('PASSWORD_AT_LEAST_A_SPECIAL_CHAR', {
                    total: minimumSpecialCharCount
                }),
                enabled: true,
                mandatory: hasRole('PASSWORD_AT_LEAST_A_SPECIAL_CHAR'),
                test: (password) => {
                    return new RegExp(
                        `([!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*){${minimumSpecialCharCount},}`
                    ).test(password);
                }
            },
            {
                description: t('PASSWORD_NO_THREE_FOLLOWING_SAME_CHAR'),
                enabled: hasRole('PASSWORD_NO_THREE_FOLLOWING_SAME_CHAR'),
                mandatory: hasRole('PASSWORD_NO_THREE_FOLLOWING_SAME_CHAR'),
                test: (password) => {
                    return !new RegExp(`([a-z0-9])\\1{2,}`, 'i').test(password);
                }
            }
        ];

        setRules(newRules);
        processHints('');
    };

    const processHints = (password = '') => {
        const rulesToUse = hasRole('MANAGE_PASSWORD_RULES_FROM_ADMIN')
            ? getMandatoryRules()
            : getEnabledRules();
        const hints = rulesToUse.map((rule) => {
            return {
                description:
                    !hasRole('MANAGE_PASSWORD_RULES_FROM_ADMIN') && rule.mandatory
                        ? `${rule.description} (obligatoire)`
                        : rule.description,
                mandatory: rule.mandatory,
                matched: rule.test(password)
            };
        });
        setPasswordHints(hints);
    };

    const isValid = (password, withHints = true) => {
        if (withHints) {
            processHints(password);
        }

        if (!password) {
            return false;
        }

        if (hasRole('MANAGE_PASSWORD_RULES_FROM_ADMIN')) {
            return getMandatoryRules().every((rule) => {
                return rule.test(password);
            });
        }

        let minimumPasswordForce = getMinimumPasswordLevel();

        return (
            getMandatoryRules().every((rule) => {
                return rule.test(password);
            }) &&
            getOptionnalRules().filter((rule) => {
                return rule.test(password);
            }).length >=
                minimumPasswordForce - getMandatoryRules().length
        );
    };

    const getMinimumPasswordLevel = () => {
        return defaultTo(config.minimum_password_level, getMandatoryRules().length);
    };

    const getMinimumPasswordCharacters = () => {
        return defaultTo(config.minimum_password_characters, 6);
    };

    const getEnabledRules = () => {
        return rules.filter((rule) => rule.enabled);
    };

    const getOptionnalRules = () => {
        return rules.filter((rule) => rule.enabled && !rule.mandatory);
    };

    const getMandatoryRules = () => {
        return rules.filter((rule) => rule.enabled && rule.mandatory);
    };

    return {
        rules,
        passwordHints,
        isValid,
        getMinimumPasswordLevel,
        getHints: () => passwordHints
    };
};

export default usePassword;
