import { useTranslation } from 'react-i18next';
import RealTimeDate from 'components/RealTimeDate/RealTimeDate';

const Survey = ({ activity }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`card ${activity.type}`}>
                <div className="badge">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                </div>

                <div className="content">
                    <div className="campaign">{activity.title}</div>
                    <div className="text">
                        {t('TA_ACTIVITIES_SURVEY_TITLE', { number: activity.submits_count })}
                    </div>
                </div>

                <div className="meta">
                    <div className="date">
                        <RealTimeDate date={activity.last_submit_date} />
                    </div>
                </div>
            </div>

            <div className="submit">
                <div className="referer">
                    <div className="user">
                        <div className="info">
                            <div className="name">
                                {t('TA_ACTIVITY_SURVEY_ANSWERS', {
                                    answers: activity.submits_count
                                })}
                            </div>
                            <div className="job">
                                {t('TA_ACTIVITY_SURVEY_POINTS', { points: activity.total_rewards })}
                            </div>
                        </div>
                        <div className="actions">
                            <a
                                href="{ surveyCampaignService.forgeDownloadCSVUrl(activity.id, timestamp) }"
                                target="_blank"
                                className="button">
                                <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                {t('TA_ACTIVITY_SURVEY_EXPORT')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Survey;
