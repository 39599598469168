import useRight from '../../../hooks/useRight';

const useSharedDocumentRight = () => {
    const { isAdmin } = useRight();

    const canSeeAdminActionsForCategory = (parentCategory) => {
        if (parentCategory === undefined) {
            return false;
        }

        if (parentCategory?.current_user_is_category_admin) {
            return true;
        }

        if (parentCategory?.parent?.current_user_is_category_admin) {
            return true;
        }

        return isAdmin();
    };

    const canSeeAdminActionsForArticle = (article) => {
        if (article === undefined) {
            return false;
        }

        if (article.is_owner) {
            return true;
        }

        return canSeeAdminActionsForCategory(article.category);
    };

    return {
        canSeeAdminActionsForCategory,
        canSeeAdminActionsForArticle
    };
};

export default useSharedDocumentRight;
