import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useSharedDocumentCategoryService from 'api/useSharedDocumentCategoryService';
import { defaultTo } from 'lodash-es';
import useSharedDocumentArticleService from '../../../api/useSharedDocumentArticleService';

const ShareDocumentContext = createContext();

const ShareDocumentProvider = ({ children }) => {
    const { params } = useCurrentStateAndParams();
    const { getCategories } = useSharedDocumentCategoryService();
    const { getArticle } = useSharedDocumentArticleService();

    const [query, setQuery] = useState('');
    const [currentCategoryId, setCurrentCategoryId] = useState(defaultTo(params.categoryId, null));
    const [parentCategory, setParentCategory] = useState(null);
    const [breadCrumb, setBreadCrumb] = useState(null);
    const [breadcrumbArticle, setBreadcrumbArticle] = useState(null);
    const [contact, setContact] = useState(null);

    const { data: categories } = useQuery({
        queryKey: [query, 'sharedDocuments'],
        queryFn: () => {
            return getCategories(params);
        }
    });

    const { data: article } = useQuery({
        queryKey: [`sharedDocumentArticle_${params.articleId}`, 'sharedDocumentArticles'],
        queryFn: () => {
            if (params.articleId) {
                return getArticle(params.articleId);
            }
            return null;
        }
    });

    useEffect(() => {
        setCurrentCategoryId(defaultTo(params.categoryId, null));
        setQuery(`sharedDocuments_${[...Object.values(params)].join('_')}`);
    }, [params]);

    useEffect(() => {
        if (categories && categories.length > 0) {
            if (currentCategoryId) {
                const parent = categories[0];
                setBreadCrumb(processBreadcrumb(parent));
                setParentCategory(categories[0]);
            } else {
                setBreadCrumb(null);
                setParentCategory(null);
            }
        }
    }, [categories]);

    useEffect(() => {
        if (breadCrumb && breadCrumb.length > 0 && params.articleId) {
            const categ = breadCrumb[breadCrumb.length - 1];
            setBreadcrumbArticle(categ.articles.find((article) => article.id === params.articleId));
        } else {
            setBreadcrumbArticle(null);
        }
    }, [params, breadCrumb]);

    useEffect(() => {
        if (parentCategory?.contact) {
            setContact(parentCategory?.contact);
        } else {
            setContact(null);
        }
    }, [parentCategory]);

    useEffect(() => {
        if (breadcrumbArticle?.contact) {
            setContact(breadcrumbArticle.contact);
        }
    }, [breadcrumbArticle]);

    const processBreadcrumb = (parentCategory) => {
        const breadcrumbCategories = [];
        recursiveBreadcrumbCategories(parentCategory, breadcrumbCategories);
        breadcrumbCategories.reverse();
        return breadcrumbCategories;
    };

    const recursiveBreadcrumbCategories = (category, breadcrumbCategories = null) => {
        breadcrumbCategories.push(category);

        if (category.parent) {
            recursiveBreadcrumbCategories(category.parent, breadcrumbCategories);
        }

        if (breadcrumbCategories) {
            return breadcrumbCategories;
        }
    };

    return (
        <ShareDocumentContext.Provider
            value={{
                currentCategoryId,
                parentCategory,
                breadCrumb,
                breadcrumbArticle,
                categories,
                article,
                contact
            }}
        >
            {children}
        </ShareDocumentContext.Provider>
    );
};

export const useShareDocument = () => useContext(ShareDocumentContext);
export default ShareDocumentProvider;
