import { useAuth } from 'providers/AuthContext';
import useRight from 'hooks/useRight';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'providers/AlertContext';
import { useConfig } from 'providers/ConfigProvider';
import { useRouter } from '@uirouter/react';
import useUser from 'hooks/useUser';
import dayjs from 'dayjs';
import useViewport from 'hooks/useViewport';
import { useModal } from 'providers/ModalContext';

const Clipboard = require('@capacitor/clipboard').Clipboard;

const useCampaignComponent = (campaign) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { isSubscribedToCampaign } = useUser();
    const { hasRole } = useRight();
    const { showToast, showActionSheet } = useAlert();
    const { config } = useConfig();
    const router = useRouter();
    const viewport = useViewport();
    const { closeModal } = useModal();

    const showActions = () => {
        let buttons = [];

        buttons.push({
            label: t('COPY_LINK_ADDRESS_CAMPAIGN'),
            callback: () => {
                copyLinkAddress();
            }
        });

        if (isSubscribedToCampaign(campaign.id)) {
            buttons.push({
                label: t('POST_UNFOLLOW'),
                classes: ['red'],
                callback: () => {
                    unSubscribe();
                }
            });
        } else {
            buttons.push({
                label: t('CAMPAIGN_FOLLOW'),
                callback: () => {
                    subscribeActionSheet();
                }
            });
        }

        showActionSheet({
            title: 'Options',
            buttons
        });
    };

    const copyLinkAddress = () => {
        let domain = config.frontUrl ? config.frontUrl : config.serverUrl;

        Clipboard.write({
            string:
                domain +
                router.stateService.href(`auth.campaigns.campaign`, {
                    type: campaign.type,
                    id: campaign.id
                })
        }).then(() => {
            showToast({
                text: t('POST_LINK_COPIED'),
                duration: 3500
            });
        });
    };

    const subscribeActionSheet = () => {
        showActionSheet({
            title: t('CAMPAIGN_FOLLOW'),
            text: t('POST_YOU_CAN'),
            buttons: [
                {
                    label: t('POST_FOLLOW_COMMENTS'),
                    callback: () => {
                        subscribe(1);
                    }
                },
                {
                    label: t('POST_FOLLOW_COMMENTS_AND_LIKES'),
                    callback: () => {
                        subscribe(2);
                    }
                }
            ],
            cancel: () => {
                showActions();
            }
        });
    };

    const subscribe = (type) => {
        /*Campaigns.addCampaignUserSubscription(campaign, user, type).then(() => {
            user.campaign_subscriptions.push({
                campaign_id: campaign.id,
                type
            });
        });*/
    };

    const unSubscribe = () => {
        /*Campaigns.removeCampaignUserSubscription(campaign, user).then(() => {
            let deletedIndexAt = -1;

            user.campaign_subscriptions.forEach((subscription, index) => {
                if (subscription.campaign_id === campaign.id) {
                    deletedIndexAt = index;
                }
            });

            if (deletedIndexAt !== -1) {
                user.campaign_subscriptions.splice(deletedIndexAt, 1);
            }
        });*/
    };

    const canSeeStats = () => {
        if (
            (undefined !== campaign.owner && user.id === campaign.owner.id) ||
            hasRole('ROLE_SUPER_ADMIN')
        ) {
            return true;
        } else if (campaign.is_assistant) {
            return true;
        } else if (campaign.type === 'social' && hasRole('ROLE_SUPER_RS')) {
            return true;
        } else if (campaign.type === 'recruiting' && hasRole('ROLE_SUPER_RECRUITING')) {
            return true;
        } else if (campaign.type === 'product' && hasRole('ROLE_SUPER_PRODUCT')) {
            return true;
        } else if (campaign.type === 'survey' && hasRole('ROLE_SUPER_SURVEY')) {
            return true;
        }

        return false;
    };

    const toggleRules = () => {
        let rules = null;
        if (campaign.rule && campaign.rule.description) {
            rules = campaign.rule.description;
        }
        if (null === rules) {
            return;
        }

        /*DisplayRules.createPanel({
            campaign: campaign,
            rules: rules,
        });*/
    };

    const isCampaignLive = () => {
        return campaign.status == 2;
    };

    const openModalCampaignForm = (
        title = null,
        subTitle = null,
        shareWithAFriend = false,
        showRightAnswers = false,
        answers = null
    ) => {
        /*if (this.campaign.workspace_for_internal) {
            this.Composer.show({
                post: {
                    attach_campaign: true,
                    workspace: this.campaign.workspace_for_internal,
                    category: this.campaign.workspace_for_internal.category,
                    campaign: this.campaign
                }
            })
        } else {
            this.CampaignForm.createPanel({
                campaign: this.campaign,
                title,
                subTitle,
                shareWithAFriend,
                showRightAnswers,
                answers,
            });
        }*/
    };

    const showCarousel = (attachments, index) => {
        /*this.Carousel.initCarouselState();
        this.Carousel.images = attachments;
        this.Carousel.index = index;
        this.Carousel.show();*/
    };

    const shareCampaign = () => {
        /*if (IS_APP) {
            let userLinkAssociation = {
                social: 'user_link',
                recruiting: 'share_link',
            };

            let userLink = this.campaign[userLinkAssociation[this.campaign.type]];
            let defaultText = this.campaign.catchy_social_text ? this.campaign.catchy_social_text : null;

            Share.share({
                title: defaultText,
                text: defaultText,
                url: userLink,
                dialogTitle: this.translate('WHICH_NETWORK_YOUD_LIKE_TO_SHARE'),
            }).then((res) => {
                this.displayReward();
            }).catch((err) => {
                console.log('error: ' + err)
            });
        } else {
            this.ShareCampaign.createPanel({
                campaign: this.campaign,
            });
        }*/
    };

    const displayReward = (isFuture = true) => {
        /*if (this.campaign.type === 'social') {
            this.DisplayReward.createPanel({
                points: this.campaign.reward,
                isFuture,
                clicks: this.campaign.share_limit,
                podium: this.campaign.users,
                campaign: this.campaign,
                myClicks: this.campaign.totalUserClicks,
            });
        } else if (this.campaign.type === 'product' && (this.campaign.opportunity || this.campaign.email_opportunity)) {
            this.DisplayReward.createPanel({
                points: this.campaign.reward,
                hideClose: true,
                campaign: this.campaign,
            });
        } else {
            this.DisplayReward.createPanel({
                points: this.campaign.reward,
            });
        }*/
    };

    const openModalPodium = () => {
        /*this.DisplayPodium.createPanel({
            podium: this.campaign.users,
            myClicks: this.campaign.totalUserClicks,
        });*/
    };

    const getCampaignTypeIcon = (type) => {
        switch (type) {
            case 'social':
                return 'fa-share-alt';
                break;
            case 'recruiting':
                return 'fa-users';
                break;
            case 'product':
                return 'fa-cube';
                break;
            case 'survey':
                return 'fa-comment';
                break;
        }
    };

    const isCampaignProgrammed = () => {
        if (!campaign.startDate) {
            return false;
        }

        const now = new dayjs();
        return campaign.status === 1 && now.isBefore(new dayjs(campaign.startDate));
    };

    const displaySingleCampaign = () => {
        if (viewport.xs) {
            /*this.SingleCampaign.createPanel({
                campaign: this.campaign,
                user: this.user,
            });*/
        } else {
            closeModal('preview-post');
            router.stateService.go(
                `auth.campaigns.campaign`,
                { type: campaign.type, id: campaign.id },
                { reload: true, inherit: false }
            );
        }
    };

    return {
        canSeeStats,
        unSubscribe,
        subscribe,
        showActions,
        toggleRules,
        showCarousel,
        shareCampaign,
        displayReward,
        openModalPodium,
        isCampaignLive,
        openModalCampaignForm,
        getCampaignTypeIcon,
        isCampaignProgrammed,
        displaySingleCampaign
    };
};

export default useCampaignComponent;
