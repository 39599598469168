import React from 'react';
import Modal from 'components/Modal/Modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const LastConnectionModal = ({ lastConnections = [] }) => {
    const { t } = useTranslation();

    return (
        <Modal
            name={`last_connections`}
            className="last_connections"
            title={t('LAST_USER_CONNECTIONS')}
            options={{ hasFlexContent: true }}
        >
            <div className="false-table">
                <div className="connections-head">
                    <div className="head">Date</div>
                    <div className="head">Plateforme</div>
                    <div className="head">App version</div>
                    <div className="head">App build</div>
                </div>

                <div className="connections">
                    {lastConnections.map((connection, index) => {
                        return (
                            <div className="connection" key={`connection_${index}`}>
                                <div className="date">
                                    {dayjs(connection.created_at).format('LLL')}
                                </div>
                                <div className="platform">{connection.platform}</div>
                                <div className="app-version">{connection.app_version}</div>
                                <div className="app-build">{connection.app_build}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default LastConnectionModal;
