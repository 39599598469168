import { useRouter } from '@uirouter/react';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import useRight from './useRight';

const useBlog = () => {
    const router = useRouter();
    const { locale } = useConfig();
    const { t } = useTranslation();
    const { hasRole } = useRight();
    const { config } = useConfig();

    const isBlogState = () => {
        return (
            router.stateService.includes('public.blog.**') ||
            router.stateService.includes('auth.blog.**')
        );
    };

    const isPublicState = () => {
        return router.stateService.includes('public.**');
    };

    const isSingleArticleState = () => {
        return (
            router.stateService.includes('auth.blog.article') ||
            router.stateService.includes('public.blog.article')
        );
    };

    const isAdminRoute = () => {
        return (
            router.stateService.includes('auth.blog.admin') ||
            router.stateService.includes('auth.blog.admin-search')
        );
    };

    const getLocalizedArticleProperty = (article, property) => {
        let localizedArticleContent = article.contents.find((content) => {
            return content.locale === locale;
        });

        if (!localizedArticleContent) {
            return article.contents[0][property];
        }

        return localizedArticleContent[property];
    };

    const getLocalizedCategoryTitle = (category) => {
        if (!category.name.hasOwnProperty(locale)) {
            return Object.values(category.name)[0];
        }

        return category.name[locale];
    };

    const getArticleTypeIcon = (article) => {
        switch (article.type) {
            case 'article':
                return 'icon-design-file-text-image';
            case 'podcast':
                return 'icon-audio-file';
            case 'video':
                return 'icon-video-file-camera';
            case 'gallery':
                return 'icon-paginate-filter-picture';
        }
    };

    const canSeeAdminActions = () => {
        return hasRole('ROLE_ADMIN_BLOG') || hasRole('ROLE_ADMIN') || hasRole('ROLE_SUPER_ADMIN');
    };

    const readMore = (article, withCommentHash = false, indirect = false) => {
        let params = {
            slug: getLocalizedArticleProperty(article, 'slug'),
            article: article.id
        };

        if (withCommentHash) {
            params['#'] = 'comment';
        }

        if (indirect) {
            return router.stateService.href('auth.blog.article', params);
        }

        router.stateService.go('auth.blog.article', params, { reload: false, inherit: false });
    };

    const showConsultationUsers = (blogArticleId) => {
        if (!hasRole('ROLE_ADMIN')) {
            return;
        }

        window.open(this.downloadConsultationsUsers(blogArticleId), '_blank');
    };

    const downloadConsultationsUsers = (blogArticleId) => {
        return `${config.apiUrl}/blog/articles/download/${blogArticleId}/consultations`;
    };

    const getArticleTimeToRead = (article, long = false) => {
        const timeToRead = getLocalizedArticleProperty(article, 'timeToRead');

        if (!timeToRead) {
            return null;
        }

        let key = 'BLOG_TIME_TO_READ';

        if (long) {
            key = 'BLOG_TIME_TO_READ_LONG';
        }

        return t(key, { timeToRead });
    };

    return {
        isBlogState,
        isPublicState,
        isSingleArticleState,
        isAdminRoute,
        getLocalizedArticleProperty,
        getArticleTypeIcon,
        readMore,
        getLocalizedCategoryTitle,
        getArticleTimeToRead,
        canSeeAdminActions,
        showConsultationUsers
    };
};

export default useBlog;
