const PHOTOS_EXTENSIONS = ['.jpg', '.jpeg', '.webp', '.gif', '.png'];

export default function isPhotoLink(url) {
    if (!url) {
        return false;
    }

    url = url.toLowerCase();

    return PHOTOS_EXTENSIONS.some((ext) => {
        return url.indexOf(ext) !== -1;
    });
}
