import React, { useState } from 'react';
import { useShareDocument as useShareDocumentContext } from '../providers/SharedDocumentProvider';
import useSharedDocumentRight from '../hooks/useSharedDocumentRight';
import { isEmpty, isNil } from 'lodash-es';
import { isAllImagesAttachment, isVideoAttachment } from 'helpers/attachment';
import useAttachment from 'hooks/useAttachment';
import useViewport from 'hooks/useViewport';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Resources from './SharedDocumentArticle/components/Resources';

const SharedDocumentArticle = () => {
    const { article } = useShareDocumentContext();
    const { canSeeAdminActionsForArticle } = useSharedDocumentRight();
    const { forgeAttachmentUrl, getVideoFiles, getVideoState, getVideoType } = useAttachment();
    const viewport = useViewport();

    const [readMore, setReadMore] = useState(false);

    const hasContent = () => {
        return article && !isNil(article.content) && !isEmpty(article.content);
    };

    const isShortArticle = () => {
        if (!article || isNil(article.content) || isEmpty(article.content)) {
            return true;
        }

        return article.content.length < 500;
    };

    const getArticleStyle = () => {
        if (isShortArticle()) {
            return;
        }

        if (readMore || viewport.gtSm) {
            return {
                maxHeight: 'auto'
            };
        } else {
            return {
                maxHeight: '275px'
            };
        }
    };

    const isResponsive = () => {
        return viewport.xs || viewport.sm;
    };

    if (!article) return;

    return (
        <div className="content">
            <div className={'shared-documents-article-wrapper-main'}>
                {/*<share-document-article-panel
                    ng-if="SharedDocuments.canSeeAdminActionsForArticle(article)"></share-document-article-panel>
                <share-document-resource-panel></share-document-resource-panel>
                <share-document-import-resources-panel></share-document-import-resources-panel>
                <video-view></video-view>
                <secure-document></secure-document>*/}

                <div className="shared-documents-article">
                    <div className="overview">
                        <div className="title">
                            <a
                                className="icon"
                                ui-sref="auth.sharedDocuments.categories({ categoryId: article.category.id })"
                                ui-sref-opts="{ reload: true, inherit: false }"
                            >
                                <i className="icon-arrow-left" aria-hidden="false"></i>
                            </a>

                            <div className="text">{article.title}</div>

                            {canSeeAdminActionsForArticle(article) && (
                                <div className="actions">
                                    <button
                                        type="button"
                                        className="edit-article"
                                        ng-click="editArticle(true)"
                                    >
                                        <i className="icon-pencil-1" aria-hidden="true"></i>
                                    </button>

                                    <div className="action" ng-click="showActionButtons()">
                                        <i
                                            className="icon-navigation-menu-horizontal"
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="shared-documents-article-wrapper">
                        <div className="article-wrapper">
                            {(article.media || hasContent()) && (
                                <div className="article">
                                    {article.media && (
                                        <div className="media">
                                            {isAllImagesAttachment([article.media]) && (
                                                <img
                                                    src={forgeAttachmentUrl(article.media, true)}
                                                />
                                            )}

                                            {isVideoAttachment(article.media) && (
                                                <VideoPlayer
                                                    src={forgeAttachmentUrl(article.media)}
                                                    type={getVideoType(article.media.type)}
                                                    poster={article.media}
                                                    files={getVideoFiles(article.attachment)}
                                                    videoState={getVideoState(article.attachment)}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {hasContent() && (
                                        <div className="content">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: article.content
                                                }}
                                                style={getArticleStyle()}
                                            ></div>
                                        </div>
                                    )}

                                    {!isShortArticle() && isResponsive() && (
                                        <div className="actions">
                                            <div
                                                className="action is-clikable"
                                                onChange={() => setReadMore(!readMore)}
                                            >
                                                {readMore && (
                                                    <div className="label">
                                                        Replier{' '}
                                                        <i
                                                            className="icon-arrow-up-1-arrows-diagrams"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}
                                                {!readMore && (
                                                    <div className="label">
                                                        Lire la suite{' '}
                                                        <i
                                                            className="icon-arrow-down-1-arrows-diagrams"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {(isShortArticle() || isResponsive()) && (
                                <div className="resources-wrapper as-row">
                                    <Resources article={article} />
                                </div>
                            )}
                        </div>
                        {!isShortArticle() && !isResponsive() && (
                            <div className="resources-wrapper as-column">
                                <Resources article={article} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SharedDocumentArticle;
