import useUtils from '../../../hooks/useUtils';
import useRight from '../../../hooks/useRight';
import useWorkspace from '../../../hooks/useWorkspace';
import { useComposer } from '../../../pages/Auth/providers/ComposerContext';
import Editor from '../../Editor/Editor';

const Form = () => {
    const { workspace } = useComposer();
    const { getFirstLetter } = useUtils();
    const { hasRole } = useRight();
    const { forgeIconUrl } = useWorkspace();

    if (!workspace) {
        return <></>;
    }

    return (
        <div className="step form">
            <div className="header">
                <div className="workspace">
                    {hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                        <div className="icon" style={{ backgroundColor: workspace.icon_color }}>
                            <div className="letter">{getFirstLetter(workspace.name)}</div>
                        </div>
                    ) : (
                        <div className="icon">
                            <img src={forgeIconUrl(workspace.icon_name)} />
                        </div>
                    )}

                    <div className="name">{workspace.name}</div>

                    {workspace.writer_description && (
                        <div
                            className="writer-description"
                            dangerouslySetInnerHTML={{
                                __html: workspace.writer_description
                            }}></div>
                    )}
                </div>
            </div>

            <Editor workspace={workspace}></Editor>
        </div>
    );
};

export default Form;
