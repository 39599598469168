import React, { useEffect, useRef, useState } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import Widgets from '../Widgets';
import { isNil } from 'lodash-es';
import WidgetsService from '../services/Widget';

const SectionStart = ({ widget, children, options }) => {
    const [maxHeight, setMaxHeight] = useState(null);
    const ref = useRef();
    const { getStyleValueForKey, setSavedFoldedState, isFolded } = useWidgetComponent(
        widget,
        options
    );
    const widgetService = new WidgetsService();

    useEffect(() => {
        if (!isFolded) {
            setMaxHeight(ref?.current?.clientHeight);
        } else {
            setMaxHeight(getStyleValueForKey('maxHeight'));
        }
    }, [ref, isFolded]);

    const isSlider = () => {
        return getStyleValueForKey('layoutMode') === 'slider';
    };

    const isAccordion = () => {
        return getStyleValueForKey('layoutMode') === 'accordion';
    };

    const getBackgroundGradient = () => {
        let bgColor = '#EFEDEF';

        const localBgColor = getStyleValueForKey('bgColor');
        const globalBgColor = widgetService.getWidgetStyleValueForKey(options, 'bgColor');

        if (localBgColor && localBgColor !== 'transparent') {
            bgColor = localBgColor;
        } else {
            if (globalBgColor && globalBgColor !== 'transparent') {
                bgColor = globalBgColor;
            }
        }

        return {
            backgroundImage: `linear-gradient(180deg, transparent, ${bgColor})`
        };
    };

    const getChildrenStyle = () => {
        if (isNil(maxHeight)) {
            return;
        }

        return {
            maxHeight: `${Math.ceil(maxHeight)}px`
        };
    };

    const getPaginationStyle = () => {
        if (!getStyleValueForKey('paginationColor')) return;

        return { '--swiper-pagination-color': getStyleValueForKey('paginationColor') };
    };

    const getSections = () => {
        return [];
    };

    return (
        <>
            {isAccordion() && (
                <div className={`section-start is-accordion ${isFolded ? 'folded' : null}`}>
                    <div className="children" style={getChildrenStyle()}>
                        <Widgets
                            isChild={true}
                            widgetsLoaded={children}
                            optionsLoaded={options}
                            innerRef={ref}
                        />
                    </div>

                    <div className="actions">
                        {isFolded && (
                            <div className="overlay" style={getBackgroundGradient()}></div>
                        )}

                        <div
                            className="toggle"
                            onClick={() => {
                                setSavedFoldedState(!isFolded);
                            }}>
                            <i className={isFolded ? 'icon-add' : 'icon-subtract'}></i>
                        </div>
                    </div>
                </div>
            )}

            {isSlider() && (
                <div className="section-start is-slider">
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {getSections().map((children, index) => {
                                return (
                                    <div className="swiper-slide" key={`children_${index}`}>
                                        <div
                                            style={{
                                                ...getChildrenStyle(widget),
                                                overflow: 'hidden'
                                            }}>
                                            <Widgets
                                                isChild={true}
                                                childrenWidgets={children}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            className="swiper-button-next"
                            style={{ color: getStyleValueForKey('paginationColor') }}></div>

                        <div
                            className="swiper-button-prev"
                            style={{ color: getStyleValueForKey('paginationColor') }}></div>

                        <div className="swiper-pagination" style={getPaginationStyle()}></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SectionStart;
