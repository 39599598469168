import { useHttp } from 'api/useHttp';

const useOrganizationalUnitService = () => {
    const axios = useHttp();

    const getAll = () => {
        return axios.get(`/organizational/units`).then((response) => response.data);
    };

    const getAllWithStaff = () => {
        return axios.get(`/organizational/units/with/staff`).then((response) => response.data);
    };

    const getOnlyEntities = () => {
        return axios.get(`/organizational/units?onlyEntities=1`).then((response) => response.data);
    };

    const getOnlyTargetable = () => {
        return axios
            .get(`/organizational/units?onlyTargetable=1`)
            .then((response) => response.data);
    };

    const getOrganizationalUnitsByUser = (userId) => {
        let params = { params: { userId } };

        return axios.get(`/organizational/units/by/user`, params).then((response) => response.data);
    };

    const postOrganizationalUnit = (organizationalUnit) => {
        return axios
            .post(`/organizationals/units`, { organizationalUnit })
            .then((response) => response.data);
    };

    const deleteOrganizationalUnit = (organizationalUnit) => {
        return axios
            .delete(`/organizational/unit/${organizationalUnit.id}`)
            .then((response) => response.data);
    };

    const countTotalCrossoverUsers = (workspaces, levels) => {
        return axios
            .get(`/total/users/crossover/count`, {
                params: {
                    workspaces,
                    levels
                }
            })
            .then((response) => response.data);
    };

    return {
        getAll,
        getAllWithStaff,
        getOnlyEntities,
        getOnlyTargetable,
        getOrganizationalUnitsByUser,
        postOrganizationalUnit,
        deleteOrganizationalUnit,
        countTotalCrossoverUsers
    };
};

export default useOrganizationalUnitService;
