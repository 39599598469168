import Modal from 'components/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Input, Checkbox, Select } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';
import { SEARCH_FIELDS, LETTERS_WITH_CUSTOMS, FIELDS } from '../shareds';
import { Button } from 'antd';
import { range } from 'lodash-es';

const ImportEmployeesFormModalContent = () => {
    const [levels, setLevels] = useState([]);
    const [customLevels, setCustomLevels] = useState([]);
    const {
        contexts: {
            import_employees: { currentImportEmployeeConfig }
        },
        closeModal
    } = useModal();
    const methods = useForm({
        defaultValues: currentImportEmployeeConfig
    });

    useEffect(() => {
        if (currentImportEmployeeConfig?.id) {
            setLevels(
                currentImportEmployeeConfig?.configs['levels']
                    ? currentImportEmployeeConfig.configs['levels']
                    : []
            );
            setCustomLevels(
                currentImportEmployeeConfig?.configs['additional_levels']
                    ? currentImportEmployeeConfig.configs['additional_levels']
                    : []
            );
        }
    }, [currentImportEmployeeConfig]);

    const onSubmit = (values) => {
        console.log('values', values);
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input name={'name'} label={"Nom de l'import"} />
                    <Input
                        name={'type'}
                        label={'Type de population (laisser vide pour la population de base)'}
                    />
                    <Input
                        name={'connexion_type'}
                        label={'Type de connexion (email_only, matricule, birthdate)'}
                    />
                    <Input
                        name={'label_connexion_button'}
                        label={'Libellé du bouton de connexion'}
                    />
                    <Select
                        name={'configs[search_field]'}
                        options={SEARCH_FIELDS}
                        label={'Clé de recherche'}
                    />
                    {FIELDS.map((field) => {
                        switch (field.type) {
                            case 'select':
                                return (
                                    <Select
                                        name={`configs[${field.name}]`}
                                        label={field.label}
                                        value={
                                            currentImportEmployeeConfig
                                                ? currentImportEmployeeConfig.configs[field.name]
                                                    ? parseInt(
                                                          currentImportEmployeeConfig.configs[
                                                              field.name
                                                          ]
                                                      )
                                                    : null
                                                : null
                                        }
                                        options={LETTERS_WITH_CUSTOMS.map((letter, index) => ({
                                            value: index,
                                            label: letter
                                        }))}
                                    />
                                );
                            case 'boolean':
                                return (
                                    <Checkbox name={`configs[${field.name}]`} label={field.label} />
                                );
                            case 'input':
                                return (
                                    <Input name={`configs[${field.name}]`} label={field.label} />
                                );
                        }
                    })}
                    <div className={'row'}>
                        <div className={'column full'}>
                            <strong>Levels</strong>
                        </div>
                        <div className={'column'}>
                            <Button
                                onClick={() => {
                                    setLevels((prevState) => [
                                        ...prevState,
                                        { name: '', index: '' }
                                    ]);
                                }}>
                                Ajouter un level
                            </Button>
                        </div>
                    </div>
                    {levels.map((level, index) => {
                        return (
                            <div className={'row'} key={`level_${index}`}>
                                <div className={'column full'}>
                                    <Select
                                        name={`configs[levels][${index}].levelIndex`}
                                        value={level.levelIndex ? parseInt(level.levelIndex) : null}
                                        label={'Index du level'}
                                        options={range(40).map((value, index) => {
                                            return {
                                                value: value + 1,
                                                label: value + 1
                                            };
                                        })}
                                    />
                                </div>
                                <div className={'column full'}>
                                    <Select
                                        name={`configs[levels][${index}].column`}
                                        label={'Colonne'}
                                        value={level.column ? parseInt(level.column) : null}
                                        options={LETTERS_WITH_CUSTOMS.map(
                                            (letter, letterIndex) => ({
                                                value: letterIndex,
                                                label: letter
                                            })
                                        )}
                                    />
                                </div>
                                <div className={'column'}>
                                    <label htmlFor="">&nbsp;</label>
                                    <Button
                                        onClick={() => {
                                            setLevels((prev) => [
                                                ...prev.filter((_, i) => i !== index)
                                            ]);
                                        }}>
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    <div className={'row'}>
                        <div className={'column full'}>
                            <strong>Levels custom</strong>
                        </div>
                        <div className={'column'}>
                            <Button
                                onClick={() => {
                                    setCustomLevels((prevState) => [
                                        ...prevState,
                                        { name: '', index: '' }
                                    ]);
                                }}>
                                Ajouter un level custom
                            </Button>
                        </div>
                    </div>
                    {customLevels.map((level, index) => {
                        return (
                            <div className={'row'} key={`custom_level_${index}`}>
                                <div className={'column full'}>
                                    <Input
                                        name={`configs[additional_levels][${index}].name`}
                                        value={level?.name}
                                        label={'Nom du level custom'}
                                    />
                                </div>
                                <div className={'column full'}>
                                    <Input
                                        name={`configs[additional_levels][${index}].index`}
                                        value={level?.index}
                                        label={'Index du level custom'}
                                        type={'number'}
                                    />
                                </div>
                                <div className={'column'}>
                                    <label htmlFor="">&nbsp;</label>
                                    <Button
                                        onClick={() => {
                                            setCustomLevels((prev) => [
                                                ...prev.filter((_, i) => i !== index)
                                            ]);
                                        }}>
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('import_employees');
                }}
            />
        </ModalForm>
    );
};

const ImportEmployeesFormModal = () => {
    return (
        <Modal
            name={'import_employees'}
            title={'Configurer un import salarié'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <ImportEmployeesFormModalContent />
        </Modal>
    );
};

export default ImportEmployeesFormModal;
