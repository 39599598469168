import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useConfig } from '../../providers/ConfigProvider';
import useDate from '../../hooks/useDate';

const RelativeTime = ({ date, isLight }) => {
    const { config } = useConfig();
    const { format: dateFormat } = useDate();

    const [humanReadableDate, setHumanReadableDate] = useState(dateFormat(date, 'human'));
    const [relativeTime, setRelativeTime] = useState(dateFormat(date, isLight ? 'light' : null));

    const intervalRef = useRef(null);

    useEffect(() => {
        if (!config.isMsie && isToday()) {
            intervalRef.current = setInterval(() => {
                setRelativeTime(dateFormat(date, isLight ? 'light' : null));
            }, 60000);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [date, isLight, config.isMsie, dateFormat]);

    const isToday = () => {
        return dayjs(date).isSame(new Date(), 'day');
    };

    return <span title={humanReadableDate}>{relativeTime}</span>;
};

export default RelativeTime;
