// TODO Gérer les levels editAvatar / editProfile
import React from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import useRight from 'hooks/useRight';
import Username from 'components/Username/Username';
import { useConfig } from 'providers/ConfigProvider';

const ProfileSidebarUser = ({ user }) => {
    const { t } = useTranslation();
    const { isMe, getAvatar } = useUser();
    const { hasRole, isInternal, isAllowToUseResetPassword } = useRight();
    const { config } = useConfig();

    const hasUserInformation = () => {
        return user && user.user_informations;
    };

    const getUserInformation = () => {
        let { show: userInformation = '' } = user.user_informations;

        switch (userInformation) {
            case '':
                return;
            case 'company':
                return user.company;
            case 'job':
                return user.job;
            case 'phone':
                return user.phone_number;
            case 'email':
                return user.email;
            default:
                if (
                    userInformation.indexOf('level') < 0 ||
                    !user.employee ||
                    !user.employee.levels
                ) {
                    return;
                }

                let levelIndex = userInformation.split('_').pop();

                let foundLevel = user.employee.levels.find((level) => {
                    return level.index === parseInt(levelIndex);
                });

                if (!foundLevel) {
                    return;
                }

                return foundLevel.name;
        }
    };

    const hasProfileContent = () => {
        return user && user.user_profile_contents && user.user_profile_contents.length > 0;
    };

    return (
        <>
            {isMe(user) && (
                <div className="buttons">
                    <button
                        className="edit"
                        title={t('PROFILE_EDIT_AVATAR')}
                        ng-click="editAvatar()"
                    >
                        <i className="icon-camera-retro" aria-hidden="true"></i>
                    </button>

                    {(!hasRole('HAS_RESTRICTED_PROFILE_EDITING') || !isInternal()) && (
                        <button
                            className="edit"
                            title={t('PROFILE_EDIT_TITLE')}
                            ng-click="editProfile()"
                        >
                            <i className="icon-pencil-1" aria-hidden="true"></i>
                        </button>
                    )}
                </div>
            )}

            <div className="avatar">
                <img src={getAvatar(user)} alt={'Avatar'} />
            </div>

            <div className="details">
                <div className="name">
                    <Username user={user} light={true} />
                </div>

                {hasUserInformation() && <div className="highlight">{getUserInformation()}</div>}
            </div>

            <div className="fields">
                {user.job && config.hide_job !== true && (
                    <div className="field">
                        <div className="label">{t('JOB')} :</div>
                        <div className="value">{user.job}</div>
                    </div>
                )}

                {user.company && config.hide_company !== true && (
                    <div className="field">
                        <div className="label">{t('COMPANY')} :</div>
                        <div className="value">{user.company}</div>
                    </div>
                )}

                {user.city && config.hide_city !== true && (
                    <div className="field">
                        <div className="label">{t('CITY')} :</div>
                        <div className="value">{user.city}</div>
                    </div>
                )}
            </div>

            <div className="fields contacts">
                {user.email && user.show_email_in_directory && !config.hide_email && (
                    <div className="field">
                        <div className="label">{t('EMAIL_SHORT')} :</div>
                        <div className="value">{user.email}</div>
                    </div>
                )}

                {user.phone_number && config.hide_phone !== true && (
                    <div className="field">
                        <div className="label">{t('PHONE_NUMBER')} :</div>
                        <div className="value">{user.phone_number}</div>
                    </div>
                )}

                {user.phone_number_other && config.hide_phone !== true && (
                    <div className="field">
                        <div className="label">{t('PHONE_NUMBER_OTHER')} :</div>
                        <div className="value">{user.phone_number_other}</div>
                    </div>
                )}

                {user.mobile_phone_number && config.hide_phone !== true && (
                    <div className="field">
                        <div className="label">{t('MOBILE_PHONE_NUMBER')} :</div>
                        <div className="value">{user.mobile_phone_number}</div>
                    </div>
                )}
            </div>

            {/*<div className="fields levels" ng-if="hasLevels()">
                <div className="field"
                     ng-repeat="level in getLevels() track by level.id"
                     ng-if="isLevelVisible(level)">
                    <div className="label">{getLevelConfigNameForLevel(level)} :</div>
                    <div className="value">{level.name}</div>
                </div>
            </div>*/}

            {hasProfileContent() && (
                <div className="fields custom-fields">
                    {user.user_profile_contents.map((value, index) => {
                        if (!(value && value.value !== '')) return;
                        return (
                            <div className="field" key={`value_key_${index}`}>
                                <div className="label">{value.profile_content.field_name} :</div>
                                <div className="value">{value.value}</div>
                            </div>
                        );
                    })}
                </div>
            )}

            {isMe(user) && isAllowToUseResetPassword() && (
                <div className="actions">
                    <div className="action">
                        <button
                            className="btn outline primary"
                            ng-click="ChangePassword.createPanel({needOldPswd : true})"
                        >
                            {t('PROFILE_CHANGE_PASSWORD')}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfileSidebarUser;
