import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useConfig } from '../../../../providers/ConfigProvider';
import { useHttp } from 'api/useHttp';
import useRight from '../../../../hooks/useRight';

const Variables = () => {
    const axios = useHttp();
    const { hasRole } = useRight();
    const [serverHealth, setServerHealth] = useState({});
    const [parameters, setParameters] = useState('');
    const [phpKeys, setPhpKeys] = useState([[], []]);
    const [meilisearchHealthKeys, setMeilisearchHealthKeys] = useState([[], []]);
    const hash = ''; //__webpack_hash__.slice(0, 8);
    const { config } = useConfig();

    useEffect(() => {
        const fetchData = async () => {
            const parameters = await axios
                .get('/super-admin/parameters')
                .then((response) => response.data);
            const sortedParameters = Object.keys(parameters)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = parameters[key];
                    return obj;
                }, {});
            setParameters(JSON.stringify(sortedParameters, null, 2));

            const serverHealth = await axios
                .get('/super-admin/server/health')
                .then((response) => response.data);
            setServerHealth((prev) => ({ ...prev, ...serverHealth }));

            const phpKeys = Object.keys(serverHealth.php);
            const half = Math.ceil(phpKeys.length / 2);
            setPhpKeys([phpKeys.slice(0, half), phpKeys.slice(-(phpKeys.length - half))]);

            const meilisearchHealth = await axios
                .get('/super-admin/server/meilisearch/health')
                .then((response) => response.data);
            const indexesKeys = Object.keys(meilisearchHealth.indexes);
            const halfMeili = Math.ceil(indexesKeys.length / 2);
            setMeilisearchHealthKeys([
                indexesKeys.slice(0, halfMeili),
                indexesKeys.slice(-(indexesKeys.length - halfMeili))
            ]);

            const version = await getVersion();
            setServerHealth((prev) => ({ ...prev, frontend: { version: { ...version } } }));
        };

        fetchData();
    }, []);

    const getUrl = (type, resource) => {
        if (!type || !resource || !serverHealth[type] || !serverHealth.backend) {
            return '#';
        }

        const serverUrl = serverHealth.backend.version.server_url;
        const projectPath = serverHealth[type].version.project_path;
        const resourcePath = resource === 'job_id' ? '-/jobs' : '-/commit';
        const resourceData = serverHealth[type].version[resource];

        return `${serverUrl}/${projectPath}/${resourcePath}/${resourceData}`;
    };

    const humanReadableDate = (date) => (date ? dayjs(date).format('DD/MM/YY HH:mm') : '');

    const timezone = (tz) => (tz ? `@ ${tz}` : '');

    const getDiskUsageInPercent = (disk) => `${disk.usage}%`;

    const getVersion = async () => {
        if (!config.origin) return Promise.reject();
        /*const response = await axios.get(`/version.json?t=${new Date().getTime()}`);
        return response.data;*/
    };

    return (
        <div className="admin__variables">
            <div className="title">Variables</div>

            <div className="server-health">
                <div className="subtitle">Versions de l'application</div>
                <div className="flex">
                    <div className="col">
                        <div className="subtitle small">Front-end</div>
                        <div className="items">
                            {serverHealth.frontend && (
                                <>
                                    <div className="item">
                                        <div className="label">Date du build</div>
                                        <div className="value">
                                            {humanReadableDate(
                                                serverHealth.frontend.version.job_started_at
                                            )}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Job ID</div>
                                        <div className="value flex">
                                            <div className="text">
                                                {serverHealth.frontend.version.job_id}
                                            </div>
                                            <div className="action">
                                                <a
                                                    className="btn"
                                                    href={getUrl('frontend', 'job_id')}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Détails{' '}
                                                    <i
                                                        className="fa fa-external-link"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Commit #</div>
                                        <div className="value flex">
                                            <div className="text">
                                                {serverHealth.frontend.version.commit_hash}
                                            </div>
                                            <div className="action">
                                                <a
                                                    className="btn"
                                                    href={getUrl('frontend', 'commit_hash')}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Détails{' '}
                                                    <i
                                                        className="fa fa-external-link"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="item">
                                <div className="label">Build #</div>
                                <div className="value">{hash}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="subtitle small">Back-end</div>
                        <div className="items">
                            {serverHealth.backend && (
                                <>
                                    <div className="item">
                                        <div className="label">Date du build</div>
                                        <div className="value">
                                            {humanReadableDate(
                                                serverHealth.backend.version.job_started_at
                                            )}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Job ID</div>
                                        <div className="value flex">
                                            <div className="text">
                                                {serverHealth.backend.version.job_id}
                                            </div>
                                            <div className="action">
                                                <a
                                                    className="btn"
                                                    href={getUrl('backend', 'job_id')}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Détails{' '}
                                                    <i
                                                        className="fa fa-external-link"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Commit #</div>
                                        <div className="value flex">
                                            <div className="text">
                                                {serverHealth.backend.version.commit_hash}
                                            </div>
                                            <div className="action">
                                                <a
                                                    className="btn"
                                                    href={getUrl('backend', 'commit_hash')}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Détails{' '}
                                                    <i
                                                        className="fa fa-external-link"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {serverHealth && JSON.stringify(serverHealth) && (
                <div className="server-health">
                    <div className="flex">
                        <div className="col">
                            <div className="subtitle">Variables serveur</div>
                            <div className="items">
                                <div className="item">
                                    <div className="label">Env. Symfony</div>
                                    <div className="value">{serverHealth.env}</div>
                                </div>
                                <div className="item">
                                    <div className="label">DateTime PHP</div>
                                    <div className="value">
                                        {humanReadableDate(serverHealth?.date?.php)}{' '}
                                        {timezone(serverHealth?.date?.php_timezone)}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">DateTime DB</div>
                                    <div className="value">
                                        {humanReadableDate(serverHealth?.date?.db)}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="label">Loadavg</div>
                                    <div className="value">{serverHealth.loadavg}</div>
                                </div>
                            </div>
                        </div>

                        {serverHealth.disk && (
                            <div className="col">
                                <div className="subtitle">Espace disque</div>
                                <div className="items">
                                    <div className="item">
                                        <div className="label">Espace total</div>
                                        <div className="value">{serverHealth.disk.total}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Espace utilisé</div>
                                        <div className="value">{serverHealth.disk.used}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Espace disponible</div>
                                        <div className="value">{serverHealth.disk.available}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">Usage</div>
                                        <div className="value">
                                            <div className="usage">
                                                <div
                                                    className="percent"
                                                    style={{
                                                        width: getDiskUsageInPercent(
                                                            serverHealth.disk
                                                        )
                                                    }}
                                                >
                                                    {getDiskUsageInPercent(serverHealth.disk)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="server-health">
                <div className="subtitle">Variables PHP</div>
                <div className="flex">
                    {phpKeys.map((keys, index) => (
                        <div className="col" key={index}>
                            <div className="items">
                                {keys.map((key, index) => (
                                    <div className="item" key={index}>
                                        <div className="label">{key}</div>
                                        <div className="value">{serverHealth.php[key]}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {hasRole('HAS_MEILI_SEARCH') && (
                <div className="server-health">
                    <div className="subtitle">MeiliSearch</div>
                    <div className="flex">
                        {meilisearchHealthKeys.map((keys, index) => (
                            <div className="col" key={index}>
                                <div className="items">
                                    {keys.map((key, index) => (
                                        <div className="item" key={index}>
                                            <div className="label">
                                                {key} :{' '}
                                                {
                                                    serverHealth?.meilisearch?.indexes[key]
                                                        .numberOfDocuments
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="server-health">
                <div className="subtitle">Extensions PHP</div>
                <div className="flex">
                    <div className="col">
                        <div className="items">
                            <div className="item">
                                <div className="value">{serverHealth.extensions?.join(', ')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="parameters">
                <div className="subtitle">Variables de l'application</div>
                <div className="code">{parameters}</div>
            </div>
        </div>
    );
};

export default Variables;
