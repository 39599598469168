import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useChannelService from 'api/useChannelService';
import { useQuery } from '@tanstack/react-query';
import { useCurrentStateAndParams } from '@uirouter/react';
import useViewport from 'hooks/useViewport';
import { useTop } from 'providers/TopProvider';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import Placeholder from '../../../components/Placeholder/Placeholder';
import ChannelMessage from '../../../components/Channel/ChannelMessage';
import { useAuth } from '../../../providers/AuthContext';
import { get } from 'lodash-es';
import ChannelDetails from './partials/ChannelDetails';
import ChannelProvider, { useChannelContext } from '../providers/ChannelProvider';
import Composer from './partials/Composer';

const ChannelContent = () => {
    const { params } = useCurrentStateAndParams();
    const viewport = useViewport();
    const { t } = useTranslation();
    const { getMessages } = useChannelService();
    const { setTitle, addBackButton, addActionButton } = useTop();
    const { openModal } = useModal();
    const { user } = useAuth();
    const { channelInfo } = useChannelContext();

    const [sidebarState, setSidebarState] = useState(false);
    const [lastMessageId, setLastMessageId] = useState(null);
    const [prepared, setPrepared] = useState(false);
    const [lastViewDate, setLastViewDate] = useState(null);
    const [messages, setMessages] = useState([]);

    const { id } = params;

    const { data: dataMessages, isLoading: isLoadingMessage } = useQuery({
        queryKey: [`channel_messages_${id}_${lastMessageId}`, 'channels'],
        queryFn: () => {
            return getMessages(id, lastMessageId);
        }
    });

    useEffect(() => {
        if (dataMessages) {
            if (dataMessages.lastViewDate) {
                setLastViewDate(new Date(dataMessages.lastViewDate));
            }
            setMessages((prevState) => {
                return [].concat(dataMessages.messages, prevState);
            });
        }
    }, [dataMessages]);

    useEffect(() => {
        if (lastViewDate !== null && messages.length > 0 && !prepared) {
            prepareMessages(messages);
        }
    }, [messages, lastViewDate, prepared]);

    useEffect(() => {
        if (channelInfo) {
            setTitle(channelInfo.channel.name);
            addBackButton({ targetState: 'auth.chat.channels' });
            addActionButton({
                icon: 'icon-information',
                callback: () => {
                    openModal('channel_description');
                }
            });
        }
    }, [channelInfo]);

    const isSidebarShown = () => {
        if (viewport.xs || viewport.gtMd) {
            return true;
        }

        return sidebarState;
    };

    const isFromLoggedUser = (message) => {
        return message.user.id === user.id;
    };

    const nextMinuteIsDifferent = (index) => {
        let nextMessageDate = new Date(get(messages, [index + 1, 'created_at'], null));
        let currentMessageDate = new Date(messages[index].created_at);

        return nextMessageDate === null || nextMessageDate - currentMessageDate >= 60000;
    };

    const previousMinuteIsDifferent = (index) => {
        let previousMessageDate = new Date(get(messages, [index - 1, 'created_at'], null));
        let currentMessageDate = new Date(messages[index].created_at);

        return previousMessageDate === null || currentMessageDate - previousMessageDate >= 60000;
    };

    const isStacked = (index, message) => {
        if (previousMinuteIsDifferent(index)) {
            return false;
        }

        return previousMessageIsFromSameUser(index) && !currentDayIsDifferent(index);
    };
    const currentDayIsDifferent = (index) => {
        let previousMessageDate = new Date(get(messages, [index - 1, 'created_at'], null));
        let currentMessageDate = new Date(messages[index].created_at);

        return (
            previousMessageDate === null ||
            previousMessageDate.getDate() !== currentMessageDate.getDate()
        );
    };

    const previousMessageIsFromSameUser = (index) => {
        let previousUser = get(messages, [index - 1, 'user'], null);
        let currentUser = get(messages, [index, 'user'], null);

        if (previousUser === null) {
            return false;
        }

        return previousUser.id === currentUser.id;
    };

    const nextMessageIsFromSameUser = (index) => {
        let nextUser = get(messages, [index + 1, 'user'], null);
        let currentUser = get(messages, [index, 'user'], null);

        if (nextUser === null) {
            return false;
        }

        return nextUser.id === currentUser.id;
    };

    const nextDayIsDifferent = (index) => {
        let nextMessageDate = new Date(get(messages, [index + 1, 'created_at'], null));
        let currentMessageDate = new Date(messages[index].created_at);

        return (
            nextMessageDate === null || nextMessageDate.getDate() !== currentMessageDate.getDate()
        );
    };

    const needToDisplayAvatarAndDate = (index) => {
        if (nextMinuteIsDifferent(index)) {
            return true;
        }

        return !nextMessageIsFromSameUser(index) || nextDayIsDifferent(index);
    };

    const isNew = (index) => {
        let previousMessageDate = new Date(get(messages, [index - 1, 'created_at'], null));
        let currentMessageDate = new Date(messages[index].created_at);

        if (previousMessageDate === null && lastViewDate < currentMessageDate) {
            return true;
        }

        return previousMessageDate <= lastViewDate && lastViewDate < currentMessageDate;
    };

    const prepareMessages = (messages) => {
        const computedMessages = messages;
        setMessages(
            computedMessages.filter((message, index) => {
                message.isFromLoggedUser = isFromLoggedUser(message);
                message.isStacked = isStacked(index, message);
                message.currentDayIsDifferent = currentDayIsDifferent(index);
                message.needToDisplayDate = needToDisplayAvatarAndDate(index);
                message.isNew = isNew(index);

                if (message.isNew) {
                    let previousMessageIndex = index - 1;
                    let previousMessage = get(computedMessages, [previousMessageIndex], null);

                    if (previousMessage) {
                        previousMessage.needToDisplayAvatar =
                            needToDisplayAvatarAndDate(previousMessageIndex);
                        previousMessage.needToDisplayDate = previousMessage.needToDisplayAvatar;
                    }
                }

                return message;
            })
        );
        setPrepared(true);
    };

    return (
        <div className={'channel-wrapper'}>
            {/*<modal className="channel-form" md-name="add_user" md-title="t('CHANNEL_ADD_USERS')"
                   md-options="{ canScroll: false, zIndex: 301 }">
                <form className="form">
                    <div className="group" ng-if="users.length === 2 && newUsers.length > 0">
                        <label htmlFor="name" className="label">{t('CHANNEL_ADD_INPUT_NAME')}</label>
                        <input id="name" type="text" className="input" required ng-model="newName"/>
                    </div>

                    <div className="group">
                        <ui-select ng-model-options="{ debounce: 0 }" id="users" ng-model="newUsers" multiple
                                   theme="select2" style="width: 100%;">
                            <ui-select-match ng-attr-placeholder="{ t('TYPE_TO_SEARCH') }">
                                <div className="workspace">
                                    <div className="icon">
                                        <img ng-src="{ getUserAvatar($item.image) }"/>
                                    </div>
                                    <div className="meta">
                                        <div className="name">
                                            {$item.name}
                                        </div>
                                    </div>
                                </div>
                            </ui-select-match>

                            <ui-select-choices refresh="querySearch($select.search)"
                                               repeat="user in selectedUsers" minimum-input-length="2">
                                <div className="workspace">
                                    <div className="desc">
                                        <div className="icon">
                                            <img ng-src="{ getUserAvatar(user.image) }"/>
                                        </div>
                                        <div className="name">{user.name}</div>
                                    </div>
                                </div>
                            </ui-select-choices>
                        </ui-select>
                        <div className="error"
                             ng-if="(users.length + newUsers.length) >= Config.maxPeopleLimit">
                            <div className="text" t="CHAT_TOO_CROWDED"
                                 t-values='{ max_people: Config.maxPeopleLimit }'></div>
                        </div>
                    </div>

                    <div className="actions">
                        <button className="button small grey"
                                ng-click="Modal.close('add_user')">{t('CHANNEL_ADD_DIALOG_CLOSE')}</button>
                        <button className="button small dark" ng-click="save()"
                                ng-disabled="!isSubmitAvailable()">{t('POST_JOIN_DOC_VALIDATE')}</button>
                    </div>
                </form>
            </modal>*/}

            <div className="channel">
                {(viewport.sm || viewport.md) && (
                    <div className="header">
                        <div className="title">{channelInfo?.channel?.name}</div>

                        {!viewport.gtMd && (
                            <div className="actions">
                                <button
                                    className={`action ${!isSidebarShown() ? 'outline' : null}`}
                                    onClick={() => {
                                        setSidebarState(!sidebarState);
                                    }}
                                >
                                    <i className="icon-information" aria-hidden="true"></i>
                                </button>
                            </div>
                        )}
                    </div>
                )}

                <div className="body">
                    {(!isLoadingMessage || messages.length > 0) && (
                        <div className="feed">
                            <div className="view scrollable">
                                <div className="list">
                                    {messages.map((message, index) => {
                                        return (
                                            <ChannelMessage
                                                message={message}
                                                key={`message_${message.id}_${index}`}
                                            />
                                        );
                                    })}

                                    {/*<div className="item" ng-if="usersTyping.length > 0">
                                        <div className="message theirs">
                                            <div className="avatar typing">
                                                <img
                                                    ng-src="{ getAvatarUrl(getLastUserTyping().image_profile_name) }"/>
                                                <div className="overlay" ng-if="countRemainingUsersTyping() > 0">
                                    <span className="value">
                                        +{countRemainingUsersTyping()}
                                    </span>
                                                </div>
                                            </div>

                                            <div className="content">
                                                <div className="name">
                                    <span className="user">
                                        <username user="getLastUserTyping()" light="true"></username>
                                    </span>
                                                    <span className="others" ng-if="countRemainingUsersTyping() === 1"
                                                          t="CHAT_ONE_MORE_USER_TYPING"
                                                          t-values='{ count: countRemainingUsersTyping() }'></span>
                                                    <span className="others" ng-if="countRemainingUsersTyping() > 1"
                                                          t="CHAT_TWO_OR_MORE_MORE_USER_TYPING"
                                                          t-values='{ count: countRemainingUsersTyping() }'></span>
                                                </div>

                                                <div className="text">
                                                    <div className="dots">
                                                        <div className="dot"></div>
                                                        <div className="dot"></div>
                                                        <div className="dot"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>

                            <Composer />
                        </div>
                    )}

                    {!isLoadingMessage && messages.length === 0 && (
                        <div className="feed">
                            <div className="view scrollable">
                                <div className="list">
                                    <Placeholder number={20} template={'message'} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={`modal description scrollable ${isSidebarShown() ? 'show' : ''} ${viewport.sm || viewport.md ? 'slide-in' : null}`}
                    >
                        <Modal
                            name="channel_description"
                            title={t('CHAT_DETAILS_PANEL_TITLE_LABEL')}
                            options={{ isSidebar: true }}
                        >
                            <ChannelDetails />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Channel = () => {
    return (
        <ChannelProvider>
            <ChannelContent />
        </ChannelProvider>
    );
};

export default Channel;
