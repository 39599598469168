import { Button } from 'antd';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';

const AdminHeader = ({ title, modalName, modalButtonTitle, children }) => {
    const { t } = useTranslation();
    const { openModal } = useModal();
    return (
        <div className={'admin-header'}>
            <div className={'title'}>{t(title)}</div>
            {children && <div>{children}</div>}
            <div>
                {modalName && (
                    <Button onClick={() => openModal(modalName)}>{t(modalButtonTitle)}</Button>
                )}
            </div>
        </div>
    );
};

export default AdminHeader;
