import useSearchBoxService from '../../api/useSearchBoxService';
import { useComposer } from '../../../providers/ComposerContext';

const useSearchBoxResultComponent = ({ query }) => {
    const { normalize, getWorkspaceDataForFolder } = useSearchBoxService();
    const { showComposer } = useComposer();

    const getResultWithQueryHighlight = (text) => {
        const normalizedText = normalize(text);
        const normalizedQuery = normalize(query);

        const highlightIndex = normalizedText.indexOf(normalizedQuery);

        if (highlightIndex !== -1) {
            const start = text.substring(0, highlightIndex);
            const highlight = text.substring(
                highlightIndex,
                highlightIndex + normalizedQuery.length
            );
            const end = text.substring(highlightIndex + normalizedQuery.length);

            return `${start}<span class="hl">${highlight}</span>${end}`;
        }

        return text;
    };

    const goToComposer = (result, searcheable, event) => {
        switch (searcheable) {
            case 'categories':
                showComposer({
                    category: {
                        value: result.id
                    }
                });

                break;
            case 'workspaces':
                showComposer({
                    workspace: {
                        category: {
                            id: result.workspace_data.workspace.category.id
                        },
                        value: result.workspace_data.workspace.id
                    }
                });

                break;
            case 'folders':
                let workspace = getWorkspaceDataForFolder(result).workspace_data.workspace;

                showComposer({
                    workspace: {
                        category: {
                            id: workspace.category.id
                        },
                        value: workspace.id
                    }
                });

                break;
        }

        event.preventDefault();
        event?.stopPropagation();
    };

    return {
        getResultWithQueryHighlight,
        goToComposer
    };
};

export default useSearchBoxResultComponent;
