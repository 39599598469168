import { DatePicker } from 'components/Form/Inputs';

const Date = ({ field }) => {
    return (
        <>
            <label className="custom_text" htmlFor={field.id}>
                {field.label} {field.require && <span className="required">*</span>}
            </label>

            <DatePicker
                name={`form.fields[${field.id}].value`}
                id={field.id}
                placeholder={field.label}
            />

            {field.explanation && <div className="explanation">{field.explanation}</div>}
        </>
    );
};

export default Date;
