import React, { useMemo } from 'react';
import { useDevice } from '../../../../providers/DeviceContext';
import { useConfig } from '../../../../providers/ConfigProvider';
import { useAuth } from '../../../../providers/AuthContext';
import { useTranslation } from 'react-i18next';
import useRight from '../../../../hooks/useRight';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useComposer } from '../../../Auth/providers/ComposerContext';
import { useQuery } from '@tanstack/react-query';
import { useFeedContext } from '../../providers/FeedProvider';
import usePostService from '../../../../api/usePostService';

const SchedulePosts = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { hasScheduledPosts } = usePostService();
    const { data } = useQuery({
        queryKey: ['schedule_posts_feed'],
        queryFn: () => {
            return hasScheduledPosts();
        }
    });

    if (data) {
        return (
            <a
                className="scheduled-posts"
                href={router.stateService.href('auth.feed.posts.scheduled')}>
                <button className="text">
                    <span
                        className="t"
                        dangerouslySetInnerHTML={{ __html: t('SCHEDULED_POSTS') }}></span>
                    <span className="arrow">⟶</span>
                </button>
            </a>
        );
    }
};

const Actions = () => {
    const { showComposer } = useComposer();
    const router = useRouter();
    const { workspace } = useFeedContext();

    const { params } = useCurrentStateAndParams();
    const isReview = useMemo(() => {
        return router.stateService.includes('auth.feed.posts.review');
    }, [router]);
    const isScheduled = useMemo(() => {
        return router.stateService.includes('auth.feed.posts.scheduled');
    }, [router]);
    const isHome = useMemo(() => {
        return (
            undefined === params.category && !isReview && !isScheduled && undefined === params.tag
        );
    }, [params, router]);

    const getPostPlaceholder = () => {
        if (workspace && workspace.custom_post_action_label) {
            return workspace.custom_post_action_label;
        }

        return t('POST_PLACEHOLDER');
    };

    const { device } = useDevice();
    const { config } = useConfig();
    const { user } = useAuth();
    const { workspacePermissionFormat, hasWriteAccess, hasRole } = useRight();
    const { t } = useTranslation();

    const hasWriteAccessComputed = () => {
        return (
            (!workspace && hasWriteAccess()) ||
            (workspace && hasRole(workspacePermissionFormat(workspace, 'WRITE')))
        );
    };

    return (
        <>
            {hasWriteAccessComputed() && !isReview && !isScheduled && (
                <div className={`mobile-add-wrapper ${workspace ? 'is-workspace' : ''}`}>
                    <button
                        className="mobile-add-button"
                        onClick={() => {
                            showComposer({ workspace });
                        }}>
                        {getPostPlaceholder()}
                    </button>
                </div>
            )}

            {isHome && hasRole('HAS_FEATURE_MANAGE_FUTURE_POSTS') && <SchedulePosts />}

            {config.isCordova && device.intAppVersion < user.latest_version && (
                <div
                    className="update-app"
                    onClick={() => {
                        //goToAppUrl()
                    }}>
                    <div className="title">{t('POSTS_AVAILABLE_UPDATE')}!</div>
                    <div className="text">{t('POSTS_AVAILABLE_UPDATE_TEXT')}.</div>
                    <button className="white-border small button rounded">{t('DO_UPDATE')}</button>
                </div>
            )}
        </>
    );
};

export default Actions;
