import React from 'react';
import Username from '../../Username/Username';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import Excerpt from './Excerpt/Excerpt';
import { isVideoAttachment } from 'helpers/attachment';
import useAttachment from 'hooks/useAttachment';
import { usePost } from '../providers/PostProvider';
import useWorkspace from 'hooks/useWorkspace';
import { useTranslation } from 'react-i18next';
import useDate from 'hooks/useDate';
import { useRouter } from '@uirouter/react';
import useTree from 'hooks/useTree';
import useUtils from 'hooks/useUtils';
import useRight from 'hooks/useRight';
import RealtimeDate from '../../RealTimeDate/RealTimeDate';

const StackedPost = () => {
    const {
        post,
        setPost,
        setConsultationConfirmed,
        isPostOutdated,
        getAttachmentUrl,
        goToUserProfile,
        getPostLink,
        goToPostFromDate,
        showPostStatus,
        goToPostStatusPage,
        hasTags,
        getTags,
        goToTagPage,
        canTag,
        addPostTags,
        showActions,
        hasReactions,
        getReactionsCount,
        hasFeedback,
        feedbackIsDone,
        relevantTag
    } = usePost();

    const router = useRouter();
    const { getVideoFiles, getVideoState, forgeAttachmentUrl, getVideoType } = useAttachment();
    const { forgeIconUrl } = useWorkspace();
    const { hasRole } = useRight();
    const { t } = useTranslation();
    const { getFirstLetter } = useUtils();
    const Tree = useTree();
    const { format: dateFormat } = useDate();

    const getWorkspaceLink = () => {
        return Tree.getWorkspaceLink({
            workspace: post.workspace
        });
    };

    const videoPlayerPlayCallback = () => {
        setPost((prev) => {
            return {
                ...prev,
                isCoverFullWidth: true
            };
        });
    };

    const displayFooter = () => {
        return hasRole('HAS_LIKES') && !router.stateService.includes('auth.slots');
    };

    return (
        <>
            {post.stacked_update_reason && (
                <div className="header">{post.stacked_update_reason}</div>
            )}

            <div className={`wrapper ${!post.isCoverFullWidth ? 'inline' : ''}`}>
                {post.cover && (
                    <div className="cover" onClick={setConsultationConfirmed}>
                        {isVideoAttachment(post.cover) ? (
                            <div className="video">
                                <VideoPlayer
                                    src={getAttachmentUrl(post.cover)}
                                    type={getVideoType(post.cover.type)}
                                    poster={post.cover}
                                    playCallback={videoPlayerPlayCallback}
                                    files={getVideoFiles(post.cover)}
                                    state={getVideoState(post.cover)}
                                    readOnly={post.cover.read_only}
                                    securityLevel={post.security_level}
                                />
                            </div>
                        ) : (
                            <div className="image">
                                <img src={forgeAttachmentUrl(post.cover)} alt="cover" />
                            </div>
                        )}
                    </div>
                )}

                <div className="content">
                    <div className="details">
                        <div className="description">
                            <div className="workspace-and-author">
                                <div className="workspace">
                                    <a
                                        className="icon cursor pointer"
                                        href={getWorkspaceLink()}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {!hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                                            <img
                                                src={forgeIconUrl(post.workspace.icon_name)}
                                                alt="Workspace Icon"
                                            />
                                        ) : (
                                            <div
                                                className="letter"
                                                style={{
                                                    backgroundColor: post.workspace.icon_color
                                                }}>
                                                {getFirstLetter(post.workspace.name)}
                                            </div>
                                        )}
                                    </a>
                                    <a
                                        className="name"
                                        href={getWorkspaceLink()}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {post.workspace.name}
                                    </a>
                                </div>

                                <div className="author" onClick={() => goToUserProfile(post.user)}>
                                    <Username user={post.user} light={true} />
                                </div>

                                <a
                                    className="date"
                                    href={getPostLink()}
                                    onClick={goToPostFromDate}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="not-scheduled">
                                        <RealtimeDate date={post.edited_at} />
                                    </div>
                                </a>

                                {showPostStatus() && (
                                    <div
                                        className="status"
                                        onClick={() =>
                                            goToPostStatusPage(post.workspace, post.post_status)
                                        }>
                                        {t('POST_STATUS_LABEL')} : {post.post_status.label}
                                    </div>
                                )}
                            </div>

                            {hasTags() && relevantTag && (
                                <div className="tags">
                                    <div
                                        className="tag"
                                        style={{
                                            backgroundColor: relevantTag.color,
                                            borderColor: relevantTag.color
                                        }}
                                        onClick={() => goToTagPage(relevantTag)}
                                        title={relevantTag.name}>
                                        {relevantTag.name}
                                    </div>

                                    {getTags().map(
                                        (tag, index) =>
                                            tag.id !== relevantTag.id && (
                                                <div
                                                    key={index}
                                                    className="tag xs"
                                                    style={{
                                                        backgroundColor: tag.color,
                                                        borderColor: tag.color
                                                    }}
                                                    title={tag.name}
                                                    onClick={() => goToTagPage(tag)}>
                                                    {getFirstLetter(tag.name)}
                                                </div>
                                            )
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="tools">
                            {canTag() && (
                                <div className="add-post-tags" onClick={addPostTags}>
                                    <div className="shield">+</div>
                                </div>
                            )}
                            <div className="actions" onClick={showActions}>
                                <i
                                    className="icon-navigation-menu-horizontal"
                                    aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <Excerpt post={post} showReadMore={true} onClick={setConsultationConfirmed} />

                    {isPostOutdated() && (
                        <div
                            className="stacked-outdated excerpt"
                            onClick={setConsultationConfirmed}>
                            <div className="status is-expired">
                                {t('POST_OUTDATED_DATE_LABEL', {
                                    date: dateFormat(post.outdated_at, 'simple')
                                })}
                            </div>
                            <div className="html">{t('POST_OUTDATED_TEXT')}</div>
                            <div className="read-more">
                                <div className="text">{t('POST_SHOW')}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {displayFooter() && (
                <div className="footer">
                    <div className="likes" onClick={setConsultationConfirmed}>
                        <div className="icon">
                            <i className="icon-like-1" aria-hidden="true"></i>
                        </div>
                        <div className="value">
                            {hasReactions()
                                ? getReactionsCount() === 0
                                    ? t('POST_NO_REACTION')
                                    : getReactionsCount() === 1
                                      ? t('POST_REACTION')
                                      : t('POST_REACTIONS', {
                                            reaction_number: getReactionsCount()
                                        })
                                : `${post.likes.length} ${t('POST_LIKE')}`}
                        </div>
                    </div>

                    <div className="comments" onClick={setConsultationConfirmed}>
                        <div className="icon">
                            <i className="icon-messaging-imessage" aria-hidden="true"></i>
                        </div>
                        <div className="value">
                            {post.comments.length === 0
                                ? t('POST_NO_COMMENT')
                                : post.comments.length === 1
                                  ? t('POST_COMMENT')
                                  : t('POST_COMMENTS', { comment_number: post.comments.length })}
                        </div>
                    </div>
                </div>
            )}

            {hasFeedback() && !feedbackIsDone(true) && (
                <div className="feedback">
                    <div className="label">{t('POST_STACKED_FEEDBACK_NEEDED_TEXT')}</div>
                    <div className="action" onClick={setConsultationConfirmed}>
                        {t('Faire mon feedback')}
                    </div>
                </div>
            )}
        </>
    );
};

export default StackedPost;
