import { useTranslation } from 'react-i18next';
import AdminHeader from 'components/Admin/components/AdminHeader';
import React from 'react';
import useUtils from 'hooks/useUtils';
import Table from 'components/Table';
import { UIView, useRouter } from '@uirouter/react';
import { Button } from 'antd';
import AdminFormProvider, { useAdminFormContext } from './providers/AdminFormProvider';

const FormsContent = () => {
    const { t } = useTranslation();
    const { getFirstLetter } = useUtils();
    const router = useRouter();
    const { workspaceForms, isLoading } = useAdminFormContext();

    const COLUMNS = [
        {
            id: 'name',
            header: 'Nom du formulaire',
            sortable: true,
            defaultSort: 'asc'
        },
        {
            id: 'workspaces',
            header: 'Data Feed(s) lié(s)',
            cell: ({ cell }) => {
                const workspaces = cell.getValue();
                return (
                    <>
                        <div className="workspace-row">
                            {workspaces?.length > 0 && (
                                <>
                                    <div
                                        className="icon"
                                        style={{ backgroundColor: workspaces[0].icon_color }}>
                                        <div className="letter">
                                            {getFirstLetter(workspaces.name)}
                                        </div>
                                    </div>
                                    <div className={'name'}>
                                        {workspaces[0]?.name}{' '}
                                        {workspaces?.length > 1 && (
                                            <span>
                                                {t('ADMIN_WORKSPACE_FORMS_AND_X_MORE_DATAFEEDS', {
                                                    total: workspaces?.length - 1
                                                })}
                                            </span>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );
            }
        }
    ];
    return (
        <>
            <AdminHeader title={'ADMIN_WORKSPACE_FORMS_TITLE_LIST'}>
                <Button
                    onClick={() => {
                        router.stateService.go('auth.admin.forms.add');
                    }}>
                    <i class="icon-add-circle" aria-hidden="true"></i> Nouveau formulaire
                </Button>
            </AdminHeader>

            <Table
                columns={COLUMNS}
                data={workspaceForms}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Editer',
                        handler: (row) => {
                            router.stateService.go('auth.admin.forms.edit', { formId: row.id });
                        }
                    },
                    {
                        label: 'Supprimer',
                        danger: true,
                        handler: (row) => {
                            console.log(row);
                        }
                    }
                ]}
            />
        </>
    );
};

const Forms = () => {
    return (
        <AdminFormProvider>
            <UIView>
                <FormsContent />
            </UIView>
        </AdminFormProvider>
    );
};

export default Forms;
