import { useHttp } from 'api/useHttp';

const useExportPdfService = () => {
    const axios = useHttp();

    const postExportPDF = (exportPDF) => {
        return axios.post(`/export/pdf`, exportPDF).then((response) => {
            return response.data;
        });
    };

    const getExportPDF = (exportPDF) => {
        return axios.get(`/exports/pdf/${exportPDF.id}`, {}).then((response) => {
            return response.data;
        });
    };

    const deleteExportPDF = (exportPDF) => {
        return axios.delete(`/exports/pdf/${exportPDF.id}`);
    };

    const getAllExportPDF = () => {
        return axios.get(`/exports/pdf`).then((response) => {
            return response.data;
        });
    };

    return {
        postExportPDF,
        getExportPDF,
        deleteExportPDF,
        getAllExportPDF
    };
};

export default useExportPdfService;
