import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import LinkExtension from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Emoji from '@tiptap-pro/extension-emoji';
import Image from '@tiptap/extension-image';
import FileHandler from '@tiptap-pro/extension-file-handler';

import suggestion from './Emoji/Suggestion';

const RichTextContent = ({ value, field, ...props }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Emoji.configure({
                enableEmoticons: true,
                suggestion
            }),
            LinkExtension,
            Placeholder.configure({ placeholder: props?.placeholder }),
            FileHandler.configure({
                allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
                onDrop: (currentEditor, files, pos) => {
                    files.forEach((file) => {
                        const fileReader = new FileReader();

                        fileReader.readAsDataURL(file);
                        fileReader.onload = () => {
                            currentEditor
                                .chain()
                                .insertContentAt(pos, {
                                    type: 'image',
                                    attrs: {
                                        src: fileReader.result
                                    }
                                })
                                .focus()
                                .run();
                        };
                    });
                },
                onPaste: (currentEditor, files, htmlContent) => {
                    files.forEach((file) => {
                        if (htmlContent) {
                            // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
                            // you could extract the pasted file from this url string and upload it to a server for example
                            console.log(htmlContent); // eslint-disable-line no-console
                            return false;
                        }

                        const fileReader = new FileReader();

                        fileReader.readAsDataURL(file);
                        fileReader.onload = () => {
                            currentEditor
                                .chain()
                                .insertContentAt(currentEditor.state.selection.anchor, {
                                    type: 'image',
                                    attrs: {
                                        src: fileReader.result
                                    }
                                })
                                .focus()
                                .run();
                        };
                    });
                }
            })
        ],
        onUpdate({ editor }) {
            field.onChange(editor.getHTML());
        },
        content: value ? value : ''
    });

    return (
        <RichTextEditor editor={editor} style={{ '--mantine-scale': 1.5 }}>
            <RichTextEditor.Content />
            <RichTextEditor.Toolbar>
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup className={'space'} />
            </RichTextEditor.Toolbar>
        </RichTextEditor>
    );
};

function RichText({ name, value = null, ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field, formState }) => {
                return (
                    <div>
                        {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                        <RichTextContent field={field} value={field.value} {...props} />
                        {formState.errors[name] && (
                            <span className="error">{formState.errors[name].message}</span>
                        )}
                    </div>
                );
            }}
        />
    );
}

export default RichText;
