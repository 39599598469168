import React, { useState } from 'react';
import { Select } from 'antd';
import useCommandService from './api/useCommandService';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'providers/AuthContext';
import AdminHeader from 'components/Admin/components/AdminHeader';

const Commands = () => {
    const { getCommands, postCommand } = useCommandService();
    const { user } = useAuth();

    const [command, setCommand] = useState(null);
    const [response, setResponse] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const { data: commands } = useQuery({
        queryFn: () => getCommands(),
        queryKey: ['commands']
    });

    const run = () => {
        setResponse(null);
        setIsBusy(true);

        postCommand({
            command: command,
            user: user.id
        })
            .then((response) => {
                setResponse({
                    command: command,
                    output: response
                });
            })
            .catch((response) => {
                setResponse({
                    command: command,
                    error: response.message
                });
            })
            .finally(() => {
                setCommand(null);
                setIsBusy(false);
            });
    };

    const isError = () => {
        return !!response && !!response.error;
    };

    return (
        <div className="admin__commands">
            <AdminHeader title={'Exécuter une commande'} />

            <div className="commands">
                <div className="command">
                    <Select
                        style={{
                            width: '100%'
                        }}
                        defaultValue={command}
                        options={
                            commands
                                ? commands.map((command) => {
                                      return {
                                          label: command,
                                          value: command
                                      };
                                  })
                                : []
                        }
                        onChange={(value) => {
                            setCommand(value);
                        }}
                    />
                </div>

                <div className="actions">
                    <div className="action">
                        <button className="btn" onClick={run} disabled={!command || isBusy}>
                            Exécuter
                        </button>
                    </div>
                </div>
            </div>

            {response && (
                <div className="response">
                    <div className="subtitle">{!isError() ? 'Résultat' : 'Erreur'}</div>

                    <div className="command code">
                        Sortie de la commande : <i>{response.command}</i>
                    </div>

                    <div className="message code">
                        {!isError() ? response.output : response.error}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Commands;
