import { useTranslation } from 'react-i18next';
import useTableCeilComponent from '../hooks/useTableCeilComponent';

const CommentsCeil = ({ config, row }) => {
    const { t } = useTranslation();
    const { getIntValue } = useTableCeilComponent(config, row);

    return (
        <>
            <div className="text-center">
                {getIntValue() === 0 && <span>{t('POST_NO_COMMENT')}</span>}
                {getIntValue() === 1 && <span>{t('POST_COMMENT')}</span>}
                {getIntValue() > 1 && (
                    <span>{t('POST_COMMENTS', { comment_number: getIntValue() })}</span>
                )}
            </div>
        </>
    );
};

export default CommentsCeil;
