import { Input } from 'components/Form/Inputs';
import {
    ModalForm,
    ModalFormBody,
    ModalFormFooter,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useForm } from 'components/Form/hooks';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import newLevelFormSchema from '../schemas/newLevelForm.schema.json';
import useLevelStatsService from '../api/useLevelsStatsService';
import { useQueryClient } from '@tanstack/react-query';

const LevelNewModalContent = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { closeModal } = useModal();
    const { postLevelStats } = useLevelStatsService();

    const methods = useForm({
        schema: newLevelFormSchema,
        defaultValues: {
            name: '',
            index: 0
        }
    });

    const onFinish = (data) => {
        postLevelStats({
            name: data.name,
            index: data.index
        })
            .then(() => {
                queryClient.invalidateQueries('adminLevelStats');
                methods.reset();
            })
            .catch((error) => {
                console.log('error', error);
            });

        closeModal('add_levels_stats');
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <div>
                        <Input name="name" label={t('LEVEL_NAME')} />
                    </div>

                    <div>
                        <Input type="number" name="index" label={t('LEVEL_INDEX')} min={0} />
                    </div>
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter onCancel={() => closeModal('add_levels_stats')} />
        </ModalForm>
    );
};

const LevelNewModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name="add_levels_stats"
            title={t('ADMIN_LEVEL_ADD_LEVEL')}
            options={{
                hasPaddedContent: true
            }}>
            <LevelNewModalContent />
        </Modal>
    );
};

export default LevelNewModal;
