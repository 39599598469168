import { useCallback, useEffect } from 'react';
import { useEvent } from 'react-use';
import { useRouter } from '@uirouter/react';
import { useConfig } from 'providers/ConfigProvider';

import onClick from './helpers/onClick';
import { useModal } from 'providers/ModalContext';
import { forEach } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import { useTop } from 'providers/TopProvider';
import { useTranslation } from 'react-i18next';

const EventsWrapper = ({ children }) => {
    const router = useRouter();
    const { modals, closeModal } = useModal();
    const { t } = useTranslation();
    const { setTitle, setActionButtons, clearBackButton } = useTop();
    const { config } = useConfig();
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleTransitionStart = () => {
            queryClient.cancelQueries();
        };

        const handleTransitionSuccess = () => {
            setTitle(t(router.globals.current?.title));
        };

        const handleTransitionFinish = () => {
            setActionButtons([]);
            clearBackButton();
            forEach(
                modals.filter((m) => m.isOpen),
                (m) => {
                    closeModal(m.name);
                }
            );
        };

        const deregisterOnStart = router.transitionService.onStart({}, handleTransitionStart);
        const deregisterOnSuccess = router.transitionService.onSuccess({}, handleTransitionSuccess);
        const deregisterOnFinish = router.transitionService.onFinish({}, handleTransitionFinish);

        return () => {
            deregisterOnStart();
            deregisterOnSuccess();
            deregisterOnFinish();
        };
    }, [router, queryClient, modals]);

    const onClickCallback = useCallback(
        (event) => {
            onClick(event, router, config);
        },
        [router, config]
    );

    const escape = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                forEach(
                    modals.filter((m) => m.isOpen),
                    (m) => {
                        closeModal(m.name);
                    }
                );
            }
        },
        [modals]
    );

    useEvent('keyup', escape);
    useEvent('click', onClickCallback);

    return children;
};

export default EventsWrapper;
