import { useHttp } from 'api/useHttp';

const useTreeService = () => {
    const axios = useHttp();

    const getTree = () => {
        return axios.get('/tree').then(({ data }) => {
            return data;
        });
    };

    const getFullTree = () => {
        return axios.get('/full/tree').then(({ data }) => {
            return data;
        });
    };

    return {
        getTree,
        getFullTree
    };
};

export default useTreeService;
