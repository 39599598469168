import React from 'react';
import useContent from 'hooks/useContent';

const Title = ({ input }) => {
    const { formatContent } = useContent();

    return (
        <div className="render_label title">
            <div
                dangerouslySetInnerHTML={{
                    __html: formatContent(input.workspace_form_input.label)
                }}
            ></div>
        </div>
    );
};

export default Title;
