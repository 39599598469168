import React from 'react';
import { useModal } from 'providers/ModalContext';
import ExportPdfModal from './modals/ExportPdfModal';
import useExportPdfService from './api/useExportPdfService';
import { useQuery } from '@tanstack/react-query';
import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';

const ExportPdf = () => {
    const { openModal } = useModal();
    const { getAllExportPDF } = useExportPdfService();

    const { data: exportsPDF, isLoading } = useQuery({
        queryKey: ['allExportPDF'],
        queryFn: () => getAllExportPDF()
    });

    const COLUMNS = [
        {
            id: 'id',
            header: "Configuration de l'export",
            cell: ({ row }) => {
                const exportPDF = row.original;
                return (
                    <>
                        Les DF {exportPDF.workspaces_target.join(', ')} seront posté dans{' '}
                        {exportPDF.workspace.name} par {exportPDF.user.first_name}{' '}
                        {exportPDF.user.last_name}
                    </>
                );
            }
        }
    ];

    return (
        <>
            <ExportPdfModal />
            <AdminHeader
                title={'EXPORTS_PDF'}
                modalName={'add_export-pdf'}
                modalButtonTitle={'ADD_EXPORT_PDF'}
            />

            <Table
                columns={COLUMNS}
                data={exportsPDF}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Editer',
                        handler: (exportPDF) => {
                            openModal('add_export-pdf', {
                                currentExportPDF: exportPDF
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        danger: true,
                        handler: (row) => {
                            console.log(row);
                        }
                    }
                ]}
            />
        </>
    );
};

export default ExportPdf;
