const { useHttp } = require('api/useHttp');

const useAppSettingService = () => {
    const axios = useHttp();

    const getTextConfig = (configName) => {
        return axios.get(`/text/config/${configName}`).then((response) => response.data);
    };

    const saveTextConfig = (config) => {
        return axios.post('/text/configs', { config }).then((response) => response.data);
    };

    return {
        getTextConfig,
        saveTextConfig
    };
};

export default useAppSettingService;
