import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAttachment from '../../../hooks/useAttachment';
import useCampaignComponent from './hooks/useCampaignComponent';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Link from 'components/Link/Link';
import useEmoji from '../../../hooks/useEmoji';

const Survey = ({ campaign }) => {
    const { t } = useTranslation();
    const { emoji } = useEmoji();
    const { forgeAttachmentUrl, getVideoState, getVideoFiles, getVideoType } = useAttachment();
    const {
        canSeeStats,
        showActions,
        toggleRules,
        showCarousel,
        displayReward,
        openModalCampaignForm,
        isCampaignLive
    } = useCampaignComponent(campaign);

    const [isReadMore, setIsReadMore] = useState(false);
    const textContainerRef = useRef(null);
    const textRef = useRef(null);

    const readMoreText = isReadMore
        ? `${t('POST_DIALOG_CLOSE')} <i class="fa fa-caret-up"></i>`
        : `${t('TA_POST_READ_MORE')} <i class="fa fa-caret-down"></i>`;

    const getTextOffsetHeight = useCallback(() => {
        return textRef.current ? textRef.current.offsetHeight : 0;
    }, []);

    const hasReadMore = useCallback(() => {
        const textOffsetHeight = getTextOffsetHeight();
        return textOffsetHeight > 105;
    }, [getTextOffsetHeight]);

    const toggleReadMore = () => {
        setIsReadMore((prev) => !prev);
    };

    const containerStyle = {
        height: isReadMore ? `${getTextOffsetHeight()}px` : '105px'
    };

    useEffect(() => {
        if (textContainerRef.current && !isReadMore) {
            const textOffsetHeight = getTextOffsetHeight();
            textContainerRef.current.style.height =
                textOffsetHeight > 105 ? '105px' : `${textOffsetHeight}px`;
        }
    }, [isReadMore, getTextOffsetHeight]);

    const showLiveFeedback = () => {
        /*this.LiveFeedback.createPanel({
            campaign: this.campaign,
        });*/
    };

    const editMyAnswers = () => {
        /*let submit = this.campaign.submits.find((submit) => {
            return submit.user.id === this.User.getUserObject().id;
        });

        this.openModalCampaignForm(this.translate('TA_POST_SURVEY_MY_ANSWERS_PANEL'), this.campaign.title, false, false, submit.answers)*/
    };

    return (
        <>
            <div className="header">
                <div className="icon">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                </div>
                <div className="title">{t('TA_SURVEY_TITLE')}</div>
                <div className="post__header__menu">
                    <button onClick={showActions}>
                        <i className="icon-navigation-menu-horizontal"></i>
                    </button>
                </div>
            </div>

            <div className="campaign">
                <div className="description">
                    <div className="title">{campaign.title}</div>
                    <div className="text-container" style={containerStyle} ref={textContainerRef}>
                        <div
                            className="text ng-quill-text"
                            ref={textRef}
                            dangerouslySetInnerHTML={{ __html: emoji(campaign.text) }}
                        ></div>
                        {hasReadMore() && (
                            <div className={`gradient ${isReadMore ? 'out' : ''}`}></div>
                        )}
                    </div>
                    {hasReadMore() && (
                        <div className="text-center">
                            <button
                                className="button dark-grey"
                                onClick={toggleReadMore}
                                dangerouslySetInnerHTML={{ __html: readMoreText }}
                            ></button>
                        </div>
                    )}
                </div>

                <div className="image">
                    {campaign.documents?.length > 0 &&
                        campaign.documents.every((doc) => doc.type.includes('image')) && (
                            <div className="pictures">
                                <div
                                    className="picture"
                                    onClick={() => showCarousel(campaign.documents, 0)}
                                >
                                    <img
                                        src={forgeAttachmentUrl(campaign.documents[0])}
                                        alt="campaign"
                                    />
                                </div>
                                {campaign.documents.length > 1 && (
                                    <div className="show-carrousel">{t('SHOW_CARROUSEL')}</div>
                                )}
                            </div>
                        )}
                    {campaign.link && (
                        <div className="item">
                            <Link item={campaign.link} className="item" campaign={true} />
                        </div>
                    )}
                </div>

                {campaign.documents?.length > 0 && campaign.documents[0].type.includes('video') && (
                    <div className="video-player-container">
                        <VideoPlayer
                            src={forgeAttachmentUrl(campaign.documents[0])}
                            files={getVideoFiles(campaign.documents[0])}
                            state={getVideoState(campaign.documents[0])}
                            type={getVideoType(campaign.documents[0].type)}
                            poster={campaign.documents[0]}
                        />
                    </div>
                )}

                <div className="actions" style={{ display: !campaign.is_event ? 'flex' : 'none' }}>
                    {campaign.reward > 0 && !campaign.user_already_answered && (
                        <button className="button survey big-button" onClick={toggleRules}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('TA_POST_PTS_TO_EARN', { points: campaign.reward })
                                }}
                            ></span>
                        </button>
                    )}
                    {campaign.reward > 0 && campaign.user_already_answered && (
                        <button
                            className="button survey big-button user-already-earned"
                            onClick={displayReward}
                        >
                            {campaign.reward} {t('TA_POST_POINTS_EARNED')}
                        </button>
                    )}
                    {!campaign.user_already_answered &&
                        campaign.status === 2 &&
                        campaign.canParticipate && (
                            <button
                                className="button survey big-button bg-white"
                                onClick={() =>
                                    openModalCampaignForm(
                                        t('TA_POST_SURVEY_ANSWER_PANEL'),
                                        campaign.title
                                    )
                                }
                            >
                                {t('TA_POST_SURVEY_ANSWER_PANEL')}
                            </button>
                        )}
                    {!campaign.canParticipate && (
                        <div className="button small tiny see-stats survey">
                            {t('CAMPAIGN_NO_ACCESS')}
                        </div>
                    )}
                    {campaign.live_feedback && campaign.user_already_answered && (
                        <button className="button survey big-button" onClick={showLiveFeedback}>
                            {t('SURVEY_RESULTS')}
                        </button>
                    )}
                    {campaign.submits_count > 10 && (
                        <div className="button survey big-button">
                            {campaign.submits_count} {t('TA_POST_SURVEY_ANSWERS_TOTAL')}
                        </div>
                    )}
                    {isCampaignLive() &&
                        campaign.user_already_answered &&
                        campaign.allow_to_edit_answers && (
                            <button
                                onClick={editMyAnswers}
                                className="button small tiny see-stats survey"
                            >
                                {t('SEE_MY_ANSWERS')}
                            </button>
                        )}
                    {canSeeStats() && (
                        <>
                            {campaign.submits?.length > 0 ? (
                                <button
                                    ui-sref="auth.dashboard.campaigns.feed({ type: 'survey', status: 'running' })"
                                    className="button small tiny see-stats survey"
                                >
                                    {t('SEE_THE_STATS')}
                                </button>
                            ) : (
                                <span className="button small tiny see-stats survey">
                                    {t('NO_STATS')}
                                </span>
                            )}
                        </>
                    )}
                </div>

                <div className="actions" style={{ display: campaign.is_event ? 'flex' : 'none' }}>
                    {!campaign.user_already_answered && campaign.status === 2 && (
                        <button className="button survey big-button" onClick={toggleRules}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('TA_POST_PTS_TO_EARN', { points: campaign.reward })
                                }}
                            ></span>
                        </button>
                    )}
                    <button
                        className="button survey big-button bg-white"
                        onClick={() => {
                            //LiveCampaign.show({campaign})
                        }}
                    >
                        {t('TA_POST_LIVE_CAMPAIGN_JOIN')}
                    </button>
                    {campaign.reward > 0 && campaign.user_already_answered && (
                        <button
                            className="button survey big-button user-already-earned"
                            onClick={displayReward}
                        >
                            {campaign.reward} {t('TA_POST_POINTS_EARNED')}
                        </button>
                    )}
                    {canSeeStats() && (
                        <button
                            className="button small tiny see-stats survey"
                            onClick={() => {
                                //LiveCampaignBroadcast.show({campaign})
                            }}
                        >
                            {t('TA_POST_LIVE_CAMPAIGN_BROADCAST')}
                        </button>
                    )}
                    {canSeeStats() && (
                        <>
                            {campaign.submits?.length > 0 ? (
                                <button
                                    ui-sref="auth.dashboard.campaigns.feed({ type: 'survey', status: 'running' })"
                                    className="button small tiny see-stats survey"
                                >
                                    {t('SEE_THE_STATS')}
                                </button>
                            ) : (
                                <span className="button small tiny see-stats survey">
                                    {t('NO_STATS')}
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Survey;
