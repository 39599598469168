import React, { useEffect, useState, useRef } from 'react';
import Placeholder from 'components/Placeholder/Placeholder';
import usePostService from 'api/usePostService';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import Post from 'components/Post/Post';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import Actions from './partials/Actions';
import Header from './partials/Header';
import ViewsSelector from './partials/ViewsSelector';
import PostStatusTab from './partials/PostStatusTab';
import { useFeedContext } from '../providers/FeedProvider';

const Posts = () => {
    const { params } = useCurrentStateAndParams();
    const { hasPostStatus } = useFeedContext();
    const [query, setQuery] = useState('');
    const router = useRouter();
    const queryClient = useQueryClient();

    const { category, workspace, folder, post, status, query: q } = params;

    const observer = useRef();
    const lastElementRef = useRef();

    const PostService = usePostService();

    const fetchItems = ({ pageParam }) => {
        if (pageParam === false) {
            return [];
        }
        if (post) {
            return PostService.getPostById(post);
        }

        if (folder) {
            return PostService.getPostsByFolder(folder, pageParam);
        }

        if (workspace) {
            return PostService.getPostsByWorkspace(workspace, pageParam, status, q);
        }
        if (category) {
            return PostService.getPostsByCategory(category, pageParam);
        }

        return PostService.getPosts(pageParam);
    };

    const {
        data: posts,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading
    } = useInfiniteQuery({
        queryKey: ['posts', query],
        queryFn: fetchItems,
        initialPageParam: params.page || 1,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
            if (lastPage.length === 0) {
                return false;
            }
            return lastPageParam + 1;
        }
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            observer.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting && hasNextPage && !post) {
                        fetchNextPage().then(({ data }) => {
                            router.stateService.go(
                                '.',
                                { ...params, page: params.page + 1 },
                                { reload: false, inherit: false }
                            );
                        });
                    }
                },
                { threshold: 1.0 }
            );
            if (lastElementRef.current) {
                observer.current.observe(lastElementRef.current);
            }
        }, 1000);

        return () => {
            clearTimeout(timeout);
            if (lastElementRef.current && observer.current) {
                observer.current.unobserve(lastElementRef.current);
            }
        };
    }, [hasNextPage, fetchNextPage, query, post]);

    useEffect(() => {
        queryClient.removeQueries({ queryKey: ['posts'] });
    }, [queryClient]);

    useEffect(() => {
        setQuery((prevState) => {
            queryClient.removeQueries({ queryKey: ['posts', prevState] });
            return `posts_${category}_${workspace}_${folder}_${post}_${status}}`;
        });
    }, [category, workspace, folder, post, status]);

    return (
        <>
            <div>
                <Header />
                <Actions />
                <ViewsSelector />
                <PostStatusTab />
            </div>
            <div className={hasPostStatus() ? 'has-post-status' : ''}>
                {(!posts || isLoading) && <Placeholder template={'post'} number={10} />}
                {!isLoading && posts && (
                    <>
                        {posts.pages.map((page) => {
                            return page.map(
                                ({ post, download, alreadyLiked, post_consultations, tags_id }) => {
                                    return (
                                        <Post
                                            post={post}
                                            key={post.id}
                                            alreadyLiked={alreadyLiked}
                                            download={download}
                                            consultations={post_consultations}
                                            tagsId={tags_id}
                                        />
                                    );
                                }
                            );
                        })}

                        <div ref={lastElementRef}>
                            {hasNextPage && !post && (
                                <>{isFetchingNextPage ? 'Loading more...' : 'Load more'}</>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Posts;
