import React from 'react';

export default (props) => {
    return (
        <svg
            className="svg"
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 87 87"
            style={{ stroke: props.strokeColorHex }}
        >
            <path
                d="M38.9 4.9C39.5 4.2 40.2 3.7 40.9 3.3 41.7 2.9 42.6 2.7 43.5 2.7 44.4 2.7 45.3 2.9 46.1 3.3 46.8 3.7 47.6 4.2 48.1 4.9L53.6 11.7C54.2 12.5 55 13.1 55.9 13.5 56.8 13.9 57.8 14 58.8 13.9L67.5 13C68.4 12.9 69.3 13 70.1 13.3 71 13.6 71.7 14 72.4 14.7 73 15.3 73.5 16 73.8 16.9 74 17.7 74.1 18.6 74.1 19.5L73.1 28.2C73 29.2 73.1 30.2 73.5 31.1 73.9 32 74.5 32.8 75.3 33.4L82.1 38.9C82.8 39.5 83.3 40.2 83.7 41 84.1 41.8 84.3 42.6 84.3 43.5 84.3 44.4 84.1 45.3 83.7 46.1 83.3 46.9 82.8 47.6 82.1 48.1L75.3 53.6C74.5 54.2 73.9 55 73.5 55.9 73.1 56.8 73 57.8 73.1 58.8L74 67.5C74.1 68.4 74 69.3 73.7 70.1 73.4 71 73 71.7 72.3 72.4 71.7 73 71 73.5 70.1 73.8 69.3 74 68.4 74.1 67.5 74L58.8 73.1C57.8 73 56.8 73.1 55.9 73.5 55 73.9 54.2 74.5 53.6 75.3L48.1 82.1C47.6 82.8 46.8 83.3 46.1 83.7 45.3 84.1 44.4 84.3 43.5 84.3 42.6 84.3 41.7 84.1 40.9 83.7 40.2 83.3 39.4 82.8 38.9 82.1L33.4 75.3C32.8 74.5 32 73.9 31.1 73.5 30.2 73.1 29.2 73 28.2 73.1L19.5 74C18.6 74.1 17.7 74 16.9 73.7 16 73.4 15.3 73 14.6 72.3 14 71.7 13.5 70.9 13.2 70.1 12.9 69.3 12.8 68.4 12.9 67.5L13.9 58.8C14 57.8 13.9 56.8 13.5 55.9 13.1 55 12.5 54.2 11.7 53.6L4.9 48.1C4.2 47.5 3.7 46.8 3.3 46 2.9 45.2 2.7 44.4 2.7 43.5 2.7 42.6 2.9 41.7 3.3 40.9 3.7 40.1 4.2 39.4 4.9 38.9L11.7 33.4C12.5 32.8 13.1 32 13.5 31 13.9 30.1 14 29.1 13.9 28.1L12.9 19.4C12.9 18.6 13 17.7 13.2 16.8 13.5 16 14 15.2 14.6 14.6 15.3 14 16 13.5 16.9 13.2 17.7 12.9 18.6 12.8 19.5 12.9L28.2 13.9C29.2 14 30.2 13.8 31.1 13.5 32 13.1 32.8 12.5 33.4 11.7L38.9 4.9Z"
                fill={`url(${props.hashUrl})`}
            />

            <defs>
                <linearGradient id={props.hash} x2="0" y2="1">
                    <stop stopColor={props.startColorHex} />
                    <stop offset="1" stopColor={props.stopColorHex} />
                </linearGradient>
            </defs>
        </svg>
    );
};
