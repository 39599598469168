import Ambassador from './Ambassador';
import BlogArticles from './BlogArticles';
import Campaigns from './Campaigns';
import Categories from './Categories';
import CategoryBlogArticles from './CategoryBlogArticles';
import Colors from './Colors';
import Customizer from './Customizer';
import Feeds from './Feeds';
import Icons from './Icons';
import Image from './Image';
import OrganizationUnits from './OrganizationUnits';
import Range from './Range';
import Repeater from './Repeater';
import Routes from './Routes';
import Select from './Select';
import ShareDocumentCategories from './SharedDocumentCategories';
import Tags from './Tags';
import Text from './Text';
import Widgets from './Widgets';
import { isNil } from 'lodash-es';

const AbstractInput = ({ field, ...props }) => {
    let type = null;
    if (!isNil(field.special)) {
        type = field.special;
    } else if (field.values && field.values.length > 0) {
        type = 'select';
    } else if (!isNil(field.min) || !isNil(field.max)) {
        type = 'range';
    } else {
        type = 'text';
    }

    switch (type) {
        case 'ambassador':
            return <Ambassador field={field} {...props} />;
        case 'blogArticles':
            return <BlogArticles field={field} {...props} />;
        case 'campaigns':
            return <Campaigns field={field} {...props} />;
        case 'categories':
            return <Categories field={field} {...props} />;
        case 'colors':
            return <Colors field={field} {...props} />;
        case 'categoryBlogArticles':
            return <CategoryBlogArticles field={field} {...props} />;
        case 'feeds':
            return <Feeds field={field} {...props} />;
        case 'icons':
            return <Icons field={field} {...props} />;
        case 'image':
            return <Image field={field} {...props} />;
        case 'organizationUnits':
            return <OrganizationUnits field={field} {...props} />;
        case 'range':
            return <Range field={field} {...props} />;
        case 'customizer':
            return <Customizer field={field} {...props} />;
        case 'routes':
            return <Routes field={field} {...props} />;
        case 'repeater':
            return <Repeater field={field} {...props} />;
        case 'select':
            return <Select field={field} {...props} />;
        case 'shareDocumentCategories':
            return <ShareDocumentCategories field={field} {...props} />;
        case 'tags':
            return <Tags field={field} {...props} />;
        case 'text':
            return <Text field={field} {...props} />;
        case 'widgets':
            return <Widgets field={field} {...props} />;
    }
};

export default AbstractInput;
