import { useFeedContext } from '../../../../providers/FeedProvider';
import { useCallback } from 'react';
import { orderBy } from 'lodash-es';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useTranslation } from 'react-i18next';

const Statuses = () => {
    const { t } = useTranslation();
    const { tileFilters } = useFeedContext();
    const router = useRouter();
    const { params } = useCurrentStateAndParams();

    const isActive = useCallback(
        (status) => {
            return status.id === parseInt(params.status);
        },
        [params]
    );

    const getStatuses = useCallback(() => {
        if (tileFilters) {
            return orderBy(
                [
                    {
                        label: 'TILE_ALL',
                        id: 0,
                        color: '#373A4D',
                        tile_order: 0
                    },
                    ...tileFilters.postStatuses
                ],
                ['tile_order']
            );
        }
        return [];
    }, [tileFilters]);

    const changePostStatus = (postStatus) => {
        router.stateService.go(
            '.',
            { page: 1, status: postStatus.id },
            { reload: false, notify: false }
        );
    };

    if (!tileFilters) {
        return <></>;
    }

    return (
        <div className="statuses">
            {getStatuses().map((status) => {
                return (
                    <div
                        className={`status ${!isActive(status) ? 'inactive' : ''}`}
                        style={{
                            color: '#fff',
                            backgroundColor: status.color
                        }}
                        onClick={() => changePostStatus(status)}>
                        {t(status.label)}
                    </div>
                );
            })}
        </div>
    );
};

export default Statuses;
