import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Input } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';

const AchievementCategoryFormModalContent = () => {
    const { t } = useTranslation();
    const {
        contexts: {
            edit_achievement_category: { currentAchievementCategory }
        },
        closeModal
    } = useModal();
    const methods = useForm();

    const onFinish = (values) => {};

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input
                        name={'name'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL')}
                        value={currentAchievementCategory?.name}
                    />
                    <Input
                        name={'position'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_POSITION_LABEL')}
                        type={'number'}
                        value={currentAchievementCategory?.position}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('edit_achievement_category');
                }}
            />
        </ModalForm>
    );
};

const AchievementCategoryFormModal = () => {
    return (
        <Modal
            name={'edit_achievement_category'}
            title={'Ajouter ou modifier une catégorie'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <AchievementCategoryFormModalContent />
        </Modal>
    );
};

export default AchievementCategoryFormModal;
