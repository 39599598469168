import { useHttp } from 'api/useHttp';

const useSpringboardService = () => {
    const axios = useHttp();

    const getAllSpringboards = () => {
        return axios.get(`/homepage/all/configs`).then((response) => response.data);
    };

    return {
        getAllSpringboards
    };
};

export default useSpringboardService;
