import { useHttp } from 'api/useHttp';

const useFolderService = () => {
    const axios = useHttp();

    const getFolders = () => {
        return axios.get(`/folders`).then((response) => response.data);
    };

    const getFolder = (id) => {
        return axios.get(`/folders/${id}`).then((response) => response.data);
    };

    return {
        getFolders,
        getFolder
    };
};

export default useFolderService;
