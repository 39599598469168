export const SEARCH_FIELDS = [
    {
        value: 'unique_synchronisation_id',
        label: 'Clé de synchro'
    },
    {
        value: 'email',
        label: 'Email'
    },
    {
        value: 'registration_number',
        label: 'Matricule'
    }
];

export const FIELDS = [
    {
        name: 'last_name',
        label: 'Nom',
        type: 'select'
    },
    {
        name: 'first_name',
        label: 'Prénom',
        type: 'select'
    },
    {
        name: 'email',
        label: 'Email',
        type: 'select'
    },
    {
        name: 'registration_number',
        label: 'Matricule',
        type: 'select'
    },
    {
        name: 'key',
        label: 'Colonne de synchonisation',
        type: 'select'
    },
    {
        name: 'birth_date',
        label: 'Date de naissance',
        type: 'select'
    },
    {
        name: 'birth_date_format',
        label: 'Format de la date de naissance',
        type: 'input'
    },
    {
        name: 'out_date',
        label: 'Date de sortie',
        type: 'select'
    },
    {
        name: 'out_date_format',
        label: 'Format de la date de sortie',
        type: 'input'
    },
    {
        name: 'phone_number',
        label: 'Numéro de téléphone',
        type: 'select'
    },
    {
        name: 'lang',
        label: 'Langue',
        type: 'select'
    },
    {
        name: 'password',
        label: "Mot de passe (si création automatique de l'utilisateur)",
        type: 'select'
    },
    {
        name: 'ignore_first_row',
        label: 'Ignorer la première ligne',
        type: 'boolean'
    },
    {
        name: 'UTF8',
        label: 'Les données sont en UTF8',
        type: 'boolean'
    },
    {
        name: 'remove_double_quote',
        label: 'Supprimer les ""',
        type: 'boolean'
    },
    {
        name: 'create_user',
        label: "Créer automatiquement l'utilisateur",
        type: 'boolean'
    },
    {
        name: 'update_password',
        label: 'Mettre à jour systématiquement les mots de passe',
        type: 'boolean'
    }
];

export const LETTERS_WITH_CUSTOMS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ',
    'Valeur forcée'
];
export const LETTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ'
];
