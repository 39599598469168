import React, { useEffect, useRef } from 'react';

const Map = ({ lat, lng, address }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        /*
        const options = {
            center: new google.maps.LatLng(lat, lng),
            zoom: 12,
            gestureHandling: 'cooperative',
            zoomControl: false
        };

        const map = new google.maps.Map(mapRef.current, options);
        const loc = new google.maps.LatLng(lat, lng);
        const marker = new google.maps.Marker({ position: loc, map, title: address });
        const infowindow = new google.maps.InfoWindow({ content: address });

        marker.addListener('click', () => {
            infowindow.open(map, marker);
        });*/
    }, [lat, lng, address]);

    return <div ref={mapRef} className="map"></div>;
};

export default Map;
