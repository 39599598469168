import React, { useState } from 'react';
import MapComponent from 'components/Map/Map';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';

const Position = ({ input }) => {
    const { isExternalDependencyRestricted } = useConfig();
    const { t } = useTranslation();

    const [showMap, setShowMap] = useState(false);

    const isPositionInputValid = (input) => {
        return input.address && input.address !== '';
    };

    const isGoogleMapsAvailable = (input) => {
        if (isExternalDependencyRestricted('googlemaps')) {
            return false;
        }

        return input.latitude && input.longitude;
    };

    return (
        <>
            {((input.workspace_form_input.label_render !== '' && isPositionInputValid(input)) ||
                isGoogleMapsAvailable(input)) && (
                <div className="render_label">
                    {input.workspace_form_input.label_render !== '' &&
                        isPositionInputValid(input) && (
                            <div className="address">
                                <span>{input.workspace_form_input.label_render} :</span>{' '}
                                {input.address}
                            </div>
                        )}

                    {isGoogleMapsAvailable(input) && !showMap && (
                        <div className="action">
                            <button
                                className="btn"
                                onClick={() => {
                                    setShowMap(!showMap);
                                }}
                            >
                                <i className="icon-search" aria-hidden="true"></i>{' '}
                                {t('POST_SHOW_MAP')}
                            </button>
                        </div>
                    )}
                </div>
            )}

            {isGoogleMapsAvailable(input) && showMap && (
                <MapComponent
                    address={input.address}
                    lat={input.latitude}
                    lng={input.longitude}
                ></MapComponent>
            )}
        </>
    );
};

export default Position;
