import React from 'react';
import { useTop } from 'providers/TopProvider';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import useUser from 'hooks/useUser';
import { useRouter } from '@uirouter/react';
import useRight from 'hooks/useRight';
import { useAlert } from 'providers/AlertContext';

const Top = () => {
    const router = useRouter();
    const { hasRole, isReviewer, canSeeSlots } = useRight();
    const { user } = useAuth();
    const { forgeAvatarUrl } = useUser();
    const { t } = useTranslation();
    const {
        title,
        hasSearchBar,
        hasBackButton,
        backButtonClicked,
        actionButtons,
        actionButtonClicked
    } = useTop();
    const { showActionSheet } = useAlert();

    const getActionButtonType = (actionButton) => {
        if (actionButton.icon !== null) {
            return 'icon';
        }

        return 'text';
    };

    const openUserActionSheet = () => {
        let buttons = [
            {
                label: t('TOP_PROFILE'),
                callback: () => {
                    router.stateService.go('auth.my-profile', {}, { reload: true, inherit: false });
                }
            }
        ];

        if (!hasRole('ROLE_HIDE_LIST_USERS')) {
            buttons.push({
                label: t('TOP_USERS'),
                callback: () => {
                    if (hasRole('ROLE_INTERNAL')) {
                        if (hasRole('HAS_EMPLOYEE_DIRECTORY')) {
                            router.stateService.go(
                                'auth.phonebook',
                                {},
                                { reload: true, inherit: false }
                            );
                        }

                        if (hasRole('HAS_EMPLOYEE_DIRECTORY_V2')) {
                            router.stateService.go(
                                'auth.phonebookv2',
                                {},
                                { reload: true, inherit: false }
                            );
                        }
                    } else {
                        router.stateService.go(
                            'auth.directory.list',
                            {},
                            { reload: true, inherit: false }
                        );
                    }
                }
            });
        }

        if (hasRole('HAS_HELPDESK')) {
            buttons.push({
                label: t('ADMIN_MENU_HELPDESK_LABEL'),
                callback: () => {
                    router.stateService.go(
                        'auth.helpdesk.categories',
                        {},
                        { reload: true, inherit: false }
                    );
                }
            });
        }

        if (
            hasRole('ROLE_RS') ||
            hasRole('ROLE_PRODUCT') ||
            hasRole('ROLE_RECRUITING') ||
            hasRole('ROLE_SURVEY')
        ) {
            buttons.push({
                label: t('CAMPAIGNS_MANAGEMENT'),
                callback: () => {
                    router.stateService.go(
                        'auth.dashboard.campaigns.home',
                        {},
                        { reload: true, inherit: false }
                    );
                }
            });
        }

        if (hasRole('ROLE_SUPER_MISSION')) {
            buttons.push({
                label: t('MISSIONS_MANAGEMENT'),
                callback: () => {
                    router.stateService.go(
                        'auth.dashboard.missions.home',
                        {},
                        { reload: true, inherit: false }
                    );
                }
            });
        }

        if (hasRole('HAS_MISSIONS') && isReviewer()) {
            buttons.push({
                label: t('REALIZATIONS_MANAGEMENT'),
                callback: () => {
                    router.stateService.go(
                        'auth.missions.dashboard.inbox',
                        {},
                        { reload: true, inherit: false }
                    );
                }
            });
        }

        if (hasRole('HAS_TRACKS') && canSeeSlots()) {
            buttons.push({
                label: t('SLOTS_MANAGEMENT'),
                callback: () => {
                    /*this.Slot.showDetails = false;
                    this.Slot.showSlots = true;
                    this.Slot.displayMySlots = false;*/

                    router.stateService.go('auth.slots', {}, { reload: true, inherit: false });
                }
            });
        }

        if (hasRole('ROLE_ADMIN')) {
            buttons.push({
                label: t('STATS_NAVBAR_LABEL'),
                callback: () => {
                    router.stateService.go('auth.stats', {}, { reload: true, inherit: false });
                }
            });

            if (hasRole('HAS_METRICS') && !hasRole('HAS_DASHBOARD_METRICS')) {
                buttons.push({
                    label: t('METRICS'),
                    callback: () => {
                        router.stateService.go(
                            'auth.metrics.global',
                            {},
                            { reload: true, inherit: false }
                        );
                    }
                });
            }

            if (hasRole('HAS_METRICS') && hasRole('HAS_DASHBOARD_METRICS')) {
                buttons.push({
                    label: t('METRICS'),
                    callback: () => {
                        router.stateService.go(
                            'auth.metrics.dashboard',
                            {},
                            { reload: true, inherit: false }
                        );
                    }
                });
            }

            buttons.push({
                label: t('TOP_ADMIN'),
                callback: () => {
                    router.stateService.go(
                        'auth.admin.users',
                        {},
                        { reload: true, inherit: false }
                    );
                }
            });
        }

        buttons.push({
            label: t('MISC_SETTINGS'),
            callback: () => {
                router.stateService.go(
                    'auth.my-profile',
                    { tab: 'preferences' },
                    { reload: true, inherit: false }
                );
            }
        });

        buttons.push({
            label: t('TOP_LOGOUT'),
            classes: ['red', 'bold'],
            callback: () => {
                //this.logoutUser();
            }
        });

        showActionSheet({
            buttons: buttons
        });
    };

    return (
        <div className="top-wrapper">
            <div className="top">
                <div className="items">
                    {!hasBackButton && (
                        <div className="item user" onClick={openUserActionSheet}>
                            <div className="avatar">
                                <img src={forgeAvatarUrl(user.image_profile)} alt={'Avatar'} />
                            </div>
                        </div>
                    )}

                    {hasBackButton && (
                        <button className="item action icon" onClick={backButtonClicked}>
                            <i className="icon-arrow-left"></i>
                        </button>
                    )}

                    {hasSearchBar && (
                        <div className="item expand search" ng-click="$ctrl.SearchBox.show()">
                            <input
                                type="text"
                                style={{ pointerEvents: 'none' }}
                                placeholder={t('TOP_SEARCH_IN', { title })}
                                disabled
                            />
                            <div className="icon">
                                <i className="icon-search"></i>
                            </div>
                        </div>
                    )}

                    {!hasSearchBar && (
                        <div className="item expand title">
                            <div className="text">{title}</div>
                        </div>
                    )}

                    {actionButtons.map((actionButton, index) => {
                        return (
                            <div
                                className={`item action ${getActionButtonType(actionButton)}`}
                                onClick={() => {
                                    actionButtonClicked(actionButton);
                                }}
                                key={`top_${index}`}
                            >
                                {actionButton.icon && (
                                    <span className="content">
                                        <i className={actionButton.icon}></i>
                                    </span>
                                )}
                                {actionButton.text && <span className="content">{title}</span>}
                            </div>
                        );
                    })}

                    {actionButtons.length === 0 && <div className="item spacer"></div>}
                </div>
            </div>

            <Loader />
        </div>
    );
};

export default Top;
