import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useWorkspaceService from 'api/useWorkspaceService';
import { useTreeContext } from '../../Auth/providers/TreeProvider';
import { cloneDeep, defaultTo, isNil } from 'lodash-es';
import processTreeCategories from 'helpers/processTreeCategories';
import removeEmptyTopLevelCategories from 'helpers/removeEmptyTopLevelCategories';
import mergeAuthorizedWorkspaceRulesByUserWithTree from './helpers/mergeAuthorizedWorkspaceRulesByUserWithTree';
import useRight from 'hooks/useRight';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useAuth } from 'providers/AuthContext';
import { useAlert } from 'providers/AlertContext';

const Subscriptions = () => {
    const { tree } = useTreeContext();
    const { user, setUser } = useAuth();
    const { t } = useTranslation();
    const { isAllowToRead, isAllowToWrite, isAllowToHalfRead } = useRight();
    const { params } = useCurrentStateAndParams();
    const { showToast } = useAlert();
    const {
        getWorkspaceUserSubscriptions,
        getAuthorizedWorkspaceRulesByUser,
        toggleUserWorkspaceRule,
        deleteWorkspaceUserSubscription,
        postWorkspaceUserSubscription
    } = useWorkspaceService();
    const queryClient = useQueryClient();

    useEffect(() => {
        return () => {
            queryClient.removeQueries(['tree']);
        };
    }, []);

    const { data } = useQuery({
        queryKey: ['user_subscriptions'],
        queryFn: () => {
            return Promise.all([
                getAuthorizedWorkspaceRulesByUser(),
                getWorkspaceUserSubscriptions()
            ]);
        }
    });

    const [workspaceUserSubscriptions, setWorkspaceUserSubscriptions] = useState([]);
    const [workspacesByCategories, setWorkspacesByCategories] = useState([]);
    const [open, setOpen] = useState(defaultTo(params.open, 'first-opened'));
    const [categoryIds, setCategoryIds] = useState(defaultTo(params.open, null));

    useEffect(() => {
        if (data && tree) {
            setWorkspaceUserSubscriptions(data[1]);
            const treeProcessed = processTreeCategories(
                removeEmptyTopLevelCategories(cloneDeep(tree))
            );
            setWorkspacesByCategories(
                mergeAuthorizedWorkspaceRulesByUserWithTree(data[0], treeProcessed).map(
                    (workspacesByCategory, index) => {
                        return {
                            ...workspacesByCategory,
                            workspaces: workspacesByCategory.workspaces.filter((workspaceData) => {
                                // Filter workspaces from old workspace rules
                                let { workspace } = workspaceData;

                                return !(
                                    isAllowToRead(workspace.id) &&
                                    !canEditWorkspaceRule('is_readable', workspace) &&
                                    isAllowToWrite(workspace.id) &&
                                    !canEditWorkspaceRule('is_writable', workspace) &&
                                    isAllowToHalfRead(workspace.id) &&
                                    !canEditWorkspaceRule('is_half_readable', workspace)
                                );
                            }),
                            hide: getOpenedCategory(index, workspacesByCategory)
                        };
                    }
                )
            );
        }
    }, [data, tree]);

    const getOpenedCategory = (index, workspacesByCategory) => {
        switch (open) {
            case 'all-opened':
                return false;
            case 'all-closed':
                return true;
            case 'first-opened':
                return index !== 0;
            case 'one-opened':
                return !categoryIds.includes(workspacesByCategory.id.toString());
        }
    };

    const canEditWorkspaceRule = (name, workspace) => {
        switch (name) {
            case 'is_readable':
                return canEditReadWorkspaceRule(workspace);
            case 'is_writable':
                return canEditWriteWorkspaceRule(workspace);
            case 'is_half_readable':
                return canEditHalfReadWorkspaceRule(workspace);
        }

        return false;
    };

    const canEditReadWorkspaceRule = (workspace) => {
        if (!isNil(workspace.is_user_in_ous_for_read_management)) {
            return (
                workspace.subscription_management_read_by_user &&
                workspace.is_user_in_ous_for_read_management
            );
        }

        return workspace.subscription_management_read_by_user;
    };

    const canEditWriteWorkspaceRule = (workspace) => {
        if (!isNil(workspace.is_user_in_ous_for_write_management)) {
            return (
                workspace.subscription_management_write_by_user &&
                workspace.is_user_in_ous_for_write_management
            );
        }

        return workspace.subscription_management_write_by_user;
    };

    const canEditHalfReadWorkspaceRule = (workspace) => {
        if (!isNil(workspace.is_user_in_ous_for_half_read_management)) {
            return (
                workspace.silent_mode &&
                workspace.is_user_in_ous_for_half_read_management &&
                isAllowToRead(workspace.id)
            );
        }

        return workspace.silent_mode && isAllowToRead(workspace.id);
    };

    const isSubscribedToWorkspace = (workspaceId) => {
        if (!workspaceUserSubscriptions && workspaceUserSubscriptions.length === 0) {
            return false;
        }

        return workspaceUserSubscriptions.some((subscription) => {
            return subscription.workspace_id === workspaceId;
        });
    };

    const canSubscribeToWorkspace = (workspace) => {
        return isAllowToRead(workspace.id);
    };

    const toggleCategory = (category) => {
        setWorkspacesByCategories((prev) => {
            return prev.map((c) => {
                if (c.id === category.id) {
                    return { ...c, hide: !c.hide };
                } else {
                    return c;
                }
            });
        });
    };

    const toggleWorkspaceRule = (name, workspace, silent = false) => {
        if (!name || !canEditWorkspaceRule(name, workspace)) {
            return;
        }

        let type = name === 'is_readable' ? 0 : name === 'is_writable' ? 1 : 2;

        let rule = user.workspaces_rules.find((rule) => {
            return rule.workspace_id === workspace.id;
        });

        if (!rule) {
            return;
        }

        toggleUserWorkspaceRule(rule.id, type).then(() => {
            let value = !rule[name];

            switch (name) {
                case 'is_readable':
                    if (value === false) {
                        if (rule.is_half_readable === true) {
                            toggleWorkspaceRule('is_half_readable', workspace, true);
                        }

                        if (isSubscribedToWorkspace(workspace.id)) {
                            toggleWorkspaceSubscription(workspace, true);
                        }
                    }

                    break;
            }

            setUser((prevUser) => {
                return {
                    ...prevUser,
                    workspaces_rules: prevUser.workspaces_rules.map((prevRule) => {
                        if (prevRule.id === rule.id) {
                            return { ...prevRule, [name]: value };
                        } else {
                            return prevRule;
                        }
                    })
                };
            });

            done(silent);
        });
    };

    const toggleWorkspaceSubscription = (workspace, silent = false) => {
        if (!canSubscribeToWorkspace(workspace)) {
            return;
        }

        if (isSubscribedToWorkspace(workspace.id)) {
            deleteWorkspaceUserSubscription(workspace, user).then(() => {
                const subscriptionIndex = workspaceUserSubscriptions.findIndex(
                    (subscription) => subscription.workspace_id === workspace.id
                );

                if (subscriptionIndex < 0) {
                    return;
                }

                const updatedSubscriptions = [
                    ...workspaceUserSubscriptions.slice(0, subscriptionIndex),
                    ...workspaceUserSubscriptions.slice(subscriptionIndex + 1)
                ];

                setWorkspaceUserSubscriptions(updatedSubscriptions);

                done(silent);
            });
        } else {
            postWorkspaceUserSubscription(workspace, user).then(() => {
                const newSubscription = {
                    workspace_id: workspace.id,
                    workspace_name: workspace.name
                };

                const updatedSubscriptions = [...workspaceUserSubscriptions, newSubscription];
                setWorkspaceUserSubscriptions(updatedSubscriptions);

                done(silent);
            });
        }
    };

    const done = (silent = false) => {
        if (!silent) {
            showToast({
                text: t('SETTINGS_SAVED'),
                duration: 1500
            });
        }
    };

    return (
        <div className="subscriptions-wrapper">
            <div className="subscriptions">
                <div className="intro">
                    <p>
                        <strong>{t('PROFILE_SUBSCRIPTIONS_INTRO_TEXT')}</strong>
                    </p>

                    <p>{t('PROFILE_SUBSCRIPTIONS_INTRO_DEFINITION_TITLE')}</p>

                    <ul>
                        <li>{t('PROFILE_SUBSCRIPTIONS_INTRO_DEFINITION_READER')}</li>
                        <li>{t('PROFILE_SUBSCRIPTIONS_INTRO_DEFINITION_WRITER')}</li>
                        <li>{t('PROFILE_SUBSCRIPTIONS_INTRO_DEFINITION_HALF_READ')}</li>
                        <li>{t('PROFILE_SUBSCRIPTIONS_INTRO_DEFINITION_SUBSCRIBE')}</li>
                    </ul>

                    <p>
                        <em>{t('PROFILE_SUBSCRIPTIONS_INTRO_TIPS')}</em>
                    </p>
                </div>

                {workspacesByCategories && workspacesByCategories.length > 0 && (
                    <div className="categories">
                        {workspacesByCategories.map((category) => {
                            return (
                                <div
                                    className="category"
                                    key={`workspacesByCategories_${category.id}`}>
                                    <div className="row">
                                        <div
                                            className="name title is-clickable"
                                            onClick={() => toggleCategory(category)}>
                                            {category.name}{' '}
                                            <span>
                                                <i
                                                    className={
                                                        category.hide
                                                            ? `icon-arrow-down-1-arrows-diagrams`
                                                            : `icon-arrow-up-1-arrows-diagrams`
                                                    }
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>

                                        {!category.hide && (
                                            <div className="settings hidden-xs">
                                                <div className="setting title">
                                                    {t('PROFILE_SUBSCRIPTIONS_READ_TITLE')}
                                                </div>
                                                <div className="setting title">
                                                    {t('PROFILE_SUBSCRIPTIONS_WRITE_TITLE')}
                                                </div>
                                                <div className="setting title">
                                                    {t('PROFILE_SUBSCRIPTIONS_HALF_READ_TITLE')}
                                                </div>
                                                <div className="setting title">
                                                    {t('PROFILE_SUBSCRIPTIONS_SUBSCRIBE_TITLE')}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {category.workspaces.map(({ workspace }) => {
                                        if (category.hide) return;
                                        return (
                                            <div className="row" key={`workspace_${workspace.id}`}>
                                                <div className="name">{workspace.name}</div>

                                                <div className="settings">
                                                    <div className="setting">
                                                        <div className="title visible-xs">
                                                            {t('PROFILE_SUBSCRIPTIONS_READ_TITLE')}
                                                        </div>

                                                        <div className="input">
                                                            <input
                                                                type="checkbox"
                                                                className="switch"
                                                                checked={isAllowToRead(
                                                                    workspace.id
                                                                )}
                                                                onChange={() => {
                                                                    toggleWorkspaceRule(
                                                                        'is_readable',
                                                                        workspace
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !canEditWorkspaceRule(
                                                                        'is_readable',
                                                                        workspace
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="setting">
                                                        <div className="title visible-xs">
                                                            {t('PROFILE_SUBSCRIPTIONS_WRITE_TITLE')}
                                                        </div>

                                                        <div className="input">
                                                            <input
                                                                type="checkbox"
                                                                className="switch"
                                                                checked={isAllowToWrite(
                                                                    workspace.id
                                                                )}
                                                                onChange={() => {
                                                                    toggleWorkspaceRule(
                                                                        'is_writable',
                                                                        workspace
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !canEditWorkspaceRule(
                                                                        'is_writable',
                                                                        workspace
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="setting">
                                                        <div className="title visible-xs">
                                                            {t(
                                                                'PROFILE_SUBSCRIPTIONS_HALF_READ_TITLE'
                                                            )}
                                                        </div>

                                                        <div className="input">
                                                            <input
                                                                type="checkbox"
                                                                className="switch"
                                                                checked={isAllowToHalfRead(
                                                                    workspace.id
                                                                )}
                                                                onChange={() => {
                                                                    toggleWorkspaceRule(
                                                                        'is_half_readable',
                                                                        workspace
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !canEditWorkspaceRule(
                                                                        'is_half_readable',
                                                                        workspace
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="setting">
                                                        <div className="title visible-xs">
                                                            {t(
                                                                'PROFILE_SUBSCRIPTIONS_SUBSCRIBE_TITLE'
                                                            )}
                                                        </div>

                                                        <div className="input">
                                                            <input
                                                                type="checkbox"
                                                                className="switch"
                                                                checked={isSubscribedToWorkspace(
                                                                    workspace.id
                                                                )}
                                                                onChange={() => {
                                                                    toggleWorkspaceSubscription(
                                                                        workspace
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !canSubscribeToWorkspace(
                                                                        workspace
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Subscriptions;
