import { Select, DatePicker } from '../../../../../../components/Form/Inputs';
import useTableComponent from '../hooks/useTableComponent';
import { useFeedContext } from '../../../../providers/FeedProvider';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

const DateFilter = ({ input }) => {
    const { workspace } = useFeedContext();
    const { getSelectValues } = useTableComponent(workspace, []);

    const { watch, getValues } = useFormContext();
    const dateValue = useMemo(() => {
        return getValues()[input.type];
    }, [watch()]);

    return (
        <>
            <Select name={input.type} placeholder={input.name} options={getSelectValues(input)} />
            {dateValue && dateValue === 'custom' && (
                <>
                    <div className="custom">
                        <div className="range start">
                            <DatePicker name={`${input.type}__startDate`} />
                        </div>
                    </div>
                    <div className="custom">
                        <div className="range start">
                            <DatePicker name={`${input.type}__endDate`} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DateFilter;
