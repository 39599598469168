import React, { useEffect, useState } from 'react';
import useAchievementService from 'api/useAchievementService';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'providers/AuthContext';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash-es';
import useViewport from 'hooks/useViewport';
import AchievementBadge from 'components/Achievement/Achievement';
import { useAlert } from 'providers/AlertContext';

const Badges = () => {
    const { user: me, setUser } = useAuth();
    const { params } = useCurrentStateAndParams();
    const { getAllAchievements, setUserAchievement } = useAchievementService();
    const { t } = useTranslation();
    const viewport = useViewport();
    const { showToast, showConfirm } = useAlert();

    const DEFAULT_LIMIT = viewport.gtSm ? 8 : 6;
    const SECTIONS = [
        {
            slug: 'unlocked',
            title: t('PROFILE_BADGES_UNLOCKED_TITLE'),
            condition: (badge) => badge.unlocked
        },
        {
            slug: 'inProgress',
            title: t('PROFILE_BADGES_IN_PROGRESS_TITLE'),
            condition: (badge) => badge.progress_result && badge.unlockable
        },
        {
            slug: 'others',
            title: t('PROFILE_BADGES_OTHERS_TITLE'),
            condition: (badge) => !badge.unlocked && !badge.progress_result && badge.unlockable
        }
    ];

    const [sections, setSections] = useState([]);

    const { data: badges } = useQuery({
        queryKey: [params.userId ? `user_badges_${params.userId}` : 'my_badges'],
        queryFn: () => {
            if (params.userId) {
                return getAllAchievements(params.userId);
            }

            return getAllAchievements(me.id);
        }
    });

    const isMe = () => {
        return !(params.userId && params.userId !== me.id);
    };

    useEffect(() => {
        if (badges) {
            const tmpSections = {};
            SECTIONS.forEach(({ slug, condition }) => {
                tmpSections[`${slug}Badges`] = badges.filter(condition);

                tmpSections[`${slug}Badges`] = orderBy(
                    tmpSections[`${slug}Badges`],
                    ['category.position', 'position'],
                    ['asc', 'asc']
                );

                tmpSections[`${slug}Limit`] = DEFAULT_LIMIT;
            });
            setSections(tmpSections);
        }
    }, [badges]);

    const addUnit = (value, unit, space = false) => {
        if (space) {
            return `${value} ${unit}`;
        }

        return `${value}${unit}`;
    };

    const getBadges = (section) => {
        return sections && sections[`${section.slug}Badges`];
    };

    const hasBadges = (section) => {
        let badges = getBadges(section);

        return badges && badges.length > 0;
    };

    const getLimit = (section) => {
        return sections && sections[`${section.slug}Limit`];
    };

    const setUserAchievementHandler = (badge) => {
        if (me.user_achievement && me.user_achievement.id === badge.user_achievement_id) {
            showToast({
                text: 'Ce badge est déjà mis en avant',
                duration: 1500
            });

            return;
        }

        showConfirm({
            title: 'Mettre en avant un badge',
            text: `Etes-vous sûr de vouloir mettre en avant le badge ${t(badge.name)} ?`,
            button: {
                label: 'Mettre en avant',
                classes: ['bold']
            },
            cancelButtonLabel: 'Annuler'
        })
            .then(() => {
                setUserAchievement(me, badge).then(({ data }) => {
                    setUser((prevState) => {
                        return { ...prevState, ...data };
                    });
                });
            })
            .catch(() => {});
    };

    if (!badges) return;

    return (
        <div className={'badges_wrapper'}>
            <div className="badges">
                {SECTIONS.map((section) => {
                    if (!hasBadges(section)) return;
                    return (
                        <div className={`card ${section.slug}`} key={section.slug}>
                            <div className="title">{section.title}</div>

                            <div className="items">
                                {getBadges(section).map((badge) => {
                                    return (
                                        <div className="item" key={badge.id}>
                                            <div className="badge partial">
                                                {badge.unlocked && isMe() && (
                                                    <div
                                                        className={`highlight ${me.user_achievement && me.user_achievement.id === badge.user_achievement_id ? 'highlighted' : ''}`}
                                                        title="Mettre en avant ce badge"
                                                        onClick={() => {
                                                            setUserAchievementHandler(badge);
                                                        }}
                                                    >
                                                        <i
                                                            className="icon-pin"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}

                                                <div className="icon">
                                                    <AchievementBadge item={badge} />
                                                </div>

                                                <div className="name">{t(badge.name)}</div>

                                                <div className="description">
                                                    {t(badge.description)}
                                                </div>

                                                {badge.points && (
                                                    <div className="reward">
                                                        {badge.unlocked && (
                                                            <div className="unlocked">
                                                                {t('TA_POST_PTS_EARNED_SHORT', {
                                                                    points: badge.points
                                                                })}
                                                            </div>
                                                        )}
                                                        {!badge.unlocked && (
                                                            <div className="locked">
                                                                {t('TA_POST_PTS_TO_EARN_SHORT', {
                                                                    points: badge.points
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {badge.progress_result && (
                                                    <div className="progress">
                                                        <div className="bar">
                                                            <div
                                                                className="fill"
                                                                style={{
                                                                    width: addUnit(
                                                                        badge.progress_result
                                                                            .result,
                                                                        '%'
                                                                    )
                                                                }}
                                                            ></div>
                                                        </div>

                                                        <div className="details text-right">
                                                            {badge.progress_result.count}/
                                                            {badge.progress_result.total}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {getBadges(section).length > DEFAULT_LIMIT && (
                                <div className="actions">
                                    <button
                                        className="action"
                                        title={t('PROFILE_BADGES_ACTION_TITLE_ATTR')}
                                        ng-click="$ctrl.toggleMore(section)"
                                        disabled={getBadges(section).length <= getLimit(section)}
                                    >
                                        +
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Badges;
