import React, { createContext, useContext, useState } from 'react';

const ItemNavbarContext = createContext();

const ItemNavbarProvider = ({ children, state }) => {
    const [isClicked, setIsClicked] = useState(false);

    return (
        <ItemNavbarContext.Provider value={{ state, isClicked, setIsClicked }}>
            {children}
        </ItemNavbarContext.Provider>
    );
};

export const useItemNavbarContext = () => useContext(ItemNavbarContext);
export default ItemNavbarProvider;
