import { useTranslation } from 'react-i18next';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';

const Breadcrumb = () => {
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const router = useRouter();

    return (
        <div className="mlc-breadcrumb">
            <span className="legend">{t('TA_BREADCRUMB_TITLE')}</span>
            <span className="mlc-items">
                <span className="mlc-item">
                    <a href={router.stateService.href('auth.dashboard.campaigns.home')}>
                        {t('TA_DASHBOARD_TITLE')}
                    </a>
                </span>
                {params.type === 'product' && (
                    <span className="mlc-item">
                        {t('CAMPAIGNS')} {t('TA_PRODUCT_TITLE')}
                    </span>
                )}
                {params.type === 'social' && (
                    <span className="mlc-item">
                        {t('CAMPAIGNS')} {t('TA_SOCIAL_TITLE')}
                    </span>
                )}
                {params.type === 'recruiting' && (
                    <span className="mlc-item">
                        {t('CAMPAIGNS')} {t('TA_RECRUITING_TITLE')}
                    </span>
                )}
                {params.type === 'survey' && (
                    <span className="mlc-item">
                        {t('CAMPAIGNS')} {t('TA_SURVEY_TITLE')}
                    </span>
                )}
            </span>
        </div>
    );
};

export default Breadcrumb;
