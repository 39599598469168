import React from 'react';
import { Select } from '../../Inputs/index';
import { orderBy } from 'lodash-es';
import useTreeHook from 'hooks/useTree';
import { useAdminTreeContext } from 'pages/Admin/providers/AdminTreeProvider';
import useRight from 'hooks/useRight';
import useWorkspace from 'hooks/useWorkspace';
import useUtils from 'hooks/useUtils';
import './styles/workspace-list.scss';

function WorkspaceList({ ...props }) {
    const { fullTree } = useAdminTreeContext();
    const { getOnlyCategory } = useTreeHook();
    const { hasRole } = useRight();
    const { forgeIconUrl } = useWorkspace();
    const { getFirstLetter } = useUtils();
    const optionRender = (option) => {
        return (
            <div className={'workspace-list-option'}>
                {!hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                    <div className="icon">
                        <img src={forgeIconUrl(option.data.icon_name)} alt={option.data.label} />
                    </div>
                ) : (
                    <div className="icon" style={{ backgroundColor: option.data.icon_color }}>
                        <div className="letter">{getFirstLetter(option.data.label)}</div>
                    </div>
                )}

                <div className="name">{option.data.label}</div>
            </div>
        );
    };
    return (
        <Select
            {...props}
            options={orderBy(getOnlyCategory(fullTree), ['name']).map((category) => {
                return {
                    label: category.name,
                    title: category.name,
                    options: orderBy(category.workspaces, ['name']).map((workspace) => {
                        return {
                            label: workspace.name,
                            value: workspace.id,
                            icon_name: workspace.icon_name,
                            icon_color: workspace.icon_color
                        };
                    })
                };
            })}
            optionRender={optionRender}
        />
    );
}

export default WorkspaceList;
