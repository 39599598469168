import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useQuery } from '@tanstack/react-query';
import { first } from 'lodash-es';
import useRight from '../../../hooks/useRight';
import limitTo from '../../../helpers/limitTo';
import usePostService from '../../../api/usePostService';
import Post from '../../Post/Post';

const Posts = ({ widget, options }) => {
    const { getStyleValueForKey, getFieldValueForKey, getWidgetStyle } = useWidgetComponent(
        widget,
        options
    );
    const { getPostsByWorkspace, getPostsByTag, getPosts } = usePostService();
    const { workspacePermissionCheck } = useRight();

    const slideOptions = () => {
        if (getStyleValueForKey('layoutMode') !== 'slider') {
            return {};
        }

        let swiperOptions = {
            spaceBetween: 20,
            navigation: true,
            pagination: { clickable: true }
        };

        if (getStyleValueForKey('autoRotate')) {
            swiperOptions.loop = true;
            swiperOptions.autoplay = {
                delay: getStyleValueForKey('autoRotateTiming') * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            };
        }

        return swiperOptions;
    };

    const { data: posts } = useQuery({
        queryKey: ['widget_post', widget.id],
        queryFn: (fromNotification = null) => {
            switch (getFieldValueForKey('displayMode')) {
                case 'latestByFeeds':
                    let feeds = getFieldValueForKey('feeds');

                    if (!feeds || feeds.length === 0) {
                        break;
                    }

                    let feed = first(
                        feeds.filter((feed) => {
                            return workspacePermissionCheck({ id: feed.value }, 'READ');
                        })
                    );

                    if (!feed) {
                        break;
                    }

                    if (fromNotification && fromNotification.workspace_id !== feed.value) {
                        break;
                    }

                    return getPostsByWorkspace(feed.value, 1).then((posts) => {
                        return limitTo(posts, getFieldValueForKey('limit'));
                    });

                    break;
                case 'latestBySharedTag':
                    let tag = first(getFieldValueForKey('tag'));

                    if (!tag) {
                        break;
                    }

                    return getPostsByTag(
                        tag.value,
                        1,
                        'default',
                        getFieldValueForKey('limit')
                    ).then((posts) => {
                        return limitTo(posts, getFieldValueForKey('limit'));
                    });

                    break;
                case 'latest':
                default:
                    return getPosts(1, null, 'springboard').then((posts) => {
                        return limitTo(posts, getFieldValueForKey('limit'));
                    });
            }
        }
    });

    const hasPosts = () => {
        return posts && posts.length > 0;
    };

    return (
        <div
            className={`posts ${getStyleValueForKey('layoutMode')}`}
            style={getWidgetStyle(widget)}>
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                <div className="text">{getFieldValueForKey('title')}</div>
            </div>

            {hasPosts() ? (
                <div className="listing">
                    <Swiper
                        slidesPerView={1}
                        {...slideOptions()}
                        modules={[Autoplay, Pagination, Navigation]}>
                        {posts.map((post) => {
                            return (
                                <SwiperSlide key={`post_${post.id}`}>
                                    <div ng-click="$ctrl.goToPost(post); $event.stopPropagation();">
                                        <Post
                                            className="post {{ post.post.alreadyLiked }}"
                                            post={post.post}
                                            alreadyLiked={post.post.alreadyLiked}
                                            download={post.post.download}
                                            tagId={post.post.tag_id}
                                            tags={post.post.tags}
                                            template={'stacked'}
                                            forceUnconfirmedStyle={true}></Post>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            ) : (
                <div className="empty" style={{ color: getStyleValueForKey('titleColor') }}>
                    Pas d'actualités pour le moment
                </div>
            )}
        </div>
    );
};

export default Posts;
