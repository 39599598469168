import React from 'react';
import NotificationsPanel from '../../../pages/Notifications/components/NotificationsPanel';
import useWidgetComponent from '../hooks/useWidgetComponent';

const Notifications = ({ widget, options }) => {
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);
    return (
        <div className="notifications">
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                {getFieldValueForKey('title')}
            </div>

            <div className="component">
                <NotificationsPanel limit={getFieldValueForKey('limit')} />
            </div>
        </div>
    );
};

export default Notifications;
