import React, { useMemo, useState } from 'react';
import { ModalFormSection } from 'components/Form/ModalForm';
import { Button } from 'antd';
import Labels from './partials/Labels';
import Options from './partials/Options';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash-es';
import useFormRowInputComponent from './hooks/useFormRowInputComponent';
import FileOptions from './partials/FileOptions';
import LongTextOptions from './partials/LongTextOptions';
import SelectOptions from './partials/SelectOptions';
import TitleOptions from './partials/TitleOptions';
import EmailOptions from './partials/EmailOptions';
import './styles/formInputRow.scss';

const FormInputRow = ({ input, index, handleDeleteRow }) => {
    const [edit, setEdit] = useState(!input?.id);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: input.uniqueId
    });
    const { watch, getValues } = useFormContext();
    const { isFieldType, workspaceFormsService } = useFormRowInputComponent(input);

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    };

    const name = useMemo(() => {
        const values = getValues();
        const label =
            !isEmpty(values) &&
            values?.workspace_form_inputs &&
            values?.workspace_form_inputs[input.uniqueId]
                ? values?.workspace_form_inputs[input.uniqueId]?.label
                : null;

        return label ? label : input.label;
    }, [watch(`workspace_form_inputs[${input.uniqueId}].label`), getValues, input]);

    const computeCondition = useMemo(() => {
        const values = getValues();
        if (isEmpty(values)) {
            return null;
        }
        if (!values.workspace_form_inputs) return null;
        const workspace_form_inputs = Object.values(values.workspace_form_inputs);

        if (workspace_form_inputs[index]?.input_condition?.id) {
            const input = workspace_form_inputs.filter(
                (field) => field.id === workspace_form_inputs[index].input_condition.id
            )[0];
            return `Visible si "${input.label}" est égal à "${workspace_form_inputs[index].input_condition.value_condition_as_array.join(' ou ')}"`;
        }
        return null;
    }, [watch(`workspace_form_inputs`)]);

    return (
        <div ref={setNodeRef} style={style} className={'input-row'}>
            <ModalFormSection inline>
                <div className="handle-sort" {...attributes} {...listeners}>
                    <div className="icon">
                        <i className="icon-navigation-menu-horizontal"></i>
                    </div>
                </div>
                <div className={'meta'}>
                    <div className="name">
                        <div>{name}</div>
                    </div>
                    <div className={'type_and_condition'}>
                        <div className="type">
                            {workspaceFormsService.getFieldNameByType(input.type)}
                        </div>
                        {computeCondition && <div className="condition">{computeCondition}</div>}
                    </div>
                </div>
                <Button
                    onClick={() => {
                        setEdit(!edit);
                    }}>
                    <i className="icon-pencil-1" aria-hidden="true"></i>
                </Button>
                <Button>
                    <i className="icon-common-file-double-1" aria-hidden="true"></i>
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteRow(input.uniqueId);
                    }}>
                    <i className="icon-bin-2" aria-hidden="true"></i>
                </Button>
            </ModalFormSection>
            {edit && (
                <ModalFormSection>
                    <Labels input={input} />
                    {(isFieldType('file') || isFieldType('files')) && <FileOptions input={input} />}
                    {isFieldType('long_text') && <LongTextOptions input={input} />}
                    {(isFieldType('select') ||
                        isFieldType('collection') ||
                        isFieldType('radio')) && <SelectOptions input={input} />}
                    {isFieldType('title') && <TitleOptions input={input} />}
                    {isFieldType('email') && <EmailOptions input={input} />}
                    <Options input={input} index={index} />
                </ModalFormSection>
            )}
        </div>
    );
};

export default FormInputRow;
