import React from 'react';
import { useModal } from 'providers/ModalContext';
import { UIView } from '@uirouter/react';

const Public = () => {
    const { hasModalOpened } = useModal();

    return (
        <div className="public">
            <div className="screen">
                <div className={`content scrollable ${hasModalOpened() ? 'has-modal-opened' : ''}`}>
                    <div className="view">
                        <UIView />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Public;
