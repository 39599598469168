import Modal from 'components/Modal/Modal';
import React, { useMemo } from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Input, Textarea, Select, ColorPicker, IconPicker } from 'components/Form/Inputs';
import { useModal } from 'providers/ModalContext';
import { SHAPES, TARGETS } from '../shareds';
import './styles/achievementFormModal.scss';
import Achievement from 'components/Achievement/Achievement';
import { isEmpty } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import useAchievementService from 'api/useAchievementService';

const AchievementFormModalContent = () => {
    const {
        contexts: {
            add_edit_achievement: { currentAchievement }
        },
        closeModal
    } = useModal();
    const { getAllRoutes } = useAchievementService();
    const methods = useForm();

    const { data: allRoutes } = useQuery({
        queryKey: ['allRoutes'],
        queryFn: () => getAllRoutes()
    });

    const achievementPreview = useMemo(() => {
        return isEmpty(methods.getValues()) ? currentAchievement : methods.getValues();
    }, [methods.watch()]);

    const onFinish = (values) => {};

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input name={'name'} label={'Nom du badge'} value={currentAchievement?.name} />
                    <Input
                        name={'description'}
                        label={'Description du badge'}
                        value={currentAchievement?.description}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <div className={'preview'}>
                        <div className={'achievement'}>
                            <Achievement item={achievementPreview} hasWatcher={true} />
                        </div>
                        <div className={'config'}>
                            <ColorPicker
                                name={'color'}
                                label={'Couleur'}
                                value={currentAchievement?.color}
                            />
                            <IconPicker
                                name={'icon'}
                                label={'Icône'}
                                value={currentAchievement?.icon}
                            />
                            <Select
                                label={'Cible'}
                                name={'shape'}
                                value={currentAchievement?.shape}
                                options={SHAPES}
                            />
                        </div>
                    </div>
                </ModalFormSection>
                <ModalFormSection>
                    <Select
                        label={'Cible'}
                        name={'target'}
                        value={currentAchievement?.target}
                        options={TARGETS}
                    />
                    <Input
                        name={'position'}
                        label={'Position'}
                        type={'number'}
                        value={currentAchievement?.position}
                    />
                    <Input
                        name={'points'}
                        label={'Récompense en points pour déblocage (facultatif)'}
                        type={'number'}
                        value={currentAchievement?.points}
                    />
                    <Select
                        label={'Hook sur les routes'}
                        name={'routes_name'}
                        mode={'multiple'}
                        value={currentAchievement?.routes_name}
                        options={
                            allRoutes
                                ? allRoutes['post'].map((route) => {
                                      return {
                                          value: route,
                                          label: route
                                      };
                                  })
                                : []
                        }
                    />
                    <Textarea
                        label={
                            "Condition (Requete SQL doit retourner une ligne si l'utilisateur a obtenu le badge)"
                        }
                        name={'condition'}
                        value={currentAchievement?.condition}
                    />
                    <Textarea
                        label={
                            'Progression (Requete SQL de progression, elle doit retourner 2 valeurs le total et le nombre max)'
                        }
                        name={'condition'}
                        value={currentAchievement?.progress}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_edit_achievement');
                }}
            />
        </ModalForm>
    );
};

const AchievementFormModal = () => {
    return (
        <Modal
            name={'add_edit_achievement'}
            title={'Ajouter  ou éditer un badge'}
            options={{
                hasPaddedContent: true,
                hasFlexContent: true
            }}>
            <AchievementFormModalContent />
        </Modal>
    );
};

export default AchievementFormModal;
