import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';

const Text = ({ widget, options }) => {
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);

    return (
        <div className="text">
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                {getFieldValueForKey('title')}
            </div>
            <div className="text" style={{ color: getStyleValueForKey('textColor') }}>
                {getFieldValueForKey('text')}
            </div>
        </div>
    );
};

export default Text;
