import { useTranslation } from 'react-i18next';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import Shared from './shared';
import { useRouter } from '@uirouter/react';

const Social = ({ campaign }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        closeCampaign,
        cloneCampaign,
        togglePreview,
        deleteCampaign,
        isRunning,
        isClosed,
        isDraft,
        totalShares,
        getTotalClicks
    } = useCampaignsCustomRender(campaign);

    const ROI = 0;
    const totalClicks = 0;

    const toggleSharers = () => console.log('in progress');
    const exportSocialShares = () => console.log('in progress');
    const isUniqueReward = () => console.log('in progress');

    return (
        <Shared campaign={campaign}>
            <div className={`informations ${campaign.type}`}>
                {campaign.networks && (
                    <div className="details">
                        <div className="social-networks">
                            {campaign.networks.map((socialNetwork, index) => (
                                <div key={index} className="social-network">
                                    <div className={`icon ${socialNetwork.name}`}>
                                        <i
                                            className={`fa fa-${socialNetwork.name}`}
                                            aria-hidden="true"></i>
                                    </div>
                                    <div className="shares">
                                        <i className="fa fa-share-alt" aria-hidden="true"></i>{' '}
                                        {socialNetwork.shares}
                                    </div>
                                    <div className="clicks">
                                        <i className="fa fa-mouse-pointer" aria-hidden="true"></i>{' '}
                                        {socialNetwork.views}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="stats">
                            <div className="stat shares not-pointer">
                                <div className="icon">
                                    <i className="fa fa-money" aria-hidden="true"></i>{' '}
                                </div>
                                <div className="text">
                                    {campaign.total_rewards} {t('TA_CAMPAIGN_POINTS_GIVEN')}
                                </div>
                            </div>
                            <div className="stat shares" onClick={toggleSharers}>
                                <div className="icon">
                                    <i className="fa fa-share-alt" aria-hidden="true"></i>{' '}
                                </div>
                                <div className="text">
                                    {totalShares()} {t('TA_CAMPAIGN_SOCIAL_SHARES')}
                                </div>
                            </div>
                            <div className="stat clicks not-pointer">
                                <div className="icon">
                                    <i className="fa fa-mouse-pointer" aria-hidden="true"></i>{' '}
                                </div>
                                <div className="text">
                                    {getTotalClicks()} {t('TA_CAMPAIGN_SOCIAL_CLICKS')}
                                </div>
                            </div>
                            <div className="stat shares not-pointer">
                                <div className="icon">
                                    <i className="fa fa-money" aria-hidden="true"></i>{' '}
                                </div>
                                <div className="text">ROI : {ROI}</div>
                            </div>
                            <div className="stat shares">
                                <div className="icon not-pointer">
                                    <i className="fa fa-download" aria-hidden="true"></i>{' '}
                                </div>
                                <div
                                    className="text"
                                    onClick={
                                        campaign.users.length > 0 ? exportSocialShares : undefined
                                    }
                                    style={{
                                        cursor: campaign.users.length > 0 ? 'pointer' : 'default'
                                    }}>
                                    {campaign.users.length > 0
                                        ? t('CAMPAIGN_SOCIAL_DOWNLOAD_CLICK_STATS')
                                        : t('CAMPAIGN_SOCIAL_NO_CLICK_STATS')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="actions">
                    {campaign.reward > 0 && isUniqueReward() && (
                        <div className="reward unique">
                            {campaign.reward} {t('POINTS')}
                        </div>
                    )}

                    <div className="buttons">
                        {(isDraft || isRunning) && (
                            <>
                                <button className="button grey" onClick={closeCampaign}>
                                    {t('TA_CAMPAIGN_CLOSE')}
                                </button>
                                <a
                                    className="button grey"
                                    href={router.stateService.href(
                                        'auth.dashboard.campaigns.create',
                                        {
                                            type: campaign.type,
                                            id: campaign.id
                                        }
                                    )}>
                                    {t('TA_CAMPAIGN_EDIT')}
                                </a>
                            </>
                        )}
                        <button className="button grey" onClick={cloneCampaign}>
                            {t('TA_CAMPAIGN_DUPLICATE')}
                        </button>
                        <button className="button grey" onClick={() => togglePreview(campaign)}>
                            {t('TA_CAMPAIGN_PREVIEW')}
                        </button>
                        {!isRunning && (
                            <button className="button red" onClick={deleteCampaign}>
                                {t('TA_CAMPAIGN_DELETE')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Shared>
    );
};

export default Social;
