import React from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import useUtils from 'hooks/useUtils';
import useWorkspace from 'hooks/useWorkspace';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const Workspaces = ({ result, searcheable, query }) => {
    const { t } = useTranslation();
    const { getFirstLetter } = useUtils();
    const { hasRole, workspacePermissionCheck } = useRight();
    const { forgeIconUrl } = useWorkspace();
    const { getResultWithQueryHighlight, goToComposer } = useSearchBoxResultComponent(query);

    const isAllowToWrite = (result, searcheable) => {
        switch (searcheable) {
            case 'categories':
                return (
                    result.workspaces &&
                    result.workspaces.some((workspace) => {
                        return workspacePermissionCheck({ id: workspace.id }, 'WRITE');
                    })
                );
            case 'workspaces':
                return workspacePermissionCheck(
                    { id: result.workspace_data.workspace.id },
                    'WRITE'
                );
            case 'folders':
                return workspacePermissionCheck({ id: result.workspace_id }, 'WRITE');
        }
    };

    return (
        <>
            {hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                <div
                    className="icon"
                    style={{ backgroundColor: result.workspace_data.workspace.icon_color }}>
                    <div className="letter">
                        {getFirstLetter(result.workspace_data.workspace.name)}
                    </div>
                </div>
            ) : (
                <div className="icon">
                    <img
                        src={forgeIconUrl(result.workspace_data.workspace.icon_name)}
                        alt="Workspace Icon"
                    />
                </div>
            )}

            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.workspace_data.workspace.name)
                    }}
                />
            </div>

            {isAllowToWrite(result, searcheable) && (
                <div className="actions">
                    <div
                        className="action primary"
                        onClick={(event) => goToComposer(result, searcheable, event)}>
                        {t('POST_TITLE')} <i className="icon-arrow-left"></i>
                    </div>
                </div>
            )}
        </>
    );
};

export default Workspaces;
