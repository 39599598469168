import React from 'react';

const ModalFormInputTable = ({ title, headers = [], groups = [], ...props }) => {
    return (
        <div className="switch-table" {...props}>
            <div className="title">{title}</div>
            <div className="row heading">
                <div className="label"></div>
                {headers.map((header, index) => {
                    return (
                        <div
                            className={header.class ? header.class : `read`}
                            key={`header_${index}`}>
                            {header.label}
                        </div>
                    );
                })}
            </div>
            {groups.map((group, groupIndex) => {
                return (
                    <div className="group" key={`group_${groupIndex}`}>
                        <div className="children">
                            {group.children.map((child, index) => {
                                if (!child) return;
                                return (
                                    <div
                                        className={`row ${index % 2 === 0 ? 'grey' : ''}`}
                                        key={`child_${groupIndex}_${index}`}>
                                        <div className="label">{child.label}</div>
                                        {child.inputs.map((input, inputIndex) => {
                                            return (
                                                <div
                                                    className={input.class ? input.class : `read`}
                                                    key={`input_${groupIndex}_${index}_${inputIndex}`}>
                                                    {input.input}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ModalFormInputTable;
