import BaseWidgets from 'components/Widgets/services/BaseWidgets';

export default class WidgetsService extends BaseWidgets {
    constructor(options = null) {
        super({
            type: 'homepage',
            defaultPosition: 3
        });

        this.position = {
            left: 1,
            right: 2,
            mobile: 3
        };

        this.prepareWidgets();
    }

    prepareWidgets() {
        this.widgets = [
            {
                name: 'Bloc de début de section',
                type: 'section-start'
            },
            {
                name: 'Bloc de fin de section',
                type: 'section-end'
            },
            {
                name: "Bloc d'action",
                type: 'action'
            },
            {
                name: 'Bloc de raccourci',
                type: 'shortcut'
            },
            {
                name: 'Bloc de liste de raccourcis',
                type: 'shortcuts'
            },
            {
                name: 'Bloc de filtrage des flux',
                type: 'tree'
            },
            {
                name: 'Bloc de catégorie',
                type: 'category'
            },
            {
                name: 'Bloc de recherche',
                type: 'search'
            },
            {
                name: 'Bloc de campagnes',
                type: 'campaigns'
            },
            {
                name: "Bloc d'articles du blog",
                type: 'blogArticles'
            },
            {
                name: "Bloc d'actualités",
                type: 'posts'
            },
            {
                name: 'Bloc des missions',
                type: 'missions'
            },
            {
                name: 'Bloc de notifications',
                type: 'notifications'
            },
            {
                name: 'Bloc de discussions',
                type: 'chats'
            },
            {
                name: 'Bloc des tags',
                type: 'tags'
            },
            {
                name: "Bloc d'ambassadeur",
                type: 'ambassador'
            },
            {
                name: 'Bloc de titre',
                type: 'title'
            },
            {
                name: 'Bloc de texte',
                type: 'text'
            },
            {
                name: 'Bloc de séparation',
                type: 'separator'
            },
            {
                name: "Bloc d'image",
                type: 'image'
            },
            {
                name: 'Bloc RSS',
                type: 'rss'
            },
            {
                name: 'Bloc demande de retour',
                type: 'feedback'
            },
            {
                name: 'Bloc espace ressource',
                type: 'sharedDocument'
            },
            {
                name: "Bloc d'intégration de sites",
                type: 'iframe'
            },
            {
                name: 'Bloc de calendrier',
                type: 'calendar'
            }
        ];

        this.widgets = this.widgets.map((widget) => {
            return {
                ...widget,
                fields: this.getFieldsForWidget(widget),
                styling: this.getStyleForWidget(widget)
            };
        });
    }

    getFieldsForWidget(widget) {
        let fields = [
            {
                name: 'Titre du bloc',
                key: 'title',
                value: widget.name
            },
            {
                name: "Groupes d'utilisateurs",
                key: 'organizationUnits',
                special: 'organizationUnits'
            },
            {
                name: "Groupes d'utilisateurs exclus",
                key: 'excludedOrganizationUnits',
                special: 'organizationUnits'
            }
        ];

        let shortcutTypeValues = [
            {
                name: 'Raccourci vers un data feed (par défaut)',
                value: 'toFeed'
            },
            {
                name: 'Raccourci vers une catégorie',
                value: 'toCategory'
            },
            {
                name: 'Raccourci vers un tag partagé',
                value: 'toSharedTag'
            },
            {
                name: 'Raccourci vers un type de campagne',
                value: 'toCampaignType'
            },
            {
                name: 'Raccourci vers les missions',
                value: 'toMissions'
            },
            {
                name: 'Raccourci vers un lien',
                value: 'toLink'
            },
            {
                name: 'Raccourci vers une application Android (app package name)',
                value: 'toAndroidPackageName'
            },
            {
                name: 'Raccourci vers la gestion des abonnements',
                value: 'toSubscriptionManagement'
            },
            {
                name: "Raccourci vers la suggestion d'actualités",
                value: 'toTopAmbassador'
            }
        ];

        /*if (this.Config.isApp(['dev', 'cace'])) {
            shortcutTypeValues.push({
                name: 'Raccourci vers suivi budgétaire des Présidents CL',
                value: 'toCustomCace',
            });
        }*/

        switch (widget.type) {
            case 'action':
                return fields.concat([
                    {
                        name: "Type d'action",
                        key: 'actionType',
                        values: [
                            {
                                name: 'Poster dans un data feed (par défaut)',
                                value: 'postToFeed'
                            },
                            {
                                name: 'Poster dans une catégorie',
                                value: 'postToCategory'
                            },
                            {
                                name: 'Poster une actualité',
                                value: 'postToAll'
                            }
                        ],
                        value: 'postToFeed',
                        required: true
                    },
                    {
                        name: 'Choisir le ou les data feeds',
                        key: 'feeds',
                        special: 'feeds',
                        multiple: true,
                        condition: {
                            key: 'actionType',
                            operator: '=',
                            value: 'postToFeed'
                        }
                    },
                    {
                        name: 'Choisir la catégorie',
                        key: 'category',
                        special: 'categories',
                        condition: {
                            key: 'actionType',
                            operator: '=',
                            value: 'postToCategory'
                        }
                    },
                    {
                        name: "Type d'affichage",
                        key: 'displayType',
                        values: [
                            {
                                name: 'Icône et texte (par défaut)',
                                value: 'displayIconText'
                            },
                            {
                                name: 'Image',
                                value: 'displayImage'
                            }
                        ],
                        value: 'displayIconText',
                        required: true
                    },
                    {
                        name: 'Icône',
                        key: 'icon',
                        special: 'icons',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayIconText'
                        }
                    },
                    {
                        name: 'Image',
                        key: 'image',
                        special: 'image',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayImage'
                        }
                    }
                ]);
            case 'rss':
                return fields.concat([
                    {
                        name: 'Lien du flux RSS',
                        key: 'link'
                    },
                    {
                        name: 'Remplacer le titre du bloc par le titre du flux',
                        key: 'showTitle',
                        values: [
                            {
                                name: 'Oui (par défaut)',
                                value: true
                            },
                            {
                                name: 'Non',
                                value: false
                            }
                        ],
                        value: true,
                        required: true
                    },
                    {
                        name: 'Nombre de lien par page',
                        key: 'offset',
                        min: 1,
                        max: 10,
                        step: 1,
                        unit: 'lien(s)',
                        value: 4
                    },
                    {
                        name: "Afficher le contenu de l'article",
                        key: 'showContent',
                        values: [
                            {
                                name: 'Oui',
                                value: true
                            },
                            {
                                name: 'Non (par défaut)',
                                value: false
                            }
                        ],
                        value: false,
                        required: true
                    },
                    {
                        name: 'Afficher la date',
                        key: 'showDate',
                        values: [
                            {
                                name: 'Oui (par défaut)',
                                value: true
                            },
                            {
                                name: 'Non',
                                value: false
                            }
                        ],
                        value: true,
                        required: true
                    },
                    {
                        name: 'Rafraîchir automatiquement le widget',
                        key: 'autoRefresh',
                        values: [
                            {
                                name: 'Oui (par défaut)',
                                value: true
                            },
                            {
                                name: 'Non',
                                value: false
                            }
                        ],
                        value: true,
                        required: true
                    },
                    {
                        name: 'Intervalle pour le rafraîchissement auto.',
                        key: 'autoRefreshInterval',
                        min: 5,
                        max: 120,
                        step: 5,
                        unit: 'minute(s)',
                        value: 30,
                        condition: {
                            key: 'autoRefresh',
                            operator: '=',
                            value: true
                        }
                    }
                ]);
            case 'iframe':
                return fields.concat([
                    {
                        name: 'Lien de la page à intégrer',
                        key: 'link'
                    },
                    {
                        name: 'Rafraîchir automatiquement le widget',
                        key: 'autoRefresh',
                        values: [
                            {
                                name: 'Oui (par défaut)',
                                value: true
                            },
                            {
                                name: 'Non',
                                value: false
                            }
                        ],
                        value: true,
                        required: true
                    },
                    {
                        name: 'Intervalle pour le rafraîchissement auto.',
                        key: 'autoRefreshInterval',
                        min: 5,
                        max: 120,
                        step: 5,
                        unit: 'minute(s)',
                        value: 5,
                        condition: {
                            key: 'autoRefresh',
                            operator: '=',
                            value: true
                        }
                    }
                ]);
            case 'tree':
                return fields.concat([
                    {
                        name: 'Masquer la flèche quand un filre est appliqué',
                        key: 'hideStroke',
                        values: [
                            {
                                name: 'Oui',
                                value: true
                            },
                            {
                                name: 'Non (par défaut)',
                                value: false
                            }
                        ],
                        value: false,
                        required: true
                    }
                ]);
            case 'calendar':
                return fields.concat([
                    {
                        name: 'Récupérer les événements depuis',
                        key: 'dataFrom',
                        values: [
                            {
                                name: 'Tous les data feeds calendaires (par défaut)',
                                value: 'all'
                            },
                            {
                                name: 'Restreindre à une ou plusieurs catégories',
                                value: 'categories'
                            },
                            {
                                name: 'Restreindre à un ou plusieurs data feeds',
                                value: 'feeds'
                            }
                        ],
                        value: 'all',
                        required: true
                    },
                    {
                        name: 'Choisir la ou les catégorie(s)',
                        key: 'categories',
                        special: 'categories',
                        multiple: true,
                        condition: {
                            key: 'dataFrom',
                            operator: '=',
                            value: 'categories'
                        }
                    },
                    {
                        name: 'Choisir le ou les data feed(s)',
                        key: 'feeds',
                        special: 'feeds',
                        multiple: true,
                        condition: {
                            key: 'dataFrom',
                            operator: '=',
                            value: 'feeds'
                        }
                    }
                    // {
                    //     name: 'Jour(s) désactivé(s)',
                    //     key: 'disabledDays',
                    //     values: [
                    //         {
                    //             name: 'Lundi',
                    //             value: '1',
                    //         },
                    //         {
                    //             name: 'Mardi',
                    //             value: '2',
                    //         },
                    //         {
                    //             name: 'Mercredi',
                    //             value: '3',
                    //         },
                    //         {
                    //             name: 'Jeudi',
                    //             value: '4',
                    //         },
                    //         {
                    //             name: 'Vendredi',
                    //             value: '5',
                    //         },
                    //         {
                    //             name: 'Samedi',
                    //             value: '6',
                    //         },
                    //         {
                    //             name: 'Dimanche',
                    //             value: '0',
                    //         },
                    //     ],
                    //     multiple: true,
                    // },
                ]);
            case 'shortcut':
                return fields.concat([
                    {
                        name: 'Type de raccourci',
                        key: 'shortcutType',
                        values: shortcutTypeValues,
                        value: 'toFeed',
                        required: true
                    },
                    {
                        name: 'Choisir le data feed',
                        key: 'feed',
                        special: 'feeds',
                        multiple: true,
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toFeed'
                        }
                    },
                    {
                        name: 'Choisir la catégorie',
                        key: 'category',
                        special: 'categories',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toCategory'
                        }
                    },
                    {
                        name: 'Choisir le tag partagé',
                        key: 'tag',
                        special: 'tags',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toSharedTag'
                        }
                    },
                    {
                        name: 'Choisir la cible du lien',
                        key: 'subscriptionTarget',
                        values: [
                            {
                                name: 'Première catégorie ouverte (par défaut)',
                                value: 'first-opened'
                            },
                            {
                                name: 'Toutes les catégories dépliées',
                                value: 'all-opened'
                            },
                            {
                                name: 'Toutes les catégories repliées',
                                value: 'all-closed'
                            },
                            {
                                name: 'Avoir une catégorie ouverte',
                                value: 'one-opened'
                            }
                        ],
                        value: 'first-opened',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toSubscriptionManagement'
                        }
                    },
                    {
                        name: 'Choisir la catégorie ouverte',
                        key: 'subscriptionCategoryTarget',
                        special: 'categories',
                        multiple: true,
                        condition: {
                            key: 'subscriptionTarget',
                            operator: '=',
                            value: 'one-opened'
                        }
                    },
                    {
                        name: "Mode d'affichage pour les actualités",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Affichage flux (par défaut)',
                                value: 'posts'
                            },
                            {
                                name: 'Affichage calendaire',
                                value: 'calendar'
                            },
                            {
                                name: 'Affichage documentaire',
                                value: 'folders'
                            }
                        ],
                        value: 'posts',
                        required: true,
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toFeed|toCategory'
                        }
                    },
                    {
                        name: 'Choisir le type de campagne',
                        key: 'campaignType',
                        values: [
                            {
                                name: 'TA_PRODUCT_TITLE',
                                value: 'product'
                            },
                            {
                                name: 'TA_SOCIAL_TITLE',
                                value: 'social'
                            },
                            {
                                name: 'TA_RECRUITING_TITLE',
                                value: 'recruiting'
                            },
                            {
                                name: 'TA_SURVEY_TITLE',
                                value: 'survey'
                            }
                        ],
                        value: 'product',
                        required: true,
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toCampaignType'
                        }
                    },
                    {
                        name: 'Lien',
                        key: 'link',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toLink'
                        }
                    },
                    {
                        name: 'Android Package Name',
                        key: 'androidPackageName',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toAndroidPackageName'
                        }
                    },
                    {
                        name: 'Lien Google Play',
                        key: 'playStoreLink',
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toAndroidPackageName'
                        }
                    },
                    {
                        name: "Mode d'ouverture du lien",
                        key: 'linkTarget',
                        values: [
                            {
                                name: 'Ouvrir dans une pop-up',
                                value: '_popup'
                            },
                            {
                                name: 'Ouvrir dans le même onglet',
                                value: '_self'
                            },
                            {
                                name: 'Ouvrir dans un nouvel onglet',
                                value: '_blank'
                            }
                        ],
                        value: '_blank',
                        required: true,
                        condition: {
                            key: 'shortcutType',
                            operator: '=',
                            value: 'toLink'
                        }
                    },
                    {
                        name: 'Titre de la pop-up',
                        key: 'popupTitle',
                        condition: {
                            key: 'linkTarget',
                            operator: '=',
                            value: '_popup'
                        }
                    },
                    {
                        name: "Type d'affichage",
                        key: 'displayType',
                        values: [
                            {
                                name: 'Icône et texte (par défaut)',
                                value: 'displayIconText'
                            },
                            {
                                name: 'Image',
                                value: 'displayImage'
                            }
                        ],
                        value: 'displayIconText',
                        required: true
                    },
                    {
                        name: 'Icône',
                        key: 'icon',
                        special: 'icons',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayIconText'
                        }
                    },
                    {
                        name: 'Image',
                        key: 'image',
                        special: 'image',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayImage'
                        }
                    }
                ]);
            case 'shortcuts':
                return fields.concat([
                    {
                        name: 'Liste des raccourcis',
                        key: 'shortcuts',
                        special: 'repeater',
                        fields: [
                            {
                                name: 'Titre du raccourci',
                                key: 'title',
                                value: 'Titre du raccourci'
                            },
                            {
                                name: 'Type de raccourci',
                                key: 'shortcutType',
                                values: shortcutTypeValues,
                                value: 'toFeed',
                                required: true
                            },
                            {
                                name: "Groupes d'utilisateurs",
                                key: 'organizationUnits',
                                special: 'organizationUnits'
                            },
                            {
                                name: "Groupes d'utilisateurs exclus",
                                key: 'excludedOrganizationUnits',
                                special: 'organizationUnits'
                            },
                            {
                                name: 'Choisir le data feed',
                                key: 'feed',
                                special: 'feeds',
                                multiple: true,
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toFeed'
                                }
                            },
                            {
                                name: 'Choisir la catégorie',
                                key: 'category',
                                special: 'categories',
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toCategory'
                                }
                            },
                            {
                                name: 'Choisir le tag partagé',
                                key: 'tag',
                                special: 'tags',
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toSharedTag'
                                }
                            },
                            {
                                name: "Mode d'affichage pour les actualités",
                                key: 'displayMode',
                                values: [
                                    {
                                        name: 'Affichage flux (par défaut)',
                                        value: 'posts'
                                    },
                                    {
                                        name: 'Affichage calendaire',
                                        value: 'calendar'
                                    },
                                    {
                                        name: 'Affichage documentaire',
                                        value: 'folders'
                                    }
                                ],
                                value: 'posts',
                                required: true,
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toFeed|toCategory'
                                }
                            },
                            {
                                name: 'Choisir le type de campagne',
                                key: 'campaignType',
                                values: [
                                    {
                                        name: 'TA_PRODUCT_TITLE',
                                        value: 'product'
                                    },
                                    {
                                        name: 'TA_SOCIAL_TITLE',
                                        value: 'social'
                                    },
                                    {
                                        name: 'TA_RECRUITING_TITLE',
                                        value: 'recruiting'
                                    },
                                    {
                                        name: 'TA_SURVEY_TITLE',
                                        value: 'survey'
                                    }
                                ],
                                value: 'product',
                                required: true,
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toCampaignType'
                                }
                            },
                            {
                                name: 'Lien',
                                key: 'link',
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toLink'
                                }
                            },
                            {
                                name: "Mode d'ouverture du lien",
                                key: 'linkTarget',
                                values: [
                                    {
                                        name: 'Ouvrir dans un nouvel onglet (par défaut)',
                                        value: '_blank'
                                    },
                                    {
                                        name: 'Ouvrir dans le même onglet',
                                        value: '_self'
                                    }
                                ],
                                value: '_blank',
                                required: true,
                                condition: {
                                    key: 'shortcutType',
                                    operator: '=',
                                    value: 'toLink'
                                }
                            },
                            {
                                name: "Type d'affichage",
                                key: 'displayType',
                                values: [
                                    {
                                        name: 'Première lettre du titre et texte (par défaut)',
                                        value: 'displayFirstLetterText'
                                    },
                                    {
                                        name: 'Icône et texte',
                                        value: 'displayIconText'
                                    }
                                ],
                                value: 'displayFirstLetterText',
                                required: true,
                                condition: {
                                    key: 'shortcutType',
                                    operator: '!=',
                                    value: 'toSharedTag'
                                }
                            },
                            {
                                name: 'Icône',
                                key: 'icon',
                                special: 'icons',
                                condition: {
                                    key: 'displayType',
                                    operator: '=',
                                    value: 'displayIconText'
                                }
                            },
                            {
                                name: "Couleur de fond de l'icône",
                                key: 'iconBgColor',
                                special: 'colors'
                            },
                            {
                                name: "Couleur de l'icône ou de la lettre",
                                key: 'iconColor',
                                special: 'colors'
                            }
                        ]
                    }
                ]);
            case 'campaigns':
                return fields.concat([
                    {
                        name: "Mode d'affichage",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Dernières campagnes (par défaut)',
                                value: 'latest'
                            },
                            {
                                name: 'Nombre de campagnes en cours',
                                value: 'latestByCount'
                            },
                            {
                                name: 'Campagnes sélectionnées',
                                value: 'selectedCampaigns'
                            }
                        ],
                        value: 'latest',
                        required: true
                    },
                    {
                        name: 'Filtrer par type',
                        key: 'campaignType',
                        values: [
                            {
                                name: 'Tous les types',
                                value: 'all'
                            },
                            {
                                name: 'TA_PRODUCT_TITLE',
                                value: 'product'
                            },
                            {
                                name: 'TA_SOCIAL_TITLE',
                                value: 'social'
                            },
                            {
                                name: 'TA_RECRUITING_TITLE',
                                value: 'recruiting'
                            },
                            {
                                name: 'TA_SURVEY_TITLE',
                                value: 'survey'
                            }
                        ],
                        value: 'all',
                        required: true,
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'latest'
                        }
                    },
                    {
                        name: 'Nombre de campagnes à afficher',
                        key: 'limit',
                        min: 1,
                        max: 5,
                        step: 1,
                        unit: 'campagne(s)',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'latest'
                        }
                    },
                    {
                        name: 'Choisir les campagnes à afficher',
                        key: 'campaigns',
                        special: 'campaigns',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'selectedCampaigns'
                        }
                    }
                ]);
            case 'blogArticles':
                return fields.concat([
                    {
                        name: "Mode d'affichage",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Derniers articles publiés (par défaut)',
                                value: 'latest'
                            },
                            {
                                name: 'Articles sélectionnés',
                                value: 'selectedBlogArticles'
                            },
                            {
                                name: 'Articles par catégorie',
                                value: 'categoryBlogArticles'
                            }
                        ],
                        value: 'latest',
                        required: true
                    },
                    {
                        name: "Type d'affichage",
                        key: 'displayType',
                        values: [
                            {
                                name: 'Petit format (par défaut)',
                                value: 'small'
                            },
                            {
                                name: 'Grand format',
                                value: 'big'
                            }
                        ],
                        value: 'small',
                        required: true
                    },
                    {
                        name: "Choisir la catégorie d'articles à afficher",
                        key: 'categoryBlogArticle',
                        special: 'categoryBlogArticles',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'categoryBlogArticles'
                        }
                    },
                    {
                        name: "Nombre d'articles à afficher",
                        key: 'limit',
                        min: 1,
                        max: 5,
                        step: 1,
                        unit: 'blogArticle(s)',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'latest|categoryBlogArticles'
                        }
                    },
                    {
                        name: 'Choisir les articles à afficher',
                        key: 'blogArticles',
                        special: 'blogArticles',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'selectedBlogArticles'
                        }
                    }
                ]);
            case 'posts':
                return fields.concat([
                    {
                        name: "Mode d'affichage",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Dernières actualités (par défaut)',
                                value: 'latest'
                            },
                            {
                                name: "Dernières actualités d'un data feed",
                                value: 'latestByFeeds'
                            },
                            {
                                name: "Dernières actualités d'un tag partagé",
                                value: 'latestBySharedTag'
                            }
                        ],
                        value: 'latest',
                        required: true
                    },
                    {
                        name: 'Choisir le ou les data feed(s)',
                        key: 'feeds',
                        special: 'feeds',
                        multiple: true,
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'latestByFeeds'
                        }
                    },
                    {
                        name: 'Choisir le tag partagé',
                        key: 'tag',
                        special: 'tags',
                        condition: {
                            key: 'displayMode',
                            operator: '=',
                            value: 'latestBySharedTag'
                        }
                    },
                    {
                        name: "Nombre d'actualités à afficher",
                        key: 'limit',
                        min: 1,
                        max: 5,
                        step: 1,
                        unit: 'actualité(s)'
                    },
                    {
                        name: "Mode d'ouverture",
                        key: 'openMode',
                        values: [
                            {
                                name: "Naviguer vers l'actualité (par défaut)",
                                value: 'link'
                            },
                            {
                                name: "Ouvrir l'actualité dans une pop-up",
                                value: 'popup'
                            }
                        ],
                        value: 'link',
                        required: true
                    }
                ]);
            case 'notifications':
            case 'chats':
                return fields.concat([
                    {
                        name: "Nombre d'élements à afficher",
                        key: 'limit',
                        min: 3,
                        max: 9,
                        step: 3,
                        unit: 'élement(s)'
                    }
                ]);
            case 'text':
                return fields.concat([
                    {
                        name: 'Texte à afficher',
                        key: 'text'
                    }
                ]);
            case 'image':
                return fields.concat([
                    {
                        name: 'Image à afficher',
                        key: 'image',
                        special: 'image'
                    },
                    {
                        name: 'Cacher le titre du bloc',
                        key: 'hideTitle',
                        values: [
                            {
                                name: 'Oui (par défaut)',
                                value: true
                            },
                            {
                                name: 'Non',
                                value: false
                            }
                        ],
                        value: true,
                        required: true
                    }
                ]);
            case 'ambassador':
                return fields.concat([
                    {
                        name: "Choisir l'ambassadeur à mettre en avant",
                        key: 'ambassador',
                        special: 'ambassador'
                    }
                ]);
            case 'missions':
                return fields.concat([
                    {
                        name: "Type d'affichage",
                        key: 'displayType',
                        values: [
                            {
                                name: 'Icône (par défaut)',
                                value: 'displayIcon'
                            },
                            {
                                name: 'Image',
                                value: 'displayImage'
                            }
                        ],
                        value: 'displayIcon',
                        required: true
                    },
                    {
                        name: 'Icône',
                        key: 'icon',
                        special: 'icons',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayIcon'
                        }
                    },
                    {
                        name: 'Image',
                        key: 'image',
                        special: 'image',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayImage'
                        }
                    }
                ]);
            case 'sharedDocument':
                return fields.concat([
                    {
                        name: "Type d'affichage",
                        key: 'displayType',
                        values: [
                            {
                                name: 'Icône (par défaut)',
                                value: 'displayIcon'
                            },
                            {
                                name: 'Image',
                                value: 'displayImage'
                            }
                        ],
                        value: 'displayIcon',
                        required: true
                    },
                    {
                        name: 'Icône',
                        key: 'icon',
                        special: 'icons',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayIcon'
                        }
                    },
                    {
                        name: 'Image',
                        key: 'image',
                        special: 'image',
                        condition: {
                            key: 'displayType',
                            operator: '=',
                            value: 'displayImage'
                        }
                    },
                    {
                        name: 'Choisir la catégorie',
                        key: 'category',
                        special: 'sharedDocumentCategories',
                        multiple: false
                    }
                ]);
            case 'tags':
                return fields.concat([
                    {
                        name: 'Type des tags',
                        key: 'tagsType',
                        values: [
                            {
                                name: 'Tags personnels (par défaut)',
                                value: 'personal'
                            },
                            {
                                name: 'Tags partagés',
                                value: 'shared'
                            },
                            {
                                name: 'Tags essentiels',
                                value: 'essential'
                            }
                        ],
                        value: 'personal',
                        required: true
                    }
                ]);
            case 'category':
                return fields.concat([
                    {
                        name: 'Choisir la catégorie',
                        key: 'category',
                        special: 'categories'
                    }
                ]);
            case 'section-start':
            case 'section-end':
                return fields;
            default:
                return fields;
        }
    }

    getStyleForWidget(widget) {
        let styling = [
            {
                name: "Taille d'affichage",
                key: 'size',
                values: [
                    {
                        name: 'Pleine largeur (par défaut)',
                        value: 'full'
                    }
                ],
                value: 'full',
                required: true
            },
            {
                name: 'Marge en haut',
                key: 'marginTopSize',
                migrateFromKey: 'marginTopBottomSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Marge en bas',
                key: 'marginBottomSize',
                migrateFromKey: 'marginTopBottomSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Marge à gauche',
                key: 'marginLeftSize',
                migrateFromKey: 'marginLeftRightSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Marge à droite',
                key: 'marginRightSize',
                migrateFromKey: 'marginLeftRightSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Taille de la bordure',
                key: 'borderSize',
                min: 0,
                max: 4,
                step: 1,
                unit: 'px',
                value: 0
            },
            {
                name: 'Couleur de la bordure',
                key: 'borderColor',
                special: 'colors',
                condition: {
                    key: 'borderSize',
                    operator: '!=',
                    value: 0
                }
            },
            {
                name: "Personnaliser l'arrondi du widget",
                key: 'customRoundSize',
                values: [
                    {
                        name: 'Non (par défaut)',
                        value: false
                    },
                    {
                        name: 'Oui',
                        value: true
                    }
                ],
                value: false,
                required: true
            },
            {
                name: "Taille de l'arrondi",
                key: 'roundSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 0,
                condition: {
                    key: 'customRoundSize',
                    operator: '=',
                    value: true
                }
            },
            {
                name: 'Définir un dégradé comme couleur de fond',
                key: 'bgGradient',
                values: [
                    {
                        name: 'Non (par défaut)',
                        value: false
                    },
                    {
                        name: 'Oui',
                        value: true
                    }
                ],
                value: false,
                required: true
            },
            {
                name: 'Couleur de fond',
                key: 'bgColor',
                special: 'colors',
                condition: {
                    key: 'bgGradient',
                    operator: '!=',
                    value: true
                }
            },
            {
                name: 'Type de dégradé',
                key: 'bgGradientType',
                values: [
                    {
                        name: 'Dégradé linéaire (par défaut)',
                        value: 'linear'
                    },
                    {
                        name: 'Dégradé radial',
                        value: 'radial'
                    }
                ],
                value: 'linear',
                required: true,
                condition: {
                    key: 'bgGradient',
                    operator: '=',
                    value: true
                }
            },
            {
                name: 'Couleur de départ du dégradé',
                key: 'bgGradientStartColor',
                special: 'colors',
                condition: {
                    key: 'bgGradient',
                    operator: '=',
                    value: true
                }
            },
            {
                name: 'Couleur de fin du dégradé',
                key: 'bgGradientEndColor',
                special: 'colors',
                condition: {
                    key: 'bgGradient',
                    operator: '=',
                    value: true
                }
            },
            {
                name: 'Direction du dégradé',
                key: 'bgGradientAngle',
                min: 0,
                max: 360,
                step: 10,
                unit: '°',
                value: 180,
                conditions: [
                    {
                        key: 'bgGradient',
                        operator: '=',
                        value: true
                    },
                    {
                        key: 'bgGradientType',
                        operator: '=',
                        value: 'linear'
                    }
                ]
            },
            {
                name: 'Couleur du titre',
                key: 'titleColor',
                special: 'colors'
            }
        ];

        switch (widget.type) {
            case 'action':
            case 'shortcut':
            case 'missions':
            case 'sharedDocument':
                let actionSize = styling.find((style) => {
                    return style.key === 'size';
                });

                if (!!actionSize) {
                    actionSize.values = actionSize.values.concat([
                        {
                            name: 'Un demi',
                            value: 'one-half'
                        },
                        {
                            name: 'Deux tiers',
                            value: 'two-third'
                        },
                        {
                            name: 'Un tier',
                            value: 'one-third'
                        }
                    ]);
                }

                return styling.concat([
                    {
                        name: 'Alignement du texte',
                        key: 'textAlign',
                        values: [
                            {
                                name: 'À gauche',
                                value: 'text-left'
                            },
                            {
                                name: 'Au centre',
                                value: 'text-center'
                            },
                            {
                                name: 'À droite',
                                value: 'text-right'
                            }
                        ],
                        value: 'text-left',
                        required: true
                    },
                    {
                        name: "Position de l'icône",
                        key: 'iconPosition',
                        values: [
                            {
                                name: 'En bas à droite (par défaut)',
                                value: 'bottom-right-icon'
                            },
                            {
                                name: 'En bas à gauche',
                                value: 'bottom-left-icon'
                            },
                            {
                                name: 'En haut au milieu',
                                value: 'top-center-icon'
                            },
                            {
                                name: 'En haut à droite',
                                value: 'top-right-icon'
                            },
                            {
                                name: 'En haut à gauche',
                                value: 'top-left-icon'
                            }
                        ],
                        value: 'bottom-right-icon',
                        required: true
                    },
                    {
                        name: "Forme de l'icône",
                        key: 'iconShape',
                        values: [
                            {
                                name: 'Aucune (par défaut)',
                                value: 'transparent'
                            },
                            {
                                name: 'Arrondie',
                                value: 'rounded'
                            },
                            {
                                name: 'Carrée',
                                value: 'square'
                            }
                        ],
                        value: 'transparent',
                        required: true
                    },
                    {
                        name: "Couleur de fond de l'icône",
                        key: 'iconBgColor',
                        special: 'colors',
                        condition: {
                            key: 'iconShape',
                            operator: '!=',
                            value: 'transparent'
                        }
                    },
                    {
                        name: "Couleur de l'icône",
                        key: 'iconColor',
                        special: 'colors',
                        condition: {
                            key: 'iconShape',
                            operator: '!=',
                            value: 'transparent'
                        }
                    }
                ]);
            case 'shortcuts':
            case 'tags':
            case 'category':
                return styling.concat([
                    {
                        name: 'Alignement du texte',
                        key: 'textAlign',
                        values: [
                            {
                                name: 'À gauche',
                                value: 'text-left'
                            },
                            {
                                name: 'Au centre',
                                value: 'text-center'
                            },
                            {
                                name: 'À droite',
                                value: 'text-right'
                            }
                        ],
                        value: 'text-left',
                        required: true
                    },
                    {
                        name: 'Permettre de déplier/replier le widget',
                        key: 'isFoldable',
                        values: [
                            {
                                name: 'Non (par défaut)',
                                value: false
                            },
                            {
                                name: 'Oui',
                                value: true
                            }
                        ],
                        value: false,
                        required: true
                    },
                    {
                        name: 'État par défaut du widget',
                        key: 'isFoldedByDefault',
                        values: [
                            {
                                name: 'Replier (par défaut)',
                                value: true
                            },
                            {
                                name: 'Déplier',
                                value: false
                            }
                        ],
                        value: true,
                        required: true,
                        condition: {
                            key: 'isFoldable',
                            operator: '=',
                            value: true
                        }
                    }
                ]);
            case 'posts':
            case 'campaigns':
                return styling.concat([
                    {
                        name: 'Mise en page du contenu',
                        key: 'layoutMode',
                        values: [
                            {
                                name: 'Slider',
                                value: 'slider'
                            },
                            {
                                name: 'Empiler',
                                value: 'stack'
                            }
                        ],
                        value: 'slider',
                        required: true
                    },
                    {
                        name: 'Défilement automatique',
                        key: 'autoRotate',
                        values: [
                            {
                                name: 'Non (par défaut)',
                                value: false
                            },
                            {
                                name: 'Oui',
                                value: true
                            }
                        ],
                        value: false,
                        required: true,
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    },
                    {
                        name: 'Timing pour le défilement',
                        key: 'autoRotateTiming',
                        min: 1,
                        max: 30,
                        step: 1,
                        unit: 's',
                        value: 4,
                        condition: {
                            key: 'autoRotate',
                            operator: '=',
                            value: true
                        }
                    },
                    {
                        name: 'Couleur de la pagination',
                        key: 'paginationColor',
                        special: 'colors',
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    }
                ]);
            case 'blogArticles':
                return styling.concat([
                    {
                        name: 'Mise en page du contenu',
                        key: 'layoutMode',
                        values: [
                            {
                                name: 'Slider',
                                value: 'slider'
                            }
                            // {
                            //     name: 'Empiler',
                            //     value: 'stack',
                            // },
                        ],
                        value: 'slider',
                        required: true
                    },
                    {
                        name: 'Défilement automatique',
                        key: 'autoRotate',
                        values: [
                            {
                                name: 'Non (par défaut)',
                                value: false
                            },
                            {
                                name: 'Oui',
                                value: true
                            }
                        ],
                        value: false,
                        required: true,
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    },
                    {
                        name: 'Timing pour le défilement',
                        key: 'autoRotateTiming',
                        min: 1,
                        max: 30,
                        step: 1,
                        unit: 's',
                        value: 4,
                        condition: {
                            key: 'autoRotate',
                            operator: '=',
                            value: true
                        }
                    },
                    {
                        name: 'Couleur de la pagination',
                        key: 'paginationColor',
                        special: 'colors',
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    }
                ]);
            case 'ambassador':
                let ambassadorSize = styling.find((style) => {
                    return style.key === 'size';
                });

                if (!!ambassadorSize) {
                    ambassadorSize.values = ambassadorSize.values.concat([
                        {
                            name: 'Un demi',
                            value: 'one-half'
                        },
                        {
                            name: 'Deux tiers',
                            value: 'two-third'
                        }
                    ]);
                }

                return styling.concat([
                    {
                        name: 'Couleur du texte',
                        key: 'textColor',
                        special: 'colors'
                    }
                ]);
            case 'calendar':
                return styling.filter(({ key }) => {
                    return ['size', 'customRoundSize', 'roundSize'].indexOf(key) !== -1;
                });
            case 'text':
                return styling.concat([
                    {
                        name: 'Couleur du texte',
                        key: 'textColor',
                        special: 'colors'
                    }
                ]);
            case 'section-start':
                return [
                    {
                        name: "Taille d'affichage",
                        key: 'size',
                        values: [
                            {
                                name: 'Pleine largeur (par défaut)',
                                value: 'full'
                            }
                        ],
                        value: 'full',
                        required: true
                    },
                    {
                        name: 'Couleur de fond',
                        key: 'bgColor',
                        special: 'colors'
                    },
                    {
                        name: 'Mise en page du contenu',
                        key: 'layoutMode',
                        values: [
                            {
                                name: 'Accordéon',
                                value: 'accordion'
                            },
                            {
                                name: 'Slider',
                                value: 'slider'
                            }
                        ],
                        value: 'accordion',
                        required: true
                    },
                    {
                        name: 'Taille maximum du bloc',
                        key: 'maxHeight',
                        min: 0,
                        max: 1000,
                        step: 1,
                        unit: 'px',
                        value: 375
                    },
                    {
                        name: 'Défilement automatique',
                        key: 'autoRotate',
                        values: [
                            {
                                name: 'Non (par défaut)',
                                value: false
                            },
                            {
                                name: 'Oui',
                                value: true
                            }
                        ],
                        value: false,
                        required: true,
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    },
                    {
                        name: 'Timing pour le défilement',
                        key: 'autoRotateTiming',
                        min: 1,
                        max: 30,
                        step: 1,
                        unit: 's',
                        value: 4,
                        condition: {
                            key: 'autoRotate',
                            operator: '=',
                            value: true
                        }
                    },
                    {
                        name: 'Couleur de la pagination',
                        key: 'paginationColor',
                        special: 'colors',
                        condition: {
                            key: 'layoutMode',
                            operator: '=',
                            value: 'slider'
                        }
                    }
                ];
            case 'section-end':
                return [];
            case 'rss':
                return styling.concat([
                    {
                        name: 'Couleur du texte du bouton',
                        key: 'refreshTextColor',
                        special: 'colors'
                    },
                    {
                        name: 'Couleur du texte',
                        key: 'textColor',
                        special: 'colors'
                    }
                ]);
            case 'chats':
                return styling.concat([
                    {
                        name: 'Couleur du texte',
                        key: 'textColor',
                        special: 'colors'
                    },
                    {
                        name: 'Couleur de fond des cartes',
                        key: 'cardBgColor',
                        special: 'colors'
                    },
                    {
                        name: 'Couleur de fond des icônes',
                        key: 'iconBgColor',
                        special: 'colors'
                    },
                    {
                        name: 'Couleur des icônes',
                        key: 'iconColor',
                        special: 'colors'
                    }
                ]);
            case 'title':
                return styling.concat([
                    {
                        name: 'Taille du texte',
                        key: 'fontSize',
                        min: 14,
                        max: 32,
                        step: 2,
                        unit: 'px',
                        value: 20
                    },
                    {
                        name: 'Alignement du titre',
                        key: 'titleAlign',
                        values: [
                            {
                                name: 'À gauche',
                                value: 'text-left'
                            },
                            {
                                name: 'Au centre',
                                value: 'text-center'
                            },
                            {
                                name: 'À droite',
                                value: 'text-right'
                            }
                        ],
                        value: 'text-center',
                        required: true
                    }
                ]);
            case 'image':
                let imageSize = styling.find((style) => {
                    return style.key === 'size';
                });

                if (!!imageSize) {
                    imageSize.values = imageSize.values.concat([
                        {
                            name: 'Un demi',
                            value: 'one-half'
                        },
                        {
                            name: 'Deux tiers',
                            value: 'two-third'
                        },
                        {
                            name: 'Un tier',
                            value: 'one-third'
                        }
                    ]);
                }

                return styling;
            default:
                return styling;
        }
    }

    getSpringboardFieldsConfigs() {
        return [
            {
                name: 'Couleur de fond',
                key: 'bgColor',
                special: 'colors'
            },
            {
                name: 'Marge en haut et en bas',
                key: 'marginTopBottomSize',
                min: 0,
                max: 30,
                step: 2,
                unit: 'px',
                value: 4
            },
            {
                name: 'Marge à gauche et à droite',
                key: 'marginLeftRightSize',
                min: 0,
                max: 30,
                step: 2,
                unit: 'px',
                value: 4
            },
            {
                name: 'Marge entre les blocs',
                key: 'gutterSize',
                min: 0,
                max: 30,
                step: 2,
                unit: 'px',
                value: 10
            },
            {
                name: 'Arrondi des widgets',
                key: 'roundSize',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 10
            }
        ];
    }
}
