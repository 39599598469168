import { Select as SelectInput } from 'components/Form/Inputs';

const Select = ({ field, fieldName, ...props }) => {
    return (
        <SelectInput
            name={fieldName}
            {...props}
            options={field.values.map((v) => ({
                label: v.name,
                value: v.value
            }))}
            shouldUnregister={false}
        />
    );
};

export default Select;
