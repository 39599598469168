import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePost } from '../../providers/PostProvider';

const PostActions = () => {
    const {
        post,
        alreadyLiked,
        like,
        isAllowToComment,
        toggleComment,
        hasReactions,
        react,
        isScheduledPost
    } = usePost();
    const { t } = useTranslation();
    const shouldShowFirstSection =
        !post.suggest &&
        !post.waiting_for_review &&
        !hasReactions() &&
        (post.post_status === undefined || !post.post_status.final) &&
        !post.hideAll &&
        !post.hideForSearch;
    const shouldShowSecondSection =
        !post.suggest &&
        !post.waiting_for_review &&
        hasReactions() &&
        (post.post_status === undefined || !post.post_status.final) &&
        !post.hideAll &&
        !post.hideForSearch &&
        !isScheduledPost();

    return (
        <>
            {shouldShowFirstSection && (
                <div className="post__info actions">
                    <div>
                        <button className={alreadyLiked ? 'liked' : ''} onClick={like}>
                            <span className="icon-like-1"></span>
                            {t('POST_LIKE').toUpperCase()}
                        </button>
                    </div>
                    {isAllowToComment() && (
                        <div>
                            <button onClick={toggleComment}>
                                <span className="icon-messaging-imessage"></span>
                                {t('POST_COMMENT_ACTION').toUpperCase()}
                            </button>
                        </div>
                    )}
                </div>
            )}

            {shouldShowSecondSection && (
                <div className="post__info actions">
                    {post.workspace.reactions.map(
                        (reaction, index) =>
                            !reaction.deleted_at && (
                                <div key={index} className="reaction">
                                    <button
                                        className={
                                            reaction.id ===
                                            post.post_reaction_current_user?.reaction?.id
                                                ? 'liked'
                                                : ''
                                        }
                                        onClick={() => react(reaction)}
                                    >
                                        <span className={`icon-${reaction.icon}`}></span>
                                        {reaction.name.toUpperCase()}
                                    </button>
                                </div>
                            )
                    )}
                </div>
            )}
        </>
    );
};

export default PostActions;
