import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function Radio({ name, options, inline = false, value, ...props }) {
    const { register, unregister } = useFormContext();

    useEffect(() => {
        return () => {
            unregister(name);
        };
    }, []);

    return (
        <div>
            {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
            <div className={`radios ${inline ? 'inline' : ''}`}>
                {options.map((option, index) => {
                    return (
                        <div className="radio" key={`radio_${name}_${index}`}>
                            <input
                                className="input"
                                id={`${name}_${index}`}
                                type="radio"
                                value={option.value}
                                {...register(name, { value })}
                            />
                            <label className="label" htmlFor={`${name}_${index}`}>
                                {option.label}
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Radio;
