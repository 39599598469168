import Campaigns from '../Campaigns';
import CampaignsUser from '../components/CampaignsUser';

export default [
    { name: 'auth.campaigns', url: 'campaigns', abstract: true, component: Campaigns },
    { name: 'auth.campaigns.campaign', url: '/campaign/:type/:id', component: CampaignsUser },
    {
        name: 'auth.campaigns.feed',
        url: `/:type/:status?page&tab`,
        component: CampaignsUser,
        navbar: {
            getIcon: () => {
                return 'icon-like-shine';
            },
            getLabel: (context) => {
                return context.t('CAMPAIGNS');
            },
            isActive: (context) => {
                return context.isActive([
                    'auth.achievements.**',
                    'auth.campaigns.feed.**',
                    'auth.campaigns.campaign.**'
                ]);
            },
            isVisible: () => {
                return true;
            }
        },
        params: {
            type: {
                type: 'string',
                value: 'all'
            },
            tab: {
                type: 'string',
                value: 'feed'
            },
            status: {
                type: 'string',
                value: 'running'
            },
            page: {
                type: 'int',
                value: 1
            }
        }
    }
];
