import { useForm } from '../Form/hooks';
import EditorProvider from './providers/EditorProvider';
import { EditorForm } from '../Form/EditorForm';
import SubscriptionLess from './components/SubscriptionLess';
import EditorFormTemplate from './components/EditorFormTemplate';
import EditorPagination from './components/EditorPagination';
import { useTranslation } from 'react-i18next';
import EditorButtons from './components/EditorButtons';

const Editor = ({ workspace, isPreview = false, isReview = false, form = null }) => {
    const { t } = useTranslation();
    const methods = useForm({
        defaultValue: form
    });
    return (
        <div className="editor-root">
            <EditorForm methods={methods} workspace={workspace} isPreview={isPreview}>
                <EditorProvider workspace={workspace} isPreview={isPreview} isReview={isReview}>
                    <SubscriptionLess />
                    <div className="template">
                        <EditorFormTemplate />
                    </div>
                    <EditorPagination />
                    <EditorButtons />
                </EditorProvider>
            </EditorForm>
        </div>
    );
};

export default Editor;
