import React, { Component, Fragment } from 'react';

import Achievement from 'components/Achievement/Achievement';
import useUser from 'hooks/useUser';

const Avatar = ({ user, hideBadge, showPopover }) => {
    const { getAvatar } = useUser();

    return (
        <div className="avatarRoot">
            <div className="avatar">
                <div className="image">
                    <img src={getAvatar(user)} />
                </div>

                {!hideBadge && user.user_achievement ? (
                    <div className="trophy">
                        <Achievement
                            item={user.user_achievement.achievement}
                            show-popover={showPopover}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Avatar;
