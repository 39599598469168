import React from 'react';
import { useTranslation } from 'react-i18next';
import useAttachment from 'hooks/useAttachment';
import useCampaignComponent from '../hooks/useCampaignComponent';

const CampaignImage = ({ campaign }) => {
    const { forgeAttachmentUrl } = useAttachment();
    const { getCampaignTypeIcon } = useCampaignComponent(campaign);

    return (
        <div className="image">
            {campaign.image ? (
                <div className="icon">
                    <div
                        className="background"
                        style={{ backgroundImage: `url(${forgeAttachmentUrl(campaign.image)})` }}
                    ></div>
                </div>
            ) : (
                <div className="icon no-image">
                    {!campaign.link ? (
                        <div className="type">
                            <i className={`fa ${getCampaignTypeIcon(campaign.type)}`}></i>
                        </div>
                    ) : (
                        <div className="video">
                            {!campaign.link.picture_url ? (
                                <i className="fa fa-video-camera" aria-hidden="true"></i>
                            ) : (
                                <div
                                    className="background"
                                    style={{ backgroundImage: `url(${campaign.link.picture_url})` }}
                                ></div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const CampaignContent = ({ campaign }) => {
    const { t } = useTranslation();
    const { displaySingleCampaign, isCampaignProgrammed } = useCampaignComponent(campaign);

    return (
        <div className={`content ${campaign.type}`}>
            <div className="type">{t('TA_RECRUITING_TITLE')}</div>
            <div className="title">{campaign.title}</div>
            <div className="action">
                {!isCampaignProgrammed() ? (
                    <button
                        className="button small rounded"
                        onClick={() => displaySingleCampaign(campaign)}
                    >
                        {t('JOIN_IN')}
                    </button>
                ) : (
                    <div>{t('TA_CAMPAIGN_PLANNED_FUTUR_ACCESS', { date: campaign.startDate })}</div>
                )}
            </div>
        </div>
    );
};

const RecruitingLight = ({ campaign }) => {
    return (
        <>
            <CampaignImage campaign={campaign} />
            <CampaignContent campaign={campaign} />
        </>
    );
};

export default RecruitingLight;
