import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useCampaignService from 'api/useCampaignService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';
import limitTo from 'helpers/limitTo';
import { useModal } from 'providers/ModalContext';
import { useRouter } from '@uirouter/react';
import useViewport from 'hooks/useViewport';
import useCampaign from 'hooks/useCampaign';

const Campaigns = ({ widget, options }) => {
    const { t } = useTranslation();
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);
    const { getAllRunning } = useCampaignService();
    const { closeModal } = useModal();
    const router = useRouter();
    const viewport = useViewport();
    const { getCampaignImage, getCampaignTypeIcon, getCampaignTypeName } = useCampaign();

    const getPaginationStyle = () => {
        if (!getStyleValueForKey('paginationColor')) return;

        return { '--swiper-pagination-color': getStyleValueForKey('paginationColor') };
    };

    const goToCampaign = (campaign) => {
        closeModal('springboard');

        if (viewport.xs) {
            /*this.SingleCampaign.createPanel({
                campaign: campaign,
                user: this.User.getUserObject(),
            });*/
        } else {
            return router.stateService.go(
                `auth.campaigns.campaign`,
                {
                    type: campaign.type,
                    id: campaign.id
                },
                { reload: false, inherit: false }
            );
        }
    };

    const slideOptions = () => {
        if (getStyleValueForKey('layoutMode') !== 'slider') {
            return {};
        }

        let swiperOptions = {
            spaceBetween: 20,
            navigation: true,
            pagination: { clickable: true }
        };

        if (getStyleValueForKey('autoRotate')) {
            swiperOptions.loop = true;
            swiperOptions.autoplay = {
                delay: getStyleValueForKey('autoRotateTiming') * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            };
        }

        return swiperOptions;
    };

    const { data: campaigns, isLoading } = useQuery({
        queryKey: ['widget_campaigns', widget.id],
        queryFn: () => {
            switch (getFieldValueForKey('displayMode')) {
                case 'latestByCount':
                    break;
                case 'selectedCampaigns':
                    return getAllRunning(1, 'all', false).then((response) => {
                        let selectedCampaigns = getFieldValueForKey('campaigns');

                        return response.campaigns.filter((campaign) => {
                            return (
                                selectedCampaigns.findIndex((selectCampaign) => {
                                    return selectCampaign.value === campaign.id;
                                }) !== -1
                            );
                        });
                    });

                    break;
                case 'latest':
                default:
                    let campaignType = getFieldValueForKey('campaignType');

                    return getAllRunning(1, campaignType, false).then((response) => {
                        return limitTo(response.campaigns, getFieldValueForKey('limit'));
                    });
            }
        }
    });

    const isDisplayMode = (name) => {
        return getFieldValueForKey('displayMode') === name;
    };

    const hasCampaigns = () => {
        return campaigns && campaigns.length > 0;
    };

    return (
        <>
            {!isDisplayMode('latestByCount') ? (
                <div className={`campaigns ${getStyleValueForKey('layoutMode')}`}>
                    <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                        {getFieldValueForKey('title')}
                    </div>

                    {hasCampaigns() ? (
                        <div className="listing" style={getPaginationStyle()}>
                            <Swiper
                                slidesPerView={1}
                                {...slideOptions()}
                                modules={[Autoplay, Pagination, Navigation]}>
                                {campaigns.map((campaign) => {
                                    return (
                                        <SwiperSlide key={`campaign_${campaign.id}`}>
                                            <div className={`campaign ${campaign.type}`}>
                                                <div className="preview">
                                                    {getCampaignImage(campaign) ? (
                                                        <div className="image">
                                                            <div
                                                                className="background"
                                                                style={{
                                                                    'background-image': `url(${getCampaignImage(campaign)})`
                                                                }}></div>
                                                        </div>
                                                    ) : (
                                                        <div className="image empty">
                                                            <i
                                                                className={`fa ${getCampaignTypeIcon(campaign)}`}></i>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="meta">
                                                    <div className="type ellipsis">
                                                        <div className="ellipsis-text">
                                                            {getCampaignTypeName(campaign)}
                                                        </div>
                                                    </div>
                                                    <div className="title">{campaign.title}</div>

                                                    <div className="actions">
                                                        <button
                                                            type="button"
                                                            onClick={() => goToCampaign(campaign)}>
                                                            {t('JOIN_IN')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    ) : (
                        <div className="empty" style={{ color: getStyleValueForKey('titleColor') }}>
                            {t('NO_CAMPAIGNS_YET')}
                        </div>
                    )}
                </div>
            ) : (
                <div className={`campaigns ${getStyleValueForKey('layoutMode')}`}>
                    <campaigns-widget filter-mode="true"></campaigns-widget>
                </div>
            )}
        </>
    );
};

export default Campaigns;
