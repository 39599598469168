import { UIView } from '@uirouter/react';
import DashboardCampaigns from '../components/DashboardCampaigns/DashboardCampaigns';
import DashboardHome from '../components/DashboardHome/DashboardHome';
import DashboardCampaignCreate from '../components/DashboardCampaignCreate/DashboardCampaignCreate';
import DashboardActivities from '../components/DashboardActivities/DashboardActivities';
import DashboardActivityView from '../components/DashboardActivityView/DashboardActivityView';

const routes = [
    {
        name: 'auth.dashboard',
        url: 'dashboard',
        abstract: true,
        component: UIView
    },
    {
        name: 'auth.dashboard.campaigns',
        url: '/campaigns',
        abstract: true,
        component: UIView
    },
    {
        name: 'auth.dashboard.activities',
        url: '/activities',
        abstract: true,
        component: UIView
    },
    {
        name: 'auth.dashboard.missions',
        url: '/missions',
        abstract: true,
        component: UIView
    },
    {
        name: 'auth.dashboard.activities.home',
        url: '/home?campaignId&campaignType&page',
        component: DashboardActivities,
        params: {
            campaignId: {
                type: 'string'
            },
            campaignType: {
                type: 'string'
            },
            page: {
                type: 'int',
                value: 1
            }
        }
    },
    {
        name: 'auth.dashboard.activities.home.view',
        url: '/view/:activity',
        component: DashboardActivityView,
        params: {
            activity: {
                type: 'int'
            }
        }
    },
    {
        name: 'auth.dashboard.campaigns.create',
        url: '/create?id&type',
        component: DashboardCampaignCreate,
        params: {
            id: {
                type: 'int'
            },
            type: {
                type: 'string',
                value: 'product'
            }
        }
    },
    {
        name: 'auth.dashboard.campaigns.home',
        url: '/home',
        component: DashboardHome
    },
    {
        name: 'auth.dashboard.campaigns.feed',
        url: '/:type/:status?page',
        component: DashboardCampaigns,
        params: {
            type: {
                type: 'string',
                value: 'product'
            },
            status: {
                type: 'string',
                value: 'running'
            },
            page: {
                type: 'int',
                value: 1
            }
        }
    },
    {
        name: 'auth.dashboard.missions.home',
        url: '/home',
        component: DashboardHome
    }
];

export default routes;
