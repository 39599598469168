import React, { useCallback } from 'react';
import { useDevice } from '../../../../providers/DeviceContext';
import { useChannelContext } from '../../providers/ChannelProvider';
import { useConfig } from '../../../../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';

const Composer = () => {
    const { device } = useDevice();
    const { config } = useConfig();
    const { channelInfo } = useChannelContext();
    const { t } = useTranslation();
    const [message, setMessage] = useSetState({});

    const notAlone = useCallback(() => {
        return channelInfo && channelInfo.users.length > 1;
    }, [channelInfo]);

    const hasAnswer = () => {
        return false;
    };

    return (
        <div className={`composer ${!device.isKeyboardOpen ? 'keyboard-closed' : ''}`}>
            <div className="actions">
                <button
                    className="action emoji hide-xs"
                    disabled={!notAlone()}
                    ng-click="addReactionsToMessage()"
                >
                    <i className="icon-smiley-happy" aria-hidden="true"></i>
                </button>

                {config.allow_files_in_channels !== false && (
                    <button
                        className="action files"
                        disabled={!notAlone()}
                        ng-click="addDocument()"
                    >
                        <i className="icon-attachment" aria-hidden="true"></i>
                    </button>
                )}
            </div>

            <div className={`editor ${!notAlone() ? 'disabled' : ''}`}>
                {hasAnswer() && (
                    <div className="answer">
                        <div className="message">
                            <div
                                className="author"
                                ng-bind-html="t('CHAT_ANSWER_USER', { name: getAnswerUser() })"
                            ></div>
                            <div
                                className="content"
                                ng-bind-html="formattedMessage(getAnswerContent()) | emoji"
                            ></div>
                        </div>

                        <div className="actions">
                            <button
                                className="action"
                                title={t('CHAT_ANSWER_CANCEL')}
                                ng-click="cancelAnswer()"
                            >
                                <i className="icon-remove-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                )}

                {/*<div className="attachments" ng-if="message.attachments.length > 0">
                                        <div className="attachment"
                                             ng-repeat="attachment in message.attachments track by $index">
                                            <div className="remove" ng-click="removeDocument($index)">+</div>

                                            <div className="rotate" ng-click="rotateImage(attachment)">
                                                <i className="fa fa-undo"></i>
                                            </div>

                                            <div className="file" ng-if="!isAllImagesAttachment([attachment])">
                                                <div className="icon">
                                                    <i className="fa { attachment | attachmentIcon: 'class' }"
                                                       ng-attr-style="color: { attachment | attachmentIcon: 'color' };"></i>
                                                </div>

                                                <div className="name">
                                                    {attachment.name}
                                                </div>
                                            </div>

                                            <div className="image" ng-if="isAllImagesAttachment([attachment])">
                                                <img ngf-src="attachment.native" imageonload="onImageLoaded()"
                                                     ng-style="getImageRotationStyle(attachment)"/>
                                            </div>
                                        </div>
                                    </div>*/}

                {message.link && (
                    <div className="link">
                        <div className="remove" ng-click="removeLink()">
                            +
                        </div>

                        <link-preview
                            item="message.link"
                            user="User.getUserObject()"
                            load-callback="onImageLoadedHandler"
                            small="true"
                        ></link-preview>
                    </div>
                )}

                <div className="textarea">
                    {!config.isMsie ? (
                        <textarea
                            ng-if="!Config.isMsie"
                            ng-disabled="!notAlone()"
                            ng-model="message.content"
                            rows="1"
                            ng-attr-placeholder="{ t('CHANNEL_PLACEHOLDER') }"
                            ng-keyup="onKeyUp()"
                            ng-keydown="onKeyDown($event)"
                            ng-blur="onBlur()"
                            elastic
                            ng-paste="onTextareaPaste($event)"
                        ></textarea>
                    ) : (
                        <textarea
                            ng-if="Config.isMsie"
                            ng-disabled="!notAlone()"
                            ng-model="message.content"
                            rows="1"
                            ng-attr-placeholder="{ t('CHANNEL_PLACEHOLDER') }"
                            ng-keydown="onKeyDown($event)"
                            ng-paste="onTextareaPaste($event)"
                        ></textarea>
                    )}
                </div>
            </div>

            <div className="actions">
                <button
                    className="action send"
                    ng-disabled="!notAlone()"
                    onMouseDown="event.preventDefault()"
                    ng-click="postMessage()"
                >
                    <i className="icon-send-email"></i>
                </button>
            </div>
        </div>
    );
};

export default Composer;
