import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    cloneDeep,
    toNumber,
    defaultTo,
    concat,
    findIndex,
    orderBy,
    forEach,
    size,
    get
} from 'lodash-es';
import { useAuth } from 'providers/AuthContext';
import useWorkspaceService from 'api/useWorkspaceService';
import { useTranslation } from 'react-i18next';
import { useModal } from 'providers/ModalContext';
import { useTreeContext } from './TreeProvider';
import { useCurrentStateAndParams } from '@uirouter/react';
import useTreeHook from 'hooks/useTree';
import Category from '../../../components/Composer/steps/Category';
import Workspace from '../../../components/Composer/steps/Workspace';
import Form from '../../../components/Composer/steps/Form';

const ComposerContext = createContext();

export const STEPS = [
    {
        title: 'MODAL_COMPOSER_MAIN_TITLE',
        subtitle: 'MODAL_COMPOSER_SELECT_CATEGORY',
        slug: 'category',
        partial: Category
    },
    {
        title: 'MODAL_COMPOSER_MAIN_TITLE',
        subtitle: 'MODAL_COMPOSER_SELECT_WORKSPACE',
        slug: 'workspace',
        partial: Workspace
    },
    {
        title: 'MODAL_COMPOSER_MAIN_TITLE',
        subtitle: 'MODAL_COMPOSER_SELECT_SLOT',
        slug: 'slot'
    },
    {
        title: 'MODAL_COMPOSER_FORM_TITLE',
        subtitle: 'MODAL_COMPOSER_FORM_SUBTITLE',
        slug: 'form',
        partial: Form
    }
];

export const ComposerProvider = ({ children }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { getWorkspaceFolders } = useWorkspaceService();
    const { tree } = useTreeContext();
    const { getOnlyWorkspace } = useTreeHook();

    const { params } = useCurrentStateAndParams();

    const [currentStepTitle, setCurrentStepTitle] = useState(null);
    const [currentStepSubtitle, setCurrentStepSubtitle] = useState(null);
    const [writableWorkspacesByCategories, setWritableWorkspacesByCategories] = useState([]);
    const [writableWorkspaces, setWritableWorkspaces] = useState([]);
    const [category, setCategory] = useState(null);
    const [workspace, setWorkspace] = useState(null);
    const [slot, setSlot] = useState(null);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [enabledSteps, setEnabledSteps] = useState(STEPS);

    const { openModal } = useModal();

    const showComposer = (options) => {
        initComposer(options);
        openModal('composer');
    };

    useEffect(() => {
        if (tree) {
            setWritableWorkspaces(
                orderBy(
                    getOnlyWorkspace(tree),
                    ['total_posts_since_30_days', 'total_posts_since_90_days'],
                    ['desc', 'desc']
                )
            );
            setWritableWorkspacesByCategories(processTreeCategories(tree));
        }
    }, [tree]);

    useEffect(() => {
        if (enabledSteps[currentStepIndex]) {
            setCurrentStepTitle(t(enabledSteps[currentStepIndex].title));
            setCurrentStepSubtitle(t(enabledSteps[currentStepIndex].subtitle));
        }
    }, [currentStepIndex, enabledSteps]);

    const setSteps = (steps) => {
        setEnabledSteps(STEPS.filter((step) => steps.indexOf(step.slug) !== -1));
    };

    const initComposer = (options) => {
        let {
            category = null,
            workspace = null,
            post = null,
            fromPost = null,
            slot = null,
            isReview = false
        } = options;

        if (slot !== null) {
            setSlot(slot);

            setSteps(['workspace', 'form']);
            setCurrentStepIndex(0);

            let workspaces = writableWorkspaces
                .filter((workspace) => {
                    return slot.track.workspaces.find(
                        (slotWorkspace) =>
                            slotWorkspace.id === workspace.workspace_data.workspace.id
                    );
                })
                .map((workspace) => {
                    return workspace.workspace_data;
                });

            if (workspaces.length === 1) {
                setCategory({ workspaces }, false);
                setWorkspace(workspaces[0].workspace);
            } else {
                setCategory({ workspaces }, false);
            }

            /*this.form = {
                slot: this.slot,
            };*/
        } else {
            setSteps(['category', 'workspace', 'form']);

            if (post !== null) {
                setCategory(get(post, ['workspace', 'category'], null), false);
                setWorkspace(get(post, ['workspace'], null), null, false);

                this.form = post;

                if (this.form.workspace.workspace_form) {
                    this.form.workspace.workspace_form.security_level = post.security_level;
                }

                setCurrentStepIndex(2);

                this.isReview = isReview;
            } else if (fromPost !== null) {
                let {
                    content = null,
                    link = null,
                    blog_article = null,
                    attach_campaign = null,
                    campaign = null,
                    event = null,
                    attachments = [],
                    images = []
                } = fromPost;

                this.form = {
                    content,
                    link,
                    blog_article,
                    attach_campaign,
                    campaign,
                    event,
                    attachments,
                    images,
                    targetUsers: []
                };
            } else if (category !== null && workspace !== null) {
                setCategory(category);
                setWorkspace(workspace);
            } else if (category !== null) {
                setCategoryById(category.value);

                if (category && category.workspaces.length === 1) {
                    setWorkspace(category.workspaces[0].workspace);
                }
            } else if (workspace !== null) {
                setWorkspaceById(workspace.category.id, workspace.value);
            } else {
                /*this.findCurrentCategoryAndWorkspace();

                if (this.currentCategory) {
                    this.setCategory(this.currentCategory);

                    if (this.currentWorkspace) {
                        this.setWorkspace(this.currentWorkspace);
                    }
                }*/
            }
        }
    };

    const findCurrentCategoryAndWorkspace = () => {
        this.currentCategory = null;
        this.currentWorkspace = null;
        this.currentFolder = null;

        let categoryId = defaultTo(toNumber(params.category), null);

        if (categoryId) {
            let foundCategoryIndex = findIndex(this.writableWorkspacesByCategories, (category) => {
                return category.id === categoryId;
            });

            if (foundCategoryIndex !== -1) {
                this.currentCategory = this.writableWorkspacesByCategories[foundCategoryIndex];

                let workspaceId = defaultTo(toNumber(params.workspace), null);

                if (workspaceId) {
                    let foundWorkspaceIndex = findIndex(params.workspaces, (item) => {
                        return item.workspace.id === workspaceId;
                    });

                    if (foundWorkspaceIndex !== -1) {
                        this.currentWorkspace =
                            this.currentCategory.workspaces[foundWorkspaceIndex].workspace;
                    }
                }
            }
        }

        let folderId = defaultTo(toNumber(params.folder), null);

        if (folderId) {
            this.currentFolder = folderId;
        }
    };

    const setWorkspaceById = (categoryId = null, workspaceId = null, forceStep = true) => {
        if (!categoryId || !workspaceId) {
            return;
        }

        const c = setCategoryById(categoryId);

        let foundWorkspaceIndex = findIndex(c.workspaces, (item) => {
            return item.workspace.id === workspaceId;
        });

        if (foundWorkspaceIndex !== -1) {
            setWorkspaceHandler(c.workspaces[foundWorkspaceIndex].workspace, forceStep);
        }
    };

    const setWorkspaceHandler = (workspace, forceStep = true) => {
        setWorkspace(workspace);

        getWorkspaceFolders(workspace.id).then(({ folders }) => {
            setWorkspace((prevState) => {
                return {
                    ...prevState,
                    folders
                };
            });
        });

        if (hasTracks(workspace) && !slot) {
            setSteps(['category', 'workspace', 'slot', 'form']);

            workspace.slots = [];

            workspace.tracks.forEach((track) => {
                /*getAllSlotsByTrack(track.id, 'waiting_for_post', null, user.id).then((data) => {
                    workspace.slots = workspace.slots.concat(data.slots);
                });*/
            });
        }

        if (forceStep) {
            goToStep(1);
        }
    };

    const goToStep = (increment) => {
        goToTargetStep(currentStepIndex + increment);
    };

    const setCategoryById = (categoryId = null, forceStep = true) => {
        if (!categoryId) {
            return;
        }

        let foundCategoryIndex = findIndex(writableWorkspacesByCategories, (category) => {
            return category.id === categoryId;
        });

        if (foundCategoryIndex !== -1) {
            setCategory(writableWorkspacesByCategories[foundCategoryIndex], forceStep);
            return writableWorkspacesByCategories[foundCategoryIndex];
        }
        return null;
    };

    const userHasFewWorkspaces = () => {
        return writableWorkspacesByCategories.length <= 8;
    };

    const goToTargetStep = (step) => {
        setCurrentStepIndex(step);
    };

    const setCategoryAndWorkspace = (workspace, forceStep = true) => {
        let foundCategoryIndex = findIndex(writableWorkspacesByCategories, (category) => {
            return category.id === workspace.category.id;
        });

        if (foundCategoryIndex !== -1) {
            setCategory(writableWorkspacesByCategories[foundCategoryIndex], false);
        }

        setWorkspace(workspace, false);

        if (forceStep) {
            let targetStep = enabledSteps.length - 1;

            if (hasTracks(workspace)) {
                targetStep = enabledSteps.length - 2;
            }

            goToTargetStep(targetStep);
        }
    };

    const hasTracks = (workspace) => {
        if (!workspace) {
            return false;
        }

        return workspace.tracks && workspace.tracks.length > 0;
    };

    const processTreeCategories = (tree) => {
        let categories = [];

        forEach(tree, (item) => {
            if (item.category) {
                if (size(item.workspaces) === 0) {
                    return;
                }

                let category = cloneDeep(item.category);

                category.workspaces = [];

                forEach(item.workspaces, (item) => {
                    if (item.workspace_data) {
                        category.workspaces.push(item.workspace_data);
                    }
                });

                categories.push(category);

                categories = concat(categories, processTreeCategories(item.workspaces));
            }
        });

        return categories;
    };

    return (
        <ComposerContext.Provider
            value={{
                showComposer,
                userHasFewWorkspaces,
                writableWorkspacesByCategories,
                writableWorkspaces,
                setCurrentStepTitle,
                category,
                setCategory,
                workspace,
                setWorkspace,
                currentStepSubtitle,
                currentStepTitle,
                setCurrentStepSubtitle,
                currentStepIndex,
                setCurrentStepIndex,
                slot
            }}>
            {children}
        </ComposerContext.Provider>
    );
};

export const useComposer = () => useContext(ComposerContext);
