import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import NavbarWidgetsService from '../services/NavbarWidgets';
import useViewport from 'hooks/useViewport';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import useNavbarService from './useNavbar';
import { useAlert } from 'providers/AlertContext';
import useHomeStateName from '../../../hooks/useHomeStateName';
import useUrl from '../../../hooks/useUrl';

const useGetItemAsState = (forceMobile) => {
    const NavbarWidgets = new NavbarWidgetsService();
    const viewport = useViewport();
    const { isModalOpen } = useModal();
    const { t } = useTranslation();
    const router = useRouter();
    const { getNavbarStateForName } = useNavbarService();
    const { getHomeStateName } = useHomeStateName();
    const { openUrl } = useUrl();
    const { openModal } = useModal();

    const getImage = (item) => {
        return (
            NavbarWidgets.getWidgetStyleValueForKey(item, 'image') ||
            NavbarWidgets.getWidgetFieldValueForKey(item, 'image')
        );
    };

    const getIcon = (item) => {
        const name =
            NavbarWidgets.getWidgetStyleFirstValueForKey(item, 'icon')?.value ||
            NavbarWidgets.getWidgetFieldFirstValueForKey(item, 'icon')?.value;

        if (!name) {
            return;
        }

        return `icon-${name}`;
    };

    const getClasses = (item) => {
        const displayMode = NavbarWidgets.getWidgetStyleValueForKey(item, 'displayMode');
        const mobileDisplayMode = NavbarWidgets.getWidgetStyleValueForKey(
            item,
            'mobileDisplayMode'
        );
        const imageSize = NavbarWidgets.getWidgetStyleValueForKey(item, 'imageSize');
        const position = NavbarWidgets.getWidgetStyleValueForKey(item, 'position');

        return classNames(
            [
                `is-${item.type}`,
                displayMode && `as-${displayMode}`,
                mobileDisplayMode && `with-${mobileDisplayMode}`,
                imageSize && `with-${imageSize}-image`
            ],
            {
                'with-right-aligned-dropdown': position === 'right'
            }
        );
    };

    const getStyles = (item) => {
        const bgColor = NavbarWidgets.getWidgetStyleValueForKey(item, 'bgColor');
        const imageSize = NavbarWidgets.getWidgetStyleValueForKey(item, 'imageSize');

        if (!bgColor || bgColor === 'transparent' || (imageSize && imageSize === 'larger')) {
            return;
        }

        return {
            backgroundColor: bgColor,
            paddingLeft: '10px',
            paddingRight: '10px'
        };
    };

    const isMobile = () => {
        return viewport.xs || forceMobile;
    };

    const getChildren = (item) => {
        return NavbarWidgets.getWidgetFieldValueForKey(item, 'children').map((child) => {
            const repeaterType = NavbarWidgets.getWidgetFieldValueForKey(
                {
                    fields: child
                },
                'repeaterType'
            );

            const repeaterIcon = NavbarWidgets.getWidgetFieldValueForKey(
                {
                    fields: child
                },
                'repeaterIcon'
            );

            const repeaterKey = `repeater-${repeaterType}-`;

            const fields = child
                .filter((field) => field.key.startsWith(repeaterKey))
                .map((field) => {
                    return { ...field, key: field.key.replace(repeaterKey, '') };
                });

            const mergedWithDefaultConfig = NavbarWidgets.mergeWithDefaultConfigs({
                type: repeaterType,
                fields
            });

            return {
                ...mergedWithDefaultConfig,
                fields: [
                    ...mergedWithDefaultConfig.fields,
                    {
                        key: 'icon',
                        value: repeaterIcon
                    }
                ]
            };
        });
    };

    const getName = (item) => {
        const { name } = getStateData(item);

        switch (name) {
            case 'auth.homepage':
                return getHomeStateName();
            default:
                return name;
        }
    };

    const getStateData = (item) => {
        const link = NavbarWidgets.getWidgetFieldFirstValueForKey(item, 'link')?.value;

        if (!link) {
            return;
        }

        const state = getNavbarStateForName(link);

        if (!state) {
            return;
        }

        return {
            name: link,
            ...state
        };
    };

    const getItemAsState = (item, parent = null) => {
        let state = {
            label: NavbarWidgets.getWidgetFieldValueForKey(item, 'title'),
            visibility: NavbarWidgets.getWidgetStyleValueForKey(item, 'visibility'),
            icon: getIcon(item),
            image: getImage(item),
            classes: getClasses(item),
            styles: getStyles(item),
            isActive: () => {
                return false;
            },
            isVisible: (context, navItem) => {
                switch (navItem.visibility) {
                    case 'web':
                        return !isMobile();
                    case 'mobile':
                        return isMobile();
                    default:
                        return true;
                }
            }
        };

        switch (item.type) {
            case 'button':
                const action = NavbarWidgets.getWidgetFieldValueForKey(item, 'action');

                switch (action) {
                    case 'openMobileAnchor':
                        const displayMode = NavbarWidgets.getWidgetFieldValueForKey(
                            item,
                            'displayMode'
                        );
                        const isPopup = displayMode === 'popup';
                        const hideHeader = isPopup
                            ? NavbarWidgets.getWidgetFieldValueForKey(item, 'hideHeader')
                            : false;
                        const openDirection = isPopup
                            ? NavbarWidgets.getWidgetFieldValueForKey(item, 'openDirection')
                            : 'from-fixed';

                        /*Springboard.setOptions({
                            displayMode,
                            hideHeader,
                            openDirection,
                        });*/

                        return {
                            ...state,
                            icon: null,
                            label: null,
                            springboard: true,
                            getIcon: () => {
                                if (isModalOpen('springboard') && isPopup) {
                                    return 'icon-remove-circle';
                                }

                                return state.icon;
                            },
                            getLabel: () => {
                                if (isModalOpen('springboard') && isPopup) {
                                    return t('POST_DIALOG_CLOSE');
                                }

                                return state.label;
                            },
                            callback: () => {
                                if (isPopup) {
                                    /*Springboard.toggle({
                                        displayMode,
                                        hideHeader,
                                        openDirection,
                                    });*/
                                } else {
                                    /*Springboard.show({
                                        displayMode,
                                        hideHeader,
                                        openDirection,
                                    });*/
                                }
                            },
                            isActive: () => {
                                return isModalOpen('springboard');
                            },
                            isVisible: () => {
                                return isMobile();
                            }
                        };
                    case 'openSearch':
                        return {
                            ...state,
                            callback: () => {
                                openModal('search-box');
                            }
                        };
                    case 'openTree':
                        return {
                            ...state,
                            callback: () => {
                                openModal('tree-modal');
                            }
                        };
                    case 'openMissions':
                        let missionClasses = classNames('badge-mission', state.classes);
                        let showMissionBadge = false;

                        if (parent?.extra_data?.missions?.color) {
                            showMissionBadge = true;
                            missionClasses = classNames(
                                'badge-mission ' + parent.extra_data.missions.color,
                                state.classes
                            );
                        }

                        if (!parent && item.extra_data?.color) {
                            showMissionBadge = true;
                            missionClasses = classNames(
                                'badge-mission ' + item.extra_data.color,
                                state.classes
                            );
                        }

                        return {
                            ...state,
                            classes: missionClasses,
                            callback: () => {
                                /*Missions.openMissions();
                                Widgets.postCleanBadge();*/

                                let badgeMissionsElements =
                                    document.querySelectorAll('.badge-mission');

                                if (badgeMissionsElements.length > 0) {
                                    badgeMissionsElements.forEach((element) => {
                                        element.classList.replace('tomato', 'grey');
                                    });
                                }
                            },
                            isVisible: (context, navItem) => {
                                //return state.isVisible(context, navItem) && !User.isReviewer();
                            },
                            hasBadge: () => {
                                return showMissionBadge;
                            }
                        };
                    case 'openRealizations':
                        let realizationClasses = classNames('badge-realization', state.classes);
                        let showRealizationsBadge = false;

                        if (parent?.extra_data?.realizations?.color) {
                            showRealizationsBadge = true;
                            realizationClasses = classNames(
                                'badge-realization ' + parent.extra_data.realizations.color,
                                state.classes
                            );
                        }

                        if (!parent && item.extra_data?.color) {
                            showRealizationsBadge = true;
                            realizationClasses = classNames(
                                'badge-realization ' + item.extra_data.color,
                                state.classes
                            );
                        }

                        return {
                            ...state,
                            classes: realizationClasses,
                            callback: () => {
                                //Missions.openMissions();

                                let data = {
                                    fromRealizations: true
                                };

                                //Widgets.postCleanBadge(data);

                                let badgeRealizationsElements =
                                    document.querySelectorAll('.badge-realization');

                                if (badgeRealizationsElements.length > 0) {
                                    badgeRealizationsElements.forEach((element) => {
                                        element.classList.replace('tomato', 'grey');
                                    });
                                }
                            },
                            isVisible: (context, navItem) => {
                                //return state.isVisible(context, navItem) && User.isReviewer();
                            },
                            hasBadge: () => {
                                return showRealizationsBadge;
                            }
                        };
                    case 'openEssentials':
                        let essentialsClasses = classNames('badge-essentials', state.classes);
                        let showEssentialsBadge = false;

                        if (parent?.extra_data?.essentials?.color) {
                            showEssentialsBadge = true;
                            essentialsClasses = classNames(
                                'badge-essentials ' + parent.extra_data.essentials.color,
                                state.classes
                            );
                        }

                        if (!parent && item.extra_data?.color) {
                            showEssentialsBadge = true;
                            essentialsClasses = classNames(
                                'badge-essentials ' + item.extra_data.color,
                                state.classes
                            );
                        }

                        return {
                            ...state,
                            classes: essentialsClasses,
                            callback: () => {
                                router.stateService.go(
                                    'auth.essentials.feed',
                                    {},
                                    { reload: true, inherit: false }
                                );
                            },
                            hasBadge: () => {
                                return showEssentialsBadge;
                            }
                        };
                    case 'openComposer':
                    default:
                        const actionType = NavbarWidgets.getWidgetFieldValueForKey(
                            item,
                            'actionType'
                        );

                        return {
                            ...state,
                            callback: () => {
                                switch (actionType) {
                                    case 'postToFeed':
                                        const feeds = NavbarWidgets.getWidgetFieldValueForKey(
                                            item,
                                            'feeds'
                                        );

                                        if (!feeds || feeds.length === 0) {
                                            break;
                                        }

                                        let writableFeeds = feeds.filter((feed) => {
                                            //return workspacePermissionCheck({ id: feed.value }, 'WRITE');
                                        });

                                        if (!writableFeeds || writableFeeds.length === 0) {
                                            break;
                                        }

                                        if (writableFeeds.length === 1) {
                                            /*Composer.show({
                                                workspace: first(writableFeeds),
                                            });*/
                                        } else {
                                            let workspaces = [];

                                            writableFeeds.forEach((writableFeed) => {
                                                /*let w = Tree.findWorkspaceById(writableFeed.value);

                                                if (!w) {
                                                    return;
                                                }

                                                workspaces.push({
                                                    workspace_data: w[0].workspace_data,
                                                });*/
                                            });

                                            /*WorkspaceList.show({
                                                workspaces,
                                                type: 'action',
                                            });*/
                                        }

                                        break;
                                    case 'postToCategory':
                                        const category =
                                            NavbarWidgets.getWidgetFieldFirstValueForKey(
                                                item,
                                                'category'
                                            );

                                        if (!category) {
                                            break;
                                        }
                                        /*
                                                                                Composer.show({
                                                                                    category,
                                                                                });*/

                                        break;
                                    case 'postToAll':
                                    default:
                                        /*Composer.show();*/

                                        break;
                                }
                            }
                        };
                }
            case 'dropdown':
                const children = getChildren(item).map((child) => getItemAsState(child, item));

                let dropdownClasses = state.classes;

                if (item.extra_data !== undefined) {
                    if (!isEmpty(item.extra_data.realizations)) {
                        dropdownClasses = classNames(
                            'badge-realization ' + item.extra_data.realizations.color,
                            dropdownClasses
                        );
                    }

                    if (!isEmpty(item.extra_data.missions)) {
                        dropdownClasses = classNames(
                            'badge-mission ' + item.extra_data.missions.color,
                            dropdownClasses
                        );
                    }

                    if (!isEmpty(item.extra_data.essentials)) {
                        dropdownClasses = classNames(
                            'badge-essentials ' + item.extra_data.essentials.color,
                            dropdownClasses
                        );
                    }
                }

                return {
                    ...state,
                    classes: dropdownClasses,
                    callback: (context, navItem) => {},
                    hasBadge: (context, navItem) => {
                        return navItem.children.some((child) => {
                            if (!child.isVisible(context, child) || !child.hasBadge) {
                                return false;
                            }

                            return child.hasBadge(context, child);
                        });
                    },
                    getBadge: (context, navItem) => {
                        let count = navItem.children.reduce((acc, child) => {
                            if (
                                !child.isVisible(context, child) ||
                                !child.hasBadge ||
                                !child.hasBadge(context, child) ||
                                !child.getBadge
                            ) {
                                return acc;
                            }

                            return acc + child.getBadge(context, child);
                        }, 0);

                        return count > 0 ? count : null;
                    },
                    isActive: (context, navItem) => {
                        return navItem.children.some((child) => {
                            return (
                                child.isVisible(context, child) && child.isActive(context, child)
                            );
                        });
                    },
                    isVisible: (context, navItem) => {
                        return (
                            navItem.children.some((child) => {
                                return child.isVisible(context, child);
                            }) && state.isVisible(context, navItem)
                        );
                    },
                    children
                };
            case 'link':
                const itemStateData = getStateData(item);

                if (!itemStateData) {
                    return state;
                }

                const {
                    getCallback: callback,
                    getClasses,
                    getTemplate,
                    getUserLabel,
                    hasBadge,
                    getBadge,
                    isActive,
                    isVisible
                } = itemStateData;

                const template = getTemplate ? getTemplate() : null;
                const classes = getClasses ? getClasses() : null;

                return {
                    ...state,
                    label: null,
                    getLabel: (context) => {
                        return getUserLabel ? getUserLabel(context) : state.label;
                    },
                    name: getName(item),
                    classes: classes ? classNames(classes, state.classes) : state.classes,
                    callback,
                    template,
                    hasBadge,
                    getBadge,
                    isActive,
                    isVisible: (context, navItem) => {
                        return state.isVisible(context, navItem) && isVisible(context, navItem);
                    }
                };
            case 'externalLink':
                return {
                    ...state,
                    callback: () => {
                        const link = NavbarWidgets.getWidgetFieldValueForKey(item, 'link');
                        const linkTarget = NavbarWidgets.getWidgetFieldValueForKey(
                            item,
                            'linkTarget'
                        );

                        if (!link) {
                            return;
                        }

                        const {
                            url = null,
                            pathname = null,
                            params = null
                        } = { url: null, pathname: null, params: null };
                        /*} = Config.extractParamsFromUrl(link);*/

                        const isInternal = true;
                        /*const isInternal = Config.isInternalLink(url);*/

                        const matchedRoute = router.urlService.match({
                            path: pathname,
                            search: params,
                            hash: url === null ? null : url.hash.replace('#', '')
                        });

                        switch (linkTarget) {
                            case '_popup':
                                /*if (isInternal) {
                                    if (!matchedRoute || !matchedRoute.rule || matchedRoute.rule.type !== 'STATE') {
                                        return;
                                    }

                                    switch(matchedRoute.rule.state.name) {
                                        case 'auth.posts.post':
                                            let {
                                                workspace: workspaceId = null,
                                                post: postId = null,
                                            } = matchedRoute.match;

                                            postId = parseInt(postId);
                                            workspaceId = parseInt(workspaceId);

                                            if (!postId || !workspaceId || !User.isAllowToRead(workspaceId)) {
                                                return;
                                            }

                                            if (post && post.id === postId) {
                                                PreviewPost.show({
                                                    post: post,
                                                    overrideTitle: NavbarWidgets.getWidgetFieldValueForKey(item, 'popupTitle'),
                                                });
                                            }
                                            else {
                                                Posts.getPostById(postId).then(([postData]) => {
                                                    let {
                                                        post = null,
                                                    } = postData;

                                                    post = post;

                                                    PreviewPost.show({
                                                        post,
                                                        overrideTitle: NavbarWidgets.getWidgetFieldValueForKey(item, 'popupTitle'),
                                                    });
                                                });
                                            }

                                            break;
                                        default:
                                            Config.openUrl(link);
                                    }
                                }
                                else {
                                    if (canPlay(link)) {
                                        const hash = sha256(link).toString();
                                        const name = `widget-shortcut-${hash}`;

                                        if (!Modal.exists(name)) {
                                            popups.push({
                                                name,
                                                title: NavbarWidgets.getWidgetFieldValueForKey(item, 'popupTitle'),
                                                url: link,
                                            });
                                        }

                                        setTimeout(() => {
                                            //Modal.open(name);
                                        });
                                    }
                                    else {
                                        //Config.openUrl(link);
                                    }
                                }*/

                                break;
                            case '_self':
                                if (
                                    isInternal &&
                                    matchedRoute &&
                                    matchedRoute.rule &&
                                    matchedRoute.rule.type === 'STATE'
                                ) {
                                    router.stateService.go(
                                        matchedRoute.rule.state.name,
                                        matchedRoute.match,
                                        { reload: true, inherit: false }
                                    );
                                } else {
                                    openUrl(link, linkTarget);
                                }

                                break;
                            case '_blank':
                            default:
                                openUrl(link);
                        }
                    }
                };
            case 'image':
                return {
                    ...state,
                    callback: () => {}
                };
            default:
                return state;
        }
    };

    const getDeviceAsClass = () => {
        return isMobile() ? 'is-mobile' : 'is-desktop';
    };

    return {
        getItemAsState,
        getDeviceAsClass
    };
};

export default useGetItemAsState;
