import { useAuth } from 'providers/AuthContext';
import { useConfig } from 'providers/ConfigProvider';
import { useRouter } from '@uirouter/react';
import useRight from 'hooks/useRight';

const useUser = () => {
    const { user: currentUser, setUser } = useAuth();
    const { hasRole } = useRight();
    const { config } = useConfig();
    const router = useRouter();

    const getAvatar = (user = currentUser) => {
        if (user.image_profile_name) {
            return forgeAvatarUrl(user.image_profile_name);
        }

        return forgeAvatarUrl(user.image_profile);
    };

    const forgeAvatarUrl = (imageName) => {
        return `${config.staticUrl}download/user/avatar/${imageName}`;
    };

    const getProfileLink = (user) => {
        if (currentUser.id === user.id) {
            return router.stateService.href('auth.my-profile');
        }

        if (hasRole('ROLE_HIDE_LIST_USERS')) return;

        return router.stateService.href('auth.profile', { userId: user.id });
    };

    const goToProfileLink = (user) => {
        if (currentUser.id === user.id) {
            router.stateService.go('auth.my-profile', {}, { reload: true, inherit: false });
        }

        if (hasRole('ROLE_HIDE_LIST_USERS')) return;

        router.stateService.go(
            'auth.profile',
            { userId: user.id },
            { reload: true, inherit: false }
        );
    };

    const isSubscribedToCampaign = (campaignId) => {
        if (!currentUser.campaign_subscriptions) {
            return false;
        }

        return currentUser.campaign_subscriptions.some((subscription) => {
            return subscription.campaign_id === campaignId;
        });
    };

    const isSwitched = () => {
        return false;
    };

    const isMe = (user) => {
        return currentUser.id === user.id;
    };

    const getFeedDefaultView = () => {
        let stateName = 'posts';

        if (hasRole('HAS_STACKED_VIEW_DEFAULT') || hasRole('HAS_ESSENTIAL_VIEW_DEFAULT')) {
            stateName = hasRole('HAS_STACKED_VIEW_DEFAULT') ? 'stacked' : 'essentials';
        }

        return stateName;
    };

    return {
        getAvatar,
        goToProfileLink,
        getProfileLink,
        isSubscribedToCampaign,
        isSwitched,
        forgeAvatarUrl,
        isMe,
        getFeedDefaultView
    };
};

export default useUser;
