import React from 'react';
import Collection from './collection';
import Date from './date';
import LineSpace from './line_space';
import LongText from './long_text';
import Numeric from './numeric';
import Radio from './radio';
import Recipients from './recipients';
import Separator from './separator';
import Text from './text';
import Title from './title';
import Image from './image';
import Files from './files';
import Position from './position';

const AbstractField = ({
    input,
    type,
    post,
    download,
    tags,
    tagId,
    inCarousel,
    isPostPreview,
    alreadyLiked
}) => {
    const renderElement = () => {
        switch (type) {
            case 'text':
            case 'email':
            case 'select':
                return <Text input={input} />;
            case 'long_text':
                return <LongText input={input} />;
            case 'date':
                return <Date input={input} />;
            case 'numeric':
                return <Numeric input={input} />;
            case 'radio':
                return <Radio input={input} />;
            case 'recipients':
                return <Recipients input={input} />;
            case 'title':
                return <Title input={input} />;
            case 'line_space':
                return <LineSpace input={input} />;
            case 'separator':
                return <Separator input={input} />;
            case 'collection':
                return <Collection input={input} />;
            case 'image':
                return <Image input={input} />;
            case 'position':
                return <Position input={input} />;
            case 'files':
            case 'file':
                return (
                    <Files
                        input={input}
                        post={post}
                        inCarousel={inCarousel}
                        alreadyLiked={alreadyLiked}
                        download={download}
                        isPostPreview={isPostPreview}
                        tags={tags}
                        tagId={tagId}
                    />
                );
            default:
                return <></>;
        }
    };

    return <div className={'render_element'}>{renderElement()}</div>;
};

export default AbstractField;
