import React, { useEffect, useState } from 'react';
import useViewport from '../../hooks/useViewport';
import useRight from '../../hooks/useRight';
import { useConfig } from '../../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import Comments from '../Comments/Comments';
import Comment from '../Comment/Comment';
import useLikeService from '../../api/useLikeService';
import { useAuth } from '../../providers/AuthContext';
import Social from './components/Social';
import Survey from './components/Survey';
import Recruiting from './components/Recruiting';
import Product from './components/Product';
import SocialLight from './components/light/SocialLight';
import SurveyLight from './components/light/SuveyLight';
import RecruitingLight from './components/light/RecruitingLight';
import ProductLight from './components/light/ProductLight';

const Campaign = ({ campaignItem, forceLight = false, pushed = false, isPreview = false }) => {
    const viewport = useViewport();
    const { user } = useAuth();
    const { config } = useConfig();
    const { hasRole } = useRight();
    const { t } = useTranslation();
    const LikeService = useLikeService();

    const [campaign, setCampaign] = useState({
        ...campaignItem,
        doComment: !!campaignItem.comments.length
    });

    useEffect(() => {
        if (campaign.focusComment) {
            setTimeout(() => {
                setCampaign((prevState) => {
                    return {
                        ...prevState,
                        focusComment: false
                    };
                });
            }, 100);
        }
    }, [campaign]);

    const showLikeButton = () => {
        if (!hasRole('HAS_LIKES')) {
            return false;
        }

        return campaign.type !== 'social' || hasRole('ENABLE_LIKE_ON_SOCIAL_CAMPAIGNS');
    };

    const showComment = () => {
        return (!hasRole('HAS_LIKES') || campaign.doComment) && campaign.status;
    };

    const toggleComment = () => {
        setCampaign((prevState) => {
            return {
                ...prevState,
                focusComment: true,
                doComment: true
            };
        });
    };

    const likeHandler = () => {
        if (!campaign.inLikeProgress) {
            setCampaign((prevState) => {
                return {
                    ...prevState,
                    inLikeProgress: true
                };
            });

            let toggle = campaign.alreadyLiked ? 'deleteLike' : 'addLike';

            return LikeService[toggle](campaign.alreadyLiked ? campaign.alreadyLiked : campaign)
                .then((like) => {
                    setCampaign((prevState) => {
                        return {
                            ...prevState,
                            alreadyLiked: like ? like.id : null
                        };
                    });
                    if (like.id) {
                        setCampaign((prevState) => {
                            const likes = prevState.likes;
                            likes.push(like);

                            return {
                                ...prevState,
                                likes
                            };
                        });
                    } else {
                        setCampaign((prevState) => {
                            return {
                                ...prevState,
                                likes: prevState.likes.filter((like) => {
                                    return like.user.id !== user.id;
                                })
                            };
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error : ', err);
                })
                .finally(() => {
                    setCampaign((prevState) => {
                        return {
                            ...prevState,
                            inLikeProgress: false
                        };
                    });
                });
        }
    };

    return (
        <div className={'campaign-wrapper'}>
            {campaign != null &&
                (!viewport.xs || (viewport.xs && !pushed)) &&
                !forceLight &&
                campaign.type !== 'mission' && (
                    <div
                        className="ta_global ta_post ta-post"
                        data-id={campaign.id}
                        data-type={campaign.type}
                    >
                        <div className="ta_post__container">
                            <div className={`content ${campaign.type}`}>
                                {campaign.type === 'social' && <Social campaign={campaign} />}
                                {campaign.type === 'survey' && <Survey campaign={campaign} />}
                                {campaign.type === 'recruiting' && (
                                    <Recruiting campaign={campaign} />
                                )}
                                {campaign.type === 'product' && <Product campaign={campaign} />}
                            </div>

                            {!isPreview && (
                                <div className="post__info data">
                                    {showLikeButton() && (
                                        <a
                                            href="javascript:;"
                                            className="likes tooltip-toggle"
                                            ng-click="$ctrl.showLikers($event)"
                                        >
                                            <div>
                                                <i className="icon-like-1"></i>
                                                <span className="result">
                                                    {campaign.likes.length}
                                                </span>
                                            </div>
                                        </a>
                                    )}

                                    {!config.disable_comments_on_campaign && (
                                        <div>
                                            <i className="icon-messaging-imessage"></i>
                                            <span className="result">
                                                {campaign.comments.length}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!hasRole('ROLE_HIDE_LIKE_LIST_USERS') &&
                                !isPreview &&
                                hasRole('HAS_LIKES') &&
                                campaign.likers && (
                                    <div
                                        className={`tooltip ng-scope ${campaign.likers.length > 0 ? 'hover' : ''}`}
                                    >
                                        <div className="tooltip-content">
                                            {/*<div ng-repeat="liker in $ctrl.campaign.likers | limitTo:20">{{liker}}<br/></div>*/}
                                        </div>
                                    </div>
                                )}

                            {hasRole('HAS_LIKES') && campaign.status === 2 && (
                                <div className="post__info actions">
                                    {showLikeButton() && (
                                        <div>
                                            <button
                                                className={campaign.alreadyLiked ? 'liked' : null}
                                                onClick={likeHandler}
                                            >
                                                <span className="icon-like-1"></span>
                                                {t('POST_LIKE').toUpperCase()}
                                            </button>
                                        </div>
                                    )}
                                    {!config.disable_comments_on_campaign && (
                                        <div>
                                            <button onClick={toggleComment}>
                                                <span className="icon-messaging-imessage"></span>
                                                {t('POST_COMMENT_ACTION').toUpperCase()}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!config.disable_comments_on_campaign && !isPreview && (
                                <>
                                    {campaign.comments.length > 0 && <Comments object={campaign} />}
                                    {(showComment() || hasRole('ROLE_HIDE_LIST_USERS')) && (
                                        <div
                                            className="post_add-comment"
                                            ng-class="{ 'keyboard-closed': !$ctrl.Device.getDevice().isKeyboardOpen }"
                                        >
                                            <Comment
                                                campaignItem={campaign}
                                                submitCallback="$ctrl.commentSubmitCallbackHandler"
                                                hasFocus={campaign.focusComment}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}

            {((viewport.xs && pushed && campaign != null) || forceLight) && (
                <div className="ta_post">
                    <div className="pushed">
                        <div className={`item ${campaign.type}`}>
                            {campaign.type === 'social' && <SocialLight campaign={campaign} />}
                            {campaign.type === 'survey' && <SurveyLight campaign={campaign} />}
                            {campaign.type === 'recruiting' && (
                                <RecruitingLight campaign={campaign} />
                            )}
                            {campaign.type === 'product' && <ProductLight campaign={campaign} />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Campaign;
