import React, { createContext, useCallback, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useLevelService from 'api/useLevelService';
import { find, forEach } from 'lodash-es';

const LevelContext = createContext();

const AdminLevelProvider = ({ children }) => {
    const { getLevelConfigs, getLevels } = useLevelService();

    const { data: levels, isLoading: isLoadingLevels } = useQuery({
        queryKey: ['levels', `all_levels`],
        queryFn: () => getLevels()
    });
    const { data: levelConfigs, isLoading: isLoadingLevelConfigs } = useQuery({
        queryKey: ['levels', `level_configs`],
        queryFn: () => getLevelConfigs()
    });

    const getLevelName = useCallback(
        (index) => {
            if (levelConfigs?.length === 0) {
                return index;
            }

            let levels = levelConfigs?.filter((level) => {
                return level.index === index;
            });

            return levels && levels.length === 1 ? levels[0].name : index;
        },
        [levelConfigs]
    );

    const getMaxLevelIndex = useCallback(() => {
        let maxLevelIndex = 0;

        forEach(levels, (value) => {
            if (maxLevelIndex < value.index) {
                maxLevelIndex = value.index;
            }
        });

        return maxLevelIndex;
    }, [levels]);

    const getLevelByIndex = useCallback(
        (index) => {
            let levelsByIndex = [];

            forEach(levels, (value) => {
                if (parseInt(index) === parseInt(value.index)) {
                    levelsByIndex.push({ id: value.id, name: value.name });
                }
            });

            return levelsByIndex;
        },
        [levels]
    );

    const isLevelAlreadyConfigured = useCallback(
        (i) => {
            let found = false;
            levelConfigs?.forEach((value) => {
                if (i === value.index) {
                    found = true;
                }
            });

            return found;
        },
        [levelConfigs]
    );

    const getLevelById = useCallback(
        (id) => {
            if (levels) {
                return find(levels, (l) => l.id === parseInt(id));
            }
        },
        [levels]
    );

    const getLevelRange = useCallback(() => {
        let maxLevelIndex = 0;
        levels?.forEach((level) => {
            if (level.index > maxLevelIndex) {
                maxLevelIndex = level.index;
            }
        });

        let range = [];
        for (let i = 1; i <= maxLevelIndex; i++) {
            if (isLevelAlreadyConfigured(i)) {
                continue;
            }

            range.push({
                index: i
            });
        }

        return range;
    }, [isLevelAlreadyConfigured, levels]);

    return (
        <LevelContext.Provider
            value={{
                levels,
                levelConfigs,
                isLoadingLevels,
                isLoadingLevelConfigs,
                getLevelName,
                getMaxLevelIndex,
                getLevelByIndex,
                getLevelRange,
                getLevelById
            }}>
            {children}
        </LevelContext.Provider>
    );
};

export const useAdminLevelContext = () => useContext(LevelContext);
export default AdminLevelProvider;
