import { useState } from 'react';
import { useHttp } from 'api/useHttp';

const useUploadJoinDocuments = (url) => {
    const axios = useHttp();
    const [files, setFiles] = useState([]);
    const [progresses, setProgresses] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState({});
    const [uploaded, setUploaded] = useState({});

    const deleteFile = (name) => {
        setFiles((prevState) => {
            return prevState.filter((file) => {
                return file.name !== name;
            });
        });
    };

    const editFile = (name) => {
        setFiles((prevState) => {
            return prevState.map((file) => {
                if (file.name === name) {
                    file.editing = !!!file.editing;
                    return file;
                }
                return file;
            });
        });
    };

    const toggleVideo = (name) => {
        setFiles((prevState) => {
            return prevState.map((file) => {
                if (file.name === name) {
                    file.show = !!!file.show;
                    return file;
                }
                return file;
            });
        });
    };

    const editCustomName = (name, value) => {
        setFiles((prevState) => {
            return prevState.map((file) => {
                if (file.name === name) {
                    file.custom_name = value;
                    return file;
                }
                return file;
            });
        });
    };

    const addVideoPoster = (name, value) => {
        setFiles((prevState) => {
            return prevState.map((file) => {
                if (file.name === name) {
                    file.poster = value;
                    return file;
                }
                return file;
            });
        });
    };

    const uploadFiles = async (selectedFiles) => {
        setIsUploading(true);
        setErrors({});
        setUploaded({});
        const newFiles = Array.from(selectedFiles);
        setFiles((prevState) => {
            return [...prevState, ...newFiles];
        });

        const uploadPromises = newFiles.map((file) => {
            const formData = new FormData();
            formData.append('file', file);

            return axios
                .post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgresses((prevProgresses) => ({
                            ...prevProgresses,
                            [file.name]: percentCompleted
                        }));
                    }
                })
                .then((response) => {
                    setUploaded((prevUploaded) => ({
                        ...prevUploaded,
                        [file.name]: true
                    }));
                    setFiles((prevState) => {
                        return prevState.filter((f) => {
                            if (f.name === file.name) {
                                file.hard_name = response.data.hard_name;
                                return file;
                            }
                            return file;
                        });
                    });
                    return response.data;
                })
                .catch((err) => {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [file.name]: err
                    }));
                    setUploaded((prevUploaded) => ({
                        ...prevUploaded,
                        [file.name]: false
                    }));
                });
        });

        await Promise.all(uploadPromises);
        setIsUploading(false);
    };

    return {
        files,
        progresses,
        isUploading,
        errors,
        uploaded,
        toggleVideo,
        editFile,
        editCustomName,
        uploadFiles,
        deleteFile,
        addVideoPoster
    };
};

export default useUploadJoinDocuments;
