import React, { useState } from 'react';
import useUserAppSettingService from 'api/useUserAppSettingService';
import { useAlert } from 'providers/AlertContext';
import { useTranslation } from 'react-i18next';

const Checkbox = (props) => {
    const { postUserConfig } = useUserAppSettingService();
    const { showToast } = useAlert();
    const { t } = useTranslation();

    const [setting, setSetting] = useState(props.setting);

    const saveSetting = (setting, newValue) => {
        postUserConfig({ ...setting, value: newValue }).then(() => {
            showToast({
                text: t('SETTINGS_SAVED'),
                duration: 1500
            });
        });
    };

    return (
        <div className="input-with-label">
            <label
                className="label"
                htmlFor={`checkbox-${props.sectionIndex}-${props.settingIndex}`}
            >
                {setting.label}
            </label>

            <input
                className="switch"
                type="checkbox"
                id={`checkbox-${props.sectionIndex}-${props.settingIndex}`}
                checked={setting.value}
                onChange={(e) => {
                    setSetting((prevSetting) => {
                        return { ...prevSetting, value: e.target.checked };
                    });
                    saveSetting(setting, e.target.checked);
                }}
            />
        </div>
    );
};

export default Checkbox;
