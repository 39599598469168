import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { forEach, orderBy } from 'lodash-es';
import { FormProvider } from 'react-hook-form';
import { Autocomplete, Input, Checkbox, Radio, Select, Switch } from 'components/Form/Inputs';
import useRight from 'hooks/useRight';
import useEmployeeService from 'api/useEmployeeService';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import useUserService from 'api/useUserService';
import { useAdminTreeContext } from '../../../providers/AdminTreeProvider';
import { useQuery } from '@tanstack/react-query';
import useWorkspaceService from 'api/useWorkspaceService';
import { useForm } from 'components/Form/hooks';
import userFormSchema from '../schemas/userForm.schema.json';
import { useModal } from '../../../../../providers/ModalContext';

const UserFormModalContent = () => {
    const { t } = useTranslation();
    const {
        contexts: {
            add_edit_user: { currentUser }
        },
        closeModal
    } = useModal();
    const { findEmployeeByCriteria } = useEmployeeService();
    const { findUsersByCriteria } = useUserService();
    const { hasRole } = useRight();
    const { levels } = useAdminLevelContext();
    const { fullTree, filterHiddenWorkspaces } = useAdminTreeContext();
    const { getWorkspaceRulesByUser, getWorkspaceAdminRulesByUser } = useWorkspaceService();

    const [addLevelsToEmployee, setAddLevelsToEmployee] = useState(false);

    const methods = useForm({
        schema: userFormSchema
    });

    const { data: workspaceSubscriptions } = useQuery({
        queryFn: () => {
            if (currentUser?.id) {
                return Promise.all([
                    getWorkspaceAdminRulesByUser(currentUser?.id),
                    getWorkspaceRulesByUser(currentUser?.id)
                ]).then((data) => {
                    const workspace_rules = [];
                    forEach(data[0], (rule) => {
                        workspace_rules[rule.workspace.id] = {};
                        workspace_rules[rule.workspace.id].is_admin = true;
                    });

                    forEach(data[1], function (rule) {
                        if (!workspace_rules[rule.workspace.id]) {
                            workspace_rules[rule.workspace.id] = {};
                        }
                        workspace_rules[rule.workspace.id].is_readable = rule.is_readable;
                        workspace_rules[rule.workspace.id].is_half_readable = rule.is_half_readable;
                        workspace_rules[rule.workspace.id].is_writable = rule.is_writable;
                    });

                    return workspace_rules;
                });
            }
            return [];
        },
        queryKey: ['userWorkspaceSubscription', currentUser?.id]
    });

    const onFinish = (data) => {
        console.log('data', data);
    };

    const fetchEmployeeList = async (username) => {
        return findEmployeeByCriteria(username, true).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.first_name} ${r.last_name}`,
                    value: r.id
                };
            });
        });
    };

    const fetchUserList = async (username) => {
        return findUsersByCriteria(username, [currentUser?.id]).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.name}`,
                    value: r.id
                };
            });
        });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onFinish)} className="user-panel">
                <div className="user-panel-body scrollable">
                    <div className="user-panel-content">
                        <div className="user-info form">
                            <div className="title">
                                {t('ADMIN_USERS_DIALOG_GENERAL_TITLE_LABEL')} (ID: {currentUser?.id}
                                )
                            </div>

                            <div className="items">
                                <div className="item">
                                    <label className="label" htmlFor="last_name">
                                        {t('ADMIN_USERS_DIALOG_FIRSTNAME_LABEL')}
                                    </label>
                                    <Input
                                        value={currentUser?.first_name || null}
                                        name={'first_name'}
                                    />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="last_name">
                                        {t('ADMIN_USERS_DIALOG_NAME_LABEL')}
                                    </label>
                                    <Input
                                        value={currentUser?.last_name || null}
                                        name={'last_name'}
                                    />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="email">
                                        {t('ADMIN_USERS_DIALOG_EMAIL_LABEL')}
                                    </label>
                                    <Input
                                        value={currentUser?.email || null}
                                        name={'email'}
                                        type={'email'}
                                    />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="job">
                                        {t('ADMIN_USERS_DIALOG_JOB_LABEL')}
                                    </label>
                                    <Input value={currentUser?.job || null} name={'job'} />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="city">
                                        {t('ADMIN_USERS_DIALOG_CITY_LABEL')}
                                    </label>
                                    <Input value={currentUser?.city || null} name={'city'} />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="company">
                                        {t('ADMIN_USERS_DIALOG_COMPANY_LABEL')}
                                    </label>
                                    <Input value={currentUser?.company || null} name={'company'} />
                                </div>

                                <div className="item">
                                    <label className="label" htmlFor="mobile_phone_number">
                                        {t('ADMIN_USERS_DIALOG_MOBILE_LABEL')}
                                    </label>
                                    <Input
                                        value={currentUser?.mobile_phone_number || null}
                                        name={'mobile_phone_number'}
                                    />
                                </div>

                                {!currentUser?.id && (
                                    <div className="item">
                                        <label className="label" htmlFor="password">
                                            {t('ADMIN_USERS_PASSWORD_LABEL')}
                                        </label>
                                        <Input value={null} name={'password'} />
                                    </div>
                                )}

                                <div className="item">
                                    <label className="label" htmlFor="rapport">
                                        {t('ADMIN_USERS_DIALOG_RAPPORT_LABEL')}
                                    </label>
                                    <Select
                                        name={'activity_report_interval'}
                                        style={{
                                            width: '100%'
                                        }}
                                        value={currentUser?.activity_report_interval || 1}
                                        options={[
                                            {
                                                value: -1,
                                                label: t(
                                                    'ADMIN_USERS_DIALOG_ACTIVITY_REPORT_DISABLE'
                                                )
                                            },
                                            {
                                                value: 1,
                                                label: t(
                                                    'ADMIN_USERS_DIALOG_ACTIVITY_REPORT_EVERYDAY'
                                                )
                                            },
                                            {
                                                id: 7,
                                                label: t(
                                                    'ADMIN_USERS_DIALOG_ACTIVITY_REPORT_EVERYWEEK'
                                                )
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                        {currentUser?.id && hasRole('HAS_EMPLOYEE_ACCOUNT') && (
                            <div className="form user-employee">
                                <div className="title">
                                    {t('ADMIN_USERS_DIALOG_EMPLOYEE_INFORMATIONS')}
                                </div>

                                <div className="autocomplete">
                                    <Autocomplete
                                        value={
                                            currentUser?.employee
                                                ? {
                                                      value: currentUser.employee.id,
                                                      label: `${currentUser.employee.first_name} ${currentUser.employee.last_name}`
                                                  }
                                                : null
                                        }
                                        fetchOptions={fetchEmployeeList}
                                        name={'employee.id'}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </div>

                                {currentUser.employee && currentUser.employee.levels.length > 0 && (
                                    <div className="employee-details">
                                        <div className="title">
                                            {t('ADMIN_USERS_DIALOG_EMPLOYEE_LEVELS_DETAILS')}
                                        </div>
                                        {orderBy(currentUser.employee.levels, ['index']).map(
                                            (level) => {
                                                return (
                                                    <div
                                                        className="employee-detail"
                                                        key={`level_${level.id}`}>
                                                        {level.index}: {level.name}
                                                    </div>
                                                );
                                            }
                                        )}

                                        <div
                                            className="employee-detail add-level is-clickable"
                                            onClick={() => {
                                                setAddLevelsToEmployee(!addLevelsToEmployee);
                                            }}>
                                            {t('ADMIN_LEVELS_ADD')}
                                        </div>
                                    </div>
                                )}

                                {addLevelsToEmployee && (
                                    <>
                                        <div className="autocomplete">
                                            <Select
                                                value={
                                                    currentUser?.employee?.levels
                                                        ? currentUser?.employee?.levels.map((l) => {
                                                              return {
                                                                  label: l.name,
                                                                  value: l.id
                                                              };
                                                          })
                                                        : null
                                                }
                                                mode={'multiple'}
                                                name={'employeeLevels'}
                                                style={{
                                                    width: '100%'
                                                }}
                                                options={levels.map((l) => {
                                                    return {
                                                        label: l.name,
                                                        value: l.id
                                                    };
                                                })}
                                            />
                                        </div>
                                    </>
                                    /*<ui-select ng-model-options="{ debounce: 0 }" close-on-select="false"
                                                      ng-if="$ctrl.addlevelsToEmployee" ng-model="$ctrl.employeeLevels" multiple
                                                      theme="select2" style="width: 100%;">
                                               <ui-select-match
                                                   ng-attr-placeholder="{ t('ADMIN_LEVELS_ADD_EMPLOYEE') }">
                                                   <div className="workspace">
                                                       <div className="meta">
                                                           <div className="name">{$item.index}: {$item.name}</div>
                                                       </div>
                                                   </div>
                                               </ui-select-match>
                                               <ui-select-choices
                                                   repeat="item in ($ctrl.levels | filter: { 'name': $select.search }) track by $index">
                                                   <div className="workspace">
                                                       <div className="desc">
                                                           <div className="name">{item.index}: {item.name}</div>
                                                       </div>
                                                   </div>
                                               </ui-select-choices>
                                           </ui-select>*/
                                )}
                            </div>
                        )}

                        {currentUser?.id && (
                            <>
                                <div className="form user-organizational-units">
                                    <div className="title">
                                        {t('USER_ORGANISATIONAL_UNITS_ALREADY_LINKED')}
                                    </div>

                                    {/*<ui-select ng-model-options="{ debounce: 0 }" ng-disabled="true"
                                               close-on-select="false"
                                               ng-model="currentUser.organizationalUnitsAlreadySelected" multiple
                                               theme="select2"
                                               style="width: 100%;">
                                        <ui-select-match
                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                            <div className="workspace">
                                                <div className="meta">
                                                    <div className="name">
                                                        {$item.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </ui-select-match>

                                        <ui-select-choices
                                            repeat="item in $ctrl.organizationalUnits.linkedToUser track by item.id">
                                            <div className="workspace">
                                                <div className="desc">
                                                    <div className="name">{item.name}</div>
                                                </div>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>*/}
                                </div>

                                <div className="form user-organizational-units">
                                    <div className="title">{t('USER_ORGANISATIONAL_UNITS')}</div>

                                    {/*<ui-select ng-model-options="{ debounce: 0 }" close-on-select="false"
                                               ng-model="currentUser.manualOrganizationalUnitsSelected" multiple
                                               theme="select2"
                                               style="width: 100%;">
                                        <ui-select-match ng-attr-placeholder="{ t('WRITE_OU_NAMES') }">
                                            <div className="workspace">
                                                <div className="meta">
                                                    <div className="name">
                                                        {$item.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </ui-select-match>

                                        <ui-select-choices
                                            repeat="item in ($ctrl.remainingOrganizationalUnits | filter: { 'name': $select.search }) track by item.id">
                                            <div className="workspace">
                                                <div className="desc">
                                                    <div className="name">{item.name}</div>
                                                </div>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>*/}
                                </div>

                                <div className="user-permissions form">
                                    <div className="title">
                                        {t('ADMIN_USERS_DIALOG_PERMISSIONS_TITLE_LABEL')}
                                    </div>

                                    <div className="tabs">
                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf('ROLE_MLC') !== -1
                                                }
                                                name={`update_roles[ROLE_MLC]`}
                                                label={t('ROLE_MLC')}
                                            />
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf('ROLE_INTERNAL') !==
                                                    -1
                                                }
                                                name={`update_roles[ROLE_INTERNAL]`}
                                                label={t('ROLE_INTERNAL')}
                                            />
                                        </div>

                                        {hasRole('ROLE_SUPER_ADMIN') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf('ROLE_ADMIN') !==
                                                        -1
                                                    }
                                                    name={`update_roles[ROLE_ADMIN]`}
                                                    label={t('ROLE_ADMIN')}
                                                />
                                            </div>
                                        )}

                                        {hasRole('ROLE_SUPER_ADMIN') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf(
                                                            'ROLE_SUPER_ADMIN'
                                                        ) !== -1
                                                    }
                                                    name={`update_roles[ROLE_SUPER_ADMIN]`}
                                                    label={t('ROLE_SUPER_ADMIN')}
                                                />
                                            </div>
                                        )}

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_ADMIN_BLOG'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_ADMIN_BLOG]`}
                                                label={t('ROLE_ADMIN_BLOG')}
                                            />
                                        </div>

                                        {hasRole('ROLE_SUPER_ADMIN') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf('ROLE_API') !==
                                                        -1
                                                    }
                                                    name={`update_roles[ROLE_API]`}
                                                    label={t('ROLE_API')}
                                                />
                                            </div>
                                        )}

                                        {hasRole('ROLE_SUPER_ADMIN') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf(
                                                            'ROLE_METRICS'
                                                        ) !== -1
                                                    }
                                                    name={`update_roles[ROLE_METRICS]`}
                                                    label={t('ROLE_METRICS')}
                                                />
                                            </div>
                                        )}

                                        {/*<div className="user-level-restrict"
                                                 ng-if="currentUser.update_roles['ROLE_METRICS']">
                                                <div className="select">
                                                    <label
                                                        className="label">{t('ROLE_METRICS_LEVELS_RESTRICT')}</label>
                                                    <ui-select ng-model-options="{ debounce: 0 }" close-on-select="true"
                                                               ng-model="currentUser.restricted_level_configs" multiple
                                                               theme="select2">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('WRITE_LEVEL_NAMES') }">
                                                            <div className="workspace">
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in $ctrl.levelsConfigs track by item.id">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>
                                                </div>
                                            </div>*/}

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf('ROLE_RESOURCES') !==
                                                    -1
                                                }
                                                name={`update_roles[ROLE_RESOURCES]`}
                                                label={t('ROLE_RESOURCES')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {methods.watch('update_roles[ROLE_API]') && (
                                    <div className="api-key form">
                                        <div className="title">API KEY</div>
                                        <div className="row">
                                            <input
                                                type="text"
                                                ng-model="currentUser.api_key"
                                                disabled
                                            />
                                            <button
                                                className="button"
                                                ng-click="$ctrl.generateApiKey()">
                                                <i className="fa fa-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="user-campaign-permissions form">
                                    <div className="title">
                                        {t('ADMIN_USERS_DIALOG_CAMPAIGN_PERMISSIONS_TITLE_LABEL')}
                                    </div>

                                    <div className="tabs">
                                        <div className="tab">
                                            <Checkbox
                                                value={currentUser?.roles.indexOf('ROLE_RS') !== -1}
                                                name={`update_roles[ROLE_RS]`}
                                                label={t('TA_SOCIAL_TITLE')}
                                            />

                                            {methods.watch(`update_roles[ROLE_RS]`) && (
                                                <div className="config">
                                                    <div className="title">
                                                        {t(
                                                            'ADMIN_USERS_DIALOG_CAMPAIGNS_RESTRICT_TITLE_LABEL'
                                                        )}
                                                    </div>

                                                    <Radio
                                                        name={`campaign_restricted_targets[ROLE_RS]`}
                                                        value={
                                                            currentUser
                                                                ?.campaign_restricted_targets[
                                                                'ROLE_RS'
                                                            ]
                                                        }
                                                        options={[
                                                            {
                                                                value: 0,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_ALL'
                                                                )
                                                            },
                                                            {
                                                                value: 1,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_INTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 2,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_EXTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 3,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_WORKSPACE'
                                                                )
                                                            },
                                                            {
                                                                value: 5,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_USER_ORGANIZATIONAL_UNITS'
                                                                )
                                                            }
                                                        ]}
                                                    />

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_workspaces['ROLE_RS']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_RS'] == 3"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>
                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspaces | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>

                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{t('WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_user_organizational_units['ROLE_RS']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_RS'] == 5"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_USER_ORGANIZATIONAL_UNITS') }">
                                                            <div className="user_organizational_units">
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.targetableOrganizationalUnits | filter: { 'name': $select.search }) track by item.id">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_RECRUITING'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_RECRUITING]`}
                                                label={t('TA_RECRUITING_TITLE')}
                                            />
                                            {methods.watch(`update_roles[ROLE_RECRUITING]`) && (
                                                <div className="config">
                                                    <div className="title">
                                                        {t(
                                                            'ADMIN_USERS_DIALOG_CAMPAIGNS_RESTRICT_TITLE_LABEL'
                                                        )}
                                                    </div>

                                                    <Radio
                                                        name={`campaign_restricted_targets[ROLE_RECRUITING]`}
                                                        value={
                                                            currentUser
                                                                ?.campaign_restricted_targets[
                                                                'ROLE_RECRUITING'
                                                            ]
                                                        }
                                                        options={[
                                                            {
                                                                value: 0,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_ALL'
                                                                )
                                                            },
                                                            {
                                                                value: 1,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_INTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 2,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_EXTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 3,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_WORKSPACE'
                                                                )
                                                            },
                                                            {
                                                                value: 5,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_USER_ORGANIZATIONAL_UNITS'
                                                                )
                                                            }
                                                        ]}
                                                    />

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_workspaces['ROLE_RECRUITING']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_RECRUITING'] == 3"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        $ctrl
                                                                            .getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>
                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspaces | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>

                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{'WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_user_organizational_units['ROLE_RECRUITING']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_RECRUITING'] == 5"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_USER_ORGANIZATIONAL_UNITS') }">
                                                            <div className="user_organizational_units">
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.organizationalUnits | filter: { 'name': $select.search }) track by item.id">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf('ROLE_PRODUCT') !==
                                                    -1
                                                }
                                                name={`update_roles[ROLE_PRODUCT]`}
                                                label={t('TA_PRODUCT_TITLE')}
                                            />

                                            {methods.watch(`update_roles[ROLE_PRODUCT]`) && (
                                                <div className="config">
                                                    <div className="title">
                                                        {t(
                                                            'ADMIN_USERS_DIALOG_CAMPAIGNS_RESTRICT_TITLE_LABEL'
                                                        )}
                                                    </div>

                                                    <Radio
                                                        name={`campaign_restricted_targets[ROLE_PRODUCT]`}
                                                        value={
                                                            currentUser
                                                                ?.campaign_restricted_targets[
                                                                'ROLE_PRODUCT'
                                                            ]
                                                        }
                                                        options={[
                                                            {
                                                                value: 0,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_ALL'
                                                                )
                                                            },
                                                            {
                                                                value: 1,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_INTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 2,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_EXTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 3,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_WORKSPACE'
                                                                )
                                                            },
                                                            {
                                                                value: 5,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_USER_ORGANIZATIONAL_UNITS'
                                                                )
                                                            }
                                                        ]}
                                                    />

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_workspaces['ROLE_PRODUCT']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_PRODUCT'] == 3"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        $ctrl
                                                                            .getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>
                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspaces | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>

                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{'WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_user_organizational_units['ROLE_PRODUCT']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_PRODUCT'] == 5"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_USER_ORGANIZATIONAL_UNITS') }">
                                                            <div className="user_organizational_units">
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.organizationalUnits | filter: { 'name': $select.search }) track by item.id">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="tab">
                                            <div className="checkbox">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf(
                                                            'ROLE_SURVEY'
                                                        ) !== -1
                                                    }
                                                    name={'update_roles[ROLE_SURVEY]'}
                                                    label={t('TA_SURVEY_TITLE')}
                                                />
                                            </div>

                                            {methods.watch(`update_roles[ROLE_SURVEY]`) && (
                                                <div className="config">
                                                    <div className="title">
                                                        {t(
                                                            'ADMIN_USERS_DIALOG_CAMPAIGNS_RESTRICT_TITLE_LABEL'
                                                        )}
                                                    </div>
                                                    <Radio
                                                        name={`campaign_restricted_targets[ROLE_SURVEY]`}
                                                        value={
                                                            currentUser
                                                                ?.campaign_restricted_targets[
                                                                'ROLE_SURVEY'
                                                            ]
                                                        }
                                                        options={[
                                                            {
                                                                value: 0,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_ALL'
                                                                )
                                                            },
                                                            {
                                                                value: 1,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_INTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 2,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_EXTERNAL_ONLY'
                                                                )
                                                            },
                                                            {
                                                                value: 3,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_WORKSPACE'
                                                                )
                                                            },
                                                            {
                                                                value: 5,
                                                                label: t(
                                                                    'TA_CREATE_CAMPAIGN_TARGET_USER_ORGANIZATIONAL_UNITS'
                                                                )
                                                            }
                                                        ]}
                                                    />

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_workspaces['ROLE_SURVEY']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_SURVEY'] == 3"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        $ctrl
                                                                            .getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>
                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspaces | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>

                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{'WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_user_organizational_units['ROLE_SURVEY']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_SURVEY'] == 5"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_USER_ORGANIZATIONAL_UNITS') }">
                                                            <div className="user_organizational_units">
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.organizationalUnits | filter: { 'name': $select.search }) track by item.id">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {hasRole('HAS_MISSIONS') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf(
                                                            'ROLE_MISSION'
                                                        ) !== -1
                                                    }
                                                    name={`update_roles[ROLE_MISSION]`}
                                                    label={t('MISSIONS')}
                                                />
                                                {methods.watch(`update_roles[ROLE_MISSION]`) && (
                                                    <div className="config">
                                                        <div className="title">
                                                            {t(
                                                                'ADMIN_USERS_DIALOG_CAMPAIGNS_RESTRICT_TITLE_LABEL'
                                                            )}
                                                        </div>

                                                        <Radio
                                                            name={`campaign_restricted_targets[ROLE_MISSION]`}
                                                            value={
                                                                currentUser
                                                                    ?.campaign_restricted_targets[
                                                                    'ROLE_MISSION'
                                                                ]
                                                            }
                                                            options={[
                                                                {
                                                                    value: 0,
                                                                    label: t(
                                                                        'TA_CREATE_CAMPAIGN_TARGET_ALL'
                                                                    )
                                                                },
                                                                {
                                                                    value: 4,
                                                                    label: t('BY_LEVEL')
                                                                }
                                                            ]}
                                                        />

                                                        <div className="workspaces">
                                                            {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               ng-model="currentUser.campaign_restricted_workspaces['ROLE_MISSION']"
                                                               multiple theme="select2"
                                                               ng-if="currentUser.campaign_restricted_targets['ROLE_MISSION'] == 3"
                                                               style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        $ctrl
                                                                            .getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>

                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspaces | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>

                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{'WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                        </div>

                                                        {/*<div className="workspaces levels">
                                                    <div
                                                        ng-repeat="i in ::$ctrl.getMaxLevelIndex() | range track by $index"
                                                        className="items"
                                                        ng-if="currentUser.campaign_restricted_targets['ROLE_MISSION'] == 4">
                                                        <div className="item full"
                                                             ng-if="$index === ($ctrl.selectedMissionLevel - 1)">
                                                            <label className="label">{$ctrl.getLevelName($index + 1)} ({
                                                                $ctrl
                                                                    .user.missions_manager_levels.levelsIds[($index + 1)].length
                                                            })</label>
                                                            <select className="select-multiple"
                                                                    name="user_{ currentUser.id }_missions_manager_level[]"
                                                                    ng-model="currentUser.missions_manager_levels.levelsIds[($index + 1)]"
                                                                    ng-options="option.name for option in $ctrl.getLevelByIndex(($index + 1)) | orderBy:'name' track by option.id"
                                                                    ng-multiple="true" multiple>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_TOP_AMBASSADOR'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_TOP_AMBASSADOR]`}
                                                label={t('TA_TOP_AMBASSADOR_TITLE')}
                                            />

                                            {methods.watch(`update_roles[ROLE_TOP_AMBASSADOR]`) && (
                                                <div className="config">
                                                    <div className="title">
                                                        {t(
                                                            'ADMIN_USERS_DIALOG_POSTS_RESTRICT_TITLE_LABEL'
                                                        )}
                                                    </div>

                                                    <div className="workspaces">
                                                        {/*<ui-select ng-model-options="{ debounce: 0 }"
                                                               close-on-select="false"
                                                               ng-model="currentUser.post_restricted_workspaces"
                                                               multiple
                                                               theme="select2" style="width: 100%;">
                                                        <ui-select-match
                                                            ng-attr-placeholder="{ t('TA_CREATE_CAMPAIGN_CHOOSE_WORKSPACE') }">
                                                            <div className="workspace">
                                                                <div className="icon"
                                                                     ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                    <img
                                                                        ng-src="{ $ctrl.Workspaces.forgeIconUrl($item.icon_name) }"/>
                                                                </div>

                                                                <div className="icon"
                                                                     ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                     ng-style="::{ 'background-color': $item.icon_color }">
                                                                    <div className="letter">{
                                                                        $ctrl
                                                                            .getFirstLetter($item.name)
                                                                    }</div>
                                                                </div>

                                                                <div className="meta">
                                                                    <div className="name">
                                                                        {$item.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ui-select-match>
                                                        <ui-select-choices
                                                            repeat="item in ($ctrl.allPublicWorkspacesWithOwner | filter: { 'name': $select.search }) track by item.id"
                                                            group-by="$ctrl.filterWorkspacesByCategory"
                                                            group-filter="$ctrl.sortWorkspaceSelectionGroupsByName">
                                                            <div className="workspace">
                                                                <div className="desc">
                                                                    <div className="icon"
                                                                         ng-if="!currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')">
                                                                        <img
                                                                            ng-src="{ $ctrl.Workspaces.forgeIconUrl(item.icon_name) }"/>
                                                                    </div>

                                                                    <div className="icon"
                                                                         ng-if="currentUser.hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE')"
                                                                         ng-style="::{ 'background-color': item.icon_color }">
                                                                        <div className="letter">{
                                                                            $ctrl
                                                                                .getFirstLetter(item.name)
                                                                        }</div>
                                                                    </div>
                                                                    <div className="name">{item.name}</div>
                                                                </div>
                                                                <div className="subscribers">
                                                                    {item.reader} <span
                                                                    ng-if="item.reader == 1 || item.reader == 0">{t('WORKSPACE_READER')}</span><span
                                                                    ng-if="item.reader > 1">{t('WORKSPACE_READERS')}</span>
                                                                </div>
                                                            </div>
                                                        </ui-select-choices>
                                                    </ui-select>*/}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="user-campaign-permissions form">
                                    <div className="title">
                                        {t(
                                            'ADMIN_USERS_DIALOG_SUPER_CAMPAIGN_PERMISSIONS_TITLE_LABEL'
                                        )}
                                    </div>
                                    <div className="sub-title">
                                        {t('ADMIN_USERS_DIALOG_SUPER_CAMPAIGN_PERMISSIONS_TEXT')}
                                    </div>

                                    <div className="tabs">
                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_SUPER_SOCIAL'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_SUPER_SOCIAL]`}
                                                label={t('TA_SOCIAL_TITLE')}
                                            />
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_SUPER_RECRUITING'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_SUPER_RECRUITING]`}
                                                label={t('TA_RECRUITING_TITLE')}
                                            />
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_SUPER_PRODUCT'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_SUPER_PRODUCT]`}
                                                label={t('TA_PRODUCT_TITLE')}
                                            />
                                        </div>

                                        <div className="tab">
                                            <Checkbox
                                                value={
                                                    currentUser?.roles.indexOf(
                                                        'ROLE_SUPER_SURVEY'
                                                    ) !== -1
                                                }
                                                name={`update_roles[ROLE_SUPER_SURVEY]`}
                                                label={t('TA_SURVEY_TITLE')}
                                            />
                                        </div>

                                        {hasRole('HAS_MISSIONS') && (
                                            <div className="tab">
                                                <Checkbox
                                                    value={
                                                        currentUser?.roles.indexOf(
                                                            'ROLE_SUPER_MISSION'
                                                        ) !== -1
                                                    }
                                                    name={`update_roles[ROLE_SUPER_MISSION]`}
                                                    label={t('TA_SURVEY_TITLE')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="user-workspaces form">
                                    <div
                                        className="user-workspaces-nav"
                                        style={{ display: 'flex' }}>
                                        <div className="title">
                                            {t('ADMIN_USERS_DIALOG_SUBSCRIPTIONS_TITLE_LABEL')}
                                        </div>

                                        <button
                                            className="reset button danger"
                                            type="button"
                                            ng-click="$ctrl.resetWorkspaceRules()">
                                            {t('ADMIN_USERS_DIALOG_SUBSCRIPTIONS_RESET_LABEL')}
                                        </button>
                                    </div>

                                    <div className="autocomplete">
                                        <Autocomplete
                                            fetchOptions={fetchUserList}
                                            name={'to_copy.id'}
                                        />
                                    </div>

                                    <div className="workspaces">
                                        <div className="title">
                                            {t('ADMIN_USERS_DIALOG_WORKSPACES_TITLE_LABEL')}
                                        </div>

                                        {fullTree &&
                                            workspaceSubscriptions &&
                                            Object.values(fullTree).length > 0 &&
                                            Object.values(fullTree).map((cat, index) => {
                                                if (
                                                    filterHiddenWorkspaces(cat.workspaces)
                                                        .length === 0
                                                )
                                                    return;
                                                return (
                                                    <div className="category" key={`cat_${index}`}>
                                                        <div className="row heading">
                                                            <div className="workspace">
                                                                {cat?.category?.parent && (
                                                                    <span>
                                                                        {cat.category.parent.name} /{' '}
                                                                    </span>
                                                                )}
                                                                {cat.category.name}
                                                            </div>
                                                            <div className="manager">
                                                                {t(
                                                                    'ADMIN_USERS_DIALOG_MANAGER_LABEL'
                                                                )}
                                                            </div>
                                                            <div className="half-read">
                                                                {t(
                                                                    'ADMIN_USERS_DIALOG_HALF_SUBCRIBER_LABEL'
                                                                )}
                                                            </div>
                                                            <div className="read">
                                                                {t(
                                                                    'ADMIN_USERS_DIALOG_SUBCRIBER_LABEL'
                                                                )}
                                                            </div>
                                                            <div className="write">
                                                                {t(
                                                                    'ADMIN_USERS_DIALOG_CONTRIB_LABEL'
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="children">
                                                            {filterHiddenWorkspaces(
                                                                cat.workspaces
                                                            ).map((ws) => {
                                                                return (
                                                                    <div
                                                                        className="row"
                                                                        key={`ws_${ws.workspace_data.workspace.id}`}>
                                                                        <div className="workspace">
                                                                            {
                                                                                ws.workspace_data
                                                                                    .workspace.id
                                                                            }{' '}
                                                                            /{' '}
                                                                            {
                                                                                ws.workspace_data
                                                                                    .workspace.name
                                                                            }
                                                                        </div>
                                                                        <div className="manager">
                                                                            <Switch
                                                                                name={`workspace_admin_rules[${ws.workspace_data.workspace.id}].is_admin`}
                                                                                value={
                                                                                    workspaceSubscriptions[
                                                                                        ws
                                                                                            .workspace_data
                                                                                            .workspace
                                                                                            .id
                                                                                    ]?.is_admin ||
                                                                                    false
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="half-read">
                                                                            {ws.workspace_data
                                                                                .workspace
                                                                                .silent_mode && (
                                                                                <Switch
                                                                                    value={
                                                                                        workspaceSubscriptions[
                                                                                            ws
                                                                                                .workspace_data
                                                                                                .workspace
                                                                                                .id
                                                                                        ]
                                                                                            ?.is_half_readable ||
                                                                                        false
                                                                                    }
                                                                                    name={`workspace_rules[${ws.workspace_data.workspace.id}].is_half_readable`}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="read">
                                                                            <Switch
                                                                                value={
                                                                                    workspaceSubscriptions[
                                                                                        ws
                                                                                            .workspace_data
                                                                                            .workspace
                                                                                            .id
                                                                                    ]
                                                                                        ?.is_readable ||
                                                                                    false
                                                                                }
                                                                                name={`workspace_rules[${ws.workspace_data.workspace.id}].is_readable`}
                                                                            />
                                                                        </div>
                                                                        <div className="write">
                                                                            <Switch
                                                                                value={
                                                                                    workspaceSubscriptions[
                                                                                        ws
                                                                                            .workspace_data
                                                                                            .workspace
                                                                                            .id
                                                                                    ]
                                                                                        ?.is_writable ||
                                                                                    false
                                                                                }
                                                                                name={`workspace_rules[${ws.workspace_data.workspace.id}].is_writable`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="user-panel-footer">
                    <button
                        className="button"
                        type="submit"
                        ng-disabled="!$ctrl.isSubmittable()"
                        ng-click="$ctrl.save()">
                        <span ng-if="currentUser.id">{t('ADMIN_USERS_DIALOG_SAVE_LABEL')}</span>
                        <span ng-if="!currentUser.id">
                            {t('ADMIN_USERS_DIALOG_CREATE_USER_LABEL')}
                        </span>
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

const UserFormModal = () => {
    const { t } = useTranslation();
    return (
        <Modal
            name={`add_edit_user`}
            className="user-panel-panel"
            title={t('ADMIN_USERS_DIALOG_TITLE_LABEL')}
            options={{
                isFullscreen: true,
                hasFlexContent: true
            }}>
            <UserFormModalContent />
        </Modal>
    );
};

export default UserFormModal;
