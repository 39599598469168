import { FormProvider } from 'react-hook-form';

const ModalForm = ({ methods, children, ...props }) => {
    return (
        <FormProvider {...methods}>
            <form className="form-wrapper" {...props}>
                {children}
            </form>
        </FormProvider>
    );
};

export default ModalForm;
