import { useHttp } from './useHttp';

const useEmployeeService = () => {
    const axios = useHttp();

    const getAllEmployeesV2 = (
        page,
        query = null,
        letter = null,
        levels = null,
        employee_id = null
    ) => {
        return axios
            .get(`/employees/v2`, {
                params: {
                    page,
                    letter,
                    query,
                    levels,
                    employee_id
                }
            })
            .then((response) => response.data);
    };

    const findEmployeeByCriteria = (criteria, availableOnly = true) => {
        return axios
            .get(`/employees/autocomplete`, {
                params: {
                    criteria,
                    availableOnly: availableOnly
                }
            })
            .then((response) => response.data);
    };

    return {
        getAllEmployeesV2,
        findEmployeeByCriteria
    };
};

export default useEmployeeService;
