import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import useTree from '../../../../hooks/useTree';

const ViewsSelector = () => {
    const { t } = useTranslation();
    const { isView, goToView } = useTree();
    const { hasRole } = useRight();

    if (!hasRole('HAS_STACKED_VIEW') && !hasRole('HAS_ESSENTIAL_VIEW')) return;

    return (
        <div className="views">
            {hasRole('HAS_ESSENTIAL_VIEW') && (
                <a
                    className={`view ${isView('essentials') ? 'active' : ''}`}
                    ng-click="$ctrl.goToEssentials()"
                >
                    {t('ESSENTIAL_VIEW')}
                </a>
            )}

            {hasRole('HAS_STACKED_VIEW') && (
                <a
                    className={`view ${isView('stacked') ? 'active' : ''}`}
                    href={goToView('stacked', true)}
                >
                    {t('STACKED_VIEW')}
                </a>
            )}

            <a className={`view ${isView('posts') ? 'active' : ''}`} href={goToView('posts', true)}>
                {t('DEFAULT_VIEW')}
            </a>
        </div>
    );
};

export default ViewsSelector;
