import { Input } from 'components/Form/Inputs';
import { useTranslation } from 'react-i18next';

const Labels = ({ input }) => {
    const { t } = useTranslation();

    const isFieldType = (type) => {
        return input.type === type;
    };

    return (
        <>
            <Input
                name={`workspace_form_inputs[${input.uniqueId}].type`}
                value={input.type}
                type={'hidden'}
                shouldUnregister={false}
            />
            <Input
                name={`workspace_form_inputs[${input.uniqueId}].uniqueId`}
                value={input.uniqueId}
                type={'hidden'}
                shouldUnregister={false}
            />
            <Input
                name={`workspace_form_inputs[${input.uniqueId}].id`}
                value={input.id}
                type={'hidden'}
                shouldUnregister={false}
            />
            {!(isFieldType('title') || isFieldType('image')) && (
                <>
                    <Input
                        name={`workspace_form_inputs[${input.uniqueId}].label`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_LABEL')}
                        shouldUnregister={false}
                    />
                    <Input
                        name={`workspace_form_inputs[${input.uniqueId}].label_render`}
                        label={t('WORKSPACE_CUSTOM_FORM_FIELD_LABEL_RENDER')}
                        shouldUnregister={false}
                    />
                    {!isFieldType('page') && (
                        <Input
                            name={`workspace_form_inputs[${input.uniqueId}].explanation`}
                            label={t('WORKSPACE_CUSTOM_FORM_FIELD_EXPLANATION')}
                            shouldUnregister={false}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Labels;
