import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useChannelService from 'api/useChannelService';
import useRight from 'hooks/useRight';

const ChannelsContext = createContext();

const ChannelsProvider = ({ children }) => {
    const { hasRole } = useRight();
    const { getChannels } = useChannelService();

    const [channels, setChannels] = useState([]);

    const { data, isLoading } = useQuery({
        queryKey: ['user_channels', 'channels'],
        queryFn: () => {
            if (hasRole('HAS_CHANNELS')) {
                return getChannels();
            } else {
                return Promise.resolve([]);
            }
        }
    });

    useEffect(() => {
        if (data) {
            setChannels(data);
        }
    }, [data]);

    return (
        <ChannelsContext.Provider value={{ channels, isLoading }}>
            {children}
        </ChannelsContext.Provider>
    );
};

export const useChannels = () => useContext(ChannelsContext);
export default ChannelsProvider;
