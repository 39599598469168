import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import AchievementBadge from 'components/Achievement/Achievement';

const Achievement = ({ activity }) => {
    const { t } = useTranslation();
    const router = useRouter();

    const isRanking = () => {
        return router.stateService.includes('auth.ranking.**');
    };

    return (
        <>
            <div className="details achievement">
                <div className="icon achievement">
                    <AchievementBadge item={activity} />
                </div>

                <div className="meta">
                    <div className="type" style={{ color: activity.color }}>
                        {t('TROPHY_ACTIVITY_TYPE')}
                    </div>
                    <div className="name">{t(activity.name)}</div>
                    <div className="description">{t(activity.description)}</div>
                </div>
            </div>

            <div className="actions">
                {isRanking() && (
                    <a
                        href={router.stateService.href('auth.ranking.badges')}
                        className="btn outline"
                    >
                        {t('POST_VIEW')}
                    </a>
                )}

                {!isRanking() && (
                    <a
                        ui-sref="auth.profile.badges"
                        ui-sref-opts="{ reload: true }"
                        className="btn outline"
                    >
                        {t('POST_VIEW')}
                    </a>
                )}

                {activity.points > 0 && (
                    <button className="btn reward" type="button">
                        <span>{t('TA_POST_PTS_EARNED', { points: activity.points })}</span>
                    </button>
                )}
            </div>
        </>
    );
};

export default Achievement;
