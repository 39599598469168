import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useTranslationService from 'api/useTranslationService';

const MAX_PER_PAGE = 50;

const Translations = () => {
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [onlyCustom, setOnlyCustom] = useState(false);
    const { getCustomTranslations } = useTranslationService();

    const { data: customTranslations } = useQuery({
        queryKey: ['custom_translations'],
        queryFn: () => getCustomTranslations()
    });

    const isActiveLang = (lang) => {
        return lang === currentLang;
    };

    const getTranslations = () => {
        if (!customTranslations) {
            return [];
        }

        const start = (page - 1) * MAX_PER_PAGE;
        const end = page * MAX_PER_PAGE;

        const customTranslationsForCurrentLang = customTranslations
            ? customTranslations[currentLang]
            : [];

        const filteredKeys = Object.entries(i18n.getDataByLanguage(currentLang).translation).filter(
            ([key, value]) => {
                return true;
            }
        );

        /*setTotalPages(Math.ceil(filteredKeys.length / MAX_PER_PAGE));*/

        return filteredKeys.slice(start, end);
    };

    return (
        <div className={'translations-admin-wrapper'}>
            <div className="translations-admin">
                <div className="dashboard">
                    <div className="top">
                        <h1>Gestion des traductions</h1>

                        <div className="search">
                            <input
                                type="search"
                                ng-model="$ctrl.query"
                                ng-change="$ctrl.onChangeQueryOrOnlyCustom()"
                                placeholder="Rechercher une clé ou une traduction"
                            />
                        </div>
                    </div>

                    <div className="tabs">
                        {i18n.languages.map((lang) => {
                            return (
                                <div
                                    className={`tab is-clickable ${isActiveLang(lang) ? 'active' : ''}`}
                                    onClick={() => {
                                        setCurrentLang(lang);
                                    }}
                                    key={lang}
                                >
                                    {lang}
                                </div>
                            );
                        })}
                    </div>

                    <div className="tabs-content scrollable">
                        <div className="tab-content">
                            <div className="heading">
                                <div className="columns">
                                    <div className="key">Clé</div>
                                    <div className="translation">Traduction</div>
                                    <div className="actions"></div>
                                </div>
                            </div>

                            <div className="translations">
                                {getTranslations().map(([key, value], index) => {
                                    return (
                                        <div className="translation" key={key}>
                                            <div className="label ellipsis">
                                                <label className="ellipsis-text" title={key}>
                                                    {key}
                                                </label>
                                            </div>

                                            <div
                                                className="input"
                                                ng-dblclick="$ctrl.addCustom(key)"
                                            >
                                                <textarea
                                                    ng-model="$ctrl.customTranslationsModel[key]"
                                                    ng-disabled="!$ctrl.isCustom(key)"
                                                    ng-change="$ctrl.onChangeTextarea(key)"
                                                    value={value}
                                                    rows="2"
                                                ></textarea>
                                            </div>

                                            <div className="actions">
                                                <button
                                                    className="button grey small ng-animate-disabled"
                                                    ng-if="!$ctrl.isCustom(key)"
                                                    ng-click="$ctrl.addCustom(key)"
                                                >
                                                    <i className="icon-pencil-1"></i> Modifier
                                                </button>

                                                <button
                                                    className="button grey small ng-animate-disabled"
                                                    ng-if="$ctrl.isCustom(key)"
                                                    ng-click="$ctrl.removeCustom(key)"
                                                >
                                                    <i className="icon-undo"></i> Par défaut
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                                {/*
                                 */}
                            </div>
                        </div>
                    </div>

                    <div className="actions">
                        <button
                            className="pagination"
                            ng-disabled="!$ctrl.hasPreviousPage()"
                            ng-click="$ctrl.goToPreviousPage()"
                        >
                            <i className="icon-arrow-left-1-arrows-diagrams" aria-hidden="true"></i>
                        </button>

                        <div className="page">
                            Page {page}/{totalPages}
                        </div>

                        <button
                            className="pagination"
                            ng-disabled="!$ctrl.hasNextPage()"
                            ng-click="$ctrl.goToNextPage()"
                        >
                            <i
                                className="icon-arrow-right-1-arrows-diagrams"
                                aria-hidden="true"
                            ></i>
                        </button>

                        <div className="input-with-label">
                            <input
                                className="switch small"
                                type="checkbox"
                                id="only-custom"
                                ng-model="$ctrl.onlyCustom"
                                ng-change="$ctrl.onChangeQueryOrOnlyCustom()"
                            />

                            <label className="label" htmlFor="only-custom">
                                Voir uniquement les trad. modifiées
                            </label>
                        </div>

                        <button className="button small" ng-click="$ctrl.save()">
                            Sauvegarder
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Translations;
