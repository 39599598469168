import React, { useEffect, useState } from 'react';
import classes from 'classnames';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { cloneDeep } from 'lodash-es';
import useUtils from 'hooks/useUtils';
import { useTreeContext } from '../../../pages/Auth/providers/TreeProvider';
import useTreeHook from 'hooks/useTree';

const Category = ({ widget, options }) => {
    const { tree } = useTreeContext();
    const [category, setCategory] = useState(null);
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        getFirstFieldValueForKey,
        configurationError,
        getSavedFoldedState,
        setSavedFoldedState,
        isFolded,
        setIsFolded
    } = useWidgetComponent(widget, options);
    const { getFirstLetter, orderByPosition, removeEmptyTopLevelCategories } = useUtils();
    const { goToWorkspace, getOnlyCategory } = useTreeHook();

    useEffect(() => {
        setIsFolded(getSavedFoldedState(false));
    }, []);

    useEffect(() => {
        if (tree) {
            processCategory(tree);
        }
    }, [tree]);

    const processCategory = (tree) => {
        const orderedTree = orderByPosition(removeEmptyTopLevelCategories(cloneDeep(tree)));
        const categories = getOnlyCategory(orderedTree);

        const { value = null } = getFirstFieldValueForKey('category');

        if (!value) {
            configurationError();
        }

        setCategory(categories.find((category) => category.id === value));
    };

    return (
        <div
            className={`shortcuts category ${getStyleValueForKey('isFoldable') ? 'foldable' : null}`}
            style={{ color: getStyleValueForKey('titleColor') }}>
            <div
                className="title"
                onClick={() => {
                    setSavedFoldedState(!isFolded);
                }}>
                <div className={`text ${classes(getStyleValueForKey('textAlign'))}`}>
                    {getFieldValueForKey('title')}
                </div>

                {getStyleValueForKey('isFoldable') && (
                    <div className="toggle">
                        <i
                            className={
                                isFolded
                                    ? 'icon-arrow-down-1-arrows-diagrams'
                                    : 'icon-arrow-up-1-arrows-diagrams'
                            }
                            aria-hidden="true"></i>
                    </div>
                )}
            </div>

            {category && (
                <div className={`wrapper ${isFolded ? 'hidden' : null}`}>
                    {category.workspaces.map((workspace) => {
                        return (
                            <a
                                className="shortcut tag"
                                key={workspace.id}
                                href={goToWorkspace(
                                    {
                                        workspace: {
                                            category: {
                                                id: category.id
                                            },
                                            ...workspace
                                        }
                                    },
                                    true
                                )}>
                                <div
                                    className="icon"
                                    style={{ backgroundColor: workspace.icon_color }}>
                                    <div className="letter">{getFirstLetter(workspace.name)}</div>
                                </div>

                                <div className="name">{workspace.name}</div>
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Category;
