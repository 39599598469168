import linkifyHtml from 'linkify-html';
import clip from 'text-clipper';
import { useConfig } from 'providers/ConfigProvider';

const useContent = () => {
    const { config } = useConfig();

    const getClippedContent = (content, maxLines = 15) => {
        return clip(content, 280, { html: true, maxLines });
    };

    const formatRecipientsContent = (values) => {
        let users = values.map((user) => {
            return `<span class="mention">@${user.name}</span>`;
        });

        return users.join('<span class="separator">,</span>');
    };

    const formatContent = (content, hasShowMore = false, showMore = false) => {
        if (content) {
            let formattedContent = linkifyHtml(content, {
                defaultProtocol: 'https',
                nl2br: true,
                className: (value) => {
                    if (config.links === undefined) {
                        return 'linkified';
                    }

                    let find = config.links.map((link) => {
                        if (value.indexOf(Object.keys(link)[0]) !== -1) {
                            return link[Object.keys(link)[0]];
                        }
                    });

                    find = find.find((findValue) => {
                        return findValue !== undefined;
                    });

                    if (find === undefined) {
                        return 'linkified';
                    }

                    return 'linkified btn';
                },
                format: (value, type) => {
                    if (config.links === undefined) {
                        return value;
                    }

                    let find = config.links.map((link) => {
                        if (value.indexOf(Object.keys(link)[0]) !== -1) {
                            return link[Object.keys(link)[0]];
                        }
                    });

                    find = find.find((findValue) => {
                        return findValue !== undefined;
                    });

                    if (find === undefined) {
                        return value;
                    }

                    return find;
                }
            });

            if (hasShowMore && !showMore) {
                return getClippedContent(content);
            }

            return formattedContent;
        }
    };

    return {
        formatContent,
        getClippedContent,
        formatRecipientsContent
    };
};

export default useContent;
