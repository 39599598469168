const IMAGES_MIME_TYPES = [
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
    'image/png'
];

export default function isImageAttachment(attachment) {
    return IMAGES_MIME_TYPES.indexOf(attachment.type) !== -1;
}
