import React, { useEffect, useState } from 'react';
import classes from 'classnames';
import useWidgetComponent from '../hooks/useWidgetComponent';
import ReactPlayer from 'react-player';
import sha256 from 'crypto-js/sha256';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import extractParamsFromUrl from 'helpers/extractParamsFromUrl';
import { isInternalLink } from 'helpers/link';
import { useConfig } from '../../../providers/ConfigProvider';
import { useRouter } from '@uirouter/react';
import useRight from '../../../hooks/useRight';
import usePostService from '../../../api/usePostService';
import useUrl from '../../../hooks/useUrl';
import { first } from 'lodash-es';
import dayjs from 'dayjs';
import { useAuth } from '../../../providers/AuthContext';

const canPlay = ReactPlayer.canPlay;
const Shortcut = ({ widget, options }) => {
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        getIconForKey,
        getRoundSize,
        isImageDisplayType,
        configurationError
    } = useWidgetComponent(widget, options);
    const { isModalOpen } = useModal();
    const { config } = useConfig();
    const router = useRouter();
    const { isAllowToRead, workspacePermissionCheck, hasRole } = useRight();
    const { getPostById } = usePostService();
    const { openUrl } = useUrl();
    const { user } = useAuth();

    const [modalName, setModalName] = useState(null);

    const isLinkShortcutType = () => {
        return getFieldValueForKey('shortcutType') === 'toLink';
    };

    const isEmbed = () => {
        if (!isLinkShortcutType()) {
            return;
        }

        let link = getFieldValueForKey('link');

        if (!link) {
            return false;
        }

        return canPlay(link);
    };

    const goToShortcut = () => {
        let shortcutType = getFieldValueForKey('shortcutType');

        //widgetHistoryClick();

        switch (shortcutType) {
            case 'toLink':
                let link = getFieldValueForKey('link');

                if (!link) {
                    configurationError();

                    break;
                }

                let linkTarget = getFieldValueForKey('linkTarget');

                let { url = null, pathname = null, params = null } = extractParamsFromUrl(link);

                let isInternal = isInternalLink(url, config.hostnames);

                let matchedRoute = router.urlService.match({
                    path: pathname,
                    search: params,
                    hash: url === null ? null : url.hash.replace('#', '')
                });

                switch (linkTarget) {
                    case '_popup':
                        if (isInternal) {
                            if (
                                !matchedRoute ||
                                !matchedRoute.rule ||
                                matchedRoute.rule.type !== 'STATE'
                            ) {
                                return;
                            }

                            switch (matchedRoute.rule.state.name) {
                                case 'auth.posts.post':
                                    let { workspace: workspaceId = null, post: postId = null } =
                                        matchedRoute.match;

                                    postId = parseInt(postId);
                                    workspaceId = parseInt(workspaceId);

                                    if (!postId || !workspaceId || !isAllowToRead(workspaceId)) {
                                        return;
                                    }

                                    getPostById(postId).then(([postData]) => {
                                        let { post = null } = postData;

                                        /*PreviewPost.show({
                                            post,
                                            overrideTitle: getFieldValueForKey('popupTitle'),
                                        });*/
                                    });

                                    break;
                                default:
                                    openUrl(link);
                            }
                        } else {
                            if (isEmbed()) {
                                Modal.open(modalName);
                            } else {
                                openUrl(link);
                            }
                        }

                        break;
                    case '_self':
                        if (
                            isInternal &&
                            matchedRoute &&
                            matchedRoute.rule &&
                            matchedRoute.rule.type === 'STATE'
                        ) {
                            router.stateService.go(
                                matchedRoute.rule.state.name,
                                matchedRoute.match,
                                { reload: true, inherit: false }
                            );
                        } else {
                            openUrl(link, linkTarget);
                        }

                        break;
                    case '_blank':
                    default:
                        openUrl(link);
                }

                break;
            case 'toAndroidPackageName':
                let androidPackageName = getFieldValueForKey('androidPackageName');
                let playStoreLink = getFieldValueForKey('playStoreLink');

                if (!androidPackageName || !playStoreLink) {
                    configurationError();

                    break;
                }

                /*if (IS_APP) {
                    AppLauncher.canOpenUrl({
                        url: androidPackageName
                    }).then((res1) => {

                        if (res1.value) {
                            AppLauncher.openUrl({
                                url: androidPackageName
                            });
                        } else {
                            openUrl(playStoreLink);
                        }
                    });
                } else {
                    openUrl(playStoreLink);
                }*/

                break;
            case 'toFeed':
                let feeds = getFieldValueForKey('feed');

                if (!feeds || feeds.length === 0) {
                    configurationError();

                    break;
                }

                let feedsFound = feeds.filter((feed) => {
                    return (
                        workspacePermissionCheck({ id: feed.value }, 'READ') ||
                        workspacePermissionCheck({ id: feed.value }, 'WRITE')
                    );
                });

                if (feedsFound.length === 0) {
                    configurationError();

                    break;
                }

                if (feedsFound.length === 1) {
                    let feed = first(feedsFound);

                    /*let ws = Tree.findWorkspaceById(feed.value);

                    Tree.goToWorkspace(ws[0].workspace_data);*/
                } else {
                    let workspaces = [];

                    feedsFound.forEach((feed) => {
                        //let ws = Tree.findWorkspaceById(feed.value);
                        /*workspaces.push({
                            workspace_data: ws[0].workspace_data,
                        });*/
                    });

                    //WorkspaceList.show({workspaces, displayMode: getFieldValueForKey('displayMode')});
                }

                break;
            case 'toCategory':
                let category = first(getFieldValueForKey('category'));

                if (!category) {
                    configurationError();

                    break;
                }

                router.stateService.go(
                    `auth.${getFieldValueForKey('displayMode')}.category`,
                    {
                        category: category.value
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toSharedTag':
                let tag = first(getFieldValueForKey('tag'));

                if (!tag) {
                    configurationError();

                    break;
                }

                /*Tags.getTag(tag.value).then((data) => {
                    Tags.goToTag(data);
                })*/

                break;
            case 'toCampaignType':
                let campaignType = getFieldValueForKey('campaignType');

                if (!campaignType) {
                    configurationError();

                    break;
                }

                router.stateService.go(
                    `auth.campaigns.feed`,
                    {
                        type: campaignType,
                        state: 'running',
                        page: 1,
                        opportunity: null
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toMissions':
                /*Missions.getMissionsFromServer().then(() => {
                    Missions.openMissions();
                });

                Modal.close('springboard');*/

                break;
            case 'toSubscriptionManagement':
                let subscriptionCategoryTarget = null;
                let subscriptionTarget = getFieldValueForKey('subscriptionTarget');

                if (subscriptionTarget === 'one-opened') {
                    subscriptionCategoryTarget = getFieldValueForKey(
                        'subscriptionCategoryTarget'
                    ).map((item) => item.value);
                }

                router.stateService.go(
                    'auth.profile.subscriptions',
                    {
                        userId: user.id,
                        open: subscriptionTarget,
                        categoryIds: subscriptionCategoryTarget
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toTopAmbassador':
                if (!hasRole('ROLE_TOP_AMBASSADOR')) {
                    configurationError();

                    break;
                }

                //TopAmbassador.openSuggestPost();

                break;
            case 'toCustomCace':
                let email = user.email;
                let timestamp = dayjs().unix();
                let token = sha256(`${email};${timestamp};15c6ec92f108d121`).toString();

                let intranetUrl = `http://extranet.eluca.fr/?t=${token}&uid=${email}&d=${timestamp}`;

                openUrl(intranetUrl, '_blank', false);

                break;
            default:
                console.log('not implemented');
        }
    };

    useEffect(() => {
        if (isLinkShortcutType()) {
            const hash = sha256(getFieldValueForKey('link')).toString();

            setModalName(`widget-shortcut-${hash}`);
        }
    }, []);

    return (
        <>
            {isEmbed() && modalName && (
                <Modal
                    name={modalName}
                    title={getFieldValueForKey('popupTitle')}
                    options={{ zIndex: 310 }}
                >
                    {isModalOpen(modalName) && (
                        <div className="iframe-responsive">
                            {/*<embed-player url="$ctrl.getFieldValueForKey('link')"></embed-player>*/}
                        </div>
                    )}
                </Modal>
            )}

            {!isImageDisplayType() && (
                <div
                    className={`shortcut ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToShortcut}
                >
                    <div
                        className={`icon ${classes(getStyleValueForKey('iconShape'))}`}
                        style={{
                            backgroundColor: getStyleValueForKey('iconBgColor'),
                            color: getStyleValueForKey('iconColor')
                        }}
                    >
                        <i className={classes(getIconForKey('icon'))}></i>
                    </div>

                    <div className={`title ${classes(getStyleValueForKey('textAlign'))}`}>
                        {getFieldValueForKey('title')}
                    </div>
                </div>
            )}

            {isImageDisplayType() && (
                <div
                    className={`shortcut ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToShortcut}
                >
                    <div
                        className="image"
                        style={{ borderRadius: getRoundSize(), overflow: 'hidden' }}
                    >
                        <img src={getFieldValueForKey('image')} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Shortcut;
