import { useConfig } from 'providers/ConfigProvider';
import useRight from './useRight';
import { has } from 'lodash-es';
import { useCurrentStateAndParams } from '@uirouter/react';

const useWorkspace = () => {
    const { config } = useConfig();
    const { hasRole, isWorkspaceAdmin } = useRight();
    const { params } = useCurrentStateAndParams();

    const forgeIconUrl = (iconName) => {
        return `${config.staticUrl}download/workspace/icon/${iconName}`;
    };

    const isWorkspaceFilterable = (workspace) => {
        return (
            workspace &&
            workspace.workspace_form &&
            workspace.workspace_form.workspace_form_inputs.some((workspaceFormInput) => {
                return isWorkspaceFormInputFilterable(workspaceFormInput);
            })
        );
    };

    const isWorkspaceFormInputFilterable = (workspaceFormInput) => {
        let filterableTypes = ['select', 'radio', 'collection'];

        if (workspaceFormInput.hidden && !(hasRole('ROLE_ADMIN') || isWorkspaceAdmin())) {
            return false;
        }

        if (filterableTypes.indexOf(workspaceFormInput.type) === -1) {
            return false;
        }

        return !(
            isWorkspaceFormInputConditional(workspaceFormInput) &&
            !isWorkspaceFormInputConditionFulfilled(workspaceFormInput)
        );
    };

    const isWorkspaceFormInputConditional = (workspaceFormInput) => {
        return !!workspaceFormInput.input_condition;
    };

    const isWorkspaceFormInputConditionFulfilled = (workspaceFormInput) => {
        if (!has(params.query, workspaceFormInput.value_condition)) {
            return false;
        }

        return (
            params.query[workspaceFormInput.value_condition] ===
            workspaceFormInput.input_condition.id
        );
    };

    const extractWorkspaceFormsFromWorkspaces = (workspaces) => {
        let workspaceForms = {};

        workspaces.forEach((workspace) => {
            if (workspace.workspace_form) {
                let workspaceForm = workspace.workspace_form;

                if (!workspaceForms[workspaceForm.id]) {
                    workspaceForms[workspaceForm.id] = {
                        ...workspaceForm,
                        workspaces: [
                            {
                                ...workspace,
                                workspace_form: null
                            }
                        ]
                    };
                } else {
                    workspaceForms[workspaceForm.id].workspaces.push({
                        ...workspace,
                        workspace_form: null
                    });
                }
            }
        });

        return Object.values(workspaceForms);
    };

    return {
        forgeIconUrl,
        isWorkspaceFilterable,
        isWorkspaceFormInputFilterable,
        extractWorkspaceFormsFromWorkspaces
    };
};

export default useWorkspace;
