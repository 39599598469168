import { useTranslation } from 'react-i18next';
import useViewport from 'hooks/useViewport';
import { useEffect, useState, useCallback } from 'react';
import { Select } from 'antd';
import { sinceOptions, orders } from '../hooks/useTileComponent';
import { useFeedContext } from '../../../../providers/FeedProvider';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { decode as base64Decode, encode as base64Encode } from 'base-64';
import utf8 from 'utf8';
import { find, includes, pull, has, filter, map, isEqual, isArray } from 'lodash-es';

const Filters = () => {
    const { t } = useTranslation();
    const viewport = useViewport();
    const { tileFilters } = useFeedContext();
    const router = useRouter();
    const { params } = useCurrentStateAndParams();

    const [filtersToggled, setFiltersToggled] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [query, setQuery] = useState({});
    const [queryValues, setQueryValues] = useState([]);

    const getInputLabel = useCallback(
        (inputId) => {
            const foundInput = find(tileFilters.inputs, { id: inputId });
            return foundInput
                ? foundInput.label_render
                    ? foundInput.label_render
                    : foundInput.label
                : '';
        },
        [tileFilters]
    );

    useEffect(() => {
        if (params.query) {
            const newQueryValues = [];
            const newQueryInputs = {};

            try {
                params.query.forEach((item) => {
                    const [id, value] = item.split(',');
                    const decodedValue = utf8.decode(base64Decode(decodeURIComponent(value)));
                    newQueryInputs[decodedValue] = parseInt(id);

                    const foundInput = find(newQueryValues, { inputId: parseInt(id) });

                    if (foundInput) {
                        foundInput.inputValues.push(decodedValue);
                    } else {
                        newQueryValues.push({
                            inputId: parseInt(id),
                            inputLabel: getInputLabel(parseInt(id)),
                            inputValues: [decodedValue]
                        });
                    }
                });

                setQuery((prevQuery) => ({ ...prevQuery, inputs: newQueryInputs }));
                setQueryValues(newQueryValues);

                const newSelectedFilters = {};

                Object.keys(newQueryInputs).forEach((value) => {
                    const workspaceFormInputId = newQueryInputs[value];
                    if (!newSelectedFilters[workspaceFormInputId]) {
                        newSelectedFilters[workspaceFormInputId] = [];
                    }
                    newSelectedFilters[workspaceFormInputId].push(value);
                });

                setSelectedFilters(newSelectedFilters);
            } catch (e) {
                setQuery({ inputs: {} });
            }
        }
    }, [params.query, getInputLabel]);

    const onChangeOrder = useCallback(
        (event) => {
            router.stateService.go(
                '.',
                { page: 1, order: event.target.value },
                { reload: false, inherit: true }
            );
        },
        [router]
    );

    const onChangeSince = useCallback(
        (since) => {
            router.stateService.go('.', { page: 1, since }, { reload: false, inherit: true });
        },
        [router]
    );

    useEffect(() => {
        const queryArr = filter(
            map(query.inputs, (id, value) =>
                value ? `${id},${base64Encode(utf8.encode(value))}` : null
            ),
            (value) => value !== null
        );

        if (!isEqual(queryArr, params.query) && query.inputs) {
            router.stateService.go(
                '.',
                { page: 1, query: queryArr },
                { reload: false, inherit: true }
            );
        }
    }, [query, router]);

    const filterBy = useCallback((workspaceFormInput, value) => {
        setQuery((prevQuery) => {
            const newInputs = {
                ...Object.keys(prevQuery.inputs)
                    .filter((key) => {
                        return prevQuery.inputs[key] !== workspaceFormInput.id;
                    })
                    .reduce((res, key) => ((res[key] = prevQuery.inputs[key]), res), {})
            };

            if (isArray(value)) {
                value.forEach((v) => {
                    newInputs[v] = workspaceFormInput.id;
                });
                return { ...prevQuery, inputs: newInputs };
            } else {
                newInputs[value] = workspaceFormInput.id;
                return { ...prevQuery, inputs: newInputs };
            }
        });
    }, []);
    
    const onSelectFilter = useCallback(
        (workspaceFormInput, value, elem = 'select') => {
            setSelectedFilters((prevSelectedFilters) => {
                const updatedFilters = { ...prevSelectedFilters };

                if (elem === 'checkbox') {
                    if (includes(updatedFilters[workspaceFormInput.id], value)) {
                        pull(updatedFilters[workspaceFormInput.id], value);
                    } else {
                        if (!updatedFilters[workspaceFormInput.id]) {
                            updatedFilters[workspaceFormInput.id] = [];
                        }
                        updatedFilters[workspaceFormInput.id].push(value);
                    }
                }

                filterBy(workspaceFormInput, value);
                return updatedFilters;
            });
        },
        [filterBy]
    );

    return (
        <div className={'tile-filters'}>
            <div className="header">
                <h3>{t('SEARCH_FILTER_BUTTON')}</h3>

                {!viewport.gtXs && (
                    <div
                        className={`toggle-filters ${filtersToggled ? 'active' : ''}`}
                        onClick={() => setFiltersToggled((prev) => !prev)}>
                        <div className="icon">
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.37487 5.46873C9.47768 5.46813 9.57961 5.48784 9.67479 5.52672C9.76998 5.5656 9.85655 5.62288 9.92955 5.69529L16.1796 11.9453C16.2528 12.0179 16.3109 12.1043 16.3506 12.1995C16.3902 12.2947 16.4106 12.3968 16.4106 12.5C16.4106 12.6031 16.3902 12.7052 16.3506 12.8004C16.3109 12.8956 16.2528 12.982 16.1796 13.0547L9.92955 19.3047C9.78244 19.4518 9.58291 19.5344 9.37486 19.5344C9.16682 19.5344 8.96729 19.4518 8.82018 19.3047C8.67307 19.1576 8.59042 18.958 8.59042 18.75C8.59042 18.5419 8.67307 18.3424 8.82018 18.1953L14.5233 12.5L8.82018 6.80466C8.74695 6.73204 8.68883 6.64563 8.64917 6.55043C8.60951 6.45523 8.58909 6.35311 8.58909 6.24998C8.58909 6.14684 8.60951 6.04473 8.64917 5.94953C8.68883 5.85432 8.74695 5.76792 8.82018 5.69529C8.89318 5.62288 8.97975 5.5656 9.07494 5.52672C9.17012 5.48784 9.27205 5.46813 9.37487 5.46873Z"
                                    fill="#373A4D"></path>
                            </svg>
                        </div>
                    </div>
                )}
            </div>

            <div className={`toggleable ${filtersToggled && !viewport.gtXs ? 'toggled' : ''}`}>
                <div className="filters">
                    <div className="period">
                        <label htmlFor="since" className="title">
                            {t('TILE_FILTER_PERIOD')} :
                        </label>
                        <div className="select">
                            <Select
                                options={sinceOptions}
                                style={{ width: '100%' }}
                                onChange={onChangeSince}
                            />
                        </div>
                    </div>

                    <div className="filters">
                        {tileFilters &&
                            tileFilters?.inputs.map((input, index) => {
                                return (
                                    <div className="filter" key={`input_${index}`}>
                                        <label className="title">
                                            {t('WORKSPACE_FILTER_BY', {
                                                type: input.label_render
                                            })}{' '}
                                            :
                                        </label>

                                        {!input.unfolded_filter ? (
                                            <Select
                                                options={input.values.map((value) => {
                                                    return {
                                                        label: value,
                                                        value
                                                    };
                                                })}
                                                style={{ width: '100%' }}
                                                onChange={(values) => {
                                                    onSelectFilter(input, values, 'select');
                                                }}
                                                mode={'multiple'}
                                            />
                                        ) : (
                                            <div className="order">
                                                <div className="order-choices">
                                                    {input.values.map((value, valueIndex) => {
                                                        return (
                                                            <div
                                                                className="checkbox"
                                                                key={`input_${input.id}_${valueIndex}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`filter-${index}-${valueIndex}`}
                                                                    //ng-checked="$ctrl.Tile.selectedFilters[workspace_form_input.id] && $ctrl.Tile.selectedFilters[workspace_form_input.id].indexOf(value) != -1"
                                                                    className="checkbox"
                                                                    onChange={(event) => {
                                                                        onSelectFilter(
                                                                            input,
                                                                            event.target.value,
                                                                            'checkbox'
                                                                        );
                                                                    }}
                                                                />
                                                                <label
                                                                    htmlFor={`filter-${index}-${valueIndex}`}
                                                                    className="label">
                                                                    {{ value }}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="order">
                    <div className="title">{t('TILE_ORDER_DISPLAY')}</div>

                    <div className="order-choices">
                        {orders.map((order, index) => {
                            return (
                                <div className="radio" key={`order_${index}`}>
                                    <input
                                        id={`order-${order.value}`}
                                        className="input"
                                        type="radio"
                                        name={'order'}
                                        onChange={onChangeOrder}
                                        value={order.value}
                                    />
                                    <label htmlFor={`order-${order.value}`} className="label">
                                        {t(order.label)}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filters;
