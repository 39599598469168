import { flexRender } from '@tanstack/react-table';

import React, { useCallback, useState } from 'react';
import useLevelStatsService from './api/useLevelsStatsService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import LevelNewModal from './modals/LevelNewModal';
import Table from 'components/Table';
import dayjs from 'dayjs';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useAlert } from 'providers/AlertContext';
import AdminHeader from 'components/Admin/components/AdminHeader';

const LevelsStats = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { params } = useCurrentStateAndParams();
    const queryClient = useQueryClient();
    const { getLevelStats, deleteLevels } = useLevelStatsService();
    const { showConfirm, showToast } = useAlert();

    const [nameLevelLocal, setNameLevelLocal] = useState(params.nameLevel);

    const handleFilter = useCallback(
        (params) => {
            router.stateService.go('.', params);
        },
        [router.stateService]
    );

    const { data, isLoading } = useQuery({
        queryKey: [`adminLevelStats`],
        queryFn: () =>
            getLevelStats(params).then((res) => {
                return res;
            })
    });

    const handleDeleteLevels = (rows) => {
        showConfirm({
            title: 'Suppression',
            text: `Voulez-vous vraiment supprimer les levels sélectionnés ?`,
            button: {
                label: 'Supprimer',
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                const keys = Object.keys(rows).map((key) => ({ id: data[key].id }));

                deleteLevels(keys).then(() => {
                    queryClient.invalidateQueries('adminLevelStats');

                    showToast({
                        text: `Les levels sélectionnés ont bien été supprimés`
                    });
                });
            })
            .catch(() => {});
    };

    const COLUMNS = [
        {
            id: 'name',
            header: 'Nom du Level',
            sortable: true
        },
        {
            id: 'index',
            header: 'Index',
            sortable: true
        },
        {
            id: 'levelConfigName',
            header: 'Nom Index',
            sortable: true
        },
        {
            id: 'usersCount',
            header: "Nombre d'utilisateurs concernés",
            sortable: true
        },
        {
            id: 'rulesCount',
            header: 'Nombre de règles concernés',
            sortable: true
        },
        {
            id: 'createdAt',
            header: "Date d'ajout",
            cell: flexRender(({ value }) => dayjs(value).format('DD/MM/YYYY'))
        }
    ];

    return (
        <>
            <LevelNewModal />

            <AdminHeader
                title={'ADMIN_LEVEL_LEVELS_TITLE'}
                modalButtonTitle={'ADMIN_LEVEL_ADD_LEVEL'}
                modalName={'add_levels_stats'}
            />
            <div className="admin__users__letters">
                <div className="option">
                    <label htmlFor="noEmployees">Levels sans utilisateurs</label>
                    <input
                        type="checkbox"
                        name={'noEmployees'}
                        id={'noEmployees'}
                        checked={!!params.noEmployees}
                        onChange={(e) => handleFilter({ ...params, noEmployees: e.target.checked })}
                    />
                </div>
                <div className="option">
                    <label htmlFor="noRules">levels sans règles</label>
                    <input
                        type="checkbox"
                        name={'noRules'}
                        id={'noRules'}
                        checked={!!params.noRules}
                        onChange={(e) => handleFilter({ ...params, noRules: e.target.checked })}
                    />
                </div>

                <form className="search-form-tree">
                    <input
                        type="text"
                        value={nameLevelLocal}
                        onChange={(e) => {
                            setNameLevelLocal(e.target.value);
                        }}
                        placeholder={t('ADMIN_USERS_SEARCH_LABEL')}
                    />
                    <button
                        onClick={() => {
                            if (nameLevelLocal) {
                                handleFilter({ ...params, nameLevel: nameLevelLocal });
                            }
                        }}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>

            <Table
                columns={COLUMNS}
                data={data}
                isLoading={isLoading}
                selectable
                handleSelectedRows={[
                    {
                        label: 'Supprimer',
                        handler: handleDeleteLevels
                    }
                ]}
            />
        </>
    );
};

export default LevelsStats;
