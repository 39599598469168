import useUtils from 'hooks/useUtils';
import useRight from 'hooks/useRight';
import { useCallback, useState } from 'react';
import { dropRight, findIndex, indexOf, isEmpty } from 'lodash-es';
import { useCurrentStateAndParams } from '@uirouter/react';
import Folder from './folder';
import useTree from 'hooks/useTree';

const Item = ({ item }) => {
    const { getFirstLetter, orderTopLevelFoldersByName } = useUtils();
    const { hasRole } = useRight();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const { params } = useCurrentStateAndParams();
    const { getWorkspaceLink, getCategoryLink } = useTree();

    const badgeColor = '';

    const selectCategory = (category) => {
        let categoryIndex = findCategoryIndex(category);

        if (categoryIndex !== -1) {
            setSelectedCategories(
                dropRight(selectedCategories, selectedCategories.length - categoryIndex)
            );
        } else {
            if (!category.parent) {
                setSelectedCategories([category]);
            } else {
                if (!category.hasHiddenParent) {
                    setSelectedCategories((prevState) => {
                        return prevState.push(category);
                    });
                } else {
                    let parentCategoryIndex = isChildOfSelectedCategories(category);

                    if (parentCategoryIndex !== -1) {
                        parentCategoryIndex += 1;
                        setSelectedCategories([
                            ...dropRight(
                                selectedCategories,
                                selectedCategories.length - parentCategoryIndex
                            ),
                            category
                        ]);
                    } else {
                        setSelectedCategories([category]);
                    }
                }
            }
        }

        setSelectedWorkspace(null);
        setSelectedFolders([]);
    };

    const isChildOfSelectedCategories = (category) => {
        return findIndex(selectedCategories, (selectedCategory) => {
            if (!!selectedCategory.categories_id && selectedCategory.categories_id.length > 0) {
                return indexOf(selectedCategory.categories_id, category.id) !== -1;
            }

            return false;
        });
    };

    const findCategoryIndex = useCallback(
        (category) => {
            return findIndex(selectedCategories, (selectedCategory) => {
                return category.id === selectedCategory.id;
            });
        },
        [selectedCategories]
    );

    const isCategorySelected = (category) => {
        return findCategoryIndex(category) !== -1;
    };

    const hasChildren = (children) => {
        return !isEmpty(children);
    };

    const isCurrentWorkspace = useCallback(
        (workspace) => {
            return params.workspace === workspace.id;
        },
        [params]
    );

    const selectWorkspace = (workspace) => {
        if (isWorkspaceSelected(workspace)) {
            setSelectedWorkspace(null);
            setSelectedFolders([]);
        } else {
            setSelectedWorkspace(workspace);
            let categoryIndex = findCategoryIndex(workspace.category);

            if (categoryIndex !== -1) {
                categoryIndex += 1;
                setSelectedCategories(
                    dropRight(selectedCategories, selectedCategories.length - categoryIndex)
                );
            }
        }
    };

    const isWorkspaceSelected = (workspace) => {
        if (selectedWorkspace === null) {
            return false;
        }

        return selectedWorkspace.id === workspace.id;
    };

    return (
        <>
            {item.category && (
                <div className="category">
                    <div className="meta">
                        <a className="name" href={getCategoryLink(item.category)}>
                            <div className="text">{item.category.name}</div>
                        </a>

                        {!hasRole('HIDE_NEW_POST_BADGE_TREE') &&
                            item.category.total_unread_category > 0 &&
                            !isCategorySelected(item.category) && (
                                <div className="unread">
                                    {!hasRole('NEW_POST_BADGE_IS_COLOR') ? (
                                        <div>({item.category.total_unread_category})</div>
                                    ) : (
                                        <div
                                            className="circle"
                                            style={{ backgroundColor: badgeColor }}></div>
                                    )}
                                </div>
                            )}

                        {hasChildren(item.workspaces) && (
                            <div
                                className={`arrow ${isCategorySelected(item.category) ? 'arrow-down' : ''}`}
                                onClick={() => selectCategory(item.category)}>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.37487 5.46873C9.47768 5.46813 9.57961 5.48784 9.67479 5.52672C9.76998 5.5656 9.85655 5.62288 9.92955 5.69529L16.1796 11.9453C16.2528 12.0179 16.3109 12.1043 16.3506 12.1995C16.3902 12.2947 16.4106 12.3968 16.4106 12.5C16.4106 12.6031 16.3902 12.7052 16.3506 12.8004C16.3109 12.8956 16.2528 12.982 16.1796 13.0547L9.92955 19.3047C9.78244 19.4518 9.58291 19.5344 9.37486 19.5344C9.16682 19.5344 8.96729 19.4518 8.82018 19.3047C8.67307 19.1576 8.59042 18.958 8.59042 18.75C8.59042 18.5419 8.67307 18.3424 8.82018 18.1953L14.5233 12.5L8.82018 6.80466C8.74695 6.73204 8.68883 6.64563 8.64917 6.55043C8.60951 6.45523 8.58909 6.35311 8.58909 6.24998C8.58909 6.14684 8.60951 6.04473 8.64917 5.94953C8.68883 5.85432 8.74695 5.76792 8.82018 5.69529C8.89318 5.62288 8.97975 5.5656 9.07494 5.52672C9.17012 5.48784 9.27205 5.46813 9.37487 5.46873Z"
                                        fill="#373A4D"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>

                    {isCategorySelected(item.category) && (
                        <div className="children">
                            {item.workspaces &&
                                item.workspaces.map((workspace, index) => {
                                    return (
                                        <div
                                            className="items"
                                            key={`ctegory_${item.category.id}_${index}`}>
                                            <div className="item">
                                                <Item item={workspace} />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            )}

            {item.workspace_data && (
                <div
                    className={`workspace ${isCurrentWorkspace(item.workspace_data.workspace) ? 'is-current' : ''}`}>
                    <div className="meta">
                        <a
                            className="icon cursor pointer"
                            style={{ backgroundColor: item.workspace_data.workspace.icon_color }}
                            href={getWorkspaceLink(item.workspace_data)}
                            target="_blank">
                            <div className="letter">
                                {getFirstLetter(item.workspace_data.workspace.name)}
                            </div>
                        </a>

                        <a
                            className="name"
                            href={getWorkspaceLink(item.workspace_data)}
                            target="_blank">
                            {item.workspace_data.workspace.name}
                        </a>

                        {!hasRole('HIDE_NEW_POST_BADGE_TREE') &&
                            item.workspace_data.unread_post &&
                            item.workspace_data.unread_post.length > 0 && (
                                <div className="unread">
                                    {!hasRole('NEW_POST_BADGE_IS_COLOR') ? (
                                        <div>({item.workspace_data.unread_post.length})</div>
                                    ) : (
                                        <div
                                            className="circle"
                                            style={{ backgroundColor: badgeColor }}></div>
                                    )}
                                </div>
                            )}

                        {hasChildren(item.workspace_data.folders) && (
                            <div
                                className={`arrow ${isWorkspaceSelected(item.workspace_data.workspace) ? 'arrow-down' : ''}`}
                                onClick={() => selectWorkspace(item.workspace_data.workspace)}>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.37487 5.46873C9.47768 5.46813 9.57961 5.48784 9.67479 5.52672C9.76998 5.5656 9.85655 5.62288 9.92955 5.69529L16.1796 11.9453C16.2528 12.0179 16.3109 12.1043 16.3506 12.1995C16.3902 12.2947 16.4106 12.3968 16.4106 12.5C16.4106 12.6031 16.3902 12.7052 16.3506 12.8004C16.3109 12.8956 16.2528 12.982 16.1796 13.0547L9.92955 19.3047C9.78244 19.4518 9.58291 19.5344 9.37486 19.5344C9.16682 19.5344 8.96729 19.4518 8.82018 19.3047C8.67307 19.1576 8.59042 18.958 8.59042 18.75C8.59042 18.5419 8.67307 18.3424 8.82018 18.1953L14.5233 12.5L8.82018 6.80466C8.74695 6.73204 8.68883 6.64563 8.64917 6.55043C8.60951 6.45523 8.58909 6.35311 8.58909 6.24998C8.58909 6.14684 8.60951 6.04473 8.64917 5.94953C8.68883 5.85432 8.74695 5.76792 8.82018 5.69529C8.89318 5.62288 8.97975 5.5656 9.07494 5.52672C9.17012 5.48784 9.27205 5.46813 9.37487 5.46873Z"
                                        fill="#373A4D"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>

                    {isWorkspaceSelected(item.workspace_data.workspace) && (
                        <div className="children">
                            {item.workspace_data.folders &&
                                orderTopLevelFoldersByName(item.workspace_data.folders).map(
                                    (folder) => {
                                        return (
                                            <div
                                                className="items"
                                                key={`folder_top_level_${folder.id}`}>
                                                <div className="item">
                                                    <Folder
                                                        folders={folder}
                                                        selectedFolders={selectedFolders}
                                                        setSelectedFolders={setSelectedFolders}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Item;
