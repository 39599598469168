import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';
import { findIndex, orderBy } from 'lodash-es';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useSponsorshipClientProgramService from './api/useSponsorshipClientProgramService';
import SponsorshipClientFormModal from './modals/SponsorshipClientFormModal';
import { useModal } from 'providers/ModalContext';

const SponsorshipClient = () => {
    const queryClient = useQueryClient();
    const SponsorshipClientProgramService = useSponsorshipClientProgramService();
    const { openModal } = useModal();
    const COLUMNS = [
        {
            id: 'label',
            header: 'Programme'
        }
    ];

    const { data: sponsorshipClientPrograms, isLoading } = useQuery({
        queryFn: () => SponsorshipClientProgramService.getAll(),
        queryKey: ['allSponsorshipClientProgram']
    });

    const canUp = (program) => {
        if (sponsorshipClientPrograms.length === 1) {
            return false;
        }

        if (program.position == 0) {
            return true;
        }

        let programsOrdered = orderBy(sponsorshipClientPrograms, ['position'], ['asc']);

        if (
            findIndex(programsOrdered, (c) => {
                return c.id === program.id;
            }) === 0
        ) {
            return true;
        }

        return false;
    };

    const up = (program) => {
        SponsorshipClientProgramService.down(program).then(() => {
            queryClient.refetchQueries({ queryKey: ['allSponsorshipClientProgram'] });
        });
    };

    const canDown = (program) => {
        if (sponsorshipClientPrograms.length === 1) {
            return false;
        }

        if (program.position == 0) {
            return false;
        }

        let programsOrdered = orderBy(sponsorshipClientPrograms, ['position'], ['asc']);

        if (
            findIndex(programsOrdered, (c) => {
                return c.id === program.id;
            }) ===
            programsOrdered.length - 1
        ) {
            return true;
        }

        return false;
    };

    const down = (program) => {
        SponsorshipClientProgramService.up(program).then(() => {
            queryClient.refetchQueries({ queryKey: ['allSponsorshipClientProgram'] });
        });
    };

    return (
        <>
            <SponsorshipClientFormModal />
            <AdminHeader
                title={'RECONNECT_PROGRAMS_NAME'}
                modalName={'add_sponsorship-program'}
                modalButtonTitle={'RECONNECT_ADD_PROGRAM'}
            />
            <Table
                columns={COLUMNS}
                data={isLoading ? [] : orderBy(sponsorshipClientPrograms, ['position'], ['asc'])}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        icon: <i className="fa fa-chevron-up"></i>,
                        handler: (program) => {
                            up(program);
                        },
                        disableHandler: (program) => {
                            return canUp(program);
                        }
                    },
                    {
                        icon: <i className="fa fa-chevron-down"></i>,
                        handler: (program) => {
                            down(program);
                        },
                        disableHandler: (program) => {
                            return canDown(program);
                        }
                    },
                    {
                        label: 'ADMIN_MENU_BRANDS_EDIT_BRAND_LABEL',
                        handler: (program) => {
                            openModal('add_sponsorship-program', {
                                currentSponsorshipProgram: program
                            });
                        }
                    }
                ]}
            />
        </>
    );
};
export default SponsorshipClient;
