import { useState } from 'react';

const ModalFormSection = ({ children, inline, ...props }) => {
    const [currentTab, setCurrenTab] = useState(props.tabs ? props.tabs[0] : null);
    return (
        <div className={'section-wrapper'}>
            {currentTab && (
                <div className={'tabs'}>
                    {props.tabs.map((tab) => {
                        return (
                            <button
                                type={'button'}
                                className={`tab ${currentTab === tab ? 'active' : ''}`}
                                onClick={() => {
                                    setCurrenTab(tab);
                                    props.onTabChange(tab);
                                }}>
                                {tab}
                            </button>
                        );
                    })}
                </div>
            )}
            <div className={`section ${inline ? 'inline' : ''}`} {...props}>
                {children}
            </div>
        </div>
    );
};

export default ModalFormSection;
