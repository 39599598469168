import extractParamsFromUrl from 'helpers/extractParamsFromUrl';

export default function isInternalLink(url, hostnames) {
    if (typeof url !== 'object') {
        url = extractParamsFromUrl(url).url;
    }

    if (url.origin.includes('.monapptransport.fr') || url.origin.includes('.fizzy-retail.app')) {
        return true;
    }

    return !!url && hostnames.some((h) => url.hostname.includes(h));
}
