import { useTranslation } from 'react-i18next';
import { Select as InputSelect } from 'components/Form/Inputs';

const Select = ({ field }) => {
    const { t } = useTranslation();
    return (
        <div className="custom_select">
            <label className="custom_text" htmlFor="{{ field.id }}">
                {field.label} {field.require && <span className="required">*</span>}
            </label>

            <div className="select">
                <InputSelect
                    name={`form.fields[${field.id}].value`}
                    placeholder={t('EDITOR_FIELDS_SELECT_PLACEHOLDER')}
                    options={field.values.map((value) => ({
                        label: value,
                        value
                    }))}
                />
            </div>
            {field.explanation && <div className="explanation">{field.explanation}</div>}
        </div>
    );
};

export default Select;
