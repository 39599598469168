import React, { useEffect, useState } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useCampaignService from 'api/useCampaignService';
import { useTranslation } from 'react-i18next';
import Campaign from '../../../components/Campaign/Campaign';

const CampaignsUser = () => {
    const { params } = useCurrentStateAndParams();
    const [query, setQuery] = useState('');
    const { getAllRunning, getCampaignById } = useCampaignService();
    const { t } = useTranslation();

    const { type, page, id } = params;

    const { data, isLoading } = useQuery({
        queryKey: [query, 'user_campaigns'],
        queryFn: () => {
            if (id) {
                return getCampaignById(id, type).then((data) => {
                    return {
                        campaigns: [data]
                    };
                });
            }
            return getAllRunning(page, type);
        }
    });

    useEffect(() => {
        setQuery(`user_campaigns_${[...Object.values(params)].join('_')}`);
    }, [params]);

    return (
        <div className="campaigns-wrapper">
            {!isLoading && data && data.campaigns && data.campaigns.length > 0 && (
                <>
                    {data.campaigns.map((campaign) => {
                        return <Campaign campaignItem={campaign} key={`campaign_${campaign.id}`} />;
                    })}
                </>
            )}

            {!isLoading && data && data.campaigns && data.campaigns.length === 0 && (
                <div className="empty">
                    <div className="message">
                        <div className="icon">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </div>
                        <div className="text">{t('NO_CAMPAIGN')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CampaignsUser;
