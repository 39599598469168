import React, { useEffect } from 'react';
import classes from 'classnames';
import useWidgetComponent from '../hooks/useWidgetComponent';
import useUtils from 'hooks/useUtils';
import { useAuth } from 'providers/AuthContext';
import { isArray, trimEnd, first } from 'lodash-es';
import dayjs from 'dayjs';
import sha256 from 'crypto-js/sha256';
import useRight from '../../../hooks/useRight';

const Shortcuts = ({ widget, options }) => {
    const { user } = useAuth();
    const { workspacePermissionCheck } = useRight();
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        getIconForKey,
        getSavedFoldedState,
        setSavedFoldedState,
        configurationError,
        isFolded,
        setIsFolded
    } = useWidgetComponent(widget, options);
    const { getFirstLetter } = useUtils();

    useEffect(() => {
        setIsFolded(getSavedFoldedState(false));
    }, []);

    const isTag = (shortcut) => {
        return getFieldValueForKey('shortcutType', shortcut) === 'toSharedTag';
    };

    const isFirstLetterIconStyle = (shortcut) => {
        return getFieldValueForKey('displayType', shortcut) === 'displayFirstLetterText';
    };

    const isTargetedForUserOrganizationalUnit = (shortcut) => {
        let userOrganizationalUnits = user.organizational_units.map((userOrganizationalUnit) => {
            return userOrganizationalUnit.id;
        });

        if (userOrganizationalUnits && userOrganizationalUnits.length === 0) {
            return true;
        }

        let targetOrganizationalUnits = getFieldValueForKey('organizationUnits', shortcut);
        let excludedOrganizationalUnits = getFieldValueForKey(
            'excludedOrganizationUnits',
            shortcut
        );

        if (!targetOrganizationalUnits && !excludedOrganizationalUnits) {
            return true;
        }

        let userHasExcludedOrganizationalUnits =
            excludedOrganizationalUnits &&
            excludedOrganizationalUnits.some((excludedOrganizationalUnit) => {
                return userOrganizationalUnits.indexOf(excludedOrganizationalUnit.value) !== -1;
            });

        if (userHasExcludedOrganizationalUnits) {
            return false;
        }

        if (targetOrganizationalUnits && targetOrganizationalUnits.length > 0) {
            return targetOrganizationalUnits.some((targetOrganizationalUnit) => {
                return userOrganizationalUnits.indexOf(targetOrganizationalUnit.value) !== -1;
            });
        }

        return true;
    };

    const goToShortcut = (shortcut) => {
        let shortcutType = getFieldValueForKey('shortcutType', shortcut);

        switch (shortcutType) {
            case 'toLink':
                let link = getFieldValueForKey('link', shortcut);

                if (!link) {
                    configurationError();

                    break;
                }

                let linkTarget = this.getFieldValueForKey('linkTarget', shortcut);

                switch (linkTarget) {
                    case '_self':
                        let url = new URL(link);

                        let pathname = trimEnd(url.pathname, '/');

                        let params = url.search
                            .slice(1)
                            .split('&')
                            .map((str) => {
                                return str.split('=');
                            })
                            .reduce((accum, [key, val]) => {
                                if (!accum.hasOwnProperty(key)) {
                                    accum[key] = val;
                                } else if (isArray(accum[key])) {
                                    accum[key].push(val);
                                } else {
                                    accum[key] = [accum[key], val];
                                }
                                return accum;
                            }, {});

                        let matchedRoute = this.$urlRouter.match({
                            path: pathname,
                            search: params,
                            hash: url === null ? null : url.hash.replace('#', '')
                        });

                        if (
                            matchedRoute &&
                            matchedRoute.rule &&
                            matchedRoute.rule.type === 'STATE'
                        ) {
                            this.$state.go(matchedRoute.rule.state.name, matchedRoute.match, {
                                reload: true,
                                inherit: false
                            });
                        } else {
                            this.Config.openUrl(link, linkTarget);
                        }

                        break;
                    case '_blank':
                    default:
                        this.Config.openUrl(link);
                }

                break;
            case 'toFeed':
                let feeds = getFieldValueForKey('feed', shortcut);

                if (!feeds || feeds.length === 0) {
                    configurationError();

                    break;
                }

                let feedsFound = feeds.filter((feed) => {
                    return (
                        workspacePermissionCheck({ id: feed.value }, 'READ') ||
                        workspacePermissionCheck({ id: feed.value }, 'WRITE')
                    );
                });

                if (feedsFound.length === 0) {
                    configurationError();

                    break;
                }

                if (feedsFound.length === 1) {
                    let feed = first(feedsFound);

                    let ws = this.Tree.findWorkspaceById(feed.value);

                    this.Tree.goToWorkspace(ws[0].workspace_data);
                } else {
                    let workspaces = [];

                    feedsFound.forEach((feed) => {
                        let ws = this.Tree.findWorkspaceById(feed.value);

                        workspaces.push({
                            workspace_data: ws[0].workspace_data
                        });
                    });

                    this.WorkspaceList.show({
                        workspaces,
                        displayMode: getFieldValueForKey('displayMode', shortcut)
                    });
                }

                break;
            case 'toCategory':
                let category = first(getFieldValueForKey('category', shortcut));

                if (!category) {
                    configurationError();

                    break;
                }

                this.$state.go(
                    `auth.${getFieldValueForKey('displayMode', shortcut)}.category`,
                    {
                        category: category.value
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toSharedTag':
                let tag = first(getFieldValueForKey('tag', shortcut));

                if (!tag) {
                    configurationError();

                    break;
                }

                this.Tags.getTag(tag.value).then((data) => {
                    this.Tags.goToTag(data);
                });

                break;
            case 'toCampaignType':
                let campaignType = getFieldValueForKey('campaignType', shortcut);

                if (!campaignType) {
                    configurationError();

                    break;
                }

                this.$state.go(
                    `auth.campaigns.feed`,
                    {
                        type: campaignType,
                        state: 'running',
                        page: 1,
                        opportunity: null
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toMissions':
                /*this.Missions.getMissionsFromServer().then(() => {
                    this.Missions.openMissions();
                });

                this.Modal.close('springboard');*/

                break;
            case 'toSubscriptionManagement':
                let subscriptionTarget = getFieldValueForKey('subscriptionTarget');
                let subscriptionCategoryTarget = getFieldValueForKey(
                    'subscriptionCategoryTarget'
                ).map((item) => item.value);

                this.$state.go(
                    'auth.profile.subscriptions',
                    {
                        userId: this.User.getUserObject().id,
                        open: subscriptionTarget,
                        categoryIds: subscriptionCategoryTarget
                    },
                    { reload: true, inherit: false }
                );

                break;
            case 'toTopAmbassador':
                if (!this.User.hasRole('ROLE_TOP_AMBASSADOR')) {
                    configurationError();

                    break;
                }

                this.Modal.open('suggest_post');

                break;
            case 'toCustomCace':
                let email = this.User.getUserObject().email;
                let timestamp = dayjs().unix();
                let token = sha256(`${email};${timestamp};15c6ec92f108d121`).toString();

                let url = `http://extranet.eluca.fr/?t=${token}&uid=${email}&d=${timestamp}`;

                this.Config.openUrl(url, '_blank', false);

                break;
            default:
                console.log('not implemented');
        }
    };

    return (
        <div
            className={`shortcuts ${getStyleValueForKey('isFoldable') ? 'foldable' : null}`}
            style={{ color: getStyleValueForKey('titleColor') }}
        >
            <div
                className="title"
                onClick={() => {
                    setSavedFoldedState(!isFolded);
                }}
            >
                <div className={`text ${classes(getStyleValueForKey('textAlign'))}`}>
                    {getFieldValueForKey('title')}
                </div>

                {getStyleValueForKey('isFoldable') && (
                    <div className="toggle">
                        <i
                            className={
                                isFolded
                                    ? 'icon-arrow-down-1-arrows-diagrams'
                                    : 'icon-arrow-up-1-arrows-diagrams'
                            }
                            aria-hidden="true"
                        ></i>
                    </div>
                )}
            </div>

            <div className={`wrapper ${isFolded ? 'hidden' : null}`}>
                {getFieldValueForKey('shortcuts')
                    .filter((shortcut) => isTargetedForUserOrganizationalUnit(shortcut))
                    .map((shortcut, index) => {
                        return (
                            <div
                                className="shortcut"
                                ng-click="$ctrl.goToShortcut(shortcut)"
                                key={`shortcut_${index}`}
                            >
                                {isTag(shortcut) && (
                                    <div
                                        className="icon"
                                        style={{
                                            backgroundColor: getFieldValueForKey(
                                                'iconBgColor',
                                                shortcut
                                            )
                                        }}
                                    >
                                        <div
                                            className="shield"
                                            style={{
                                                backgroundColor: getFieldValueForKey(
                                                    'iconColor',
                                                    shortcut
                                                )
                                            }}
                                        ></div>
                                    </div>
                                )}

                                {!isTag(shortcut) && (
                                    <div
                                        className="icon"
                                        style={{
                                            backgroundColor: getFieldValueForKey(
                                                'iconBgColor',
                                                shortcut
                                            ),
                                            color: getFieldValueForKey('iconColor', shortcut)
                                        }}
                                    >
                                        {isFirstLetterIconStyle(shortcut) && (
                                            <span>
                                                {getFirstLetter(
                                                    getFieldValueForKey('title', shortcut)
                                                )}
                                            </span>
                                        )}

                                        {!isFirstLetterIconStyle(shortcut) && (
                                            <i
                                                className={classes(getIconForKey('icon', shortcut))}
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                    </div>
                                )}

                                <div className="name">{getFieldValueForKey('title', shortcut)}</div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Shortcuts;
