import React, { useMemo, useState } from 'react';
import {
    createColumnHelper,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    flexRender
} from '@tanstack/react-table';
import { useAlert } from '../../providers/AlertContext';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';

const ActionButton = ({ action, row }) => {
    const { t } = useTranslation();
    const disabled = useMemo(() => {
        if (action.disableHandler) {
            return action.disableHandler(row.original);
        }

        return false;
    }, [action, row]);
    return (
        <Button {...action} disabled={disabled} onClick={() => action.handler(row.original)}>
            {t(action.label)}
        </Button>
    );
};

function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
}

const ExpandButton = ({ row, setExpandedRows }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        setExpandedRows((prev) => ({
            ...prev,
            [row.id]: !prev[row.id]
        }));
    };

    return (
        <Button className={'expand'} onClick={toggleExpand}>
            <i
                className={`fa ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                aria-hidden="true"></i>
        </Button>
    );
};

const columnHelper = createColumnHelper();

/**
 * Table
 * @param {[]} columns header with id, header, cell, sortable, sortDesc
 * @param {[]} data data with id, name, index, levelConfigName, usersCount, rulesCount
 * @param {boolean} isLoading
 * @param {boolean} selectable enable row selection and multi row selection
 * @param {boolean} expandable enable row expandable
 * @param {[]} handleSelectedRows handlers for selected rows
 * @param {[]} handleActions handlers for selected rows
 * @param {boolean} actionsAsButtons display actions as buttons
 * @returns {JSX.Element}
 */
const Table = ({
    columns,
    data,
    isLoading,
    selectable,
    subRowPath,
    subColumns,
    handleSelectedRows,
    handleActions,
    actionsAsButtons
}) => {
    const [rowSelection, setRowSelection] = React.useState({});
    const { t } = useTranslation();
    const { showActionSheet } = useAlert();
    const [expandedRows, setExpandedRows] = useState({});

    const colSpan = useMemo(() => {
        return (
            columns.length +
            (selectable ? 1 : 0) +
            (subColumns ? 1 : 0) +
            (handleActions?.length > 0 ? 1 : 0)
        );
    }, [columns, handleActions, selectable, subColumns]);

    const memoizedColumns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'select',
                hidden: !selectable,
                enableSorting: false,
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler()
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler()
                            }}
                        />
                    </div>
                )
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'expand',
                hidden: !subColumns,
                enableSorting: false,
                header: ({ table }) => <></>,
                cell: ({ row }) => (
                    <div>
                        <ExpandButton row={row} setExpandedRows={setExpandedRows} />
                    </div>
                )
            }),
            ...columns.map((column) => {
                return columnHelper.accessor((row) => get(row, column.id), {
                    enableSorting: !!column.sortable,
                    ...column,
                    header: t(column.header)
                });
            }),
            columnHelper.accessor((row) => row.id, {
                id: 'action',
                hidden: !(handleActions && handleActions.length > 0),
                enableSorting: false,
                meta: {
                    align: 'right'
                },
                header: () => <div style={{ textAlign: 'right' }}>Actions</div>,
                cell: ({ row }) => {
                    return (
                        <div style={{ textAlign: 'right' }}>
                            {!actionsAsButtons ? (
                                <Button
                                    onClick={() => {
                                        showActionSheet({
                                            title: 'Actions',
                                            buttons: handleActions.map((action) => ({
                                                ...action,
                                                label: t(action.label),
                                                callback: () => {
                                                    action.handler(row.original);
                                                }
                                            }))
                                        });
                                    }}>
                                    <i className="fa fa-cogs" aria-hidden="true"></i>
                                </Button>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        gap: '4px'
                                    }}>
                                    {handleActions.map((action) => (
                                        <ActionButton action={action} row={row} />
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                }
            })
        ],
        [columns, selectable, handleActions]
    );
    const memoizedData = useMemo(() => data, [data]);

    const table = useReactTable({
        columns: memoizedColumns.filter((column) => !column.hidden),
        data: memoizedData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),

        initialState: {
            hiddenColumns: {
                select: true
            },
            sorting: columns
                .filter((column) => column.defaultSort)
                .map((column) => {
                    return {
                        id: column.id,
                        ...(column.defaultSort ? { [column.defaultSort]: true } : {})
                    };
                })
        },
        state: {
            rowSelection
        },
        enableRowSelection: selectable,
        onRowSelectionChange: (newRowSelection) => {
            setRowSelection(newRowSelection);
        }
    });

    if (isLoading) return <div className={'loading'}>Loading...</div>;

    return (
        <div className="wrapper-table">
            <table className="ts-table">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ cursor: 'pointer' }}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    <span>
                                        {header.column.getIsSorted()
                                            ? header.column.getIsSorted() === 'desc'
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <>
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {subRowPath && expandedRows[row.id] && (
                                    <tr key={`sub_row_${row.id}`}>
                                        <td colSpan={colSpan}>
                                            <Table
                                                columns={subColumns}
                                                data={get(row.original, subRowPath)}
                                                isLoading={isLoading}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </>
                        );
                    })}
                </tbody>
            </table>

            <div className="ts-table-footer">
                <div className="table-footer__selectable">
                    {selectable && Object.keys(rowSelection).length > 0 && (
                        <div className="selected-rows">
                            {Object.keys(rowSelection).length} sur{' '}
                            {table.getPreFilteredRowModel().rows.length} lignes sélectionnées
                            <Button
                                onClick={() => {
                                    showActionSheet({
                                        title: 'Actions',
                                        buttons: handleSelectedRows.map((action) => ({
                                            ...action,
                                            label: t(action.label),
                                            callback: () => {
                                                action.handler(rowSelection);
                                                table.resetRowSelection();
                                            }
                                        }))
                                    });
                                }}>
                                Actions
                            </Button>
                        </div>
                    )}
                </div>

                <div className="table-footer__results">
                    <div className="pagination">
                        <Button
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}>
                            Prédédent
                        </Button>
                        <span>
                            {' '}
                            {table.getState().pagination.pageIndex + 1} sur {table.getPageCount()}{' '}
                        </span>
                        <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                            Suivant
                        </Button>
                    </div>

                    <div>
                        <Select
                            value={table.getState().pagination.pageSize}
                            onChange={(value) => {
                                table.setPageSize(Number(value));
                            }}
                            options={[10, 20, 30, 40, 50].map((pageSize) => {
                                return {
                                    label: `Afficher ${pageSize}`,
                                    value: pageSize
                                };
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
