import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React, { useMemo } from 'react';
import { Input, Checkbox, Select, Textarea } from 'components/Form/Inputs';
import { WorkspaceList } from 'components/Form/Admin/Inputs';
import { orderBy, isEmpty } from 'lodash-es';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import useOrganizationalUnitService from '../api/useOrganizationalUnitService';
import { useAlert } from 'providers/AlertContext';
import { useModal } from 'providers/ModalContext';
import { useQueryClient } from '@tanstack/react-query';
import useUtils from 'hooks/useUtils';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';

const OUFormModalContent = () => {
    const { t } = useTranslation();
    const { levels } = useAdminLevelContext();
    const {
        contexts: {
            add_edit_ou: { currentOU }
        },
        closeModal
    } = useModal();
    const { postOrganizationalUnit } = useOrganizationalUnitService();
    const { showToast } = useAlert();
    const { arrayAsId } = useUtils();
    const queryClient = useQueryClient();

    const methods = useForm({
        defaultValues: currentOU
            ? {
                  ...currentOU,
                  excluded_emails: currentOU?.excluded_emails?.join('\n'),
                  manualUserEmails: currentOU?.manualUserEmails?.join('\n')
              }
            : {}
    });

    const onFinish = (values) => {
        const payload = { id: currentOU?.id, ...values };
        payload.excluded_emails = payload.excluded_emails.split('/n');
        payload.manualUserEmails = payload.manualUserEmails.split('/n');
        payload.writable_workspaces_target = arrayAsId(payload.writable_workspaces_target);
        payload.readable_workspaces_target = arrayAsId(payload.readable_workspaces_target);
        payload.levels_target = arrayAsId(payload.levels_target);
        postOrganizationalUnit(payload)
            .then(
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_CONFIRM'),
                        duration: 1500
                    });
                    queryClient.refetchQueries({ queryKey: ['allOrganizationalUnits'] });
                },
                () => {
                    showToast({
                        text: t('ADMIN_LEVEL_DIALOG_LEVEL_CONFIG_CREATED_ERROR'),
                        duration: 1500
                    });
                }
            )
            .finally(() => {
                closeModal('add_edit_ou');
            });
    };

    const formIsFilled = useMemo(() => {
        if (!isEmpty(methods.getValues())) {
            if (!isEmpty(methods.watch('writable_workspaces_target'))) {
                return true;
            }

            if (!isEmpty(methods.watch('readable_workspaces_target'))) {
                return true;
            }

            if (!isEmpty(methods.watch('levels_target'))) {
                return true;
            }

            return false;
        } else {
            return (
                !isEmpty(currentOU?.readable_workspaces_target) ||
                !isEmpty(currentOU?.writable_workspaces_target) ||
                !isEmpty(currentOU?.levels_target)
            );
        }
    }, [
        methods.watch('writable_workspaces_target'),
        methods.watch('readable_workspaces_target'),
        methods.watch('levels_target')
    ]);

    return (
        <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
            <ModalFormBody>
                <ModalFormSection>
                    <Input
                        type={'text'}
                        name={'name'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_NAME_LABEL')}
                    />
                    <Checkbox
                        name={'entity'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_ENTITY_LABEL')}
                    />
                    <Checkbox name={'for_external'} label={t('FOR_EXTERNALS')} />
                    <Checkbox
                        name={'targetable'}
                        label={t('ADMIN_MENU_HELPDESK_COMMON_CAMPAIGN_TARGET_LABEL')}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    {(!formIsFilled || methods.watch('readable_workspaces_target')?.length > 0) && (
                        <WorkspaceList
                            label={`${t('ORGANIZATIONAL_UNIT_DATAFEED')}} (${t('WORKSPACE_READERS')}`}
                            mode={'multiple'}
                            name={'readable_workspaces_target'}
                        />
                    )}

                    {!formIsFilled && (
                        <div className="text-center">
                            - {t('TA_CREATE_CAMPAIGN_REWARD_OR_LABEL').toUpperCase()} -
                        </div>
                    )}

                    {(!formIsFilled || methods.watch('writable_workspaces_target')?.length > 0) && (
                        <WorkspaceList
                            label={`${t('ORGANIZATIONAL_UNIT_DATAFEED')}} (${t('WORKSPACE_WRITERS')}`}
                            mode={'multiple'}
                            name={'writable_workspaces_target'}
                        />
                    )}

                    {!formIsFilled && (
                        <div className="text-center">
                            - {t('TA_CREATE_CAMPAIGN_REWARD_OR_LABEL').toUpperCase()} -
                        </div>
                    )}

                    {(!formIsFilled || methods.watch('levels_target')?.length > 0) && (
                        <Select
                            label={`${t('ORGANIZATIONAL_UNIT_LEVEL')} (seuls les levels configurés sont affichés dans la liste)`}
                            style={{ width: '100%' }}
                            mode={'multiple'}
                            name={'levels_target'}
                            options={orderBy(levels, ['name']).map((level) => {
                                return {
                                    label: level.name,
                                    value: level.id
                                };
                            })}
                        />
                    )}
                </ModalFormSection>
                <ModalFormSection>
                    <Textarea
                        rows={4}
                        type={'text'}
                        label={t('EXCLUDE_USERS_FOR_OU')}
                        name={'excluded_emails'}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <Textarea
                        rows={4}
                        type={'text'}
                        label={t('ADD_USERS_FOR_OU')}
                        name={'manualUserEmails'}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <Input label={t('STAFF_COUNT')} type={'text'} name={'staff_count'} />
                </ModalFormSection>
                <ModalFormSection>
                    <Input
                        label={t('ADMIN_MENU_HELPDESK_COMMON_POSITION_LABEL')}
                        type={'number'}
                        name={'position'}
                        value={currentOU?.position}
                    />
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_edit_ou');
                }}
            />
        </ModalForm>
    );
};

const OUFormModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name={`add_edit_ou`}
            title={t('ADD_ORGANIZATIONAL_UNIT')}
            options={{
                isFullscreen: true,
                hasFlexContent: true
            }}>
            <OUFormModalContent />
        </Modal>
    );
};

export default OUFormModal;
