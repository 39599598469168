import { useHttp } from 'api/useHttp';

const useUserAppSettingService = () => {
    const axios = useHttp();

    const getSettingsUserOverride = () => {
        return axios.get('/user/configs').then((response) => response.data);
    };

    const getUserTextConfigs = () => {
        return axios.get('/user/text/configs').then((response) => response.data);
    };

    const getSettingsWithoutUserOverride = () => {
        return axios.get('/user/configs?override=false').then((response) => response.data);
    };

    const postUserConfig = (config) => {
        return axios.post('/user/configs', { config }).then((response) => response.data);
    };

    const postUserTextConfig = (config) => {
        return axios.post('/user/text/configs', { config }).then((response) => response.data);
    };

    return {
        getSettingsUserOverride,
        getUserTextConfigs,
        getSettingsWithoutUserOverride,
        postUserConfig,
        postUserTextConfig
    };
};

export default useUserAppSettingService;
