import { useHttp } from 'api/useHttp';

const useCampaignActivityService = () => {
    const axios = useHttp();

    const getAdminActivities = (page, filter, showAll) => {
        return axios
            .get(
                `/campaign/activities?page=${page}&filter=${JSON.stringify(filter)}&showAll=${showAll}`
            )
            .then((response) => response.data);
    };

    const getActivityById = (id) => {
        return axios.get(`/campaign/activity/${id}`).then((response) => response.data);
    };

    return {
        getAdminActivities,
        getActivityById
    };
};

export default useCampaignActivityService;
