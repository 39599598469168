import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import classes from 'classnames';
import { first } from 'lodash-es';
import { useComposer } from '../../../pages/Auth/providers/ComposerContext';
import useRight from 'hooks/useRight';

const Action = ({ widget, options }) => {
    const { showComposer } = useComposer();
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        getIconForKey,
        getRoundSize,
        isImageDisplayType,
        configurationError
    } = useWidgetComponent(widget, options);
    const { isAllowToWrite } = useRight();

    const goToAction = () => {
        let actionType = getFieldValueForKey('actionType');

        switch (actionType) {
            case 'postToFeed':
                let feeds = getFieldValueForKey('feeds');

                if (!feeds || feeds.length === 0) {
                    configurationError();

                    break;
                }

                let writableFeeds = feeds.filter((feed) => {
                    return isAllowToWrite(feed.value);
                });

                if (!writableFeeds || writableFeeds.length === 0) {
                    configurationError();

                    break;
                }

                if (writableFeeds.length === 1) {
                    showComposer({
                        workspace: first(writableFeeds)
                    });
                } else {
                    let workspaces = [];

                    /*writableFeeds.forEach((writableFeed) => {
                        let w = this.Tree.findWorkspaceById(writableFeed.value);

                        workspaces.push({
                            workspace_data: w[0].workspace_data,
                        });
                    });

                    this.WorkspaceList.show({
                        workspaces,
                        type: 'action',
                    });*/
                }

                break;
            case 'postToCategory':
                let category = first(getFieldValueForKey('category'));

                if (!category) {
                    configurationError();

                    break;
                }

                showComposer({
                    category
                });

                break;
            case 'postToAll':
                showComposer();

                break;
            default:
                console.log('not implemented');
        }
    };

    return (
        <>
            {!isImageDisplayType() && (
                <div
                    className={`action ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToAction}
                >
                    <div
                        className={`icon ${classes(getStyleValueForKey('iconShape'))}`}
                        style={{
                            backgroundColor: getStyleValueForKey('iconBgColor'),
                            color: getStyleValueForKey('iconColor')
                        }}
                    >
                        <i className={classes(getIconForKey('icon'))}></i>
                    </div>

                    <div className={`title ${classes(getStyleValueForKey('textAlign'))}`}>
                        {getFieldValueForKey('title')}
                    </div>
                </div>
            )}

            {isImageDisplayType() && (
                <div
                    className={`action ${classes(getStyleValueForKey('iconPosition'))}`}
                    style={{ color: getStyleValueForKey('titleColor') }}
                    onClick={goToAction}
                >
                    <div
                        className="image"
                        style={{ borderRadius: getRoundSize(), overflow: 'hidden' }}
                    >
                        <img src={getFieldValueForKey('image')} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Action;
