import React from 'react';
import { Input as AntdInput } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

function Textarea({ name, value = null, ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field, formState }) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                    <AntdInput.TextArea {...props} {...field} defaultValue={value} />
                    {formState.errors[name] && (
                        <span className="error">{formState.errors[name].message}</span>
                    )}
                </div>
            )}
        />
    );
}

export default Textarea;
