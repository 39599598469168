import useNavbar from 'components/Navbar/hooks/useNavbar';
import { Select } from 'components/Form/Inputs';

const Routes = ({ field, fieldName, ...props }) => {
    const { getStates } = useNavbar();
    return (
        <Select
            name={fieldName}
            {...props}
            options={getStates().map((state) => {
                return {
                    name: state.navbar.getAdminLabel
                        ? state.navbar.getAdminLabel(this)
                        : state.navbar.getLabel(this),
                    value: state.name
                };
            })}
            shouldUnregister={false}
        />
    );
};

export default Routes;
