import BaseWidgets from 'components/Widgets/services/BaseWidgets';

export default class NavbarWidgetsService extends BaseWidgets {
    constructor() {
        super({
            type: 'navbar',
            defaultPosition: 1
        });

        this.position = {
            public: 1,
            authenticated: 2
        };

        this.prepareWidgets();
    }

    prepareWidgets() {
        this.widgets = [
            {
                name: 'Lien interne',
                type: 'link'
            },
            {
                name: 'Lien externe',
                type: 'externalLink'
            },
            {
                name: 'Menu déroulant',
                type: 'dropdown'
            },
            {
                name: "Bouton d'action",
                type: 'button'
            },
            {
                name: 'Image',
                type: 'image'
            },
            {
                name: 'Séparateur',
                type: 'separator'
            }
        ];

        this.widgets = this.widgets.map((widget) => {
            return {
                ...widget,
                fields: this.getFieldsForWidget(widget),
                styling: this.getStyleForWidget(widget)
            };
        });
    }

    getFieldsForWidget(widget, withoutOrganizationalUnits = false) {
        let fields = [
            {
                name: 'Titre du lien',
                key: 'title',
                value: widget.name
            }
        ];

        if (!withoutOrganizationalUnits) {
            fields = fields.concat([
                {
                    name: "Groupes d'utilisateurs",
                    key: 'organizationUnits',
                    special: 'organizationUnits',
                    ignoreFromImport: true
                },
                {
                    name: "Groupes d'utilisateurs exclus",
                    key: 'excludedOrganizationUnits',
                    special: 'organizationUnits',
                    ignoreFromImport: true
                }
            ]);
        }

        switch (widget.type) {
            case 'link':
                return fields.concat([
                    {
                        name: 'Cible du lien',
                        key: 'link',
                        special: 'routes'
                    }
                ]);
            case 'externalLink':
                return fields.concat([
                    {
                        name: 'Cible du lien',
                        key: 'link'
                    },
                    {
                        name: "Mode d'ouverture du lien",
                        key: 'linkTarget',
                        values: [
                            {
                                name: 'Ouvrir dans le même onglet',
                                value: '_self'
                            },
                            {
                                name: 'Ouvrir dans un nouvel onglet',
                                value: '_blank'
                            },
                            {
                                name: 'Ouvrir dans une pop-up',
                                value: '_popup'
                            }
                        ],
                        value: '_self',
                        required: true
                    },
                    {
                        name: 'Titre de la pop-up',
                        key: 'popupTitle',
                        condition: {
                            key: 'linkTarget',
                            operator: '=',
                            value: '_popup'
                        }
                    }
                ]);
            case 'button':
                return fields.concat([
                    {
                        name: 'Action au clic',
                        key: 'action',
                        values: [
                            {
                                name: 'Poster une actualité',
                                value: 'openComposer'
                            },
                            {
                                name: "Ouvrir l'ancre mobile",
                                value: 'openMobileAnchor'
                            },
                            {
                                name: 'Rechercher',
                                value: 'openSearch'
                            },
                            {
                                name: 'Filtrer les actualités',
                                value: 'openTree'
                            },
                            {
                                name: 'Ouvrir les missions (pour les magasins)',
                                value: 'openMissions'
                            },
                            {
                                name: 'Ouvrir les réalisations',
                                value: 'openRealizations'
                            },
                            {
                                name: 'Ouvrir les essentiels',
                                value: 'openEssentials'
                            }
                        ],
                        value: 'openComposer',
                        required: true
                    },
                    {
                        name: "Type d'action",
                        key: 'actionType',
                        values: [
                            {
                                name: 'Poster dans un data feed (par défaut)',
                                value: 'postToFeed'
                            },
                            {
                                name: 'Poster dans une catégorie',
                                value: 'postToCategory'
                            },
                            {
                                name: 'Poster une actualité',
                                value: 'postToAll'
                            }
                        ],
                        value: 'postToFeed',
                        required: true,
                        condition: {
                            key: 'action',
                            operator: '=',
                            value: 'openComposer'
                        }
                    },
                    {
                        name: 'Choisir le ou les data feeds',
                        key: 'feeds',
                        special: 'feeds',
                        multiple: true,
                        conditions: [
                            {
                                key: 'action',
                                operator: '=',
                                value: 'openComposer'
                            },
                            {
                                key: 'actionType',
                                operator: '=',
                                value: 'postToFeed'
                            }
                        ],
                        ignoreFromImport: true
                    },
                    {
                        name: 'Choisir la catégorie',
                        key: 'category',
                        special: 'categories',
                        conditions: [
                            {
                                key: 'action',
                                operator: '=',
                                value: 'openComposer'
                            },
                            {
                                key: 'actionType',
                                operator: '=',
                                value: 'postToCategory'
                            }
                        ],
                        ignoreFromImport: true
                    },
                    {
                        name: "Mode d'affichage",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Fenêtre pop-up (par défaut)',
                                value: 'popup'
                            },
                            {
                                name: 'Fenêtre fixe',
                                value: 'fixed'
                            }
                        ],
                        value: 'popup',
                        required: true,
                        condition: {
                            key: 'action',
                            operator: '=',
                            value: 'openMobileAnchor'
                        }
                    },
                    {
                        name: 'Masquer le header',
                        key: 'hideHeader',
                        values: [
                            {
                                name: 'Non (par défaut)',
                                value: false
                            },
                            {
                                name: 'Oui',
                                value: true
                            }
                        ],
                        value: false,
                        required: true,
                        conditions: [
                            {
                                key: 'action',
                                operator: '=',
                                value: 'openMobileAnchor'
                            },
                            {
                                key: 'displayMode',
                                operator: '=',
                                value: 'popup'
                            }
                        ]
                    },
                    {
                        name: "Direction à l'ouverture",
                        key: 'openDirection',
                        values: [
                            {
                                name: 'Depuis le bas (par défaut)',
                                value: 'from-bottom'
                            },
                            {
                                name: 'Depuis la droite',
                                value: 'from-right'
                            },
                            {
                                name: 'Depuis la gauche',
                                value: 'from-left'
                            }
                        ],
                        value: 'from-bottom',
                        required: true,
                        conditions: [
                            {
                                key: 'action',
                                operator: '=',
                                value: 'openMobileAnchor'
                            },
                            {
                                key: 'displayMode',
                                operator: '=',
                                value: 'popup'
                            }
                        ]
                    }
                ]);
            case 'dropdown':
                const widgetsRepeaterFields = this.widgets
                    .filter((widget) => {
                        return (
                            widget.type !== 'image' &&
                            widget.type !== 'dropdown' &&
                            widget.type !== 'separator'
                        );
                    })
                    .flatMap((widget) =>
                        this.getFieldsForWidget(widget, true).flatMap((field) => {
                            const conditions = field.conditions
                                ? field.conditions.map((condition) => {
                                      return {
                                          ...condition,
                                          key: `repeater-${widget.type}-${condition.key}`
                                      };
                                  })
                                : [];

                            return {
                                ...field,
                                key: `repeater-${widget.type}-${field.key}`,
                                condition: null,
                                conditions: [
                                    {
                                        key: 'repeaterType',
                                        value: (value) => {
                                            return value === widget.type;
                                        }
                                    },
                                    field.condition
                                        ? {
                                              ...field.condition,
                                              key: `repeater-${widget.type}-${field.condition.key}`
                                          }
                                        : null,
                                    ...conditions
                                ]
                            };
                        })
                    );

                let repeaterFields = [
                    {
                        name: "Type d'élément",
                        key: 'repeaterType',
                        values: this.widgets
                            .filter((widget) => {
                                return (
                                    widget.type !== 'image' &&
                                    widget.type !== 'dropdown' &&
                                    widget.type !== 'separator'
                                );
                            })
                            .map((widget) => {
                                return {
                                    name: widget.name,
                                    value: widget.type
                                };
                            }),
                        value: 'link',
                        required: true
                    },
                    ...widgetsRepeaterFields,
                    {
                        name: 'Icône',
                        key: `repeaterIcon`,
                        special: 'icons'
                    }
                ];

                return fields.concat([
                    {
                        name: 'Liste des éléments du menu',
                        key: 'children',
                        special: 'repeater',
                        fields: repeaterFields
                    }
                ]);
            case 'image':
                return fields.concat([
                    {
                        name: 'Image',
                        key: 'image',
                        special: 'image'
                    }
                ]);
            default:
                return fields;
        }
    }

    getStyleForWidget(widget) {
        let styling = [
            {
                name: 'Visibilité',
                key: 'visibility',
                values: [
                    {
                        name: 'Web et mobile (par défaut)',
                        value: 'all'
                    },
                    {
                        name: 'Uniquement sur web',
                        value: 'web'
                    },
                    {
                        name: 'Uniquement sur mobile',
                        value: 'mobile'
                    }
                ],
                value: 'all',
                required: true
            },
            {
                name: "Mode d'affichage sur le web",
                key: 'displayMode',
                values: [
                    {
                        name: 'Texte (par défaut)',
                        value: 'text'
                    },
                    {
                        name: 'Icône',
                        value: 'icon'
                    },
                    {
                        name: 'Image',
                        value: 'image'
                    }
                ],
                value: 'text',
                required: true,
                condition: {
                    key: 'visibility',
                    operator: '=',
                    value: 'all|web'
                }
            },
            {
                name: "Mode d'affichage sur mobile",
                key: 'mobileDisplayMode',
                values: [
                    {
                        name: 'Classique (par défaut)',
                        value: 'classic-icon'
                    },
                    {
                        name: 'Grande icône',
                        value: 'big-icon'
                    }
                ],
                value: 'classic-icon',
                required: true,
                condition: {
                    key: 'visibility',
                    operator: '=',
                    value: 'all|mobile'
                }
            },
            {
                name: 'Icône',
                key: `icon`,
                special: 'icons',
                atLeastOneConditionIsTrue: true,
                conditions: [
                    {
                        key: 'visibility',
                        operator: '=',
                        value: 'all|mobile'
                    },
                    {
                        key: 'displayMode',
                        operator: '=',
                        value: 'icon'
                    }
                ]
            },
            {
                name: 'Image',
                key: 'image',
                special: 'image',
                conditions: [
                    {
                        key: 'visibility',
                        operator: '!=',
                        value: 'mobile'
                    },
                    {
                        key: 'displayMode',
                        operator: '=',
                        value: 'image'
                    }
                ]
            },
            {
                name: "Taille de l'image",
                key: 'imageSize',
                values: [
                    {
                        name: 'Normale (par défaut)',
                        value: 'regular'
                    },
                    {
                        name: 'Pleine hauteur',
                        value: 'larger'
                    }
                ],
                value: 'regular',
                required: true,
                conditions: [
                    {
                        key: 'visibility',
                        operator: '!=',
                        value: 'mobile'
                    },
                    {
                        key: 'displayMode',
                        operator: '=',
                        value: 'image'
                    }
                ]
            },
            {
                name: 'Couleur de fond',
                key: 'bgColor',
                special: 'colors',
                conditions: [
                    {
                        key: 'visibility',
                        operator: '!=',
                        value: 'mobile'
                    },
                    {
                        key: 'displayMode',
                        operator: '=',
                        value: 'image'
                    },
                    {
                        key: 'imageSize',
                        operator: '=',
                        value: 'regular'
                    }
                ]
            }
        ];

        switch (widget.type) {
            case 'separator':
                return [];
            case 'image':
                return [
                    {
                        name: 'Visibilité',
                        key: 'visibility',
                        values: [
                            {
                                name: 'Web et mobile (par défaut)',
                                value: 'all'
                            },
                            {
                                name: 'Uniquement sur web',
                                value: 'web'
                            },
                            {
                                name: 'Uniquement sur mobile',
                                value: 'mobile'
                            }
                        ],
                        value: 'all',
                        required: true
                    },
                    {
                        name: "Mode d'affichage sur le web",
                        key: 'displayMode',
                        values: [
                            {
                                name: 'Image',
                                value: 'image'
                            }
                        ],
                        value: 'image',
                        disabled: true,
                        required: true
                    },
                    {
                        name: "Taille de l'image",
                        key: 'imageSize',
                        values: [
                            {
                                name: 'Normale (par défaut)',
                                value: 'regular'
                            },
                            {
                                name: 'Pleine hauteur',
                                value: 'larger'
                            }
                        ],
                        value: 'regular',
                        required: true
                    },
                    {
                        name: 'Couleur de fond',
                        key: 'bgColor',
                        special: 'colors',
                        condition: {
                            key: 'imageSize',
                            operator: '=',
                            value: 'regular'
                        }
                    }
                ];
            case 'dropdown':
                return styling.concat([
                    {
                        name: 'Alignement du menu déroulant',
                        key: 'position',
                        values: [
                            {
                                name: 'Aligner à gauche (par défaut)',
                                value: 'left'
                            },
                            {
                                name: 'Aligner à droite',
                                value: 'right'
                            }
                        ],
                        value: 'left',
                        required: true
                    }
                ]);
            default:
                return styling;
        }
    }

    getSpringboardFieldsConfigs() {
        return [
            {
                name: 'Marge à gauche',
                key: 'marginLeft',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Marge à droite',
                key: 'marginRight',
                min: 0,
                max: 30,
                step: 1,
                unit: 'px',
                value: 15
            },
            {
                name: 'Personnalisation des couleurs',
                key: 'customColors',
                special: 'customizer',
                values: this.getGlobalCustomizer()
            }
        ];
    }

    getGlobalCustomizer() {
        return [
            {
                name: 'Version web',
                icon: 'icon-desktop-monitor-smiley',
                values: [
                    {
                        name: 'Couleur de fond',
                        values: [
                            {
                                name: 'Couleur de départ du dégradé',
                                key: '--navbar-gradient-start-color'
                            },
                            {
                                name: 'Couleur de fin du dégradé',
                                key: '--navbar-gradient-stop-color'
                            },
                            {
                                name: 'Direction du dégradé',
                                key: '--navbar-gradient-angle',
                                min: 0,
                                max: 360,
                                step: 10,
                                unit: 'deg'
                            }
                        ]
                    },
                    {
                        name: 'Éléments (affichage Texte)',
                        values: [
                            {
                                name: 'Couleur du texte',
                                key: '--navbar-text-color'
                            },
                            {
                                name: 'Couleur du texte (au survol)',
                                key: '--navbar-text-hover-color'
                            },
                            {
                                name: 'Couleur du texte (sélectionné)',
                                key: '--navbar-text-active-color'
                            },
                            {
                                name: 'Couleur de départ du fond dégradé (sélectionné)',
                                key: '--navbar-text-active-gradient-start-color'
                            },
                            {
                                name: 'Couleur de fin du fond dégradé (sélectionné)',
                                key: '--navbar-text-active-gradient-stop-color'
                            },
                            {
                                name: 'Direction du fond dégradé (sélectionné)',
                                key: '--navbar-text-active-gradient-angle',
                                min: 0,
                                max: 360,
                                step: 10,
                                unit: 'deg'
                            }
                        ]
                    },
                    {
                        name: 'Éléments (affichage Icône)',
                        values: [
                            {
                                name: 'Couleur des icônes',
                                key: '--navbar-icon-color'
                            },
                            {
                                name: 'Couleur des icônes (au survol)',
                                key: '--navbar-icon-hover-color'
                            },
                            {
                                name: 'Couleur des icônes (sélectionné)',
                                key: '--navbar-icon-active-color'
                            }
                        ]
                    },
                    {
                        name: 'Menu déroulant',
                        values: [
                            {
                                name: 'Couleur de fond',
                                key: '--navbar-dropdown-color'
                            },
                            {
                                name: 'Couleur du texte',
                                key: '--navbar-dropdown-text-color'
                            },
                            {
                                name: 'Couleur de fond des icônes',
                                key: '--navbar-dropdown-icon-color'
                            },
                            {
                                name: 'Couleur des icônes',
                                key: '--navbar-dropdown-icon-text-color'
                            },
                            {
                                name: 'Couleur de fond des icônes (au survol & sélectionné)',
                                key: '--navbar-dropdown-icon-active-color'
                            },
                            {
                                name: 'Couleur des icônes (au survol & sélectionné)',
                                key: '--navbar-dropdown-icon-active-text-color'
                            },
                            {
                                name: 'Couleur du séparateur entre les éléments',
                                key: '--navbar-dropdown-border-color'
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Version mobile',
                icon: 'icon-face-id-smartphone',
                values: [
                    {
                        name: 'Couleur de fond',
                        values: [
                            {
                                name: 'Couleur de départ du dégradé',
                                key: '--navbar-mobile-gradient-start-color'
                            },
                            {
                                name: 'Couleur de fin du dégradé',
                                key: '--navbar-mobile-gradient-stop-color'
                            },
                            {
                                name: 'Direction du dégradé',
                                key: '--navbar-mobile-gradient-angle',
                                min: 0,
                                max: 360,
                                step: 10,
                                unit: 'deg'
                            },
                            {
                                name: 'Couleur de la bordure supérieure',
                                key: '--navbar-mobile-border-color'
                            }
                        ]
                    },
                    {
                        name: 'Éléments (affichage Classique)',
                        values: [
                            {
                                name: 'Couleur du texte et des icônes',
                                key: '--navbar-mobile-text-color'
                            },
                            {
                                name: 'Couleur du texte et des icônes (sélectionné)',
                                key: '--navbar-mobile-active-text-color'
                            }
                        ]
                    },
                    {
                        name: 'Éléments (affichage Grande icône)',
                        values: [
                            {
                                name: 'Couleur de fond',
                                key: '--navbar-mobile-highlight-color'
                            },
                            {
                                name: 'Couleur des icônes',
                                key: '--navbar-mobile-highlight-text-color'
                            },
                            {
                                name: 'Couleur de fond (sélectionné)',
                                key: '--navbar-mobile-highlight-active-color'
                            },
                            {
                                name: 'Couleur des icônes (sélectionné)',
                                key: '--navbar-mobile-highlight-active-text-color'
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Les badges de notification',
                icon: 'icon-alarm-bell-1',
                values: [
                    {
                        name: 'Couleur de fond',
                        key: '--navbar-badge-color'
                    },
                    {
                        name: 'Couleur du texte',
                        key: '--navbar-badge-text-color'
                    }
                ]
            }
        ];
    }
}
