import React, { useCallback, useState } from 'react';
import MetricsProvider, { useMetricsContext } from './providers/MetricProvider';
import { UIView, useRouter } from '@uirouter/react';
import useRight from '../../hooks/useRight';
import { useAuth } from '../../providers/AuthContext';

const MetricsContent = () => {
    const { organizationalUnits, levels } = useMetricsContext();
    const { hasRole } = useRight();
    const { user } = useAuth();
    const router = useRouter();
    const [menuClosed, setMenuClosed] = useState(false);

    const currentRoute = useCallback(
        (routeName) => {
            return router.globals.current.name === `auth.metrics.${routeName}`;
        },
        [router]
    );

    const canAccess = () => {
        return user.restrictedLevelConfigs.length === 0;
    };

    return (
        <div className="metrics">
            <div className={`sidebar scrollable ${menuClosed ? 'closed' : ''}`}>
                <i
                    className={`arrow is-clickable fa ${menuClosed ? 'fa-chevron-right' : 'fa-chevron-left'}`}
                    onClick={() => {
                        setMenuClosed(!menuClosed);
                    }}
                ></i>
                {hasRole('HAS_DASHBOARD_METRICS') && (
                    <a
                        className={currentRoute('dashboard') ? 'active' : ''}
                        href={router.stateService.href('auth.metrics.dashboard')}
                    >
                        Tableau de bord
                    </a>
                )}
                {canAccess() && (
                    <a
                        className={currentRoute('global') ? 'active' : ''}
                        href={router.stateService.href('auth.metrics.global')}
                    >
                        Statistiques globales
                    </a>
                )}

                {levels && levels.length > 0 && (
                    <a
                        className={currentRoute('levels') ? 'active' : ''}
                        href={router.stateService.href('auth.metrics.levels', { page: 0 })}
                    >
                        Statistiques par level
                    </a>
                )}
                {canAccess() && organizationalUnits && organizationalUnits.length > 0 && (
                    <a
                        className={currentRoute('ou') ? 'active' : ''}
                        href={router.stateService.href('auth.metrics.ou', {
                            id: organizationalUnits[0].id
                        })}
                    >
                        Statistiques par groupe d'utilisateur
                    </a>
                )}
                {organizationalUnits && organizationalUnits.length > 0 && currentRoute('ou') && (
                    <div className="organizational-units">
                        {organizationalUnits.map((organizationalUnit) => {
                            return (
                                <a
                                    className="button"
                                    ng-class="{ 'active': $ctrl.currentRoute('ou') && $ctrl.isId(organizationalUnit.id) }"
                                    href={router.stateService.href('auth.metrics.ou', {
                                        id: organizationalUnit.id
                                    })}
                                >
                                    {organizationalUnit.name}
                                </a>
                            );
                        })}
                    </div>
                )}
                {canAccess() && (
                    <>
                        <a
                            className={currentRoute('ws') ? 'active' : ''}
                            href={router.stateService.href('auth.metrics.ws')}
                        >
                            Statistiques par data-feed
                        </a>
                        <a
                            className={currentRoute('tags') ? 'active' : ''}
                            href={router.stateService.href('auth.metrics.tags')}
                        >
                            Statistiques par tags
                        </a>
                    </>
                )}

                {canAccess() && hasRole('HAS_BLOG') && (
                    <a
                        className={currentRoute('blog') ? 'active' : ''}
                        href={router.stateService.href('auth.metrics.blog')}
                    >
                        Statistiques du blog
                    </a>
                )}
            </div>

            <div className="view scrollable">
                <UIView />
            </div>
        </div>
    );
};

const Metrics = () => {
    return (
        <div className="metrics-wrapper">
            <MetricsProvider>
                <MetricsContent />
            </MetricsProvider>
        </div>
    );
};

export default Metrics;
