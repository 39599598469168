import React, { useState } from 'react';
import useUserAppSettingService from 'api/useUserAppSettingService';
import { useAlert } from 'providers/AlertContext';
import { useTranslation } from 'react-i18next';

const Select = (props) => {
    const { postUserTextConfig } = useUserAppSettingService();
    const { showToast } = useAlert();
    const { t } = useTranslation();

    const [setting, setSetting] = useState(props.setting);

    const saveSetting = (setting, newValue) => {
        postUserTextConfig({ ...setting, value: newValue }).then(() => {
            showToast({
                text: t('SETTINGS_SAVED'),
                duration: 1500
            });
        });
    };

    return (
        <div className="input-with-label">
            <label className="label" htmlFor={`select-${props.sectionIndex}-${props.settingIndex}`}>
                {setting.label}
            </label>

            <select
                className="select"
                id={`select-${props.sectionIndex}-${props.settingIndex}`}
                value={setting.value}
                onChange={(e) => {
                    setSetting((prevSetting) => {
                        return { ...prevSetting, value: e.target.value };
                    });
                    saveSetting(setting, e.target.value);
                }}
            >
                {setting.choices.map((choice) => {
                    return <option value={choice.value}>{choice.label}</option>;
                })}
            </select>
        </div>
    );
};

export default Select;
