import React from 'react';
import useContent from 'hooks/useContent';

const Text = ({ input }) => {
    const { formatContent } = useContent();

    const createClassName = (label) => {
        return label.toLowerCase().replace(/[^a-z0-9]/gi, '_');
    };
    return (
        <>
            {input.workspace_form_input.label_render !== '' && input.text !== '' && (
                <div className="render_label inline">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}

            {input.text !== '' && (
                <div
                    className={`render_content inline ${createClassName(input.workspace_form_input.label)}`}
                    dangerouslySetInnerHTML={{ __html: formatContent(input.text) }}
                ></div>
            )}
        </>
    );
};

export default Text;
