import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Link from 'components/Link/Link';
import * as linkify from 'linkifyjs';
import { forEach, indexOf, filter, concat, last, isEmpty, isNil, pick } from 'lodash-es';
import striptags from 'striptags';
import classNames from 'classnames';
import removeAccents from 'remove-accents';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import useQuill from 'hooks/useQuill';
import { useAuth } from 'providers/AuthContext';
import useWorkspaceService from 'api/useWorkspaceService';
import useAttachment from 'hooks/useAttachment';
import { useAlert } from 'providers/AlertContext';
import useLinkService from 'api/useLinkService';
import { useConfig } from 'providers/ConfigProvider';
import getExtensionFromMimeType from 'helpers/getExtensionFromMimeType';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import useForbiddenWords from 'hooks/useForbiddenWords';
import useUploadService from 'api/useUploadService';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import LinkExtension from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { useJoinDocumentModal } from '../../pages/Auth/providers/JoinDocumentModalProvider';
import useRight from '../../hooks/useRight';

const Comment = (props) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { extractMentions, getBindings, getOnlyNewMentions } = useQuill();
    const { findUsersByCriteria } = useWorkspaceService();
    const { forgeAttachmentUrl } = useAttachment();
    const { user } = useAuth();
    const { hasRole } = useRight();
    const { showToast, showActionSheet, hideToast, showConfirm } = useAlert();
    const { getLinkInfo } = useLinkService();
    const { attachmentIcon } = useAttachmentIcon();
    const { hasMatches } = useForbiddenWords();
    const JoinDocumentModal = useJoinDocumentModal();
    const { workspacePermissionCheck } = useRight();

    const workspace = props.postItem && props.postItem.workspace;
    const workspaceId = workspace && workspace.id;
    const campaign = props.campaignItem;
    const campaignId = campaign && campaign.id;

    const [isBusy, setIsBusy] = useState(false);
    const [prevMentionsUserIds, setPrevMentionsUserIds] = useState([]);
    const [matchedAutocompleteValues, setMatchedAutocompleteValues] = useState([]);
    const [autocompleteBusy, setAutocompleteBusy] = useState(false);
    const [postNextStatusId, setPostNextStatusId] = useState(
        props.postItem && props.postItem.post_status ? props.postItem.post_status.id : null
    );
    const [comment, setComment] = useState(props.commentItem ? props.commentItem : {});
    const commentRef = useRef(comment);

    useEffect(() => {
        commentRef.current = comment;
    }, [comment]);

    const addAttachments = () => {
        JoinDocumentModal.createPanel({ multiple: true, invisible: true })
            .then((data) => {
                const attachments = concat(
                    data.files.map((item) => ({
                        custom_name: item.custom_name,
                        name: item.custom_name,
                        hard_name: item.hard_name,
                        type: item.type,
                        native: item,
                        object_url: URL.createObjectURL(item)
                    })),
                    commentRef.current.attachments && commentRef.current.attachments.length > 0
                        ? commentRef.current.attachments
                        : []
                );
                setComment((prevState) => ({
                    ...prevState,
                    attachments
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const editor = useEditor({
        extensions: [
            StarterKit,
            LinkExtension,
            Placeholder.configure({ placeholder: t('COMMENT_POST') })
        ],
        onUpdate({ editor }) {
            setComment({ ...comment, content: editor.getHTML() });
        },
        content: props.commentItem ? props.commentItem.comment : ''
    });

    const onBeforeUnloadHandler = useCallback(
        (event) => {
            if (isNil(comment.content) || isEmpty(striptags(comment.content))) {
                return;
            }

            event.preventDefault();
            event.returnValue = 'Are you sure you want to exit?';
        },
        [comment]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeUnloadHandler, { capture: true });
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnloadHandler, { capture: true });
        };
    }, [onBeforeUnloadHandler]);

    const onBeforeTransitionHandler = router.transitionService.onBefore({}, () => {
        if (isNil(comment.content) || isEmpty(striptags(comment.content))) {
            return true;
        }
        return window.confirm(t('COMMENT_ARE_YOU_SURE_QUIT_WRITING'));
    });

    useEffect(() => {
        return () => {
            onBeforeTransitionHandler();
        };
    }, []);

    useEffect(() => {
        if (props.hasFocus && editor) {
            editor.commands.focus();
        }

        /*if (props.postStatus && props.postStatus.id !== prevPostStatus.id) {
            setPostNextStatusId(props.postStatus.id);
        } else {
            if (prevPostNextStatusId === postNextStatusId) {
                if (props.postItem && props.postItem.nextStatusId && props.postItem.nextStatusId !== postNextStatusId) {
                    setPostNextStatusId(props.postItem.nextStatusId);
                }
            }
        }*/
    }, [props.hasFocus, props.postStatus, postNextStatusId, props.postItem, editor]);

    const allowAttachments = () => {
        if (!workspace) {
            return true;
        }
        return workspace && !workspace.disable_attachments_in_comments;
    };

    const userIsAllowToRead = () => {
        return workspacePermissionCheck({ id: workspaceId }, 'READ');
    };

    const userIsAllowToWrite = () => {
        return workspacePermissionCheck({ id: workspaceId }, 'WRITE');
    };

    const userIsTargeted = () => {
        if (props.activityItem || props.campaignItem) {
            return false;
        }
        const found =
            props.postItem &&
            props.postItem.targetUsers &&
            props.postItem.targetUsers.find((value) => value.id === user.id);
        return !!found;
    };

    const isEditing = () => {
        return !!comment.id;
    };

    const hasAttachment = () => {
        return comment.attachments && comment.attachments.length > 0;
    };

    const removeAttachment = (attachment) => {
        const attachments = comment.attachments.filter((item) => item !== attachment);
        setComment((prevState) => ({
            ...prevState,
            attachments
        }));
    };

    const isImage = (attachment) => {
        return attachment.type.indexOf('image') !== -1;
    };

    const hasLink = () => {
        return !!comment.link;
    };

    const removeLink = () => {
        setComment((prevState) => ({
            ...prevState,
            link: null
        }));
    };

    const isSubmittable = () => {
        if (isBusy) {
            return false;
        }
        return comment.content && comment.content.replace(/(<([^>]+)>)/gi, '');
    };

    const userCanChangePostStatus = () => {
        if (isEditing()) {
            return false;
        }
        const post = props.postItem;
        if (!post || !post.post_status) {
            return false;
        }

        return (
            (workspacePermissionCheck({ id: post.workspace.id }, 'ADMIN') ||
                (post.workspace.can_writer_change_status && post.user.id === user.id)) &&
            !post.post_status.final
        );
    };

    const postComment = () => {
        if (!isSubmittable()) {
            return;
        }

        setIsBusy(true);
        //comment.mentionUserIds = getOnlyNewMentions(extractMentions(quillRef.current.getEditor().getContents()), prevMentionsUserIds);

        const action = isEditing() ? 'edit' : 'add';

        if (props.postItem) {
            comment.post_id = props.postItem.id;
            if (props.postItem.waiting_for_review) {
                comment.isReviewInstruction = true;
            }
        } else if (props.campaignItem && props.campaignItem.id) {
            comment.campaign_id = props.campaignItem.id;
            comment.campaign_type = props.campaignItem.type;
        } else if (props.articleItem && props.articleItem.id) {
            comment.blog_article_id = props.articleItem.id;
        } else {
            comment.activity_id = props.activityItem.id;
            comment.activity_type = props.activityItem.type;
        }

        if (!comment.activity_id || !comment.blog_article_id) {
            if (hasMatches(comment.content)) {
                comment.has_forbidden_word = true;
            }
        }

        /*Comments[action](comment).then((comment) => {
            if (action === 'add' && comment.has_forbidden_word) {
                showConfirm({
                    title: t('POST_REVIEW_ALERT_TITLE'),
                    text: t('POST_REVIEW_ALERT_TEXT'),
                });
                setIsBusy(false);
                setComment({});
                return;
            }

            let textOk = t('COMMENT_ADDED');
            setComment({});

            if (action === 'add') {
                if (props.postItem) {
                    props.submitCallback(comment, postNextStatusId);
                } else if (props.campaignItem) {
                    props.submitCallback(comment);
                } else if (props.articleItem) {
                    props.submitCallback(comment, props.articleItem);
                } else {
                    props.submitCallback(comment, props.activityItem);
                }
            } else {
                textOk = t('COMMENT_UPDATED');
                props.submitCallback(comment);
            }

            setIsBusy(false);
            showToast({
                text: textOk,
                duration: 1500,
            });
        }).catch(() => {
            let textKO = t('COMMENT_ADD_FAIL');
            if (action === 'edit') {
                textKO = t('COMMENT_UPDATE_FAIL');
            }
            setIsBusy(false);
            setComment({});
            showToast({
                text: textKO,
                duration: 1500,
            });
        });*/
    };

    return (
        <div className="commentRoot" translate="no">
            {props.activityItem ||
            props.campaignItem ||
            props.articleItem ||
            userIsAllowToRead() ||
            userIsAllowToWrite() ||
            userIsTargeted() ? (
                <div className={classNames('comment', { editing: isEditing() })}>
                    <div className="textarea">
                        <div className="quill-editor minimalist">
                            <RichTextEditor editor={editor} style={{ '--mantine-scale': 1.5 }}>
                                <RichTextEditor.Content />
                                <RichTextEditor.Toolbar>
                                    <RichTextEditor.ControlsGroup>
                                        <RichTextEditor.Bold />
                                        <RichTextEditor.Italic />
                                        <RichTextEditor.Underline />
                                    </RichTextEditor.ControlsGroup>

                                    <RichTextEditor.ControlsGroup>
                                        <RichTextEditor.BulletList />
                                        <RichTextEditor.OrderedList />
                                    </RichTextEditor.ControlsGroup>

                                    <RichTextEditor.ControlsGroup>
                                        <RichTextEditor.Link />
                                        <RichTextEditor.Control onClick={addAttachments}>
                                            <i
                                                className="icon-design-file-text-image"
                                                aria-hidden="true"
                                            />
                                        </RichTextEditor.Control>
                                    </RichTextEditor.ControlsGroup>

                                    <RichTextEditor.ControlsGroup className={'space'} />

                                    {allowAttachments() && (
                                        <RichTextEditor.ControlsGroup className={'right-group'}>
                                            {userCanChangePostStatus() ? (
                                                <></>
                                            ) : /*<button className="status custom_button" style={{backgroundColor: postStatus.color}}
                                                    disabled={!props.isSubmittable}>
                                                {postStatus.label} <i className="fa fa-angle-down"/>
                                            </button>*/
                                            null}

                                            <button
                                                className="send custom_button"
                                                disabled={!isSubmittable()}
                                                onClick={() => {
                                                    postComment();
                                                }}>
                                                <i className="icon-send-email" aria-hidden="true" />
                                            </button>
                                        </RichTextEditor.ControlsGroup>
                                    )}
                                </RichTextEditor.Toolbar>
                            </RichTextEditor>
                        </div>

                        {hasAttachment() ? (
                            <div className="attachments">
                                {comment.attachments.map((attachment) => (
                                    <div className="attachment" key={attachment.hard_name}>
                                        <div
                                            className="remove"
                                            onClick={() => removeAttachment(attachment)}>
                                            +
                                        </div>
                                        {!isImage(attachment) ? (
                                            <div className="file">
                                                <div className="icon">
                                                    <i
                                                        className={classNames(
                                                            'fa',
                                                            attachmentIcon(attachment, 'class')
                                                        )}
                                                        style={{
                                                            color: attachmentIcon(
                                                                attachment,
                                                                'color'
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="name">{attachment.name}</div>
                                            </div>
                                        ) : (
                                            <div className="image">
                                                {attachment.native ? (
                                                    <img src={attachment.object_url} />
                                                ) : (
                                                    <img src={forgeAttachmentUrl(attachment)} />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        {hasLink() ? (
                            <div className="link">
                                <div className="remove" onClick={() => removeLink()}>
                                    +
                                </div>
                                <Link item={comment.link} small={true} />
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Comment;
