import { useHttp } from 'api/useHttp';

const useProductCampaignService = () => {
    const axios = useHttp();

    const getAllProductCampaign = (status, page, opportunity, showAll) => {
        return axios
            .get(
                `/dashboard/product/campaigns?status=${status}&page=${page}&opportunity=${opportunity}&showAll=${showAll}`
            )
            .then((response) => response.data);
    };

    return {
        getAllProductCampaign
    };
};

export default useProductCampaignService;
