import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import { Input } from 'antd';

const Alerts = ({ alert, clear }) => {
    const { t } = useTranslation();

    const [promptValue, setPromptValue] = useState('');
    const [promptValueEnd, setPromptValueEnd] = useState('');
    const [textAreaValues, setTextAreaValues] = useState({});

    useEffect(() => {
        if (alert) {
            if (isToast() && alert.duration !== -1) {
                setTimeout(clear, alert.duration);
            }
        }
    }, [alert]);

    const isConfirm = () => {
        return alert && alert.type === 'confirm';
    };

    const isPrompt = () => {
        return alert && alert.type === 'prompt';
    };

    const isPromptDate = () => {
        return alert && alert.type === 'prompt_date';
    };

    const isPromptPeriod = () => {
        return alert && alert.type === 'prompt_period';
    };

    const isToast = () => {
        return alert && alert.type === 'toast';
    };

    const isActionSheet = () => {
        return alert && alert.type === 'actionsheet';
    };

    const isReaction = () => {
        return alert && alert.type === 'reaction';
    };

    const isTextArea = () => {
        return alert && alert.type === 'textarea';
    };

    const buttonClicked = (callback) => {
        setPromptValue('');
        setPromptValueEnd('');
        callback();
        clear();
    };

    const buttonPromptConfirmClicked = (callback) => {
        if (isPromptPeriod()) {
            if (promptValue && promptValueEnd) {
                callback({ start: promptValue, end: promptValueEnd });
            }
        } else {
            if (promptValue) {
                callback(promptValue);
            }
        }

        buttonClicked(alert.cancel);
    };

    const buttonTextAreaConfirmClicked = (callback) => {
        callback({ inputs: alert.inputs, textAreas: textAreaValues });

        clear();
    };

    const actionButtonClicked = (button) => {
        button.callback();

        clear();
    };

    const reactionButtonClicked = (button) => {
        button.callback();

        clear();
    };

    const closeBackdrop = ($event) => {
        if (alert.type === 'prompt' && !alert.clickOutsideToDismiss) {
            return;
        }

        if ($event.target.classList.contains('alert')) {
            if (isConfirm()) {
                buttonClicked(alert.cancelBackdrop);
            }

            if (isActionSheet()) {
                actionButtonClicked(alert.cancelButton);
            }

            clear();
        }
    };

    return (
        <div
            className={`alert ${alert.type}`}
            onClick={(event) => {
                closeBackdrop(event);
            }}>
            <div className={`message ${alert.classes?.join(' ')}`}>
                <div className="body">
                    {(alert.title || alert.text || alert.textAreas) && (
                        <div className="content">
                            {alert.title && <div className="title">{alert.title}</div>}
                            {alert.text && (
                                <div
                                    className="text"
                                    dangerouslySetInnerHTML={{ __html: alert.text }}></div>
                            )}

                            {isPrompt() && (
                                <div className="input">
                                    <input
                                        type={alert.isSecret ? 'password' : 'text'}
                                        placeholder={alert.placeholder}
                                        value={promptValue}
                                        onChange={(e) => {
                                            setPromptValue(e.target.value);
                                        }}
                                        onKeyUp={(e) => {
                                            e.keyCode === 13 &&
                                                buttonPromptConfirmClicked(alert.confirm);
                                        }}
                                    />
                                </div>
                            )}

                            {isPromptDate() && (
                                <div className="input">
                                    {!alert.fullDate && (
                                        <input
                                            className="input"
                                            type="text"
                                            moment-picker="$ctrl.promptValue"
                                            locale="fr"
                                            format="DD/MM/YYYY"
                                            min-view="decade"
                                            max-view="day"
                                            start-view="year"
                                            ng-attr-placeholder="{{ $ctrl.alert.placeholder }}"
                                            ng-model="$ctrl.promptValue"
                                            ng-model-options="{ updateOn: 'blur' }"
                                            readOnly="readonly"
                                            min-date="$ctrl.alert.minDate"
                                            max-date="$ctrl.alert.maxDate"
                                        />
                                    )}

                                    {alert.fullDate && (
                                        <input
                                            className="input"
                                            type="text"
                                            moment-picker="$ctrl.promptValue"
                                            locale="fr"
                                            format="DD/MM/YYYY HH:[00]"
                                            min-view="decade"
                                            max-view="day"
                                            start-view="month"
                                            ng-model="$ctrl.promptValue"
                                            ng-model-options="{ updateOn: 'blur' }"
                                            readOnly="readonly"
                                            min-date="$ctrl.alert.minDate"
                                            max-date="$ctrl.alert.maxDate"
                                        />
                                    )}
                                </div>
                            )}

                            {isPromptPeriod() && (
                                <div className="input">
                                    <label>{alert.placeholder}</label>
                                    <input
                                        className="input"
                                        type="text"
                                        moment-picker="$ctrl.promptValue"
                                        locale="fr"
                                        format="DD/MM/YYYY"
                                        min-view="decade"
                                        max-view="day"
                                        start-view="year"
                                        ng-attr-placeholder="{{ $ctrl.alert.placeholder }}"
                                        max-date="$ctrl.promptValueEnd"
                                        ng-model="$ctrl.promptValue"
                                        ng-model-options="{ updateOn: 'blur' }"
                                        readOnly="readonly"
                                    />

                                    <label>{alert.placeholder_end}</label>
                                    <input
                                        className="input"
                                        type="text"
                                        moment-picker="$ctrl.promptValueEnd"
                                        locale="fr"
                                        format="DD/MM/YYYY"
                                        min-view="decade"
                                        max-view="day"
                                        start-view="year"
                                        ng-attr-placeholder="{{ $ctrl.alert.placeholder_end }}"
                                        min-date="$ctrl.promptValue"
                                        ng-model="$ctrl.promptValueEnd"
                                        ng-model-options="{ updateOn: 'blur' }"
                                        readOnly="readonly"
                                    />
                                </div>
                            )}

                            {isTextArea() &&
                                alert.inputs.map((input, index) => {
                                    return (
                                        <div className="input" key={`input_${index}`}>
                                            <label> {input.label} </label>
                                            <Input.TextArea
                                                type="text"
                                                placeholder={input.placeholder}
                                                onChange={(e) => {
                                                    setTextAreaValues((prevState) => {
                                                        prevState[index] = e.target.value;
                                                        return prevState;
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })}

                            {isTextArea() &&
                                alert.textAreas.map((text, index) => {
                                    return (
                                        <div className="input" key={`text_${index}`}>
                                            <label>{text.label}</label>
                                            <textarea placeholder={text.placeholder}>
                                                {text.value}
                                            </textarea>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {isActionSheet() && (
                        <div className="actions">
                            {alert.buttons.map((button, index) => {
                                return (
                                    <button
                                        type="button"
                                        className={button.classes ? classes(button.classes) : ''}
                                        onClick={() => {
                                            actionButtonClicked(button);
                                        }}
                                        key={`button_${index}`}>
                                        {button.icon && (
                                            <span
                                                className={`icon ${button.icon.type}`}
                                                style={{
                                                    backgroundColor: button.icon.color
                                                }}></span>
                                        )}
                                        {button.label}
                                    </button>
                                );
                            })}
                        </div>
                    )}

                    {isReaction() && (
                        <div className="actions">
                            <div className="emoji-picker"></div>
                        </div>
                    )}
                </div>

                {isActionSheet() && (
                    <div className="footer">
                        <button
                            type="button"
                            className={alert.cancelButton.classes.join(' ')}
                            onClick={() => {
                                actionButtonClicked(alert.cancelButton);
                            }}>
                            {alert.cancelButton.label}
                        </button>
                    </div>
                )}

                {isReaction() && (
                    <div className="footer">
                        <button
                            type="button"
                            className={alert.cancelButton.classes.join(' ')}
                            onClick={() => {
                                reactionButtonClicked(alert.cancelButton);
                            }}>
                            {alert.cancelButton.label}
                        </button>
                    </div>
                )}

                {isConfirm() && (
                    <div className="footer">
                        <button
                            type="button"
                            className={`${!alert.button ? 'bold' : ''} ${alert.cancelButtonClasses.join(' ')}`}
                            onClick={() => {
                                buttonClicked(alert.cancel);
                            }}>
                            <span className="text">{alert.cancelButtonLabel}</span>
                        </button>
                        {alert.button && (
                            <button
                                type="button"
                                className={alert.button.classes.join(' ')}
                                onClick={() => {
                                    buttonClicked(alert.confirm);
                                }}>
                                {alert.button.label}
                            </button>
                        )}
                    </div>
                )}

                {(isPrompt() || isPromptDate() || isPromptPeriod()) && (
                    <div className="footer">
                        <button
                            type="button"
                            onClick={() => {
                                buttonClicked(alert.cancel);
                            }}>
                            {t('TAG_DELETE_CONFIRM_CANCEL')}
                        </button>
                        <button
                            type="button"
                            className={alert.button.classes.join(' ')}
                            onClick={() => {
                                buttonPromptConfirmClicked(alert.confirm);
                            }}>
                            {alert.button.label}
                        </button>
                    </div>
                )}

                {isTextArea() && (
                    <div className="footer">
                        <button
                            type="button"
                            onClick={() => {
                                buttonClicked(alert.cancel);
                            }}>
                            {alert.cancelButtonLabel}
                        </button>
                        <button
                            type="button"
                            className={alert?.button?.classes?.join(' ')}
                            onClick={() => {
                                buttonTextAreaConfirmClicked(alert.confirm);
                            }}>
                            {alert.button.label}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Alerts;
