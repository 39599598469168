import React from 'react';
import useRight from 'hooks/useRight';
import { useTranslation } from 'react-i18next';
import { UIView, useRouter } from '@uirouter/react';
import Activity from 'components/Profile/components/Activity';
import Sidebar from './components/Sidebar';
import { useAuth } from 'providers/AuthContext';
import DisplayRankModal from './modals/DisplayRankModal';

const Ranking = () => {
    const { t } = useTranslation();
    const { hasTrophies, hasGamification } = useRight();
    const { user } = useAuth();
    const router = useRouter();

    return (
        <div className={'ranking_wrapper'}>
            <DisplayRankModal />
            <tags
                tags-resolver="$ctrl.tagsResolver"
                hide-list="true"
                is-light="true"
                ng-if="$ctrl.User.hasRole('SHOW_TAGS')"
            ></tags>

            <div className="profile">
                {hasGamification() && (
                    <div className="sidebar">
                        <div className={'gamification card'}>
                            <Sidebar user={user} />
                        </div>
                    </div>
                )}

                <div className="main">
                    <div className="tabs">
                        <a
                            className={`tab ${router.stateService.is('auth.ranking') ? 'active' : ''}`}
                            href={router.stateService.href('auth.ranking')}
                        >
                            {t('MY_ACTIVITY')}
                        </a>

                        {hasTrophies() && (
                            <a
                                className={`tab ${router.stateService.is('auth.ranking.badges') ? 'active' : ''}`}
                                href={router.stateService.href('auth.ranking.badges')}
                            >
                                {t('PROFILE_TABS_BADGES')}
                            </a>
                        )}
                    </div>

                    <div className="view">
                        <UIView>
                            <Activity />
                        </UIView>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ranking;
