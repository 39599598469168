import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import { useRouter } from '@uirouter/react';

import RegularConnection from './Connection/Forms/RegularConnection';
import SSOConnection from './Connection/Forms/SSOConnection';

const Connection = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { config } = useConfig();
    const connectionType = config.first_login_view || 'login';

    const isAllowToCreateAccount = () => {
        return config.allow_create_account;
    };

    return (
        <div className="connection">
            <div className={`connection-form spacing ${connectionType}`}>
                {connectionType === 'sso' && <SSOConnection />}
                {connectionType !== 'sso' && <RegularConnection />}
            </div>

            {isAllowToCreateAccount() && (
                <div className="cancel">
                    <button
                        type="button"
                        className="link"
                        onClick={() => router.stateService.go('login.registration')}
                    >
                        {t('LOGIN_CONNECTION_SUGGEST_CREATE_ACCOUNT')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Connection;
