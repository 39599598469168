import useDirectoryService from 'api/useDirectoryService';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormBody,
    ModalFormFooter,
    ModalFormSection
} from 'components/Form/ModalForm';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import formDirectoryConfigSchema from '../schemas/formDirectoryConfig.schema.json';
import { useAlert } from 'providers/AlertContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Checkbox, Input, Select } from 'components/Form/Inputs';
import useOrganizationalUnitService from '../../OrganizationalUnits/api/useOrganizationalUnitService';
import LevelsToPin from '../components/LevelsToPin';
import OrderOfAdditionalInformation from '../components/OrderOfAdditionalInformation';

const DirectoryConfigContent = () => {
    const queryClient = useQueryClient();
    const { showToast } = useAlert();
    const { getOnlyEntities } = useOrganizationalUnitService();
    const directionConfigService = useDirectoryService();
    const {
        contexts: {
            directory_config: { currentDirectoryConfig }
        },
        closeModal
    } = useModal();

    const { data: allOrganizationalUnits, isLoading: isLoadingOU } = useQuery({
        queryFn: () => getOnlyEntities(),
        queryKey: 'organizational_units'
    });

    const methods = useForm({
        schema: formDirectoryConfigSchema
    });

    const onFinish = (data) => {
        let method = currentDirectoryConfig?.id ? 'putDirectoryConfig' : 'postDirectoryConfig';
        // TODO: payload.organizationalUnits = arrayAsId(payload.organizationalUnits);

        const payload = {
            ...(method === 'putDirectoryConfig' && { id: data.id })
            // ... TODO: others data
        };

        directionConfigService[method](payload)
            .then(() => {
                showToast({
                    text: "L'annuaire a bien été créé",
                    duration: 1500
                });
                queryClient.invalidateQueries('directory_configs');
                methods.reset();
            })
            .catch((error) => {
                showToast({
                    text: "Une erreur s'est produite lors de la création de l'annuaire",
                    duration: 1500
                });
            })
            .finally(() => closeModal('directory_config'));
    };

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                {/* Name and isActive */}
                <ModalFormSection>
                    <Input name="name" label={'Nom'} />

                    <Checkbox name={'isActive'} label={"Activer l'annuaire"} />
                </ModalFormSection>

                {/* Organizational Units */}

                <ModalFormSection>
                    <Select
                        name={'organizationalUnits'}
                        label={"Restreindre l'accès aux groupes d'utilisateurs sélectionnés"}
                        options={allOrganizationalUnits?.map((ou) => ({
                            label: ou.name,
                            value: ou.id
                        }))}
                        loading={isLoadingOU}
                        mode="multiple"
                    />
                </ModalFormSection>

                {/* Pinned Levels */}

                <ModalFormSection>
                    <LevelsToPin methods={methods} />
                </ModalFormSection>

                {/* Order of additional information */}

                <ModalFormSection>
                    <OrderOfAdditionalInformation methods={methods} />
                </ModalFormSection>
            </ModalFormBody>

            <ModalFormFooter onCancel={() => closeModal('directory_config')} />
        </ModalForm>
    );
};

const DirectoryConfigModal = () => {
    const { t } = useTranslation();
    return (
        <Modal name="directory_config" title={t('ADD_DIRECTORY_CONFIG')}>
            <DirectoryConfigContent />
        </Modal>
    );
};

export default DirectoryConfigModal;
