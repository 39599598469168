import { useTranslation } from 'react-i18next';
import { useEditorContext } from '../providers/EditorProvider';
import useEditorComponent from '../hooks/useEditorComponent';

const EditorButtons = () => {
    const { t } = useTranslation();
    const { isPreview, form, isEditing, isReview } = useEditorContext();
    if (isPreview) {
        return null;
    }

    return (
        <div className="actions">
            <button className="btn preview" ng-click="$ctrl.previewPost()">
                {t('BLOG_PREVIEW')}
            </button>
            <button
                className="btn draft"
                ng-if="$ctrl.canSubmitDraft()"
                ng-disabled="!$ctrl.isSubmittable()"
                ng-click="$ctrl.submitDraft()">
                {t('EDITOR_POST_DRAFT')}
            </button>
            <button
                className="btn submit"
                ng-click="$ctrl.submit()"
                ng-disabled="!$ctrl.isSubmittable()">
                {!isEditing() ? (
                    <span>{t('EDITOR_POST_FORM')}</span>
                ) : isReview ? (
                    <span>{t('PUBLISH')}</span>
                ) : (
                    <span>{t(form?.drafted_at ? 'EDITOR_POST_FORM' : 'EDITOR_EDIT_FORM')}</span>
                )}
            </button>
        </div>
    );
};

export default EditorButtons;
