import { useHttp } from './useHttp';

const useCampaignProductService = () => {
    const axios = useHttp();

    const getProductCampaign = (id, fromCampaignDashboard = false) => {
        return axios
            .get(
                `/dashboard/products/${id}/campaign?fromCampaignDashboard=${fromCampaignDashboard}`
            )
            .then((response) => response.data);
    };

    return {
        getProductCampaign
    };
};

export default useCampaignProductService;
