import Login from '../Login';

import Connection from '../components/Connection';
import Registration from '../components/Registration';
import Reset from '../components/Reset';
import Redirect from '../components/Redirect';
import ChangePassword from '../components/ChangePassword';

export default [
    { name: 'login', url: '/', abstract: true, component: Login },
    { name: 'login.home', url: 'login', component: Connection },
    { name: 'login.registration', url: 'registration', component: Registration },
    { name: 'login.reset', url: 'reset', component: Reset },
    { name: 'login.redirect', url: 'redirect?code&state', component: Redirect },
    { name: 'login.changePassword', url: 'change-password', component: ChangePassword }
];
