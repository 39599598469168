import React from 'react';
import Modal from 'components/Modal/Modal';
import TreeModalCampaignContent from './Tree/components/TreeModalCampaignContent';
import TreeModalMenuContent from './Tree/components/TreeModalMenuContent';

const TreeModalContent = ({ page, isSidebar }) => {
    return (
        <>
            {page === 'campaigns' && <TreeModalCampaignContent />}
            {page === 'tree' && <TreeModalMenuContent isSidebar={isSidebar} />}
        </>
    );
};

const TreeModal = ({ name, page, options, title, ...props }) => {
    return (
        <div className={'tree-modal-root'}>
            <Modal name={name} title={title} options={options} {...props}>
                <div className="tree-wrapper">
                    <TreeModalContent page={page} isSidebar={!!options.isSidebar} />
                </div>
            </Modal>
        </div>
    );
};

export default TreeModal;
