const VIEWABLE_MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.ms-excel',
    'application/vnd.ms-office',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'application/msword'
];

export default function isViewableFile(type) {
    return VIEWABLE_MIME_TYPES.indexOf(type) !== -1;
}
