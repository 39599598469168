import React from 'react';
import DropdownItemNavbar from './dropdown-item-navbar/dropdown-item-navbar.component';
import ItemNavbar from './item-navbar/item-navbar.component';
import NavbarWidgetsService from './services/NavbarWidgets';
import { first } from 'lodash-es';
import useNavbarContext from './hooks/useNavbarContext';
import useGetItemAsState from './hooks/useGetItemAsState';
import ItemNavbarProvider from './providers/ItemProvider';

const AbstractItemNavbar = (props) => {
    switch (props.item.type) {
        case 'separator':
            return null;
        case 'dropdown':
            return <DropdownItemNavbar {...props} />;
        default:
            return <ItemNavbar {...props} />;
    }
};

const Navbar = ({ settings, forceMobile = false }) => {
    const NavbarWidgets = new NavbarWidgetsService();
    const context = useNavbarContext();
    const { getItemAsState, getDeviceAsClass } = useGetItemAsState(forceMobile);

    const processCustomColors = () => {
        if (!settings) {
            return;
        }
        const options = NavbarWidgets.mergeSpringboardWithDefaultConfigs(first(settings));
        const customColors = NavbarWidgets.getWidgetStyleValueForKey(
            options.navbar_config ? options.navbar_config : options,
            'customColors',
            {}
        );

        if (!customColors) {
            return;
        }

        return customColors;
    };

    const processCustomStyle = () => {
        const options = NavbarWidgets.mergeSpringboardWithDefaultConfigs(first(settings));

        const marginLeft = NavbarWidgets.getWidgetStyleValueForKey(
            options.navbar_config ? options.navbar_config : options,
            'marginLeft'
        );
        const marginRight = NavbarWidgets.getWidgetStyleValueForKey(
            options.navbar_config ? options.navbar_config : options,
            'marginRight'
        );

        let style = {
            paddingLeft: `${marginLeft}px`,
            paddingRight: `${marginRight}px`
        };

        return {
            ...style
        };
    };

    return (
        <div className={`custom-navbar ${getDeviceAsClass()}`} style={processCustomColors()}>
            <div className="items" style={processCustomStyle()}>
                {settings?.map((item, index) => {
                    const state = getItemAsState(item);

                    if (state && state.isVisible(context, state)) {
                        return (
                            <ItemNavbarProvider state={state}>
                                <div
                                    key={index}
                                    className={`item ${state.classes}`}
                                    style={state.styles}
                                >
                                    <AbstractItemNavbar
                                        item={item}
                                        state={state}
                                        context={context}
                                    />
                                </div>
                            </ItemNavbarProvider>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default Navbar;
