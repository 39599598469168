import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div className="text" style={{ width: '75%' }}></div>
            <div className="text" style={{ width: '45%' }}></div>
        </div>
    );
};
