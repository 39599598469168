import { Input, InputList, Select } from 'components/Form/Inputs';
import {
    ModalForm,
    ModalFormBody,
    ModalFormFooter,
    ModalFormSection
} from 'components/Form/ModalForm';
import { useForm } from 'components/Form/hooks';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import newProfileFormSchema from '../schemas/newProfileForm.schema.json';
import { useProfileSettingsService } from '../api/useProfileSettingsService';
import { useAlert } from 'providers/AlertContext';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';

const ProfileSettingsNewModalContent = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { showToast } = useAlert();
    const {
        closeModal,
        contexts: {
            add_profileSettings: { currentProfileSetting }
        }
    } = useModal();
    const profileSettingsService = useProfileSettingsService();
    const methods = useForm({
        schema: newProfileFormSchema,
        defaultValues: {
            order: 1,
            type: 'text'
        }
    });

    const types = [
        {
            name: 'text',
            value: t('ADMIN_PROFILE_TYPE_TEXT')
        },
        {
            name: 'long_text',
            value: t('ADMIN_PROFILE_TYPE_LONG_TEXT')
        },
        {
            name: 'select',
            value: t('ADMIN_PROFILE_TYPE_SELECT'),
            need_values: true
        },
        {
            name: 'numeric',
            value: t('ADMIN_PROFILE_TYPE_NUMERIC')
        }
    ];

    const onFinish = (data) => {
        let method = currentProfileSetting?.id ? 'putProfileSetting' : 'postProfileSetting';

        const payload = {
            ...(method === 'putProfileSetting' && { id: data.id }),
            setting: {
                values: data.values,
                field_name: data.field_name,
                order: Number(data.order),
                type: {
                    type: data.type,
                    value: types.find((type) => type.value === data.type).name
                }
            }
        };

        profileSettingsService[method](payload)
            .then(() => {
                showToast({
                    text: t('ADMIN_PROFILE_SAVED'),
                    duration: 1500
                });
                queryClient.invalidateQueries('adminLevelStats');
                methods.reset();
            })
            .catch((error) => {
                showToast({
                    text: t('ADMIN_PROFILE_FAIL'),
                    duration: 1500
                });
            })
            .finally(() => closeModal('add_profileSettings'));
    };

    const isSelectType = useMemo(() => {
        return !isEmpty(methods.getValues())
            ? methods.getValues()['type'] === 'Select'
            : currentProfileSetting?.type === 'Select';
    }, [methods.watch(), currentProfileSetting]);

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <div>
                        <Input name="field_name" label={t('ADMIN_PROFILE_FIELD_NAME_LABEL')} />
                    </div>

                    <div>
                        <Input
                            type="number"
                            name="order"
                            label={t('ADMIN_PROFILE_ORDER_LABEL')}
                            min={0}
                        />
                    </div>

                    <div>
                        <Select name="type" label={t('ADMIN_PROFILE_TYPE_LABEL')} options={types} />
                    </div>

                    {isSelectType && (
                        <div>
                            <label className="label" htmlFor={'workspace'}>
                                Valeurs
                            </label>
                            <InputList name={'values'} />
                        </div>
                    )}
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter onCancel={() => closeModal('add_profileSettings')} />
        </ModalForm>
    );
};

const ProfileSettingsNewModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name="add_profileSettings"
            title={t('ADMIN_PROFILE_TYPE_CREATE_LABEL')}
            options={{
                hasPaddedContent: true
            }}>
            <ProfileSettingsNewModalContent />
        </Modal>
    );
};

export default ProfileSettingsNewModal;
