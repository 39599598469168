import React, { createContext, useContext, useState } from 'react';
import { useModal } from '../../../providers/ModalContext';

const ShowLikerModalContext = createContext();

const ShowLikerModalProvider = ({ children }) => {
    const { openModal } = useModal();

    const [id, setId] = useState(null);
    const [type, setType] = useState(null);

    const createPanel = (params) => {
        let { id, type = null } = params;
        setId(id);
        setType(type);
        openModal('users_like');
    };

    return (
        <ShowLikerModalContext.Provider value={{ createPanel, setId, id, type, setType }}>
            {children}
        </ShowLikerModalContext.Provider>
    );
};

export const useShowLikerModal = () => useContext(ShowLikerModalContext);

export default ShowLikerModalProvider;
