import { UserAutocomplete } from 'components/Form/Inputs';

const Recipient = ({ field }) => {
    return (
        <>
            <label className="custom_text" htmlFor={field.id}>
                {field.label} {field.require && <span className="required">*</span>}
            </label>

            <UserAutocomplete
                name={`form.fields[${field.id}].value`}
                id={field.id}
                placeholder={field.label}
                mode={'multiple'}
            />

            {field.explanation && <div className="explanation">{field.explanation}</div>}
        </>
    );
};

export default Recipient;
