import SharedDocuments from '../SharedDocuments';
import SharedDocumentCategories from '../components/SharedDocumentCategories';
import SharedDocumentArticle from '../components/SharedDocumentArticle';

export default [
    {
        name: 'auth.sharedDocuments',
        url: 'shared/documents',
        component: SharedDocuments,
        navbar: {
            getIcon: () => {
                return 'icon-help-wheel';
            },
            getLabel: (context, state) => {
                return state.label;
            },
            isActive: (context) => {
                return context.isActive(['auth.sharedDocuments.**']);
            },
            isVisible: (context) => {
                return context.Right.hasRole(['HAS_RESOURCES_SPACE']);
            }
        }
    },
    { name: 'auth.sharedDocuments.root-categories', url: '', component: SharedDocumentCategories },
    {
        name: 'auth.sharedDocuments.categories',
        url: '/:categoryId?reconect',
        component: SharedDocumentCategories,
        params: {
            categoryId: {
                type: 'int'
            },
            reconect: {
                type: 'bool',
                value: false,
                squash: true,
                dynamic: true
            }
        }
    },
    {
        name: 'auth.sharedDocuments.categories.article',
        url: '/article/:articleId',
        component: SharedDocumentArticle,
        params: {
            articleId: {
                type: 'int'
            }
        }
    }
];
