import React from 'react';
import { useTranslation } from 'react-i18next';
import PostProvider, { usePost } from './providers/PostProvider';
import { useRouter } from '@uirouter/react';
import classes from 'classnames';
import { useTagContext } from '../../pages/Auth/providers/TagProvider';
import DefaultPost from './components/DefaultPost';
import StackedPost from './components/StackedPost';
import PreviewPost from './components/PreviewPost';

const PostContainer = () => {
    const router = useRouter();
    const { t } = useTranslation();
    const { hasTags } = useTagContext();
    const {
        getTemplateName,
        post,
        isPostPreview,
        isAllowToRead,
        canReviewPost,
        displayStackedSlotReview,
        relevantTag,
        forceUnconfirmedStyle,
        isNew,
        showPostId,
        isPostOutdated,
        isConsultationConfirmed
    } = usePost();

    const getRelevantTagColor = () => {
        if (relevantTag) {
            return relevantTag.color;
        }

        if (post.outdated_at) {
            return '#9d9d9c';
        }
    };

    const getClasses = () => {
        return [
            getTemplateName(),
            'is-v5',
            router.stateService.includes('auth.profile.**') || forceUnconfirmedStyle
                ? 'force-unconfirmed-style'
                : null,
            isNew() || showPostId() ? 'has-metadata' : null,
            hasTags() || isPostOutdated() ? 'has-tag' : null,
            isConsultationConfirmed() ? 'is-confirmed' : null
        ];
    };

    return (
        <div className={'post_wrapper_main'}>
            {!post.is_campaign && !post.editPost && isAllowToRead() && (
                <div
                    className={`post ${classes(getClasses())}`}
                    style={{ borderLeftColor: getRelevantTagColor() }}>
                    {getTemplateName() === 'stacked' && <StackedPost />}
                    {getTemplateName() === 'default' && <DefaultPost />}
                    {getTemplateName() === 'preview' && <PreviewPost />}
                </div>
            )}

            {!isAllowToRead() && (
                <div className="post">
                    <div className="post_wrapper">
                        <div className="reported">{t('NO_READ_ACCESS_TO_POST')}</div>
                    </div>
                </div>
            )}

            {!isPostPreview && canReviewPost() && displayStackedSlotReview() && (
                <div className="review-stacked">
                    <button
                        className="action button white"
                        ng-click="$ctrl.editPost()"
                        ng-if="$ctrl.canEditReviewPost()">
                        {t('EDITOR_EDIT_FORM')}
                    </button>
                    <button className="action button grey" ng-click="$ctrl.reviewDiscardPost()">
                        {t('POST_REVIEW_REJECT')}
                    </button>
                    <button
                        className="action button primary-color"
                        ng-click="$ctrl.reviewPublishPost()">
                        {t('POST_REVIEW_PUBLISH')}
                    </button>
                </div>
            )}
        </div>
    );
};

const Post = ({
    post,
    isPostPreview = false,
    hideForSearch = false,
    slot = null,
    forceUnconfirmedStyle = false,
    download = null,
    consultations = null,
    tagsId = [],
    alreadyLiked = false,
    template = null
}) => {
    return (
        <PostProvider
            currentPost={post}
            isPostPreview={isPostPreview}
            hideForSearch={hideForSearch}
            slot={slot}
            forceUnconfirmedStyle={forceUnconfirmedStyle}
            download={download}
            consultations={consultations}
            defaultTemplate={template}
            tagIds={tagsId}>
            <PostContainer />
        </PostProvider>
    );
};

export default Post;
