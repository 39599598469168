import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import { useTranslation } from 'react-i18next';
import { useTop } from '../../../providers/TopProvider';

const Search = ({ widget, options }) => {
    const { t } = useTranslation();
    const { title } = useTop();
    const { getWidgetStyle } = useWidgetComponent(widget, options);

    return (
        <div className="search" style={getWidgetStyle()}>
            <div className="input" ng-click="$ctrl.SearchBox.show()">
                <div className="icon">
                    <i className="icon-search" aria-hidden="true"></i>
                </div>

                <input type="search" placeholder={t('TOP_SEARCH_IN', { title })} disabled />
            </div>
        </div>
    );
};

export default Search;
