import { useTranslation } from 'react-i18next';
import useAttachment from '../../../../../../hooks/useAttachment';
import useCampaignsCustomRender from '../../hooks/useCampaignsCustomRender';
import dayjs from 'dayjs';

const Shared = ({ campaign, children }) => {
    const { forgeAttachmentPoster, forgeAttachmentUrl } = useAttachment();
    const { t } = useTranslation();
    const { getCampaignTarget, getCampaignAuthor } = useCampaignsCustomRender(campaign);
    return (
        <div className="ta_global ta_campaign">
            <div className="metadata">
                <div className="created">
                    {t('TA_CAMPAIGN_CREATED_AT')}{' '}
                    {new dayjs(campaign.created_at).format('DD/MM/YYYY')}
                </div>

                {campaign.startDate && campaign.endDate && (
                    <div className="campaign-dates">
                        {campaign.startDate && (
                            <span>
                                {t('TA_CAMPAIGN_STARTING_AT')}{' '}
                                {new dayjs(campaign.startDate).format('DD/MM/YYYY HH:mm')}
                            </span>
                        )}
                        <br />
                        {campaign.endDate && (
                            <span>
                                {t('TA_CAMPAIGN_ENDING_AT')}{' '}
                                {new dayjs(campaign.endDate).format('DD/MM/YYYY HH:mm')}
                            </span>
                        )}
                    </div>
                )}
                <div className="id">
                    {t('TA_CAMPAIGN_ID')} {campaign.id}
                </div>
            </div>

            <div className="block">
                <div>
                    <div className="description">
                        <div className="title">{campaign.title}</div>

                        <div className="image">
                            {!campaign.image && campaign.link && (
                                <img src={campaign.link.picture_url} alt="" />
                            )}
                            {!campaign?.image?.poster && (!campaign.link || campaign.image) && (
                                <img src={forgeAttachmentUrl(campaign.image)} alt="" />
                            )}
                            {campaign?.image?.poster && (
                                <img src={forgeAttachmentPoster(campaign.image)} alt="" />
                            )}
                        </div>

                        {campaign.target && (
                            <div className="target">
                                {t('TA_CREATE_CAMPAIGN_TARGET_TITLE')} : {getCampaignTarget()}
                            </div>
                        )}
                        {campaign.owner && (
                            <div className="target">
                                {t('TA_CREATE_CAMPAIGN_AUTHOR_TITLE')} : {getCampaignAuthor()}
                            </div>
                        )}
                    </div>

                    <div className="layout-align-stretch-stretch layout-row flex-gt-xs-auto informations-wrapper">
                        {children}
                    </div>
                </div>

                {/* <ng-include src="$ctrl.campaignPartial()"></ng-include> */}
            </div>
        </div>
    );
};

export default Shared;
