import { useQuery } from '@tanstack/react-query';
import { Select } from 'components/Form/Inputs';
import useSharedDocumentCategoryService from 'api/useSharedDocumentCategoryService';

const ShareDocumentCategories = ({ field, fieldName, ...props }) => {
    const { getAllSharedCategories } = useSharedDocumentCategoryService();
    const { data: categories } = useQuery({
        queryKey: ['allSharedCategories'],
        queryFn: () => getAllSharedCategories()
    });
    return (
        <Select
            shouldUnregister={false}
            mode={'multiple'}
            name={fieldName}
            options={
                categories
                    ? categories.map((category) => {
                          return {
                              label: category.name,
                              value: category.id
                          };
                      })
                    : []
            }
            {...props}
        />
    );
};

export default ShareDocumentCategories;
