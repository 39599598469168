import { useHttp } from 'api/useHttp';
import { useEffect, useState } from 'react';

const useRssService = (widgetId, refresh) => {
    const axios = useHttp();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState(null);
    const [title, setTitle] = useState(null);
    const [link, setLink] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`/rss/parse/${widgetId}`).then(({ data }) => {
            let { items = [], title = null, link = null } = data;
            setItems(items);
            setTitle(title);
            setLink(link);
            setIsLoading(false);
        });
    }, [widgetId, refresh]);

    return {
        isLoading,
        items,
        title,
        link
    };
};

export default useRssService;
