import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import RealTimeDate from 'components/RealTimeDate/RealTimeDate';

const Product = ({ activity }) => {
    const { t } = useTranslation();
    const { getAvatar } = useUser();
    return (
        <>
            <div className="badge">
                <i className="fa fa-cube" aria-hidden="true"></i>
            </div>

            <div className="content">
                <div className="campaign">{activity.campaign.title}</div>
                <div className="text">
                    {t('TA_ACTIVITIES_PRODUCT_TITLE', {
                        firstname: t(activity.submit.user.first_name),
                        name: t(activity.submit.user.last_name)
                    })}
                </div>
            </div>

            <div className="meta">
                <div className="date">
                    <RealTimeDate date={activity.submit.row.created_at} isLight={true} />
                </div>

                <div className="avatar">
                    <img src={getAvatar(activity.submit.row.user)} />
                </div>
            </div>
        </>
    );
};

export default Product;
