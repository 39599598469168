import Metrics from '../Metrics';
import Global from '../components/Global';
import Workspaces from '../components/Workspaces';

const navbarData = {
    getIcon: () => {
        return 'icon-graph-stats-circle';
    },
    getLabel: (context) => {
        return context.t('METRICS');
    },
    isActive: (context) => {
        return context.isActive(['auth.metrics.**']);
    },
    isVisible: (context) => {
        return (
            context.Right.hasRole(['ROLE_ADMIN', 'HAS_METRICS']) ||
            context.Right.hasRole(['HAS_METRICS', 'ROLE_METRICS'])
        );
    }
};

export default [
    { name: 'auth.metrics', url: 'metrics', abstract: true, component: Metrics },
    {
        name: 'auth.metrics.global',
        url: '?period&startDate&endDate',
        component: Global,
        navbar: navbarData,
        params: {
            period: {
                type: 'string',
                value: 'last_month'
            }
        }
    },
    {
        name: 'auth.metrics.ws',
        url: '/ws?period&startDate&endDate',
        component: Workspaces,
        navbar: navbarData,
        params: {
            period: {
                type: 'string',
                value: 'last_month'
            }
        }
    },
    { name: 'auth.metrics.blog', url: '/blog', component: Workspaces, navbar: navbarData },
    { name: 'auth.metrics.tags', url: '/tags', component: Workspaces, navbar: navbarData },
    { name: 'auth.metrics.levels', url: '/levels?page', component: Workspaces, navbar: navbarData },
    { name: 'auth.metrics.ou', url: '/ou?id', component: Workspaces, navbar: navbarData }
];
