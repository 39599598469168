import React from 'react';
import ReactDOM from 'react-dom/client';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import './global.scss';
import './styles/apps/tousnexenture.scss';
import 'plyr/dist/plyr.css';
import 'font-awesome/css/font-awesome.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const appName = process.env.REACT_APP_NAME;
/*
if (appName) {
    import(`./styles/apps/${appName}.scss`)
        .then(() => {
            console.log(`${appName} styles loaded`);
        })
        .catch((err) => {
            console.error(`Failed to load ${appName} styles`, err);
        });
} else {
    console.warn('No app name specified in environment variables.');
}*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
