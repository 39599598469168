import { useHttp } from './useHttp';

const useCampaignMissionService = () => {
    const axios = useHttp();

    const getMissionCampaign = (id) => {
        return axios.get(`/dashboard/missions/${id}/campaign`).then((response) => response.data);
    };

    return {
        getMissionCampaign
    };
};

export default useCampaignMissionService;
