import React from 'react';
import { useQuery } from '@tanstack/react-query';
import useBrandService from './api/useBrandService';
import useAttachment from 'hooks/useAttachment';
import AdminHeader from 'components/Admin/components/AdminHeader';
import Table from 'components/Table';
import { useModal } from 'providers/ModalContext';
import BrandFormModal from './modals/BrandFormModal';

const Brands = () => {
    const { getBrands } = useBrandService();
    const { forgeAttachmentUrl } = useAttachment();
    const { openModal } = useModal();

    const { data: brands, isLoading } = useQuery({
        queryFn: () => getBrands(),
        queryKey: ['brands']
    });

    const COLUMNS = [
        {
            id: 'name',
            header: 'Nom',
            sortable: true
        },
        {
            id: 'id',
            header: 'Logo',
            cell: ({ row }) => {
                const brand = row.original;
                return <img src={forgeAttachmentUrl(brand.logo)} width="100" alt={brand.name} />;
            }
        }
    ];

    return (
        <>
            <BrandFormModal />
            <AdminHeader
                title={'ADMIN_MENU_BRANDS_TITLE_LABEL'}
                modalName={'add_edit_brand'}
                modalButtonTitle={'ADMIN_MENU_BRANDS_ADD_BRAND_LABEL'}
            />

            <Table
                columns={COLUMNS}
                data={brands}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'Editer',
                        handler: (brand) => {
                            openModal('add_edit_brand', {
                                currentBrand: brand
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        danger: true,
                        handler: (row) => {
                            console.log(row);
                        }
                    }
                ]}
            />
        </>
    );
};

export default Brands;
