import React from 'react';
import { useAuth } from 'providers/AuthContext';
import useRight from 'hooks/useRight';

const Username = ({ user, light }) => {
    const { user: me } = useAuth();
    const { hasRole } = useRight();

    const getUserInformation = () => {
        if (!hasRole('ROLE_INTERNAL')) {
            return;
        }

        let { show: userInformation = '' } = me.user_informations;

        switch (userInformation) {
            case '':
                return;
            case 'company':
                return user.company;
            case 'job':
                return user.job;
            case 'phone':
                return user.phone_number;
            case 'email':
                return user.email;
            default:
                if (
                    userInformation.indexOf('level') < 0 ||
                    !user.employee ||
                    !user.employee.levels
                ) {
                    return;
                }

                let levelIndex = userInformation.split('_').pop();

                let foundLevel = user.employee.levels.find((level) => {
                    return level.index === parseInt(levelIndex);
                });

                if (!foundLevel) {
                    return;
                }

                return foundLevel.name;
        }
    };

    if (!user) {
        return null;
    }

    return (
        <div className="usernameRoot">
            <div className="username">
                {user.first_name ? <span className="firstname">{user.first_name}</span> : null}
                &nbsp;
                {user.last_name && hasRole('ROLE_INTERNAL') ? (
                    <span className="lastname">{user.last_name}</span>
                ) : (
                    <span className="lastname">{user.last_name && user.last_name.charAt(0)}.</span>
                )}
                {me && me.user_informations && getUserInformation() && !light ? (
                    <span className="description">&nbsp;({getUserInformation()})</span>
                ) : null}
            </div>
        </div>
    );
};

export default Username;
