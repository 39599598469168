import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div className="row">
                <div
                    className="icon"
                    style={{ width: '45px', height: '45px', alignSelf: 'flex-start' }}
                ></div>

                <div className="column">
                    <div className="text" style={{ width: '75%' }}></div>
                    <div className="text" style={{ width: '55%' }}></div>
                </div>
            </div>
        </div>
    );
};
