import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import { useDevice } from 'providers/DeviceContext';
import Hashids from 'hashids';
import { Browser } from '@capacitor/browser';

const SSO = () => {
    const [userHasAcceptedConnectionSSOTermsOfUse, setUserHasAcceptedConnectionSSOTermsOfUse] =
        useState();
    const { t } = useTranslation();
    const { config } = useConfig();
    const { device } = useDevice();

    const isTermsOfUseApprovalNeeded = () => {
        return !!config.ASK_TERMS_ON_LOGIN;
    };

    const openSSOLoginPage = () => {
        const ssoState = new Hashids().encode(new Date().getTime());

        if (device.isApp) {
            const ssoMobileLoginPage = config.sso_mobile_login_page.replace('$state', ssoState);

            if (config.ssoNotInApp && device.platform !== 'ios') {
                window.open(ssoMobileLoginPage, '_blank');
            } else {
                Browser.open({
                    url: ssoMobileLoginPage
                });
            }
        } else {
            const ssoLoginPage = config.sso_login_page
                .replace('$state', ssoState)
                .replace('https://mlc.nexenture.fr', 'http://localhost:3000');

            window.open(ssoLoginPage, '_self');
        }
    };

    return (
        <div className="form">
            {isTermsOfUseApprovalNeeded() && (
                <div className="fields" style={{ marginBottom: 20 }}>
                    <div className="field">
                        <div className="input-with-label">
                            <input
                                className="switch"
                                type="checkbox"
                                id="sso-terms-of-use"
                                checked={userHasAcceptedConnectionSSOTermsOfUse}
                                onChange={(e) => {
                                    setUserHasAcceptedConnectionSSOTermsOfUse(e.target.checked);
                                }}
                            />

                            <label className="label" htmlFor="sso-terms-of-use">
                                {t('LOGIN_AGREE')} (
                                <a className="link" target="_blank" href={config.terms_url}>
                                    {t('LOGIN_READ_TERMS')}
                                </a>
                                )
                            </label>
                        </div>
                    </div>
                </div>
            )}

            <div className="actions sso">
                <button
                    className="btn hl"
                    disabled={
                        !userHasAcceptedConnectionSSOTermsOfUse && isTermsOfUseApprovalNeeded()
                    }
                    onClick={openSSOLoginPage}
                >
                    {t('LOGIN_WITH_SSO')}{' '}
                </button>
            </div>

            {t('LOGIN_WITH_SSO_TIPS') !== 'LOGIN_WITH_SSO_TIPS' && (
                <div className="fields" style="margin-top: -20px;">
                    <div className="field">
                        <div className="tips">{t('LOGIN_WITH_SSO_TIPS')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SSO;
