import AdminHeader from '../../../../components/Admin/components/AdminHeader';
import Table from 'components/Table';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProfileSettingsService } from './api/useProfileSettingsService';
import ProfileSettingsNewModal from './modals/ProfileSettingsNewModal';
import { useModal } from 'providers/ModalContext';
import { TYPES } from './shareds';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'providers/AlertContext';

const ProfileSettings = () => {
    const { getAllSettings, deleteProfileSetting } = useProfileSettingsService();
    const { showConfirm, showToast } = useAlert();
    const { openModal } = useModal();
    const { t } = useTranslation();

    const COLUMNS = [
        {
            id: 'id',
            header: 'Id',
            sortable: true
        },
        {
            id: 'field_name',
            header: 'Nom du champ',
            sortable: true
        },
        {
            id: 'order',
            header: 'Ordre',
            sortable: true,
            center: true
        },
        {
            id: 'type',
            header: 'Type du champ',
            sortable: true,
            cell: ({ cell, row }) => {
                const selects = row.original.values.filter((value) => value && value !== '');
                const selectValues = selects.length > 0 ? `(${selects.join(', ')})` : '';

                return (
                    <div>
                        {t(TYPES.find((type) => type.name === cell.getValue()).value)}{' '}
                        {cell.getValue() === 'select' && <span>{selectValues}</span>}
                    </div>
                );
            },
            center: true
        }
    ];

    const {
        data: profileSettings,
        isLoading,
        refetch
    } = useQuery({
        queryKey: ['admin_profile_settings'],
        queryFn: () => getAllSettings()
    });

    const handleDelete = (profileId) => {
        showConfirm({
            title: t('ADMIN_PROFILE_DELETE_TITLE'),
            text: t('ADMIN_PROFILE_DELETE_TEXT'),
            button: {
                label: t('DELETE'),
                classes: ['red', 'bold']
            }
        }).then(() => {
            deleteProfileSetting(profileId)
                .then(() => {
                    showToast({
                        text: t('ADMIN_PROFILE_DELETE_SUCCEEDED_TEXT'),
                        duration: 1500
                    });

                    refetch();
                })
                .catch(() => {
                    showToast({
                        text: t('ADMIN_PROFILE_FAIL'),
                        duration: 1500
                    });
                });
        });
    };

    return (
        <>
            <ProfileSettingsNewModal />

            <AdminHeader
                title={'Gestion des champs du profil'}
                modalName={'add_profileSettings'}
                modalButtonTitle={'Ajouter un champ'}
            />

            <Table
                columns={COLUMNS}
                data={profileSettings}
                isLoading={isLoading}
                handleActions={[
                    {
                        label: 'Éditer',
                        handler: (row) => {
                            openModal('add_profileSettings', { currentProfileSetting: row });
                        }
                    },
                    {
                        label: 'Supprimer',
                        handler: ({ id }) => handleDelete(id)
                    }
                ]}
            />
        </>
    );
};
export default ProfileSettings;
