import { useComposer } from '../../../pages/Auth/providers/ComposerContext';
import useUtils from '../../../hooks/useUtils';
import useRight from '../../../hooks/useRight';
import useWorkspace from '../../../hooks/useWorkspace';

const Workspace = () => {
    const { slot, category, setWorkspace, setCurrentStepIndex } = useComposer();

    const { getFirstLetter } = useUtils();
    const { hasRole } = useRight();
    const { forgeIconUrl } = useWorkspace();

    return (
        <div className="step workspace">
            {!slot ? (
                <div className="header">
                    <div className="category">
                        <div className="icon">
                            <div className="letter">{getFirstLetter(category.name)}</div>
                        </div>

                        <div className="name">{category.name}</div>
                    </div>
                </div>
            ) : (
                <div className="header">
                    <div className="category">
                        <div className="icon" style={{ backgroundColor: slot.track.color }}>
                            <div className="letter">{getFirstLetter(slot.track.name)}</div>
                        </div>

                        <div className="name">{slot.track.name}</div>
                    </div>
                </div>
            )}

            <div className="workspaces">
                {category &&
                    category.workspaces.map(({ workspace }, index) => {
                        return (
                            <div
                                className="workspace"
                                onClick={() => {
                                    setWorkspace(workspace);
                                    setCurrentStepIndex(3);
                                }}
                                key={`composer_workspace${index}`}>
                                <div className="meta">
                                    {hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: workspace.icon_color }}>
                                            <div className="letter">
                                                {getFirstLetter(workspace.name)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="icon">
                                            <img src={forgeIconUrl(workspace.icon_name)} />
                                        </div>
                                    )}

                                    <div className="name">{workspace.name}</div>

                                    <div className="arrow">
                                        <i className="icon-arrow-left" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Workspace;
