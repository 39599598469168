import { useTranslation } from 'react-i18next';
import { usePost } from '../providers/PostProvider';
import PostRecipient from './Partials/PostRecipient';
import PostDescription from './Partials/PostDescription';
import PostContent from './Partials/PostContent';
import PostReport from './Partials/PostReport';
import PostInfo from './Partials/PostInfo';
import PostActions from './Partials/PostActions';
import PostReview from './Partials/PostReview';
import PostComments from './Partials/PostComments';
import PostComment from './Partials/PostComment';
import PostHeader from './Partials/PostHeader';

const DefaultPost = () => {
    const { t } = useTranslation();
    const { post, showPostId, isNew, canComment } = usePost();

    return (
        <>
            <div className="metadata">
                {isNew() && <div className="post__new">{t('NEW')}</div>}
                {showPostId() && (
                    <div className="post__number">{t('POST_ID_SHOW', { id: post.id })}</div>
                )}
            </div>

            <div className="post_wrapper">
                <div>
                    <PostHeader />
                </div>
                <div>
                    <PostRecipient />
                </div>
                <div>
                    <PostDescription />
                </div>
                <div>
                    <PostContent />
                </div>
                <div>
                    <PostReport />
                </div>

                {/* { hasFeedback() && !hideAll && <div className={`feedback_wrapper ${feedbackIsDone() ? 'done' : null}`}>
                    <PostFeedback />
                </div> }*/}

                <div>
                    <PostInfo />
                </div>
                <div>
                    <PostActions />
                </div>
                <div>
                    <PostReview />
                </div>

                {/*

                { forceDefaultView && <div className="post__collapse">
                    <div className="actions">
                        <div className="action" onClick={() => {
                            setForceDefaultView(false)
                        }}>
                            <i className="icon-move-top-left" aria-hidden="true"></i>
                        </div>
                    </div>
                </div> }*/}
            </div>

            <div className="comment_wrapper">
                <div>
                    <PostComments />
                </div>

                {canComment() && (
                    <div>
                        <PostComment />
                    </div>
                )}
            </div>
        </>
    );
};

export default DefaultPost;
