import React, { useEffect, useState } from 'react';
import VideoPlayerComponent from 'components/VideoPlayer/VideoPlayer';
import UsernameComponent from 'components/Username/Username';
import useDate from 'hooks/useDate';
import PhotoAlbum from 'react-photo-album';
import {
    isAudioAttachment,
    isVideoAttachment,
    getAllWithoutImagesVideosAndAudios,
    isAllImagesAttachment,
    getAllImages
} from 'helpers/attachment';
import useAttachment from 'hooks/useAttachment';
import { useTranslation } from 'react-i18next';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import { isViewableFile } from 'helpers/attachment';
import useRight from 'hooks/useRight';
import { useModal } from '../../../../providers/ModalContext';

const Files = ({
    input,
    post,
    isPostPreview = false,
    inCarousel = false,
    alreadyLiked = false,
    download = 0,
    tags = [],
    tagId = null
}) => {
    const {
        forgeAttachmentUrl,
        forgeTmpAttachmentUrl,
        download: downloadHandler,
        forgeAllAttachmentsUrl,
        getVideoFiles,
        getVideoState,
        getVideoType
    } = useAttachment();
    const { t } = useTranslation();
    const { attachmentIcon } = useAttachmentIcon();
    const { hasRole } = useRight();
    const { format } = useDate();
    const { openModal } = useModal();

    const [images, setImages] = useState([]);

    useEffect(() => {
        const imageUrls = getAllImages(getInputAttachments(input));
        if (!imageUrls) {
            return;
        }
        imageUrls.forEach((image) => {
            const url = getAttachmentUrl(image);
            const img = new Image();
            img.onload = () => {
                setImages((images) => [
                    ...images,
                    { src: url, width: img.width, height: img.height, isSelected: false }
                ]);
            };
            img.src = url;
        });
    }, [input]);

    const getInputAttachments = (input) => {
        if (input.workspace_form_input.type === 'files' && input.attachments) {
            return input.attachments;
        }

        if (input.workspace_form_input.type === 'file' && input.attachment) {
            return [input.attachment];
        }

        return [];
    };

    const getAllVideosAndAudios = (attachments) => {
        let videosAndAudios = [];

        attachments.forEach((attachment) => {
            if (isVideoAttachment(attachment) || isAudioAttachment(attachment)) {
                videosAndAudios.push(attachment);
            }
        });

        return videosAndAudios;
    };

    const getAttachmentUrl = (attachment, forceToken = false, before = false) => {
        if (isPostPreview) {
            return forgeTmpAttachmentUrl(attachment);
        }

        return forgeAttachmentUrl(attachment, false, null, forceToken, before);
    };

    const isSecurityLevelReadonly = () => {
        return post.security_level && post.security_level === 3;
    };

    const downloadAllAttachments = (postId, inputId = null) => {
        if (isSecurityLevelReadonly() || post.workspace.disable_dl_all_attachments) {
            return;
        }

        downloadHandler(forgeAllAttachmentsUrl(postId, inputId), post.security_level);
    };

    const downloadAttachment = (attachment, forceToken = false, before = false) => {
        if (isSecurityLevelReadonly()) {
            /*SecureDocument.show({ document: attachment });*/
        } else {
            downloadHandler(
                getAttachmentUrl(attachment, forceToken, before),
                post.security_level,
                attachment
            );
        }
    };

    const allowViewFile = (type) => {
        return post.security_level === 0 && isViewableFile(type);
    };

    const showCarousel = (attachments, index) => {
        if (isPostPreview) {
            return;
        }

        openModal('carousel', {
            images: attachments,
            index,
            post: { ...post, alreadyLiked, tag_id: tagId, tags, download }
        });
    };

    return (
        <div className="render_files">
            {input.workspace_form_input.label_render !== '' && (
                <div className="render_label">{input.workspace_form_input.label_render} :</div>
            )}
            <div className="render_files_content">
                <div className="attachments">
                    {getAllVideosAndAudios(getInputAttachments(input)).length > 0 && (
                        <div className="files">
                            {getAllVideosAndAudios(getInputAttachments(input)).map(
                                (attachment, index) => {
                                    if (isVideoAttachment(attachment)) {
                                        return (
                                            <div className="is-video" key={index}>
                                                <div className="video">
                                                    <VideoPlayerComponent
                                                        src={getAttachmentUrl(attachment)}
                                                        type={getVideoType(attachment.type)}
                                                        poster={attachment}
                                                        files={getVideoFiles(attachment)}
                                                        state={getVideoState(attachment)}
                                                        readOnly={attachment.read_only}
                                                        securityLevel={
                                                            post.security_level
                                                        }></VideoPlayerComponent>
                                                </div>
                                            </div>
                                        );
                                    }
                                    if (isAudioAttachment(attachment)) {
                                        return (
                                            <div className="video-player-container">
                                                <VideoPlayerComponent
                                                    src={getAttachmentUrl(attachment)}
                                                    type={attachment.type}
                                                    isAudio={true}
                                                    readOnly={attachment.read_only}
                                                    securityLevel={
                                                        post.security_level
                                                    }></VideoPlayerComponent>
                                            </div>
                                        );
                                    }
                                }
                            )}
                        </div>
                    )}

                    {getAllImages(getInputAttachments(input)) &&
                        images.length > 1 &&
                        input.workspace_form_input.photo_grid &&
                        !inCarousel && (
                            <div className="images">
                                <PhotoAlbum
                                    photos={images}
                                    layout={'rows'}
                                    spacing={2}
                                    onClick={({ index }) => {
                                        console.log('index', index);
                                        showCarousel(getInputAttachments(input), index);
                                    }}
                                />

                                {!isSecurityLevelReadonly() &&
                                    !post.workspace.disable_dl_all_attachments && (
                                        <div className="download">
                                            {post.attachments.length > 1 && (
                                                <button
                                                    onClick={() => {
                                                        downloadAllAttachments(post.id, input.id);
                                                    }}>
                                                    {t('POST_DOWNLOAD')}
                                                </button>
                                            )}

                                            {post.attachments.length === 1 && (
                                                <button
                                                    onClick={() => {
                                                        downloadAttachment(post.attachments[0]);
                                                    }}>
                                                    {t('POST_DOWNLOAD')}
                                                </button>
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}

                    {getAllImages(getInputAttachments(input)).length > 0 &&
                        (!input.workspace_form_input.photo_grid ||
                            getAllImages(getInputAttachments(input)).length === 1) &&
                        !inCarousel && (
                            <div className="images">
                                {getAllImages(getInputAttachments(input)).map(
                                    (attachment, index) => {
                                        return (
                                            <div className="attachment" key={index}>
                                                <div className="image">
                                                    <img
                                                        onClick={() => {
                                                            showCarousel(
                                                                getInputAttachments(input),
                                                                index
                                                            );
                                                        }}
                                                        src={getAttachmentUrl(attachment)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}

                    {!isAllImagesAttachment(getInputAttachments(input)) &&
                        getAllWithoutImagesVideosAndAudios(getInputAttachments(input)).length >
                            0 && (
                            <div className="files" style={{ marginTop: 10 }}>
                                {getAllWithoutImagesVideosAndAudios(getInputAttachments(input)).map(
                                    (attachment, index) => {
                                        return (
                                            <div className="file" key={index}>
                                                <div className="is-mixed">
                                                    <div className="mixed">
                                                        <div className="icon">
                                                            <i
                                                                className={attachmentIcon(
                                                                    attachment,
                                                                    'class'
                                                                )}
                                                                style={{
                                                                    color: attachmentIcon(
                                                                        attachment,
                                                                        'color'
                                                                    )
                                                                }}></i>
                                                        </div>

                                                        <div className="meta">
                                                            <button
                                                                className="filename"
                                                                onClick={() => {
                                                                    downloadAttachment(attachment);
                                                                }}>
                                                                {attachment.name}
                                                            </button>

                                                            <div className="owner">
                                                                <UsernameComponent
                                                                    user={
                                                                        post.user
                                                                    }></UsernameComponent>
                                                            </div>

                                                            <div className="date">
                                                                {format(attachment.created_at)}
                                                            </div>
                                                        </div>

                                                        <div className="actions">
                                                            {allowViewFile(attachment.type) &&
                                                                hasRole('SHOW_VIEWER') && (
                                                                    <button
                                                                        type="button"
                                                                        className="action"
                                                                        onClick={() => {
                                                                            //PreviewDocument.show({ url: getAttachmentUrl(attachment, true) })
                                                                        }}>
                                                                        <i
                                                                            className="icon-search"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                )}

                                                            <button
                                                                type="button"
                                                                className="action"
                                                                onClick={() => {
                                                                    downloadAttachment(attachment);
                                                                }}>
                                                                <i
                                                                    className="icon-download-bottom"
                                                                    aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Files;
