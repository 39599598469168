import React, { createContext, useContext } from 'react';
import useOrganizationalUnitService from '../components/OrganizationalUnits/api/useOrganizationalUnitService';
import { useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash-es';

const AdminOrganizationalUnitContext = createContext();

const AdminOrganizationalUnitProvider = ({ children }) => {
    const { getAll } = useOrganizationalUnitService();

    const { data: allOrganizationalUnits, isLoading } = useQuery({
        queryKey: ['allOrganizationalUnits'],
        queryFn: getAll
    });

    return (
        <AdminOrganizationalUnitContext.Provider
            value={{
                allOrganizationalUnits: allOrganizationalUnits
                    ? orderBy(allOrganizationalUnits, ['name'])
                    : null,
                isLoading
            }}>
            {children}
        </AdminOrganizationalUnitContext.Provider>
    );
};

export const useAdminOrganizationalUnitContext = () => useContext(AdminOrganizationalUnitContext);
export default AdminOrganizationalUnitProvider;
