import { first } from 'lodash-es';

const useEditorComponent = (workspace) => {
    const workspaceHasCustomForm = () => {
        return workspace && workspace.workspace_form;
    };

    const hasPages = () => {
        if (!workspaceHasCustomForm()) {
            return false;
        }

        let field = first(workspace.workspace_form.workspace_form_inputs);

        return field && field.type === 'page';
    };

    const getPages = () => {
        if (!hasPages()) {
            return [
                {
                    label: 'Default page'
                }
            ];
        }

        return this.workspace.workspace_form.workspace_form_inputs.filter((input) => {
            return input.type === 'page';
        });
    };

    const isSubscriptionLess = () => {
        return workspace && workspace.subscription_less;
    };

    const hasCustomFieldWithRecipient = () => {
        if (!workspaceHasCustomForm()) {
            return false;
        }

        return (
            workspace.workspace_form.workspace_form_inputs.filter((item) => {
                return item.type === 'recipients';
            }).length > 0
        );
    };

    const getInput = (id) => {
        return workspace.workspace_form.workspace_form_inputs.find((input) => {
            return input.id === id;
        });
    };

    return {
        workspaceHasCustomForm,
        hasPages,
        isSubscriptionLess,
        hasCustomFieldWithRecipient,
        getPages,
        getInput
    };
};

export default useEditorComponent;
