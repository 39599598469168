import React from 'react';
import classNames from 'classnames';
import { range } from 'lodash-es';

import Workspace from './types/Workspace';
import Tree from './types/Tree';
import Post from './types/Post';
import Text from './types/Text';
import Paragraph from './types/Paragraph';
import Channel from './types/Channel';
import Message from './types/Message';
import Activity from './types/Activity';
import Notification from './types/Notification';
import Rank from './types/Rank';

const Placeholder = ({ number = 1, template }) => {
    const templates = {
        workspace: Workspace,
        tree: Tree,
        activity: Activity,
        channel: Channel,
        message: Message,
        paragraph: Paragraph,
        post: Post,
        text: Text,
        notification: Notification,
        rank: Rank
    };

    const getTemplateComponent = () => {
        return templates[template];
    };

    const getClassForTemplate = () => {
        return `is-${template}`;
    };

    const PlaceholderTemplate = getTemplateComponent();

    return (
        <div className="placeholderRoot">
            {range(number).map((value, index) => (
                <div key={index} className={classNames('placeholder', getClassForTemplate())}>
                    <PlaceholderTemplate odd={index % 2} />
                </div>
            ))}
        </div>
    );
};

export default Placeholder;
