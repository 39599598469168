import React from 'react';

import Providers from './providers/providers';
import Routes from './pages/routes';

import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';

function App() {
    const queryClient = new QueryClient();

    return (
        <MantineProvider>
            <QueryClientProvider client={queryClient}>
                <Providers>
                    <Routes />
                </Providers>
            </QueryClientProvider>
        </MantineProvider>
    );
}

export default App;
