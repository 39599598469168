import React, { useState } from 'react';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import { useAlert } from 'providers/AlertContext';
import useImportEmployeeService from '../api/useImportEmployeeService';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAdminLevelContext } from '../../../providers/AdminLevelProvider';
import { useModal } from '../../../../../providers/ModalContext';
import { useJoinDocumentModal } from '../../../../Auth/providers/JoinDocumentModalProvider';

const ImportEmployeeConfig = ({ config }) => {
    const { t } = useTranslation();
    const { hasRole } = useRight();
    const { showConfirm, showToast, hideToast } = useAlert();
    const { getEmployeesByType, importEmployee } = useImportEmployeeService();
    const { getLevelName } = useAdminLevelContext();
    const { openModal } = useModal();
    const JoinDocument = useJoinDocumentModal();

    const [importEmployeeConfig, setImportEmployeeConfig] = useSetState(config);
    const [page, setPage] = useState(0);

    const { data: employeesList, isLoading } = useQuery({
        queryKey: [`employeesList_${config.id}_${page}`, 'employeesLists'],
        queryFn: () => {
            if (page === 0) {
                return [];
            } else {
                return getEmployeesByType(importEmployeeConfig, page);
            }
        }
    });

    const importFile = () => {
        JoinDocument.createPanel({
            invisible: true,
            confirmDialog: true,
            confirmDialogTitle: "Êtes vous sur de vouloir lancer l'import ?"
        })
            .then((data) => {
                importEmployee(data.files[0], true, config, importEmployeeConfig)
                    .then((result) => {
                        hideToast();
                        showConfirm({
                            title: t('ADMIN_USERS_IMPORT_COUNT_TITLE'),
                            text:
                                t('ADMIN_USERS_IMPORT_ENABLE_EMPLOYEES_COUNT', {
                                    count: result.enabledEmployees
                                }) +
                                '<br/>' +
                                t('ADMIN_USERS_IMPORT_DISABLE_EMPLOYEES_COUNT', {
                                    count: result.disabledEmployees
                                }),
                            button: {
                                label: t('SEARCH_CONFIRM'),
                                classes: ['red', 'bold']
                            },
                            cancelButtonLabel: 'Annuler'
                        }).then(() => {
                            showToast({
                                text: t('ADMIN_USERS_IMPORT_IN_PROGRESS'),
                                duration: 2500
                            });
                            importEmployee(data.files[0], false, config?.id, config?.onlyCreate)
                                .then(() => {
                                    hideToast();
                                    showToast({
                                        text: t('ADMIN_USERS_IMPORT_DONE'),
                                        duration: 1500
                                    });
                                })
                                .catch(() => {
                                    showToast({
                                        text: t('ERROR_UNEXPECTED_CAUGHT'),
                                        duration: 1500
                                    });
                                });
                        });
                    })
                    .catch(() => {
                        showToast({
                            text: t('ERROR_UNEXPECTED_CAUGHT'),
                            duration: 1500
                        });
                    });
            })
            .catch(() => {});
    };

    const confirmImport = () => {
        if (hasRole('WELCOMING_MAIL_FOR_IMPORTED_USERS')) {
            showConfirm({
                title: t('IMPORT_EMPLOYEES_TITLE'),
                text: t('IMPORT_EMPLOYEES_TEXT'),
                button: {
                    label: t('SEARCH_CONFIRM'),
                    classes: ['red', 'bold']
                },
                cancelButtonLabel: t('TAG_DELETE_CONFIRM_CANCEL')
            })
                .then(() => {
                    importFile();
                })
                .catch();
        } else {
            importFile();
        }
    };

    const getLevelValue = (employee, index) => {
        let levels = employee.levels?.filter((level) => {
            return level.index == index && level.name !== '';
        });

        return levels && levels.length > 0 ? levels.map((l) => l.name).join(' - ') : '-';
    };

    return (
        <div className="employee-detail">
            <div className="level">
                <div className="level__description">
                    <div className="level__description__name">{importEmployeeConfig.name}</div>
                </div>
                <div className="level__action">
                    {importEmployeeConfig.configs['key'] && (
                        <div style={{ paddingRight: 10 }}>Créer seulement</div>
                    )}

                    {importEmployeeConfig.configs['key'] && (
                        <div
                            className="checkbox"
                            title="Cocher pour seulement créer sans désactiver">
                            <input
                                className="input"
                                id={`config[${importEmployeeConfig.id}]`}
                                type="checkbox"
                                value={importEmployeeConfig.onlyCreate}
                                onChange={(e) => {
                                    setImportEmployeeConfig({
                                        onlyCreate: e.target.checked
                                    });
                                }}
                            />
                            <label
                                className="label"
                                htmlFor={`config[${importEmployeeConfig.id}]`}></label>
                        </div>
                    )}

                    <button
                        onClick={() => {
                            setPage(page === 0 ? 1 : 0);
                        }}>
                        <i className="icon-search" aria-hidden="true"></i>
                    </button>

                    {importEmployeeConfig.configs['key'] && (
                        <button onClick={confirmImport}>
                            <i className="icon-download-bottom" aria-hidden="true"></i>
                        </button>
                    )}

                    <button
                        onClick={() => {
                            openModal('import_employees', {
                                currentImportEmployeeConfig: importEmployeeConfig
                            });
                        }}>
                        <i className="icon-pencil-1" aria-hidden="true"></i>
                    </button>

                    <button ng-click="$ctrl.deleteImportEmployeeConfig(importEmployeeConfig)">
                        <i className="icon-bin-2" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

            {employeesList && employeesList.length > 0 && !isLoading && (
                <div className="data">
                    <table>
                        <thead>
                            <tr>
                                <td className="small">Id</td>
                                <td>Nom</td>
                                <td>Prénom</td>
                                <td>Email</td>
                                {importEmployeeConfig.configs['registration_number'] && (
                                    <td>Matricule</td>
                                )}
                                <td>Clé de synchronisation</td>
                                {importEmployeeConfig.configs['phone_number'] && (
                                    <td>Numéro de téléphone</td>
                                )}
                                {importEmployeeConfig.configs['birth_date'] && (
                                    <td>Date de naissance</td>
                                )}
                                <td>Créé le</td>
                                {importEmployeeConfig.configs['levels'] &&
                                    importEmployeeConfig.configs['levels'].map((level, index) => {
                                        return (
                                            <td key={`level_head_${config.id}_${index}`}>
                                                {getLevelName(level.levelIndex)}
                                            </td>
                                        );
                                    })}
                            </tr>
                        </thead>

                        <tbody>
                            {!isLoading &&
                                employeesList.map((row) => {
                                    return (
                                        <tr>
                                            <td>{row.id}</td>
                                            <td>{row.lastname}</td>
                                            <td>{row.firstname}</td>
                                            <td>{row.email}</td>
                                            {importEmployeeConfig.configs[
                                                'registration_number'
                                            ] && <td>{row.registration_number}</td>}
                                            <td>{row.unique_synchronisation_id}</td>
                                            {importEmployeeConfig.configs['phone_number'] && (
                                                <td>{row.phone_number}</td>
                                            )}
                                            {importEmployeeConfig.configs['birth_date'] && (
                                                <td>
                                                    {dayjs(row.birth_date).format('DD/MM/YYYY')}
                                                </td>
                                            )}
                                            <td>{dayjs(row.created_at).format('DD/MM/YYYY')}</td>
                                            {importEmployeeConfig.configs['levels'] &&
                                                importEmployeeConfig.configs['levels'].map(
                                                    (level, index) => {
                                                        return (
                                                            <td
                                                                key={`level_value_${config.id}_${index}`}>
                                                                {getLevelValue(
                                                                    row,
                                                                    level.levelIndex
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>

                    <div className="paging">
                        {page > 1 && (
                            <button
                                className="button"
                                ng-click="$ctrl.previousPage(importEmployeeConfig)">
                                Précédent
                            </button>
                        )}
                        {employeesList && employeesList.length == 20 && (
                            <button
                                className="button"
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                Suivant
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImportEmployeeConfig;
