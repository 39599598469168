import Header from './partials/Header';
import React from 'react';
import Statuses from './partials/Tiles/partials/Statuses';
import Filters from './partials/Tiles/partials/Filters';
import useViewport from 'hooks/useViewport';
import { useCurrentStateAndParams } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useTileService from '../api/useTileService';
import Posts from './partials/Tiles/partials/Posts';
import { useFeedContext } from '../providers/FeedProvider';

const Tiles = () => {
    const viewport = useViewport();
    const { params } = useCurrentStateAndParams();
    const { getTilePosts } = useTileService();
    const { tileFilters } = useFeedContext();

    const { workspace, status = 0, page = 1, query, order, since = '' } = params;

    const { data, isLoading } = useQuery({
        queryKey: ['tiles', workspace, page, status, query, order, since],
        queryFn: () => getTilePosts(workspace, page, status, query, order, since)
    });

    return (
        <div className={'tiles-root'}>
            <div>
                <Header hideFilters />
                {!viewport.gtXs && tileFilters && tileFilters.inputs && <Filters />}
            </div>
            <Statuses />
            <div className="posts">
                <Posts data={data} isLoading={isLoading} />
            </div>
        </div>
    );
};

export default Tiles;
