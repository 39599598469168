import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalFormFooter = ({ children, onCancel = () => {}, showCancel = true, ...props }) => {
    const { t } = useTranslation();
    return (
        <div className="footer" {...props}>
            {children ? (
                children
            ) : (
                <>
                    {showCancel && (
                        <Button type="default" danger onClick={onCancel}>
                            {t('POST_DIALOG_CLOSE')}
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {t('POST_JOIN_DOC_VALIDATE')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default ModalFormFooter;
