import React from 'react';

export default (props) => {
    return (
        <div className="item">
            {props.odd ? (
                <div className="text" style={{ width: '80%' }}></div>
            ) : (
                <div className="text" style={{ width: '45%' }}></div>
            )}
        </div>
    );
};
