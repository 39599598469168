import { useTranslation } from 'react-i18next';
import RealTimeDate from 'components/RealTimeDate/RealTimeDate';

const Survey = ({ activity }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="badge">
                <i className="fa fa-comment" aria-hidden="true"></i>
            </div>

            <div className="content">
                <div className="campaign">{activity.title}</div>
                <div className="text">
                    {t('TA_ACTIVITIES_SURVEY_TITLE', { number: activity.submits_count })}
                </div>
            </div>

            <div className="meta">
                <div className="date">
                    <RealTimeDate date={activity.last_submit_date} isLight={true} />
                </div>
            </div>
        </>
    );
};

export default Survey;
