import { useHttp } from './useHttp';

const useSharedDocumentArticleService = () => {
    const axios = useHttp();

    const getArticle = (articleId) => {
        return axios
            .get(`/shared/documents/article/${articleId}`)
            .then((response) => response.data);
    };

    return {
        getArticle
    };
};

export default useSharedDocumentArticleService;
