import React from 'react';
import { useShareDocument as useShareDocumentContext } from '../providers/SharedDocumentProvider';
import { useTranslation } from 'react-i18next';
import { size, values } from 'lodash-es';
import { UIView, useRouter } from '@uirouter/react';
import useSharedDocumentRight from '../hooks/useSharedDocumentRight';
import { isAllImagesAttachment } from 'helpers/attachment';
import useAttachment from 'hooks/useAttachment';
import Username from 'components/Username/Username';
import useUser from 'hooks/useUser';

const SharedDocumentCategories = () => {
    const { parentCategory, categories, currentCategoryId, contact } = useShareDocumentContext();
    const { canSeeAdminActionsForCategory } = useSharedDocumentRight();
    const { forgeAttachmentUrl } = useAttachment();
    const { t } = useTranslation();
    const { forgeAvatarUrl } = useUser();
    const router = useRouter();

    const getCategories = () => {
        return parentCategory ? values(parentCategory.children) : categories;
    };

    const hasChildren = (category = null) => {
        if (!category) {
            return categories && categories.length > 0;
        }

        return category && category.children && size(category.children) > 0;
    };

    const hasReconect = (category) => {
        if (category.is_for_reconnect) {
            return true;
        }

        if (category.parent) {
            if (category.parent.is_for_reconnect) {
                return true;
            } else {
                return hasReconect(category.parent);
            }
        }
    };

    const getTotalChildrenAndArticles = (category) => {
        let categoryHasChildren = hasChildren(category);

        const totalChildrenAndArticles = [];

        if (hasChildren) {
            totalChildrenAndArticles.push(
                `${size(category.children)} ${t('SHARED_DOCUMENT_CATEGORIES')}`
            );
        }

        if (categoryHasChildren) {
            totalChildrenAndArticles.push(
                `${size(category.articles)} ${t('SHARED_DOCUMENT_ARTICLES')}`
            );
        }

        return totalChildrenAndArticles.join(', ');
    };

    const hasArticles = (category) => {
        return category && category.articles && category.articles.length > 0;
    };

    return (
        <div className="body">
            <UIView className="content">
                <div className="content">
                    <div className="category-wrapper">
                        {parentCategory ? (
                            <div className="overview">
                                <div className="title">
                                    <div className="text">{parentCategory.name}</div>
                                    {canSeeAdminActionsForCategory(parentCategory) && (
                                        <div className="actions">
                                            <button
                                                type="button"
                                                className="edit-article"
                                                ng-click="editCategory(parentCategory, true)"
                                            >
                                                <i className="icon-pencil-1" aria-hidden="true"></i>
                                            </button>

                                            <div className="action" ng-click="showActionButtons()">
                                                <i
                                                    className="icon-navigation-menu-horizontal"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </div>
                                    )}

                                    {hasReconect(parentCategory) && (
                                        <div className="reconect" ng-click="gotToReconect()">
                                            {t('RECONNECT_SPACE_SHORT')}
                                        </div>
                                    )}
                                </div>

                                {parentCategory.description && (
                                    <div className="description">
                                        <div
                                            // TODO nl2br
                                            dangerouslySetInnerHTML={{
                                                __html: parentCategory.description
                                            }}
                                        ></div>
                                    </div>
                                )}

                                <div className="actions">
                                    <div
                                        className="action grey"
                                        ng-click="Modal.open('shared_documents_recents')"
                                    >
                                        Voir les mises à jour récentes
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="overview">
                                <div className="title">
                                    <div className="text">
                                        {t('ADMIN_MENU_SHARED_DOCUMENTS_LABEL')}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="children">
                            <div className="title">
                                {t('SHARED_DOCUMENTS_CATEGORIES')}

                                {canSeeAdminActionsForCategory(parentCategory) && (
                                    <div className="plus">
                                        <button
                                            type="button"
                                            className="add-category"
                                            ng-click="addCategory(true)"
                                        >
                                            +
                                        </button>
                                    </div>
                                )}
                            </div>

                            {hasChildren(parentCategory) && (
                                <div className="items">
                                    {getCategories().map((category) => {
                                        if (category.id === currentCategoryId) return;
                                        return (
                                            <div
                                                className={`item ${category.media && isAllImagesAttachment([category.media]) ? 'has-cover' : ''}`}
                                                key={`category_${category.id}`}
                                            >
                                                {category.media &&
                                                    isAllImagesAttachment([category.media]) && (
                                                        <div className="cover">
                                                            <img
                                                                src={forgeAttachmentUrl(
                                                                    category.media,
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                    )}

                                                <div className="meta-wrapper">
                                                    {canSeeAdminActionsForCategory(
                                                        parentCategory
                                                    ) && (
                                                        <div className="actions">
                                                            <button
                                                                type="button"
                                                                className="edit-category"
                                                                ng-click="editCategory(category, true)"
                                                            >
                                                                <i
                                                                    className="icon-pencil-1"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="delete-category"
                                                                ng-click="deleteCategory(category.id)"
                                                            >
                                                                <i
                                                                    className="icon-bin-2"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    )}

                                                    <a
                                                        className="meta"
                                                        href={router.stateService.href(
                                                            'auth.sharedDocuments.categories',
                                                            { categoryId: category.id }
                                                        )}
                                                    >
                                                        <div className="name">{category.name}</div>
                                                        <div className="details">
                                                            {getTotalChildrenAndArticles(category)}
                                                        </div>
                                                        {category.organizational_units_names &&
                                                            canSeeAdminActionsForCategory(
                                                                parentCategory
                                                            ) && (
                                                                <div className="target">
                                                                    {t('SHARED_DOCUMENTS_TARGET')} :{' '}
                                                                    {
                                                                        category.organizational_units_names
                                                                    }
                                                                </div>
                                                            )}
                                                    </a>

                                                    <a
                                                        className="icon"
                                                        href={router.stateService.href(
                                                            'auth.sharedDocuments.categories',
                                                            { categoryId: category.id }
                                                        )}
                                                    >
                                                        <i
                                                            className="icon-arrow-right-arrows-diagrams"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <div className="articles">
                            <div className="title">
                                {t('SHARED_DOCUMENTS_ARTICLES')}

                                {canSeeAdminActionsForCategory(parentCategory) && (
                                    <div className="plus">
                                        <button
                                            type="button"
                                            className="add-article"
                                            ng-click="addArticle(true)"
                                        >
                                            +
                                        </button>
                                    </div>
                                )}
                            </div>

                            {hasArticles(parentCategory) && (
                                <div className="items">
                                    {parentCategory.articles.map((article) => {
                                        return (
                                            <div className="item">
                                                <div className="meta">
                                                    <div className="name">
                                                        {article.title}
                                                        {article.organizational_units_names &&
                                                            canSeeAdminActionsForCategory(
                                                                parentCategory
                                                            ) && (
                                                                <div className="target">
                                                                    {t('SHARED_DOCUMENTS_TARGET')} :{' '}
                                                                    {
                                                                        article.organizational_units_names
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>

                                                    {canSeeAdminActionsForCategory(
                                                        parentCategory
                                                    ) && (
                                                        <div className="actions">
                                                            <button
                                                                type="button"
                                                                className="edit-article"
                                                                ng-click="editArticle(article, true)"
                                                            >
                                                                <i
                                                                    className="icon-pencil-1"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="delete-article"
                                                                ng-click="deleteArticle(article.id, article.resources_count)"
                                                            >
                                                                <i
                                                                    className="icon-bin-2"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <a
                                                    className="action"
                                                    href={router.stateService.href(
                                                        'auth.sharedDocuments.categories.article',
                                                        {
                                                            articleId: article.id,
                                                            categoryId: parentCategory.id
                                                        }
                                                    )}
                                                >
                                                    {t('SEE_X_SHARED_DOCUMENT_RESOURCES', {
                                                        count_documents: article.resources_count
                                                    })}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </UIView>

            {contact && (
                <div className="contact" ng-if="contact">
                    <div className="title">
                        <div className="icon" ng-include="'/images/phone-circle.svg'"></div>
                        <div className="text">Contact référent</div>
                    </div>

                    <div className="user">
                        {contact.image_profile_name && (
                            <div className="image">
                                <img src={forgeAvatarUrl(contact.image_profile_name)} />
                            </div>
                        )}

                        <div className="description">
                            <div className="name">
                                <Username user={contact} />
                            </div>

                            {contact.job && <div className="job">{contact.job}</div>}

                            {contact.phone_number && (
                                <div className="phone">{contact.phone_number}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SharedDocumentCategories;
