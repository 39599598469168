import { forEach, has, set } from 'lodash-es';

const useLevel = () => {
    const levelsGroupByIndex = (levels) => {
        const levelsGroupByIndexArray = [];
        forEach(levels, (level) => {
            if (!has(levelsGroupByIndexArray, [level.index])) {
                set(levelsGroupByIndexArray, [level.index], []);
            }

            levelsGroupByIndexArray[level.index].push(level);
        });
        return levelsGroupByIndexArray;
    };
    return {
        levelsGroupByIndex
    };
};

export default useLevel;
