import React, { useEffect, useRef, useState } from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import useBlogArticleService from 'api/useBlogArticleService';
import { useTranslation } from 'react-i18next';
import useBlog from '../../../hooks/useBlog';
import useAttachment from '../../../hooks/useAttachment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';

const Small = ({ article }) => {
    const { t } = useTranslation();
    const { forgeBlogArticleContentAttachmentUrl } = useAttachment();
    const { getLocalizedArticleProperty, getArticleTypeIcon, readMore } = useBlog();

    return (
        <div className="blog-article-xs" style={{ backgroundColor: article.category.color }}>
            <div className="preview">
                {getLocalizedArticleProperty(article, 'thumbnail') && (
                    <div className="image">
                        <div
                            className="background"
                            style={{
                                backgroundImage: `url(${forgeBlogArticleContentAttachmentUrl(getLocalizedArticleProperty(article, 'thumbnail'))})`
                            }}></div>
                    </div>
                )}

                {!getLocalizedArticleProperty(article, 'thumbnail') && (
                    <div className="image empty">
                        <i className={`fa ${getArticleTypeIcon(article)}`}></i>
                    </div>
                )}
            </div>

            <div className="meta">
                <div className="title">{getLocalizedArticleProperty(article, 'title')}</div>

                <div className="actions">
                    <button type="button" onClick={() => readMore(article)}>
                        {t('POST_ATTACH_BLOG_ARTICLE_READ')}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Xl = ({ article }) => {
    const { t } = useTranslation();
    const { forgeBlogArticleContentAttachmentUrl } = useAttachment();

    const { getLocalizedArticleProperty, getLocalizedCategoryTitle, readMore } = useBlog();

    return (
        <div className="blog-article-xl">
            <div className="article-wrapper">
                {getLocalizedArticleProperty(article, 'thumbnail') && (
                    <div className="thumbnail">
                        <img
                            src={forgeBlogArticleContentAttachmentUrl(
                                getLocalizedArticleProperty(article, 'thumbnail')
                            )}
                            alt={getLocalizedArticleProperty(article, 'title')}
                        />
                    </div>
                )}

                <div className="content">
                    <div className="tags">
                        <div
                            className="tag category"
                            style={{ backgroundColor: article.category.color }}>
                            {getLocalizedCategoryTitle(article.category)}
                        </div>
                    </div>

                    <div className="title" onClick={() => readMore(article)}>
                        {getLocalizedArticleProperty(article, 'title')}
                    </div>

                    <div className="actions">
                        <button type="button" onClick={() => readMore(article)}>
                            {t('POST_ATTACH_BLOG_ARTICLE_READ')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BlogArticles = ({ widget, options }) => {
    const [blogArticles, setBlogArticles] = useState([]);
    const { getStyleValueForKey, getFieldValueForKey } = useWidgetComponent(widget, options);
    const { getPublishedBlogArticles } = useBlogArticleService();
    const { t } = useTranslation();

    useEffect(() => {
        getBlogArticles();
    }, []);

    const getBlogArticles = () => {
        let limit;

        switch (getFieldValueForKey('displayMode')) {
            case 'selectedBlogArticles':
                getPublishedBlogArticles().then((response) => {
                    let selectedBlogArticles = getFieldValueForKey('blogArticles');

                    setBlogArticles(
                        response.filter((blogArticle) => {
                            return (
                                selectedBlogArticles.findIndex((selectedBlogArticle) => {
                                    return selectedBlogArticle.value === blogArticle.id;
                                }) !== -1
                            );
                        })
                    );
                });

                break;
            case 'categoryBlogArticles':
                let categoryId = getFieldValueForKey('categoryBlogArticle').value;
                limit = getFieldValueForKey('limit');

                getPublishedBlogArticles(false, limit, categoryId).then((response) => {
                    setBlogArticles(response);
                });

                break;
            case 'latest':
            default:
                limit = getFieldValueForKey('limit');
                getPublishedBlogArticles(false, limit).then((response) => {
                    setBlogArticles(response);
                });
        }
    };

    const hasBlogArticles = () => {
        return blogArticles && blogArticles.length > 0;
    };

    const getPaginationStyle = () => {
        if (!getStyleValueForKey('paginationColor')) return;

        return { '--swiper-pagination-color': getStyleValueForKey('paginationColor') };
    };

    const isSmallArticleDisplay = () => {
        return getFieldValueForKey('displayType') === 'small';
    };

    const slideOptions = () => {
        if (getStyleValueForKey('layoutMode') !== 'slider') {
            return {};
        }

        let swiperOptions = {
            spaceBetween: 20,
            navigation: true,
            pagination: { clickable: true }
        };

        if (getStyleValueForKey('autoRotate')) {
            swiperOptions.loop = true;
            swiperOptions.autoplay = {
                delay: getStyleValueForKey('autoRotateTiming') * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            };
        }

        return swiperOptions;
    };

    return (
        <div className={`blog-articles ${getStyleValueForKey('layoutMode')}`}>
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                {getFieldValueForKey('title')}
            </div>

            {hasBlogArticles() && (
                <div className="listing" style={getPaginationStyle()}>
                    <Swiper
                        slidesPerView={1}
                        {...slideOptions()}
                        modules={[Autoplay, Pagination, Navigation]}>
                        {blogArticles.map((article) => {
                            return (
                                <SwiperSlide key={`article_${article.id}`}>
                                    {isSmallArticleDisplay() && (
                                        <div>
                                            <Small article={article} />
                                        </div>
                                    )}
                                    {!isSmallArticleDisplay() && (
                                        <div>
                                            <Xl article={article} />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            )}

            {!hasBlogArticles() && (
                <div className="empty" style={{ color: getStyleValueForKey('titleColor') }}>
                    {t('NO_BLOG_ARTICLES_YET')}
                </div>
            )}
        </div>
    );
};

export default BlogArticles;
