import axios from 'axios';
import { useConfig } from 'providers/ConfigProvider';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useDevice } from 'providers/DeviceContext';
import { isObject, toJSON } from 'lodash-es';
import utf8 from 'utf8';
import base64 from 'base-64';

export const useHttp = () => {
    const { config: appConfig, locale } = useConfig();
    const { device } = useDevice();

    const isFile = (obj) => {
        return toString.call(obj) === '[object File]';
    };

    const isFormData = (obj) => {
        return toString.call(obj) === '[object FormData]';
    };

    const isBlob = (obj) => {
        return toString.call(obj) === '[object Blob]';
    };

    const refreshAuthLogic = (failedRequest) =>
        axios
            .post((appConfig.serverUrl ? appConfig.serverUrl : '') + '/api/token/refresh', null, {
                withCredentials: true
            })
            .then((tokenRefreshResponse) => {
                return Promise.resolve();
            });

    const instance = axios.create({
        baseURL: appConfig.apiUrl,
        withCredentials: true,
        transformRequest: [
            (data, headers) => {
                if (appConfig.isMsie || appConfig.HAS_DISABLE_DATA_OBFUSCATION) {
                    return data;
                }

                if (isObject(data) && !isFile(data) && !isBlob(data) && !isFormData(data)) {
                    return toJSON({
                        obfuscated_data: base64.encode(utf8.encode(toJSON(data)))
                    });
                }

                return data;
            },
            ...axios.defaults.transformRequest
        ]
    });

    instance.interceptors.request.use((config) => {
        config.headers = {
            ...config.headers,
            locale: locale,
            'X-TA-Version': device.version,
            'X-TA-IsApp': device.isApp
        };

        if (appConfig.custom_header) {
            config.headers = {
                ...config.headers,
                ...appConfig.custom_header
            };
        }

        if (
            appConfig.remove_http_cache &&
            config.url.startsWith('http') &&
            config.url.indexOf('/api/')
        ) {
            let timestamp = new Date().getTime();

            if (config.url.indexOf('?') !== -1) {
                config.url = `${config.url}&t=${timestamp}`;
            } else {
                config.url = `${config.url}?t=${timestamp}`;
            }
        }

        return config;
    });

    createAuthRefreshInterceptor(instance, refreshAuthLogic);

    return instance;
};
