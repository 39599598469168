import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import useUserService from 'api/useUserService';
import { useConfig } from './ConfigProvider';
import { forEach } from 'lodash-es';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { getUser, getUserConfig } = useUserService();
    const { setLocale, setConfig } = useConfig();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const rolesTmp = [];
        if (user) {
            forEach(user.roles, (role) => {
                rolesTmp.push(role);
            });

            forEach(user.workspaces_rules, (role) => {
                if (role.is_readable) {
                    rolesTmp.push(`${role.workspace_id}_READ`);
                }

                if (role.is_half_readable) {
                    rolesTmp.push(`${role.workspace_id}_SILENT`);
                }

                if (role.is_writable) {
                    rolesTmp.push(`${role.workspace_id}_WRITE`);
                }

                if (role.is_admin) {
                    rolesTmp.push(`${role.workspace_id}_ADMIN`);
                }
            });

            setRoles(rolesTmp);
        }
    }, [user]);

    useEffect(() => {
        const fetchUser = () => {
            getUser()
                .then((data) => {
                    setUser(data);
                    if (isAuthenticated === null) {
                        setIsAuthenticated(true);
                    }
                    if (data.lang) {
                        setLocale(data.lang);
                    }
                })
                .catch(() => {
                    setIsAuthenticated(false);
                });
        };
        const fetchConfigs = () => {
            getUserConfig().then((data) => {
                const config = {
                    ...data.configs.reduce((acc, row) => {
                        acc[row.name] = row.value;
                        return acc;
                    }, {}),
                    ...data.configsText.reduce((acc, row) => {
                        acc[row.name] = row.value;
                        return acc;
                    }, {})
                };
                setConfig(config);
            });
        };

        if (isAuthenticated !== false) {
            fetchUser();
            fetchConfigs();
        }
    }, [isAuthenticated]);

    const login = useCallback(() => {
        setIsAuthenticated(true);
    }, []);

    const logout = useCallback(() => {
        setIsAuthenticated(false);
    }, []);

    const contextValues = useMemo(
        () => ({
            isAuthenticated,
            setIsAuthenticated,
            login,
            logout,
            user,
            roles,
            setUser
        }),
        [isAuthenticated, login, logout, user, roles]
    );

    return (
        <AuthContext.Provider value={contextValues}>
            {isAuthenticated !== null && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
