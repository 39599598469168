import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAttachment from '../../../hooks/useAttachment';
import useCampaignComponent from './hooks/useCampaignComponent';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Link from 'components/Link/Link';
import useEmoji from '../../../hooks/useEmoji';

const Recruiting = ({ campaign }) => {
    const { t } = useTranslation();
    const { emoji } = useEmoji();
    const { forgeAttachmentUrl, getVideoState, getVideoFiles, getVideoType } = useAttachment();
    const {
        canSeeStats,
        showActions,
        toggleRules,
        showCarousel,
        shareCampaign,
        displayReward,
        openModalCampaignForm
    } = useCampaignComponent(campaign);
    const [isReadMore, setIsReadMore] = useState(false);
    const textContainerRef = useRef(null);
    const textRef = useRef(null);

    const readMoreText = isReadMore
        ? `${t('POST_DIALOG_CLOSE')} <i class="fa fa-caret-up"></i>`
        : `${t('TA_POST_READ_MORE')} <i class="fa fa-caret-down"></i>`;

    const getTextOffsetHeight = useCallback(() => {
        return textRef.current ? textRef.current.offsetHeight : 0;
    }, []);

    const hasReadMore = useCallback(() => {
        const textOffsetHeight = getTextOffsetHeight();
        return textOffsetHeight > 105;
    }, [getTextOffsetHeight]);

    const toggleReadMore = () => {
        setIsReadMore((prev) => !prev);
    };

    const containerStyle = {
        height: isReadMore ? `${getTextOffsetHeight()}px` : '105px'
    };

    useEffect(() => {
        if (textContainerRef.current && !isReadMore) {
            const textOffsetHeight = getTextOffsetHeight();
            textContainerRef.current.style.height =
                textOffsetHeight > 105 ? '105px' : `${textOffsetHeight}px`;
        }
    }, [isReadMore, getTextOffsetHeight]);

    return (
        <>
            <div className="header">
                <div className="icon">
                    <i className="fa fa-users" aria-hidden="true"></i>
                </div>
                <div className="title">{t('TA_RECRUITING_TITLE')}</div>
                {campaign.user_link && (
                    <div className="target">
                        <a
                            href={campaign.user_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            {t('TA_POST_VIEW_SHARE_RECRUITING_LINK')}
                        </a>
                    </div>
                )}
                <div className="post__header__menu">
                    <button onClick={showActions}>
                        <i className="icon-navigation-menu-horizontal"></i>
                    </button>
                </div>
            </div>

            <div className="campaign">
                <div className="description">
                    <div className="title">{campaign.title}</div>
                    <div className="text-container" style={containerStyle} ref={textContainerRef}>
                        <div
                            className="text ng-quill-text"
                            ref={textRef}
                            dangerouslySetInnerHTML={{ __html: emoji(campaign.text) }}
                        ></div>
                        {hasReadMore() && (
                            <div className={`gradient ${isReadMore ? 'out' : ''}`}></div>
                        )}
                    </div>
                    {hasReadMore() && (
                        <div className="text-center">
                            <button
                                className="button dark-grey"
                                onClick={toggleReadMore}
                                dangerouslySetInnerHTML={{ __html: readMoreText }}
                            ></button>
                        </div>
                    )}
                </div>

                <div className="image">
                    {campaign.documents?.length > 0 &&
                        campaign.documents.every((doc) => doc.type.includes('image')) && (
                            <div className="pictures">
                                <div
                                    className="picture"
                                    onClick={() => showCarousel(campaign.documents, 0)}
                                >
                                    <img
                                        src={forgeAttachmentUrl(campaign.documents[0])}
                                        alt="campaign"
                                    />
                                </div>
                                {campaign.documents.length > 1 && (
                                    <div className="show-carrousel">{t('SHOW_CARROUSEL')}</div>
                                )}
                            </div>
                        )}
                    {campaign.link && (
                        <div className="item">
                            <Link item={campaign.link} className="item" campaign={true} />
                        </div>
                    )}
                </div>

                {campaign.documents?.length > 0 && campaign.documents[0].type.includes('video') && (
                    <div className="video-player-container">
                        <VideoPlayer
                            src={forgeAttachmentUrl(campaign.documents[0])}
                            files={getVideoFiles(campaign.documents[0])}
                            state={getVideoState(campaign.documents[0])}
                            type={getVideoType(campaign.documents[0].type)}
                            poster={campaign.documents[0]}
                        />
                    </div>
                )}

                <div className="actions">
                    {campaign.reward > 0 && !campaign.user_already_answered && (
                        <button className="button recruiting big-button" onClick={toggleRules}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('TA_RECRUITING_PTS_TO_EARN', {
                                        cooptPoints: campaign.reward,
                                        sharePoints: campaign.reward_share
                                    })
                                }}
                            ></span>
                        </button>
                    )}
                    {campaign.reward > 0 && campaign.user_already_answered && (
                        <button
                            className="button recruiting big-button user-already-earned"
                            onClick={() => displayReward(false)}
                        >
                            {campaign.reward} {t('TA_POST_POINTS_EARNED')}
                        </button>
                    )}
                    {campaign.status === 2 && (
                        <>
                            <button
                                className="button recruiting big-button bg-white"
                                onClick={() =>
                                    openModalCampaignForm(t('APPLICATION_OFFER'), campaign.title)
                                }
                            >
                                {t('TA_POST_RECRUITING_SEND_SUBMIT')}
                            </button>
                            <button
                                className="button recruiting big-button bg-white"
                                onClick={shareCampaign}
                            >
                                {t('TA_POST_SHARE_OFFER')}
                            </button>
                        </>
                    )}
                    {canSeeStats() && (
                        <>
                            {campaign.total_clicks > 0 ? (
                                <button
                                    ui-sref="auth.dashboard.campaigns.feed({ type: 'recruiting', status: 'running' })"
                                    className="button small tiny see-stats recruiting"
                                >
                                    {t('SEE_THE_STATS')}
                                </button>
                            ) : (
                                <span className="button small tiny see-stats recruiting">
                                    {t('NO_STATS')}
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Recruiting;
