import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import { isArray } from 'lodash-es';
import { useRouter } from '@uirouter/react';
import Internal from './Registration/Internal';
import External from './Registration/External';

const Registration = () => {
    const { t } = useTranslation();

    const router = useRouter();
    const [userRegistrationType, setUserRegistrationType] = useState(null);
    const [internalType, setInternalType] = useState(null);
    const { config } = useConfig();

    return (
        <div className="registration">
            {!userRegistrationType && (
                <div className="user-type spacing">
                    <div className="form">
                        {(!config.employee_configs ||
                            (isArray(config.employee_configs) &&
                                config.employee_config.length === 0)) && (
                            <div className="actions">
                                <button
                                    className="btn hl"
                                    onClick={() => {
                                        setUserRegistrationType('internal');
                                    }}
                                >
                                    {t('LOGIN_ASK_STATUS_EMPLOYEE')}
                                </button>
                            </div>
                        )}

                        {config.employee_configs &&
                            !isArray(config.employee_configs) &&
                            Object.keys(config.employee_configs).map((key) => {
                                return (
                                    <div className="actions">
                                        <button
                                            className="btn hl"
                                            onClick={() => {
                                                setUserRegistrationType('internal');
                                                setInternalType(key);
                                            }}
                                        >
                                            {t(config.employee_configs[key])}
                                        </button>
                                    </div>
                                );
                            })}
                    </div>

                    {!config.is_closed_for_external && (
                        <div className="form">
                            <div className="actions">
                                <button
                                    className="btn grey"
                                    onClick={() => {
                                        setUserRegistrationType('external');
                                    }}
                                >
                                    {t('LOGIN_ASK_STATUS_NOT_EMPLOYEE')}
                                </button>
                            </div>
                        </div>
                    )}

                    {config.additional_access_for_internal && (
                        <div className="form">
                            <div className="actions">
                                <button
                                    className="btn hl"
                                    onClick={() => {
                                        setUserRegistrationType('internal');
                                    }}
                                >
                                    {t('I_AM_PREMIUM')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {userRegistrationType && userRegistrationType === 'internal' && (
                <Internal type={internalType} />
            )}
            {userRegistrationType && userRegistrationType === 'external' && <External />}

            <div className="cancel">
                <button
                    type="button"
                    className="link"
                    onClick={() => {
                        router.stateService.go('login.home');
                    }}
                >
                    {t('LOGIN_CANCEL_FORGOT_PASSWORD')}
                </button>
            </div>
        </div>
    );
};

export default Registration;
