import { useTranslation } from 'react-i18next';
import { useRouter } from '@uirouter/react';
import useRight from 'hooks/useRight';

const useNavbarService = () => {
    const { t } = useTranslation();
    const { hasRole, hasAtLeastOneRole } = useRight();
    const router = useRouter();

    const lazyStates = [
        {
            name: 'auth.homepage',
            navbar: {
                getIcon: () => {
                    return 'icon-house-chimney-1';
                },
                getLabel: () => {
                    return t('HOME_PAGE');
                },
                isActive: (context, { name }) => {
                    const scope = name.split('.').slice(0, -1).join('.');

                    return context.isActive([`${scope}.**`]);
                },
                isVisible: () => {
                    return true;
                }
            }
        },
        {
            name: 'auth.dashboard.campaigns.home',
            navbar: {
                getIcon: () => {
                    return 'icon-cog';
                },
                getLabel: (context) => {
                    return t('CAMPAIGNS_MANAGEMENT');
                },
                isActive: (context) => {
                    return context.isActive(['auth.dashboard.campaigns.**']);
                },
                isVisible: (context) => {
                    return hasAtLeastOneRole([
                        'ROLE_RS',
                        'ROLE_PRODUCT',
                        'ROLE_RECRUITING',
                        'ROLE_SURVEY',
                        'ROLE_SUPER_RS',
                        'ROLE_SUPER_PRODUCT',
                        'ROLE_SUPER_RECRUITING',
                        'ROLE_SUPER_SURVEY'
                    ]);
                }
            }
        },
        {
            name: 'auth.dashboard.activities.home',
            navbar: {
                getIcon: () => {
                    return 'icon-cog';
                },
                getLabel: (context) => {
                    return t('CAMPAIGNS_MANAGEMENT');
                },
                getAdminLabel: (context) => {
                    return t('CAMPAIGNS_ACTIVITIES_MANAGEMENT');
                },
                isActive: (context) => {
                    return context.isActive(['auth.dashboard.activities.**']);
                },
                isVisible: (context) => {
                    return (
                        !hasAtLeastOneRole([
                            'ROLE_RS',
                            'ROLE_PRODUCT',
                            'ROLE_RECRUITING',
                            'ROLE_SURVEY',
                            'ROLE_SUPER_RS',
                            'ROLE_SUPER_PRODUCT',
                            'ROLE_SUPER_RECRUITING',
                            'ROLE_SUPER_SURVEY'
                        ]) &&
                        hasAtLeastOneRole([
                            'ROLE_DELEGATE_RS',
                            'ROLE_DELEGATE_PRODUCT',
                            'ROLE_DELEGATE_RECRUITING',
                            'ROLE_DELEGATE_SURVEY',
                            'ROLE_DELEGATE_MISSION',
                            'ROLE_SUPER_RS'
                        ])
                    );
                }
            }
        },
        {
            name: 'auth.dashboard.missions.home',
            navbar: {
                getIcon: () => {
                    return 'icon-flash-1';
                },
                getLabel: (context) => {
                    return t('MISSIONS_MANAGEMENT');
                },
                isActive: (context) => {
                    return context.isActive(['auth.dashboard.missions.**']);
                },
                isVisible: (context) => {
                    return hasRole('ROLE_SUPER_MISSION');
                }
            }
        },
        {
            name: 'auth.admin.users',
            navbar: {
                getIcon: () => {
                    return 'icon-cog';
                },
                getLabel: (context) => {
                    return t('TOP_ADMIN');
                },
                isActive: (context) => {
                    return context.isActive(['auth.admin.**']);
                },
                isVisible: (context) => {
                    return hasRole('ROLE_ADMIN');
                }
            }
        },
        {
            name: 'auth.logout',
            navbar: {
                getIcon: () => {
                    return 'icon-power-button';
                },
                getLabel: (context) => {
                    return t('TOP_LOGOUT');
                },
                getCallback: (context) => {
                    //context.logoutUser();
                },
                isActive: () => {
                    return false;
                },
                isVisible: () => {
                    return true;
                }
            }
        }
    ];

    const getStates = () => {
        const routerStates = router.urlService.rules
            .rules()
            .filter((rule) => {
                return rule.type === 'STATE' && rule.state.self.navbar;
            })
            .map((rule) => {
                return {
                    name: rule.state.name,
                    navbar: rule.state.self.navbar
                };
            });

        return [...routerStates, ...lazyStates];
    };

    const getNavbarStateForName = (name) => {
        const state = getStates().find((state) => state.name === name);

        return state && state.navbar;
    };

    return {
        getNavbarStateForName
    };
};

export default useNavbarService;
