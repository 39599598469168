import React from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from './components/Sidebar/Sidebar';
import useUser from 'hooks/useUser';
import Tabs from './components/Tabs/Tabs';
import { UIView } from '@uirouter/react';
import ProfileProvider, { useProfile as useProfileContext } from './providers/ProfileProvider';

const ProfileContent = () => {
    const { t } = useTranslation();
    const { isMe } = useUser();
    const { currentUser } = useProfileContext();

    return (
        <div className={'profile-wrapper'}>
            {currentUser && isMe(currentUser) && (
                <div
                    className="edit-profile"
                    ng-include="'profile_partials_edit_profile'"
                    ng-if="$ctrl.isMe()"
                ></div>
            )}

            <div className="profile">
                <div className="sidebar">
                    <Sidebar user={currentUser} />
                </div>

                <div className="main">
                    <div className="tabs">
                        <Tabs user={currentUser} />
                    </div>

                    <div className="view">
                        <UIView>
                            <div className="posts-wrapper" ng-if="">
                                <div
                                    className="posts"
                                    ng-repeat="post in $ctrl.posts track by post.post.id"
                                >
                                    <post
                                        className="post {{ post.alreadyLiked }}"
                                        post="post.post"
                                        already-liked="post.alreadyLiked"
                                        download="post.download"
                                        consultations="post.post_consultations"
                                        tag-ids="post.tags_id"
                                        tags="$ctrl.tags"
                                        shared-tags="$ctrl.sharedTags"
                                        user="$ctrl.user"
                                        template="stacked"
                                    ></post>
                                </div>

                                <div className="actions" ng-if="$ctrl.hasMorePosts">
                                    <div className="action">
                                        <button
                                            className="btn outline primary"
                                            ng-click="$ctrl.loadMorePosts()"
                                        >
                                            {t('PROFILE_LOAD_MORE_POSTS')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </UIView>
                    </div>
                </div>

                <div
                    className="sidebar sidebar-lt-md visible-lt-md"
                    ng-if=""
                    ng-include="$ctrl.getTemplateByType('sidebar_lt_md')"
                ></div>
            </div>
        </div>
    );
};

const Profile = () => {
    return (
        <ProfileProvider>
            <ProfileContent />
        </ProfileProvider>
    );
};

export default Profile;
