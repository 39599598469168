import { useHttp } from 'api/useHttp';

const useMissionService = () => {
    const axios = useHttp();

    const expireMission = (mission) => {
        return axios
            .delete(`/dashboard/expire/mission/${mission.id}`)
            .then((response) => response.data);
    };
    return {
        expireMission
    };
};

export default useMissionService;
