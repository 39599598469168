import { useHttp } from 'api/useHttp';

const useTagService = () => {
    const axios = useHttp();

    const getTags = () => {
        return axios.get(`/user/tags`).then((response) => {
            return response.data;
        });
    };

    const getAdminTags = () => {
        return axios.get(`/admin/tags`).then((response) => {
            return response.data;
        });
    };

    const getEssentialTags = () => {
        return axios.get(`/essential/tags`).then((response) => {
            return response.data;
        });
    };

    const deleteTag = (tag) => {
        return axios.delete(`/tags/${tag.id}`).then((response) => {
            return response.data;
        });
    };

    const addTag = (tag) => {
        return axios.post(`/tags`, tag).then((response) => {
            return response.data;
        });
    };

    const getAllAdminTags = (params) => {
        return axios.get(`/admin/all/shared-tags`).then((response) => {
            return response.data;
        });
    };

    const getTag = (tagId) => {
        return axios.get(`/tags/${tagId}`).then((response) => {
            return response.data;
        });
    };

    return {
        addTag,
        getAdminTags,
        getTag,
        getAllAdminTags,
        getEssentialTags,
        getTags,
        deleteTag
    };
};

export default useTagService;
