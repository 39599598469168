import React, { createElement } from 'react';
import Product from './components/Product';
import Survey from './components/Survey';
import Recruiting from './components/Recruiting';
import { useQuery } from '@tanstack/react-query';
import useCampaignActivityService from '../../api/useCampaignActivityService';
import { useCurrentStateAndParams } from '@uirouter/react';

const partials = {
    product: Product,
    recruiting: Recruiting,
    survey: Survey,
    social: () => <></>
};

function DashboardActivityView() {
    const { getActivityById } = useCampaignActivityService();
    const { params } = useCurrentStateAndParams();

    const { data: activity, isLoading } = useQuery({
        queryKey: ['activity', params.activity],
        queryFn: () => getActivityById(params.activity)
    });

    if (isLoading && !activity) {
        return <></>;
    }
    return <div className={'content'}>{createElement(partials[activity.type], { activity })}</div>;
}

export default DashboardActivityView;
