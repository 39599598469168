import React from 'react';
import useWidgetComponent from '../hooks/useWidgetComponent';
import useUser from '../../../hooks/useUser';
import { useTranslation } from 'react-i18next';

const Ambassador = ({ widget, options }) => {
    const {
        getStyleValueForKey,
        getFieldValueForKey,
        getIconForKey,
        getRoundSize,
        isImageDisplayType,
        configurationError
    } = useWidgetComponent(widget, options);
    const { getAvatar } = useUser();
    const { t } = useTranslation();

    const getAmbassadorTextDescription = (ambassador) => {
        if (!ambassador) {
            return '';
        }

        return t(`AMBASSADOR_${ambassador.criterion.toUpperCase()}_ACTION`, {
            name: ambassador.user.name,
            value: ambassador.value
        });
    };

    return (
        <div className="ambassador">
            <div className="title" style={{ color: getStyleValueForKey('titleColor') }}>
                {getFieldValueForKey('title')}
            </div>

            <div className="user">
                <div className="avatar">
                    <img src={getAvatar(getFieldValueForKey('ambassador').user)} />
                </div>
            </div>

            <div
                className="criterion"
                style={{ color: getStyleValueForKey('textColor') }}
                dangerouslySetInnerHTML={{
                    __html: getAmbassadorTextDescription(getFieldValueForKey('ambassador'))
                }}
            ></div>
        </div>
    );
};

export default Ambassador;
