import React from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import { useRouter } from '@uirouter/react';

const ProfileSidebarTools = ({ user }) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { hasRole } = useRight();

    return (
        <>
            <div className="title">{t('PROFILE_TOOLS')}</div>

            <div className="actions">
                {!hasRole('ROLE_HIDE_LIST_USERS') &&
                    !hasRole('HAS_EMPLOYEE_DIRECTORY_V2') &&
                    hasRole(['ROLE_INTERNAL', 'HAS_EMPLOYEE_DIRECTORY']) && (
                        <div className="action">
                            <a
                                className="btn outline"
                                href={router.stateService.href('auth.phonebook')}
                            >
                                {t('TOP_USERS')}
                            </a>
                        </div>
                    )}

                {!hasRole('ROLE_HIDE_LIST_USERS') &&
                    hasRole(['ROLE_INTERNAL', 'HAS_EMPLOYEE_DIRECTORY_V2']) && (
                        <div className="action">
                            <a
                                className="btn outline"
                                href={router.stateService.href('auth.phonebookv2')}
                            >
                                {t('TOP_USERS')}
                            </a>
                        </div>
                    )}

                {!hasRole('ROLE_HIDE_LIST_USERS') &&
                    !hasRole('HAS_EMPLOYEE_DIRECTORY_V2') &&
                    !hasRole(['ROLE_INTERNAL', 'HAS_EMPLOYEE_DIRECTORY']) && (
                        <div className="action">
                            <a
                                className="btn outline"
                                href={router.stateService.href('auth.directory.list')}
                            >
                                {t('TOP_USERS')}
                            </a>
                        </div>
                    )}

                {hasRole('HAS_HELPDESK') && (
                    <div className="action">
                        <a
                            className="btn outline"
                            href={router.stateService.href('auth.helpdesk.categories')}
                        >
                            {t('ADMIN_MENU_HELPDESK_LABEL')}
                        </a>
                    </div>
                )}

                {hasRole('ROLE_ADMIN') && (
                    <div className="action">
                        <a
                            className="btn outline"
                            href={router.stateService.href('auth.admin.users')}
                        >
                            {t('TOP_ADMIN')}
                        </a>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfileSidebarTools;
