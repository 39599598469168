import { Select } from 'components/Form/Inputs';
import useTableComponent from '../hooks/useTableComponent';
import { useFeedContext } from '../../../../providers/FeedProvider';

const SelectFilter = ({ input }) => {
    const { workspace } = useFeedContext();
    const { getSelectValues } = useTableComponent(workspace, []);

    return <Select name={input.type} placeholder={input.name} options={getSelectValues(input)} />;
};

export default SelectFilter;
