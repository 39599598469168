import React, { useState } from 'react';
import { Select, Input } from 'antd';
import base64 from 'base-64';
import utf8 from 'utf8';
import JSONFormatter from 'json-formatter-js';
import { useAlert } from 'providers/AlertContext';
import { useHttp } from 'api/useHttp';
import clipboard from 'clipboardy';

const Queries = () => {
    const axios = useHttp();
    const { showConfirm, showToast } = useAlert();
    const [query, setQuery] = useState('');
    const [secret, setSecret] = useState(null);
    const [history, setHistory] = useState([]);
    const [response, setResponse] = useState(null);

    const prepare = (q = null) => {
        const currentQuery = q ? q : query;
        if (currentQuery.toLowerCase().indexOf('select') === -1) {
            showConfirm({
                title: 'Êtes-vous sûr ?',
                text: 'Attention, cette requête est potentiellement destructrice !',
                button: {
                    label: 'Exécuter',
                    classes: ['red', 'bold']
                }
            })
                .then(() => {
                    execute(currentQuery, secret);
                })
                .catch(() => {});
        } else {
            execute(currentQuery, secret);
        }
    };

    const execute = (query, secret = null) => {
        setResponse(null);
        axios
            .post('/super-admin/request', {
                query: base64.encode(utf8.encode(query)),
                secret
            })
            .then(({ data }) => {
                let keys = data.reduce((acc, value) => {
                    return acc.concat(Object.keys(value).filter((v) => acc.indexOf(v) === -1));
                }, []);

                let columns = keys.map((key) => {
                    return {
                        name: key,
                        selector: (row) => row[key],
                        sortable: true
                    };
                });

                setResponse({
                    query,
                    columns,
                    results: data
                });

                addToHistory(query);

                setTimeout(() => {
                    let messageEl = document.querySelector('.admin__queries .response .message');

                    if (messageEl) {
                        let formatter = new JSONFormatter(data, 0, {
                            animateOpen: false,
                            animateClose: false
                        });

                        if (data.length <= 10) {
                            formatter.openAtDepth(Infinity);
                        }

                        messageEl.appendChild(formatter.render());
                    }
                }, 200);
            })
            .catch(({ message }) => {
                setResponse({
                    query,
                    error: message
                });
            })
            .finally(() => {
                setQuery('');
            });
    };

    const retry = () => {
        if (response.query) {
            setQuery(response.query);
            prepare(response.query);
        }
    };

    const copy = () => {
        clipboard.write(response.query).then(() => {
            showToast({
                text: 'La requête a bien été copiée dans votre presse-papier !',
                duration: 1500
            });
        });
    };

    const addToHistory = (query = '') => {
        if (!query) {
            return;
        }

        setHistory((prevHistory) => {
            let newHistory = [...prevHistory];
            let queryIndex = newHistory.indexOf(query);

            if (queryIndex !== -1) {
                newHistory.splice(queryIndex, 1);
            }

            newHistory.unshift(query);

            return newHistory.slice(0, 10);
        });
    };

    const isError = () => {
        return !!response && !!response.error;
    };

    return (
        <div className="admin__queries">
            <div className="title">Exécuter une requête</div>

            <div className="form">
                <div className="query">
                    <Input.TextArea
                        placeholder={'Requête SQL'}
                        value={query}
                        rows={4}
                        autoSize={{ minRows: 4, maxRows: 25 }}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>

                {history.length > 0 && (
                    <div className="history">
                        <div className="label">ou choisir depuis l'historique :</div>

                        <div className="field">
                            <Select
                                options={
                                    history
                                        ? history.map((q) => {
                                              return {
                                                  label: q,
                                                  value: q
                                              };
                                          })
                                        : []
                                }
                                placeholder={'Sélectionner une requête précédente'}
                                onChange={(value) => {
                                    setQuery(value);
                                }}
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>
                    </div>
                )}

                <div className="secret">
                    <Input
                        type="password"
                        className="input"
                        placeholder="Clé de sécurité"
                        value={secret}
                        onChange={(e) => {
                            setSecret(e.target.value);
                        }}
                        autoComplete="new-password"
                    />
                </div>

                <div className="actions">
                    <div className="action">
                        <button
                            className="btn"
                            onClick={() => prepare()}
                            disabled={query === null || secret === null}
                        >
                            Exécuter
                        </button>
                    </div>
                </div>
            </div>

            {!!response && (
                <div className="response">
                    <div className="subtitle">{!isError() ? 'Résultat' : 'Erreur'}</div>

                    <div className="meta">
                        <div className="query code">
                            Sortie de la requête SQL : <i>{response.query}</i>
                        </div>

                        <div className="actions">
                            <div className="action">
                                <button className="btn outline" onClick={retry}>
                                    <i className="icon-button-refresh-arrow" aria-hidden="true"></i>{' '}
                                    Relancer
                                </button>
                            </div>

                            <div className="action">
                                <button className="btn outline" onClick={copy}>
                                    <i className="icon-copy-paste" aria-hidden="true"></i> Copier
                                </button>
                            </div>
                        </div>
                    </div>

                    {isError() ? (
                        <div className="message code">{response.error}</div>
                    ) : (
                        <div className="message"></div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Queries;
