import React from 'react';
import { usePost } from '../../providers/PostProvider';

const PostRecipient = () => {
    const { post, hasCustomFieldWithRecipient } = usePost();

    const renderRecipient = () => {
        let users = post.targetUsers.map((user) => {
            return `<span class="mention">@${user.name}</span>`;
        });

        return users.join('<span class="separator">,</span>');
    };

    return (
        <>
            {post.workspace.subscription_less &&
                post.targetUsers.length > 0 &&
                !hasCustomFieldWithRecipient() && (
                    <div className="post__recipient">
                        <span>Destinataire(s):</span>
                        <span dangerouslySetInnerHTML={{ __html: renderRecipient() }}></span>
                    </div>
                )}
        </>
    );
};

export default PostRecipient;
