import React, { createContext, useContext, useState } from 'react';
import { useTitle } from 'react-use';
import { useRouter } from '@uirouter/react';
import useHomeStateName from 'hooks/useHomeStateName';

const TopContext = createContext();

const TopProvider = ({ children }) => {
    const router = useRouter();
    const { globals } = router;
    const { getHomeStateName } = useHomeStateName();

    const [title, setTitle] = useState(null);
    const [hasSearchBar, setHasSearchBar] = useState(false);
    const [hasBackButton, setHasBackButton] = useState(false);
    const [actionButtons, setActionButtons] = useState([]);
    const [backButton, setBackButton] = useState({
        targetState: null,
        targetParams: null
    });

    useTitle(title);

    const backButtonClicked = () => {
        router.stateService.go(backButton.targetState, backButton.targetParams, {
            reload: true,
            inherit: false
        });
    };

    const addBackButton = (params) => {
        let { targetState, targetParams, fallbackState, fallbackParams } = params || {};

        const backButtonConfig = {
            targetState: null,
            targetParams: null
        };

        if (targetState) {
            backButtonConfig.targetState = targetState;
            backButtonConfig.targetParams = targetParams || {};
        } else {
            if (targetParams) {
                backButtonConfig.targetState = router.globals.current.name;
                backButtonConfig.targetParams = targetParams;
            } else {
                let previousState =
                    globals.transitionHistory.size > 1
                        ? globals.transitionHistory.peekTail(1)
                        : null;

                if (previousState && previousState.to() !== router.globals.current.name) {
                    backButtonConfig.targetState = previousState.to();
                    backButtonConfig.targetParams = previousState.params() || {};
                } else {
                    if (fallbackState) {
                        backButtonConfig.targetState = fallbackState;
                        backButtonConfig.targetParams = fallbackParams || {};
                    } else {
                        backButtonConfig.targetState = getHomeStateName();
                        backButtonConfig.targetParams = {};
                    }
                }
            }
        }

        setBackButton(backButtonConfig);
        setHasBackButton(true);
    };

    const clearBackButton = () => {
        setBackButton(null);
        setHasBackButton(false);
    };

    const actionButtonClicked = (actionButton) => {
        if (actionButton.callback !== null) {
            actionButton.callback();
        }

        actionButton.buttonState = !actionButton.buttonState;
    };

    const addActionButton = (params) => {
        let { icon = null, text = null, callback = null } = params || {};

        let actionButton = {
            icon: icon,
            text: text,
            callback: callback,
            buttonState: false
        };

        setActionButtons((prevActionButtons) => [actionButton, ...prevActionButtons]);
    };

    return (
        <TopContext.Provider
            value={{
                title,
                setTitle,
                setHasSearchBar,
                hasSearchBar,
                hasBackButton,
                setHasBackButton,
                backButtonClicked,
                addBackButton,
                actionButtons,
                setActionButtons,
                actionButtonClicked,
                addActionButton,
                clearBackButton
            }}
        >
            {children}
        </TopContext.Provider>
    );
};

export const useTop = () => useContext(TopContext);
export default TopProvider;
