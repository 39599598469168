import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from 'hooks/useViewport';
import { useRouter } from '@uirouter/react';
import { Select, Spin } from 'antd';
import 'rc-select/assets/index.css';
import { cloneDeep, debounce, find, forEach, has, set, sortBy } from 'lodash-es';
import useUserService from 'api/useUserService';
import { useQuery } from '@tanstack/react-query';
import useLevelService from 'api/useLevelService';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

const PhoneBookFilters = ({ q, selectedLevels, configDirectory }) => {
    const { t } = useTranslation();
    const viewport = useViewport();
    const router = useRouter();
    const { findEmployeeByCriteriaV2 } = useUserService();
    const { getLevels, getLevelConfigs } = useLevelService();

    const [searchValue, setSearchValue] = useState(
        q
            ? {
                  value: q,
                  label: q
              }
            : null
    );
    const [currentLevels, setCurrentLevels] = useState(selectedLevels);
    const [computedSelectedLevels, setComputedSelectedLevels] = useState({});

    const { data: dataLevels } = useQuery({ queryKey: [`all_levels`], queryFn: () => getLevels() });
    const { data: dataLevelConfigs } = useQuery({
        queryKey: [`level_configs`],
        queryFn: () => getLevelConfigs()
    });

    useEffect(() => {
        setCurrentLevels(selectedLevels);
        if (dataLevels && dataLevels.length > 0) {
            const tmpComputedSelectedLevels = {};
            forEach(selectedLevels, (currentLevel) => {
                let level = dataLevels.find((level) => {
                    return level.id === currentLevel;
                });

                if (level) {
                    if (tmpComputedSelectedLevels[level.index] === undefined) {
                        set(tmpComputedSelectedLevels, [level.index], []);
                    }

                    tmpComputedSelectedLevels[level.index].push(level);
                }
            });
            setComputedSelectedLevels(tmpComputedSelectedLevels);
        }
    }, [selectedLevels, dataLevels]);

    useEffect(() => {
        if (q) {
            setSearchValue({
                value: q,
                label: q
            });
        }
    }, [q]);

    const reset = () => {
        setSearchValue(null);
        setCurrentLevels([]);
    };

    const searchValueHandler = ({ value }) => {
        router.stateService.go(
            'auth.phonebookv2',
            { levels: currentLevels?.join(','), q: value },
            { reload: true, inherit: false }
        );
    };

    const selectLevelHandler = (newValue, levelIndex) => {
        const tmpComputedSelectedLevels = cloneDeep(computedSelectedLevels);
        let tmpSelectedLevels = [];

        tmpComputedSelectedLevels[levelIndex] = newValue;

        Object.keys(tmpComputedSelectedLevels).forEach((levelIndex) => {
            tmpSelectedLevels = tmpSelectedLevels.concat(
                tmpComputedSelectedLevels[levelIndex].map((l) => l.id || l)
            );
        });

        router.stateService.go(
            'auth.phonebookv2',
            { levels: tmpSelectedLevels.join(','), q: searchValue?.value },
            { reload: true, inherit: false }
        );
    };

    const fetchUserList = async (username) => {
        return findEmployeeByCriteriaV2(username, false).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.first_name} ${r.last_name}`,
                    value: `${r.first_name} ${r.last_name}`
                };
            });
        });
    };

    const groupLevelByIndex = () => {
        if (!dataLevels) {
            return {};
        }
        const levels = {};
        forEach(dataLevels, (level) => {
            if (!has(levels, [level.index])) {
                set(levels, [level.index], []);
            }

            levels[level.index].push(level);
        });

        return levels;
    };

    const levelIsShownInDirectory = (index) => {
        let levelConfig = find(dataLevelConfigs, { index: parseInt(index) });

        if (levelConfig && levelConfig.show_in_directory) {
            let isLevelInConfig = false;

            forEach(configDirectory, ({ displayConfigs }) => {
                let displayLevelConfigs = displayConfigs.filter((displayConfig) => {
                    return displayConfig.type === 'levelConfig';
                });

                isLevelInConfig = displayLevelConfigs.some((displayLevelConfig) => {
                    return displayLevelConfig.index === levelConfig.index;
                });

                if (isLevelInConfig) {
                    return false;
                }
            });

            return isLevelInConfig;
        }

        return false;
    };

    const getLevelName = (index) => {
        let levelConfig = find(dataLevelConfigs, { index: parseInt(index) });

        if (levelConfig) {
            return levelConfig.name;
        }

        return `Level ${index}`;
    };

    return (
        <div className="filters">
            <div className="header">
                {viewport.gtXs && <div className="title">{t('SEARCH_FILTER_BUTTON')}</div>}
                {currentLevels.length > 0 ||
                    (searchValue !== null && (
                        <div
                            className="reset-filter"
                            ng-if="$ctrl.currentLevels > 0 || $ctrl.searchValue !== null"
                            onClick={reset}
                        >
                            {t('RESET_FILTERS')}
                            <i className="icon-remove-circle" aria-hidden="true"></i>
                        </div>
                    ))}
            </div>

            <div className="filter search">
                <DebounceSelect
                    showSearch={true}
                    size={'large'}
                    placeholder={t('PHONE_BOOK_USER_SEARCH_PLACEHOLDER')}
                    fetchOptions={fetchUserList}
                    value={searchValue}
                    onChange={(newValue) => {
                        searchValueHandler(newValue);
                    }}
                    style={{
                        width: '100%'
                    }}
                />
            </div>

            {dataLevels &&
                dataLevelConfigs &&
                Object.entries(groupLevelByIndex()).map(([levelIndex, levels]) => {
                    if (!levelIsShownInDirectory(levelIndex)) return;
                    return (
                        <div className="filter default by-levels" key={`select_${levelIndex}`}>
                            <Select
                                options={sortBy(
                                    levels.map((level) => {
                                        return {
                                            label: level.name,
                                            value: level.id
                                        };
                                    }),
                                    ['label']
                                )}
                                value={computedSelectedLevels[levelIndex]?.map((level) => {
                                    return {
                                        label: level.name,
                                        value: level.id
                                    };
                                })}
                                onChange={(newValue) => {
                                    selectLevelHandler(newValue, levelIndex);
                                }}
                                placeholder={getLevelName(levelIndex)}
                                mode={'multiple'}
                                size={'large'}
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default PhoneBookFilters;
