import React, { createElement, useCallback, useState } from 'react';
import Header from './partials/Header';
import Actions from './partials/Actions';
import Sidebar from './partials/Tables/Sidebar';
import { useFeedContext } from '../providers/FeedProvider';
import { useQuery } from '@tanstack/react-query';
import usePostService from 'api/usePostService';
import base64 from 'base-64';
import { useCurrentStateAndParams } from '@uirouter/react';
import useTableComponent from './partials/Tables/hooks/useTableComponent';
import CommentsCeil from './partials/Tables/partials/Comments';
import DateCeil from './partials/Tables/partials/Date';
import LikesCeil from './partials/Tables/partials/Likes';
import StatusCeil from './partials/Tables/partials/Status';
import TextCeil from './partials/Tables/partials/Text';
import UserCeil from './partials/Tables/partials/User';
import ViewsCeil from './partials/Tables/partials/Views';
import { orderBy } from 'lodash-es';
import { useModal } from '../../../providers/ModalContext';

const partials = {
    comments: CommentsCeil,
    date: DateCeil,
    likes: LikesCeil,
    status: StatusCeil,
    text: TextCeil,
    user: UserCeil,
    views: ViewsCeil
};

const Tables = () => {
    const [limit, setLimit] = useState(100);
    const [sort, setSort] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const { workspace, initialFilters } = useFeedContext();
    const { params } = useCurrentStateAndParams();
    const { openModal } = useModal();
    const { getPostById } = usePostService();
    const { getTableRows } = usePostService();
    const { isConfigDisplayed, getTemplateCeil, getNumberCeil } = useTableComponent(
        workspace,
        initialFilters
    );

    const { data: posts, isLoading } = useQuery({
        queryKey: ['postsTable', workspace?.id, base64.encode(params.filters)],
        queryFn: () => {
            if (workspace && params.filters) {
                const filterString = base64.encode(params.filters);
                return getTableRows(workspace.id, filterString);
            } else {
                return [];
            }
        }
    });

    const sortBy = (config) => {
        if (sort === config.type) {
            setSortDirection((prev) => {
                return prev === 'asc' ? 'desc' : 'asc';
            });
        } else {
            setSort(config.type);
            setSortDirection('asc');
        }
    };

    const limitPosts = useCallback(() => {
        if (posts && posts.posts) {
            return sortPosts(posts.posts).slice(0, limit);
        }

        return [];
    }, [posts, sort, sortDirection, limit]);

    const getCeilValue = (post, inputId) => {
        return post[inputId];
    };

    const getSum = (number) => {
        return posts.posts
            .filter((post) => getCeilValue(post, number.type) !== '')
            .map((post) => {
                return getCeilValue(post, number.type);
            })
            .reduce((ac, value) => {
                return ac + value;
            });
    };

    const getAVG = (number) => {
        const totalRows = posts.posts.filter(
            (post) => getCeilValue(post, number.type) !== ''
        ).length;
        if (totalRows) {
            return parseFloat(getSum(number) / totalRows).toFixed(2);
        }
        return 0;
    };

    const sortPosts = (posts) => {
        let type = sort;
        if (!type) {
            return posts;
        }
        if (type.indexOf('input') === 0) {
            type = 'input';
        }
        switch (type) {
            case 'author':
                return orderBy(posts, ['author.last_name'], [sortDirection]);
            case 'status':
                return orderBy(posts, ['status.label'], [sortDirection]);
            case 'created_at':
                return orderBy(posts, [(obj) => new Date(obj.created_at)], [sortDirection]);
            case 'updated_at':
                return orderBy(posts, [(obj) => new Date(obj.updated_at)], [sortDirection]);
            case 'input':
            case 'comments':
            case 'views':
            case 'likes':
                return orderBy(posts, [sort], [sortDirection]);
        }
    };

    const previewPost = (id) => {
        getPostById(id).then((data) => {
            openModal('preview-post', {
                post: data[0].post
            });
        });
    };

    return (
        <div className={'tables-root'}>
            <div>
                <Header hideInfo />
                <Actions />
            </div>

            <div className="table-wrapper">
                {initialFilters && workspace && <Sidebar initialFilters={initialFilters} />}
                {workspace && initialFilters && (
                    <div className="content">
                        <div className="view">
                            {initialFilters.length === 0 ? (
                                <div className="no-filters">
                                    Merci de sélectionner au moins un filtre
                                </div>
                            ) : posts && posts.length === 0 ? (
                                <div className="no-result">Aucun résulat</div>
                            ) : (
                                <>
                                    <table>
                                        <tr>
                                            <th style={{ width: 45 }}></th>
                                            {workspace.table_configs &&
                                                workspace.table_configs.map((config) => {
                                                    if (!isConfigDisplayed(config)) {
                                                        return;
                                                    }
                                                    return (
                                                        <th
                                                            className="header"
                                                            key={`header_${config.name}`}>
                                                            <button onClick={() => sortBy(config)}>
                                                                {config.name}
                                                                <i className="fa fa-chevron-down"></i>
                                                            </button>
                                                        </th>
                                                    );
                                                })}
                                        </tr>
                                        {limitPosts().map((post, index) => {
                                            return (
                                                <tr>
                                                    <td
                                                        className="text-center"
                                                        style={{ width: 45 }}>
                                                        <button
                                                            onClick={() => previewPost(post.id)}>
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                    </td>
                                                    {workspace.table_configs &&
                                                        workspace.table_configs.map((config) => {
                                                            if (!isConfigDisplayed(config)) {
                                                                return;
                                                            }
                                                            return (
                                                                <td
                                                                    key={`row_${config.name}_${index}`}
                                                                    className="ceil">
                                                                    {createElement(
                                                                        partials[
                                                                            getTemplateCeil(config)
                                                                        ],
                                                                        {
                                                                            config,
                                                                            row: post
                                                                        }
                                                                    )}
                                                                </td>
                                                            );
                                                        })}
                                                </tr>
                                            );
                                        })}
                                    </table>
                                    {posts &&
                                        posts.posts.length > 0 &&
                                        getNumberCeil() &&
                                        getNumberCeil().length > 0 && (
                                            <div className="number_results">
                                                {getNumberCeil().map((number, index) => {
                                                    return (
                                                        <div
                                                            className="stats"
                                                            key={`number_${index}`}>
                                                            <div className="row">
                                                                <div className="label">
                                                                    Somme "{number.name}"
                                                                </div>
                                                                <div className="separator"> :</div>
                                                                <div className="number">
                                                                    {getSum(number)}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="label">
                                                                    Moyenne "{number.name}"
                                                                </div>
                                                                <div className="separator"> :</div>
                                                                <div className="number">
                                                                    {getAVG(number)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tables;
