import useWidgetComponent from '../hooks/useWidgetComponent';
import useUtils from '../../../hooks/useUtils';
import { useEffect, useState } from 'react';
import { last } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import TreeModal from '../../../pages/Auth/modals/TreeModal';

const Tree = ({ widget, options }) => {
    const { t } = useTranslation();
    const { getFieldValueForKey, getStyleValueForKey } = useWidgetComponent(widget, options);
    const { getFirstLetter } = useUtils();
    const [showTree, setShowTree] = useState(false);
    const [openingFromClass, setOpeningFromClass] = useState(null);
    const [currentBreadcrumb, setCurrentBreadcrumb] = useState([]);

    const hasBreadcrumb = () => {
        return currentBreadcrumb && currentBreadcrumb.length > 0;
    };

    const hasBreadcrumbChildren = () => {
        let children = getBreadcrumbChildren();

        if (!children) {
            return false;
        }

        return children.length > 0;
    };

    const getBreadcrumbChildren = () => {
        return last(currentBreadcrumb).children;
    };

    useEffect(() => {
        if (showTree) {
            setOpeningFromClass('from-right');
        } else {
            setOpeningFromClass(null);
        }
    }, [showTree]);

    const toggleTree = () => {
        setShowTree(!showTree);
    };

    const getWidgetStyle = () => {
        return {
            margin: `${getStyleValueForKey('marginTopSize')}px 0 ${getStyleValueForKey('marginBottomSize')}px 0`
        };
    };

    return (
        <div className="tree" style={getWidgetStyle()}>
            <div className="meta" onClick={toggleTree}>
                <div className="icon">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.3332 2.5H1.6665L8.33317 10.3833V15.8333L11.6665 17.5V10.3833L18.3332 2.5Z"
                            stroke="#373A4D"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>

                <div className="title">{getFieldValueForKey('title')}</div>

                {!(getFieldValueForKey('hideStroke') && hasBreadcrumb()) && (
                    <div className="icon">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.37487 5.46873C9.47768 5.46813 9.57961 5.48784 9.67479 5.52672C9.76998 5.5656 9.85655 5.62288 9.92955 5.69529L16.1796 11.9453C16.2528 12.0179 16.3109 12.1043 16.3506 12.1995C16.3902 12.2947 16.4106 12.3968 16.4106 12.5C16.4106 12.6031 16.3902 12.7052 16.3506 12.8004C16.3109 12.8956 16.2528 12.982 16.1796 13.0547L9.92955 19.3047C9.78244 19.4518 9.58291 19.5344 9.37486 19.5344C9.16682 19.5344 8.96729 19.4518 8.82018 19.3047C8.67307 19.1576 8.59042 18.958 8.59042 18.75C8.59042 18.5419 8.67307 18.3424 8.82018 18.1953L14.5233 12.5L8.82018 6.80466C8.74695 6.73204 8.68883 6.64563 8.64917 6.55043C8.60951 6.45523 8.58909 6.35311 8.58909 6.24998C8.58909 6.14684 8.60951 6.04473 8.64917 5.94953C8.68883 5.85432 8.74695 5.76792 8.82018 5.69529C8.89318 5.62288 8.97975 5.5656 9.07494 5.52672C9.17012 5.48784 9.27205 5.46813 9.37487 5.46873Z"
                                fill="#373A4D"
                            />
                        </svg>
                    </div>
                )}
            </div>

            {hasBreadcrumb() && (
                <div className="current-tree">
                    <div className="breadcrumb">
                        {currentBreadcrumb.map((item, index) => {
                            return (
                                <div className="item" key={`bread_crumb_${index}`}>
                                    <a
                                        className="meta"
                                        ng-href="{ getItemLink(item) }"
                                        target="_blank">
                                        <div className="name">{item.name}</div>
                                    </a>

                                    <div className="separator">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.49979 4.37488C7.58205 4.37441 7.66359 4.39017 7.73974 4.42128C7.81589 4.45238 7.88514 4.49821 7.94354 4.55613L12.9435 9.55614C13.0021 9.61424 13.0486 9.68336 13.0804 9.75952C13.1121 9.83569 13.1284 9.91738 13.1284 9.99988C13.1284 10.0824 13.1121 10.1641 13.0804 10.2402C13.0486 10.3164 13.0021 10.3855 12.9435 10.4436L7.94354 15.4436C7.82585 15.5613 7.66623 15.6274 7.49979 15.6274C7.33336 15.6274 7.17373 15.5613 7.05604 15.4436C6.93835 15.3259 6.87224 15.1663 6.87224 14.9999C6.87224 14.8334 6.93836 14.6738 7.05604 14.5561L11.6185 9.99988L7.05605 5.44363C6.99747 5.38553 6.95097 5.31641 6.91924 5.24024C6.88751 5.16408 6.87117 5.08239 6.87117 4.99988C6.87117 4.91738 6.88751 4.83569 6.91924 4.75952C6.95097 4.68336 6.99747 4.61424 7.05605 4.55613C7.11445 4.49821 7.1837 4.45238 7.25985 4.42128C7.336 4.39017 7.41754 4.37441 7.49979 4.37488Z" />
                                        </svg>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {hasBreadcrumbChildren() && (
                        <div className="children">
                            {getBreadcrumbChildren().map((child, index) => {
                                return (
                                    <a
                                        className="children"
                                        ng-href="{ getChildLink(child) }"
                                        target="_blank"
                                        key={`child_breadcrumb_${index}`}>
                                        {child.category && (
                                            <div className="child category">
                                                <div className="name">{child.category.name}</div>
                                            </div>
                                        )}

                                        {child.workspace_data && (
                                            <>
                                                <div className="child workspace">
                                                    <div
                                                        className="icon"
                                                        style={{
                                                            'background-color':
                                                                child.workspace_data.workspace
                                                                    .icon_color
                                                        }}>
                                                        <div className="letter">
                                                            {getFirstLetter(
                                                                child.workspace_data.workspace.name
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="name">
                                                        {child.workspace_data.workspace.name}
                                                    </div>
                                                </div>
                                                <div className="child folder">
                                                    <div className="icon">
                                                        <i
                                                            className="icon-office-folder"
                                                            aria-hidden="true"></i>
                                                    </div>
                                                    <div className="name">{child.name}</div>
                                                </div>
                                            </>
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    )}

                    <div className="actions">
                        <div className="action" ng-click="removeFilters()">
                            <i className="icon-remove-circle" aria-hidden="true"></i>{' '}
                            {t('SPRINGBOARD_TREE_REMOVE_FILTERS')}
                        </div>
                    </div>
                </div>
            )}

            <div className={`overflow-wrapper ${openingFromClass}`} hidden={!showTree}>
                <div className="tree-backdrop" onClick={() => setShowTree(false)}></div>
                <div className="tree-wrapper">
                    <div className="scrollable-wrapper">
                        <div className="items-wrapper">
                            <TreeModal
                                page={'tree'}
                                options={{ isSidebar: true }}
                                className={'tree-modal'}
                                name={'tree_modal_widget'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tree;
