import React from 'react';

export default (props) => {
    return (
        <div className="item">
            <div className="row">
                <div className="icon" style={{ width: '50px', height: '50px' }}></div>

                <div className="column">
                    <div className="text" style={{ width: '45%' }}></div>
                    <div className="text" style={{ width: '85%' }}></div>
                    <div className="text" style={{ width: '35%' }}></div>
                </div>
            </div>
        </div>
    );
};
