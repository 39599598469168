import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { Mission, Product, Social } from './customRender';
import { useQuery } from '@tanstack/react-query';
import useMissionService from '../api/useMissionCampaignService';
import useProductCampaignService from '../api/useProductCampaignService';
import useSocialCampaignService from '../api/useSocialCampaignService';
import Filters from './Filters';
import React, { createElement } from 'react';
import Breadcrumb from './Breadcrumb';
import Recruiting from './customRender/recruiting';
import Survey from './customRender/survey';
import useRecruitingCampaignService from '../api/useRecruitingCampaignService';
import useSurveyCampaignService from '../api/useSurveyCampaignService';
import { Pagination } from 'antd';

const RenderDynamicPage = () => {
    const { params } = useCurrentStateAndParams();
    const { type, status, page } = params;
    const router = useRouter();

    const { getAllProductCampaign } = useProductCampaignService();
    const { getAllDashboardMissionCampaign } = useMissionService();
    const { getAllSocialNetworkCampaign } = useSocialCampaignService();
    const { getAllRecruitingCampaign } = useRecruitingCampaignService();
    const { getAllSurveyCampaign } = useSurveyCampaignService();

    const handleFilter = (params) => {
        router.stateService.go('.', params);
    };

    // TODO: not has role redirect to auth.feed.posts

    const customRender = {
        product: {
            hasRole: ['ROLE_PRODUCT'],
            component: Product,
            queryFn: () => getAllProductCampaign(status, page, null, true),
            pageSize: 10
        },
        social: {
            hasRole: ['ROLE_RS'],
            component: Social,
            queryFn: () => getAllSocialNetworkCampaign(status, page, true),
            pageSize: 10
        },
        recruiting: {
            hasRole: ['ROLE_RECRUITING'],
            component: Recruiting,
            queryFn: () => getAllRecruitingCampaign(status, page, true),
            pageSize: 10
        },
        survey: {
            hasRole: ['ROLE_SURVEY'],
            component: Survey,
            queryFn: () => getAllSurveyCampaign(status, page, true),
            pageSize: 5
        },
        mission: {
            component: Mission,
            addFilters: [
                {
                    value: 'expired',
                    label: 'TA_CAMPAIGNS_IS_EXPIRED'
                }
            ],
            queryFn: () => getAllDashboardMissionCampaign(status, page)
        }
    };

    const { data, isLoading: isLoadingCampaigns } = useQuery({
        queryKey: [`dashboard-campaigns`, type, status, page],
        queryFn: () => customRender[type].queryFn()
    });

    const renderComponent = customRender[type].component;

    const campaigns = data?.campaigns;
    const paging = data?.paging;

    return (
        <>
            <Breadcrumb />
            <Filters extraFilters={customRender[type]?.addFilters || []} />

            <div className="campaigns layout-column">
                {!isLoadingCampaigns &&
                    campaigns?.map((campaign) => (
                        <div key={campaign.id}>{createElement(renderComponent, { campaign })}</div>
                    ))}
            </div>
            {paging && (campaigns?.length > 0 || (campaigns?.length === 0 && page !== 1)) && (
                <div className="pagination">
                    <Pagination
                        defaultCurrent={params.page}
                        pageSize={customRender[type].pageSize}
                        total={paging.total}
                        showSizeChanger={false}
                        onChange={(page) => {
                            handleFilter({ ...params, page });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default RenderDynamicPage;
