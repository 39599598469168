import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React, { useState, useEffect, useCallback } from 'react';
import { useModal } from 'providers/ModalContext';
import useViewport from 'hooks/useViewport';
import Post from 'components/Post/Post';
import { useDevice } from 'providers/DeviceContext';
import ResizableImage from 'components/ResizableImage/ResizableImage';
import useAttachment from 'hooks/useAttachment';

const photoExtensions = ['.jpg', '.jpeg', '.webp', '.gif', '.png'];

const CarouselModalContent = () => {
    const { t } = useTranslation();
    const { forgeExternalAttachmentUrl, forgeAttachmentUrl } = useAttachment();
    const { device } = useDevice();
    const viewport = useViewport();
    const [currentIndex, setCurrentIndex] = useState(null);
    const {
        contexts: {
            ['carousel']: {
                images = [],
                post = null,
                legend = null,
                index = 0,
                post_consultations = 0,
                external = false
            }
        }
    } = useModal();

    useEffect(() => {
        setCurrentIndex(index);
    }, [index]);

    const getImageWidth = (selectedImage) => {
        let width = Math.ceil(document.querySelector(selectedImage).getBoundingClientRect().width);

        if (width === 0) {
            return '100%';
        }

        return width.toString() + 'px';
    };

    const alignAbsoluteLegendWithImage = (selectedImage) => {
        let imageWidth = Math.ceil(
            document.querySelector(selectedImage).getBoundingClientRect().width
        );

        if (imageWidth === 0) {
            return '0';
        }

        let leftForLegend = imageWidth / 2;

        return `calc(50% - ${leftForLegend.toString()}px)`;
    };

    const isPhotoFile = (url) => {
        url = url.toLowerCase();

        return photoExtensions.some((ext) => {
            return url.indexOf(ext) !== -1;
        });
    };

    const forgeAttachmentUrlCarousel = (attachment, full = true) => {
        if (attachment.hasOwnProperty('url') && isPhotoFile(attachment.url)) {
            return attachment.url;
        }

        if (images[currentIndex] && images[currentIndex].picture_url) {
            return images[currentIndex].picture_url;
        }

        if (external) {
            return forgeExternalAttachmentUrl(attachment);
        }

        return forgeAttachmentUrl(attachment, full);
    };

    const getImageUrl = (image, full = true) => {
        if (!image) {
            return null;
        }
        if (image?.native) {
            return image.native.$ngfBlobUrl;
        }

        if (device.isApp) {
            return forgeAttachmentUrlCarousel(image, false);
        }

        return forgeAttachmentUrlCarousel(image, full);
    };

    const getBackgroundImageUrl = (image) => {
        return `url(${getImageUrl(image, false)})`;
    };

    const nextImage = () => {
        if (currentIndex + 1 === images.length) {
            setCurrentIndex(0);
            return;
        }

        setCurrentIndex(currentIndex + 1);
    };

    const previousImage = () => {
        if (currentIndex - 1 === -1) {
            setCurrentIndex(images.length - 1);
            return;
        }

        setCurrentIndex(currentIndex - 1);
    };

    const getLegend = useCallback(() => {
        let image = images[currentIndex];

        if (image?.legend) {
            return image.legend;
        }
        return legend;
    }, [currentIndex, images, legend]);

    return (
        <div className={`carousel-content ${post ? 'has-post' : ''}`}>
            {images.length > 0 && currentIndex !== null && (
                <div className="carousel">
                    <div className="arrow-container prev" hidden={images.length === 1}>
                        <button onClick={previousImage}>
                            <i className="arrow fa fa-chevron-left"></i>
                        </button>
                    </div>

                    <div className={`image__container ${legend ? 'with-legend' : ''}`}>
                        <ResizableImage src={getImageUrl(images[currentIndex])}></ResizableImage>
                    </div>

                    {getLegend() && (
                        <div
                            className="legend"
                            style={{
                                width: getImageWidth('resizable-image img'),
                                left: alignAbsoluteLegendWithImage('resizable-image img')
                            }}>
                            {getLegend()}
                        </div>
                    )}

                    <div className="image__preview">
                        {images.map((image, index) => {
                            return (
                                <div
                                    onClick={() => setCurrentIndex(index)}
                                    className={index === currentIndex ? 'selected' : ''}>
                                    <div
                                        style={{
                                            backgroundImage: getBackgroundImageUrl(image)
                                        }}></div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="arrow-container next" hidden={images.length === 1}>
                        <button onClick={nextImage}>
                            <i className="arrow fa fa-chevron-right"></i>
                        </button>
                    </div>

                    <div className="download">
                        <button ng-click="$ctrl.downloadAttachment($ctrl.Carousel.images[$ctrl.Carousel.index])">
                            <i className="icon-download-bottom" aria-hidden="true"></i>{' '}
                            {t('SHARED_DOCUMENT_RESOURCE_DOWNLOAD_LABEL')}
                        </button>
                    </div>
                </div>
            )}

            {post && viewport.gtSm && (
                <div className="carousel-post">
                    <Post
                        className="post"
                        inCarousel
                        post={post}
                        already-liked={post.alreadyLiked}
                        download={post.download}
                        consultations={post_consultations}
                        tagId={post.tag_id}
                        tags={post.tags}></Post>
                </div>
            )}
        </div>
    );
};

const CarouselModal = () => {
    return (
        <div className="carousel-modal-root">
            <Modal
                name="carousel"
                title={'Images'}
                options={{ isFullscreen: true, hasPaddedContent: true, zIndex: 400 }}>
                <CarouselModalContent />
            </Modal>
        </div>
    );
};

export default CarouselModal;
