import { useQuery } from '@tanstack/react-query';
import useTagService from 'api/useTagService';
import { Select } from 'components/Form/Inputs';

const Tags = ({ field, fieldName, ...props }) => {
    const { getAllAdminTags } = useTagService();
    const { data: tags } = useQuery({
        queryKey: ['tags'],
        queryFn: () => getAllAdminTags()
    });
    return (
        <Select
            shouldUnregister={false}
            mode={'multiple'}
            name={fieldName}
            options={
                tags
                    ? tags.map((tag) => {
                          return {
                              label: tag.name,
                              value: tag.id
                          };
                      })
                    : []
            }
            {...props}
        />
    );
};

export default Tags;
