import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useRouter } from '@uirouter/react';
import { useItemNavbarContext } from '../providers/ItemProvider';

const AbstractClickableItemNavbar = ({ children, ...props }) => {
    if (props.href) {
        return <a {...props}>{children}</a>;
    }

    return <button {...props}>{children}</button>;
};

export default function ItemNavbar({ item, state, context, ...props }) {
    const router = useRouter();
    const { isClicked, setIsClicked } = useItemNavbarContext();

    useEffect(() => {
        const handleTransitionStart = () => {
            setIsClicked(false);
        };
        const deregisterOnStart = router.transitionService.onStart({}, handleTransitionStart);

        return () => {
            deregisterOnStart();
        };
    }, [router]);

    if (!state) {
        return;
    }

    const href = router.stateService.href(state.name, {}, { inherit: false });
    const onClick = props.onClick ? props.onClick : state.callback ? state.callback : null;
    const isActive = state.isActive(context, state);
    const hasBadge = state.hasBadge && state.hasBadge(context, state);
    const icon = state.icon ? state.icon : state.getIcon ? state.getIcon(context, state) : null;
    const label = state.label
        ? state.label
        : state.getLabel
          ? state.getLabel(context, state)
          : null;

    return (
        <>
            {isClicked && <div className="backdrop" onClick={() => setIsClicked(false)}></div>}
            <AbstractClickableItemNavbar
                className={classNames([
                    'link',
                    { 'has-image': !!state.image },
                    { active: isActive }
                ])}
                href={!onClick && href ? href : 'javascript:;'}
                onClick={() => {
                    if (!state.children) {
                        setIsClicked(!isClicked);
                    }
                    if (onClick) {
                        onClick(context, state);
                    }
                }}>
                {hasBadge && state.getBadge && (
                    <div className="badge">{state.getBadge(context, state)}</div>
                )}

                {icon && (
                    <div className="icon">
                        <i className={icon} aria-hidden="true"></i>
                    </div>
                )}

                {state.image && (
                    <div className="image">
                        <img src={state.image} />
                    </div>
                )}

                {label && <div className="label">{label}</div>}
            </AbstractClickableItemNavbar>
            {!!state.template && isClicked && <div className={`template`}>{state.template}</div>}
        </>
    );
}
