import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useCurrentStateAndParams } from '@uirouter/react';
import useChannelService from '../../../api/useChannelService';
import { get } from 'lodash-es';

const ChannelContext = createContext();

const ChannelProvider = ({ children }) => {
    const { getChannelInfo } = useChannelService();
    const { params } = useCurrentStateAndParams();
    const { id } = params;

    const { data: channelInfo } = useQuery({
        queryKey: ['channel_info', id],
        queryFn: () => {
            return getChannelInfo(id);
        }
    });

    const isAdmin = useCallback(
        (user) => {
            if (isOwner(user)) {
                return true;
            }

            return (
                user.is_admin ||
                channelInfo.users.some((channelUser) => {
                    return channelUser.id === user.id && channelUser.is_admin;
                })
            );
        },
        [channelInfo]
    );

    const isOwner = useCallback(
        (user) => {
            let ownerId = get(channelInfo, ['channel', 'owner', 'id'], null);

            return user.id === ownerId || user.is_owner;
        },
        [channelInfo]
    );

    return (
        <ChannelContext.Provider
            value={{
                channelInfo,
                isAdmin,
                isOwner
            }}
        >
            {children}
        </ChannelContext.Provider>
    );
};

export const useChannelContext = () => useContext(ChannelContext);
export default ChannelProvider;
