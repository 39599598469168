import { isAudioAttachment, isVideoAttachment } from './index';

export default function getAllVideosAndAudios(attachments) {
    let videosAndAudios = [];

    attachments.forEach((attachment) => {
        if (isVideoAttachment(attachment) || isAudioAttachment(attachment)) {
            videosAndAudios.push(attachment);
        }
    });

    return videosAndAudios;
}
