import { Range as RangeInput } from 'components/Form/Inputs';

const Range = ({ field, fieldName, ...props }) => {
    return (
        <RangeInput
            name={fieldName}
            shouldUnregister={false}
            min={field.min}
            max={field.max}
            {...props}
        />
    );
};

export default Range;
