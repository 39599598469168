import React from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useFeedContext } from '../providers/FeedProvider';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import usePostService from 'api/usePostService';
import Post from 'components/Post/Post';
import { useAuth } from 'providers/AuthContext';
import { indexOf, concat } from 'lodash-es';
import useCommentService from 'api/useCommentService';
import CommentRender from '../../../components/Comments/components/CommentRender';
import classes from 'classnames';

const Review = () => {
    const router = useRouter();
    const { params } = useCurrentStateAndParams();
    const { hasPostStatus } = useFeedContext();
    const { t } = useTranslation();
    const { getPostsWaitingForReview, getPostsScheduledForReview } = usePostService();
    const { getCommentsWaitingForReview } = useCommentService();
    const { user } = useAuth();

    const { data: posts } = useQuery({
        queryFn: getPostsWaitingForReview,
        queryKey: ['posts', 'waiting_for_review']
    });

    const { data: scheduledPosts } = useQuery({
        queryFn: getPostsScheduledForReview,
        queryKey: ['posts', 'scheduled_posts']
    });

    const { data: comments } = useQuery({
        queryFn: getCommentsWaitingForReview,
        queryKey: ['comments', 'waiting_for_review']
    });

    const getCommentObject = (comment) => {
        if (comment.post) {
            return comment.post;
        }

        if (comment.campaign) {
            return comment.campaign;
        }
    };

    return (
        <>
            <div className="review-title">Modération des actualités</div>

            <div className="review-tabs">
                <a
                    className={`tab ${params.tab === 'posts' ? 'active' : ''}`}
                    href={router.stateService.href('auth.feed.review', { tab: 'posts' })}
                >
                    À modérer
                </a>
                <a
                    className={`tab ${params.tab === 'scheduled-posts' ? 'active' : ''}`}
                    href={router.stateService.href('auth.feed.review', { tab: 'scheduled-posts' })}
                >
                    À venir
                </a>
                <a
                    className={`tab ${params.tab === 'comments' ? 'active' : ''}`}
                    href={router.stateService.href('auth.feed.review', { tab: 'comments' })}
                    ng-click="setReviewTab('comments')"
                >
                    Commentaires
                </a>
            </div>

            <div className="feed">
                {/*
                !isAdminTag ? 
                */}
                {params.tab === 'posts' &&
                    posts &&
                    posts.map(
                        ({ post, download, alreadyLiked, post_consultations, tags_id }, index) => {
                            return (
                                <div className="post_container" key={`post_${post.id}`}>
                                    <Post
                                        post={post}
                                        key={post.id}
                                        alreadyLiked={alreadyLiked}
                                        download={download}
                                        consultations={post_consultations}
                                        tagsId={tags_id}
                                    />

                                    {indexOf(
                                        concat(user.unread_posts, user.own_unread_posts),
                                        post.id
                                    ) !== -1 &&
                                        concat(user.unread_posts, user.own_unread_posts).length >
                                            0 &&
                                        concat(user.unread_posts, user.own_unread_posts).length ===
                                            index + 1 && (
                                            <div className="up-to-date">
                                                <div className="icon">
                                                    <div className="circle">
                                                        <i className="icon-check-circle-1-filled"></i>
                                                    </div>
                                                </div>

                                                <p className="text">{t('UP_TO_DATE')}</p>
                                            </div>
                                        )}
                                </div>
                            );
                        }
                    )}

                {params.tab === 'scheduled-posts' &&
                    scheduledPosts &&
                    scheduledPosts.map(
                        ({ post, download, alreadyLiked, post_consultations, tags_id }) => {
                            return (
                                <div className="post_container" key={`scheduled-post_${post.id}`}>
                                    <Post
                                        post={post}
                                        key={post.id}
                                        alreadyLiked={alreadyLiked}
                                        download={download}
                                        consultations={post_consultations}
                                        tagsId={tags_id}
                                    />
                                </div>
                            );
                        }
                    )}

                {params.tab === 'comments' && (
                    <div className="comment_container">
                        <div className="comments" style={{ background: 'transparent' }}>
                            {comments &&
                                comments.map((comment) => {
                                    return (
                                        <CommentRender
                                            CommentRender
                                            className={`comment`}
                                            comment={comment}
                                            object={getCommentObject(comment)}
                                            key={`comment_${comment.id}`}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Review;
