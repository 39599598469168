import React, { createContext, useContext, useState, useEffect } from 'react';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { Badge } from '@capawesome/capacitor-badge';
import { useConfig } from './ConfigProvider';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
    const { config } = useConfig();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [keyboardWillShow, setKeyboardWillShow] = useState(false);

    const [device, setDevice] = useState({
        initialized: false,
        platform: 'web',
        playerId: null,
        pushToken: null,
        wonderpush: false,
        isKeyboardOpen: false,
        networkState: null,
        scrollableEl: null,
        scrollableElScrollTop: 0,
        lastScrollDirection: null,
        topEl: null
    });

    useEffect(() => {
        App.getInfo()
            .then((appData) => {
                Device.getInfo().then((deviceData) => {
                    setDevice((prevDevice) => ({
                        ...prevDevice,
                        ...appData,
                        initialized: true,
                        isApp: true,
                        platform: deviceData.platform,
                        intAppVersion: appData.version.replace(/\./g, '')
                    }));
                });
            })
            .catch(() => {
                setDevice((prevDevice) => ({
                    ...prevDevice,
                    initialized: true,
                    isApp: false,
                    platform: 'web',
                    version: 'web'
                }));
            });
    }, []);

    useEffect(() => {
        if (device.initialized && device.isApp) {
            configureNetwork();
            configureApp();
            configureStatusBar();
            configureKeyboard();
            configureBadge();
        }
    }, [device.initialized]);

    useEffect(() => {
        if (!scrollPosition || !document.querySelector('.auth > .top-wrapper')) {
            return;
        }

        if (!isScrolling) {
            setIsScrolling(true);
            document.querySelector('.auth > .top-wrapper').classList.add('out');
        }

        if (keyboardShown) {
            Keyboard.hide();
            setKeyboardShown(false);
        }

        const timeout = setTimeout(handleScrollStop, 200);

        return () => clearTimeout(timeout);
    }, [scrollPosition]);

    const handleScrollStop = () => {
        document.querySelector('.auth > .top-wrapper').classList.remove('out');
        setIsScrolling(false);
    };

    const configureNetwork = () => {
        Network.getStatus().then((status) => {
            setDevice((prevDevice) => ({ ...prevDevice, networkState: status.connected }));
        });

        Network.addListener('networkStatusChange', (status) => {
            setDevice((prevDevice) => ({ ...prevDevice, networkState: status.connected }));
        });
    };

    const configureApp = () => {
        App.addListener('appStateChange', (state) => {
            // Handle app state change
        });

        App.addListener('backButton', () => {
            // Handle back button
        });
    };

    const configureStatusBar = () => {
        StatusBar.setStyle({ style: Style.Dark });
        StatusBar.setBackgroundColor({ color: config.theme_color });
    };

    const configureKeyboard = () => {
        if (device.platform === 'ios') {
            Keyboard.setAccessoryBarVisible({ isVisible: false });
        }

        window.addEventListener('keyboardWillShow', (e) => {
            setKeyboardWillShow(true);

            if (device.platform === 'ios') {
                if (undefined === e.keyboardHeight) {
                    return;
                }

                loop(e);
            } else {
                // TODO: tester sur Android
                // TODO: tester sur Android
                // TODO: tester sur Android
                document.querySelector(':focus').scrollIntoViewIfNeeded();
            }

            setDevice((prevDevice) => ({ ...prevDevice, isKeyboardOpen: true }));
        });

        window.addEventListener('keyboardDidShow', (e) => {
            setTimeout(() => {
                if (!isScrolling) {
                    setKeyboardShown(true);
                }
            }, 1000);
        });

        window.addEventListener('keyboardWillHide', () => {
            setKeyboardWillShow(false);
            document.activeElement.blur();

            if (device.platform === 'ios') {
                if (null !== device.scrollableEl) {
                    device.scrollableEl.removeAttribute('style');

                    // TODO
                    // TODO
                    // TODO

                    // if (undefined !== this.scrollableChannel) {
                    //     this.scrollableChannel.removeAttribute('style');
                    // }

                    Keyboard.setResizeMode({ mode: 'none' });
                }
            }

            setDevice((prevDevice) => ({
                ...prevDevice,
                isKeyboardOpen: false,
                scrollableEl: device.scrollableEl
            }));
        });

        window.addEventListener('keyboardDidHide', () => {
            setKeyboardShown(false);
        });
    };

    const configureBadge = () => {
        Badge.clear();
    };

    const loop = (e) => {
        if (null !== document.querySelector(':focus')) {
            setKeyboardToBottom(e);
        } else {
            setTimeout(() => {
                loop(e);
            }, 100);
        }
    };

    const setKeyboardToBottom = (e) => {
        let focusedEl = document.querySelector(':focus');
        let closestScrollableEl = focusedEl.closest('.scrollable');

        device.scrollableEl = document.querySelector('body');
        device.scrollableEl.style.background = '#fff';

        setDevice((prevDevice) => ({ ...prevDevice, scrollableEl: device.scrollableEl }));

        // TODO
        // TODO
        // TODO
        if (!isInMobileViewport(focusedEl, e.keyboardHeight)) {
            // if (0 === this.Top.scrollableElScrollTop) {
            //     if (this.$state.current.name === 'auth.chat.channel') {
            //         Keyboard.setResizeMode({ mode: "body" });

            //         this.scrollableChannel = document.querySelector('.channel .scrollable .list');

            //         this.scrollableChannel.style.transition = 'transform 0.3s 0.1s';

            //         let height = Math.abs(scrollableEl.scrollHeight - e.keyboardHeight - focusedEl.getBoundingClientRect().y - 50);

            //         if (this.$state.current.name === 'auth.chat.channel') {
            //             height = height + 34;
            //         }

            //         this.device.originalComposerHeight = document.querySelector('.composer').scrollHeight - 34;

            //         this.$timeout(() => {
            //             this.scrollableChannel.style.transform = `translateY(-${height}px)`;
            //         }, 600);
            //     } else {
            //         Keyboard.setResizeMode({ mode: "native" });

            //         this.$timeout(() => {
            //             focusedEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            //         }, 1000);
            //     }
            // } else if (this.Top.scrollableElScrollTop > 0 && (closestScrollableEl.scrollHeight - this.Top.scrollableElScrollTop - focusedEl.getBoundingClientRect().y) < e.keyboardHeight) {
            //     Keyboard.setResizeMode({ mode: "native" });

            //     this.$timeout(() => {
            //         focusedEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            //     }, 600);
            // } else {
            Keyboard.setResizeMode({ mode: 'body' });

            setTimeout(() => {
                focusedEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 600);
            // }
        }
    };

    const isInMobileViewport = (elem, keyboardHeight) => {
        let bounding = elem.getBoundingClientRect();

        let clientHeight =
            (window.innerHeight || document.documentElement.clientHeight) - keyboardHeight;

        return bounding.top >= 0 && bounding.bottom <= clientHeight;
    };

    return (
        <DeviceContext.Provider
            value={{
                device,
                isFullScreen,
                setIsFullScreen,
                setScrollPosition,
                keyboardWillShow
            }}
        >
            {device.initialized && children}
        </DeviceContext.Provider>
    );
};

export const useDevice = () => useContext(DeviceContext);
