import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import { Input, ColorPicker, Select, RichText } from 'components/Form/Inputs';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import categoryFormSchema from '../schemas/categoryForm.schema.json';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useCategoryService from 'api/useCategoryService';
import { useModal } from 'providers/ModalContext';
import { useAlert } from 'providers/AlertContext';

const CategoryFormModalContent = () => {
    const { t } = useTranslation();
    const Category = useCategoryService();
    const {
        contexts: {
            admin_category_form: { currentCategory }
        },
        closeModal
    } = useModal();
    const queryClient = useQueryClient();
    const { showToast } = useAlert();
    const methods = useForm({
        schema: categoryFormSchema
    });

    const { data: categories } = useQuery({
        queryKey: ['allCategories', currentCategory?.id],
        queryFn: () => {
            return Category.getAllCategories(
                false,
                currentCategory?.id ? [currentCategory.id] : null
            );
        }
    });

    const onFinish = (values) => {
        const payload = { ...(currentCategory?.id ? currentCategory : {}), ...values };

        if (payload.parent) {
            payload.parent = { id: payload.parent };
        }

        const method = currentCategory?.id ? 'putCategory' : 'postCategory';

        Category[method](payload).then((data) => {
            showToast({
                text: t('ADMIN_TREE_DIALOG_CATEGORY_EDIT_SUCCESS', { name: data.name }),
                duration: 1500
            });
            queryClient.refetchQueries({ queryKey: ['full_tree'] });
            closeModal('admin_category_form');
        });
    };

    return (
        <ModalForm onSubmit={methods.handleSubmit(onFinish)} methods={methods}>
            <ModalFormBody>
                <ModalFormSection>
                    <div>
                        <label htmlFor="name">{t('CATEGORY_ADD_INPUT_NAME')}</label>
                        <Input name={'name'} value={currentCategory?.name} />
                    </div>
                    <div>
                        <label htmlFor="parent">{t('CATEGORY_ADD_INPUT_NAME')}</label>
                        <Select
                            name={'parent'}
                            value={currentCategory?.parent?.id || null}
                            options={
                                categories
                                    ? [
                                          {
                                              label: '-- Aucune catégorie parente --',
                                              value: null
                                          },
                                          ...categories.map((category) => {
                                              return {
                                                  label: category.name,
                                                  value: category.id
                                              };
                                          })
                                      ]
                                    : []
                            }
                        />
                    </div>
                    <div>
                        <label htmlFor="description">{t('CATEGORY_ADD_INPUT_DESCRIPTION')}</label>
                        <RichText name={'description'} value={currentCategory?.description} />
                    </div>
                    <div>
                        <label htmlFor="icon_color">{t('CATEGORY_ADD_INPUT_COLOR')}</label>
                        <ColorPicker name={'icon_color'} value={currentCategory?.icon_color} />
                    </div>
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter />
        </ModalForm>
    );
};

const CategoryFormModal = () => {
    const { t } = useTranslation();

    return (
        <Modal
            name={`admin_category_form`}
            title={t('CATEGORY_ADD_DIALOG_TITLE')}
            options={{
                hasFlexContent: true
            }}>
            <CategoryFormModalContent />
        </Modal>
    );
};

export default CategoryFormModal;
