import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import React from 'react';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormFooter,
    ModalFormBody,
    ModalFormSection
} from 'components/Form/ModalForm';
import { Radio, Autocomplete } from 'components/Form/Inputs';
import { WorkspaceList } from 'components/Form/Admin/Inputs';
import { useModal } from 'providers/ModalContext';
import useUserService from 'api/useUserService';

const ExportPdfModalContent = () => {
    const { t } = useTranslation();
    const {
        contexts: {
            'add_export-pdf': { currentExportPDF }
        },
        closeModal
    } = useModal();
    const methods = useForm({
        defaultValues: currentExportPDF
    });
    const { findUsersByCriteria } = useUserService();

    const fetchUserList = async (username) => {
        return findUsersByCriteria(username).then((data) => {
            return data.map((r) => {
                return {
                    label: `${r.name}`,
                    value: r.id
                };
            });
        });
    };

    const onFinish = () => {};

    return (
        <ModalForm methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
            <ModalFormBody>
                <ModalFormSection>
                    <Radio
                        name={'type'}
                        label={t('EXPORT_PDF_TYPE')}
                        options={[
                            {
                                label: t('EXPORT_PDF_TYPE_CESAR'),
                                value: 'cesar'
                            },
                            {
                                label: t('EXPORT_PDF_TYPE_INSTRUCTION'),
                                value: 'instructions'
                            }
                        ]}
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <WorkspaceList
                        label={'Flux ciblé(s)'}
                        mode={'multiple'}
                        name={'workspaces_target'}
                        value={currentExportPDF?.workspaces_target}
                    />
                    <WorkspaceList
                        name={'workspace'}
                        label={'Flux cible'}
                        value={
                            currentExportPDF?.workspace?.id
                                ? {
                                      label: currentExportPDF?.workspace.name,
                                      value: currentExportPDF?.workspace.id
                                  }
                                : null
                        }
                    />
                </ModalFormSection>
                <ModalFormSection>
                    <div>
                        <label htmlFor={'user.id'}>Utilisateur de l'export</label>
                        {currentExportPDF?.user && (
                            <label>
                                L'utilisateur actuel est {currentExportPDF?.user.first_name}{' '}
                                {currentExportPDF?.user.last_name}
                            </label>
                        )}
                        <Autocomplete
                            fetchOptions={fetchUserList}
                            name={'user'}
                            placeholder={'Rechercher un utilisateur'}
                        />
                    </div>
                </ModalFormSection>
            </ModalFormBody>
            <ModalFormFooter
                onCancel={() => {
                    closeModal('add_export-pdf');
                }}
            />
        </ModalForm>
    );
};

const ExportPdfModal = () => {
    return (
        <Modal
            name={'add_export-pdf'}
            title={'Configurer un nouvel export PDF'}
            options={{
                hasPaddedContent: true
            }}>
            <ExportPdfModalContent />
        </Modal>
    );
};

export default ExportPdfModal;
