import React from 'react';
import useProfileActivityCampaignComponent from '../hooks/useProfileActivityCampaignComponent';
import { useTranslation } from 'react-i18next';

const CampaignOtherUser = ({ activity }) => {
    const { t } = useTranslation();
    const { getCampaignTypeInitials } = useProfileActivityCampaignComponent();

    const getActivityOtherUserAction = (activity) => {
        switch (activity.type) {
            case 'product':
                return t('ACTIVITY_OPPORTUNITY_OTHER_USER_GENERATED');
            case 'social':
                return t('TA_ACTIVITY_CAMPAIGN_SOCIAL_OTHER_USER_TITLE');
            case 'recruiting':
                return activity.submit === undefined || !activity.submit.from_social
                    ? t('TA_ACTIVITY_CAMPAIGN_RECRUITING_OTHER_USER_TITLE')
                    : t('TA_ACTIVITY_CAMPAIGN_RECRUITING_OTHER_USER_TITLE_FROM_SOCIAL');
            case 'survey':
                return t('TA_ACTIVITY_OTHER_USER_CAMPAIGN_SURVEY_TITLE');
            case 'recruiting_share':
                return t('ACTIVITY_OTHER_USER_RECRUITING_SHARE');
            default:
                return '';
        }
    };

    return (
        <div className={`details ${activity.type}`}>
            <div className="icon">
                <div className="letters">{getCampaignTypeInitials(activity.type)}</div>
            </div>

            <div className="meta">
                <div className="title">{activity.campaign.title}</div>

                <div className="action">
                    <div className="name">{getActivityOtherUserAction(activity)}</div>
                </div>
            </div>
        </div>
    );
};

export default CampaignOtherUser;
