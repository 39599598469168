import React, { useState } from 'react';
import { useModal } from '../../../../../providers/ModalContext';

const AdminLevelRightActions = ({ adminLevelRight }) => {
    const [enable, setEnable] = useState(adminLevelRight.enable);
    const { openModal } = useModal();

    return (
        <div className="level__action">
            <div className="checkbox">
                <input
                    className="input"
                    id={`config[${adminLevelRight.id}]`}
                    type="checkbox"
                    checked={enable}
                    onChange={(e) => {
                        setEnable(e.target.checked);
                        //ng-change="$ctrl.toggleEnable(adminLevelRight.id)"
                    }}
                />
                <label className="label" htmlFor={`config[${adminLevelRight.id}]`}></label>
            </div>
            <button
                onClick={() => {
                    openModal('add_level_rule', {
                        currentLevelRule: adminLevelRight
                    });
                }}>
                <i className="icon-pencil-1" aria-hidden="true"></i>
            </button>
            <button
                onClick={() => {
                    //deleteLevelRights(adminLevelRight)
                }}>
                <i className="icon-bin-2" aria-hidden="true"></i>
            </button>
            <button
                onClick={() => {
                    //createOrganizationalUnit(adminLevelRight)
                }}>
                <i className="icon-multiple-users-1" aria-hidden="true"></i>
            </button>
        </div>
    );
};

export default AdminLevelRightActions;
