import React from 'react';
import useDate from 'hooks/useDate';

const Date = ({ input }) => {
    const { format } = useDate();
    return (
        <>
            {input.workspace_form_input.label_render !== '' && (
                <div className="render_label inline">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}
            <div className="render_content inline">{format(input.date, 'simple')}</div>
        </>
    );
};

export default Date;
