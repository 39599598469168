import { orderBy } from 'lodash-es';
import { useFeedContext } from '../../providers/FeedProvider';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';

const PostStatusTab = ({ totalPosts }) => {
    const router = useRouter();
    const { workspace, hasPostStatus } = useFeedContext();
    const { params } = useCurrentStateAndParams();

    const { status: postStatusId } = params;

    const postStatusIsActive = (postStatus) => {
        return parseInt(postStatusId) === postStatus.id;
    };

    if (!hasPostStatus() || !workspace) return;

    return (
        <div className="post-status">
            <a
                className={`item ${!postStatusId ? 'active' : ''}`}
                href={router.stateService.href('.', { page: 1, status: null })}
            >
                Tous
            </a>
            {orderBy(workspace.post_status, 'order').map((postStatus) => {
                return (
                    <a
                        className={`item ${postStatusIsActive(postStatus) ? 'active' : ''}`}
                        style={{ borderColor: postStatus.color }}
                        href={router.stateService.href('.', { page: 1, status: postStatus.id })}
                        key={`post_status_${postStatus.id}`}
                    >
                        {postStatus.label}{' '}
                        {postStatusIsActive(postStatus) && postStatus.with_count && totalPosts && (
                            <span>({totalPosts})</span>
                        )}
                    </a>
                );
            })}
        </div>
    );
};

export default PostStatusTab;
