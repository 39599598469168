import React from 'react';
import { useTranslation } from 'react-i18next';
import useSharedDocumentRight from '../../../hooks/useSharedDocumentRight';
import {
    getIconResource,
    getImageSize,
    isFile,
    isImage
} from '../../../helpers/sharedDocumentArticleResource';
import useAttachment from 'hooks/useAttachment';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import dayjs from 'dayjs';

const Resources = ({ article }) => {
    const { t } = useTranslation();
    const { canSeeAdminActionsForArticle } = useSharedDocumentRight();
    const { forgeAttachmentUrl } = useAttachment();
    const { attachmentIcon } = useAttachmentIcon();

    const getFormatedDate = (date) => {
        return dayjs(date).format('DD/MM/YYYY');
    };

    return (
        <>
            <div className="title">
                {article.resources.length} {t('SHARED_DOCUMENTS_RESOURCES')}
                {canSeeAdminActionsForArticle(article) && article.resources.length > 0 && (
                    <div className="clean-resources">
                        <button
                            type="button"
                            className="clean-all-resources"
                            ng-click="cleanResources()"
                        >
                            <i className="icon-bin-2" aria-hidden="true"></i>
                        </button>
                    </div>
                )}
                {canSeeAdminActionsForArticle(article) && (
                    <div className="plus">
                        <button type="button" className="add-resource" ng-click="addResource()">
                            +
                        </button>
                    </div>
                )}
            </div>

            <div className="resources">
                {article.resources.map((resource) => {
                    return (
                        <div
                            className={`resource ${isImage(resource) ? 'is-image' : ''}`}
                            key={`resource_${resource.id}`}
                        >
                            {isImage(resource) && (
                                <div
                                    className="thumbnail"
                                    ng-click="SharedDocuments.resourceAction(resource)"
                                >
                                    <img src={forgeAttachmentUrl(resource.attachment, true)} />
                                </div>
                            )}

                            {!isImage(resource) && (
                                <div
                                    className="icon"
                                    ng-click="SharedDocuments.resourceAction(resource)"
                                >
                                    {isFile(resource) && (
                                        <i
                                            className={`fa ${attachmentIcon(resource.attachment, 'class')}`}
                                            style={{
                                                color: attachmentIcon(resource.attachment, 'color')
                                            }}
                                        ></i>
                                    )}

                                    {!isFile(resource) && (
                                        <i className={`fa ${getIconResource(resource)}`}></i>
                                    )}
                                </div>
                            )}

                            <div
                                className="meta"
                                ng-click="SharedDocuments.resourceAction(resource)"
                            >
                                <div className="label">{resource.label}</div>
                                <div className="date">
                                    {t('CREATED_AT', {
                                        date: getFormatedDate(resource.created_at)
                                    })}
                                </div>
                                {getFormatedDate(resource.updated_at) !=
                                    getFormatedDate(resource.created_at) && (
                                    <div className="date">
                                        {t('UPDATED_AT', {
                                            date: getFormatedDate(resource.updated_at)
                                        })}
                                    </div>
                                )}
                                {resource.organizational_units_names &&
                                    canSeeAdminActionsForArticle(article) && (
                                        <div className="date">
                                            {t('SHARED_DOCUMENTS_TARGET')} :{' '}
                                            {resource.organizational_units_names}
                                        </div>
                                    )}

                                {isImage(resource) && (
                                    <div className="size">
                                        {t('FILE_IMAGE_SIZE')} :{' '}
                                        {getImageSize(forgeAttachmentUrl(resource))}
                                    </div>
                                )}
                            </div>

                            {canSeeAdminActionsForArticle(article) && (
                                <div className="actions">
                                    <button
                                        type="button"
                                        className="edit-resource"
                                        ng-click="editResource(resource, article)"
                                    >
                                        <i className="icon-pencil-1" aria-hidden="true"></i>
                                    </button>

                                    <button
                                        type="button"
                                        className="delete-resource"
                                        ng-click="deleteResource(resource.id)"
                                    >
                                        <i className="icon-bin-2" aria-hidden="true"></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Resources;
