import React from 'react';
import { useTranslation } from 'react-i18next';
import useAttachment from 'hooks/useAttachment';
import useCampaignComponent from '../hooks/useCampaignComponent';

const CampaignImage = ({ campaign }) => {
    const { forgeAttachmentUrl } = useAttachment();
    const { getCampaignTypeIcon } = useCampaignComponent(campaign);

    const backgroundImageStyle = (url) => ({
        backgroundImage: `url(${url})`
    });

    return (
        <div className="image">
            {campaign.image ? (
                <div className="icon">
                    <div
                        className="background"
                        style={backgroundImageStyle(forgeAttachmentUrl(campaign.image))}
                    ></div>
                </div>
            ) : (
                <div className="icon no-image">
                    {!campaign.link ? (
                        <div className="type">
                            <i className={`fa ${getCampaignTypeIcon(campaign.type)}`}></i>
                        </div>
                    ) : (
                        <div className="video">
                            {!campaign.link.picture_url ? (
                                <i className="fa fa-video-camera" aria-hidden="true"></i>
                            ) : (
                                <div
                                    className="background"
                                    style={backgroundImageStyle(campaign.link.picture_url)}
                                ></div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const CampaignContent = ({ campaign }) => {
    const { t } = useTranslation();
    const { displaySingleCampaign, isCampaignProgrammed } = useCampaignComponent(campaign);

    return (
        <div className={`content ${campaign.type}`}>
            <div className="type">{t('TA_SURVEY_TITLE')}</div>
            <div className="title">{campaign.title}</div>
            <div className="action">
                {campaign.canParticipate ? (
                    <button
                        className="button small rounded"
                        onClick={() => displaySingleCampaign(campaign)}
                    >
                        {t('JOIN_IN')}
                    </button>
                ) : isCampaignProgrammed() ? (
                    <div>{t('TA_CAMPAIGN_PLANNED_FUTUR_ACCESS', { date: campaign.startDate })}</div>
                ) : (
                    <div>{t('CAMPAIGN_NO_ACCESS')}</div>
                )}
            </div>
        </div>
    );
};

const SurveyLight = ({ campaign }) => {
    return (
        <>
            <CampaignImage campaign={campaign} />
            <CampaignContent campaign={campaign} />
        </>
    );
};

export default SurveyLight;
