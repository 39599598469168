import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'providers/ConfigProvider';
import { useRouter } from '@uirouter/react';
import { useAlert } from 'providers/AlertContext';
import useUserService from 'api/useUserService';

const Reset = () => {
    const { t } = useTranslation();
    const { showToast } = useAlert();
    const router = useRouter();
    const { config } = useConfig();
    const { reInitPassword } = useUserService();

    const [email, setEmail] = useState(null);

    const canUserResetPassword = () => {
        return email && isEmail(email);
    };

    const isEmail = (email) => {
        if (!config.login_is_email) {
            return true;
        }

        let regEx =
            /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

        return regEx.test(email);
    };

    const isAllowToUseResetPassword = () => {
        if (
            config.domain_not_allow_to_use_forgot_password &&
            config.domain_not_allow_to_use_forgot_password.length
        ) {
            let domain = email.split('@')[1];

            return config.domain_not_allow_to_use_forgot_password.indexOf(domain) === -1;
        }

        return true;
    };

    const resetPassword = () => {
        /*if (IS_APP) {
            this.Device.closeKeyboard();
        }*/

        if (!canUserResetPassword()) {
            showToast({
                text: t('LOGIN_MISSING_CREDENTIALS'),
                duration: 3500
            });

            return;
        }

        if (!isAllowToUseResetPassword()) {
            showToast({
                text: t('INVALID_EMAIL_RESET_PASSWORD', { recovery_url: config.recovery_url }),
                duration: 3500
            });

            window.open(config.recovery_url, '_blank');

            return;
        }

        reInitPassword({
            username: email
        })
            .then((response) => {
                showToast({
                    text: t(response.message),
                    duration: 7000
                });

                setTimeout(() => {
                    router.stateService.go('login.home');
                }, 2000);
            })
            .catch(() => {
                showToast({
                    text: t('LOGIN_CREATE_ACCOUNT_FAIL'),
                    duration: 3500
                });
            });
    };

    return (
        <>
            <div className="reset spacing">
                <div className="form">
                    <div className="fields">
                        <div className="field">
                            <div className="notice">
                                <div className="title">{t('LOGIN_RESET_NOTICE_TITLE')}</div>
                                <div className="text">{t('LOGIN_RESET_NOTICE_SUBTITLE')}</div>
                                <div className="text">{t('LOGIN_RESET_NOTICE_SUBTITLE_SPAM')}</div>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="email" className="label">
                                {t('LOGIN_LOGIN_PLACEHOLDER')}
                            </label>
                            <input
                                id="email"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder={t('LOGIN_FORM_EMAIL_PLACEHOLDER')}
                            />
                        </div>

                        <div className="field">
                            <div className="tips light">
                                {t('LOGIN_RESET_TIPS_EMAIL_CONNECTION', { title: config.title })}
                            </div>
                        </div>
                    </div>

                    <div className="actions">
                        <button
                            className="btn hl"
                            onClick={resetPassword}
                            disabled={!canUserResetPassword()}
                        >
                            {t('LOGIN_BUTTON_FORGOTTEN_PASS')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="cancel">
                <button
                    type="button"
                    className="link"
                    onClick={() => router.stateService.go('login.home')}
                >
                    {t('LOGIN_CANCEL_FORGOT_PASSWORD')}
                </button>
            </div>
        </>
    );
};

export default Reset;
