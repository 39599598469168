import { useTranslation } from 'react-i18next';
import useTableCeilComponent from '../hooks/useTableCeilComponent';

const LikesCeil = ({ config, row }) => {
    const { t } = useTranslation();
    const { getIntValue } = useTableCeilComponent(config, row);

    return (
        <>
            <div className="text-center">
                {getIntValue() === 0 && <span>{t('POST_NO_LIKE')}</span>}
                {getIntValue() === 1 && <span>{t('POST_ONE_LIKE')}</span>}
                {getIntValue() > 1 && (
                    <span>{t('POST_LIKES', { like_number: getIntValue() })}</span>
                )}
            </div>
        </>
    );
};

export default LikesCeil;
