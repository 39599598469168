import React from 'react';
import Username from 'components/Username/Username';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import useWorkspace from 'hooks/useWorkspace';
import useDate from 'hooks/useDate';
import { usePost } from '../../providers/PostProvider';
import useUtils from 'hooks/useUtils';
import useTree from 'hooks/useTree';
import useRight from '../../../../hooks/useRight';
import RealtimeDate from '../../../RealTimeDate/RealTimeDate';

const PostHeader = () => {
    const {
        post,
        canTag,
        addPostTags,
        showActions,
        isPostPreview,
        goToUserProfile,
        isPostOutdated,
        getPostLink,
        goToPostFromDate,
        isPostScheduledAndPostdated,
        isModified,
        showPostStatus,
        goToPostStatusPage,
        canEditPostStatus,
        editPostStatus,
        hasTags,
        getTags,
        goToTagPage,
        canWriteResume,
        resumeLabel
    } = usePost();

    const { getFirstLetter } = useUtils();
    const { t } = useTranslation();
    const { hasRole } = useRight();
    const Tree = useTree();
    const { forgeIconUrl } = useWorkspace();
    const { format: dateFormat } = useDate();

    const goToWorkspace = () => {
        Tree.goToWorkspace({
            workspace: post.workspace
        });
    };

    const getCategoryLink = () => {
        return Tree.getCategoryLink(post.workspace.category);
    };

    const getWorkspaceLink = () => {
        return Tree.getWorkspaceLink({
            workspace: post.workspace
        });
    };

    const getFolderLink = () => {
        return Tree.getFolderLink(post.folder, post.workspace);
    };

    const addResume = (e) => {
        e.stopPropagation();
        /*TagResume.createPanel({ post: post }).then((data) => {
            post.resume = data.resume;
            post.image_resume = data.image_resume;
        })*/
    };

    return (
        <div className="post__header">
            <div className="icon cursor pointer" onClick={goToWorkspace}>
                {!hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') ? (
                    <img src={forgeIconUrl(post.workspace.icon_name)} alt="Workspace Icon" />
                ) : (
                    <div className="letter" style={{ backgroundColor: post.workspace.icon_color }}>
                        {getFirstLetter(post.workspace.name)}
                    </div>
                )}
            </div>

            <div className="details">
                <div className="row">
                    <div className="category-and-workspace">
                        <a
                            className="category"
                            href={getCategoryLink()}
                            target="_blank"
                            rel="noopener noreferrer">
                            {post.workspace.category.name}
                        </a>

                        <a
                            className="workspace"
                            href={getWorkspaceLink()}
                            target="_blank"
                            rel="noopener noreferrer">
                            {post.workspace.name}
                        </a>

                        {post.folder && (
                            <a
                                className="folder"
                                href={getFolderLink()}
                                target="_blank"
                                rel="noopener noreferrer">
                                {post.folder.name}
                            </a>
                        )}
                    </div>

                    {canTag() && (
                        <div className="add-post-tags" onClick={addPostTags}>
                            <div className="shield">+</div>
                        </div>
                    )}

                    {!isPostPreview && (
                        <div className="actions" onClick={showActions}>
                            <i className="icon-navigation-menu-horizontal" aria-hidden="true"></i>
                        </div>
                    )}
                </div>

                <div className="row wrap">
                    {!isPostOutdated() && (
                        <div
                            className="author is-clickable"
                            onClick={() => goToUserProfile(post.user)}>
                            <Username user={post.user} />
                        </div>
                    )}

                    <a
                        className="date"
                        href={getPostLink()}
                        onClick={goToPostFromDate}
                        target="_blank"
                        rel="noopener noreferrer">
                        {!isPostScheduledAndPostdated() ? (
                            <div className="not-scheduled">
                                <RealtimeDate date={post.edited_at} />
                                {isModified() && (
                                    <span>
                                        {t('DATE_EDITED_PUBLISHED_AT', {
                                            date: dateFormat(post.created_at, 'simple')
                                        })}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div className="scheduled">
                                {t('POST_DATE_POSTDATED_AT', {
                                    date: dateFormat(post.postdated_at, 'simple_hours')
                                })}
                            </div>
                        )}
                    </a>
                </div>

                {showPostStatus() && (
                    <div className="row">
                        <div
                            className="status"
                            style={{ backgroundColor: post.post_status.color }}
                            onClick={() => goToPostStatusPage(post.workspace, post.post_status)}>
                            {post.post_status.label}
                        </div>

                        {canEditPostStatus() && (
                            <div className="status is-action" onClick={editPostStatus}>
                                {t('UPDATE_THE_STATUS')} <i className="fa fa-angle-right"></i>
                            </div>
                        )}
                    </div>
                )}

                {hasTags() && (
                    <div className="row wrap">
                        {getTags().map((tag, index) => (
                            <div
                                key={index}
                                className={`tag`}
                                style={{ backgroundColor: tag.color, borderColor: tag.color }}
                                onClick={() => goToTagPage(tag)}>
                                {tag.name}
                            </div>
                        ))}

                        {canWriteResume() && (
                            <div className="status is-action" onClick={addResume}>
                                {resumeLabel} <i className="fa fa-angle-right"></i>
                            </div>
                        )}
                    </div>
                )}

                {isPostOutdated() && (
                    <div className="row">
                        <div className="status is-expired">
                            {t('POST_OUTDATED_DATE_LABEL', {
                                date: dateFormat(post.outdated_at, 'simple')
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PostHeader;
