import { isArray, forEach } from 'lodash-es';

const striptags = require('striptags');

const useCalendarEvent = () => {
    const getEventDate = (event) => {
        let [fieldName = null, fieldId = null] = event.workspace.calendar_sort_field.split(':');

        switch (fieldName) {
            case 'custom':
                let date = event.workspace_form_input_values.find((value) => {
                    return value.workspace_form_input.id === parseInt(fieldId);
                });

                if (!date) {
                    return event.edited_at;
                }

                return date.date;
            default:
                switch (fieldName) {
                    case 'createdAt':
                        return event.created_at;
                    case 'updatedAt':
                        return event.updated_at;
                    case 'editedAt':
                    default:
                        return event.edited_at;
                }
        }
    };

    const getEventEndDate = (event) => {
        if (!event.workspace.calendar_end_field) {
            return getEventDate(event);
        }

        let [fieldName = null, fieldId = null] = event.workspace.calendar_end_field.split(':');

        switch (fieldName) {
            case 'custom':
                let date = event.workspace_form_input_values.find((value) => {
                    return value.workspace_form_input.id === parseInt(fieldId);
                });

                if (!date) {
                    return getEventDate(event);
                }

                return date.date;
            default:
                switch (fieldName) {
                    case 'createdAt':
                        return event.created_at;
                    case 'updatedAt':
                        return event.updated_at;
                    case 'editedAt':
                        return event.edited_at;
                    default:
                        return getEventDate(event);
                }
        }
    };

    const getEventColorAsHex = (event) => {
        let color = event.workspace.icon_color;

        if (event.workspace_form_input_values) {
            forEach(event.workspace_form_input_values, (formInputValue) => {
                if (formInputValue.workspace_form_input.label === 'color') {
                    color = formInputValue.text;

                    return false;
                }
            });
        }

        return color;
    };

    const processPostWorkspaceFormInputValues = (inputValues) => {
        let workspaceFormInputValues = inputValues;

        if (!workspaceFormInputValues || workspaceFormInputValues.length === 0) {
            return;
        }

        let workspaceFormInputCollectionValues = {};

        workspaceFormInputValues = workspaceFormInputValues.filter((workspaceFormInputValue) => {
            let workspaceFormInput = workspaceFormInputValue.workspace_form_input;

            if (
                workspaceFormInput &&
                workspaceFormInput.type === 'collection' &&
                !workspaceFormInputValue.is_processed
            ) {
                if (workspaceFormInputCollectionValues.hasOwnProperty(workspaceFormInput.id)) {
                    workspaceFormInputCollectionValues[workspaceFormInput.id].values.push({
                        id: workspaceFormInputValue.id,
                        text: workspaceFormInputValue.text,
                        qualification: workspaceFormInputValue.qualification
                    });
                } else {
                    workspaceFormInputCollectionValues[workspaceFormInput.id] = {
                        is_processed: true,
                        order: workspaceFormInputValue.order,
                        workspace_form_input: workspaceFormInputValue.workspace_form_input,
                        values: [
                            {
                                id: workspaceFormInputValue.id,
                                text: workspaceFormInputValue.text,
                                qualification: workspaceFormInputValue.qualification
                            }
                        ]
                    };
                }

                return false;
            }

            return true;
        });

        return workspaceFormInputValues.concat(Object.values(workspaceFormInputCollectionValues));
    };

    const getEventTitle = (event) => {
        let [fieldName = null, fieldId = null] = event.workspace.calendar_title_field.split(':');

        switch (fieldName) {
            case 'custom':
                let title = processPostWorkspaceFormInputValues(
                    event.workspace_form_input_values
                ).find((value) => {
                    return value.workspace_form_input.id === parseInt(fieldId);
                });

                if (!title) {
                    return striptags(event.content);
                }

                switch (title.workspace_form_input.type) {
                    case 'recipients':
                        return (
                            title.recipients &&
                            isArray(title.recipients) &&
                            title.recipients.length > 0 &&
                            title.recipients.map((r) => r.name).join(', ')
                        );
                    case 'numeric':
                        return title.number;
                    case 'collection':
                        return (
                            title.values &&
                            isArray(title.values) &&
                            title.values.length > 0 &&
                            title.values.map((v) => v.text).join(', ')
                        );
                    case 'position':
                        return title.address;
                    case 'long_text':
                        return striptags(title.text);
                    default:
                        return title.text;
                }
            case 'author':
                return `${event.user.first_name} ${event.user.last_name}`;
            default:
                return striptags(event.content);
        }
    };

    return {
        getEventDate,
        getEventEndDate,
        getEventTitle,
        getEventColorAsHex
    };
};

export default useCalendarEvent;
