import Chat from '../Chat.js';
import Channels from '../components/Channels';
import Channel from '../components/Channel';

export default [
    { name: 'auth.chat', url: 'channels', abstract: true, component: Chat },
    { name: 'auth.chat.channel', url: '/{id}?unread', component: Channel },
    {
        name: 'auth.chat.channels',
        url: '/',
        component: Channels,
        navbar: {
            getIcon: () => {
                return 'icon-messages-bubble-double';
            },
            getLabel: (context) => {
                return context.t('NAVBAR_CHAT');
            },
            getCallback: (context, state) => {
                if (context.viewport.xs || context.channels.length === 0) {
                    context.router.stateService.go(
                        'auth.chat.channels',
                        {},
                        { reload: true, inherit: false }
                    );
                } else {
                    context.router.stateService.go(
                        'auth.chat.channel',
                        { id: context.channels[0].id },
                        { reload: true, inherit: false }
                    );
                }
            },
            hasBadge: (context) => {
                return context.channels?.some((channel) => {
                    return channel.unread && channel.unread > 0;
                });
            },
            getBadge: (context) => {
                return context?.getTotalUnreadMessages();
            },
            isActive: (context) => {
                return context.isActive(['auth.chat.**']);
            },
            isVisible: (context) => {
                if (context.User.isSwitched()) {
                    return false;
                }

                return context.Right.hasRole(['ROLE_INTERNAL', 'HAS_CHANNELS']);
            }
        }
    }
];
