import React from 'react';

const Numeric = ({ input }) => {
    return (
        <>
            {input.workspace_form_input.label_render !== '' && (
                <div className="render_label inline">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}
            <div className="render_content inline">{input.number}</div>
        </>
    );
};

export default Numeric;
