import React from 'react';
import useRight from 'hooks/useRight';
import { useQuery } from '@tanstack/react-query';
import useImportEmployeeService from './api/useImportEmployeeService';
import ImportEmployeeConfig from './components/ImportEmployeeConfig';
import AdminHeader from 'components/Admin/components/AdminHeader';
import { Button } from 'antd';
import ImportEmployeesFormModal from './modals/ImportEmployeesFormModal';

const ImportEmployees = () => {
    const { hasRole } = useRight();
    const { getAllConfigs } = useImportEmployeeService();

    const { data: importEmployeeConfigs } = useQuery({
        queryKey: ['importEmployeeConfigs'],
        queryFn: () => {
            return getAllConfigs();
        }
    });

    return (
        <div className="container admin__levels">
            <ImportEmployeesFormModal />
            <AdminHeader
                title={'Import des salariés'}
                modalButtonTitle={'Configurer un nouvel import'}
                modalName={'import_employees'}>
                {hasRole('ALLOW_IMPORT_EMPLOYEES') && <Button>Importer à l'ancienne</Button>}
            </AdminHeader>

            {importEmployeeConfigs &&
                importEmployeeConfigs.map((importEmployeeConfig) => {
                    return (
                        <ImportEmployeeConfig
                            config={importEmployeeConfig}
                            key={`importEmployeeConfig_${importEmployeeConfig.id}`}
                        />
                    );
                })}
        </div>
    );
};

export default ImportEmployees;
