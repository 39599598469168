import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFeedContext } from '../../providers/FeedProvider';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import useRight from 'hooks/useRight';
import { useAlert } from 'providers/AlertContext';
import dayjs from 'dayjs';
import useMetric from 'hooks/useMetric';
import useUtils from 'hooks/useUtils';
import useUrl from 'hooks/useUrl';
import { useAuth } from 'providers/AuthContext';
import useAttachment from 'hooks/useAttachment';
import useWorkspace from 'hooks/useWorkspace';
import Modes from './Modes';
import { filter, forEach, has, isEmpty, isEqual, map, unset } from 'lodash-es';
import utf8 from 'utf8';
import base64 from 'base-64';
import Placeholder from 'components/Placeholder/Placeholder';

const Header = ({ hideInfo = false, hideFilters = false }) => {
    const { t } = useTranslation();
    const {
        tag,
        workspace,
        category,
        folder,
        isTargettedInSublessWS,
        workspaceId,
        categoryId,
        folderId
    } = useFeedContext();
    const { params } = useCurrentStateAndParams();
    const {
        hasRole,
        isAllowToWrite,
        isAllowToRead,
        isWorkspaceAdmin,
        isHalfSubscribedToWorkspace
    } = useRight();
    const { user } = useAuth();
    const { showPromptPeriod, showActionSheet } = useAlert();
    const { forgeDownloadAllPosts } = useMetric();
    const { getFirstLetter } = useUtils();
    const { openUrl } = useUrl();
    const { forgeAllWorkspaceAttachmentsUrl, download } = useAttachment();
    const { forgeIconUrl, isWorkspaceFilterable, isWorkspaceFormInputFilterable } = useWorkspace();
    const router = useRouter();

    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [query, setQuery] = useState({});

    useEffect(() => {
        if (isEmpty(query)) return;

        let queryStrings = map(
            query,
            (id, value) => value && `${id},${base64.encode(utf8.encode(value))}`
        );
        queryStrings = filter(queryStrings, (value) => value !== null);

        if (!isEqual(params.query, queryStrings)) {
            router.stateService.go(
                '.',
                { ...params, page: 1, query: queryStrings },
                { reload: false, inherit: false }
            );
        }
    }, [query]);

    useEffect(() => {
        if (!params.query) return;

        try {
            const tmpQuery = {};
            params.query.forEach((item) => {
                const [id, value] = item.split(',');
                tmpQuery[utf8.decode(base64.decode(decodeURIComponent(value)))] = parseInt(id);
            });
            forEach(tmpQuery, (workspaceFormInputId, value) => {
                setSelectedFilters((prevState) => ({
                    ...prevState,
                    [workspaceFormInputId]: prevState[workspaceFormInputId]
                        ? [...prevState[workspaceFormInputId], value]
                        : [value]
                }));
            });
        } catch (e) {
            setQuery({});
        }
    }, [params]);

    useEffect(() => {
        if (!isEmpty(selectedFilters)) {
            setShowFilters(true);
        }
    }, [selectedFilters]);

    const getWorkspaceFormInputById = (id) =>
        workspace.workspace_form.workspace_form_inputs.find((input) => input.id === parseInt(id));
    const isWorkspaceFormInputConditional = (input) => !!input.input_condition;

    const workspaceHasDescription = () => workspace?.description;
    const categoryHasDescription = () => category?.description;
    const isSubscriptionLess = () => workspace?.subscription_less;
    const folderHasDescription = () => folder?.description;

    const showDownloadPosts = () => {
        if (!workspace) return false;
        if (
            (workspace.has_owner && workspace.owner_id === user.id) ||
            hasRole(`${workspace.id}_ADMIN`) ||
            hasRole('ROLE_ADMIN')
        )
            return true;
        if (workspace.everyone_can_export)
            return isSubscriptionLess() || hasRole(`${workspace.id}_READ`);
        return false;
    };

    const showWriterDownloadPost = () =>
        workspace && isAllowToWrite(workspace.id) && workspace.writers_can_export;
    const userIsSubscribedToWorkspace = () => workspace?.user_is_subscribed;

    const showActions = () => {
        const buttons = [];
        const createDownloadButton = (
            label,
            title,
            text,
            placeholder,
            placeholderEnd,
            callback
        ) => {
            buttons.push({
                label,
                callback: () => {
                    const defaultValue = new Date();
                    defaultValue.setMonth(defaultValue.getMonth() - 1);

                    showPromptPeriod({
                        title,
                        text,
                        placeholder,
                        placeholder_end: placeholderEnd,
                        defaultValue,
                        button: { label: 'Valider', classes: ['bold'] }
                    })
                        .then(({ start, end }) => callback(start, end))
                        .catch((err) => {});
                }
            });
        };

        if (showDownloadPosts()) {
            createDownloadButton(
                t('DOWNLOAD_CSV'),
                'Exporter les actualités',
                'Choisir une date de départ et une date de fin pour exporter les actualités de la période',
                'Date de départ',
                'Date de fin',
                (start, end) => {
                    // downloadPostsAsCSV(start, end, params.query);
                }
            );
        }

        if (hasRole('ROLE_ADMIN') || isWorkspaceAdmin(workspace)) {
            createDownloadButton(
                t('DL_ALL_FILES'),
                t('DL_ALL_FILES'),
                "Choisir une date de départ et une date de fin pour l'export de la période",
                'Date de départ',
                'Date de fin',
                (start, end) => {
                    download(forgeAllWorkspaceAttachmentsUrl(workspace.id, start, end));
                }
            );
        }

        if (hasRole('ROLE_ADMIN') || workspace.owner_id === user.id) {
            buttons.push({
                label: t('DOWNLOAD_WORKSPACE_USERS'),
                callback: () => {
                    // downloadWorkspaceUsers();
                }
            });
        }

        if (showWriterDownloadPost()) {
            createDownloadButton(
                t('WRITERS_DOWNLOAD_CSV'),
                'Exporter mes actualités',
                'Choisir une date de départ et une date de fin pour exporter les actualités de la période',
                'Date de départ',
                'Date de fin',
                (start, end) => {
                    // downloadWriterPostsAsCSV(start, end, params.query);
                }
            );
        }

        if (workspace.silent_mode && workspace.is_user_in_ous_for_half_read_management) {
            buttons.push({
                label: !isHalfSubscribedToWorkspace(workspace.id)
                    ? t('SILENT_MODE_ON')
                    : t('SILENT_MODE_OFF'),
                callback: () => {
                    // toggleUserSilentModeToWorkspace();
                }
            });
        }

        buttons.push({
            label: userIsSubscribedToWorkspace()
                ? t('WORKSPACE_USER_SUBSCRIPTION_DELETE')
                : t('WORKSPACE_USER_SUBSCRIPTION_ADD'),
            callback: () => {
                // toggleUserSubscribeToWorkspace();
            }
        });

        if (workspace.owner_id === user.id) {
            buttons.push({
                label: t('WORKSPACE_EDIT_DIALOG_TITLE'),
                callback: () => {
                    // togglePanelWorkspaceEdition();
                }
            });
        }

        if (
            workspace.owner_id === user.id ||
            (workspace.subscribers_manageable_by_admins && isWorkspaceAdmin())
        ) {
            buttons.push({
                label: t('WORKSPACE_EDIT_USERS_DIALOG_TITLE'),
                callback: () => {
                    // loadUsers();
                    // Modal.open('users_workspace_form');
                }
            });
        }

        showActionSheet({ title: 'Options', buttons });
    };

    const showTagAdminActions = () => {
        const buttons = [
            {
                label: t('DOWNLOAD_CSV'),
                callback: () => {
                    const defaultValue = new Date();
                    defaultValue.setMonth(defaultValue.getMonth() - 1);

                    showPromptPeriod({
                        title: 'Exporter les actualités',
                        text: 'Choisir une date de départ et une date de fin pour exporter les actualités de la période',
                        placeholder: 'Date de départ',
                        placeholder_end: 'Date de fin',
                        defaultValue,
                        button: { label: 'Valider', classes: ['bold'] }
                    })
                        .then(({ start, end }) => {
                            openUrl(
                                forgeDownloadAllPosts({
                                    period: 'custom',
                                    startDate: dayjs.unix(start).format('YYYY-MM-DD'),
                                    endDate: dayjs.unix(end).format('YYYY-MM-DD'),
                                    tagIds: tag.id
                                }),
                                '_blank'
                            );
                        })
                        .catch((err) => {});
                }
            }
        ];

        showActionSheet({ title: 'Options', buttons });
    };

    const getIconColor = () => workspace?.icon_color || category?.icon_color || '#30CCD6';

    const unselectFilter = (workspaceFormInput, value) => {
        const newQuery = { ...query };

        forEach(newQuery, (queryWorkspaceFormInputId, queryValue) => {
            const queryWorkspaceFormInput = getWorkspaceFormInputById(queryWorkspaceFormInputId);
            if (
                isWorkspaceFormInputConditional(queryWorkspaceFormInput) &&
                queryWorkspaceFormInput.input_condition.id === workspaceFormInput.id &&
                queryWorkspaceFormInput.value_condition === newQuery[value]
            ) {
                unselectFilter(queryWorkspaceFormInput, queryValue);
            }
        });

        unset(newQuery, value);
        setQuery(newQuery);
    };

    const handleFilters = (workspaceFormInput, values) => {
        const newQuery = { ...query };

        forEach(values, (value) => {
            if (has(newQuery, value)) {
                if (newQuery[value] === workspaceFormInput.id) {
                    unselectFilter(workspaceFormInput, value);
                    return;
                }
                unselectFilter(workspaceFormInput, value);
            }
            newQuery[value] = workspaceFormInput.id;
        });
        setQuery(newQuery);
    };

    const renderHeader = () => {
        if (tag) {
            return (
                <div className="header tag">
                    <div className="infos">
                        <div className="icon">
                            <div
                                className="badge"
                                style={{
                                    borderColor: tag.color,
                                    backgroundColor: !tag.admin && tag.color
                                }}>
                                <div
                                    className="shield"
                                    style={{
                                        borderColor: tag.admin && tag.color,
                                        backgroundColor: tag.admin && tag.color
                                    }}></div>
                            </div>
                        </div>
                        <div className="layout">
                            <div>
                                <div className="metas">
                                    <h3>{tag.name}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: tag.description || t('ALL_POSTS_FROM_YOUR_TAG')
                                        }}></p>
                                </div>
                                {tag.admin && (
                                    <div className="subscription">
                                        <button className="button" onClick={showTagAdminActions}>
                                            <span className="icon-navigation-menu-horizontal"></span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (params.folder && folder && workspace) {
            return (
                <div className="header folder">
                    <div
                        className={`infos ${!folderHasDescription() && !workspaceHasDescription() ? 'center' : ''}`}>
                        <div className="icon" style={{ backgroundColor: workspace.icon_color }}>
                            <i className="icon-office-folder" aria-hidden="true"></i>
                        </div>
                        <div className="layout">
                            <div>
                                <div className="metas">
                                    <h3>{folder.name}</h3>
                                    {hasRole('ROLE_INTERNAL') && (
                                        <div>
                                            <button
                                                className={
                                                    hasRole('ROLE_HIDE_LIST_USERS')
                                                        ? 'hide-list-users'
                                                        : ''
                                                }
                                                onClick={() => {}}>
                                                {workspace.writer}{' '}
                                                {workspace.writer === 1
                                                    ? t('WORKSPACE_WRITER')
                                                    : t('WORKSPACE_WRITERS')}
                                            </button>
                                            {workspace.reader > 0 && (
                                                <>
                                                    <span className="separator"> | </span>
                                                    <button
                                                        className={
                                                            hasRole('ROLE_HIDE_LIST_USERS')
                                                                ? 'hide-list-users'
                                                                : ''
                                                        }
                                                        onClick={() => {}}>
                                                        {workspace.reader}{' '}
                                                        {workspace.reader === 1
                                                            ? t('WORKSPACE_READER')
                                                            : t('WORKSPACE_READERS')}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {(isAllowToWrite(workspace.id) ||
                                    isAllowToRead(workspace.id) ||
                                    workspace.owner_id === user.id ||
                                    isTargettedInSublessWS()) && (
                                    <div className="subscription">
                                        <button className="button" onClick={showActions}>
                                            <span className="icon-navigation-menu-horizontal"></span>
                                        </button>
                                    </div>
                                )}
                            </div>
                            {workspaceHasDescription() && !folderHasDescription() && (
                                <p
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: workspace.description }}></p>
                            )}
                            {folderHasDescription() && (
                                <p
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: folder.description }}></p>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        if ((workspace || categoryHasDescription()) && !folder) {
            return (
                <div className="header">
                    <div className={`infos ${!workspaceHasDescription() ? 'center' : ''}`}>
                        {!hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') && workspace && (
                            <div className="icon">
                                <img src={forgeIconUrl(workspace.icon_name)} alt="workspace icon" />
                            </div>
                        )}
                        {(hasRole('HAS_LETTERS_AS_WORKSPACE_IMAGE') || !workspaceId) && (
                            <div className="icon" style={{ backgroundColor: getIconColor() }}>
                                <div className="letter">
                                    {getFirstLetter(workspace ? workspace.name : category.name)}
                                </div>
                            </div>
                        )}
                        <div className="layout">
                            <div>
                                <div className="metas">
                                    <h3>{workspace ? workspace.name : category.name}</h3>
                                    {hasRole('ROLE_INTERNAL') && workspace && (
                                        <div>
                                            <button
                                                className={
                                                    hasRole('ROLE_HIDE_LIST_USERS')
                                                        ? 'hide-list-users'
                                                        : ''
                                                }
                                                onClick={() => {}}>
                                                {workspace.writer}{' '}
                                                {workspace.writer === 1
                                                    ? t('WORKSPACE_WRITER')
                                                    : t('WORKSPACE_WRITERS')}
                                            </button>
                                            {workspace.reader > 0 && (
                                                <>
                                                    <span className="separator"> | </span>
                                                    <button
                                                        className={
                                                            hasRole('ROLE_HIDE_LIST_USERS')
                                                                ? 'hide-list-users'
                                                                : ''
                                                        }
                                                        onClick={() => {}}>
                                                        {workspace.reader}{' '}
                                                        {workspace.reader === 1
                                                            ? t('WORKSPACE_READER')
                                                            : t('WORKSPACE_READERS')}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {workspace &&
                                    (isAllowToWrite(workspace.id) ||
                                        isAllowToRead(workspace.id) ||
                                        workspace.owner_id === user.id ||
                                        isTargettedInSublessWS()) && (
                                        <div className="subscription">
                                            <button className="button" onClick={showActions}>
                                                <span className="icon-navigation-menu-horizontal"></span>
                                            </button>
                                        </div>
                                    )}
                            </div>
                            {(workspaceHasDescription() ||
                                (!workspace && categoryHasDescription())) && (
                                <p
                                    className="description ng-quill-text"
                                    dangerouslySetInnerHTML={{
                                        __html: workspace
                                            ? workspace.description
                                            : category.description
                                    }}></p>
                            )}
                            {workspace && !hideFilters && (
                                <div className="actions">
                                    {isWorkspaceFilterable(workspace) && (
                                        <button
                                            className={`button small rounded primary-border ${showFilters ? 'active' : ''}`}
                                            onClick={() => setShowFilters(!showFilters)}>
                                            <span
                                                style={{ marginRight: 0 }}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        workspace.custom_filter_action_label ||
                                                        t('WORKSPACE_FILTER_POSTS')
                                                }}></span>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {workspace &&
                        isWorkspaceFilterable(workspace) &&
                        showFilters &&
                        !hideFilters && (
                            <div className="filters">
                                {workspace.workspace_form.workspace_form_inputs.map((input) => {
                                    if (!isWorkspaceFormInputFilterable(input)) return null;
                                    return (
                                        <div
                                            className="filter"
                                            key={`workspace_form_input_${input.id}`}>
                                            <div className="title">
                                                {t('WORKSPACE_FILTER_BY', {
                                                    type: input.label_render
                                                })}
                                            </div>
                                            <Select
                                                style={{ width: '100%' }}
                                                mode={'multiple'}
                                                defaultValue={selectedFilters[input.id]}
                                                placeholder={t('EDITOR_COLLECTION_SELECT_VALUE')}
                                                onChange={(values) => handleFilters(input, values)}
                                                options={input.values.map((value) => ({
                                                    label: value,
                                                    value
                                                }))}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                </div>
            );
        }

        if (
            //  !hidePostsDuringLiveVoting &&
            !workspace &&
            !folder &&
            !category &&
            (workspaceId || categoryId || folderId)
        ) {
            return (
                <div className="header">
                    <div className="infos">
                        <Placeholder template={'workspace'} number={1} />
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div>
                <Modes />
            </div>
            {!hideInfo && renderHeader()}
        </>
    );
};

export default Header;
