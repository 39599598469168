import { useConfig } from 'providers/ConfigProvider';
import WorkspaceFormsService from 'components/Form/services/WorkspaceForm';

const useFormRowInputComponent = (input) => {
    const { config } = useConfig();

    const workspaceFormsService = new WorkspaceFormsService(config);

    const isFieldType = (type) => {
        return input.type === type;
    };

    return {
        isFieldType,
        workspaceFormsService
    };
};

export default useFormRowInputComponent;
