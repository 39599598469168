import React, { useEffect, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { defaultTo } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { useHttp } from '../../../../api/useHttp';
import { useConfig } from '../../../../providers/ConfigProvider';
import useUrl from '../../../../hooks/useUrl';

const Logs = () => {
    const { params } = useCurrentStateAndParams();
    const axios = useHttp();
    const router = useRouter();
    const { config } = useConfig();
    const { openUrl } = useUrl();
    const [activeTab, setActiveTab] = useState(defaultTo(params.tab, 'errors'));

    const { data: logs, isLoading } = useQuery({
        queryKey: ['logs', activeTab],
        queryFn: () => {
            const uri =
                activeTab === 'errors' ? '/super-admin/logs' : '/super-admin/user/actions/logs';
            return axios.get(uri).then((response) => response.data);
        }
    });

    useEffect(() => {
        setActiveTab(defaultTo(params.tab, 'errors'));
    }, [params]);

    const downloadLogs = () => {
        let url = `${config.staticUrl}super-admin/logs/download`;
        openUrl(url);
    };

    return (
        <div className="admin__logs">
            <div className="title">Logs</div>

            <div className="tabs">
                <div className="items">
                    <a
                        className={`item is-clickable ${activeTab === 'errors' ? 'active' : ''}`}
                        href={router.stateService.href('.', { tab: 'errors' })}
                    >
                        Logs d'erreurs
                    </a>
                    <a
                        className={`item is-clickable ${activeTab === 'user_actions' ? 'active' : ''}`}
                        href={router.stateService.href('.', { tab: 'user_actions' })}
                    >
                        Logs d'actions
                    </a>
                </div>
            </div>

            {!isLoading && logs && logs.length > 0 && (
                <div className="logs">
                    {logs.map((log, index) => {
                        return (
                            <div className="log" key={`log_${index}`}>
                                {log}
                            </div>
                        );
                    })}
                </div>
            )}

            {!isLoading && logs && logs.length === 0 && (
                <div className="no-logs">
                    <div className="error">
                        <div className="icon">
                            <i className="icon-alert-triangle" aria-hidden="true"></i>
                        </div>

                        <div className="text">Pas encore de logs de ce type</div>
                    </div>
                </div>
            )}

            {isLoading && (
                <div className="no-logs">
                    <div className="error">
                        <div className="icon">
                            <i className="icon-button-refresh-arrows" aria-hidden="true"></i>
                        </div>

                        <div className="text">Téléchargement des logs...</div>
                    </div>
                </div>
            )}

            {!isLoading && activeTab === 'errors' && (
                <div className="actions">
                    <div className="action">
                        <button className="btn" onClick={downloadLogs}>
                            Télécharger le fichier complet
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Logs;
