import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forEach } from 'lodash-es';
import useAttachment from 'hooks/useAttachment';
import { getAllImages } from 'helpers/attachment';
import { useFeedContext } from '../../../../providers/FeedProvider';
import { useModal } from 'providers/ModalContext';
import isImageAttachment from 'helpers/attachment/isImageAttachment';
import usePostService from '../../../../../../api/usePostService';

const Posts = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const { forgeAttachmentUrl } = useAttachment();
    const { workspace } = useFeedContext();
    const [posts, setPosts] = useState(null);
    const { openModal } = useModal();
    const { getPostById } = usePostService();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        setPosts(data?.posts.map((posts) => ({ ...posts, inputLimit: 2 })));
        setTotalPages(data?.totalPages);
        setTotalItems(data?.totalItems);
    }, [data]);

    const getThreeFirstPostInputValuesAsString = (post) => {
        let removeEmptyValues = [];

        forEach(post.inputs.flat(), (label) => {
            if (label !== '') {
                removeEmptyValues.push(label);
            }
        });

        let slicedInputs = removeEmptyValues.slice(0, 3);

        let threeFirstPostInputValues = '';

        forEach(slicedInputs, (label) => {
            if (threeFirstPostInputValues != '') {
                threeFirstPostInputValues = threeFirstPostInputValues + ', ';
            }

            threeFirstPostInputValues = threeFirstPostInputValues + '#';

            if (label.substring(0, 12) !== label) {
                threeFirstPostInputValues =
                    threeFirstPostInputValues + label.substring(0, 12) + '...';
            } else {
                threeFirstPostInputValues = threeFirstPostInputValues + label;
            }
        });

        return threeFirstPostInputValues;
    };

    const hasImage = (post) => {
        post.images = [];

        if (post.attachments && post.attachments.length > 0) {
            post.images = getAllImages(post.attachments);
        }

        return post.images.length > 0;
    };

    const getOneImage = (post) => {
        if (post === undefined) {
            return;
        }

        post.images = [];

        if (post.attachments && post.attachments.length > 0) {
            post.images = getAllImages(post.attachments);
        }

        if (post.images.length > 0) {
            return forgeAttachmentUrl(post.images[0]);
        }
    };

    const showCarousel = (attachments) => {
        if (attachments.length === 0) {
            return;
        }

        attachments = attachments.filter((attachment) => {
            if (!attachment) return false;

            return isImageAttachment(attachment);
        });

        openModal('carousel', { images: attachments, index: 0 });
    };

    const goToPost = (post) => {
        getPostById(post.id).then((data) => {
            openModal('preview-post', {
                post: data[0].post
            });
        });
    };

    if (!posts || !workspace || isLoading) {
        return <></>;
    }

    return (
        <>
            {posts && posts.length > 0 ? (
                posts.map((post) => {
                    return (
                        <div
                            className={`tile ${!hasImage(post) ? 'no-attachment' : ''}`}
                            key={`post_${post.id}`}>
                            <div
                                className="header"
                                style={{
                                    backgroundImage: 'url(' + getOneImage(post) + ')',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center'
                                }}
                                onClick={() => showCarousel(post.attachments)}>
                                <div
                                    className={`inputs ${!hasImage(post) ? 'no-attachment' : ''} ${!workspace.has_background_color_as_status ? 'bg-color' : ''}`}>
                                    {!hasImage(post) && (
                                        <div
                                            className="values"
                                            style={{
                                                color: workspace.has_background_color_as_status
                                                    ? post.color
                                                    : ''
                                            }}>
                                            {getThreeFirstPostInputValuesAsString(post)}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className="body"
                                style={{
                                    backgroundColor: workspace.has_background_color_as_status
                                        ? post.color
                                        : '',
                                    color: workspace.has_background_color_as_status ? '#FFF' : ''
                                }}>
                                <div className="infos">
                                    <div className="counts">
                                        <div className="count meta">
                                            <div
                                                className={`item like ${post.userLikedThePost ? 'liked' : ''}`}
                                                ng-click="$ctrl.like(post)"
                                                style={{
                                                    backgroundColor:
                                                        workspace.has_background_color_as_status
                                                            ? workspace.has_background_color_as_status &&
                                                              post.userLikedThePost
                                                                ? '#FFF'
                                                                : post.color
                                                            : '',
                                                    color: workspace.has_background_color_as_status
                                                        ? workspace.has_background_color_as_status &&
                                                          post.userLikedThePost
                                                            ? post.color
                                                            : '#FFF'
                                                        : ''
                                                }}>
                                                <i className="icon-like-1"></i>
                                                <span>{post.likesCount}</span>
                                            </div>
                                            <div
                                                className="item"
                                                onClick={() => goToPost(post)}
                                                style={{
                                                    backgroundColor:
                                                        workspace.has_background_color_as_status
                                                            ? post.color
                                                            : '',
                                                    color: workspace.has_background_color_as_status
                                                        ? '#FFF'
                                                        : ''
                                                }}>
                                                <i className="icon-messaging-imessage"></i>
                                                <span>{post.commentsCount}</span>
                                            </div>
                                            <div
                                                className="item"
                                                onClick={() => goToPost(post)}
                                                style={{
                                                    backgroundColor:
                                                        workspace.has_background_color_as_status
                                                            ? post.color
                                                            : '',
                                                    color: workspace.has_background_color_as_status
                                                        ? '#FFF'
                                                        : ''
                                                }}>
                                                <i className="icon-view-1"></i>
                                                <span>{post.viewsCount}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="status">
                                        <span
                                            style={{
                                                backgroundColor:
                                                    workspace.has_background_color_as_status
                                                        ? '#FFF'
                                                        : post.color,
                                                color: workspace.has_background_color_as_status
                                                    ? post.color
                                                    : ''
                                            }}>
                                            {post.statusLabel}
                                        </span>
                                    </div>
                                </div>

                                <div className="content" onClick={() => goToPost(post)}>
                                    {post.textInputs.map((text, index) => {
                                        return (
                                            <div
                                                className="text"
                                                key={`text_${post.id}_${index}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: text.text
                                                }}></div>
                                        );
                                    })}
                                </div>

                                <div className="actions" onClick={() => goToPost(post)}>
                                    <button
                                        className="action"
                                        style={{
                                            color: workspace.has_background_color_as_status
                                                ? '#FFF'
                                                : '',
                                            borderColor: workspace.has_background_color_as_status
                                                ? '#FFF'
                                                : ''
                                        }}>
                                        {t('POST_VIEW').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="empty">
                    <div className="message">
                        <div className="icon">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </div>
                        <div className="text">{t('BLOG_NO_RESULTS')}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Posts;
