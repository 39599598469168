import { isArray, trimEnd } from 'lodash-es';

export default function extractParamsFromUrl(source) {
    let url = new URL(source);

    return {
        url,
        pathname: trimEnd(url.pathname, '/'),
        params: url.search
            .slice(1)
            .split('&')
            .map((str) => {
                return str.split('=');
            })
            .reduce((accum, [key, val]) => {
                if (!accum.hasOwnProperty(key)) {
                    accum[key] = val;
                } else if (isArray(accum[key])) {
                    accum[key].push(val);
                } else {
                    accum[key] = [accum[key], val];
                }
                return accum;
            }, {})
    };
}
