import isImageAttachment from './isImageAttachment';
import isVideoAttachment from './isVideoAttachment';
import isAudioAttachment from './isAudioAttachment';

export default function getAllWithoutImagesVideosAndAudios(attachments) {
    let notImages = [];

    attachments.forEach((attachment) => {
        if (
            !isImageAttachment(attachment) &&
            !isVideoAttachment(attachment) &&
            !isAudioAttachment(attachment)
        ) {
            notImages.push(attachment);
        }
    });

    return notImages;
}
