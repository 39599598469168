import Profile from '../Profile';
import Badges from '../../Ranking/components/Badges';
import Preferences from '../components/Preferences';
import Subscriptions from '../components/Subscriptions';

export default [
    {
        name: 'auth.my-profile',
        url: `me?tab`,
        page: {
            type: 'string',
            value: null,
            squash: true
        },
        navbar: {
            getIcon: () => {
                return 'icon-single-man-circle';
            },
            getUserLabel: (context) => {
                return `${context.user.first_name} ${context.user.last_name}`;
            },
            getAdminLabel: (context) => {
                return context.t('TOP_PROFILE');
            },
            isActive: (context) => {
                return context.isActive(['auth.profile.**', 'auth.my-profile']);
            },
            isVisible: () => {
                return true;
            }
        },
        component: Profile
    },
    {
        name: 'auth.profile',
        url: 'user/:userId?page',
        component: Profile,
        title: 'RANK_PAGE_TITLE',
        params: {
            userId: {
                type: 'int'
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
                dynamic: true
            }
        },
        navbar: {
            getIcon: () => {
                return 'icon-award-trophy';
            },
            getLabel: (context) => {
                return context.t('RANK_PAGE_TITLE');
            },
            isActive: (context) => {
                return context.isActive(['auth.ranking.**']);
            },
            isVisible: (context) => {
                return (
                    (context.Right.hasRole('HAS_TA') && context.Right.hasRole('ROLE_INTERNAL')) ||
                    (context.Right.hasRole('HAS_TA_EXTERNAL') &&
                        !context.Right.hasRole('ROLE_INTERNAL'))
                );
            }
        }
    },
    { name: 'auth.profile.badges', url: '/badges', component: Badges },
    { name: 'auth.profile.preferences', url: '/preferences', component: Preferences },
    {
        name: 'auth.profile.subscriptions',
        url: '/subscriptions?open&categoryIds',
        component: Subscriptions
    }
];
