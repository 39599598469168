import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useAdminTreeContext } from '../../providers/AdminTreeProvider';
import { values } from 'lodash-es';
import TreeCategory from './components/TreeCategory';
import CategoryFormModal from './modals/CategoryFormModal';
import { useModal } from '../../../../providers/ModalContext';
import WorkspaceFormModal from './modals/WorkspaceFormModal';

const TreeContent = () => {
    const { t } = useTranslation();
    const [treeAsArray, setTreeAsArray] = useState([]);
    const { openModal } = useModal();
    const { fullTree } = useAdminTreeContext();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setTreeAsArray((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    useEffect(() => {
        if (fullTree) {
            setTreeAsArray(
                values(fullTree).map((v, index) => {
                    return { ...v, id: index + 1 };
                })
            );
        }
    }, [fullTree]);

    return (
        <div className="container admin__tree">
            <div className="row buttons">
                <button
                    className="button"
                    onClick={() => {
                        openModal('admin_category_form');
                    }}>
                    {t('ADMIN_TREE_ADD_CATEGORY')}
                </button>
                <button
                    className="button"
                    onClick={() => {
                        openModal('admin_workspace_form');
                    }}>
                    {t('ADMIN_TREE_ADD_WORKSPACE')}
                </button>
                <button className="button" ng-click="$ctrl.formMovePosts()">
                    {t('ADMIN_TREE_MOVE_POSTS')}
                </button>
                <button
                    className="button"
                    ng-if="$ctrl.User.hasRole('ROLE_SUPER_ADMIN')"
                    ng-click="$ctrl.exportWorkspaceData()">
                    {t('EXPORT_WORKSPACE_DATA')}
                </button>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}>
                <div className="tree-container">
                    <SortableContext items={treeAsArray} strategy={verticalListSortingStrategy}>
                        {treeAsArray.map((value) => {
                            return <TreeCategory key={value.id} id={value.id} {...value} />;
                        })}
                    </SortableContext>
                </div>
            </DndContext>
        </div>
    );
};

const Tree = () => {
    return (
        <>
            <WorkspaceFormModal />
            <CategoryFormModal />
            <TreeContent />
        </>
    );
};

export default Tree;
