import React from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import { useRouter } from '@uirouter/react';
import { useProfile } from '../../providers/ProfileProvider';
import useUser from '../../../../hooks/useUser';

const Tab = ({ user }) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { hasTrophies } = useRight();
    const { currentActiveTab } = useProfile();

    const { isMe } = useUser();

    if (!user) return;

    return (
        <>
            <a
                className={`tab ${currentActiveTab === 'posts' ? 'active' : ''}`}
                href={router.stateService.href('auth.profile', { userId: user.id })}
            >
                {t(isMe(user) ? 'PROFILE_TABS_MY_POSTS' : 'PROFILE_TABS_THEIR_POSTS')}
            </a>

            {hasTrophies() && (
                <a
                    className={`tab ${currentActiveTab === 'badges' ? 'active' : ''}`}
                    href={router.stateService.href('auth.profile.badges', { userId: user.id })}
                >
                    {t(isMe(user) ? 'PROFILE_TABS_BADGES' : 'PROFILE_TABS_THEIR_BADGES')}
                </a>
            )}

            {isMe(user) && (
                <>
                    <a
                        className={`tab ${currentActiveTab === 'preferences' ? 'active' : ''}`}
                        href={router.stateService.href('auth.profile.preferences', {
                            userId: user.id
                        })}
                    >
                        {t('PROFILE_TABS_PREFERENCES')}
                    </a>

                    <a
                        className={`tab ${currentActiveTab === 'subscriptions' ? 'active' : ''}`}
                        href={router.stateService.href('auth.profile.subscriptions', {
                            userId: user.id
                        })}
                    >
                        {t('PROFILE_TABS_DATAFEEDS')}
                    </a>
                </>
            )}
        </>
    );
};

export default Tab;
