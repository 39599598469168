import React, { useEffect, useState } from 'react';
import { Button, Input as AntdInput } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { useAlert } from '../../../providers/AlertContext';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';

const InputListContent = ({ name, value, onChange, ...props }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={true}
            render={({ field, formState }) => (
                <AntdInput
                    {...props}
                    {...field}
                    defaultValue={value}
                    onChange={(e) => {
                        field.onChange(e);
                        onChange(e.target.value);
                    }}
                />
            )}
        />
    );
};

const computeValues = (values) => {
    return values.map((value) => ({ _id: uniqueId(), value }));
};

function InputList({ name, value = [''], ...props }) {
    const { formState } = useFormContext();
    const [values, setValues] = useState(computeValues(value));
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const { showTextArea } = useAlert();

    useEffect(() => {
        const lastValue = values[values.length - 1];
        setButtonDisabled((!lastValue || lastValue.value.trim() === '') && values.length > 0);
    }, [values]);

    const handleInputChange = (_id, newValue) => {
        setValues((prev) =>
            prev.map((val) => (val._id === _id ? { ...val, value: newValue } : val))
        );
    };

    return (
        <div>
            {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
            {values.map((value, index) => (
                <div className={'input-list-row'} key={value._id}>
                    <InputListContent
                        className={'input'}
                        name={`${name}[${value._id}]`}
                        value={value.value}
                        onChange={(newValue) => handleInputChange(value._id, newValue)}
                    />
                    <Button
                        onClick={() => {
                            setValues((prev) => prev.filter((v) => v._id !== value._id));
                        }}>
                        <i className="fa fa-trash"></i>
                    </Button>
                </div>
            ))}
            <div style={{ display: 'flex', gap: 10 }}>
                <Button
                    onClick={() =>
                        setValues((prev) => [
                            ...prev,
                            {
                                _id: uniqueId(),
                                value: ''
                            }
                        ])
                    }
                    disabled={buttonDisabled}>
                    Ajouter une valeur
                </Button>
                <Button
                    onClick={() => {
                        showTextArea({
                            title: 'Ajouter plusieurs valeurs',
                            text: 'Une valeur par ligne',
                            button: {
                                label: 'Valider'
                            },
                            inputs: [
                                {
                                    label: 'Valeurs'
                                }
                            ]
                        })
                            .then(({ textAreas }) => {
                                setValues(
                                    computeValues(
                                        textAreas[0].split('\n').filter((v) => v.trim() !== '')
                                    )
                                );
                            })
                            .catch(() => {});
                    }}>
                    Ajouter plusieurs valeurs
                </Button>
            </div>
            {formState.errors[name] && (
                <span className="error">{formState.errors[name].message}</span>
            )}
        </div>
    );
}

export default InputList;
