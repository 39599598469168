const { useHttp } = require('api/useHttp');

const useSurveyCampaignService = () => {
    const axios = useHttp();

    const getAllSurveyCampaign = (status, page, showAll) => {
        return axios
            .get(`/dashboard/survey/campaigns?status=${status}&page=${page}&showAll=${showAll}`)
            .then((response) => response.data);
    };

    return {
        getAllSurveyCampaign
    };
};

export default useSurveyCampaignService;
