import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import RealTimeDate from 'components/RealTimeDate/RealTimeDate';
import { useRouter } from '@uirouter/react';
import useAttachmentIcon from 'hooks/useAttachmentIcon';
import useAttachment from 'hooks/useAttachment';
import { useAlert } from 'providers/AlertContext';
import useRecruitingCampaignSubmitService from '../../../api/useRecruitingCampaignSubmitService';
import useCommentService from '../../../../../api/useCommentService';
import { useQueryClient } from '@tanstack/react-query';

const Recruiting = ({ activity, isWidget = false }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { getAvatar } = useUser();
    const { attachmentIcon } = useAttachmentIcon();
    const { forgeAttachmentUrl } = useAttachment();
    const { showToast, showTextArea, showConfirm } = useAlert();
    const { askCV, rewardCampaignRecruitingSubmit, rejectCampaignRecruitingSubmit } =
        useRecruitingCampaignSubmitService();
    const { addComment } = useCommentService();
    const queryClient = useQueryClient();

    const relaunchApplicant = () => {
        askCV(activity.id).then(() => {
            showToast({
                text: t('TA_ACTIVITY_RECRUITING_RELAUNCH_CONFIRM'),
                duration: 5000
            });
        });
    };

    const rewardRecruitingCampaign = () => {
        showTextArea({
            button: {
                label: 'Envoyer',
                classes: ['red', 'bold']
            },
            textAreas: [
                {
                    label: t('TA_ACTIVITY_RECRUITING_FORM_MESSAGE_LABEL'),
                    value: t('TA_ACTIVITY_RECRUITING_FORM_MESSAGE', {
                        fullname: `${activity.submit.first_name} ${activity.submit.last_name}`,
                        campaignTitle: activity.campaign.title
                    })
                }
            ]
        })
            .then((textAreas) => {
                rewardCampaignRecruitingSubmit(activity.id).then((data) => {
                    activity.submit.row.status = data.new_status;

                    if (textAreas[0].value) {
                        const comment = {
                            content: textAreas[0].value,
                            activity_id: activity.id,
                            activity_type: activity.type
                        };

                        addComment(comment).then((comment) => {
                            queryClient.refetchQueries({ queryKey: ['activity', activity.id] });
                        });
                    }
                });
            })
            .catch((err) => {});
    };

    const rejectRecruitingCampaign = () => {
        showConfirm({
            title: 'Souhaitez-vous envoyer un e-mail pour justifier votre refus ?',
            text: 'Vous pouvez justifier votre refus et prévenir le coopteur.',
            cancelButtonLabel: 'Oui',
            button: {
                label: 'Non',
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                rejectCampaignRecruitingSubmit(activity.id, {}).then((data) => {
                    activity.submit.row.status = data.new_status;
                });
            })
            .catch((err) => {
                if (err.message === 'cancel') {
                    showTextArea({
                        button: {
                            label: 'Envoyer',
                            classes: ['red', 'bold']
                        },
                        textAreas: [
                            {
                                label: t('TA_ACTIVITY_RECRUITING_FORM_MESSAGE_APPLICANT_LABEL'),
                                value: t('TA_ACTIVITY_RECRUITING_FORM_REJECT_MESSAGE', {
                                    fullname: `${activity.submit.first_name} ${activity.submit.last_name}`,
                                    campaignTitle: activity.campaign.title
                                })
                            },
                            {
                                label: t('TA_ACTIVITY_RECRUITING_FORM_MESSAGE_LABEL'),
                                value: t('TA_ACTIVITY_RECRUITING_FORM_REJECT_COMMENT', {
                                    fullname: `${activity.submit.first_name} ${activity.submit.last_name}`,
                                    campaignTitle: activity.campaign.title
                                })
                            }
                        ]
                    })
                        .then((textAreas) => {
                            rejectCampaignRecruitingSubmit(activity.id, {
                                message: textAreas[0].value
                            }).then((data) => {
                                activity.submit.row.status = data.new_status;
                                if (textAreas[1].value) {
                                    const comment = {
                                        content: textAreas[1].value,
                                        activity_id: activity.id,
                                        activity_type: activity.type
                                    };
                                    addComment(comment).then((comment) => {
                                        queryClient.refetchQueries({
                                            queryKey: ['activity', activity.id]
                                        });
                                    });
                                }
                            });
                        })
                        .catch((err) => {});
                }
            });
    };

    return (
        <>
            {!isWidget && (
                <div className={`card ${activity.type}`}>
                    <div className="badge">
                        <i className="fa fa-users" aria-hidden="true"></i>
                    </div>

                    <div className="content">
                        <div className="campaign">{activity.campaign.title}</div>

                        {!activity?.submit?.hasAuthorization ? (
                            <div className="text">
                                {t('TA_ACTIVITIES_RECRUITING_TITLE_ANO', {
                                    referer_firstname: activity.submit.user.first_name,
                                    referer_name: activity.submit.user.last_name
                                })}
                            </div>
                        ) : (
                            <div className="text">
                                {t('TA_ACTIVITIES_RECRUITING_TITLE', {
                                    firstname: t(activity.submit.first_name),
                                    name: t(activity.submit.last_name),
                                    referer_firstname: activity.submit.user.first_name,
                                    referer_name: activity.submit.user.last_name
                                })}
                            </div>
                        )}

                        {activity.submit && activity.submit.from_social && (
                            <div className="from-social">
                                {t('TA_ACTIVITIES_RECRUITING_TITLE_VIA_RS')}
                            </div>
                        )}
                    </div>

                    <div className="meta">
                        <div className="date">
                            <RealTimeDate date={activity.submit.row.created_at} />
                        </div>

                        <div className="avatar">
                            <img src={getAvatar(activity.submit.row.user)} />
                        </div>
                    </div>
                </div>
            )}

            {activity?.submit?.hasAuthorization ? (
                <div className="submit">
                    {!isWidget && (
                        <div className="title">
                            {t('TA_ACTIVITY_RECRUITING_SUBMIT_INFORMATION')}
                        </div>
                    )}

                    {activity.submit && activity.submit.from_social && !isWidget && (
                        <div
                            className={`warning ${!!activity.submit.rating ? 'has-rated' : ''} ${activity.submit.rating && activity.submit.rating > 1 ? 'has-recommended' : ''}`}>
                            <div className="text">
                                {t('TA_ACTIVITY_RECRUITING_FROM_SOCIAL', {
                                    firstname: activity.submit.row.user.first_name,
                                    name: activity.submit.row.user.last_name
                                })}
                            </div>

                            {!activity?.submit?.rating && (
                                <div className="rating">
                                    {t('TA_ACTIVITY_RECRUITING_NO_RATING', {
                                        firstname: activity.submit.row.user.first_name
                                    })}
                                </div>
                            )}
                            {activity?.submit?.rating === 1 && (
                                <div className="rating">
                                    {t('TA_ACTIVITY_RECRUITING_RATING_UNKNOWN', {
                                        firstname: activity.submit.row.user.first_name
                                    })}
                                </div>
                            )}
                            {activity?.submit?.rating === 2 && (
                                <div className="rating">
                                    {t('TA_ACTIVITY_RECRUITING_RATING_RECOMMEND', {
                                        firstname: activity.submit.row.user.first_name
                                    })}
                                </div>
                            )}
                            {activity?.submit?.rating === 3 && (
                                <div className="rating">
                                    {t('TA_ACTIVITY_RECRUITING_RATING_RECOMMEND_PLUS', {
                                        firstname: activity.submit.row.user.first_name
                                    })}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="infos">
                        <div className="info">
                            <div className="label">
                                {t('TA_ACTIVITY_RECRUITING_FORM_FIRSTNAME')} :
                            </div>
                            <div className="value">{t(activity.submit.row.first_name)}</div>
                        </div>

                        <div className="info">
                            <div className="label">{t('TA_ACTIVITY_RECRUITING_FORM_NAME')} :</div>
                            <div className="value">{t(activity.submit.row.last_name)}</div>
                        </div>

                        <div className="info">
                            <div className="label">
                                {t('TA_ACTIVITY_RECRUITING_FORM_RELATION')} :
                            </div>
                            <div className="value">
                                {t(
                                    'TA_POST_RECRUITING_HOW_DO_YOU_KNOW_CANDIDATE_' +
                                        activity.submit.row.relationship.toUpperCase()
                                )}
                            </div>
                        </div>

                        <div className="info">
                            <div className="label">{t('TA_ACTIVITY_RECRUITING_FORM_EMAIL')} :</div>
                            <div className="value">{t(activity.submit.row.email)}</div>
                        </div>

                        <div className="info">
                            <div className="label">
                                {t('TA_ACTIVITY_RECRUITING_FORM_LINKEDIN')} :
                            </div>
                            <div className="value">
                                <a href="{ t(activity.submit.row.linked_in) }" target="_blank">
                                    {t(activity.submit.row.linked_in)}
                                </a>
                            </div>
                        </div>

                        <div className="info">
                            <div className="label">{t('TA_ACTIVITY_RECRUITING_FORM_PHONE')} :</div>
                            <div className="value">{t(activity.submit.row.phone)}</div>
                        </div>

                        <div className="info">
                            <div className="label">{t('REGION')} :</div>
                            <div className="value">{t(activity.submit.row.region)}</div>
                        </div>

                        <div className="info">
                            <div className="label">{t('CITY')} :</div>
                            <div className="value">{t(activity.submit.row.city)}</div>
                        </div>

                        <div className="info">
                            <div className="label">{t('JOB')}(s) :</div>
                            <div className="value">{t(activity.submit.row.jobs)}</div>
                        </div>

                        {activity?.submit?.row?.zone && (
                            <div className="info">
                                <div className="label">{t('TA_POST_RECRUITING_MOBILITY')} :</div>
                                <div className="value">{t(activity.submit.row.zone)}</div>
                            </div>
                        )}

                        {activity?.submit?.row?.custom_inputs &&
                            activity.submit.row.custom_inputs.map((input) => {
                                return (
                                    <div className="info" key={input.label}>
                                        <div className="label">{input.label} :</div>
                                        <div className="value">{input.value}</div>
                                    </div>
                                );
                            })}
                    </div>

                    {activity?.submit?.row?.attachments?.length > 0 && (
                        <>
                            <div className="title">
                                {t('TA_ACTIVITY_RECRUITING_FORM_ATTACHED_FILES')}
                            </div>
                            <div className="files">
                                {activity.submit.row.attachments.map((document) => {
                                    return (
                                        <div className="file">
                                            <div className="icon">
                                                <i
                                                    className={`fa ${attachmentIcon(document, 'class')}`}
                                                    style={{
                                                        color: attachmentIcon(document, 'color')
                                                    }}
                                                    aria-hidden="true"></i>
                                            </div>

                                            <div className="info">
                                                <div className="name">{document.name}</div>
                                                <div className="meta">{document.type}</div>
                                            </div>

                                            <div className="actions">
                                                <a
                                                    href={forgeAttachmentUrl(document)}
                                                    className="button"
                                                    target="_blank">
                                                    <i
                                                        className="fa fa-download"
                                                        aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {activity?.submit?.row?.attachment && (
                        <div className="files">
                            <div className="file">
                                <div className="icon">
                                    <i
                                        className={`fa ${attachmentIcon(activity.submit.row.attachment, 'class')}`}
                                        style={{
                                            color: attachmentIcon(
                                                activity.submit.row.attachment,
                                                'color'
                                            )
                                        }}
                                        aria-hidden="true"></i>
                                </div>

                                <div className="info">
                                    <div className="name">
                                        {activity.submit.row.attachment.name}
                                    </div>
                                    <div className="meta">
                                        {activity.submit.row.attachment.type}
                                    </div>
                                </div>

                                <div className="actions">
                                    <a
                                        href={forgeAttachmentUrl(activity.submit.row.attachment)}
                                        target="_blank"
                                        className="button">
                                        <i className="fa fa-download" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isWidget && (
                        <>
                            <div className="referer">
                                <div className="actions">
                                    <button
                                        type="button"
                                        onClick={relaunchApplicant}
                                        className="button">
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>{' '}
                                        {t('TA_ACTIVITY_RECRUITING_RELAUNCH')}
                                    </button>
                                </div>
                            </div>

                            <div className="title">
                                {t('TA_ACTIVITY_RECRUITING_USER_INFORMATION')}
                            </div>

                            <div className="referer">
                                <div className="user">
                                    <div className="avatar">
                                        <img src={getAvatar(activity.submit.row.user)} />
                                    </div>

                                    <div className="info">
                                        <div className="name">
                                            {activity.submit.row.user.first_name}{' '}
                                            {activity.submit.row.user.last_name}
                                        </div>
                                        <div className="job">{activity.submit.row.user.job}</div>
                                    </div>
                                </div>

                                <div className="actions">
                                    <a
                                        href={router.stateService.href('auth.profile', {
                                            userId: activity.submit.row.user.id
                                        })}
                                        className="button">
                                        <i className="fa fa-search" aria-hidden="true"></i>{' '}
                                        {t('TA_ACTIVITY_VIEW_PROFILE')}
                                    </a>
                                </div>
                            </div>
                            <div className="referer">
                                {activity?.submit?.row?.status === 1 && (
                                    <div className="actions">
                                        {activity?.campaign?.reward && (
                                            <button
                                                type="button"
                                                onClick={rewardRecruitingCampaign}
                                                className="button">
                                                <i className="fa fa-check" aria-hidden="true"></i>{' '}
                                                {t('TA_ACTIVITY_RECRUITING_KEEP')}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            onClick={rejectRecruitingCampaign}
                                            className="button">
                                            <i className="fa fa-close" aria-hidden="true"></i>{' '}
                                            {t('TA_ACTIVITY_RECRUITING_NO_KEEP')}
                                        </button>
                                    </div>
                                )}
                                {activity?.submit?.row?.status > 1 && (
                                    <div className="actions">
                                        {t('TA_ACTIVITY_RECRUITING_DONE')}
                                    </div>
                                )}
                            </div>
                            <div className="title">
                                {t('TA_ACTIVITY_KEEP_UPTODATE')}{' '}
                                {activity.submit.row.user.first_name}{' '}
                                {activity.submit.row.user.last_name}
                            </div>
                            <comments className="comments" object="activity"></comments>
                            <div className="post_add-comment">
                                <comment
                                    activity-item="activity"
                                    submit-callback="commentSubmitCallbackHandler"></comment>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="submit">
                    <div className="text">{t('TA_ACTIVITIES_RECRUITING_ANO')}</div>
                </div>
            )}
        </>
    );
};

export default Recruiting;
