import React, { useState } from 'react';
import useViewport from '../../../hooks/useViewport';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import Placeholder from 'components/Placeholder/Placeholder';
import useUser from 'hooks/useUser';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useChannels } from '../../Auth/providers/ChannelsProvider';
import channelOrderByLatestActivity from '../helpers/channlOrderByLatestActivity';
import channelFilterBy from '../helpers/channelFilterBy';
import useEmoji from '../../../hooks/useEmoji';

const Channels = () => {
    const router = useRouter();
    const { params } = useCurrentStateAndParams();
    const viewport = useViewport();
    const { t } = useTranslation();
    const { channels, isLoading } = useChannels();
    const { hasRole } = useRight();
    const { forgeAvatarUrl } = useUser();
    const { emoji } = useEmoji();

    const [searchText, setSearchText] = useState(null);

    const isActive = (channelId) => {
        return params.id === channelId;
    };

    const goToChannel = (channel) => {
        let routeParams = {
            id: channel.id
        };

        if (channel.unread && channel.unread > 0) {
            routeParams.unread = true;
        }

        return router.stateService.go('auth.chat.channel', routeParams, { inherit: false });
    };

    return (
        <div className="channels-wrapper">
            <div className="channels">
                {viewport.gtXs && (
                    <div className="header">
                        <div className="search">
                            <form autoComplete="off">
                                <div className="input">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                        placeholder={t('CHANNEL_SEARCH')}
                                    />
                                    {(!searchText || searchText === '') && (
                                        <i className="icon-search" aria-hidden="true"></i>
                                    )}
                                    {searchText && searchText !== '' && (
                                        <i
                                            className="icon-remove-circle cursor pointer"
                                            aria-hidden="true"
                                            onClick={() => {
                                                setSearchText(null);
                                            }}></i>
                                    )}
                                </div>
                            </form>
                        </div>

                        {hasRole('ROLE_INTERNAL') && (
                            <div className="actions">
                                <button className="action" ng-click="createChat()">
                                    <i className="icon-add" aria-hidden="true"></i>
                                </button>
                            </div>
                        )}
                    </div>
                )}

                <div className="body scrollable">
                    {!isLoading && channels && channels.length > 0 && (
                        <div className="list">
                            {channelOrderByLatestActivity(
                                channelFilterBy(channels, searchText)
                            ).map((channel) => {
                                return (
                                    <div
                                        className={`channel ${isActive(channel.id)}`}
                                        onClick={() => {
                                            goToChannel(channel);
                                        }}
                                        key={`channel_${channel.id}`}>
                                        <div className="avatar">
                                            {!channel.cover && (
                                                <div className="default">
                                                    <i className="icon-multiple-users-1"></i>
                                                </div>
                                            )}

                                            {channel.cover && (
                                                <img
                                                    src={forgeAvatarUrl(channel.cover)}
                                                    alt={channel.name}
                                                />
                                            )}
                                        </div>

                                        <div className="description">
                                            <div className="meta">
                                                <div className="name">{channel.name}</div>

                                                {!channel.last_message.content &&
                                                    !channel.last_message.attachment && (
                                                        <div className="preview">
                                                            {t('CHAT_NEW_CHAT_LABEL')}
                                                        </div>
                                                    )}

                                                {!channel.last_message.content &&
                                                    channel.last_message.attachment && (
                                                        <div className="preview">
                                                            <i
                                                                className="fa fa-fw fa-file-o"
                                                                aria-hidden="true"></i>{' '}
                                                            {t('FOLDER_HEAD_FILE')}
                                                        </div>
                                                    )}

                                                {channel.last_message.content && (
                                                    <div
                                                        className="preview"
                                                        dangerouslySetInnerHTML={{
                                                            __html: emoji(
                                                                channel.last_message.content
                                                            )
                                                        }}></div>
                                                )}
                                            </div>

                                            {channel.pinned && (
                                                <div className="pinned">
                                                    <i className="icon-pin" aria-hidden="true"></i>
                                                </div>
                                            )}
                                            <div className="actions">
                                                {channel.is_muted && (
                                                    <div className="is-muted">
                                                        <i
                                                            className="icon-alarm-bell-off"
                                                            aria-hidden="true"></i>
                                                    </div>
                                                )}
                                                {channel.unread > 0 && (
                                                    <div className="unread">{channel.unread}</div>
                                                )}

                                                <div className="date">
                                                    {channel.last_message && (
                                                        <realtime-date
                                                            date="{ channel.last_message.created_at }"
                                                            is-modified="false"
                                                            is-light="true"></realtime-date>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {isLoading && (
                        <div className="list">
                            <Placeholder number={20} template={'channel'} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Channels;
