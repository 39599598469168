import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTreeService from '../../../api/useTreeService';
import useRight from '../../../hooks/useRight';
import useWorkspaceService from '../../../api/useWorkspaceService';

const AdminTreeContext = createContext();

const AdminTreeProvider = ({ children }) => {
    const { getFullTree } = useTreeService();
    const { getAllPublicWorkspaces } = useWorkspaceService();
    const { hasRole } = useRight();

    const { data: fullTree } = useQuery({ queryKey: [`full_tree`], queryFn: () => getFullTree() });
    const { data: workspaces } = useQuery({
        queryKey: [`public_workspaces`],
        queryFn: () => getAllPublicWorkspaces()
    });

    const filterHiddenWorkspaces = (workspaces) => {
        if (hasRole('ROLE_SUPER_ADMIN')) {
            return workspaces;
        }

        return workspaces.filter((workspace) => {
            return workspace.workspace_data.workspace.hidden === false;
        });
    };

    return (
        <AdminTreeContext.Provider
            value={{
                fullTree,
                workspaces,
                filterHiddenWorkspaces
            }}>
            {children}
        </AdminTreeContext.Provider>
    );
};

export const useAdminTreeContext = () => useContext(AdminTreeContext);
export default AdminTreeProvider;
