import React, { useState, useEffect, useCallback, createElement, useRef, useMemo } from 'react';
import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'providers/ModalContext';
import useSearchBoxService from './api/useSearchBoxService';
import useTree from 'hooks/useTree';
import { useRouter } from '@uirouter/react';
import Workspaces from './SearchBox/components/partials/workspaces';
import Campaigns from './SearchBox/components/partials/campaigns';
import Channels from './SearchBox/components/partials/channels';
import Folders from './SearchBox/components/partials/folders';
import SharedTags from './SearchBox/components/partials/sharedTags';
import Tags from './SearchBox/components/partials/tags';
import Users from './SearchBox/components/partials/users';
import Categories from './SearchBox/components/partials/categories';
import keyboardArrowReturn from 'assets/images/keyboard-arrow-return.svg';
import useUser from 'hooks/useUser';
import useTag from 'hooks/useTag';

const partials = {
    workspaces: Workspaces,
    categories: Categories,
    campaigns: Campaigns,
    channels: Channels,
    folders: Folders,
    sharedTags: SharedTags,
    tags: Tags,
    users: Users
};

const MAX_RESULTS = 3;

const SearchModalContent = () => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [openWithKeyboardShortcut, setOpenWithKeyboardShortcut] = useState(true);
    const [expandedResults, setExpandedResults] = useState({});
    const [selectedResult, setSelectedResult] = useState(null);
    const [debounce, setDebounce] = useState(null);

    const { closeModal } = useModal();
    const { onQueryChange, getWorkspaceDataForFolder } = useSearchBoxService();
    const {
        getCategoryLink,
        getWorkspaceLink,
        getFolderLink,
        goToCategory,
        goToWorkspace,
        goToFolder
    } = useTree();
    const router = useRouter();
    const { goToProfileLink, getProfileLink } = useUser();
    const { goToTag } = useTag();
    const elementRef = useRef(null);

    const { data: results, isLoading } = useQuery({
        queryKey: ['search', query],
        queryFn: () => onQueryChange(query)
    });

    const hasQuery = useMemo(() => !!query, [query]);
    const hasResults = useMemo(() => !!results, [results]);

    const getTitle = (searcheable) => t(`SEARCH_BOX_RESULTS_TITLE_${searcheable.toUpperCase()}`);
    const getNavigationAction = () => {
        if (selectedResult) {
            let { action, searcheable } = selectedResult.dataset;

            switch (action) {
                case 'search':
                    return t('SEARCH_BOX_TIPS_ADVANCED_SEARCH');
                case 'expand':
                    if (isExpanded(searcheable)) {
                        return t('FOLDERS_VIEW_LESS');
                    }

                    return t('BLOG_READ_MORE');
                default:
                    return t('SEARCH_BOX_RESULTS_ACTION_GO_RESULT');
            }
        } else {
            return t('SEARCH_BOX_TIPS_ADVANCED_SEARCH');
        }
    };

    const onKeyDown = useCallback(
        (event) => {
            const { keyCode, ctrlKey = false, metaKey = false } = event;

            if (
                keyCode === 70 &&
                (ctrlKey || metaKey) &&
                openWithKeyboardShortcut &&
                !router.stateService.includes('auth.admin.**')
            ) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }

            if (keyCode === 38 || keyCode === 40) {
                setTimeout(() => {
                    selectResultByIncrement(keyCode === 38 ? -1 : 1);
                });
                event.preventDefault();
                event.stopImmediatePropagation();
            }

            if (keyCode === 13 && selectedResult) {
                const { action, searcheable, index } = selectedResult.dataset;
                if (action === 'search') {
                    // goToSearch();
                } else if (action === 'expand') {
                    const selectedResultIndex = Array.prototype.indexOf.call(
                        elementRef.current.querySelectorAll('.result'),
                        selectedResult
                    );
                    setTimeout(() => {
                        toggleExpandedResults(searcheable);
                        setTimeout(() => {
                            if (isExpanded(searcheable)) {
                                selectResult(
                                    elementRef.current.querySelectorAll('.result')[
                                        selectedResultIndex
                                    ]
                                );
                            } else {
                                scrollIfNeeded();
                            }
                        });
                    });
                } else {
                    goToResult(results[searcheable][searcheable][index], searcheable);
                }
            }
        },
        [openWithKeyboardShortcut, selectedResult, results]
    );

    const onMouseMove = useCallback((event) => {
        const target = event.target;
        let result = target.closest('.result');
        if (!result) {
            selectResult(null, false, true);
            return;
        }
        selectResult(result, false, true);
    }, []);

    const toggleExpandedResults = useCallback((searcheable) => {
        setExpandedResults((prev) => ({
            ...prev,
            [searcheable]: !prev[searcheable]
        }));
    }, []);

    const selectResult = useCallback(
        (result, scrollNeeded = true, timeout = false) => {
            setSelectedResult((prevState) => {
                if (prevState) {
                    prevState.classList.remove('selected');
                }
                if (result) {
                    result.classList.add('selected');
                }

                return result;
            });

            if (timeout) {
                setTimeout(() => {});
            }
            if (scrollNeeded) {
                scrollIfNeeded();
            }
        },
        [selectedResult]
    );

    const isExpanded = useCallback(
        (searcheable) => !!expandedResults[searcheable],
        [expandedResults]
    );

    const isExpandable = (results, searchable) => {
        return results[searchable].length > MAX_RESULTS;
    };

    const getResults = (results, searcheable) => {
        if (isExpanded(searcheable)) {
            return results[searcheable];
        }

        return results[searcheable].slice(0, MAX_RESULTS);
    };

    const selectResultByIncrement = useCallback(
        (increment) => {
            if (!hasResults) return;
            const resultEls = elementRef.current.querySelectorAll('.result');
            let selectedResultIndex = selectedResult
                ? Array.prototype.indexOf.call(resultEls, selectedResult)
                : -1;
            selectedResultIndex += increment;
            if (selectedResultIndex < 0 || selectedResultIndex >= resultEls.length) {
                return;
            }
            selectResult(resultEls[selectedResultIndex]);
        },
        [selectedResult, hasResults]
    );

    const scrollIfNeeded = () => {
        let scrollableEl = elementRef.current.querySelector('.has-results.scrollable');

        if (!scrollableEl) {
            return;
        }

        let {
            scrollHeight = 0,
            clientHeight: scrollableClientHeight = 0,
            scrollTop = 0
        } = scrollableEl;

        if (!selectedResult) {
            if (scrollTop !== 0) {
                scrollableEl.scrollTo({ top: 0 });
            }

            return;
        }

        if (scrollHeight <= scrollableClientHeight) {
            return;
        }

        let scrollTopWithHeight = scrollTop + scrollableClientHeight;

        let { offsetTop = 0, clientHeight = 0 } = selectedResult;

        let offsetTopWithHeight = offsetTop + clientHeight;

        let targetScrollTop = scrollTop;

        if (offsetTopWithHeight > scrollTopWithHeight) {
            targetScrollTop += offsetTopWithHeight - scrollTopWithHeight;
        }

        if (offsetTop < scrollTop) {
            targetScrollTop = offsetTop;
        }

        scrollableEl.scrollTo({ top: targetScrollTop });
    };

    const getResultLink = (result, searcheable) => {
        switch (searcheable) {
            case 'categories':
                return getCategoryLink(result);
            case 'workspaces':
                return getWorkspaceLink(result.workspace_data);
            case 'folders':
                return getFolderLink(
                    result,
                    getWorkspaceDataForFolder(result).workspace_data.workspace
                );
            case 'campaigns':
                return router.stateService.href(`auth.campaigns.campaign`, {
                    type: result.type,
                    id: result.id
                });
            case 'channels':
                return router.stateService.href(`auth.chat.channel`, { id: result.id });
            case 'tags':
            case 'sharedTags':
                return router.stateService.href(`auth.feed.posts.tag`, { tag: result.id });
            case 'users':
                return getProfileLink(result);
        }
    };

    const goToResult = (result, searcheable) => {
        switch (searcheable) {
            case 'categories':
                return goToCategory(result);
            case 'workspaces':
                return goToWorkspace(result.workspace_data);
            case 'folders':
                return goToFolder(
                    result,
                    getWorkspaceDataForFolder(result).workspace_data.workspace
                );
            case 'campaigns':
                return router.stateService.go(
                    `auth.campaigns.campaign`,
                    {
                        type: result.type,
                        id: result.id
                    },
                    { reload: true, inherit: false }
                );
            case 'channels':
                return router.stateService.go(
                    `auth.chat.channel`,
                    { id: result.id },
                    { reload: true, inherit: false }
                );
            case 'tags':
            case 'sharedTags':
                return goToTag(result);
            case 'users':
                return goToProfileLink(result);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        elementRef.current?.addEventListener('mousemove', onMouseMove);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            elementRef.current?.removeEventListener('mousemove', onMouseMove);
        };
    }, [onKeyDown, onMouseMove]);

    return (
        <div className="search-box v5" ref={elementRef}>
            <div className="form">
                <div className="icon">
                    <i className="icon-search" aria-hidden="true"></i>
                </div>
                <div className="input">
                    <input type="text" onChange={(event) => setQuery(event.target.value)} />
                    {!hasQuery && (
                        <div className="placeholder ellipsis">
                            <div className="ellipsis-text">{t('SEARCH_BOX_INPUT_PLACEHOLDER')}</div>
                        </div>
                    )}
                </div>
                {isLoading && (
                    <div className="is-searching">
                        <i className="icon-redo" aria-hidden="true"></i>
                    </div>
                )}
                <div className="close" onClick={() => closeModal('search-box')}>
                    <i className="icon-remove-circle" aria-hidden="true"></i>
                </div>
            </div>
            {hasQuery && (
                <div className="has-results scrollable">
                    <div className="searcheable padding-0">
                        <div className="results">
                            <div
                                className="result advanced-search"
                                data-action="search"
                                onClick={() => console.log('Advanced search action')}>
                                <div className="template">
                                    <div className="description">
                                        <div className="name">
                                            {t('SEARCH_BOX_ADVANCED_SEARCH', { query })}{' '}
                                            <i className="icon-arrow-left" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {results &&
                        Object.entries(results).map(
                            ([searcheable, items]) =>
                                items &&
                                items[searcheable] &&
                                items[searcheable].length > 0 && (
                                    <div key={searcheable} className={`searcheable ${searcheable}`}>
                                        <div className="title">
                                            <span className="text">{getTitle(searcheable)}</span>
                                            <span className="total">
                                                {t(
                                                    items[searcheable].length === 1
                                                        ? 'SEARCH_BOX_RESULTS_SINGULAR'
                                                        : 'SEARCH_BOX_RESULTS_PLURAL',
                                                    { total: items[searcheable].length }
                                                )}
                                            </span>
                                        </div>
                                        <div className="results">
                                            {getResults(items, searcheable).map((result, index) => (
                                                <a
                                                    key={index}
                                                    className="result has-hover"
                                                    href={getResultLink(result, searcheable)}
                                                    target="_blank"
                                                    data-searcheable={searcheable}
                                                    data-index={index}
                                                    rel="noopener noreferrer">
                                                    <div className="template">
                                                        {createElement(partials[searcheable], {
                                                            result,
                                                            searcheable,
                                                            query
                                                        })}
                                                    </div>
                                                    <div className="actions">
                                                        <div className="action">
                                                            {t(
                                                                'SEARCH_BOX_RESULTS_ACTION_GO_RESULT'
                                                            )}{' '}
                                                            <i className="icon-arrow-left"></i>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))}
                                            {isExpandable(items, searcheable) && (
                                                <div
                                                    className="result has-hover expand"
                                                    data-action="expand"
                                                    data-searcheable={searcheable}
                                                    onClick={() =>
                                                        toggleExpandedResults(searcheable)
                                                    }>
                                                    <div className="template">
                                                        <div className="description">
                                                            {!isExpanded(searcheable) ? (
                                                                <div className="name">
                                                                    <span className="text">
                                                                        {t('BLOG_READ_MORE')}
                                                                    </span>
                                                                    <i
                                                                        className="icon-move-bottom-right"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                            ) : (
                                                                <div className="name">
                                                                    <span className="text">
                                                                        {t('FOLDERS_VIEW_LESS')}
                                                                    </span>
                                                                    <i
                                                                        className="icon-move-top-left"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                        )}
                </div>
            )}
            <div className="tips">
                <div className="navigation">
                    <div className="icon">
                        <i className="icon-arrow-left" aria-hidden="true"></i>
                        <i className="icon-arrow-left down" aria-hidden="true"></i>
                    </div>
                    <div className="label">{t('SEARCH_BOX_TIPS_NAVIGATE')}</div>
                </div>
                <div className="navigation">
                    <div className="icon">
                        <img src={keyboardArrowReturn} alt="Keyboard Arrow Return" />
                    </div>
                    <div className="label">{getNavigationAction()}</div>
                </div>
                <div className="navigation hidden-xs is-right-aligned">
                    <div
                        className="checkbox"
                        onClick={() => console.log('Keyboard shortcut action')}>
                        <div
                            className="tip"
                            dangerouslySetInnerHTML={{
                                __html: t('SEARCH_BOX_TIPS_OPEN_WITH_KEYBOARD_SHORTCUT', {
                                    shortcut: 'Ctrl+F'
                                })
                            }}
                        />
                        <div className="input">
                            <i className="icon-check-2" aria-hidden="true"></i>
                        </div>
                        <div
                            className="label"
                            dangerouslySetInnerHTML={{
                                __html: t('SEARCH_BOX_OPEN_WITH_KEYBOARD_SHORTCUT', {
                                    shortcut: 'Ctrl+F'
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SearchModal = () => (
    <div className={'search-box-root'}>
        <Modal
            name={'search-box'}
            options={{ canScroll: false, hasPaddedContent: true, zIndex: 400, hideLoader: true }}>
            <SearchModalContent />
        </Modal>
    </div>
);

export default SearchModal;
