import React from 'react';
import { Button as AntdButton } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { useJoinDocumentModal } from 'pages/Auth/providers/JoinDocumentModalProvider';

const FileContent = ({ field, children, ...props }) => {
    const { resetField } = useFormContext();
    const JoinDocument = useJoinDocumentModal();

    const handleClick = () => {
        resetField(field.name);
        JoinDocument.createPanel(props?.joinDocumentOptions)
            .then((data) => {
                field.onChange(data.files);
            })
            .catch(() => {});
    };

    return (
        <div className={'file-button-row'}>
            <AntdButton className={'main'} {...props} onClick={handleClick}>
                {children}
            </AntdButton>
            {field.value && (
                <AntdButton
                    {...props}
                    danger
                    onClick={() => {
                        resetField(field.name);
                    }}>
                    <i className="fa fa-trash"></i>
                </AntdButton>
            )}
        </div>
    );
};

function FileButton({ name, value = null, children, ...props }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            shouldUnregister={
                props.hasOwnProperty('shouldUnregister') ? props.shouldUnregister : true
            }
            render={({ field, formState }) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px'
                    }}>
                    <FileContent field={field} {...props}>
                        {children}
                    </FileContent>

                    {formState.errors[name] && (
                        <span className="error">{formState.errors[name].message}</span>
                    )}
                </div>
            )}
        />
    );
}

export default FileButton;
