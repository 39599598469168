import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useLocation } from 'react-use';
import { useQuery } from '@tanstack/react-query';
import useBlogCategoryService from '../../../api/useBlogCategoryService';
import { orderBy } from 'lodash-es';

const BlogContext = createContext();

const BlogProvider = ({ children }) => {
    const router = useRouter();
    const { params } = useCurrentStateAndParams();
    const location = useLocation();
    const [currentState, setCurrentState] = useState(router.globals.current.name.split('.')[0]);
    const [isSearchState, setIsSearchState] = useState(
        router.globals.current.name === 'auth.blog.search'
    );
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentArticleType, setCurrentArticleType] = useState(null);
    const [showNavigation, setShowNavigation] = useState(true);
    const [categories, setCategories] = useState([]);
    const { getBlogCategories, getPublicBlogCategories } = useBlogCategoryService();

    const { data } = useQuery({
        queryKey: [`blog_categories_${currentState}`, 'blog_categories'],
        queryFn: () => {
            if (currentState === 'public') {
                return getPublicBlogCategories();
            }

            return getBlogCategories();
        }
    });

    useEffect(() => {
        if (data && data.length > 0) {
            let tmpCategories = [];
            data.forEach((category) => {
                if (category.parent) {
                    if (tmpCategories[category.parent.id]) {
                        tmpCategories[category.parent.id].children.push(category);
                    } else {
                        tmpCategories[category.parent.id] = {
                            ...category.parent,
                            children: [category]
                        };
                    }
                } else {
                    if (tmpCategories[category.id]) {
                        tmpCategories[category.id] = {
                            ...category,
                            ...tmpCategories[category.id]
                        };
                    } else {
                        tmpCategories[category.id] = {
                            ...category,
                            children: []
                        };
                    }
                }
            });

            tmpCategories = orderBy(tmpCategories, ['position'], 'asc');

            tmpCategories = tmpCategories
                .filter((c) => c)
                .map((category) => {
                    return {
                        ...category,
                        children: orderBy(category.children, ['position'], 'asc').filter((c) => c)
                    };
                });

            setCategories(tmpCategories);
        }
    }, [data]);

    useEffect(() => {
        setCurrentState(router.globals.current.name.split('.')[0]);
        setCurrentArticleType(params.type || null);
        setIsSearchState(router.globals.current.name === 'auth.blog.search');
    }, [location, router.globals.current.name]);

    return (
        <BlogContext.Provider
            value={{
                setShowNavigation,
                showNavigation,
                categories,
                currentCategory,
                currentArticleType,
                setCurrentCategory,
                currentState,
                isSearchState
            }}
        >
            {children}
        </BlogContext.Provider>
    );
};

export const useBlog = () => useContext(BlogContext);
export default BlogProvider;
