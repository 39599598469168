import { useHttp } from 'api/useHttp';

const useLinkService = () => {
    const axios = useHttp();

    const getLinkInfo = (url) => {
        return axios.post(`/links`, { url }).then((response) => response.data);
    };

    return {
        getLinkInfo
    };
};

export default useLinkService;
