import React, { useState } from 'react';
import useContent from 'hooks/useContent';
import { useTranslation } from 'react-i18next';

const LongText = ({ input }) => {
    const { formatContent } = useContent();
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);
    return (
        <>
            {input.workspace_form_input.label_render !== '' && input.text !== '' && (
                <div className="render_label">
                    {input.workspace_form_input.label_render} : &nbsp;
                </div>
            )}

            <div
                className={`render_content ${input.workspace_form_input.label.toLowerCase().split(' ').join('_')}`}
                dangerouslySetInnerHTML={{
                    __html: formatContent(input.text, input.hasShowMore, showMore)
                }}
            ></div>

            {input.hasShowMore && (
                <div className="actions">
                    <div
                        className="action"
                        onClick={() => {
                            setShowMore(!showMore);
                        }}
                    >
                        {showMore && (
                            <span className="show-less">
                                {t('FOLDERS_VIEW_LESS')}{' '}
                                <i className="icon-arrow-left" aria-hidden="true"></i>
                            </span>
                        )}
                        {!showMore && (
                            <span className="show-more">
                                {t('TA_POST_READ_MORE')}{' '}
                                <i className="icon-arrow-left" aria-hidden="true"></i>
                            </span>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default LongText;
