import React from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import useSearchBoxResultComponent from '../../hooks/useSearchBoxResultComponent';

const Categories = ({ result, searcheable, query }) => {
    const { t } = useTranslation();
    const { isAllowToWrite } = useRight();
    const { getResultWithQueryHighlight, goToComposer } = useSearchBoxResultComponent(query);

    return (
        <>
            <div className="description">
                <div
                    className="name"
                    dangerouslySetInnerHTML={{
                        __html: getResultWithQueryHighlight(result.name)
                    }}
                />
            </div>

            {isAllowToWrite(result, searcheable) && (
                <div className="actions">
                    <div
                        className="action primary"
                        onClick={(event) => goToComposer(result, searcheable, event)}>
                        {t('POST_TITLE')} <i className="icon-arrow-left"></i>
                    </div>
                </div>
            )}
        </>
    );
};

export default Categories;
