import { useTranslation } from 'react-i18next';
import { slice, split, join, map } from 'lodash-es';

const useProfileActivityCampaignComponent = () => {
    const { t } = useTranslation();

    const getCampaignTypeName = (type) => {
        switch (type) {
            case 'product':
                return t('TA_PRODUCT_TITLE');
            case 'social':
                return t('TA_SOCIAL_TITLE');
            case 'recruiting':
            case 'recruiting_share':
                return t('TA_RECRUITING_TITLE');
            case 'survey':
                return t('TA_SURVEY_TITLE');
            default:
                return '';
        }
    };

    const getCampaignTypeInitials = (type) => {
        let campaignTypeName = getCampaignTypeName(type);
        campaignTypeName = join(slice(split(campaignTypeName, /\s/), 0, 2), ' ');

        return join(
            map(split(campaignTypeName, /\s/), (s) => {
                return s.charAt(0);
            }),
            ''
        );
    };

    return {
        getCampaignTypeInitials,
        getCampaignTypeName
    };
};

export default useProfileActivityCampaignComponent;
