import { size, isEmpty, dropRight, findIndex } from 'lodash-es';
import useUtils from 'hooks/useUtils';
import useTree from 'hooks/useTree';

const Folder = ({ folders, selectedFolders, setSelectedFolders }) => {
    const { orderFoldersByName } = useUtils();
    const { getFolderLink } = useTree();
    const folderHasChildren = (children) => {
        return !isEmpty(children) && size(children) > 1;
    };

    const selectFolder = (folder) => {
        let folderIndex = findFolderIndex(folder);

        if (folderIndex !== -1) {
            setSelectedFolders(dropRight(selectedFolders, selectedFolders.length - folderIndex));
        } else {
            setSelectedFolders((prevState) => {
                return prevState.push(folder);
            });
        }
    };

    const findFolderIndex = (folder) => {
        return findIndex(selectedFolders, (selectedFolder) => {
            return folder.id === selectedFolder.id;
        });
    };

    const isFolderSelected = (folder) => {
        return findFolderIndex(folder) !== -1;
    };

    return (
        <>
            {folders[0] && folders[0].name && (
                <div className="folder">
                    <div className="meta">
                        <a
                            className="icon cursor pointer"
                            href={getFolderLink(folders[0])}
                            target="_blank">
                            <i className="icon-office-folder" aria-hidden="true"></i>
                        </a>

                        <a className="name" href={getFolderLink(folders[0])} target="_blank">
                            {folders[0].name}
                        </a>

                        {folderHasChildren(folders) && (
                            <div
                                className={`arrow ${isFolderSelected(folders[0]) ? 'arrow-down' : ''}`}
                                onClick={() => selectFolder(folders[0])}>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.37487 5.46873C9.47768 5.46813 9.57961 5.48784 9.67479 5.52672C9.76998 5.5656 9.85655 5.62288 9.92955 5.69529L16.1796 11.9453C16.2528 12.0179 16.3109 12.1043 16.3506 12.1995C16.3902 12.2947 16.4106 12.3968 16.4106 12.5C16.4106 12.6031 16.3902 12.7052 16.3506 12.8004C16.3109 12.8956 16.2528 12.982 16.1796 13.0547L9.92955 19.3047C9.78244 19.4518 9.58291 19.5344 9.37486 19.5344C9.16682 19.5344 8.96729 19.4518 8.82018 19.3047C8.67307 19.1576 8.59042 18.958 8.59042 18.75C8.59042 18.5419 8.67307 18.3424 8.82018 18.1953L14.5233 12.5L8.82018 6.80466C8.74695 6.73204 8.68883 6.64563 8.64917 6.55043C8.60951 6.45523 8.58909 6.35311 8.58909 6.24998C8.58909 6.14684 8.60951 6.04473 8.64917 5.94953C8.68883 5.85432 8.74695 5.76792 8.82018 5.69529C8.89318 5.62288 8.97975 5.5656 9.07494 5.52672C9.17012 5.48784 9.27205 5.46813 9.37487 5.46873Z"
                                        fill="#373A4D"
                                    />
                                </svg>
                            </div>
                        )}

                        {!folderHasChildren(folders) && isFolderSelected(folders[0]) && (
                            <div className="check-circle">
                                <i className="icon-check-circle-1-filled" aria-hidden="true"></i>
                            </div>
                        )}
                    </div>

                    {isFolderSelected(folders[0]) && (
                        <div className="children">
                            {orderFoldersByName(folders).map((folder) => {
                                return (
                                    <div className="items" key={`children_${folder.id}`}>
                                        <div className="item">
                                            <Folder
                                                folders={folder}
                                                selectedFolders={selectedFolders}
                                                setSelectedFolders={setSelectedFolders}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Folder;
