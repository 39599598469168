import React, { useState, useMemo, useRef } from 'react';
import { Select, Spin } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash-es';

const Autocomplete = ({ name, value = null, fetchOptions, debounceTimeout = 800, ...props }) => {
    const { control } = useFormContext();
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field }) => {
                return (
                    <div>
                        {props.label && <label htmlFor={name}>{props.label}</label>}{' '}
                        <Select
                            labelInValue
                            showSearch={true}
                            filterOption={false}
                            onSearch={debounceFetcher}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            value={field.value}
                            onSelect={({ value }) => {
                                field.onChange(value);
                            }}
                            onChange={({ value }) => {
                                field.onChange(value);
                            }}
                            {...props}
                            style={{
                                width: '100%'
                            }}
                            options={options}
                        />
                    </div>
                );
            }}
        />
    );
};

export default Autocomplete;
