import React, { useEffect, useState } from 'react';
import { useConfig } from 'providers/ConfigProvider';
import useDate from 'hooks/useDate';

const RealtimeDate = ({ date, format, isLight }) => {
    const { config } = useConfig();
    const { format: dateFormat } = useDate();
    const [originalDate, setOriginalDate] = useState(date);
    const [humanReadableDate, setHumanReadableDate] = useState(dateFormat(originalDate, 'human'));
    const [formattedDate, setFormattedDate] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        setOriginalDate(date);
    }, [date]);

    useEffect(() => {
        setHumanReadableDate(dateFormat(originalDate, 'human'));
    }, [originalDate, dateFormat]);

    useEffect(() => {
        const isToday = () => {
            const now = new Date();
            const diffDate = now - new Date(originalDate);
            return Math.floor(diffDate / (1000 * 60 * 60 * 24)) === 0;
        };

        const main = () => {
            if (isLight) {
                setFormattedDate(dateFormat(originalDate, 'light'));
            } else {
                setFormattedDate(dateFormat(originalDate, format));
            }
        };

        const cancelInterval = () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
        };

        cancelInterval();

        if (!config.isMsie && isToday()) {
            const id = setInterval(main, 60000);
            setIntervalId(id);
        }

        main();

        return () => {
            cancelInterval();
        };
    }, [originalDate, format, isLight, config, dateFormat]);

    return (
        <span className="date" title={humanReadableDate}>
            {formattedDate}
        </span>
    );
};

RealtimeDate.defaultProps = {
    isLight: false
};

export default RealtimeDate;
