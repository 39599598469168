import { useState } from 'react';

const ModalFormTabs = ({ children, tabs = [], onTabChange = () => {}, ...props }) => {
    const [currentTab, setCurrenTab] = useState(tabs ? tabs[0] : null);

    return (
        <div className="tabs" {...props}>
            {tabs.map((tab) => {
                return (
                    <button
                        type={'button'}
                        className={`tab ${currentTab === tab ? 'active' : ''}`}
                        onClick={() => {
                            setCurrenTab(tab);
                            onTabChange(tab);
                        }}>
                        {tab}
                    </button>
                );
            })}
        </div>
    );
};

export default ModalFormTabs;
