import { useEditorContext } from '../providers/EditorProvider';
import useEditorComponent from '../hooks/useEditorComponent';
import Custom from './templates/Custom';
import Default from './templates/Default';

const EditorFormTemplate = () => {
    const { workspace, isReview } = useEditorContext();
    const { workspaceHasCustomForm } = useEditorComponent(workspace);

    if (isReview) {
        return null;
    }
    if (workspaceHasCustomForm()) {
        return <Custom />;
    }

    return <Default />;
};

export default EditorFormTemplate;
