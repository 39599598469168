import Modal from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { useModal } from 'providers/ModalContext';
import { UIView, useCurrentStateAndParams, useRouter } from '@uirouter/react';
import useViewport from 'hooks/useViewport';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import useCampaignActivityService from '../../api/useCampaignActivityService';
import useRight from 'hooks/useRight';
import React, { createElement } from 'react';
import Product from './components/Product';
import Recruiting from './components/Recruiting';
import Survey from './components/Survey';

const partials = {
    product: Product,
    recruiting: Recruiting,
    survey: Survey,
    social: () => <></>
};

const DashboardActivities = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();
    const { params } = useCurrentStateAndParams();
    const router = useRouter();
    const viewport = useViewport();
    const { getAdminActivities } = useCampaignActivityService();
    const { TAViewAll } = useRight();

    const { data, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery({
        queryKey: ['activities', params.page, params.campaignId, params.campaignType],
        queryFn: ({ pageParam }) =>
            getAdminActivities(
                pageParam,
                {
                    campaignId: params.campaignId,
                    campaignType: params.campaignType
                },
                TAViewAll
            ),
        initialPageParam: params.page,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.length < 10) {
                return null;
            }
            return pages.length + 1;
        }
    });

    const isActive = useCallback(
        (activity) => {
            return activity.id === params.activity;
        },
        [params]
    );

    return (
        <div className="dashboard-activities-wrapper">
            <div className="activities">
                <div className="main">
                    <Modal
                        name={'activity_list'}
                        className="sidebar"
                        title={t('TA_ACTIVITIES_RECENT_LABEL')}
                        options={{ isSidebar: true, hasFlexContent: true }}>
                        <div className={`sidebar body scrollable ${isModalOpen('activity_list')}`}>
                            <div className="header">
                                <div className="title">{t('TA_ACTIVITIES_RECENT_LABEL')}</div>
                                {(params.campaignId || params.campaignType) && (
                                    <a
                                        href={router.stateService.href(
                                            'auth.dashboard.activities.home'
                                        )}
                                        type="button"
                                        className="button">
                                        <i className="fa fa-close" aria-hidden="true"></i> Filtres
                                    </a>
                                )}
                            </div>

                            <div className="body">
                                {data &&
                                    data?.pages.map((page) => {
                                        return page.map((activity) => {
                                            return (
                                                <a
                                                    //src="$ctrl.getTemplate(activity)"
                                                    className={`card ${activity.type} ${activity.is_read ? 'read' : ''} ${isActive(activity) ? 'active' : ''}`}
                                                    href={router.stateService.href(
                                                        'auth.dashboard.activities.home.view',
                                                        { activity: activity.id }
                                                    )}
                                                    key={`activity_${activity.id}`}>
                                                    {createElement(partials[activity.type], {
                                                        activity
                                                    })}
                                                </a>
                                            );
                                        });
                                    })}

                                {hasNextPage && (
                                    <div>
                                        <button
                                            className="button"
                                            onClick={fetchNextPage}
                                            disabled={isFetching}>
                                            {t('TA_ACTIVITIES_LOAD_MORE')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal>

                    {viewport.xs && (
                        <button className="button back" ng-click="$ctrl.openActivitiesList()">
                            <i className="fa fa-bars"></i> {t('TA_ACTIVITIES_RECENT_LABEL')}
                        </button>
                    )}

                    <UIView>
                        <>
                            <div className="content">
                                <div className="select-activity">
                                    <span ng-if="$ctrl.activities.length > 0">
                                        {t('TA_ACTIVITIES_SELECT_ACTIVITY')}
                                    </span>
                                    <span ng-if="$ctrl.activities.length == 0">
                                        {t('TA_ACTIVITIES_NO_ACTIVITY')}
                                    </span>
                                </div>
                            </div>
                        </>
                    </UIView>
                </div>
            </div>
        </div>
    );
};

export default DashboardActivities;
