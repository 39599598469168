import { useHttp } from 'api/useHttp';

const usePostService = () => {
    const axios = useHttp();

    const getPostsByWorkspaceAndDate = (workspaceId, timestamp) => {
        return axios
            .get(`/workspaces/${workspaceId}/posts/calendar`, {
                params: { timestamp }
            })
            .then((response) => response.data);
    };

    const getPostsByFolder = (folderId, page, type = 'default') => {
        return axios
            .get(`/folders/${folderId}/posts`, {
                params: { page, type }
            })
            .then((response) => response.data);
    };

    const getPostsByCategory = (categoryId, page, type = 'default') => {
        return axios
            .get(`/categories/${categoryId}/posts`, {
                params: { page, type }
            })
            .then((response) => response.data);
    };

    const getPostsByWorkspace = (
        workspaceId,
        page,
        status,
        query,
        type = 'default',
        daysLimit = null
    ) => {
        return axios
            .get(`/workspaces/${workspaceId}/posts`, {
                params: { page, status, query, type, daysLimit }
            })
            .then((response) => response.data);
    };

    const getPostById = (postId) => {
        return axios.get(`/posts/${postId}`).then((response) => [response.data]);
    };

    const getPosts = (page, limit = 15, mode = 'default', type = 'default') => {
        let options = {
            params: { page, limit, mode, type }
        };

        return axios.get('/posts/all', options).then((response) => response.data);
    };

    const hasScheduledPosts = () => {
        return axios.get('/users/has/posts/scheduled').then((response) => response.data);
    };

    const getPostsWaitingForReview = (forSlot = false, searchUser = null) => {
        let options = {
            params: { forSlot, searchUser }
        };
        return axios.get('/users/posts/review', options).then((response) => response.data);
    };

    const getPostsScheduledForReview = () => {
        return axios.get('/users/posts/scheduled/review').then((response) => response.data);
    };

    const getPostsByTag = (tagId, page, type = 'default', limit = 10) => {
        return axios
            .get(`/tags/${tagId}/posts?limit=${limit}`, {
                params: { page, type }
            })
            .then((response) => response.data);
    };

    const getTableRows = (workspaceId, filters) => {
        console.log('filters', filters);
        return axios
            .get(`/posts/table/${workspaceId}?filters=${filters}`)
            .then((response) => response.data);
    };

    const getTableFilters = (filters) => {
        return axios
            .get(`/posts/table/build/filters?filters=${filters}`)
            .then((response) => response.data);
    };

    return {
        getPostsByWorkspaceAndDate,
        getPostById,
        getPosts,
        getPostsByFolder,
        getPostsByCategory,
        getPostsByWorkspace,
        hasScheduledPosts,
        getPostsWaitingForReview,
        getPostsScheduledForReview,
        getPostsByTag,
        getTableRows,
        getTableFilters
    };
};

export default usePostService;
