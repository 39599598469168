import React, { createElement, useEffect } from 'react';
import { STEPS, useComposer } from 'pages/Auth/providers/ComposerContext';

const Composer = () => {
    useEffect(() => {}, []);
    const { currentStepIndex } = useComposer();

    return (
        <div className={'composer'}>
            <div className={'view'}>{createElement(STEPS[currentStepIndex].partial)}</div>
        </div>
    );
};

export default Composer;
