import { useHttp } from './useHttp';
import useUserService from './useUserService';
const useAchievementService = () => {
    const axios = useHttp();

    const { updateUser } = useUserService();

    const getMyPosition = () => {
        return axios.get(`/my/position`).then((response) => response.data);
    };

    const getPosition = (user) => {
        return axios.get(`/position/user/${user.id}`).then((response) => response.data);
    };

    const getAllAchievements = (userId = null) => {
        return axios.get(`/achievements/list?userId=${userId}`).then((response) => response.data);
    };

    const setUserAchievement = (user, achievement) => {
        const updatedUser = {
            ...user,
            achievement: {
                id: achievement.user_achievement_id,
                achievement
            }
        };

        return updateUser(updatedUser);
    };

    const getRank = (filter) => {
        return axios.get(`/global/rank?filter=${filter}`).then((response) => response.data);
    };

    const getAllRoutes = () => {
        return axios.get(`/all/routes`).then((response) => response.data);
    };

    const getCategories = () => {
        return axios.get('/achievement/categories').then((response) => response.data);
    };

    return {
        getMyPosition,
        getPosition,
        getAllAchievements,
        setUserAchievement,
        getRank,
        getAllRoutes,
        getCategories
    };
};

export default useAchievementService;
