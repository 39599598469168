import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRight from 'hooks/useRight';
import useViewport from 'hooks/useViewport';
import { defaultTo, range } from 'lodash-es';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQuery } from '@tanstack/react-query';
import useSearchService from 'api/useSearchService';
import useUser from 'hooks/useUser';
import { Pagination } from 'antd';
import LastConnectionModal from './modals/LastConnectionModal';
import { useModal } from 'providers/ModalContext';
import useUserService from '../../../../api/useUserService';
import ImportUserRewardsModal from './modals/ImportUserRewardsModal';
import { useAlert } from '../../../../providers/AlertContext';
import UserFormModal from './modals/UserFormModal';
import SendNotificationModal from './modals/SendNotificationModal';

const LETTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
];
const ROLES = [
    'ROLE_RS',
    'ROLE_SUPER_RS',
    'ROLE_RECRUITING',
    'ROLE_SUPER_RECRUITING',
    'ROLE_PRODUCT',
    'ROLE_SUPER_PRODUCT',
    'ROLE_SURVEY',
    'ROLE_SUPER_SURVEY',
    'ROLE_API',
    'ROLE_ADMIN_BLOG',
    'ROLE_METRICS',
    'ROLE_MISSION',
    'ROLE_SUPER_MISSION',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN'
];

const UsersContent = () => {
    const { t } = useTranslation();
    const viewport = useViewport();
    const router = useRouter();
    const { hasRole } = useRight();
    const { params } = useCurrentStateAndParams();
    const { getUsers, getAllUsers } = useSearchService();
    const { forgeAvatarUrl } = useUser();
    const { openModal } = useModal();
    const { getLastXConnections, eraseUser } = useUserService();
    const { showActionSheet } = useAlert();
    const { showConfirm, showToast } = useAlert();

    const [query, setQuery] = useState(`adminUsers_${[...Object.values(params)].join('_')}`);
    const [search, setSearch] = useState(defaultTo(params.q, null));
    const [letter, setLetter] = useState(defaultTo(params.letter, null));
    const [role, setRole] = useState(defaultTo(params.role, null));
    const [onlyDisabled, setOnlyDisabled] = useState(
        JSON.parse(defaultTo(params.onlyDisabled, false))
    );
    const [onlyExternal, setOnlyExternal] = useState(
        JSON.parse(defaultTo(params.onlyExternal, false))
    );
    const [onlyAdmins, setOnlyAdmins] = useState(JSON.parse(defaultTo(params.onlyAdmins, false)));

    const [users, setUsers] = useState([]);
    const [letters, setLetters] = useState([]);
    const [paging, setPaging] = useState(null);
    const [levelConfigs, setLevelConfigs] = useState(null);
    const [lastConnections, setLastConnections] = useState([]);

    const { data } = useQuery({
        queryKey: ['adminUsers', query],
        queryFn: () => {
            if (params.q) {
                return getUsers(params, true);
            }
            return getAllUsers(params, true);
        }
    });

    useEffect(() => {
        setOnlyDisabled(JSON.parse(defaultTo(params.onlyDisabled, false)));
        setOnlyExternal(JSON.parse(defaultTo(params.onlyExternal, false)));
        setOnlyAdmins(JSON.parse(defaultTo(params.onlyAdmins, false)));
        setSearch(defaultTo(params.q, null));
        setLetter(defaultTo(params.letter, null));
        setRole(defaultTo(params.role, null));
        setQuery(`adminUsers_${[...Object.values(params)].join('_')}`);
    }, [params]);

    useEffect(() => {
        if (data && data.users) {
            setUsers(data.users);
            setLetters(data.letters);
            setPaging(data.paging);
            setLevelConfigs(data.levelConfigs);
        }
    }, [data]);

    const eraseTAPoints = (user) => {
        showConfirm({
            title: `RàZ les points TA`,
            text: `Voulez-vous remettre à zéro les points de l'utilisateur ?`,
            button: {
                label: 'Remettre à zéro',
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                eraseUser(user).then(() => {
                    showToast({
                        text: `L'utilisateur a bien été remis à zéro.`,
                        duration: 1500
                    });
                });
            })
            .catch((err) => {});
    };

    const hasLetter = (letter) => {
        if (!letters) {
            return false;
        }

        let found = false;
        letters.forEach((letterService) => {
            if (letterService.letter === letter) {
                found = true;

                return true;
            }
        });

        return found;
    };

    const handleFilter = (params) => {
        router.stateService.go('.', params);
    };

    const seeLastConnections = (user, limit) => {
        setLastConnections([]);
        getLastXConnections(user, limit).then((data) => {
            setLastConnections(data);
            openModal('last_connections');
        });
    };

    /*const disableUser = (user) => {
        showConfirm({
            title: `Désactiver cet utilisateur`,
            text: `Voulez-vous désactiver cet utilisateur ?`,
            button: {
                label: 'Désactiver',
                classes: ['red', 'bold'],
            },
        }).then(() => {
            deleteUser(user).then(() => {
                showConfirm({
                    title: `Rendre anonyme cet utilisateur`,
                    text: `Voulez-vous rendre anonyme cet utilisateur ?`,
                    button: {
                        label: 'Rendre anonyme',
                        classes: ['red', 'bold'],
                    },
                    cancelButtonLabel : 'Ne pas anonymiser',
                }).then(() => {
                    this.$state.reload();
                    anonymizeUser(user).then(() => {
                        showToast({
                            text: `L'utilisateur a bien été désactivé.`,
                            duration: 1500,
                        });
                    });
                }).catch(() => {
                    this.$state.reload();
                    showToast({
                        text: `L'utilisateur a bien été désactivé.`,
                        duration: 1500,
                    });
                });
            });
        }).catch((err) => {});
    }

    const enableUser = (user) => {
        showConfirm({
            title: `Activer cet utilisateur`,
            text: `Voulez-vous activer cet utilisateur ?`,
            button: {
                label: 'Activer',
                classes: ['red', 'bold'],
            },
        }).then(() => {
            //this.$state.reload();
            enableUser(user).then(() => {
                showToast({
                    text: `L'utilisateur a bien été activé.`,
                    duration: 1500,
                });
            });
        }).catch((err) => {});
    }*/

    const showUserActions = (user) => {
        let buttons = [];

        buttons.push({
            label: "Modifier l'utilisateur",
            callback: () => {
                //this.editUser(user);
            }
        });

        if (hasRole('ROLE_SUPER_ADMIN')) {
            buttons.push({
                label: 'Modifier son mot de passe',
                callback: () => {
                    //this.changePassword(user);
                }
            });
        }

        if (hasRole('HAS_TA')) {
            buttons.push({
                label: 'Supprimer ses points TA',
                classes: ['red'],
                callback: () => {
                    eraseTAPoints(user);
                }
            });
        }

        if (user.enabled) {
            buttons.push({
                label: "Désactiver l'utilisateur",
                classes: ['red'],
                callback: () => {
                    //this.disableUser(user);
                }
            });
        } else {
            buttons.push({
                label: "Activer l'utilisateur",
                classes: ['red'],
                callback: () => {
                    //this.enableUser(user);
                }
            });
        }

        showActionSheet({
            title: 'Options',
            buttons
        });
    };

    return (
        <>
            <LastConnectionModal lastConnections={lastConnections} />
            <ImportUserRewardsModal />
            <UserFormModal />
            <SendNotificationModal />
            <div className="container admin__users">
                <div className="row buttons">
                    <button
                        className="button"
                        onClick={() => {
                            openModal('add_edit_user');
                        }}>
                        {t('ADMIN_USERS_ADD_USER_LABEL')}
                    </button>
                    {hasRole('ALLOW_IMPORT_EMPLOYEES') && (
                        <button className="button" ng-click="importEmployee()">
                            {t('ADMIN_USERS_IMPORT_EMPLOYEES_LABEL')}
                        </button>
                    )}

                    <button
                        className="button"
                        onClick={() => {
                            openModal('send-notification');
                        }}>
                        {t('ADMIN_USERS_SEND_NOTIFICATION_LABEL')}
                    </button>
                    <button
                        className="button"
                        onClick={() => {
                            openModal('import_user_rewards');
                        }}>
                        {t('ADMIN_USERS_IMPORT_REWARDS')}
                    </button>
                </div>

                <div className="admin__users__letters">
                    {viewport.xs && (
                        <div className="mobile">
                            <select
                                value={letter}
                                onChange={(e) => {
                                    handleFilter({ ...params, page: 1, letter: e.target.value });
                                }}>
                                <option value="">Filtrer par lettre</option>
                                {LETTERS.map((l) => {
                                    return (
                                        <option
                                            value={l}
                                            disabled={!hasLetter(l)}
                                            key={`select_letter_${l}`}>
                                            {l}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}

                    {viewport.gtXs && (
                        <div className="desktop">
                            {LETTERS.map((l, index) => {
                                return (
                                    <button
                                        type="button"
                                        className="letter"
                                        onClick={() => {
                                            handleFilter({ ...params, page: 1, letter: l });
                                        }}
                                        disabled={!hasLetter(l)}
                                        key={`letter_${index}`}>
                                        {l}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="admin__users__letters">
                    <div className="option">
                        <label htmlFor="only_disabled">Utilisateurs désactivés</label>
                        <input
                            type="checkbox"
                            id="only_disabled"
                            checked={onlyDisabled}
                            onChange={(e) => {
                                handleFilter({
                                    ...params,
                                    page: 1,
                                    onlyDisabled: e.target.checked
                                });
                            }}
                        />
                    </div>

                    <div className="option">
                        <label htmlFor="only_external">Utilisateurs externes</label>
                        <input
                            type="checkbox"
                            id="only_external"
                            checked={onlyExternal}
                            onChange={(e) => {
                                handleFilter({
                                    ...params,
                                    page: 1,
                                    onlyExternal: e.target.checked
                                });
                            }}
                        />
                    </div>

                    {hasRole('ROLE_SUPER_ADMIN') && (
                        <div className="option">
                            <label htmlFor="only_admins">Utilisateurs admins</label>
                            <input
                                type="checkbox"
                                id="only_admins"
                                checked={onlyAdmins}
                                onChange={(e) => {
                                    handleFilter({
                                        ...params,
                                        page: 1,
                                        onlyAdmins: e.target.checked
                                    });
                                }}
                            />
                        </div>
                    )}

                    <div>
                        <select
                            value={role}
                            className="option-list"
                            onChange={(e) => {
                                handleFilter({ ...params, page: 1, role: e.target.value });
                            }}>
                            <option value="">Filtrer par rôle</option>
                            {ROLES.map((r) => {
                                return (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <form className="search-form-tree">
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            placeholder={t('ADMIN_USERS_SEARCH_LABEL')}
                        />
                        <button
                            onClick={() => {
                                if (search) {
                                    handleFilter({ ...params, page: 1, q: search });
                                }
                            }}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>

                <div className="list">
                    {users.map((user) => {
                        return (
                            <div className="item" key={`user_${user.id}`}>
                                <div className={`user ${!user.enabled ? 'disabled' : ''}`}>
                                    <div className="meta">
                                        <div className="avatar">
                                            <img src={forgeAvatarUrl(user.image_profile_name)} />

                                            {hasRole('ROLE_SUPER_ADMIN') && (
                                                <button ng-click="uploadAvatar(user.id)">
                                                    <i
                                                        className="fa fa-camera"
                                                        aria-hidden="true"></i>
                                                </button>
                                            )}
                                        </div>
                                        <div className="profile">
                                            <div className="name">
                                                {user.first_name} {user.last_name} (ID: {user.id})
                                            </div>
                                            <div className="job">{user.job}</div>
                                            <div className="company">{user.company}</div>
                                            {!user.enabled && user.disable_reason && (
                                                <div className="disabled">
                                                    {t(user.disable_reason)}
                                                </div>
                                            )}
                                            {!user.enabled && !user.disable_reason && (
                                                <div className="disabled">
                                                    {t('ADMIN_USERS_DISABLED')}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {viewport.gtXs && (
                                        <div className="actions">
                                            <button
                                                className="button"
                                                type="button"
                                                onClick={() => {
                                                    openModal('add_edit_user', {
                                                        currentUser: user
                                                    });
                                                }}>
                                                <i className="icon-pencil-1" aria-hidden="true"></i>
                                            </button>

                                            {hasRole('ROLE_SUPER_ADMIN') && (
                                                <button
                                                    className="button"
                                                    type="button"
                                                    ng-click="changePassword(user)">
                                                    <i
                                                        className="fa fa-fw fa-key"
                                                        aria-hidden="true"></i>
                                                </button>
                                            )}

                                            {hasRole('HAS_TA') && (
                                                <button
                                                    className="button"
                                                    type="button"
                                                    onClick={() => eraseTAPoints(user)}>
                                                    <i
                                                        className="fa fa-fw fa-eraser"
                                                        aria-hidden="true"></i>
                                                </button>
                                            )}

                                            {user.enabled === true && (
                                                <button
                                                    className="button"
                                                    type="button"
                                                    ng-click="disableUser(user)">
                                                    <i
                                                        className="icon-bin-2"
                                                        aria-hidden="true"></i>
                                                </button>
                                            )}

                                            {user.enabled === false && (
                                                <button
                                                    className="button"
                                                    type="button"
                                                    ng-click="enableUser(user)">
                                                    <i
                                                        className="icon-flash"
                                                        aria-hidden="true"></i>
                                                </button>
                                            )}

                                            {hasRole('ROLE_SUPER_ADMIN') && (
                                                <>
                                                    <button
                                                        className="button"
                                                        type="button"
                                                        ng-click="switchUser(user)">
                                                        <i
                                                            className="icon-glasses-retro"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                    <button
                                                        className="button"
                                                        type="button"
                                                        onClick={() => {
                                                            seeLastConnections(user, 5);
                                                        }}>
                                                        <i
                                                            className="icon-desktop-monitor-smiley"
                                                            aria-hidden="true"></i>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    {viewport.xs && (
                                        <div className="actions">
                                            <button
                                                className="button"
                                                type="button"
                                                onClick={() => {
                                                    showUserActions(user);
                                                }}>
                                                <i className="fa fa-cogs" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {paging && (
                    <div className="pagination">
                        <Pagination
                            defaultCurrent={params.page}
                            pageSize={20}
                            total={paging.total}
                            showSizeChanger={false}
                            onChange={(page) => {
                                handleFilter({ ...params, page });
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const Users = () => {
    return <UsersContent />;
};
export default Users;
