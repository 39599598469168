import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const EditorContext = createContext();

const EditorProvider = ({ children, workspace, isPreview, isReview, form }) => {
    const { getValues, watch } = useFormContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [allowUseEmail, setAllowUseEmail] = useState(false);
    const [currentFormValues, setCurrentFormValue] = useState({});

    useEffect(() => {
        setCurrentFormValue(getValues('form'));
    }, [getValues('form'), watch('form')]);

    const goToPreviousPage = () => {};

    const goToNextPage = () => {};

    const goToPage = () => {};

    const isEditing = useCallback(() => {
        return !!form?.id;
    }, [form]);

    return (
        <EditorContext.Provider
            value={{
                workspace,
                isPreview,
                isReview,
                currentPage,
                goToPreviousPage,
                goToNextPage,
                goToPage,
                currentFormValues,
                allowUseEmail,
                isEditing,
                form
            }}>
            {children}
        </EditorContext.Provider>
    );
};

export const useEditorContext = () => useContext(EditorContext);
export default EditorProvider;
