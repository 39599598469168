import { forEach, cloneDeep, concat, size } from 'lodash-es';

export default function processTreeCategories(tree) {
    let categories = [];

    forEach(tree, (item) => {
        if (item.category) {
            if (size(item.workspaces) === 0) {
                return;
            }

            let category = cloneDeep(item.category);

            category.workspaces = [];

            forEach(item.workspaces, (item) => {
                if (item.workspace_data) {
                    category.workspaces.push(item.workspace_data);
                }
            });

            categories.push(category);

            categories = concat(categories, processTreeCategories(item.workspaces));
        }
    });

    return categories;
}
