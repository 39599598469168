import React from 'react';

import ItemNavbar from '../item-navbar/item-navbar.component';
import { useRouter } from '@uirouter/react';
import { useAlert } from 'providers/AlertContext';
import useViewport from 'hooks/useViewport';
import { useItemNavbarContext } from '../providers/ItemProvider';

export default function DropdownItemNavbar({ state, context }) {
    const router = useRouter();
    const { isClicked, setIsClicked } = useItemNavbarContext();
    const { showActionSheet } = useAlert();
    const viewport = useViewport();

    const isMobile = () => {
        return viewport.xs;
    };

    const onClick = () => {
        if (isMobile()) {
            showActionSheet({
                title: state.label,
                buttons: state.children
                    .filter((child) => child.isVisible(context, child))
                    .map((child) => {
                        return {
                            label: child.getLabel ? child.getLabel(context, child) : child.label,
                            callback: () => {
                                if (child.callback) {
                                    child.callback(context, child);
                                } else {
                                    router.stateService.go(
                                        child.name,
                                        {},
                                        { reload: true, inherit: false }
                                    );
                                }
                            }
                        };
                    })
            });
        } else {
            setIsClicked((prevClicked) => !prevClicked);
        }
    };

    return (
        <>
            <ItemNavbar state={state} onClick={onClick} context={context} />

            {isClicked && (
                <div className="children">
                    {state.children.map((child, index) => {
                        return (
                            <div key={index} className="item">
                                <ItemNavbar state={child} context={context} />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
