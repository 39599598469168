import { useTranslation } from 'react-i18next';
import Placeholder from '../Placeholder/Placeholder';

const NotificationStacked = ({ notifications }) => {
    const { t } = useTranslation();

    return (
        <>
            {notifications && notifications.length === 0 && (
                <div className="no-notification">
                    <div className="message">
                        <div className="icon">
                            <i className="fa fa-bell-slash-o" aria-hidden="true"></i>
                        </div>
                        <div className="text">{t('NOTIFICATION_EMPTY')}</div>
                    </div>
                </div>
            )}

            {!notifications && (
                <div className="notification-placeholder-container">
                    <Placeholder number={10} template={'notification'} />
                </div>
            )}

            {notifications &&
                notifications.length > 0 &&
                notifications.map((notification) => {
                    return (
                        <div ng-click="$ctrl.goToPage(row.data, row.type)">
                            <div
                                className={`notification ${notification.type} ${!notification.read ? 'unread' : ''}`}>
                                test
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default NotificationStacked;
