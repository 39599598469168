import { useHttp } from 'api/useHttp';

const useTriggerService = () => {
    const axios = useHttp();

    const getAllAdminTriggers = () => {
        return axios.get(`/triggers`).then((response) => response.data);
    };

    const postAdminTrigger = (trigger) => {
        return axios.post(`/triggers`, trigger).then((response) => response.data);
    };

    const putAdminTrigger = (trigger) => {
        return axios.put(`/triggers/${trigger.id}`, trigger).then((response) => response.data);
    };

    const deleteAdminTrigger = (id) => {
        return axios.delete(`/triggers/${id}`).then((response) => response.data);
    };

    return {
        getAllAdminTriggers,
        postAdminTrigger,
        putAdminTrigger,
        deleteAdminTrigger
    };
};

export default useTriggerService;
