import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/AuthContext';
import { indexOf, orderBy } from 'lodash-es';
import dayjs from 'dayjs';
import useWorkspaceService from 'api/useWorkspaceService';
import classes from 'classnames';
import CommentRender from './components/CommentRender';
import useRight from '../../hooks/useRight';

const Comments = ({ object, inCarrousel = false }) => {
    const { user } = useAuth();
    const { hasRole } = useRight();
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [prevPage, setPrevPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [type, setType] = useState(null);
    const [comments, setComments] = useState(null);

    const { putWorkspaceUser } = useWorkspaceService();

    useEffect(() => {
        setType(object.type || 'post');
        processComments();
    }, [object]);

    const processComments = () => {
        if (object?.type === 'post') {
            let hasNewComments = object.comments.some((comment) => {
                return indexOf(user.unread_comments, parseInt(comment.id)) !== -1;
            });

            if (hasNewComments) {
                putWorkspaceUser(object.workspace.id).then(() => {
                    /*this.User.resetCache();
                    this.Posts.resetCache();*/
                });
            }
        }

        setComments(
            orderBy(
                object.comments.map((comment) => {
                    return {
                        ...comment,
                        created_at_as_unix_time: dayjs(comment.created_at).unix()
                    };
                }),
                ['created_at_as_unix_time'],
                ['asc']
            )
        );
    };

    const COMMENTS_PER_PAGE = 3;

    const rangeComments = (comments) => {
        let offset = (currentPage - 1) * COMMENTS_PER_PAGE;

        if (offset > comments.length - COMMENTS_PER_PAGE) {
            offset = comments.length - COMMENTS_PER_PAGE;
        }

        let end = comments.length - offset;
        let start = end - COMMENTS_PER_PAGE;

        let items = comments.slice(start, comments.length);

        if (currentPage > 1) {
            let commentToHighlight = items.length - prevPage * COMMENTS_PER_PAGE;

            for (let i = 0; i < commentToHighlight; i++) {
                items[i].is_highlighted = true;
            }
        }

        return items;
    };

    const isCommentReported = (comment) => {
        if (!comment.reports || comment.reports.length === 0) {
            return false;
        }

        return comment.reports.some((report) => {
            return report.user.id === user.id;
        });
    };

    const getComments = (sticky = false) => {
        if (!comments) return [];

        return comments.filter((comment) => {
            return comment.sticky === sticky;
        });
    };

    const hasLessThan3Pages = () => {
        return getComments().length <= COMMENTS_PER_PAGE * 3;
    };

    const hasNextPage = () => {
        let offset = (currentPage - 1) * COMMENTS_PER_PAGE;

        return offset < getComments().length - COMMENTS_PER_PAGE;
    };

    const nextPage = () => {
        setCurrentPage((prevState) => {
            setPrevPage(prevState);
            return prevState + 1;
        });
    };

    const hasPreviousPage = () => {
        return currentPage !== 1;
    };

    const showAllComments = () => {
        setPage(Math.ceil(getComments().length / COMMENTS_PER_PAGE));
    };

    const setPage = (page) => {
        setPrevPage(currentPage);
        setCurrentPage(page);
    };

    const getTotalCommentsNotShown = () => {
        return getComments().length - COMMENTS_PER_PAGE * currentPage;
    };

    const getCommentClasses = (comment) => {
        const commentClasses = [];

        if (isCommentReported(comment)) {
            commentClasses.push('is-reported');
        }

        if (hasRole('ROLE_HIDE_LIST_USERS')) {
            commentClasses.push('hide-list-users');
        }

        if (comment.is_highlighted) {
            commentClasses.push('is-highlighted');
        }

        if (comment.sticky) {
            commentClasses.push('is-sticky');
        }

        return commentClasses;
    };

    const commentDeleteCallback = (comment) => {
        setComments(
            comments.filter((item) => {
                return item.id !== comment.id;
            })
        );
    };

    const commentStickyCallback = (comment) => {
        setComments((prevComments) => {
            return prevComments.map((cmt) =>
                cmt.id === comment.id ? { ...cmt, sticky: comment.sticky } : cmt
            );
        });
    };

    return (
        <div className={'comments-wrapper'}>
            {!showAll && (
                <div className="comments">
                    {getComments(true).map((comment) => {
                        return (
                            <CommentRender
                                className={`comment ${classes(getCommentClasses(comment))}`}
                                comment={comment}
                                object={object}
                                inCarousel={inCarrousel}
                                deleteCallback={commentDeleteCallback}
                                stickyCallback={commentStickyCallback}
                                key={comment.id}
                            ></CommentRender>
                        );
                    })}

                    {hasNextPage() && (
                        <div className="expand">
                            {!hasLessThan3Pages() && (
                                <button onClick={nextPage}>
                                    <i className="icon-messages-bubble-double"></i>{' '}
                                    {t('COMMENTS_NEXT_PAGE')}
                                </button>
                            )}

                            {hasLessThan3Pages() && (
                                <button onClick={showAllComments}>
                                    <i className="icon-messages-bubble-double"></i>{' '}
                                    {t('POST_SHOW_COMMENTS', {
                                        comment_number: getTotalCommentsNotShown()
                                    })}
                                </button>
                            )}
                        </div>
                    )}

                    {!hasNextPage() && hasPreviousPage() && (
                        <div className="expand">
                            <button
                                onClick={() => {
                                    setPage(1);
                                }}
                            >
                                <i className="icon-messages-bubble-double"></i>{' '}
                                {t('POST_SHOW_LESS_COMMENTS')}
                            </button>
                        </div>
                    )}

                    {rangeComments(getComments()).map((comment) => {
                        return (
                            <CommentRender
                                className={`comment ${classes(getCommentClasses(comment))}`}
                                comment={comment}
                                object={object}
                                inCarousel={inCarrousel}
                                deleteCallback={commentDeleteCallback}
                                stickyCallback={commentStickyCallback}
                                key={comment.id}
                            ></CommentRender>
                        );
                    })}
                </div>
            )}

            {showAll && (
                <div className="comments">
                    {getComments(true).map((comment) => {
                        return (
                            <CommentRender
                                className={`comment ${classes(getCommentClasses(comment))}`}
                                comment={comment}
                                object={object}
                                inCarousel={inCarrousel}
                                deleteCallback={commentDeleteCallback}
                                stickyCallback={commentStickyCallback}
                                key={comment.id}
                            ></CommentRender>
                        );
                    })}

                    {getComments().map((comment) => {
                        return (
                            <CommentRender
                                className={`comment ${classes(getCommentClasses(comment))}`}
                                comment={comment}
                                object={object}
                                inCarousel={inCarrousel}
                                deleteCallback={commentDeleteCallback}
                                stickyCallback={commentStickyCallback}
                                key={comment.id}
                            ></CommentRender>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Comments;
