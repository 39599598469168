import { useConfig } from 'providers/ConfigProvider';
import EmojiConverter from 'emoji-js';

const useEmoji = () => {
    const { config } = useConfig();

    const emoji = (text) => {
        if (text === undefined) {
            return text;
        }

        if (config.isMsie || config.isSafari || config.isCordova) {
            return function (text) {
                return text;
            };
        }

        let emoji = new EmojiConverter();

        emoji.img_sets.apple.path = '/images/emoji/';

        emoji.replace_mode = 'img';
        emoji.include_title = true;
        let linkRegex = /<a\s+href="([^"]+)"[^>]*>([^<]+)<\/a>/;

        let linkMatch = text.match(linkRegex);

        if (linkMatch) {
            let beforeLink = text.substring(0, linkMatch.index);
            let link = linkMatch[0];
            let afterLink = text.substring(linkMatch.index + linkMatch[0].length);

            let emojifyBeforeLink = emoji.replace_unified(emoji.replace_colons(beforeLink));
            let emojifyAfterLink = emoji.replace_unified(emoji.replace_colons(afterLink));

            return emojifyBeforeLink + link + emojifyAfterLink;
        }

        return emoji.replace_unified(emoji.replace_colons(text));
    };

    return {
        emoji
    };
};

export default useEmoji;
