import React, { createContext, useContext } from 'react';
import useNavbarService from '../hooks/useNavbarService';
import { useQuery } from '@tanstack/react-query';

const NavbarContext = createContext();

const NavbarProvider = ({ children }) => {
    const { getUserWidgetConfigs } = useNavbarService();

    const { data: navbarSettings, isLoading } = useQuery({
        queryKey: ['navbar'],
        queryFn: () => getUserWidgetConfigs(2)
    });

    return (
        <NavbarContext.Provider value={{ navbarSettings, isLoading }}>
            {children}
        </NavbarContext.Provider>
    );
};

export const useNavbar = () => useContext(NavbarContext);

export default NavbarProvider;
