import React, { useEffect, useState } from 'react';
import useViewport from 'hooks/useViewport';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useDirectoryService from 'api/useDirectoryService';
import { defaultTo } from 'lodash-es';
import { useCurrentStateAndParams, useRouter, useSref } from '@uirouter/react';
import useEmployeeService from 'api/useEmployeeService';
import dayjs from 'dayjs';
import Username from 'components/Username/Username';
import useUser from 'hooks/useUser';
import { useAuth } from 'providers/AuthContext';
import PhoneBookFilters from './components/PhoneBookFilters';
import useRight from '../../hooks/useRight';

const PhoneBook = () => {
    const viewport = useViewport();
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const router = useRouter();
    const { getAvatar, goToUserProfile } = useUser();
    const { user: me } = useAuth();
    const { hasRole } = useRight();

    const [displayFilters, setDisplayFilters] = useState(false);
    const [query, setQuery] = useState(null);
    const [pinnedLevels, setPinnedLevels] = useState([
        {
            isPrimary: true,
            directoryLabel: t('ALL')
        }
    ]);
    const [selectedLevels, setSelectedLevels] = useState([]);

    const { getDirectoryConfigForUser } = useDirectoryService();
    const { getAllEmployeesV2 } = useEmployeeService();

    const getParams = () => {
        return {
            page: defaultTo(params?.page, 1),
            query: defaultTo(params?.q, null),
            letter: null,
            levels: defaultTo(params?.levels, null)
        };
    };

    const { data: dataEmployees } = useQuery({
        queryKey: [`employee_${[...Object.values(getParams())].join('_')}`],
        queryFn: () => getAllEmployeesV2(...Object.values(getParams()))
    });
    const { data: dataConfigDirectory } = useQuery({
        queryKey: [`directory_config_for_user`],
        queryFn: () => getDirectoryConfigForUser()
    });

    useEffect(() => {
        if (dataConfigDirectory) {
            setPinnedLevels((prevState) => {
                return [
                    ...prevState,
                    ...dataConfigDirectory.reduce((acc, directory) => {
                        return [...acc, ...directory.directoryLevelConfigs];
                    }, [])
                ];
            });
        }
    }, [dataConfigDirectory]);

    useEffect(() => {
        setDisplayFilters(viewport.gtXs);
        setQuery(params?.q);
        setSelectedLevels(
            params?.levels
                ? params.levels?.split(',').map((i) => {
                      return parseInt(i);
                  })
                : []
        );
    }, []);

    const getPinnedLevelStyle = (pinnedLevel) => {
        if (pinnedLevel.isPrimary) {
            return;
        }

        return {
            color: pinnedLevel.directoryColor,
            backgroundColor: pinnedLevel.directoryBackgroundColor,
            border: '1px solid ' + pinnedLevel.directoryBorderColor
        };
    };

    const isActive = (pinnedLevel) => {
        if (!pinnedLevel.level) {
            return !selectedLevels.length;
        }

        return selectedLevels.some((selectedLevel) => selectedLevel === pinnedLevel.level.id);
    };

    const onSelectPinnedLevel = (pinnedLevel) => {
        if (!pinnedLevel.level) {
            router.stateService.go(
                'auth.phonebookv2',
                { levels: null, q: query },
                { reload: true, inherit: false }
            );

            return;
        }

        const currentLevelsState = selectedLevels;
        const index = currentLevelsState.indexOf(pinnedLevel.level.id);

        if (index === -1) {
            currentLevelsState.push(pinnedLevel.level.id);
        } else {
            currentLevelsState.splice(index, 1);
        }

        currentLevelsState.sort(function (a, b) {
            return a - b;
        });

        router.stateService.go(
            'auth.phonebookv2',
            { levels: currentLevelsState.join(','), q: query },
            { reload: true, inherit: false }
        );
    };

    const format = (field) => {
        switch (field.name) {
            case 'birthdate':
                return dayjs(field.value).format('DD/MM/YYYY');
            default:
                return field.value;
        }
    };

    const hasPreviousPage = () => {
        return params?.page && parseInt(params.page) !== 1;
    };

    const hasNextPage = () => {
        return dataEmployees && dataEmployees.length >= 45;
    };

    const previousPage = () => {
        return parseInt(params.page) - 1;
    };

    const nextPage = () => {
        return parseInt(defaultTo(params.page, 1)) + 1;
    };

    const canCreateChat = (user) => {
        return !user.id !== me.id;
    };

    const allowToChat = () => {
        return hasRole('ROLE_INTERNAL') && hasRole('HAS_CHANNELS');
    };

    const prevSref = useSref('auth.phonebookv2', { page: previousPage() });
    const nextSref = useSref('auth.phonebookv2', { page: nextPage() });

    return (
        <div className="phonebookv2-wrapper">
            <div className="phonebookv2">
                <div className={`sidebar scrollable ${displayFilters ? 'open' : null}`}>
                    {dataConfigDirectory && (
                        <PhoneBookFilters
                            q={query}
                            selectedLevels={selectedLevels}
                            configDirectory={dataConfigDirectory}
                        />
                    )}
                </div>

                <div className="view scrollable">
                    <div className="pinned-levels">
                        <div className="items">
                            {pinnedLevels.map((pinnedLevel) => {
                                return (
                                    <div
                                        className={`item clickable ${isActive(pinnedLevel) ? 'active' : ''} ${pinnedLevel.isPrimary ? 'pinnedLevel' : ''}`}
                                        style={getPinnedLevelStyle(pinnedLevel)}
                                        onClick={() => {
                                            onSelectPinnedLevel(pinnedLevel);
                                        }}
                                        key={`pin_${pinnedLevel.id}`}
                                    >
                                        {pinnedLevel.directoryLabel}
                                        {isActive(pinnedLevel) && (
                                            <i
                                                className="icon-remove-circle"
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="users cards">
                        {dataEmployees &&
                            dataEmployees.map((user) => {
                                return (
                                    <div className="user card" key={`user_${user.id}`}>
                                        {allowToChat() &&
                                            canCreateChat(user) &&
                                            user.type === 'user' && (
                                                <a
                                                    className="go-to-chat clickable"
                                                    ng-click="$ctrl.openChat(user)"
                                                >
                                                    <i
                                                        className="icon-messaging-we-chat"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            )}

                                        {user.type === 'user' && (
                                            <a
                                                className="go-to-profile clickable"
                                                onClick={() => goToUserProfile(user)}
                                            >
                                                <i className="icon-search" aria-hidden="true"></i>
                                            </a>
                                        )}

                                        <div className="avatar">
                                            <img src={getAvatar(user)} alt={'avatar'} />
                                        </div>
                                        <div className="levels">
                                            {user.levels &&
                                                user.levels.length > 0 &&
                                                user.levels.map((level) => {
                                                    if (!level.label) return;
                                                    return (
                                                        <div
                                                            className="level"
                                                            style={level.style}
                                                            key={`user_${user.id}_level_${level.id}`}
                                                        >
                                                            <div className="label">
                                                                {level.label}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>

                                        <Username user={user} light={true}></Username>

                                        {user?.fields.length > 0 &&
                                            user?.fields.map((field) => {
                                                if (!field?.value) return;
                                                return (
                                                    <div
                                                        className="fields"
                                                        key={`user_${user.id}_field_${field.label}`}
                                                    >
                                                        <div className="field">
                                                            <div className="label">
                                                                {field.label} :
                                                            </div>
                                                            <div className="value">
                                                                {format(field)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            })}
                    </div>

                    {(hasPreviousPage() || hasNextPage()) && (
                        <div className="pagination">
                            {hasPreviousPage() && (
                                <div className="page previous">
                                    <a {...prevSref}>
                                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )}

                            <div className="spacer"></div>

                            {hasNextPage() && (
                                <div className="page next">
                                    <a {...nextSref}>
                                        {t('POSTS_NEXT_PAGE')}{' '}
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PhoneBook;
