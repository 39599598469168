import { useTranslation } from 'react-i18next';
import useTableCeilComponent from '../hooks/useTableCeilComponent';

const ViewsCeil = ({ config, row }) => {
    const { t } = useTranslation();
    const { getIntValue } = useTableCeilComponent(config, row);

    return (
        <>
            <div className="text-center">
                {getIntValue() === 0 && <span>{t('POST_NO_VIEW')}</span>}
                {getIntValue() === 1 && <span>{t('POST_ONE_VIEW')}</span>}
                {getIntValue() > 1 && (
                    <span>{t('POST_VIEWS', { like_number: getIntValue() })}</span>
                )}
            </div>
        </>
    );
};

export default ViewsCeil;
