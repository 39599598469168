import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useOrganizationalUnitService from './api/useOrganizationalUnitService';
import { useAdminOrganizationalUnitContext } from '../../providers/AdminOrganizationalUnitProvider';
import { useAlert } from 'providers/AlertContext';
import OUFormModal from './modals/OUFormModal';
import { useModal } from 'providers/ModalContext';
import AdminHeader from '../../../../components/Admin/components/AdminHeader';
import Table from '../../../../components/Table';

const OrganizationalUnits = () => {
    const { t } = useTranslation();
    const { allOrganizationalUnits, isLoading } = useAdminOrganizationalUnitContext();
    const { deleteOrganizationalUnit } = useOrganizationalUnitService();
    const { showConfirm } = useAlert();
    const queryClient = useQueryClient();
    const { openModal } = useModal();

    const deleteOU = (organizationalUnit) => {
        showConfirm({
            title: t('ADMIN_MENU_HELPDESK_COMMON_ARTICLE_DELETE_TITLE_LABEL'),
            text: t('ADMIN_MENU_HELPDESK_COMMON_ARTICLE_DELETE_TEXT_LABEL'),
            button: {
                label: t('ADMIN_MENU_HELPDESK_COMMON_DELETE_BUTTON_LABEL'),
                classes: ['red', 'bold']
            }
        })
            .then(() => {
                deleteOrganizationalUnit(organizationalUnit).then(() => {
                    queryClient.refetchQueries({ queryKey: ['allOrganizationalUnits'] });
                });
            })
            .catch(() => {});
    };

    const COLUMNS = [
        {
            id: 'position',
            header: 'Position',
            sortable: true,
            defaultSort: 'asc'
        },
        {
            id: 'id',
            header: 'ID',
            sortable: true
        },
        {
            id: 'name',
            header: 'Nom',
            sortable: true
        }
    ];

    return (
        <>
            <OUFormModal />
            <AdminHeader
                title={'ORGANIZATIONAL_UNITS'}
                modalName={'add_edit_ou'}
                modalButtonTitle={'ADD_ORGANIZATIONAL_UNIT'}
            />

            <Table
                columns={COLUMNS}
                data={allOrganizationalUnits}
                isLoading={isLoading}
                actionsAsButtons
                handleActions={[
                    {
                        label: 'ADMIN_MENU_BRANDS_EDIT_BRAND_LABEL',
                        handler: (organizationalUnit) => {
                            openModal('add_edit_ou', {
                                currentOU: organizationalUnit
                            });
                        }
                    },
                    {
                        label: 'Supprimer',
                        danger: true,
                        handler: (row) => {
                            deleteOU(row);
                        }
                    }
                ]}
            />
        </>
    );
};

export default OrganizationalUnits;
