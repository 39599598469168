import { useHttp } from 'api/useHttp';

const useWidgetsService = () => {
    const axios = useHttp();

    const getUserWidgetConfigs = (position) => {
        return axios
            .get(`/homepage/widget/user/configs`, {
                params: {
                    position
                }
            })
            .then((response) => response.data);
    };

    const getConfigs = (position) => {
        return axios
            .get(`/homepage/configs`, {
                params: {
                    position
                }
            })
            .then((response) => response.data);
    };

    const getWidgetConfigs = (position) => {
        return axios
            .get(`/homepage/widget/configs`, {
                params: {
                    position
                }
            })
            .then((response) => response.data);
    };

    return {
        getUserWidgetConfigs,
        getConfigs,
        getWidgetConfigs
    };
};

export default useWidgetsService;
