import { useCurrentStateAndParams } from '@uirouter/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'components/Form/hooks';
import {
    ModalForm,
    ModalFormBody,
    ModalFormSection,
    ModalFormFooter
} from 'components/Form/ModalForm';
import AdminFormFieldSettings from './components/AdminFormFieldSettings';
import { Checkbox, Select } from 'components/Form/Inputs';
import { Button } from 'antd';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FormInputRow from './components/FormInputRow';
import { cloneDeep, uniqueId } from 'lodash-es';
import { useConfig } from 'providers/ConfigProvider';
import WorkspaceFormsService from 'components/Form/services/WorkspaceForm';
import { useAdminFormContext } from '../../providers/AdminFormProvider';
import useWorkspaceFormService from './components/api/useWorkspaceFormService';
import { useAlert } from '../../../../../../providers/AlertContext';
import Editor from '../../../../../../components/Editor/Editor';

const AddForm = () => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const { t } = useTranslation();
    const { params } = useCurrentStateAndParams();
    const [inputs, setInputs] = useState([]);
    const methods = useForm();
    const { config } = useConfig();
    const { workspaceForms } = useAdminFormContext();
    const { saveAll } = useWorkspaceFormService();
    const { showToast } = useAlert();

    const currentWorkspaceForm = useMemo(() => {
        return {
            ...methods.getValues(),
            workspace_form_inputs: methods.getValues()?.workspace_form_inputs
                ? Object.values(methods.getValues()?.workspace_form_inputs)
                : []
        };
    }, [methods.watch('workspace_form_inputs')]);

    const WorkspaceForms = new WorkspaceFormsService(config);

    const getTitle = useCallback(() => {
        let title = params.formId
            ? 'ADMIN_WORKSPACE_FORMS_TITLE_EDIT'
            : 'ADMIN_WORKSPACE_FORMS_TITLE_ADD';

        return t(title);
    }, [params.formId, t]);

    const handleDeleteRow = (uniqueId) => {
        setInputs((prevState) => [...prevState.filter((i) => i.uniqueId !== uniqueId)]);
        methods.unregister(`workspace_form_inputs[${uniqueId}]`);
    };

    useEffect(() => {
        if (params.formId && workspaceForms) {
            const form = workspaceForms.find(({ id }) => parseInt(id) === parseInt(params.formId));
            Object.keys(form).map((key) => {
                methods.setValue(key, form[key]);
                switch (key) {
                    case 'workspace_form_inputs':
                        const workspaceFormInputs = form[key].map((input) => ({
                            ...input,
                            has_condition: !!input.input_condition,
                            input_condition: {
                                ...(input.input_condition ? input.input_condition : {}),
                                value_condition_as_array: input?.value_condition
                                    ? input?.value_condition.split('|')
                                    : []
                            },
                            uniqueId: uniqueId()
                        }));
                        setInputs(workspaceFormInputs);
                        methods.setValue(
                            key,
                            workspaceFormInputs.reduce((acc, obj) => {
                                acc[obj.uniqueId] = obj;
                                return acc;
                            }, {})
                        );
                        break;
                    case 'workspaces':
                        methods.setValue(
                            key,
                            form[key].map((workspace) => workspace.id)
                        );
                        break;
                    default:
                        methods.setValue(key, form[key]);
                        break;
                }
            });
        }
    }, [params, workspaceForms]);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setInputs((items) => {
                const oldIndex = items.findIndex((item) => item.uniqueId === active.id);
                const newIndex = items.findIndex((item) => item.uniqueId === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };
    const add = () => {
        let selectedType = methods.getValues()['selectedFieldType'];

        let fieldType = cloneDeep(
            WorkspaceForms.getFieldTypes().find((fieldType) => {
                return fieldType.id === selectedType;
            })
        );

        if (!fieldType) {
            return;
        }
        let field = {
            label: WorkspaceForms.getFieldNameByType(selectedType),
            label_render: WorkspaceForms.getFieldNameByType(selectedType),
            type: selectedType,
            uniqueId: uniqueId()
        };

        if (methods.getValues()['unshiftOnList']) {
            setInputs((prevInputs) => {
                return [field, ...prevInputs];
            });
        } else {
            setInputs((prevInputs) => {
                return [...prevInputs, field];
            });
        }

        //this.saveOrder();
        methods.setValue('selectedFieldType', null);
        methods.setValue('unshiftOnList', false);
    };

    const onFinish = (values) => {
        saveAll({
            ...values,
            workspace_form_inputs: Object.values(values.workspace_form_inputs)
        }).then(() => {
            showToast({
                text: `Le formulaire "${values.name}" a été sauvegardé !`
            });
        });
    };

    return (
        <div className="admin-forms-add-wrapper">
            <div className="admin-forms-add">
                <div className="widgets-admin is-springboard">
                    <div className="dashboard">
                        <div className="top">
                            <h1>{getTitle()}</h1>

                            {!params.formId && (
                                <div className="actions">
                                    <Button ng-click="$ctrl.importWorkspaceForm()">
                                        Importer un nouveau formulaire
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className="tabs">
                            <div className="tab active">Liste des champs</div>
                        </div>

                        <div className="tabs-content scrollable">
                            <div className="tab-content">
                                <ModalForm
                                    methods={methods}
                                    onSubmit={methods.handleSubmit(onFinish)}>
                                    <ModalFormBody>
                                        <ModalFormSection>
                                            <AdminFormFieldSettings />
                                        </ModalFormSection>
                                        <ModalFormSection inline>
                                            <Select
                                                name={'selectedFieldType'}
                                                style={{ flex: 1 }}
                                                placeholder={t('Choisir un type de champ')}
                                                options={WorkspaceForms.getFieldTypes().map(
                                                    (type) => ({
                                                        label: type.name,
                                                        value: type.id
                                                    })
                                                )}
                                            />
                                            <Checkbox
                                                name={'unshiftOnList'}
                                                label={'Ajouter en haut de la liste'}
                                            />
                                            <Button htmlType={'button'} onClick={add}>
                                                Ajouter
                                            </Button>
                                        </ModalFormSection>

                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragEnd={handleDragEnd}>
                                            <SortableContext
                                                items={inputs.map((i) => i.uniqueId)}
                                                strategy={verticalListSortingStrategy}>
                                                {inputs.map((input, index) => (
                                                    <FormInputRow
                                                        input={input}
                                                        key={`form_input_row_${input.uniqueId}`}
                                                        index={index}
                                                        handleDeleteRow={handleDeleteRow}
                                                    />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </ModalFormBody>
                                    <ModalFormFooter showCancel={false} />
                                </ModalForm>
                            </div>
                        </div>
                    </div>

                    <div className="widgets scrollable">
                        {currentWorkspaceForm && (
                            <div className="editor">
                                <Editor
                                    workspace={{ workspace_form: currentWorkspaceForm }}
                                    isPreview
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddForm;
