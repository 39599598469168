import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useTagContext } from '../providers/TagProvider';
import { useTagModal } from '../providers/TagModalProvider';
import { values } from 'lodash-es';

const TagModalContent = () => {
    const TAG_TYPES = ['essentials', 'shared', 'mine'];
    const { t } = useTranslation();
    const { tags } = useTagContext();
    const { resolvePromise, rejectPromise } = useTagModal();
    const [selectedTags, setSelectedTags] = useState({});

    const {
        contexts: {
            tag_post: { tags: defaultTags }
        },
        closeModal
    } = useModal();

    useEffect(() => {
        if (defaultTags) {
            setSelectedTags(
                defaultTags.reduce((acc, tag) => {
                    acc[tag.id] = tag;
                    return acc;
                }, {})
            );
        }
    }, [defaultTags]);

    useEffect(() => {
        return () => {
            rejectPromise([]);
        };
    }, [tags]);

    const isSelected = useCallback(
        (tag) => {
            return selectedTags[tag.id];
        },
        [selectedTags]
    );

    const getTagByType = (type) => {
        if (!tags) {
            return [];
        }

        return tags
            .filter((tag) => {
                if (type === 'essentials') {
                    return tag.isEssential;
                }
                if (type === 'shared') {
                    return tag.admin && !tag.isEssential;
                }
                if (type === 'mine') {
                    return !tag.admin && !tag.isEssential;
                }

                return false;
            })
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    const validateTagPost = () => {
        resolvePromise(values(selectedTags).filter((t) => t));
        closeModal('tag_post');
    };

    if (!tags) {
        return;
    }

    return (
        <>
            {TAG_TYPES.map((type) => {
                if (getTagByType(type).length === 0) return;

                return (
                    <div className="tags" key={`tag_${type}`}>
                        <div className="title"> {t('TAG_TITLE_' + type.toUpperCase())} </div>
                        {getTagByType(type).map((tag) => {
                            return (
                                <div
                                    className={`tag ${isSelected(tag) ? 'is-checked' : ''}`}
                                    key={`tag_${tag.id}`}>
                                    <div
                                        className="meta"
                                        onClick={() =>
                                            setSelectedTags((prevState) => {
                                                return {
                                                    ...prevState,
                                                    [tag.id]: prevState[tag.id] ? false : tag
                                                };
                                            })
                                        }>
                                        <div className="name">{tag.name}</div>
                                        <div className="icon">
                                            <div
                                                className="shield"
                                                style={{
                                                    backgroundColor: tag.color,
                                                    borderColor: tag.color
                                                }}></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <div className="tags">
                <div className="actions">
                    <button className="btn outline validate" onClick={validateTagPost}>
                        {t('TAG_VALIDATE')}
                    </button>
                </div>
            </div>
        </>
    );
};

const TagModal = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();

    return (
        <div className="tags-modal-root">
            <Modal name="tag_post" title={t('TAG_THAT_POST')} options={{ zIndex: 312 }}>
                {isModalOpen('tag_post') && <TagModalContent />}
            </Modal>
        </div>
    );
};

export default TagModal;
