import { useHttp } from './useHttp';

const useDirectoryService = () => {
    const axios = useHttp();

    const getDirectoryConfigForUser = () => {
        return axios.get(`/directory-configs/user`).then((response) => {
            return response.data;
        });
    };

    return {
        getDirectoryConfigForUser
    };
};

export default useDirectoryService;
