import React, { useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import { useModal } from 'providers/ModalContext';
import { useTranslation } from 'react-i18next';
import { useShowLikerModal } from '../providers/ShowLikerModalProvider';

const ShowLikerModalContent = () => {
    const { type, id, setId, setType } = useShowLikerModal();

    useEffect(() => {
        return () => {
            setId(null);
            setType(null);
        };
    }, [id, type]);

    return null;
};

const ShowLikerModal = () => {
    const { t } = useTranslation();
    const { isModalOpen } = useModal();

    return (
        <div className="users_like-panel">
            <Modal
                name="users_like"
                title={t('STATS_USERS_TITLE')}
                options={{
                    hasFlexContent: true,
                    hasPaddedContent: true,
                    canScroll: false,
                    zIndex: 1010
                }}
            >
                <div className={'users_like'}>
                    {isModalOpen('users_like') && <ShowLikerModalContent />}
                </div>
            </Modal>
        </div>
    );
};

export default ShowLikerModal;
